import React from "react";
import StudentLayout from "../../../layouts/student";
import { PATH_STUDENT_CART } from "../../../services/constants/route/router";
import { Navigate, useNavigate } from "react-router-dom";
import CartButton from "../../../components/commons/buttons/CartButton";
import { WithChildren } from "../../../types/globals";
import { CART_COMMON } from "../../../services/constants/student/cart";
import { useStorage } from "../../../hooks/useStorage";


const StudentCartBase = ({ children }: WithChildren) => {
  const { getLocalStorage } = useStorage();
  const navigate = useNavigate();
  const paymentFeature = getLocalStorage("payment_feature");

  if (!paymentFeature) {
    return <Navigate to={"/404"} replace={true}/>
  }

  return (
    <StudentLayout gap>
      <section className="border-b pb-[14px] flex items-center justify-between mb-[10px]">
        <div className="flex justify-between items-center w-full h-[34px]">
          <div
            className="text-[14px] font-[500] cursor-pointer"
            onClick={() => navigate(PATH_STUDENT_CART.DEFAULT)}
          >
            {CART_COMMON.CART_TITLE}
          </div>
          <div>
            <CartButton />
          </div>
        </div>
      </section>
      {children}
    </StudentLayout>
  );
};

export default StudentCartBase;
