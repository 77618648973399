import { getTabObj } from "../../../helpers/parseData";
import { PATH_ADMIN_TERMS_OF_USE } from "../../route/router";

const TERMS_OF_USE_TAB_ITEM = {
    LOGIN_PAGE_SETTINGS: "ログインページ設定",
    STUDENT_THEME_SETTINGS: "受講者画面設定",
    TERMS_OP_SERVICE: "利用規約",
};

const TERMS_OF_USE_TAB_DATA = [
    getTabObj(TERMS_OF_USE_TAB_ITEM.LOGIN_PAGE_SETTINGS, PATH_ADMIN_TERMS_OF_USE.LOGIN_PAGE_SETTING),
    getTabObj(TERMS_OF_USE_TAB_ITEM.STUDENT_THEME_SETTINGS, PATH_ADMIN_TERMS_OF_USE.STUDENT_THEME_SETTING),
    getTabObj(TERMS_OF_USE_TAB_ITEM.TERMS_OP_SERVICE, PATH_ADMIN_TERMS_OF_USE.TERMS_OF_SERVICE),
  ];

const BACKGROUND = {
  MONOCHROMATIC: '単色',
  IMAGE: '画像',  
};

const BACKGROUND_DATA = [
  { value: 0, label: BACKGROUND.MONOCHROMATIC },
  { value: 1, label: BACKGROUND.IMAGE },
];

const COLOR_DATA = ['#FFFFFF', '#FAC46E', '#227C9D', '#8FC2D5', '#BEBEBE',];

export { TERMS_OF_USE_TAB_ITEM, TERMS_OF_USE_TAB_DATA, BACKGROUND, BACKGROUND_DATA, COLOR_DATA }