import { AiOutlineArrowRight } from "react-icons/ai";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { StudentActivityLog } from "../../../types/student/studentActivityLog";
import {
  handleRedirectDetail,
  showButton,
  showLabel,
} from "../../../services/utils/student/studentActivityLog";

const StudyHistoryListItem = ({ data }: { data: StudentActivityLog }) => {
  const navigate = useNavigate();
  const formatDateTime = (inputDate?: string) => {
    if (!inputDate) return "";
    return dayjs(inputDate).format("MM月DD日 HH:mm");
  };

  return (
    <div className="flex min-h-[71px] mb-[25px] max-sm:flex-col">
      <div className="font-[700] text-[10px] text-secondary with_roboto leading-[100%] w-full max-w-[100px] flex pl-[14px] pt-[10px] max-sm:hidden">
        <div className="">{formatDateTime(data.created_at)}</div>
      </div>
      <div className="w-full mr-[21px] pt-[5px] max-sm:hidden">
        <div className="text-[12px] font-[500] w-full flex items-center mb-[9px]">
          {showLabel(data?.log_type || 0)}
        </div>
        <div className="text-[13px] font-[400] w-full flex items-center px-[16px] justify-between bg-[#FEF9EF] rounded-[10px] max-w-[610px] min-h-[45px] border border-danger-light">
          <div className="flex w-full max-w-[400px]">
            <div
              className="mr-[15px] text-[12px] font-[400] truncate"
              title={data.title}
            >
              {data.title}
            </div>
          </div>
          <button
            className={`bg-white border border-danger-light text-secondary text-[9.7px] font-[500] leading-[14px] flex py-[7px] rounded-[15px] min-h-[28px] w-full max-w-[138px] cursor-pointer items-center justify-center`}
            onClick={() =>
              navigate(handleRedirectDetail(data.log_type || 0, data))
            }
          >
            {showButton(data?.log_type || 0)}
            <AiOutlineArrowRight className="ml-[5px]" size={12} />
          </button>
        </div>
      </div>

      {/* responsive smartphone */}
      <div className="font-[700] text-[10px] with_roboto leading-[100%] w-full flex sm:hidden">
        <div className="text-[10px] text-secondary with_roboto leading-[100%] flex items-center mb-[9px]">
          {formatDateTime(data.created_at)}
        </div>
        <div className="text-[12px] flex items-center mb-[9px] ml-[20px]">
          {showLabel(data?.log_type || 0)}
        </div>
      </div>
      <div className="text-[13px] font-[400] w-full flex flex-col gap-y-[10px] p-[10px] justify-center bg-[#FEF9EF] rounded-[10px] max-w-[610px] min-h-[45px] border border-danger-light sm:hidden">
        <div className="flex w-full max-w-[400px]">
          <div
            className="mr-[15px] text-[12px] font-[400] truncate"
            title={data.title}
          >
            {data.title}
          </div>
        </div>
        <div className="w-full flex justify-end items-center">
          <button
            className={`bg-white border border-danger-light text-secondary text-[9.7px] font-[500] leading-[14px] flex py-[7px] rounded-[15px] min-h-[28px] w-full max-w-[138px] cursor-pointer items-center justify-center`}
            onClick={() =>
              navigate(handleRedirectDetail(data.log_type || 0, data))
            }
          >
            {showButton(data?.log_type || 0)}
            <AiOutlineArrowRight className="ml-[5px]" size={12} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudyHistoryListItem;
