import { TYPE_CAPACITY } from "../constants/globals";

const formatCapacity = (
   value: number,
   inputUnit: string = TYPE_CAPACITY.MB,
   outputUnit: string = TYPE_CAPACITY.GB,
) => {
   const validUnits = Object.values(TYPE_CAPACITY);

   if (!validUnits.includes(inputUnit) || !validUnits.includes(outputUnit)) {
      return 0;
   }

   const unitsInBytes = {
      [TYPE_CAPACITY.BYTE]: 1,
      [TYPE_CAPACITY.KB]: 1024,
      [TYPE_CAPACITY.MB]: 1024 * 1024,
      [TYPE_CAPACITY.GB]: 1024 * 1024 * 1024,
   };

   const valueInBytes = value * unitsInBytes[inputUnit];
   const convertedValue = valueInBytes / unitsInBytes[outputUnit];

   return Number(convertedValue.toFixed(2));
}

export {
   formatCapacity,
};