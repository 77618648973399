import React from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import { File } from "../../../../types/admin/folder";
import MP3 from "../../../../assests/images/icons/MP3.svg";
import MP4 from "../../../../assests/images/icons/MP4.svg";
import PDF from "../../../../assests/images/icons/PDF.svg";
type StudentSearchItemProps = {
  file: File;
  className?: string;
};

const FileSearchItem = ({
  file,
  className = "",
  ...props
}: StudentSearchItemProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >) => {
  return (
    <div
      className={`w-full max-w-[235px] min-h-[70px] border border-secondary-lighter flex items-center justify-center ${className}`}
      {...props}
    >
      <div className="flex items-center cursor-pointer gap-[17.56px] py-[11.56px] pl-[11.56px] pr-[10px]">
        <div className="w-[41.67px] h-[41.67px] flex justify-center items-center">
          {file.resource_link === null && <div></div>}
          {file.content_type === "pdf" && (
            <img src={PDF} className="max-w-[40px]" />
          )}
          {(file.content_type === "png" ||
            file.content_type === "jpeg" ||
            file.content_type === "jpg") && (
            <img src={file.resource_link} className="w-[41.67px] h-[41.67px] object-cover"/>
          )}
          {file.content_type === "mp4" && <img src={MP4} width={40} />}
          {file.content_type === "mp3" && <img src={MP3} width={40} />}
        </div>
        <div className="text-left">
          <div className="text-[16px] leading-[16px] mb-[8px] w-[150px]">{`${file.origin_filename}`}</div>
          <div className="text-secondary text-[12px] leading-[16px] w-[150px] truncate">
            {`${file.upload_by_user.first_name} ${file.upload_by_user.last_name} `}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileSearchItem;
