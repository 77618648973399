import LearningRead from '../../commons/icons/Learning/Read';
import LearningWrite from '../../commons/icons/Learning/Write';
import { UNIT_TYPE } from '../../../services/constants/admin/pages/material';

const UnitIcon = ({ type }: { type: number }): JSX.Element => {
  switch (type) {
    case UNIT_TYPE.TEST:
      return (
        <div className="w-[24px] h-[24px] rounded-full bg-warning flex items-center justify-center">
          <LearningWrite />
        </div>
      );

    default:
      return (
        <div className="w-[24px] h-[24px] rounded-full bg-primary flex items-center justify-center">
          <LearningRead />
        </div>
      );
  }
};

export default UnitIcon;
