import Quill from 'quill';
import katex from 'katex';

const FormulaBlot = Quill.import('formats/formula');

class CustomFormulaBlot extends FormulaBlot {
  static create(value: string) {
    const node = super.create(value);
    katex.render(value, node, { throwOnError: false, output: "mathml" });
    return node;
  }
}

CustomFormulaBlot.blotName = 'formula';
CustomFormulaBlot.tagName = 'span';
CustomFormulaBlot.className = 'ql-formula';

Quill.register(CustomFormulaBlot, true);