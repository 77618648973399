import React, { useEffect } from "react";
import {
  swalClose,
  swalError,
  swalLoading,
} from "../../../../services/helpers/swal";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useSectionManagementContext } from "../../../../context/Section/Management";
import SidebarGroupItem from "./SidebarGroupItem";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { Section } from "../../../../types/admin/section";
import { PATH_ADMIN_SECTION } from "../../../../services/constants/route/router";

const SidebarManagement = () => {
  const {
    mainSections,
    sections,
    setSelectedSection,
    setSections,
    setStudents,
    setCheckedObj,
    setTitle,
    triggerRefreshCourseManagement,
  } = useSectionManagementContext();
  const { getParamValue, navigate } = useObjectRoutes();
  const sectionId = getParamValue("section_id");

  const findSectionById = (
    sections: Section[],
    sectionId: number
  ):
    | {
        currentSection: Section;
        parentName?: string;
      }
    | undefined => {
    for (const section of sections) {
      if (section.id === sectionId) {
        return { currentSection: section };
      }
      if (section.sub_section) {
        const foundSection = findSectionById(section.sub_section, sectionId);
        if (foundSection) {
          return {
            currentSection: foundSection.currentSection,
            parentName: section.name,
          };
        }
      }
    }
    return undefined;
  };

  useEffect(() => {
    // if (mainSections && mainSections.length > 0) return;

    const fetch = async () => {
      // swalLoading();

      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_SECTION.GET_SECTION_LIST}`,
      });

      if (!result.data) return swalError();

      if (!sectionId && result.data) return navigate(`${PATH_ADMIN_SECTION.MANAGEMENT}?section_id=${result.data[0]?.id}`);

      const foundSection = findSectionById(result.data, Number(sectionId));
      if (foundSection) {
        const { currentSection, parentName } = foundSection;
        if (currentSection && parentName) {
          setTitle(`${parentName} | ${currentSection.name}`);
        } else if (currentSection && !parentName) {
          setTitle(`${currentSection.name}`);
        } else {
          setTitle("");
        }
      }

      setSections([...sections, ...result.data]);
      swalClose();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefreshCourseManagement, sectionId]);

  useEffect(() => {
    if (!sectionId) return;
    setSelectedSection(Number(sectionId));

    const fetch = async () => {
      // swalLoading();
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_SECTION.GET_SECTION_STUDENT}?section_id=${sectionId}`,
      });

      if (!result.data) return swalError();
      setCheckedObj({});
      setStudents(result.data);
      swalClose();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  return (
    <section>
      {/* List */}
      <div className="text-secondary-dark mt-[15px] max-h-[328px] overflow-auto pr-[10px]">
        {mainSections &&
          mainSections.map((section) => (
            <SidebarGroupItem section={section} key={section.id} />
          ))}
      </div>
    </section>
  );
};

export default SidebarManagement;
