import React from 'react';
import { MdModeEditOutline } from 'react-icons/md';
import { HiOutlineX } from 'react-icons/hi';

type ImageInternalButtonProps = {
  removeImage?: () => void;
  editImage?: () => void;
  removeButtonOtherStyle?: string;
  editButtonOtherStyle?: string;
};

const ImageInternalButton = ({
  removeImage,
  editImage,
  removeButtonOtherStyle,
  editButtonOtherStyle,
}: ImageInternalButtonProps) => {
  return (
    <>
      <button
        type="button"
        className={`absolute top-[10px] right-[10px] rounded-lg bg-slate-400 ${removeButtonOtherStyle}`}
        onClick={removeImage}
      >
        <HiOutlineX className="text-white px-[3px]" size={18} />
      </button>
      <button
        type="button"
        className={`absolute top-[10px] right-[35px] rounded-lg bg-slate-400 ${editButtonOtherStyle}`}
        onClick={editImage}
      >
        <MdModeEditOutline className="text-white px-[3px]" size={18} />
      </button>
    </>
  );
};

export default ImageInternalButton;
