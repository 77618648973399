import React from "react";
import { useState, useEffect } from "react";
import SettingsConfigRow from "./SettingsConfigRow";
import SemiSquareButton from "../commons/SemiSquareButton";
import { makeRequest } from "../../../services/axios/axios";
import {
  swalClose,
  swalError,
  swalLoading,
} from "../../../services/helpers/swal";
import { API } from "../../../services/constants/route/api";
import { Settings } from "../../../types/admin/student";
const SettingsConfig = () => {
  const [settings, setSettings] = useState<Settings[]>([]);
  const [newSettings, setNewSettings] = useState({});

  const handleSave = async () => {
    // swalLoading();
    const result = await makeRequest({
      method: "patch",
      url: `${API.ADMIN_STUDENT.UPDATE_SETTINGS}`,
      data: newSettings,
    });
    if (!result.status) return swalError();
    setNewSettings({});
    swalClose();
  };

  const handleRadioChange = (
    index: number,
    newValue: number,
    column?: string
  ) => {
    if (!column) return;
    // Update the `isDisplay` state of the setting in the local state
    setSettings((prevSettings) =>
      prevSettings.map((setting, i) =>
        i === index ? { ...setting, isDisplay: newValue } : setting
      )
    );
    setNewSettings((prev) => ({ ...prev, [column]: newValue }));
  };
  useEffect(() => {
    const fetch = async () => {
      // swalLoading();

      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_STUDENT.GET_SETTINGS}`,
      });
      if (!result.data) return swalError();

      setSettings(result.data);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="ml-[15px] font-[500] text-[14px] mt-[40px] mb-[10px]">
        プロフィール設定
      </div>
      <div className="mb-[105px]">
        {settings && settings.length > 0 && (
          <div>
            {settings.map((item, index) => (
              <SettingsConfigRow
                name={item.label}
                isDisplay={item.isDisplay}
                key={index}
                column={item.column}
                onRadioChange={(newValue: number, column?: string) =>
                  handleRadioChange(index, newValue, column)
                }
              />
            ))}
          </div>
        )}
      </div>
      <div className="flex items-center justify-center ">
        <SemiSquareButton onSave={handleSave}>設定を保存</SemiSquareButton>
      </div>
    </div>
  );
};

export default SettingsConfig;
