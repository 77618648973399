import { useState } from "react";
import Bell from "../../components/commons/icons/Bell";
import Logo from "../../components/commons/icons/Logo";
import defaultLogo from "../../assests/images/default_avatar.webp";
import DownTriangle from "../../components/commons/icons/DownTriangle";
import Menu from "./Menu";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/student/common/BreadCrumb";
import { useAuthContext } from "../../context/AuthContext";
import { TbMenu2 } from "react-icons/tb";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { LEARNING_TOOLS } from "../../services/constants/student/learning";
import LearningToolType from "../../components/student/MyPage/LearningToolType";
import MenuMobile from "./MenuMobile";
import { MdMenuBook } from "react-icons/md";
import MyPageTopIcon from "../../assests/images/icons/mypagetop.svg";
import { useLoginSettingContext } from "../../context/LoginSettingContext";
import classNames from "classnames";
import logo from "../../assests/images/logo_LMS.webp";

const Header = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [hasNotification, setHasNotification] = useState<boolean>(true);
  const [isOpenProfile, setIsOpenProfile] = useState<boolean>(false);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const { loginSetting, studentTheme, fetching } = useLoginSettingContext();

  return (
    <>
      <div className="h-[65px] w-full flex justify-center items-center max-sm:h-[50px]">
        <div className="w-full max-w-[1088px] flex justify-between max-sm:items-center">
          {fetching ? (
            <div className="skeleton w-[180px] h-[50px] ml-[10px] bg-secondary-light rounded shadow-lg"></div>
          ) : (
            <Logo
              otherStyle={classNames(
                `max-w-[250px] w-auto !max-h-[60px] max-sm:w-[125.77px] max-sm:h-[25px] max-sm:ml-[10px] overflow-hidden`
              )}
              logo_admin_path={loginSetting?.logo_admin_path_url || logo}
            />
          )}

          <div className="flex max-sm:items-center">
            {/* <div
              className="mr-[6px] w-[42px] h-[42px] border border-dander-light flex justify-center items-center 
                        max-sm:w-[40px] max-sm:h-[40px]"
            >
              <Bell hasNotification={hasNotification} className="" />
            </div> */}
            {/* Notification Bell hidden for later phases  */}

            {/* Profile desktop */}
            <div
              style={{ color: studentTheme.text_color }}
              className="border border-dander-light flex justify-center items-center ml-[6px] text-black relative group cursor-pointer max-sm:w-[63] max-sm:h-[40px] max-sm:hidden"
              onClick={() => setIsOpenProfile(!isOpenProfile)}
            >
              <img
                src={user?.avatar_url ?? defaultLogo}
                width={26.67}
                height={26.67}
                alt="avatar"
                className="mx-[7.67px] object-cover w-[26.67px] h-[26.67px]"
              />
              <div className="text-[13px] font-[500] leading-[18.82px] tracking-[0.02em] mr-[3px]">
                {(user?.last_name || "") + (user?.first_name || "")}
              </div>
              <div className="font-[400] text-[10px] leading-[14.48px]">
                さん
              </div>
              <DownTriangle className="mx-[5px]" />
              <Menu
                className={`absolute top-[68px] max-lg:right-6 ${
                  !isOpenProfile ? "hidden" : ""
                } z-[1000]`}
              />
            </div>

            {/* Profile Mobile screen */}
            <div
              style={{ color: studentTheme.text_color }}
              className="hidden border border-dander-light max-sm:flex justify-center items-center text-black relative group cursor-pointer max-sm:w-[63px] max-sm:h-[40px]"
              onClick={() => setIsOpenProfile(true)}
            >
              <img
                src={user?.avatar_url ?? defaultLogo}
                width={26.67}
                height={26.67}
                alt="avatar"
                className="mx-[7.67px] object-cover w-[26.67px] h-[26.67px]"
              />
              <div className="text-[13px] font-[500] leading-[18.82px] tracking-[0.02em] mr-[3px] max-sm:hidden">
                {(user?.last_name || "") + (user?.first_name || "")}
              </div>
              <div className="font-[400] text-[10px] leading-[14.48px] max-sm:hidden">
                さん
              </div>
              <DownTriangle className="mx-[5px]" />
              <MenuMobile isOpenProfile={isOpenProfile} />
            </div>

            {/* Menu Mobile screen */}
            <div
              style={{ backgroundColor: studentTheme.main_color_first }}
              className="hidden z-[101] max-sm:flex max-sm:justify-center max-sm:items-center w-[50px] h-[51px] ml-[6px] mb-[-1px] bg-primary text-white max-sm:flex-col"
            >
              <TbMenu2 size={24} onClick={() => setIsOpenMenu(!isOpenMenu)} />
              <p
                className="text-[10px]"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                Menu
              </p>
              <div
                className={`fixed top-0 right-0 w-full h-full pl-[30px] transition duration-250 ease-in-out ${
                  isOpenMenu ? "" : "translate-x-full"
                }`}
              >
                <div
                  style={{ borderColor: studentTheme.main_color_first }}
                  className="h-full border-t-[5px] border-primary bg-white "
                >
                  <div className="flex justify-between items-center h-[70px] pl-[25px] pr-[20px]">
                    <Logo
                      otherStyle="!w-[125.77px] !h-[25px]"
                      logo_admin_path={
                        loginSetting?.logo_admin_path_url || logo
                      }
                    />
                    <AiOutlineCloseSquare
                      size={38}
                      style={{ color: studentTheme.main_color_first }}
                      className="text-primary"
                      onClick={() => setIsOpenMenu(false)}
                    />
                  </div>
                  <div className="h-full bg-secondary-morelighter px-[20px] py-[15px] ">
                    {/* Learning tool */}
                    <div className="flex max-[320px]:flex-col gap-[10px] justify-center">
                      <div className="flex flex-col max-[320px]:flex-row gap-[8px]">
                        <div
                          className={`w-[121px] h-[67.61px] border border-secondary-light rounded-[5px] cursor-pointer shadow-md bg-success-light`}
                          onClick={() => navigate("/")}
                        >
                          <div className="my-[8px] flex justify-center">
                            <img src={MyPageTopIcon} />
                          </div>
                          <div className="font-[500] text-[12px] text-white leading-[100%] flex justify-center">
                            マイページトップ
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: studentTheme.main_color_first,
                          }}
                          className={`w-[121px] h-[67.61px] border border-secondary-light rounded-[5px] cursor-pointer shadow-md bg-primary`}
                          onClick={() => navigate("/")}
                        >
                          <div className="my-[8px] flex justify-center">
                            <MdMenuBook size={30} />
                          </div>
                          <div className="font-[500] text-[12px] text-white leading-[100%] flex justify-center">
                            受講コース一覧
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex max-sm:flex-row flex-wrap gap-[8px]">
                        {LEARNING_TOOLS.map((tool, index) => {
                          return (
                            <div
                              className="border border-danger-light rounded-[5px] w-[47%] h-[67.61px] max-[320px]:w-[121px] cursor-pointer shadow-md"
                              key={tool.type}
                              onClick={() => navigate(tool.href)}
                            >
                              <div className="my-[8px] flex justify-center">
                                <LearningToolType type={tool.type} />
                              </div>
                              <div className="font-[500] text-[12px] leading-[100%] flex justify-center text-secondary">
                                {tool.label}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* Menu */}
                    <Menu
                      className={`!w-full mt-[15px] text-[16px] ${
                        !isOpenMenu && "hidden"
                      } z-[103]`}
                      isMobile={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Breadcrumb />
      {(isOpenProfile || isOpenMenu) && (
        <div
          className={`max-sm:fixed top-0 left-0 w-full h-full bg-[#00000066] z-[100] ${
            isOpenProfile ? "z-[102]" : ""
          }`}
          onClick={() => {
            setIsOpenProfile(false);
            setIsOpenMenu(false);
          }}
        />
      )}
      {isOpenProfile && (
        <div
          className={`max-sm:hidden fixed top-0 left-0 w-full h-full`}
          onClick={() => {
            setIsOpenProfile(false);
          }}
        />
      )}
    </>
  );
};

export default Header;
