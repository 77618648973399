import StudyToolBase from ".";
import ReportBoard from "../../../../components/student/StudyTool/Report/ReportBoard";

const ReportPage = () => {
  return (
    <StudyToolBase>
      <ReportBoard />
    </StudyToolBase>
  );
};


export default ReportPage;