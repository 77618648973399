import React, { useState } from "react";
import Button from "../components/Login/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalError, swalMessage, swalSuccess } from "../services/helpers/swal";
import AuthLayout from "../layouts/AuthLayout";
import { useObjectRoutes } from "../hooks/useObjectRoutes";
import { ErrorData } from "../types/globals";
import ErrorBox from "../components/commons/form/ErrorBox";
import { useStorage } from "../hooks/useStorage";

const VerifyEmail = () => {
  let navigate = useNavigate();
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  const [errors, setErrors] = useState<ErrorData>({});
  // check token for remember or has been login before (session)
  const { getParamValue } = useObjectRoutes();
  let accessToken = getParamValue("token");
  const [token, setToken] = useState(accessToken);
  const [verificationCode, setVerificationCode] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isSubmittingCode, setIsSubmittingCode] = useState<boolean>(false);

  const handleSendCodeClick = () => {
    setIsSendingCode(true);
    // send verification code to the user's email
  };

  const handleVerificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = event.target.value;
    setVerificationCode(code);
    if (code.length > 0) {
      setIsSendingCode(false);
      setIsSubmittingCode(true);
    } else {
      setIsSendingCode(true);
      setIsSubmittingCode(false);
    }
  };

  const handleSubmitCodeClick = async () => {
    setIsSubmittingCode(true);
    if (getParamValue("type") && getParamValue("type") === "change_email") {
      const result = await makeRequest({
        method: "post",
        url: API.AUTH.VERIFY_CHANGE_EMAIL,
        data: { code: verificationCode, token: token },
      });

      if (!result.status) {
        setErrors(result.error as ErrorData);
        swalMessage("", result.message, "error");
        return;
      }

      swalMessage("成功", "確認が完了しました。", "success", {
        timer: 1500,
        showConfirmButton: false,
      });
      setTimeout(() => navigate(ROUTE.LOGIN), 1500);
      return;
    }

    const result = await makeRequest({
      method: "post",
      url: API.AUTH.VERIFY_CHANGE_EMAIL,
      data: { code: verificationCode, token: token },
    });
    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError("確認コードが間違っています");
    }

    swalSuccess();
    navigate(ROUTE.LOGIN);
  };

  const handleRedirectResendEmail = () => {
    let url = `/${ROUTE.RESEND_VERIFY}`;
    if (getParamValue("type") && getParamValue("type") === "change_email") {
      const type = getParamValue("type");
      url += `?token=${accessToken}&type=${type}`;
    }

    navigate(url);
  };

  return (
    <>
      <AuthLayout>
        <>
          <div
            style={{ color: studentTheme.text_color }}
            className="mb-[45px] mt-[30px] mx-auto max-sm:mx-[10px] max-sm:mt-[25px]"
          >
            <div className="w-[550px] max-sm:w-full mb-[30px] bg-white px-[25px] border border-warning-light">
              <p className="mt-[30px] mb-[20px] text-[15px] max-sm:mt-[25px]">
                メール確認
              </p>
              <p className="mt-[30px] mb-[4px] text-[12px] max-sm:mt-[25px]">
                メールを確認してください。
              </p>
              <p className="text-[12px] px-[30px] leading-[22.8px] mb-[20px]">
                メール受信箱を確認し、下記に確認コードを入力してあなたのメールアドレス
                <br />
                を確認してください。
              </p>
              <div className="mb-[40px] max-sm:mb-[22px]">
                <input
                  type="text"
                  placeholder="6桁のコードを入力"
                  onChange={handleVerificationCodeChange}
                  value={verificationCode}
                  name="password"
                  className="min-h-[50px] border border-warning-light max-sm:border-danger-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F] placeholder:text-sm"
                  required
                />
              </div>
              <div className="flex flex-col items-center mb-[15px]">
                {!isSubmittingCode ? (
                  <Button
                    content={"確認"}
                    otherstyle={
                      "bg-secondary-light text-white mb-[10px] max-sm:mb-[20px] max-sm:text-[18px]"
                    }
                    type={"button"}
                    disabled={!isSubmittingCode}
                  />
                ) : (
                  <Button
                    style={{ backgroundColor: studentTheme.main_color_first }}
                    content={"確認"}
                    otherstyle={
                      "bg-primary text-white mb-[10px] max-sm:text-[18px]"
                    }
                    type={"button"}
                    disabled={!isSubmittingCode}
                    onClick={handleSubmitCodeClick}
                  />
                )}
                <Button
                  style={{ backgroundColor: studentTheme.main_color_first }}
                  content={"コードを再信"}
                  otherstyle={
                    "bg-primary text-white mb-[10px] max-sm:text-[18px]"
                  }
                  type={"button"}
                  onClick={handleRedirectResendEmail}
                />
                {errors && Object.keys(errors).length > 0 && (
                  <ErrorBox errors={errors} />
                )}
              </div>
            </div>
          </div>
        </>
      </AuthLayout>
    </>
  );
};

export default VerifyEmail;
