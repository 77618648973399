import { EventSchedule } from "../../../../types/student/event";
import { useSearchParams } from "react-router-dom";
import { MdOutlineCreate } from "react-icons/md";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { isUsableArr } from "../../../../services/helpers/etc";
import EventListItem from "./EventListItem";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import { STATE_EVENT_FORM } from "../../../../services/constants/student/event";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { makeRequest, request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { swalError, swallConfirm } from "../../../../services/helpers/swal";
import { useState, useEffect } from "react";
import { ErrorData } from "../../../../types/globals";
import { DELETE_CONFIRM_TITLE } from "../../../../services/constants/message";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";
import dayjs from "dayjs";

type EventListProps = {
  currentDate: Date | string;
  setIsHandleEvent: React.Dispatch<React.SetStateAction<boolean>>;
};

const EventList = ({ currentDate, setIsHandleEvent }: EventListProps) => {
  const [scheduleEvents, setScheduleEvents] = useState<EventSchedule[]>([]);
  const [, setErrors] = useState<ErrorData>({});
  const [, setSearchParams] = useSearchParams();
  const { searchParamsObject } = useObjectRoutes();
  const { studentTheme } = useStudentThemeContext();

  useEffect(() => {
    const eventsInDayParamsObj = {
      date: dayjs(currentDate).format(),
    };

    const eventsInDay = async () => {
      await request.get(
        `${API.SCHEDULE.LIST}?${paramizeObject(eventsInDayParamsObj)}`,
        setScheduleEvents
      );
    };

    eventsInDay();
  }, [currentDate]);

  const handleDeleteScheduleEvent = (id: number) => {
    swallConfirm(async () => {
      const result = await makeRequest({
        method: "delete",
        url: API.SCHEDULE.DELETE_SCHEDULE_EVENT,
        data: {
          schedule_event_id: id,
        },
      });

      if (!result.status) {
        swalError();
        return;
      }

      setScheduleEvents(scheduleEvents.filter((event) => event.id !== id));
      setIsHandleEvent(true);
    }, DELETE_CONFIRM_TITLE.SHEDULE_EVENT);
  };

  return (
    <section>
      <div className="flex justify-between mb-[14px]">
        <div className="font-[500] leading-[100%] min-h-0">
          <div className="text-[14px] tracking-[0.28px] mb-[14px]">
            予定を確認
          </div>
          <div className="text-secondary text-[12px] tracking-[0.24px]">
            {formatDateTime(currentDate, TYPE_DATE_FORMAT.SHORT_DEFAULT)}
          </div>
        </div>
        <div
          style={{ backgroundColor: studentTheme.main_color_first }}
          className="flex items-center justify-center gap-[6.8px] w-[140px] h-0 min-h-[37px] bg-primary text-white rounded-[19px] cursor-pointer"
          onClick={() =>
            setSearchParams({
              ...searchParamsObject,
              state: STATE_EVENT_FORM.CREATE,
            })
          }
        >
          <div className="">
            <MdOutlineCreate />
          </div>
          <div className="text-[12px] font-[700] tracking-[0.2px]">
            予定を追加する
          </div>
        </div>
      </div>

      <div className="w-[95%] h-[1px] bg-danger-light"></div>

      {/* Event list */}
      {isUsableArr(scheduleEvents) &&
        scheduleEvents.map((event) => (
          <EventListItem
            event={event}
            key={event.id}
            handleDelete={handleDeleteScheduleEvent}
          />
        ))}
    </section>
  );
};

export default EventList;
