import React from "react";
import { Link } from "react-router-dom";
import {
  ADMIN_MENU_BORDER_INDEX,
  ADMIN_MENU_DATA,
} from "../../services/constants/admin/menu";
import { IconType } from "react-icons";

const Menu = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => {
  return (
    <div
      {...props}
      className={`w-[220px] bg-primary text-white px-[10px] py-[33px] rounded-[5px] ${props.className}`}
    >
      {ADMIN_MENU_DATA.map((item, index) => (
        <div
          key={index}
          className={`mb-[15px] cursor-pointer ${
            ADMIN_MENU_BORDER_INDEX.includes(index) &&
            "pb-[15px] border-b border-white"
          }`}
        >
          <Item
            key={index}
            label={item.label}
            href={item.href}
            icon={item.icon}
          />
        </div>
      ))}
    </div>
  );
};

export default Menu;

const Item = ({
  label,
  href,
  icon,
}: {
  label: string;
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>> | React.FC | IconType;
}) => {
  const IconComponent = icon;
  return (
    <div className={`flex items-center justify-start gap-[10px]`}>
      <IconComponent color="#fff" width={23} height={23} />
      <Link to={href}>{label}</Link>
    </div>
  );
};
