import React, { useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Pagination from "../../student/Pagination";
import { File } from "../../../../types/admin/folder";
import PreviewFileModal from "../../commons/PreviewFileModal";
import MP3 from "../../../../assests/images/icons/MP3.svg";
import MP4 from "../../../../assests/images/icons/MP4.svg";
import PDF from "../../../../assests/images/icons/PDF.svg";
import { convertTimestampToDateTime } from "../../../../services/helpers/formatTime";
import dayjs from "dayjs";
const FileTable = ({
  handlePageChange,
  currentPage,
  toggleSelect,
  selectAll,
  toggleSelectAll,
  isFileSelected,
  files,
  totalPages,
  handleFileOverwrite,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  toggleSelect: (id: number) => void;
  selectAll: boolean;
  toggleSelectAll: () => void;
  isFileSelected: (id: number) => boolean;
  files: File[];
  totalPages: number;
  handleFileOverwrite: (file: File) => void;
}) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [source, setSource] = useState<string>("");
  const [fileTypeSpe, setFileTypeSpe] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const handlePreviewFile = (
    source: string,
    fileType: string,
    filename: string
  ) => {
    setFileTypeSpe(fileType);
    setSource(source);
    setFileName(filename);
    setIsOpenModal(true);
  };

  const fileUpdateFormat = (fileUpdatedAt?: string) => {
    if (!fileUpdatedAt) return "";
    return dayjs(fileUpdatedAt).format("YYYY/MM/DD HH:mm");
  };
  return (
    <>
      <div className="flex items-center justify-center overflow-hidden">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="2xl:px-[10px] xl:px-[5px] lg:px-0 px-0 py-[2px] border-x-4 border-white whitespace-nowrap "
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
              </th>
              <th
                scope="col"
                className="2xl:px-[10px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                プレビュー
              </th>
              <th
                scope="col"
                className="2xl:px-[180px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ファイル名
              </th>
              <th
                scope="col"
                className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                更新日
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                作成者
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                形式
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                サイズ
              </th>
              <th
                scope="col"
                className="2xl:px-[5px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ステータス
              </th>
              <th
                scope="col"
                className="2xl:px-[10px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                差替
              </th>
            </tr>
          </thead>
          <tbody>
            {files.map((file) => (
              <tr
                key={file.id}
                className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
              >
                <td className="text-center">
                  <input
                    type="checkbox"
                    checked={isFileSelected(file.id)}
                    onChange={() => toggleSelect(file.id)}
                  />
                </td>
                {file.resource_link === null && (
                  <td className="flex justify-center pt-[10px]"></td>
                )}
                {file.content_type === "pdf" && (
                  <td
                    className="py-[5px]"
                    onClick={() =>
                      handlePreviewFile(
                        file.resource_link ? file.resource_link : "",
                        file.content_type ? file.content_type : "",
                        file.origin_filename ? file.origin_filename : ""
                      )
                    }
                  >
                    <div className="flex justify-center items-center">
                      <img src={PDF} className="max-w-[40px] cursor-pointer" />
                    </div>
                  </td>
                )}
                {(file.content_type === "png" ||
                  file.content_type === "jpeg" ||
                  file.content_type === "jpg") && (
                  <td
                    className="py-[5px]"
                    onClick={() =>
                      handlePreviewFile(
                        file.resource_link ? file.resource_link : "",
                        file.content_type ? file.content_type : "",
                        file.origin_filename ? file.origin_filename : ""
                      )
                    }
                  >
                    <div className="flex justify-center items-center">
                      <img
                        src={`${file.resource_link}?${Date.now()}`}
                        width={40}
                        className="cursor-pointer"
                      />
                    </div>
                  </td>
                )}
                {(file.content_type === "mp4" ||
                  file.content_type === "mov") && (
                  <td
                    className="py-[5px]"
                    onClick={() =>
                      handlePreviewFile(
                        file.resource_link ? file.resource_link : "",
                        file.content_type ? file.content_type : "",
                        file.origin_filename ? file.origin_filename : ""
                      )
                    }
                  >
                    <div className="flex justify-center items-center">
                      <img src={MP4} width={40} className="cursor-pointer" />
                    </div>
                  </td>
                )}
                {file.content_type === "mp3" && (
                  <td
                    className="py-[5px]"
                    onClick={() =>
                      handlePreviewFile(
                        file.resource_link ? file.resource_link : "",
                        file.content_type ? file.content_type : "",
                        file.origin_filename ? file.origin_filename : ""
                      )
                    }
                  >
                    <div className="flex justify-center items-center">
                      <img src={MP3} width={40} className="cursor-pointer" />
                    </div>
                  </td>
                )}

                <th className="px-6 py-4 text-[14px] font-[400] whitespace-nowrap max-w-[300px] truncate">
                  {file.origin_filename}
                </th>
                <td className="px-6 py-4 text-center">
                  {fileUpdateFormat(file.updated_at)}
                </td>
                <td className="px-6 py-4 text-center">{`${file.upload_by_user.first_name} ${file.upload_by_user.last_name}`}</td>
                <td className="px-6 py-4 text-center">{file.content_type}</td>
                <td className="px-6 py-4 text-center">{`${file.size}MB`}</td>
                {file.is_used ? (
                  <td className="py-4">
                    <div className="flex justify-center items-center">
                      <div className="max-w-[70px] min-w-[70px] flex text-white bg-primary-light rounded-[5px] whitespace-nowrap relative justify-center items-center">
                        <p>使用中</p>
                      </div>
                    </div>
                  </td>
                ) : (
                  <td className="py-4">
                    <div className="flex justify-center items-center">
                      <div className="max-w-[70px] min-w-[70px] flex text-danger border border-danger rounded-[5px] whitespace-nowrap relative justify-center items-center">
                        <p>未使用</p>
                      </div>
                    </div>
                  </td>
                )}
                <td className="">
                  <div
                    className="flex justify-center items-center cursor-pointer"
                    onClick={() => handleFileOverwrite(file)}
                  >
                    <BiDotsVerticalRounded size={25} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isOpenModal && (
        <PreviewFileModal
          setIsOpen={setIsOpenModal}
          src={source}
          fileType={fileTypeSpe}
          fileName={fileName}
        />
      )}
      {totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default FileTable;
