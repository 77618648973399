import { useEffect, useState } from "react";
import TableAction from "./TableAction";
import Table from "./Table";
import { Coupon } from "../../../../types/admin/coupons";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { paramizeObject } from "../../../../services/helpers/parseData";
import {
  swalError,
  swalSuccess,
  swallConfirm,
} from "../../../../services/helpers/swal";

type Filter = {
  limit: number;
  page: number;
};

const CouponList = () => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [filter, setFilter] = useState<Filter>({
    limit: 10,
    page: 1,
  });

  const handleDelete = async () => {
    if (!selectedIds.length) return;
    swallConfirm(async () => {
      await request.delete(
        API.ADMIN_COUPON.DELETE,
        { coupon_ids: selectedIds },
        () => {
          setCoupons(
            coupons.filter((coupon) => !selectedIds.includes(coupon.id || 0))
          );

          swalSuccess();
          changePaginate(1);
          setSelectedIds([]);
        },
        (error) => swalError()
      );
    }, "このクーポンを削除します。<br>よろしいですか？");
  };

  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  const isCouponSelected = (id: number) => selectedIds?.includes(id);

  const toggleSelect = (id: number) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await request.get(
        `${API.ADMIN_COUPON.LIST}?${paramizeObject(filter)}`,
        (data) => {
          setCoupons(data["coupons"]);
          setTotalPages(data["total_pages"]);
        }
      );
    };

    fetch();
  }, [filter]);

  return (
    <>
      <TableAction handleDelete={handleDelete} />
      <Table
        coupons={coupons}
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        isCouponSelected={isCouponSelected}
        toggleSelect={toggleSelect}
      />
    </>
  );
};

export default CouponList;
