import React, { useState, useEffect, ChangeEvent } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { SetStateAction } from "../../../../../types/globals";
import { StudentThemeType } from "../../../../../types/admin/student_theme";
import Button from "../../../../commons/buttons/Button";

type StudentThemeItemType = {
  label: string;
  color: string;
  colorDefault: string;
  inputName: string;
  studentThemeData: StudentThemeType;
  setStudentThemeData: SetStateAction<StudentThemeType>;
};

const StudentThemeItem = ({
  label,
  color,
  colorDefault,
  inputName,
  studentThemeData,
  setStudentThemeData,
}: StudentThemeItemType) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [isMouseEnter, setIsMouseEnter] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>(color);

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleDocumentClick = () => {
    if (showColorPicker && !isMouseEnter) {
      setShowColorPicker(false);
    }
  };

  const handleChangeComplete = (color: ColorResult) => {
    if (color.hex === backgroundColor) {
      setStudentThemeData({ ...studentThemeData, [inputName]: color.hex });
    }
  };

  const handleChange = (color: ColorResult) => {
    setBackgroundColor(color.hex);
    setStudentThemeData({ ...studentThemeData, [inputName]: color.hex });
  };

  const handleChangeData = (e: ChangeEvent<HTMLInputElement>) => {
    const inputCurrent = e.currentTarget;
    setBackgroundColor(inputCurrent.value);
    setStudentThemeData({
      ...studentThemeData,
      [inputCurrent.name]: inputCurrent.value,
    });
  };

  const handleChangeColorDefault = () => {
    setStudentThemeData({ ...studentThemeData, [inputName]: colorDefault });
    setBackgroundColor(colorDefault);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick, true);
    return () => {
      document.removeEventListener("click", handleDocumentClick, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMouseEnter, showColorPicker]);

  useEffect(() => {
    setBackgroundColor(color);
  }, [color]);

  return (
    <div className="flex items-center gap-[13px] mb-[20px]">
      <div className="w-[139px] text-[12px]">{label}</div>
      <div
        className="w-[60px] h-[30px]"
        style={{ backgroundColor: backgroundColor }}
      ></div>
      <div className="text-secondary-dark">
        <input
          className="text-[13px] text-secondary leading-[100%] px-[11px] py-[8px] w-[90px] h-[30px] border-[#EFF1F0] focus:border-transparent"
          name={inputName}
          value={backgroundColor}
          onChange={handleChangeData}
          onClick={toggleColorPicker}
        />
        {showColorPicker && (
          <div className="relative">
            <div
              className="absolute bottom-[30px] left-[-40px] z-10"
              onMouseEnter={() => setIsMouseEnter(true)}
              onMouseLeave={() => setIsMouseEnter(false)}
            >
              <SketchPicker
                color={backgroundColor}
                onChangeComplete={handleChangeComplete}
                onChange={handleChange}
                disableAlpha={true}
                presetColors={[]}
                className="h-[225px] w-[247px] shadow-md border border-transparent"
              />
            </div>
            <div className="absolute bottom-[22px] left-[70px] w-[16px] h-[16px] bg-white border border-transparent border-r-secondary-light border-b-secondary-light z-[100] rotate-45"></div>
          </div>
        )}
      </div>

      <div>
        <Button
          className="text-[12px] text-white leading-[12px] px-[10px] py-[6px] bg-secondary rounded-[5px]"
          onClick={handleChangeColorDefault}
        >
          デフォルトに戻す
        </Button>
      </div>
    </div>
  );
};

export default StudentThemeItem;
