import React, { FC } from "react";
import logo_login from "../../assests/images/logo2_LMS.webp";
import classNames from "classnames";

interface DivProps {
  otherStyle?: string;
  logo_login_url?: string;
  imgStyle?: string;
}

const Logo: FC<DivProps> = (props) => {
  return (
    <div
      className={classNames({
        "w-[162px] h-[120px]": true,
        [`${props.otherStyle}`]: props.otherStyle !== null,
      })}
    >
      <img
        className={`w-full h-full ${props.imgStyle ? props.imgStyle : ""} `}
        src={props.logo_login_url ?? logo_login}
        alt="None"
      />
    </div>
  );
};

export default Logo;
