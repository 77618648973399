import { FormEvent, useEffect, useState } from "react";
import FormItem from "../commons/FormItem";
import { ChangeEvent } from "preact/compat";
import { Coupon } from "../../../types/admin/coupons";
import { LuTrash2 } from "react-icons/lu";
import { makeRequest, request } from "../../../services/axios/axios";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../../services/constants/route/api";
import SubmitButton from "../commons/SubmitButton";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";
import { PATH_ADMIN_COUPON } from "../../../services/constants/route/router";
import {
  swalLoading,
  swalMessage,
  swallConfirm,
} from "../../../services/helpers/swal";

const DISCOUNT_RATE = ["円", "%"];

const CouponUpsert = () => {
  const [formData, setFormData] = useState<Coupon>({
    type: 0,
  });
  const [errors, setErrors] = useState<ErrorData>({});
  const params = useParams();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleChangeForm = (
    e: FormEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>
  ) => {
    const currentInput = e.currentTarget;
    let newFormData = formData;
    switch (currentInput.type) {
      case "number":
        newFormData = {
          ...newFormData,
          [currentInput.name]:
            Number(currentInput.value) === 0
              ? null
              : Number(currentInput.value),
        };
        break;
      case "radio":
        newFormData = {
          ...newFormData,
          [currentInput.name]: Number(currentInput.value),
        };
        break;
      default:
        newFormData = {
          ...newFormData,
          [currentInput.name]: currentInput.value,
        };
        break;
    }

    setFormData(newFormData);
  };

  const handleSubmit = async () => {
    swalLoading();
    setDisabled(true);
    const endpoint = params.id
      ? API.ADMIN_COUPON.UPDATE
      : API.ADMIN_COUPON.CREATE;
    const method = params.id ? "patch" : "post";

    const result = await makeRequest({
      method: method,
      url: endpoint,
      data: formData,
    });

    if(result.code === 500) {
      swalMessage("", result.message, "error", {
        timer: 2000,
        showConfirmButton: false,
      });
      setDisabled(false);
      return;
    }

    if (result.code === 422) {
      setErrors({ ...result.error });
      setDisabled(false);
      return;
    }
    setDisabled(false);

    navigate(PATH_ADMIN_COUPON.DEFAULT);
  };

  const handleDeleteCoupon = async () => {
    swallConfirm(async () => {
      await request.delete(
        API.ADMIN_COUPON.DELETE,
        { coupon_ids: [Number(params.id)] },
        () => navigate(PATH_ADMIN_COUPON.DEFAULT),
        (error) => setErrors(error)
      );
    }, "このクーポンを削除します。<br>よろしいですか？");
  };

  useEffect(() => {
    if (params.id) {
      const fetchCouponDetail = async () => {
        await request.get(
          `${API.ADMIN_COUPON.DETAIL}?coupon_id=${params.id}`,
          (coupon) => setFormData(coupon),
          (error) => {
            setErrors(error);
            navigate(PATH_ADMIN_COUPON.DEFAULT);
          }
        );
      };

      fetchCouponDetail();
    }
  }, []);

  return (
    <>
      <div className="my-[20px]">
        {errors && Object.keys(errors).length > 0 && (
          <ErrorBox errors={errors} />
        )}
        <div className="mb-[10px]">クーポン新規登録 </div>
        <form>
          <FormItem withRequired={true} label="コード">
            <input
              type="text"
              name="code"
              className="h-[30px]"
              onChange={handleChangeForm}
              value={formData?.code || ""}
            />
          </FormItem>
          <FormItem withOptional={true} label="発行枚数">
            <input
              type="number"
              name="quantity"
              className="max-w-[110px]"
              onChange={handleChangeForm}
              value={formData?.quantity || ""}
            />
          </FormItem>
          <FormItem withOptional={true} label="対象者">
            <input
              type="text"
              name="object_of_use"
              className="h-[30px]"
              onChange={handleChangeForm}
              value={formData?.object_of_use || ""}
            />
          </FormItem>
          <FormItem withRequired={true} label="割引率">
            <div className="flex flex-col gap-[10px]">
              <div className="flex items-center gap-[20px]">
                <div className="flex items-center gap-[5px]">
                  <input
                    className="text-primary-light"
                    onChange={handleChangeForm}
                    id="amount_of_money"
                    name="type"
                    type="radio"
                    value={0}
                    checked={formData.type === 0}
                  />
                  <label
                    htmlFor="amount_of_money"
                    className="text-[14px] text-secondary-dark font-[500] ml-[5px]"
                  >
                    金額
                  </label>
                </div>
                <div className="flex items-center gap-[5px]">
                  <input
                    className="text-primary-light"
                    onChange={handleChangeForm}
                    id="rate"
                    name="type"
                    type="radio"
                    value={1}
                    checked={formData.type === 1}
                  />
                  <label
                    htmlFor="rate"
                    className="text-[14px] text-secondary-dark font-[500] ml-[5px]"
                  >
                    率
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="number"
                  name="amount"
                  className="w-[185px] h-[30px] border-1 border-success-lighter"
                  onChange={handleChangeForm}
                  value={formData?.amount || ""}
                />
                <label
                  htmlFor=""
                  className="w-[30px] h-[30px] px-[9px] py-[5px] rounded-tr-[4px] rounded-br-[4px] bg-success-extralight text-secondary-dark text-[12px] text-center ml-[-30px]"
                >
                  {DISCOUNT_RATE[formData.type || 0]}
                </label>
              </div>
            </div>
          </FormItem>
          <FormItem withOptional={true} label="使用回数制限">
            <input
              type="number"
              name="usage_limit"
              className="w-[110px] h-[30px]"
              onChange={handleChangeForm}
              value={formData?.usage_limit || ""}
            />
            <p className="text-[12px] text-secondary-dark font-[400] mt-[5px]">
              １ユーザーあたりの同一クーポン使用回数制限です。無制限の場合は空欄にしてください。
            </p>
          </FormItem>
        </form>
      </div>
      <div className="flex gap-[10px] items-center justify-center pt-[26px]">
        {params.id && (
          <div
            className="flex items-center gap-[10px] px-[30px] py-[6px] border border-secondary rounded-[10px] cursor-pointer"
            onClick={handleDeleteCoupon}
          >
            <LuTrash2 size={24} className="text-secondary" />
            <button
              type="button"
              className="text-[16px] text-secondary font-[500]"
            >
              このクーポンを削除
            </button>
          </div>
        )}
        <div className="">
          <button
            type="button"
            className="py-[6px] px-[50px] bg-secondary-light rounded-[10px] text-[16px] text-white font-[700]"
            onClick={() => navigate(PATH_ADMIN_COUPON.DEFAULT)}
          >
            キャンセル
          </button>
        </div>
        <div className="">
          <SubmitButton
            label="設定を保存"
            onSubmit={handleSubmit}
            className={`!rounded-[10px]`}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

export default CouponUpsert;
