import { Link } from "react-router-dom";
import CartMini from "../icons/CartMini";
import { PATH_STUDENT_CART } from "../../../services/constants/route/router";
import { useCartContext } from "../../../context/CartContext";
import { useFeaturePurchasedContext } from "../../../context/FeaturePurchasedContext";
import { useStorage } from "../../../hooks/useStorage";

const CartButton = () => {
  const { totalProduct, cartItems } = useCartContext();
  let totalQuantity = 0;
  cartItems.map((item) => {
    totalQuantity+= Number(item.quantity)
  })
  // const { featurePurchased } = useFeaturePurchasedContext();
  const { getLocalStorage } = useStorage();
  const paymentFeature = getLocalStorage("payment_feature") || false;

  return (
    <Link
      to={PATH_STUDENT_CART.DEFAULT}
      className={`mt-[-2px] rounded-[2px] ${paymentFeature ? "" : "hidden"}`}
    >
      <button
        className={`relative text-white bg-danger flex items-center justify-between p-[5px] rounded-[2px] ${
          totalProduct > 0
            ? "w-[65px] h-[34px] gap-[10px]"
            : "w-[60px] h-[20px]"
        }`}
      >
        <CartMini color="white" className="mb-[-4px]" />
        {totalProduct > 0 && (
          <span
            className={`absolute top-[4px] left-[15px] w-[18px] text-[10px] text-danger text-center rounded-[10px] px-[1px] bg-white`}
          >
            {totalQuantity + (totalQuantity > 99 ? "+" : "")}
          </span>
        )}
        <span className="font-[500] text-[12px] leading-[100%]">購入</span>
      </button>
    </Link>
  );
};

export default CartButton;
