import { RouterProvider } from "react-router-dom";
import "./App.css";
import "./assests/styles/globals.css";
import AuthProvider from "./context/AuthContext";
import { routes } from "./routes";
import LoginSettingContextProvider from "./context/LoginSettingContext";
import SidebarContextProvider from "./context/SidebarContext";

function App() {
  return (
    <AuthProvider>
      <LoginSettingContextProvider>
        <SidebarContextProvider>
          <RouterProvider router={routes} />
        </SidebarContextProvider>
      </LoginSettingContextProvider>
    </AuthProvider>
  );
}

export default App;
