import { useEffect, useState } from "react";
import Book from "../../commons/icons/Book";
import BookOpen from "../../commons/icons/BookOpen";
import Calendar from "../../commons/icons/Calendar";
import CircleCheck from "../../commons/icons/CircleCheck";
import Hamburger from "../../commons/icons/Hamburger";
import History from "../../commons/icons/History";
import Home from "../../commons/icons/Home";
import Pen from "../../commons/icons/Pen";
import { SidebarItemCounter } from "../../../types/globals";
import SidebarGroupItem from "./SidebarGroup";
import SidebarItem from "./SidebarItem";
import { Course } from "../../../types/student/course";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { PATH_COURSE, PATH_PRODUCT, PATH_STUDY_TOOL, } from "../../../services/constants/route/router";
import { Link } from "react-router-dom";
import Cart from "../../commons/icons/Cart";
import { useStorage } from "../../../hooks/useStorage";
import LayoutWaitingApi from "../common/LayoutWaitingApi";
import { getCommonDate } from "../../../services/helpers/date";
import classNames from "classnames";

const Sidebar = () => {
  const [courseList, setCourseList] = useState<Course[]>([]);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const { getLocalStorage, setLocalStorage } = useStorage();
  const paymentFeature = getLocalStorage("payment_feature");
  const studentTheme = getLocalStorage("theme");
  const courses = getLocalStorage("courses_of_user");

  const fetchCourses = async () => {
    await request.get(API.COURSE.GET_LIST_COURSE_OF_USER, (courses) => {
      setCourseList(courses);
      setWaitingApi(false);
      setLocalStorage("courses_of_user", {
        courses: courses,
        date_reset_api: getCommonDate(new Date()),
      });
    });
  };

  useEffect(() => {
    if (courses) {
      const dateResetAt = courses.date_reset_at;
      const dateTime1: Date = new Date(dateResetAt);
      const dateTime2: Date = new Date(getCommonDate(new Date()));
      const differenceInHours: number =
        (dateTime2.getTime() - dateTime1.getTime()) / (1000 * 60 * 60);

      if (differenceInHours === 24) {
        fetchCourses();
      }
      setCourseList(courses.courses);
      setTimeout(() => setWaitingApi(false), 500);
    } else {
      fetchCourses();
    }
  }, []);

  const [sidebarItemCounter, setSidebarItemCounter] =
    useState<SidebarItemCounter>({
      comment: 12,
      mail: 12,
      announcement: 12,
    });

  return (
    <div
      style={{
        color: studentTheme.text_color,
      }}
      className={`w-[262px] bg-white pt-[10px] px-[12px] pb-[20px] max-sm:hidden max-md:hidden max-lg:hidden animate-[show_0.5s_ease-in-out]`}
    >
      <SidebarGroupItem className="border-b border-danger-light pb-[20px]">
        <SidebarItem
          icon={<Home className="mx-[12.25px]" />}
          label="マイページトップ"
          urlAttribute="/my-page"
          name="my-page"
        />
        <SidebarItem
          icon={<Hamburger className="mx-[12.25px]" />}
          label="受講コース一覧"
          urlAttribute="/my-page/course-list"
          name="my-page"
        />
        <SidebarItem
          icon={<History className="mx-[12.25px]" />}
          label="学習履歴"
          urlAttribute="/my-page/study-history"
          name="my-page"
        />
      </SidebarGroupItem>

      <LayoutWaitingApi waitingApi={waitingApi} className="w-full h-[100px]">
        <SidebarGroupItem
          label="受講コース"
          className="border-b border-danger-light pb-[20px] mt-[20px] max-h-[280px] overflow-y-auto animate-[show_0.5s_ease-in-out]"
          reloadCourse={true}
          handleRefetchCourses={fetchCourses}
        >
          {courseList.map((course) => (
            <SidebarItem
              icon={<Book className="mx-[12.25px]" />}
              label={course.name}
              urlAttribute={`${PATH_COURSE.DEFAULT}?course_id=${course.id}`}
              name="my-page"
              key={course.id}
              courseListId={course.id}
              style={{ scrollbarColor: studentTheme.main_color_first }}
            />
          ))}
        </SidebarGroupItem>
      </LayoutWaitingApi>
      {/* Sidebar items hidden for later phases  */}
      <SidebarGroupItem
        label="学習ツール"
        className="border-b border-danger-light pb-[30px] mt-[30px]"
      >
        <div className="mt-[15px] px-[9px]">
          <div className="flex gap-[10px] mb-[10px]">
            <Link to={PATH_STUDY_TOOL.REPORT}>
              <div className="border border-dander-light rounded-[5px] pt-[13.75px] w-[105px] h-[70px] text-center">
                <div className="flex justify-center">
                  <BookOpen color={studentTheme.sub_color_first} />
                </div>
                <div className="mt-[9.5px] font-[500] text-[12px] leading-[100%]">
                  学習レポート
                </div>
              </div>
            </Link>

            <Link to={PATH_STUDY_TOOL.SCHEDULE.DEFAULT}>
              <div className="border border-dander-light rounded-[5px] pt-[12.5px] w-[105px] h-[70px] text-center">
                <div className="flex justify-center">
                  <Calendar color={studentTheme.sub_color_first} />
                </div>
                <div className="mt-[9.5px] font-[500] text-[12px] leading-[100%]">
                  スケジュール
                </div>
              </div>
            </Link>
          </div>
          <div className="flex gap-[10px]">
            <Link to={PATH_STUDY_TOOL.MEMO}>
              <div className="border border-dander-light rounded-[5px] pt-[12.5px] w-[105px] h-[70px] text-center">
                <div className="flex justify-center">
                  <Pen color={studentTheme.sub_color_first} />
                </div>
                <div className="mt-[9.5px] font-[500] text-[12px] leading-[100%]">
                  メモ
                </div>
              </div>
            </Link>

            <Link to={PATH_STUDY_TOOL.REVISION_REVIEW}>
              <div className="border border-dander-light rounded-[5px] pt-[9.5px] w-[105px] h-[70px] text-center">
                <div className="flex justify-center">
                  <CircleCheck color={studentTheme.sub_color_first} />
                </div>
                <div className="mt-[9.5px] font-[500] text-[12px] leading-[100%]">
                  復習
                </div>
              </div>
            </Link>
          </div>
        </div>
      </SidebarGroupItem>
      <SidebarGroupItem
        label="機能一覧"
        className={classNames("border-b border-danger-light pb-[20px] mt-[20px]", {
          "hidden": paymentFeature == false
        })}
      >
        {/* <SidebarItem
        icon={<Comment className="mx-[12.25px]" />}
        label="コミュニティ"
        urlAttribute="/#"
        name="my-page"
        notificationCount={sidebarItemCounter.comment}
      />
      <SidebarItem
        icon={<Video className="mx-[12.25px]" />}
        label="zoom"
        urlAttribute="/#"
        name="my-page"
      />
      <SidebarItem
        icon={<IdCard className="mx-[12.25px]" />}
        label="出席管理"
        urlAttribute="/#"
        name="my-page"
      />
      <SidebarItem
        icon={<View className="mx-[12.25px]" />}
        label="学習集中度"
        urlAttribute="/#"
        name="my-page"
      />
      <SidebarItem
        icon={<File className="mx-[12.25px]" />}
        label="シラバス"
        urlAttribute="/#"
        name="my-page"
      /> */}
        <SidebarItem
          icon={<Cart className="mx-[12.25px]" />}
          label="講座購入"
          urlAttribute={PATH_PRODUCT.DEFAULT}
          name="my-page"
          className={classNames({
            "hidden": paymentFeature == false
          })}
        />
        {/* <SidebarItem
        icon={<AssignmentSubmit className="mx-[12.25px]" />}
        label="課題提出"
        urlAttribute="/#"
        name="my-page"
      />
      <SidebarItem
        icon={<AssignmentEvaluation className="mx-[12.25px]" />}
        label="課題評価"
        urlAttribute="/#"
        name="my-page"
      />
      <SidebarItem
        icon={<Mail className="mx-[12.25px]" />}
        label="受信ボックス"
        urlAttribute="/#"
        name="my-page"
        notificationCount={sidebarItemCounter.mail}
      /> */}
      </SidebarGroupItem>

      {/* <SidebarGroupItem className="mt-[20px]">
      <SidebarItem
        icon={<Announcement className="mx-[12.25px]" />}
        label="お知らせ"
        urlAttribute="/#"
        name="my-page"
        notificationCount={sidebarItemCounter.announcement}
      />
      <SidebarItem
        icon={<Setting className="mx-[12.25px]" />}
        label="設定"
        urlAttribute="/#"
        name="my-page"
      />
    </SidebarGroupItem> */}
    </div>
  );
};

export default Sidebar;
