import Home from '../pages';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import DomainLogin from '../pages/DomainLogin';
import { ROUTE } from '../services/constants/route/router';
import { RouteObject } from 'react-router';
import RedirectToSubdomain from "../pages/RedirectToSubdomain";
import FinishSubmitForgotPwd from "../pages/auth/FinishSubmitForgotPwd";
import UpdateNewPwd from "../pages/auth/UpdateNewPwd";
import VerifyEmail from '../pages/VerifyEmail';
import ResendVerification from '../pages/ResendVerification';
import Logout from '../pages/Logout';
import PaletteLogin from '../pages/auth/PaletteLogin';
import NotFoundPage from '../pages/404';
import CompleteStudentRegistration from '../pages/CompleteStudentRegistration';

type CustomRouterObject = {};

const  userRouter: Array<RouteObject & CustomRouterObject> = [
  {
    path: ROUTE.HOME,
    element: <Home/>,
  },
  {
    path: ROUTE.DOMAIN_LOGIN,
    element: <DomainLogin/>,
  },
  {
    path: ROUTE.LOGIN,
    element: <Login/>,
  },
  {
    path: ROUTE.LOGOUT,
    element: <Logout/>,
  },
  {
    path: ROUTE.FORGOT_PASSWORD,
    element: <ForgotPassword/>,
  },
  {
    path: ROUTE.VERIFY_EMAIL,
    element: <VerifyEmail/>,
  },
  {
    path: ROUTE.RESEND_VERIFY,
    element: <ResendVerification/>,
  },
  {
    path: ROUTE.GG_CALLBACK,
    element: <Home/>,
  },
  {
    path: `${ROUTE.REDIRECT}/:path`,
    element: <RedirectToSubdomain/>
  },
  {
    path: ROUTE.SUBMIT_FORGOT_PASSWORD_DONE,
    element: <FinishSubmitForgotPwd/>
  },
  {
    path: `${ROUTE.UPDATE_NEW_PWD}/:token`,
    element: <UpdateNewPwd/>
  },
  {
    path: `${ROUTE.PALETTE_LOGIN}/:token`,
    element: <PaletteLogin/>
  },
  {
    path: "*",
    element: <NotFoundPage/>
  },
  {
    path: ROUTE.COMPLETE_STUDENT_REGISTRATION,
    element: <CompleteStudentRegistration/>,
  },
];

export default userRouter;
