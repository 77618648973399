import { FiTrash2 } from "react-icons/fi";
import { CartItem } from "../../../types/student/cart";
import { formatNumberWithCommas } from "../../../services/helpers/parseData";
import { ChangeEvent } from "react";

type StudentCartTableProps = {
  className?: string;
  cartItems: CartItem[];
  toggleSelect?: (id: number) => void;
  isSelected?: (id: number) => boolean;
  handleDelete: (id: number) => void;
  handleChangeQuantity: (
    e: ChangeEvent<HTMLInputElement>,
    cartItemId: number
  ) => void;
};

const StudentCartTable = ({
  cartItems,
  className,
  toggleSelect,
  isSelected,
  handleDelete,
  handleChangeQuantity,
}: StudentCartTableProps) => {
  return (
    <div className={`${className}`}>
      <div className="border-b border-b-secondary-light">
        <table className="w-full text-[12px] font-[500] text-center">
          <thead>
            <tr className="h-[30px] bg-secondary-morelighter">
              <th className="px-[5px] w-[10%]"></th>
              {/* <th className="px-[5px]">カテゴリ</th> */}
              <th className="px-[5px] w-[50%]">商品名</th>
              <th className="px-[5px] w-[10%]">数量</th>
              <th className="w-[20%]">金額（税込）</th>
              <th className="px-[5px] w-[10%]">削除</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {cartItems &&
              cartItems.length > 0 &&
              cartItems.map((cartItem) => (
                <tr className="h-[80px]" key={cartItem.id}>
                  <td className="px-[5px]">
                    <input
                      type="checkbox"
                      value={cartItem.id}
                      checked={isSelected?.(cartItem?.id || 0)}
                      onChange={() => toggleSelect?.(cartItem?.id || 0)}
                    />
                  </td>
                  {/* <td className="px-[5px]">
                    <span className="text-[10px] text-secondary font-[700] bg-success-lighter px-[7px] py-[2px] rounded-[5px]">
                      eラーニング
                    </span>
                  </td> */}
                  <td className="max-w-[184px] text-left px-[5px]">
                    {cartItem.name}
                  </td>
                  <td className="w-[40px] px-[5px]">
                    <input
                      type="number"
                      className="w-[40px] !h-[36px] input-number-product p-0 !pl-0 m-0 text-[12px] text-center"
                      min={1}
                      max={999}
                      value={cartItem.quantity}
                      onChange={(e) =>
                        handleChangeQuantity(e, cartItem?.id || 0)
                      }
                    />
                  </td>
                  <td className="w-[65px] overflow-ellipsis text-right px-[5px]">
                    {formatNumberWithCommas(
                      cartItem.price * cartItem.quantity || 0
                    )}
                    円
                  </td>
                  <td
                    className="pl-[8px]"
                    onClick={() => handleDelete(cartItem?.id || 0)}
                  >
                    <button className="">
                      <FiTrash2 size={24} className="text-danger text-center" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StudentCartTable;
