import React from 'react';

type TableHeadItemProps = {
    children: React.ReactNode;
    className?: string;

}

const TableHeadItem = ({className, children}: TableHeadItemProps) => {
    return (
        <th scope="col" className={`${className} border-x-4 border-white whitespace-nowrap`}>
           {children} 
        </th>
    );
};

export default TableHeadItem;