import React, { useEffect, useRef, useState } from "react";
import { UnitForm } from "../../../../types/admin/unit";
import ResourceContentDisplay from "../../common/ResourceContentDisplay";
import UnitList from "./UnitList";
import DownloadZip from "../../common/DownloadZip";
import ParagraphExpander from "../../../admin/commons/ParagraphExpander";
import { UNIT_FILE_TYPE } from "../../../../services/constants/admin/pages/material";
import { useBeforeunload } from "react-beforeunload";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import UnitSubContent from "./UnitSubContent";
const UnitLesson = ({
  unitData,
  unitId,
}: {
  unitData: UnitForm;
  unitId: string | null;
}) => {
  const [initialTime, setInitialTime] = useState<number>(Date.now());
  const [resourceProgressTime, setResourceProgressTime] = useState<number>(0);
  const [widthResponsive, setWidthResponsive] = useState<number>(500);
  const resourceProgressTimeRef = useRef<number>(0);
  const sendTimeToUnitHistory = async () => {
    const currentTime = Date.now();
    await request.patch(
      API.UNIT.UPDATE_USER_HISTORY,
      {
        unit_id: unitId,
        time_learned: currentTime - initialTime,
        current_resource_time_point: Math.round(
          resourceProgressTimeRef.current
        ),
      },
      (success) => {
        setInitialTime(Date.now());
        setResourceProgressTime(0);
      },
      (error) => {
        setInitialTime(Date.now());
        setResourceProgressTime(0);
      }
    );
  };

  useEffect(() => {
    return () => {
      sendTimeToUnitHistory();
    };
  }, [unitId]);

  useEffect(() => {
    resourceProgressTimeRef.current = resourceProgressTime;
  }, [resourceProgressTime]);

  useBeforeunload((event) => {
    event.preventDefault();
    sendTimeToUnitHistory();
    return "Would you like to exit?";
  });

  const checkScreenWidth = () => {
    if (window.innerWidth > 375) {
      setWidthResponsive(500);
    } else {
      setWidthResponsive(widthResponsive);
    }
  };
  window.addEventListener("resize", checkScreenWidth);
  document.addEventListener("DOMContentLoaded", checkScreenWidth);
  window.addEventListener("load", checkScreenWidth);

  return (
    <section className="mb-[25px]">
      <div className="flex gap-[10px] mb-[15px] animate-[show_0.5s_ease-in-out] max-sm:flex-col">
        {(unitData.main_resource || unitData.main_resource_content) &&
        unitData.main_resource_type ? (
          <div className="max-sm:mx-[5px] max-sm:flex max-sm:items-center max-sm:justify-center">
            <ResourceContentDisplay
              width={widthResponsive}
              url={unitData.main_resource?.resource_link}
              resourceType={unitData.main_resource_type}
              downloadable={unitData.main_resource_downloadable}
              textContent={unitData.main_resource_content}
              setResourceProgressTime={setResourceProgressTime}
            />
          </div>
        ) : (
          <div className="w-full max-w-[500px] h-[283px]"></div>
        )}
        {(unitData.main_resource_type === UNIT_FILE_TYPE.TYPE_VIDEO ||
          unitData.main_resource_type === UNIT_FILE_TYPE.TYPE_AUDIO) && (
          <div className="sm:hidden max-sm:mx-[5px] h-[40px]">
            <DownloadZip
              url={unitData.main_resource?.resource_link}
              resourceType={unitData.main_resource_type}
              downloadable={unitData.main_resource_downloadable}
            />
          </div>
        )}
        <div className="w-full">
          <UnitList
            unitId={unitId}
            chapterId={unitData.chapter_id}
            chapterName={unitData.chapter_name}
          />
        </div>
      </div>
      {(unitData.main_resource_type === UNIT_FILE_TYPE.TYPE_VIDEO ||
        unitData.main_resource_type === UNIT_FILE_TYPE.TYPE_AUDIO) && (
        <div className="max-sm:hidden">
          <DownloadZip
            url={unitData.main_resource?.resource_link}
            resourceType={unitData.main_resource_type}
            downloadable={unitData.main_resource_downloadable}
          />
        </div>
      )}
      <div className="font-[400] leading-[22.8px] text-[12px] animate-[show_0.5s_ease-in-out]">
        <ParagraphExpander text={unitData.description || ""} />
      </div>
      {(unitData.sub_resource || unitData.sub_resource_content) &&
        unitData.sub_resource_type && (
          <UnitSubContent unitData={unitData} widthResponsive={widthResponsive}/>
        )}
    </section>
  );
};

export default UnitLesson;
