import * as React from 'react';

const CircleCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.914 3.125A12.488 12.488 0 0 0 15 2.5C8.096 2.5 2.5 8.096 2.5 15S8.096 27.5 15 27.5 27.5 21.904 27.5 15c0-1.306-.2-2.566-.572-3.75"
      stroke={`${props.color || '#8FC2D5'}`}
      strokeWidth={1.875}
      strokeLinecap="round"
    />
    <path
      d="M10 15.385S12.813 15 15 20c0 0 6.323-12.5 12.5-15"
      stroke={`${props.color || '#8FC2D5'}`}
      strokeWidth={1.875}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleCheck;
