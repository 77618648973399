import { ChartData } from "chart.js";
import BarChart, { BarChartProps } from "../../../commons/charts/BarChart";
import { useState, useEffect } from "react";
import { request } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { ReportFilter } from "./ReportBoard";
import { API } from "../../../../services/constants/route/api";
import { isEmptyObj } from "../../../../services/helpers/etc";
import dayjs from "dayjs";
import { swalMessage } from "../../../../services/helpers/swal";
import { MESSAGE_ERROR } from "../../../../services/constants/message";
import { useStorage } from "../../../../hooks/useStorage";

export type ChartStudyTimeProps = {
  filter?: ReportFilter;
  data?: BarChartProps["data"];
} & Omit<BarChartProps, "data">;

const ChartStudyTime = ({ filter, data, ...props }: ChartStudyTimeProps) => {
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  const initialChartData: ChartData<"bar"> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: studentTheme.main_color_first,
        borderRadius: 11,
        maxBarThickness: 43,
      },
    ],
  };
  const _setCharData = (
    labels: (typeof initialChartData)["labels"],
    data: (typeof initialChartData)["datasets"][0]["data"]
  ) => {
    setChartData({
      labels: labels || [],
      datasets: [
        {
          data: data || [],
          backgroundColor: studentTheme.main_color_first,
          borderRadius: 11,
          maxBarThickness: 43,
        },
      ],
    });
  };

  const [chartData, setChartData] = useState(initialChartData);

  useEffect(() => {
    if (!filter) return;
    if (dayjs(filter.start_date).diff(filter.end_date) > 0) {
      swalMessage("", MESSAGE_ERROR.END_DATE_BIGGER_START_DATE, "error", {
        confirmButtonColor: studentTheme.main_color_first,
      });
      return;
    }

    const fetch = async () => {
      await request.get(
        `${API.REPORT.GET_STUDY_TIME}?${paramizeObject(filter)}`,
        (res) => {
          if (isEmptyObj(res)) return;
          _setCharData(Object.keys(res) as string[], Object.values(res));
        }
      );
    };

    fetch();
  }, [filter]);

  return <BarChart data={data || chartData} {...props} />;
};

export default ChartStudyTime;
