import React from "react";
import type { FC } from "react";
import classNames from "classnames";
import Spinner from "../commons/icons/Animations/Spinner";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  otherstyle: string | null;
  children?: JSX.Element;
  content: string;
  isSubmitting?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
  return (
    <button
      {...props}
      className={classNames({
        "w-full sm:max-w-[430px] max-w-[230px] h-[50px] max-sm:h-[40px] rounded-lg font-semibold text-[1.25rem]":
          true,
        "opacity-50 pointer-events-none": props.isSubmitting,
        [`${props.otherstyle}`]: props.otherstyle !== null,
      })}
      disabled={props.isSubmitting}
    >
      <div className="flex justify-center items-center">
        {props.isSubmitting ? (
          <Spinner className="text-right" />
        ) : (
          <>{props.content}</>
        )}
      </div>
    </button>
  );
};

export default Button;
