import { formatDateTime } from "../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import {
  SECTION_TYPE_EVENT_FORM_DATEPICKER,
  UNIT_EVENT_FORM_DATEPICKER,
} from "../../../../services/constants/student/event";
import {
  ElementProps,
  SetStateAction,
  ValueOf,
} from "../../../../types/globals";
import { ShowPickerStatus } from "./EventForm";

type EventFormDatePickerProps = {
  section: ValueOf<typeof SECTION_TYPE_EVENT_FORM_DATEPICKER>;
  dateInput?: Date | string;
  onDisplayHandler?: (
    type: ValueOf<typeof SECTION_TYPE_EVENT_FORM_DATEPICKER>,
    unit: ValueOf<typeof UNIT_EVENT_FORM_DATEPICKER>
  ) => SetStateAction<ShowPickerStatus[]> | undefined;
  showStatuses?: ShowPickerStatus[];
  isWholeDay?: boolean;
  isDisabled?: boolean;
} & ElementProps<HTMLDivElement>;

const EventFormDatePicker = ({
  section,
  dateInput,
  onDisplayHandler,
  className,
  showStatuses,
  isWholeDay,
  isDisabled,
  ...props
}: EventFormDatePickerProps) => {
  const isCurrentDateSelecting = showStatuses?.some(
    (status) =>
      status.section === section &&
      status.unit === UNIT_EVENT_FORM_DATEPICKER.DATE
  );
  const isCurrentTimeSelecting = showStatuses?.some(
    (status) =>
      status.section === section &&
      status.unit === UNIT_EVENT_FORM_DATEPICKER.TIME
  );

  return (
    <div
      className={`flex items-center gap-[5px] text-[14px] font-[400] leading-[100%] cursor-pointer ${className}`}
      {...props}
    >
      <div
        className={`bg-success-lighter text-center w-[90px] pt-[5px] pb-[7px] rounded-[5px] ${
          isCurrentDateSelecting && !isWholeDay ? "text-danger" : "text-black"
        } ${isDisabled ? "text-secondary-light cursor-not-allowed" : ""}`}
        onClick={() =>
          onDisplayHandler?.(section, UNIT_EVENT_FORM_DATEPICKER.DATE)
        }
      >
        {formatDateTime(dateInput, TYPE_DATE_FORMAT.REGULAR_GLOBAL)}
      </div>
      <div
        className={`bg-success-lighter text-center w-[60px] pt-[5px] pb-[7px] rounded-[5px] ${
          // isCurrentTimeSelecting && isWholeday ? "text-danger" : "" //for-current-specs
          isCurrentTimeSelecting && !isWholeDay && section === "start"
            ? "text-danger"
            : ""
        } ${isWholeDay ? "text-secondary-light cursor-not-allowed" : ""}`}
        onClick={() =>
          onDisplayHandler?.(section, UNIT_EVENT_FORM_DATEPICKER.TIME)
        }
      >
        {formatDateTime(dateInput, TYPE_DATE_FORMAT.TIME_HOUR_MINUTE)}
      </div>
    </div>
  );
};

export default EventFormDatePicker;
