import { Lecture } from "../../../../types/admin/lecture";
import { LECTURE_STATUS } from "../../../../services/constants/student/course";
import { useNavigate } from "react-router-dom";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import {
  PATH_COURSE,
  PATH_PRODUCT,
} from "../../../../services/constants/route/router";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

const LectureListItem = ({ lectureData }: { lectureData: Lecture }) => {
  const navigate = useNavigate();
  const { getParamValue } = useObjectRoutes();
  const courseId = getParamValue("course_id");
  const handleRedirectProductDetail = (productId?: number | null) => {
    if (!productId) return;

    navigate(`${PATH_PRODUCT.DETAIL}?product_id=${productId}`);
  };
  const { studentTheme } = useStudentThemeContext();

  switch (lectureData.status) {
    case LECTURE_STATUS.LECTURE_STATUS_COMPLETED:
      return (
        <div
          style={{ color: studentTheme.main_color_first }}
          className={`min-h-[34px] border-b border-danger-light w-full text-primary px-5 text-[14px] font-[500] leading-[150%] mb-[8px] bg-white flex justify-between items-center`}
        >
          <span className="max-w-[300px] truncate">
            {lectureData.name || ""}
          </span>
          <div
            className="text-[12px] font-[500] leading-[150%] text-secondary border border-secondary flex items-center justify-center w-[50px] max-w-[86px] h-[20px] cursor-pointer"
            onClick={() =>
              navigate(
                `${PATH_COURSE.LECTURE_DETAIL}?lecture_id=${lectureData.id}&course_id=${courseId}`
              )
            }
          >
            修了
          </div>
        </div>
      );
    case LECTURE_STATUS.LECTURE_STATUS_LEARNING:
      return (
        <div
          style={{ color: studentTheme.main_color_first }}
          className={`min-h-[34px] border-b border-danger-light w-full text-primary px-5 text-[14px] font-[500] leading-[150%] mb-[8px] bg-white flex justify-between items-center`}
        >
          <span className="max-w-[300px] truncate">
            {lectureData.name || ""}
          </span>
          <div
            className="text-[12px] font-[500] leading-[150%] text-danger border border-danger flex items-center justify-center w-[50px] max-w-[86px] h-[20px] cursor-pointer"
            onClick={() =>
              navigate(
                `${PATH_COURSE.LECTURE_DETAIL}?lecture_id=${lectureData.id}&course_id=${courseId}`
              )
            }
          >
            学習中
          </div>
        </div>
      );
    case LECTURE_STATUS.LECTURE_STATUS_UNLEARNED:
      return (
        <div
          style={{ color: studentTheme.main_color_first }}
          className={`min-h-[34px] border-b border-danger-light w-full text-primary px-5 text-[14px] font-[500] leading-[150%] mb-[8px] bg-white flex justify-between items-center`}
        >
          <span className="max-w-[300px] truncate">
            {lectureData.name || ""}
          </span>
          <div
            className="text-[12px] font-[500] leading-[150%] text-secondary border border-secondary flex items-center justify-center w-[50px] max-w-[86px] h-[20px] cursor-pointer"
            onClick={() =>
              navigate(
                `${PATH_COURSE.LECTURE_DETAIL}?lecture_id=${lectureData.id}&course_id=${courseId}`
              )
            }
          >
            履修前
          </div>
        </div>
      );
    case LECTURE_STATUS.UNPURCHASED_STATUS_LECTURE:
      return (
        <div
          className={`min-h-[34px] w-full text-secondary px-5 text-[14px] font-[500] leading-[150%] mb-[8px] bg-secondary-extralight flex justify-between items-center`}
        >
          <span
            className={`max-w-[300px] truncate ${
              lectureData.product_id ? "cursor-pointer" : ""
            }`}
            onClick={() => handleRedirectProductDetail(lectureData?.product_id)}
          >
            {lectureData.name || ""}
          </span>
          <div
            className="text-[12px] font-[500] leading-[150%] text-secondary-light border border-secondary-light flex items-center justify-center w-[50px] max-w-[86px] h-[20px] cursor-pointer"
            onClick={() => handleRedirectProductDetail(lectureData?.product_id)}
          >
            未購入
          </div>
        </div>
      );
    case LECTURE_STATUS.REPURCHASE_STATUS_LECTURE:
      return (
        <div
          className={`min-h-[34px] w-full text-secondary px-5 text-[14px] font-[500] leading-[150%] mb-[8px] bg-secondary-extralight flex justify-between items-center`}
        >
          <span
            className="max-w-[300px] truncate cursor-pointer"
            onClick={() => handleRedirectProductDetail(lectureData?.product_id)}
          >
            {lectureData.name || ""}
          </span>
          <div
            className="text-[12px] font-[500] leading-[150%] text-secondary-light border border-secondary-light flex items-center justify-center w-[50px] max-w-[86px] h-[20px] cursor-pointer"
            onClick={() => handleRedirectProductDetail(lectureData?.product_id)}
          >
            再購入
          </div>
        </div>
      );
    case LECTURE_STATUS.UNPUBLISHED_STATUS_LECTURE:
      return (
        <div
          className={`min-h-[34px] w-full text-secondary px-5 text-[14px] font-[500] leading-[150%] mb-[8px] bg-secondary-extralight flex justify-between items-center`}
        >
          <span className="max-w-[300px] truncate">
            {lectureData.name || ""}
          </span>
          <div className="text-[12px] font-[500] leading-[150%] text-secondary-light border border-secondary-light flex items-center justify-center w-[50px] max-w-[86px] h-[20px] cursor-pointer">
            未公開
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
};

export default LectureListItem;
