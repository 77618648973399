import React from "react";

type PlusButtonType = {
  label?: string;
  clickHandler?: () => void;
  className?: string;
  type?: number;
};

const PlusMinusButton = ({
  label,
  clickHandler,
  className,
  type,
}: PlusButtonType) => {
  switch (type) {
    case 0:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
          className="cursor-pointer"
          onClick={clickHandler}
        >
          <rect y="0.5" width="25" height="25" rx="5.20833" fill="#BEBEBE" />
          <path
            d="M5.20703 13H19.7904"
            stroke="white"
            strokeWidth="2.08333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 1:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
          className="cursor-pointer"
          onClick={clickHandler}
        >
          <rect y="0.5" width="25" height="25" rx="6.66667" fill="#BEBEBE" />
          <path
            d="M12.5 5.70703V20.2904"
            stroke="white"
            strokeWidth="2.08333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.20898 13H19.7923"
            stroke="white"
            strokeWidth="2.08333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return <div></div>;
  }
};

export default PlusMinusButton;
