import StudentLayout from "../../../layouts/student";
import { PATH_STUDENT_CART } from "../../../services/constants/route/router";
import { useNavigate } from "react-router-dom";
import CartButton from "../../../components/commons/buttons/CartButton";
import { WithChildren } from "../../../types/globals";

const StudentPolicyBase = ({ title, children }: WithChildren<{title: string}>) => {
  const navigate = useNavigate();

  return (
    <StudentLayout gap>
      <section className="border-b pb-[14px] flex items-center justify-between mb-[10px]">
        <div className="flex justify-between items-center w-full h-[34px]">
          <div
            className="text-[14px] font-[500] cursor-pointer"
            onClick={() => navigate(PATH_STUDENT_CART.DEFAULT)}
          >
            {title}
          </div>
          <div>
            <CartButton />
          </div>
        </div>
      </section>
      {children}
    </StudentLayout>
  );
};

export default StudentPolicyBase;
