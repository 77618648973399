import { useEffect, useState } from 'react';
import { Section } from '../../../../types/admin/section';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import HalfPlus from '../../../commons/icons/HalfPlus';
import { useSectionManagementContext } from '../../../../context/Section/Management';
import { PATH_ADMIN_SECTION } from '../../../../services/constants/route/router';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { sortArrayByKey } from '../../../../services/helpers/parseData';
import { isEmptyArr } from '../../../../services/helpers/etc';

type SidebarItemProps = {
  section: Section;
};

const SidebarGroupItem = ({ section }: SidebarItemProps) => {
  const { setFilter, setTitle } = useSectionManagementContext();
  const [isDropDown, setIsDropDown] = useState<boolean>(true);
  const { navigate, getParamValue } = useObjectRoutes();
  const [ subSections, setSubSections ] = useState<Section[]>([]);
  const sectionId = getParamValue('section_id');

  useEffect(() => {
    const orderedSubSection = sortArrayByKey(section.sub_section || [], 'order')
    setSubSections(orderedSubSection);
  }, [section])

  const handleFetchStudent = async (
    subSectionId?: number,
    sectionName?: string,
    subSectionName?: string,
  ) => {
    if (!subSectionId || !sectionName) return;

    setFilter({});
    setTitle(`${sectionName} ${subSectionName ? `｜${subSectionName}` : ''}`);
    navigate(`${PATH_ADMIN_SECTION.MANAGEMENT}?section_id=${subSectionId}`);
  };
  
  return (
    <div>
      <div
        className={`flex justify-between items-center bg-secondary-morelighter mb-[8px] pr-[15px] cursor-pointer min-h-[30px] hover:!bg-danger-lighter ${
          sectionId && parseInt(sectionId) === section.id && '!bg-danger-lighter rounded-[5px]'
        }`}
        onClick={() => handleFetchStudent(section.id, section.name)}
      >
        <div className="flex items-center text-[12px] font-[500] leading-[100%] pl-[5px]">
          {isDropDown ? (
            <IoMdArrowDropdown
              className="text-secondary-light"
              size={20}
              onClick={() => setIsDropDown(!isDropDown)}
            />
          ) : (
            <IoMdArrowDropright
              className="text-secondary-light"
              size={20}
              onClick={() => setIsDropDown(!isDropDown)}
            />
          )}
          <div className="ml-[8px] w-full">{section.name}</div>
        </div>
        <div className="text-[10px] font-[500] leading-[100%]">{section.student_count}</div>
      </div>
      <div className="ml-[10px]">
        {isDropDown &&
          subSections && !isEmptyArr(subSections) &&
          subSections.map((subSection) => (
            <div
              className={`flex items-center text-[11px] font-[500] leading-[100%] min-h-[20px] pl-[18px] justify-between pr-[15px] cursor-pointer mb-[6px] hover:bg-secondary-lighter ${
                sectionId &&
                parseInt(sectionId) === subSection.id &&
                'bg-danger-lighter rounded-[5px]'
              }`}
              key={subSection.id}
              onClick={() => handleFetchStudent(subSection.id, section.name, subSection.name)}
            >
              <div className="flex items-center justify-center">
                <HalfPlus className="mr-[16.5px]" /> {subSection.name}
              </div>
              <div className="">{subSection.child_count}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SidebarGroupItem;
