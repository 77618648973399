import React from "react";
import { PATH_ADMIN_TERMS_OF_USE } from "../../../../services/constants/route/router";
import { Link } from "react-router-dom";
type TermServiceModalProps = {
  children: React.ReactNode;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onClick?: () => void;
  submitLabel?: string;
  withSubmit?: boolean;
  justifyDirection?: string;
  width?: number;
  height?: number;
};

const TermServiceModal = ({
  children,
  setIsOpen,
  onClick,
  submitLabel = "新規作成",
  withSubmit = true,
  justifyDirection = "center",
  width = 1010,
  height = 616,
}: TermServiceModalProps) => {
  const pathBack = PATH_ADMIN_TERMS_OF_USE.TERMS_OF_SERVICE;

  return (
    <section className="z-[1000]">
      <div className="more_dark z-[1000]" />
      <div className="centered z-[1000]">
        <div className="modal z-[1000]">
          <div
            className="text-secondary-dark overflow-y-auto"
            style={{ width: `${width}px`, height: `${height}px` }}
          >
            {children}
            {withSubmit && (
              <div
                className={`flex gap-[10px] justify-end w-full bg-secondary-extralight items-center h-[80px] absolute bottom-0 rounded-b-[10px] px-[65px]`}
              >
                <Link
                  to={pathBack}
                  type="button"
                  className="bg-white text-secondary text-[14px] leading-[20px] w-fit px-[24px] py-[12px] rounded-[5px]"
                >
                  キャンセル
                </Link>
                <button
                  type="button"
                  className="bg-danger text-white text-[14px] leading-[20px] w-full max-w-[104px] h-[44px] rounded-[5px] verify_email_btn"
                  onClick={onClick}
                >
                  保存する
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermServiceModal;
