import dayjs from "dayjs";
import { FiMoreVertical } from "react-icons/fi";
import { Memo } from "../../../types/student/memo";
import { PATH_STUDY_TOOL } from "../../../services/constants/route/router";
import { ElementProps } from "../../../types/globals";
import { Link, useNavigate } from "react-router-dom";
import { LuTrash2 } from "react-icons/lu";
import { MdOutlineCreate } from "react-icons/md";
import { MemoFolder } from "../../../types/student/memoFolder";
import { DataDeleteMemo } from "./MemoBoard";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import '../../../assests/styles/memoItem.css';

type MemoItemProps = ElementProps<HTMLDivElement> & {
  memo: Memo;
  isChangeDisplay: boolean;
  handleDeleteMemo: (dataDelete: DataDeleteMemo) => {};
  setMemoFolders: React.Dispatch<React.SetStateAction<MemoFolder[]>>;
  isOpenPop: number;
  setIsOpenPop: React.Dispatch<React.SetStateAction<number>>;
};

export const hiddenImageElement = (memoContent: string) => {
  const regex = /<img\s+[^>]*src="([^"]*)"[^>]*>/g;
  return memoContent.replace(regex, "画像");
};

const MemoItem = ({
  memo,
  isChangeDisplay,
  handleDeleteMemo,
  setMemoFolders,
  isOpenPop,
  setIsOpenPop,
  ...props
}: MemoItemProps) => {
  const navigate = useNavigate();
  const urlEdit = `${PATH_STUDY_TOOL.DETAIL_MEMO}/${memo.id}`;
  let dataDelete: DataDeleteMemo = {
    id: Number(memo.id),
  };
  const { studentTheme } = useStudentThemeContext();

  const handleRedirectEdit = () => {
    localStorage.setItem("memoFolderId", String(memo.memo_folder_id));
    navigate(`${urlEdit}?with_edit`);
  };

  const handleRedirectDetail = () => {
    localStorage.setItem("memoFolderId", String(memo.memo_folder_id));
    navigate(urlEdit);
  };

  const MemoActionPop = () => {
    return (
      <>
        {isOpenPop === memo.id && (
          <div className="absolute z-20 top-[10px] right-[10px] w-[94px] h-[60px] px-[10px] py-[5px] border border-success-extralight bg-white text-[12px]">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => handleDeleteMemo(dataDelete)}
            >
              <LuTrash2 size={15} className="text-secondary" />
              削除する
            </div>
            <div
              className="flex items-center justify-between mt-[8px] cursor-pointer"
              onClick={handleRedirectEdit}
            >
              <MdOutlineCreate />
              編集する
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isChangeDisplay ? (
        <div
          className={`max-h-[60px] px-[15px] py-[8px] rounded-[10px] bg-[#${memo.background_color_code}] border border-danger-light relative`}
          {...props}
        >
          <div>
            <div className="flex justify-between items-center h-[20px]">
              <div
                style={{ color: studentTheme.text_color }}
                className="max-w-[400px] text-[13px] font-[700] tracking-[0.26px] cursor-pointer truncate"
                onClick={handleRedirectDetail}
              >
                {memo.name}
              </div>
              <FiMoreVertical
                className="cursor-pointer z-10"
                onClick={() => setIsOpenPop(memo.id || 0)}
                size={20}
              />
            </div>
            <div className="flex items-center gap-[30px] h-[20px]">
              <div className="text-right text-[11px] font-[400]">
                {dayjs(memo?.updated_at).format("YYYY/MM/DD")}
              </div>
              <div
                className="h-[20px] text-[12px] font-[400] tracking-[0.24px] overflow-hidden cursor-pointer"
                id="memo-content"
                dangerouslySetInnerHTML={{
                  __html: hiddenImageElement(memo?.content || ""),
                }}
                onClick={handleRedirectDetail}
              ></div>
            </div>
          </div>
          <MemoActionPop />
        </div>
      ) : (
        <div
          className={`max-h-[170px] p-[15px] rounded-[10px] bg-[#${memo.background_color_code}] border border-danger-light relative`}
          {...props}
        >
          <div>
            <div className="flex justify-between items-center">
              <div
                style={{ color: studentTheme.text_color }}
                className="max-w-[200px] text-[13px] font-[700] tracking-[0.26px] cursor-pointer truncate"
                onClick={handleRedirectDetail}
              >
                {memo.name}
              </div>
              <FiMoreVertical
                className="cursor-pointer z-10"
                onClick={() => setIsOpenPop(memo.id || 0)}
                size={20}
              />
            </div>
            <div
              className="h-[75px] text-[12px] font-[400] my-[15px] tracking-[0.24px] overflow-hidden break-words cursor-pointer"
              id="memo-content"
              dangerouslySetInnerHTML={{
                __html: memo?.content || "",
              }}
              onClick={handleRedirectDetail}
            ></div>
            <div className="text-right text-[11px] font-[400]">
              {dayjs(memo.updated_at).format("YYYY/MM/DD")}
            </div>
          </div>
          <MemoActionPop />
        </div>
      )}
      {isOpenPop > 0 && (
        <div
          className="fixed top-0 left-0 w-full h-full"
          onClick={() => setIsOpenPop(0)}
        />
      )}
    </>
  );
};

export default MemoItem;
