import React from 'react';
import { WithChildren } from '../../../../../types/globals';
import RequiredBox from '../../../commons/RequiredBox';

type UnitTestFormItemProps = { label: string; withRequired?: boolean };

const UnitTestFormItem = ({
  withRequired = true,
  label,
  children,
}: WithChildren<UnitTestFormItemProps>) => {
  return (
    <div className="mb-[20px] pl-[30px] mt-[7px] pb-[5px] text-[13px] font-[500] leading-[100%]">
      <div className="flex items-center gap-[22px] mb-[10px]">
        <label htmlFor="">{label}</label>
        {withRequired && <RequiredBox />}
      </div>
      <div className="text-[12px] leading-[100%]">{children}</div>
    </div>
  );
};

export default UnitTestFormItem;
