import React, { useEffect, useState } from "react";
import Title from "../../MyPage/Title";
import CourseStatus from "../CourseStatus";
import ChapterList from "./Chapters/ChapterList";
import UnboughtLectures from "./UnboughtLectures";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import {
  ChapterList as ChapterListType,
  LectureStatistics,
} from "../../../../types/student/lecture";
import { useNavigate } from "react-router-dom";
import { useFeaturePurchasedContext } from "../../../../context/FeaturePurchasedContext";

const LectureTop = () => {
  const [lectureStatistics, setLectureStatistics] = useState<LectureStatistics>(
    {}
  );
  const [chapterList, setChapterList] = useState<ChapterListType>({});
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const { getParamValue } = useObjectRoutes();
  const courseId = getParamValue("course_id");
  const lectureId = getParamValue("lecture_id");
  const navigate = useNavigate();
  const isFeaturePurchased = localStorage.getItem("payment_feature");

  useEffect(() => {
    if (!courseId) return;

    const fetchLectureData = async () => {
      const getLectureStatistics = request.get(
        `${API.LECTURE.LECTURE_STATISTICS}?course_id=${courseId}&lecture_id=${lectureId}`,
        (lectureStatisticsData) =>
          setLectureStatistics(lectureStatisticsData || {}),
        (error) => {
          if (error === 404) {
            navigate("/404");
          }
        }
      );
      const getLectureList = request.get(
        `${API.LECTURE.CHAPTER_OF_CURRENT_LECTURE}?course_id=${courseId}&lecture_id=${lectureId}`,
        (chapterListData) => setChapterList(chapterListData || {}),
        () => {},
        { withError: false, withClose: false }
      );

      try {
        await Promise.all([getLectureStatistics, getLectureList]);
        setWaitingApi(false);
      } catch (error) {
        setWaitingApi(false);
      }
    };

    if (isFeaturePurchased) {
      const parseIsFeaturePurchased = JSON.parse(isFeaturePurchased);
      if (parseIsFeaturePurchased) {
        const fetchCheckLecturePurchased = async () => {
          await request.get(
            `${API.LECTURE.CHECK_LECTURE_PURCHASED}?course_id=${courseId}&lecture_id=${lectureId}&is_service_purchased=${parseIsFeaturePurchased}`,
            (data) => {
              fetchLectureData();
              return;
            },
            (error) => {
              navigate("/404");
              return;
            }
          );
        };

        fetchCheckLecturePurchased();
      }

      fetchLectureData();
    }
  }, [isFeaturePurchased]);
  return (
    <>
      <Title
        content={`${lectureStatistics.course_info?.name || ""}|${
          lectureStatistics.lecture_info?.name || ""
        }`}
        courseId={lectureStatistics.course_info?.id}
        withSeperator
      />
      <CourseStatus statistics={lectureStatistics} waitingApi={waitingApi} />
      <ChapterList chapterListData={chapterList} waitingApi={waitingApi} />
      {/* UnboughtLectures hidden for later phases */}
      {/* <UnboughtLectures /> */}
    </>
  );
};

export default LectureTop;
