import React from 'react';
import StudentBase from '.';
import StudentUpdate from '../../../components/admin/student/StudentUpdate';

const StudentUpdatePage = () => {
  return (
    <StudentBase title="受講者管理 ｜ 受講者新規追加">
      <StudentUpdate/>
    </StudentBase>
  );
};

export default StudentUpdatePage;