import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../types/globals";

interface EventBoardContextType {
  month: number;
  setMonth: React.Dispatch<React.SetStateAction<number>>;
}

const EventBoardContext = React.createContext<EventBoardContextType>({
  month: 0,
  setMonth: () => {},
});

const EventBoardProvider = ({ children }: ChildrenWithProps) => {
  const [month, setMonth] = useState<number>(0);
  return (
    <EventBoardContext.Provider value={{ month, setMonth }}>
      {children}
    </EventBoardContext.Provider>
  );
};

export default EventBoardProvider;

export const useEventBoardContext = () => useContext(EventBoardContext);
