import TabCard from "../../../components/admin/commons/TabCard";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { useTabFinder } from "../../../hooks/useTabFinder";
import AdminLayout from "../../../layouts/admin";
import { PRODUCT_TAB_DATA } from "../../../services/constants/admin/pages/product";
import { PATH_ADMIN_COUPON } from "../../../services/constants/route/router";
import { WithChildren } from "../../../types/globals";

type ProductMainProp = {
  title?: string;
};

const CouponBase = ({ title, children }: WithChildren<ProductMainProp>) => {
  const { stringSearchParam } = useObjectRoutes();
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_COUPON));

  return (
    <AdminLayout>
      <TabCard
        tabData={PRODUCT_TAB_DATA}
        tabIndex={stringSearchParam ? -1 : tabIndex === 0 ? 2 : 3}
        title={title}
      >
        {children}
      </TabCard>
    </AdminLayout>
  );
};

export default CouponBase;
