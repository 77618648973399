import { Link } from "react-router-dom";
import { PATH_PRODUCT } from "../../../services/constants/route/router";
import { useEffect, useState } from "react";
import { StudentThemeType } from "../../../types/admin/student_theme";
import { useLoginSettingContext } from "../../../context/LoginSettingContext";

function PaymentKonbiniAfter() {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [studentThemeColor, setStudentThemeColor] = useState<StudentThemeType>({});
  const { studentTheme, fetching } = useLoginSettingContext();
  useEffect(() => {
    if (!fetching) {
      setStudentThemeColor(studentTheme);
    }
  }, [fetching]);

  return (
    <div className="w-full h-full bg-white px-[29px] py-[200px]">
      <div
        style={{ color: studentThemeColor.main_color_first }}
        className="text-center text-[50px] font-[600] leading-[20px] mb-[71px]"
      >
        ご注文ありがとうございました！
      </div>
      <div
        style={{ color: studentThemeColor.text_color }}
        className="flex flex-col justify-center items-center text-[30px] font-[400] px-[15px] py-[12px] mb-[38px]"
      >
        <p>{`${user.email}へ`}</p>
        <p>メールをお送りしましたので、ご確認ください。</p>
      </div>
      <div className="flex justify-center items-center">
        <Link
          style={{ backgroundColor: studentThemeColor.main_color_first }}
          to={PATH_PRODUCT.DEFAULT}
          className="text-white text-[16px] px-[15px] py-[6px] rounded-md mx-auto"
        >
          商品一覧へ戻る
        </Link>
      </div>
    </div>
  );
}

export default PaymentKonbiniAfter;
