import React from "react";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { IoIosArrowUp } from "react-icons/io";

type BackToTopButtonType = {
  width?: number;
  height?: number;
  className?: string;
};

function BackToTopButton({
  width,
  height,
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  BackToTopButtonType) {
  const { studentTheme } = useStudentThemeContext();

  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <button
      type="button"
      className={`bg-primary-light text-center flex justify-center items-center h-[${height|| 60}px] w-[${width|| 60}px] ${
        className || ""
      }`}
      style={{
        // width: `${width || 60}px`,
        // height: `${height || 60}px`,
        backgroundColor: studentTheme.sub_color_first,
      }}
      onClick={() => backToTop()}
    >
      <IoIosArrowUp className="" size={30} />
    </button>
  );
}

export default BackToTopButton;
