import StudentBase from '.';
import StudentList from '../../../components/admin/student/StudentList';
import StudentProvider from '../../../context/StudentContext';

const StudentListPage = () => {
  return (
    <StudentBase title='受講者管理 ｜ 受講者一覧'>
      <StudentProvider>
        <StudentList />
      </StudentProvider>
    </StudentBase>
  );
};

export default StudentListPage;
