import ProductBase from ".";
import ProductDetail from "../../../components/student/Product/ProductDetail";

const ProductDetailPage = () => {
  return (
    <ProductBase>
      <ProductDetail />
    </ProductBase>
  );
};

export default ProductDetailPage;
