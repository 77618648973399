import React from "react";

const Resource = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.25005 18.5002H2.75005V7.50018C2.74477 7.14345 2.80997 6.7892 2.94191 6.45773C3.07386 6.12627 3.26996 5.82411 3.51895 5.56861C3.76795 5.31311 4.06495 5.10929 4.3929 4.96885C4.72085 4.8284 5.07331 4.75409 5.43005 4.75018H7.84005C7.949 4.74783 8.05718 4.76894 8.15725 4.81207C8.25733 4.85521 8.34695 4.91936 8.42005 5.00018L11.0901 8.23018H16.0001C16.3619 8.22875 16.7203 8.2998 17.0543 8.43913C17.3882 8.57846 17.6908 8.78325 17.9443 9.04143C18.1979 9.2996 18.3971 9.60593 18.5303 9.94234C18.6636 10.2787 18.7281 10.6384 18.7201 11.0002V11.5002H17.2201V11.0002C17.2241 10.8375 17.1955 10.6756 17.1359 10.5241C17.0764 10.3726 16.9872 10.2345 16.8735 10.118C16.7598 10.0016 16.624 9.90901 16.474 9.84583C16.324 9.78265 16.1628 9.75013 16.0001 9.75018H10.7301C10.621 9.74994 10.5134 9.72562 10.4148 9.67894C10.3163 9.63227 10.2293 9.56439 10.1601 9.48018L7.49005 6.25018H5.43005C5.10821 6.26063 4.80356 6.3979 4.58252 6.63206C4.36147 6.86622 4.24197 7.17827 4.25005 7.50018V18.5002Z"
        fill={props.color || "#BEBEBE"}
      />
      <path
        d="M17.3574 20H3.73738C3.60897 19.9989 3.48293 19.9654 3.37101 19.9024C3.2591 19.8395 3.16495 19.7492 3.09738 19.64C3.03339 19.5272 2.99976 19.3997 2.99976 19.27C2.99976 19.1403 3.03339 19.0128 3.09738 18.9L6.47738 12.4C6.54329 12.2797 6.64016 12.1792 6.75795 12.1088C6.87575 12.0385 7.01019 12.0009 7.14738 12H20.7374C20.8658 12.0011 20.9918 12.0346 21.1037 12.0976C21.2157 12.1605 21.3098 12.2508 21.3774 12.36C21.4414 12.4728 21.475 12.6003 21.475 12.73C21.475 12.8597 21.4414 12.9872 21.3774 13.1L17.9974 19.6C17.934 19.716 17.8418 19.8137 17.7297 19.8838C17.6177 19.9538 17.4894 19.9939 17.3574 20ZM4.96738 18.5H16.9074L19.5074 13.5H7.56738L4.96738 18.5Z"
        fill={props.color || "#BEBEBE"}
      />
    </svg>
  );
};

export default Resource;
