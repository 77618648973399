import React, { useState, useContext } from 'react';
import { ChildrenWithProps } from '../types/globals';
import { filterUniqueArray, sortArrayByKey } from '../services/helpers/parseData';
import { Course } from '../types/admin/course';
import { Lecture } from '../types/admin/lecture';
import { Chapter } from '../types/admin/chapter';

type CourseListContextType = {
  courses: Course[];
  setCourses: (courses: Course[]) => void;
  lectures: Lecture[];
  setLectures: (lectures: Lecture[]) => void;
  chapters: Chapter[];
  setChapters: (chapters: Chapter[]) => void;
};

const CourseListContext = React.createContext<CourseListContextType>({
  courses: [],
  setCourses: () => {},
  lectures: [],
  setLectures: () => {},
  chapters: [],
  setChapters: () => {},
});

const CourseListProvider = ({ children }: ChildrenWithProps) => {
  const [courses, _setCourses] = useState<Course[]>([]);
  const [lectures, _setLectures] = useState<Lecture[]>([]);
  const [chapters, _setChapters] = useState<Chapter[]>([]);

  const setCourses = (courses: Course[]) => {
    const uniqueCourses = filterUniqueArray(courses);
    _setCourses(sortArrayByKey(uniqueCourses, 'order'));
  };

  const setLectures = (lectures: Lecture[]) => {
    const uniqueLectures = filterUniqueArray(lectures);
    _setLectures(sortArrayByKey(uniqueLectures, 'order'));
  };

  const setChapters = (chapters: Chapter[]) => {
    const uniqueChapters = filterUniqueArray(chapters);
    _setChapters(sortArrayByKey(uniqueChapters, 'order'));
  };

  return (
    <CourseListContext.Provider
      value={{
        courses,
        setCourses,
        lectures,
        setLectures,
        chapters,
        setChapters,
      }}
    >
      {children}
    </CourseListContext.Provider>
  );
};

export default CourseListProvider;

export const useCourseListContext = () => useContext(CourseListContext);
