import React from "react";
import TermServiceModal from "./TermServiceModal";
import { useState, useEffect } from "react";
import { TermOfServiceType } from "../../../../types/admin/term_of_use";
import { useNavigate } from "react-router";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { PATH_ADMIN_TERMS_OF_USE } from "../../../../services/constants/route/router";
import { swalMessage } from "../../../../services/helpers/swal";

const TermServiceEdit = () => {
  const navigate = useNavigate();
  const { getParamValue } = useObjectRoutes();
  const termServiceId = getParamValue("id");
  const [dataTermService, setDataTermService] = useState<TermOfServiceType>({
    id: Number(termServiceId),
    description: "",
  });

  const handleUpdateTermService = async () => {
    await request.patch(
      API.ADMIN_TERM_SERVICE.UPDATE,
      dataTermService,
      () => {
        navigate(PATH_ADMIN_TERMS_OF_USE.TERMS_OF_SERVICE);
      },
      (error) => {
        swalMessage("", error, "error", {
          timer: 1500,
          showConfirmButton: false,
        });
      }
    );
  };

  useEffect(() => {
    const fetchTermService = async () => {
      await request.get(
        `${API.ADMIN_TERM_SERVICE.DETAIL}?id=${termServiceId}`,
        (data) => setDataTermService(data),
        (error) => {
          swalMessage("", error, "error", {
            timer: 1500,
            showConfirmButton: false,
          });
        }
      );
    };

    fetchTermService();
  }, []);

  return (
    <TermServiceModal onClick={handleUpdateTermService}>
      <div className="px-[85px] py-[36px] flex flex-col items-start justify-center gap-[24px]">
        <p className="text-[15px] text-secondary-dark font-[500] tracking-[0.3px]">
          {dataTermService?.name}
        </p>
        <div className="mb-[70px]">
          <textarea
            name="description"
            id=""
            cols={9999}
            rows={18}
            className="w-full px-[24px] py-[17px] overflow-visible text-[14px] text-secondary font-[400] text-justify tracking-[0.3px] leading-[155%]"
            onChange={(e) =>
              setDataTermService({
                ...dataTermService,
                description: e.target.value,
              })
            }
            value={dataTermService?.description}
          ></textarea>
        </div>
      </div>
    </TermServiceModal>
  );
};

export default TermServiceEdit;
