import React, { useState } from "react";
import {
  swalError,
  swalLoading,
  swalSuccess,
} from "../../../services/helpers/swal";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import BaseModal from "../commons/BaseModal";
import { useSectionListContext } from "../../../context/Section/List";
import { useSectionManagementContext } from "../../../context/Section/Management";

type TitleProps = {
  content: string;
  withButton?: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenModal: boolean;
  withSubtitle?: boolean;
};

const SectionTitle = ({
  content,
  withButton,
  isOpenModal,
  setIsOpenModal,
  withSubtitle
}: TitleProps) => {
  const { sections: sectionsFromList, setSections: setSectionsFromList } = useSectionListContext();
  const { sections: sectionsFromManagement, setSections: setSectionsFromManagement } =
    useSectionManagementContext();
  const [name, setName] = useState<string>("");
  const [errors, setErrors] = useState<string>("");

  const handleSubmit = async () => {
    if (!withButton) return;

    // swalLoading();
    const result = await makeRequest({
      method: "post",
      url: `${API.ADMIN_SECTION.CREATE_SECTION}`,
      data: { name },
    });

    if (!result.status) {
      if (result.message) {
        setErrors(result.message);
      }
      return swalError();
    }

    setErrors("");
    setSectionsFromList([...sectionsFromList, result.data]);
    setSectionsFromManagement([...sectionsFromManagement, result.data]);
    swalSuccess();
    setIsOpenModal(false);
  };

  return (
    <div className="text-[13px] font-[500] leading-[100%] text-secondary-dark">
      <hr className="mt-[60px] mb-[20px] h-[1px] bg-secondary-light" />
      {withSubtitle && (<div className="mt-[20px] pl-[5px] mb-[8px]">選択中のセクション</div>)}
      <div className="min-h-[50px] w-full rounded-[5px] text-[18px] font-[700] leading-[100%] bg-primary text-white flex justify-between items-center">
        <div className="w-[40%] pl-[21px]">{content}</div>
        <div className="w-[20%]"></div>
        <div className="w-[10%]"></div>
        <div className="w-[20%]"></div>
        <div className="w-[10%]"></div>
        <div className="w-[20%] flex justify-center">
          {withButton && (
            <button
              className="cursor-pointer text-[12px] font-[500] leading-[100%] bg-danger text-white px-[7.5px] pt-[3px] pb-[5px] w-full max-w-[145px] text-center rounded-[5px]"
              onClick={() => setIsOpenModal(true)}
            >
              新規セクションを追加
            </button>
          )}
        </div>
      </div>
      {isOpenModal && (
        <BaseModal
          setIsOpen={setIsOpenModal}
          justifyDirection="end"
          onClick={handleSubmit}
        >
          <div className="text-center pt-[222px] pb-[167px]">
            <div className="text-[16px] font-[700] leading-[100%] tracking-[0.02em] text-primary mb-[24px]">
              セクションを新規登録する
            </div>
            <div className="mb-[12px]">新規セクションを作成します</div>

            <div className="">
              <input
                type="text"
                name="name"
                id=""
                onChange={(e) => setName(e.target.value)}
                className="max-w-[550px] h-[40px] border !border-secondary-light mb-[18px] pl-[13px] text-[12px] leading-[100%]"
                placeholder="セクション名を入力してください。"
              />
              {(errors) && (
                <div className="text-[12px] font-[500] leading-[190%] text-danger">
                  {errors}
                </div>
              )}
            </div>
          </div>
        </BaseModal>
      )}
    </div>
  );
};

export default SectionTitle;
