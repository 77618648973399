import { useState } from "react";
import LayoutLandingPage from "../layouts/LayoutLandingPage";
import ButtonHome from "../components/Home/ButtonHome";
import { ROUTE } from "../services/constants/route/router";
import { useNavigate } from "react-router-dom";
import LoginIconRound from "../components/Home/LoginIconRound";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalMessage } from "../services/helpers/swal";

const DomainLogin = () => {
  const [domain, setDomain] = useState("");
  let navigate = useNavigate();
  let subDomainText = process.env.REACT_APP_MAIN_DOMAIN;

  const verifyDomain = async () => {
    let result = await makeRequest({
      method: "post",
      url: API.AUTH.VERIFY_DOMAIN,
      data: {
        subdomain: domain,
      },
    });

    if (result.status) {
      localStorage.setItem("subdomain", `${domain}.${window.location.host}`);
      navigate(`${ROUTE.REDIRECT}/login`);
      // navigate(`${ROUTE.LOGIN}`)
      return;
    }

    let error = result.message;
    swalMessage("エラー", error, "error");
  };

  return (
    <LayoutLandingPage>
      <section className="form_sub_domain bg-transparent w-full max-w-[1070px] min-h-[400px] mx-auto text-center pb-[44px] max-sm:pt-[25px] max-sm:pb-[25px] z-[100]">
        <p className="text-[25px] text-primary mb-[54px] font-[700] max-sm:text-[20px] max-sm:mb-[25px]">
          パレットLMSのサービスログイン
        </p>
        <div className="flex justify-center">
          <div className="align-middle flex items-center bg-warning-lighter w-full max-w-[930px] min-h-[130px] rounded-md border border-danger-lighter mx-[20px] text-[18px] max-sm:text-[16px] justify-center max-[830px]:!flex-col max-[830px]:!gap-[25px] max-[830px]:!h-[250px] px-[5px]">
            <div className="flex items-center w-full justify-center max-[635px]:!px-[15px] max-[630px]:!flex-col max-[630px]:!gap-[8px]">
              <span className="align-middle whitespace-nowrap">https://</span>
              <input
                className="border border-warning-medium rounded-md w-full max-w-[330px] mx-[9px] py-[16px] px-[10px] max-[410px]:!mx-0 max-sm:text-center max-sm:!text-[18px]"
                onChange={(e) => setDomain(e.target.value)}
                type="text"
              />
              <span className="align-middle whitespace-nowrap">
                .{subDomainText}
              </span>
            </div>
            <ButtonHome
              otherButtonStyle={
                "flex items-center justify-center w-full max-w-[230px] h-[60px] max-sm:h-[60px] max-sm:w-[230px] rounded-[10px] bg-primary text-white mr-[30px] max-[880px]:!max-w-[200px] max-[880px]:!mr-0"
              }
              onClick={verifyDomain}
            >
              <div className="w-full text-[20px] flex items-center justify-center gap-x-[18px]">
                <span className="">ログイン画面へ</span>
                <LoginIconRound />
              </div>
            </ButtonHome>
          </div>
        </div>

        <p className="text-[15px] text-black mt-[45px] mx-[20px] max-sm:text-[14px]">
          サブドメイン名がわからない場合は管理者までお問い合わせください
        </p>
      </section>
    </LayoutLandingPage>
  );
};

export default DomainLogin;
