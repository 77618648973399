import React, { ChangeEvent, useEffect, useState } from "react";
import TableTitle from "../../commons/TableTitle";
import TableAction from "./TableAction";
import Table from "./Table";
import { Product } from "../../../../types/admin/product";
import { useProductListContext } from "../../../../context/ProductContext";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { paramizeObject } from "../../../../services/helpers/parseData";
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
  swallConfirm,
} from "../../../../services/helpers/swal";

type FilterType = {
  name?: string;
  limit: number;
  page: number;
};

const ProductList = () => {
  const [filter, setFilter] = useState<FilterType>({
    name: "",
    limit: 10,
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const { products, setProducts } = useProductListContext();
  const [_products, _setProducts] = useState<Product[]>(products || []);
  const { setTotalPages } = useProductListContext();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    _setProducts(products);
  }, [products]);

  useEffect(() => {
    const fetchProduct = async () => {
      // swalLoading();

      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_PRODUCT.LIST}?${paramizeObject(filter)}`,
      });
      
      setProducts(result.data.products);
      setTotalPages(result.data.total_pages);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetchProduct();
    }, 500);
    return () => clearTimeout(timer);
  }, [filter]);

  useEffect(() => {
    if(selectedIds.length > 0) checkSelectAll(products);
  }, [selectedIds]);

  const resetTable = () => {
    setSelectAll(false);
    setSelectedIds([]);
    setCurrentPage(1);
  };

  const toggleSelect = (id: number) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds(selectedIds?.filter((sId) => sId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const isProductSelected = (id: number) => selectedIds?.includes(id);

  const checkSelectAll = (currentProducts: Product[]) => {
    let currentPageProductIds = currentProducts?.map((product) => product.id);
    let allCurrentPageProductsSelected = currentPageProductIds.every((id) =>
      selectedIds.includes(Number(id))
    );
    if (allCurrentPageProductsSelected) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      const productIds = products?.map((product) => Number(product.id));
      setSelectedIds(productIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleChangeFilter = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  const handleDeleteSelected = async () => {
    if (!selectedIds.length) return;

    swallConfirm(async () => {
      // swalLoading();

      const result = await makeRequest({
        method: "delete",
        url: API.ADMIN_PRODUCT.DELETE,
        data: { product_ids: selectedIds },
      });

      if (!result.status) return swalError();

      _setProducts(
        _products?.filter((product) => !selectedIds.includes(product.id || 0))
      );

      resetTable();
      swalSuccess();
      changePaginate(1);
    }, "この商品を削除します。<br>よろしいですか？");
  };

  return (
    <>
      {/* Table Action */}
      <TableAction
        changeHandle={handleChangeFilter}
        handleDeleteSelected={handleDeleteSelected}
      />
      <Table
        _products={products}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        selectAll={selectAll}
        toggleSelectAll={toggleSelectAll}
        isProductSelected={isProductSelected}
        toggleSelect={toggleSelect}
      />
    </>
  );
};

export default ProductList;
