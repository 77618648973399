import { ChangeEvent, Dispatch } from "react";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

type StudentCartCouponProps = {
  couponCode: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleEnterCode: () => void;
};

const StudentCartCoupon = ({
  couponCode,
  handleChange,
  handleEnterCode,
}: StudentCartCouponProps) => {
  const { studentTheme } = useStudentThemeContext();

  return (
    <div className="flex gap-[10px] items-center mt-[30px] mb-[50px] p-[10px] bg-secondary-morelighter">
      <div className="flex-1">
        <input
          type="text"
          className="h-[40px] px-[20px] py-[5px] placeholder:text-[14px] placeholder:text-secondary-light"
          placeholder="クーポンコードを入力"
          value={couponCode}
          onChange={handleChange}
        />
      </div>
      <div>
        <button
          style={{
            backgroundColor: studentTheme.main_color_first,
          }}
          className={`h-[40px] text-white text-[14px] font-[500] tracking-[0.28px] px-[15px] py-[3px] bg-primary rounded-[5px] ${
            couponCode === "" ? "cursor-default" : "active:scale-90"
          }`}
          onClick={handleEnterCode}
          disabled={couponCode === ""}
        >
          適用する
        </button>
      </div>
    </div>
  );
};

export default StudentCartCoupon;
