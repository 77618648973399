import React from 'react';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { User } from '../../../../types/user';

type StudentSearchItemProps = {
  student: User;
  className?: string;
};

const StudentSearchItem = ({
  student,
  className = '',
  ...props
}: StudentSearchItemProps &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div
      className={`w-full max-w-[235px] min-h-[70px] border border-secondary-lighter flex items-center justify-center ${className}`}
      {...props}
    >
      <div className="flex items-center cursor-pointer gap-[17.56px] py-[11.56px] pl-[11.56px] pr-[10px]">
        <div className="bg-success-lighter w-[41.67px] h-[41.67px] flex justify-center items-center">
          {!student.avatar_url && <HiOutlineUserCircle color="#EBE4D8" size={'100%'} />}
          {student.avatar_url && <img src={student.avatar_url} alt={student.first_name} className='w-[41.67px] h-[41.67px] object-cover'/>}
        </div>
        <div className="text-left">
          <div
            className="text-[16px] leading-[16px] mb-[8px] w-[150px] truncate"
            title={`${student.first_name} ${student.last_name}`}
          >{`${student.first_name} ${student.last_name}`}</div>
          <div
            className="text-secondary text-[12px] leading-[16px] w-[150px] truncate"
            title={student.email}
          >
            {student.email}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentSearchItem;
