import StudentCartCoupon from "./StudentCartCoupon";
import PaymentFramework from "./PaymentFramework";
import { ChangeEvent, useEffect, useState } from "react";
import { CartItem } from "../../../types/student/cart";
import StudentCartBuyLater from "./StudentCartBuyLater";
import {
  swalClose,
  swalError,
  swalLoading,
  swalMessage,
  swallConfirm,
} from "../../../services/helpers/swal";
import StudentCartMain from "./StudentCartMain";
import { axios, makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { CART_COMMON } from "../../../services/constants/student/cart";
import { IoMdArrowBack } from "react-icons/io";
import Button from "../../commons/buttons/Button";
import { FiRefreshCw } from "react-icons/fi";
import { useCartContext } from "../../../context/CartContext";
import { COUPON_TYPE } from "../../../services/constants/admin/pages/coupon";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { PATH_STUDENT_CART } from "../../../services/constants/route/router";
import { PaymentDataType } from "../../../types/student/payment";
import { PAYMENT_METHOD_TYPE } from "../../../services/constants/student/payment";
import { useNavigate } from "react-router-dom";
import { useStorage } from "../../../hooks/useStorage";
import Swal from "sweetalert2";

type CartItemQuantityType = {
  cart_item_id: number;
  quantity: number;
};

const StudentCart = () => {
  const { waitingApi, cartItems, setCartItems, setTotalProduct } =
    useCartContext();
  const { studentTheme } = useStudentThemeContext();
  const { setLocalStorage } = useStorage();
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [services, setServices] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [amountDiscount, setAmountDiscount] = useState<number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [cartItemQuantity, setCartItemQuantity] =
    useState<CartItemQuantityType>({
      cart_item_id: 0,
      quantity: 0,
    });

  const isSelected = (id: number) => {
    return selectedIds?.includes(id);
  };

  const [paymentData, setPaymentData] = useState<PaymentDataType>({
    payment_method_type: "",
    success_url: window.location.origin + PATH_STUDENT_CART.ORDER_SUCCESS,
    cancel_url: window.location.origin + PATH_STUDENT_CART.DEFAULT,
  });

  const toggleSelect = (id: number) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleAmountTotal = (cartItems: CartItem[]) => {
    let amountTotal = 0;
    cartItems.forEach((cartItem) => {
      if (cartItem.status) amountTotal += cartItem.price * cartItem.quantity;
    });

    setTotal(amountTotal);
  };

  const handleDelete = (cartItemId?: number, status?: number) => {
    if ((selectedIds.length === 0 && !cartItemId) || !cartItems.length) return;

    swallConfirm(async () => {
      let filterCartItems;
      // swalLoading();

      // Delete single product
      if (cartItemId) {
        filterCartItems = cartItems.filter(
          (cartItem: CartItem) => cartItem.id !== cartItemId
        );

        await makeRequest({
          method: "delete",
          url: API.CART.DELETE_PRODUCT,
          data: { cart_item_ids: [cartItemId] },
        });

        setCartItems(filterCartItems);
        handleAmountTotal(filterCartItems);
        setTotalProduct(filterCartItems.length);
        localStorage.setItem("cart_items", JSON.stringify(filterCartItems));
        return;
      }

      // Delete multiple product.
      if (selectedIds.length > 0) {
        let cartItemIds: number[] = [];
        cartItems.map((cartItem: CartItem) => {
          if (
            cartItem.status === status &&
            selectedIds.includes(cartItem.id || 0)
          ) {
            cartItemIds.push(cartItem?.id || 0);
          }
        });

        filterCartItems = cartItems.filter(
          (cartItem: CartItem) => !cartItemIds.includes(cartItem?.id || 0)
        );

        await makeRequest({
          method: "delete",
          url: API.CART.DELETE_PRODUCT,
          data: { cart_item_ids: cartItemIds },
        });

        setCartItems(filterCartItems);
        setTotalProduct(filterCartItems.length);
        handleAmountTotal(filterCartItems);
        localStorage.setItem("cart_items", JSON.stringify(filterCartItems));

        return;
      }
    }, "この商品を削除します。<br>よろしいですか？");
  };

  const handleMoveCartItem = async (status: number) => {
    if (!selectedIds.length) return;

    let cartItemIds: number[] = [];

    cartItems.forEach((cartItem) => {
      if (selectedIds.includes(cartItem.id || 0)) {
        cartItem.status = status;
        cartItemIds.push(cartItem?.id || 0);
      }
    });

    const result = await makeRequest({
      method: "patch",
      url: API.CART.MOVE_PRODUCT,
      data: { status: status, cart_item_ids: cartItemIds },
    });

    if (!result.status) {
      swalError();
      return;
    }

    handleAmountTotal(cartItems);
    setCartItems(cartItems);
    setSelectedIds([]);
    localStorage.setItem("cart_items", JSON.stringify(cartItems));
  };

  const handleChangeQuantity = async (
    e: ChangeEvent<HTMLInputElement>,
    cartItemId: number
  ) => {
    const quantity = Number(e.currentTarget.value);
    if (!quantity) return;

    setCartItemQuantity({
      cart_item_id: cartItemId,
      quantity: quantity,
    });
  };

  const handleChangeCoupon = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setAmountDiscount(0);
      localStorage.removeItem("coupon_data");
      setPaymentData({
        ...paymentData,
        stripe_coupon_id: undefined,
        coupon_id: undefined,
      });
    }

    setCouponCode(e.target.value);
  };

  const handleEnterCouponCode = async () => {
    if (couponCode === "") return;

    let amountDiscount: number = 0;
    // swalLoading();
    const result = await makeRequest({
      method: "post",
      url: API.CART.ENTER_COUPON_CODE,
      data: { code: couponCode },
    });

    if (!result.status) {
      return swalMessage("", result.message, "error", {
        timer: 1500,
        showConfirmButton: false,
      });
    }

    const coupon = result.data;
    if (coupon.type === COUPON_TYPE.AMOUNT) {
      amountDiscount = coupon.amount;
    }

    if (coupon.type === COUPON_TYPE.PERCENT) {
      amountDiscount = (total * coupon.amount) / 100;
    }

    setAmountDiscount(amountDiscount);
    setPaymentData({
      ...paymentData,
      stripe_coupon_id: coupon.stripe_coupon_id,
      coupon_id: coupon.id,
    });
    localStorage.setItem(
      "coupon_data",
      JSON.stringify({
        coupon_id: coupon.id,
        amount_discount: amountDiscount,
        stripe_coupon_id: coupon.stripe_coupon_id,
      })
    );
  };

  const handleReturnBack = () => {
    window.history.back();
  };

  const fetchCart = async () => {
    // swalLoading();

    const result = await makeRequest({
      method: "get",
      url: API.CART.LIST,
    });

    handleAmountTotal(result.data);
    setCartItems(result.data);
    localStorage.setItem("cart_items", JSON.stringify(result.data));
  };

  const handleFetchPaymentMethod = async () => {
    // swalLoading();
    try {
      const subdomain = window.location.hostname.split(".")[0];
      const url =
        process.env.REACT_APP_API_PALETTE +
        `/api/payment-setting/get-payment-method?sub_domain=${subdomain}`;
      const response = (await axios.get(url)).data;
      setServices(response.result.data);
      swalClose();
    } catch (error) {
      swalError();
      return;
    }
  };

  useEffect(() => {
    localStorage.removeItem("cart_items");
    handleFetchPaymentMethod();
  }, []);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      handleAmountTotal(cartItems);
    }

    localStorage.removeItem("coupon_data");
  }, [cartItems]);

  useEffect(() => {
    if (!cartItemQuantity.cart_item_id) return;

    const timer = setTimeout(async () => {
      // swalLoading();
      const result = await makeRequest({
        method: "patch",
        url: API.CART.UPDATE_QUANTITY_PRODUCT,
        data: cartItemQuantity,
      });

      if (!result.status) {
        swalMessage("", result.message, "error", {
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }

      let findCartItem = cartItems.find(
        (cartItem) => cartItem.id === cartItemQuantity.cart_item_id
      );
      if (!findCartItem) return;

      findCartItem.quantity = cartItemQuantity.quantity;

      setCartItems(cartItems);
      handleAmountTotal(cartItems);
      localStorage.setItem("cart_items", JSON.stringify(cartItems));
    }, 500);

    return () => clearTimeout(timer);
  }, [cartItemQuantity]);

  const handleChoosePaymentMethodType = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPaymentData({ ...paymentData, payment_method_type: e.target.value });
  };

  const handleRedirectToPaymentPage = async () => {
    try {
      if (paymentData.payment_method_type === "") {
        throw new Error("決済方法を指定してください。");
      }
      swalLoading();
      const result = await makeRequest({
        method: "post",
        url: API.PAYMENT.GET_URL_CHECKOUT,
        data: {
          ...paymentData,
          products: cartItems
            .map((item) => {
              if (item.status === CART_COMMON.CART_IN_SHOP) {
                return {
                  ...item,
                  id: undefined,
                  name: undefined,
                  status: undefined,
                  product_name: item.name,
                };
              }
            })
            .filter((item) => item),
        },
      });

      if (!result.status) {
        throw new Error(result.message);
      }

      if (
        paymentData.payment_method_type === PAYMENT_METHOD_TYPE.KONBINI.VALUE
      ) {
        handleConfirmRedirectURLPayment(result.data);
      }

      if (paymentData.payment_method_type === PAYMENT_METHOD_TYPE.CARD.VALUE) {
        window.location.href = result.data;
      }

      if (paymentData.payment_method_type === PAYMENT_METHOD_TYPE.INVOICE.VALUE) {
        navigate(PATH_STUDENT_CART.ORDER_SUCCESS)
      }
    } catch (error) {
      swalMessage("", String(error).replace("Error:", ""), "error", {
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleDeleteCartItem = async () => {
    const cartItemIds = cartItems
      .filter((cartItem) => cartItem.status === CART_COMMON.CART_IN_SHOP)
      .map((cartItem) => cartItem.id);
    const cartItemsRemain = cartItems.filter(
      (cartItem) => !cartItemIds.includes(cartItem.id)
    );
    setLocalStorage("cart_items", cartItemsRemain);
    setCartItems(cartItemsRemain);

    await makeRequest({
      method: "delete",
      url: API.CART.DELETE_PRODUCT,
      data: { cart_item_ids: cartItemIds },
    });
  };

  const handleConfirmRedirectURLPayment = async (paymentLink: string) => {
    return Swal.fire({
      title: `<div style='font-weight: 600; font-size: 18px; line-height: 24px; letter-spacing: 0.2px; color: #2E2E2E;'>コンビニ決済の手続き画面へ遷移します</div>`,
      html: `<div style='font-weight: 500; font-size: 14px; line-height: 100%; letter-spacing: 0.1px; overflow: hidden; line-height: 19.8px'><div><div style="color: #FA646A; text-align:center; font-weight:500; line-height: 20px; font-family: 'Noto Sans JP';"><p>まだ決済は完了していません。</p><p>遷移先の画面で必要項目を入力し</p><p>コンビニでの支払い手続きを完了させてください。</p></div><div style="text-align:center; margin-top: 20px; font-weight:500; line-height: 20px; font-family: 'Noto Sans JP';"><p>お支払いに必要な「お支払い番号」などの詳細は</p><p>手続き完了後の説明画面にてご案内いたします。</p><p>必ずご確認ください。</p></div></div></div>`,
      showCancelButton: true,
      cancelButtonText: "キャンセル",
      confirmButtonText: "決済手続き画面へ",
      confirmButtonColor: "#FE6D73",
      focusConfirm: false,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(paymentLink, "_blank");
        setTotal(0);
        setAmountDiscount(0);
      } 
    });
  };

  return (
    <section className="flex gap-[30px] px-[20px]">
      <div className="w-[510px]">
        <StudentCartMain
          className="px-[10px] animate-[show_0.5s_ease-in-out]"
          selectedIds={selectedIds}
          cartItems={cartItems?.filter(
            (cartItem) => cartItem.status === CART_COMMON.CART_IN_SHOP
          )}
          waitingApi={waitingApi}
          isSelected={isSelected}
          toggleSelect={toggleSelect}
          handleDelete={handleDelete}
          handleMove={handleMoveCartItem}
          handleChangeQuantity={handleChangeQuantity}
        />
        <StudentCartCoupon
          couponCode={couponCode}
          handleChange={handleChangeCoupon}
          handleEnterCode={handleEnterCouponCode}
        />
        {cartItems &&
          cartItems?.filter(
            (cartItem) => cartItem.status === CART_COMMON.CART_BUY_LATER
          ).length > 0 && (
            <StudentCartBuyLater
              selectedIds={selectedIds}
              handleDelete={handleDelete}
              cartItems={cartItems?.filter(
                (cartItem) => cartItem.status === CART_COMMON.CART_BUY_LATER
              )}
              isSelected={isSelected}
              toggleSelect={toggleSelect}
              handleMove={handleMoveCartItem}
              handleChangeQuantity={handleChangeQuantity}
            />
          )}
        <div className="flex items-center justify-between">
          <Button
            className="flex items-center gap-[10px] h-[40px] bg-secondary-light px-[15px] py-[10px] text-white rounded-[5px] active:scale-90"
            onClick={handleReturnBack}
          >
            <IoMdArrowBack size={24} />
            お買い物を続ける
          </Button>
          <Button
            style={{
              color: studentTheme.sub_color_first,
              borderColor: studentTheme.sub_color_first,
            }}
            className="h-[40px] px-[15px] py-[10px] text-primary-light border-[2px] border-primary-light rounded-[5px] active:scale-90"
            onClick={fetchCart}
          >
            <FiRefreshCw size={24} />
            カートを更新する
          </Button>
        </div>
      </div>
      <PaymentFramework
        total={total}
        amountDiscount={amountDiscount}
        waitingApi={waitingApi}
        services={services}
        paymentData={paymentData}
        handleOnChange={handleChoosePaymentMethodType}
        handleCheckout={handleRedirectToPaymentPage}
      />
    </section>
  );
};

export default StudentCart;
