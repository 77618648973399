import { useStorage } from "../../../../hooks/useStorage";
import { Question } from "../../../../types/student/unit";
import { TestData } from "../../../../types/student/unit";
import Answered from "./Answer";
import { SubmitData } from "./ExecuteTest";

type ListAnsweredProps = {
  testData: TestData;
  submitData: SubmitData;
  currentStep: number;
  className?: string;
};

const ListAnswered = ({
  testData,
  submitData,
  currentStep,
  className,
}: ListAnsweredProps) => {
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  const isCheckAnswered = (question: Question) => {
    let result = false;
    if (submitData?.questions?.length !== 0) {
      let questionData = submitData.questions?.find(
        (questionSubmit) => questionSubmit.question_id === question.id
      );
      result =
        typeof questionData !== "undefined" &&
        questionData.answers?.length !== 0;
    }

    return result;
  };

  return (
    <div
      className={`mx-[33px] mb-[50px] border-t border-danger-light ${className}`}
    >
      <div className="mx-[40px]">
        <div
          style={{ color: studentTheme.text_color }}
          className="font-[500] text-[14px] mt-[30px] mb-[12px]"
        >
          解答一覧
        </div>
        <div
          style={{
            color: studentTheme.text_color,
            borderColor: studentTheme.text_color,
          }}
          className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 content-center"
        >
          {testData?.questions?.map((question, index) => (
            <Answered
              isCorrect={isCheckAnswered(question)}
              keyOrder={index}
              key={question.id}
              randomOrder={Number(question.randomOrder || 0)}
              textColor={studentTheme.text_color}
              className={`${
                currentStep === Number((question.randomOrder || 0) - 1)
                  ? "pointer-events-none pointer-default"
                  : "cursor-pointer"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListAnswered;
