import { getTabObj } from '../../../helpers/parseData';
import { PATH_ADMIN_STUDENT } from '../../route/router';

const STUDENT_TAB_ITEM = {
  STUDENT_LIST: '受講者一覧',
  STUDENT_CREATE: '受講者新規追加',
  // STUDENT_CSV: '受講者一括追加',
  SETTINGS_CONFIG: '受講者設定',
};

const STUDENT_TAB_DATA = [
  getTabObj(STUDENT_TAB_ITEM.STUDENT_LIST, PATH_ADMIN_STUDENT.DEFAULT),
  getTabObj(STUDENT_TAB_ITEM.STUDENT_CREATE, PATH_ADMIN_STUDENT.CREATE),
  // Tab hidden for future phases
  // getTabObj(STUDENT_TAB_ITEM.STUDENT_CSV, PATH_ADMIN_STUDENT.CSV),
  // getTabObj(STUDENT_TAB_ITEM.SETTINGS_CONFIG, PATH_ADMIN_STUDENT.SETTING),
];

const STUDENT_STATUS_DATA = {
  CONFIRMING: 0,
  ACTIVE: 1,
  DEACTIVE: 2,
};


export { STUDENT_TAB_DATA, STUDENT_STATUS_DATA };
