const MESSAGE_ERROR = {
  SOMETHINGS_WENT_WRONG: 'エラーが発生しました。',
  END_DATE_BIGGER_START_DATE: '終了時刻には開始時刻以降の時刻を指定して下さい。',
};

const DELETE_CONFIRM_TITLE = {
  COURSE: 'このコースを削除してよろしいですか？',
  LECTURE: 'この講義を削除してよろしいですか？',
  CHAPTER: 'この章を削除してよろしいですか？',
  UNIT: 'この教材を削除してよろしいですか？',

  TEST_HISTORY: 'この履歴を削除してよろしいですか？',

  SHEDULE_EVENT: '予定を削除しますか？',
};
const DELETE_CONFIRM_CONTENT = {
  COURSE: 'コースに含まれる「講義」「単元」「教材」も含めて削除されます',
  LECTURE: '講義に含まれる「単元」「教材」も含めて削除されます',
  CHAPTER: '章に含まれる「教材」も含めて削除されます',

  TEST_HISTORY: 'このテスト履歴を削除したらテスト学習履歴も削除されます。本当に削除したいますか。',
};

const MESSAGE_COMMON = {
  NO_DATA_FOUND: 'データがありません',
  INVALID_FILE_TYPE: 'ファイルの拡張子が指定と異なります',
};

const MESSAGE_SPECIFIED = {
  REVISION_REVIEW: {
    NO_DATA_FOUND: '復習が必要なテストはありません',
  },
  HISTORY_REVISE_TEST: {
    NO_DATA_FOUND: '履歴がありません。'
  }
};

export {
  MESSAGE_ERROR,
  DELETE_CONFIRM_TITLE,
  DELETE_CONFIRM_CONTENT,
  MESSAGE_COMMON,
  MESSAGE_SPECIFIED,
};
