import React from "react";

const Section = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 6C15.75 4.75736 16.7574 3.75 18 3.75C19.2426 3.75 20.25 4.75736 20.25 6C20.25 7.24264 19.2426 8.25 18 8.25C16.7574 8.25 15.75 7.24264 15.75 6ZM20.4815 8.81156C21.2594 8.12445 21.75 7.11952 21.75 6C21.75 3.92893 20.0711 2.25 18 2.25C15.9289 2.25 14.25 3.92893 14.25 6C14.25 7.11952 14.7406 8.12445 15.5185 8.81157C13.9845 9.54657 12.8271 10.9418 12.4144 12.6293C11.9326 12.2948 11.4111 12.0136 10.8583 11.7941C12.0073 10.9271 12.75 9.55031 12.75 8C12.75 5.37665 10.6234 3.25 8 3.25C5.37665 3.25 3.25 5.37665 3.25 8C3.25 9.55031 3.99271 10.9271 5.14172 11.7941C2.27612 12.9317 0.25 15.7293 0.25 19V20C0.25 20.4142 0.585786 20.75 1 20.75C1.41421 20.75 1.75 20.4142 1.75 20V19C1.75 15.5482 4.54822 12.75 8 12.75C11.4518 12.75 14.25 15.5482 14.25 19V20C14.25 20.4142 14.5858 20.75 15 20.75C15.4142 20.75 15.75 20.4142 15.75 20V19C15.75 17.0031 14.9947 15.1825 13.7542 13.8085C13.8544 11.5501 15.717 9.75 18 9.75C20.3472 9.75 22.25 11.6528 22.25 14V14.5C22.25 14.9142 22.5858 15.25 23 15.25C23.4142 15.25 23.75 14.9142 23.75 14.5V14C23.75 11.713 22.4149 9.73791 20.4815 8.81156ZM8 4.75C6.20507 4.75 4.75 6.20507 4.75 8C4.75 9.79493 6.20507 11.25 8 11.25C9.79493 11.25 11.25 9.79493 11.25 8C11.25 6.20507 9.79493 4.75 8 4.75Z"
        fill={props.color || "#BEBEBE"}
      />
    </svg>
  );
};

export default Section;
