import { ChangeEvent, useEffect, useState } from "react";
import { Course } from "../../../types/student/course";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import Pagination from "../../admin/student/Pagination";
import { Link } from "react-router-dom";
import { PATH_PRODUCT } from "../../../services/constants/route/router";
import { formatNumberWithCommas } from "../../../services/helpers/parseData";
import dayjs from "dayjs";
import LayoutWaitingApi from "../common/LayoutWaitingApi";
import { getCommonDate, isExpiredDate } from "../../../services/helpers/date";

type Product = {
  id: number;
  course_name: string;
  lecture_name: string;
  name: string;
  price: number;
  stock: number;
  start_date_sell: string;
  end_date_sell: string;
  order_status: number;
  order_detail_id: number;
  expiry_date: string;
};

const ProductList = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [courseId, setCourseId] = useState<number>(0);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeFilterCourse = (e: ChangeEvent<HTMLSelectElement>) => {
    setCourseId(Number(e.target.value));
  };

  const TheadItem = ({
    className,
    name,
  }: {
    className?: string;
    name: string;
  }) => {
    return (
      <th
        scope="col"
        className={`bg-warning-lighter xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap ${className}`}
      >
        {name}
      </th>
    );
  };

  const getProductStatus = (status: number, expiry_date: string) => {
    if (status === 2 || status === 3) return {
      'label': "決済待ち",
      'color': 'bg-[#FAC46E]'
    }
    if (status === 1 && (!isExpiredDate(expiry_date) || !expiry_date)) return {
      'label': "購入済み",
      'color': 'bg-[#A9ACAB]'
    }

    return null;
  }

  useEffect(() => {
    const fetchCourse = async () => {
      await request.get(
        API.COURSE.GET_LIST_COURSE_FOR_COURSE_SHOP,
        (courses) => {
          setCourses(courses);
        }
      );
    };

    const fetchProduct = async () => {
      let url = `${API.PRODUCT.LIST}?page=${currentPage}`;
      if (courseId !== 0) url += `&course_id=${courseId}`;

      await request.get(url, (data) => {
        setProducts(data.products);
        setTotalPages(data.total_pages);
        setWaitingApi(false);
      });
    };

    fetchCourse();
    fetchProduct();
  }, [courseId, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [courseId]);

  return (
    <>
      <div className="px-[30px] min-h-[500px]">
        <div className="pt-[20px] text-[16px] font-[700]">講座一覧</div>
        <div className="my-[40px]">
          <select
            name="course_id"
            id=""
            className="max-w-[300px] px-[10px] pr-[20px] rounded-[5px] text-[11px] font-[400] truncate"
            onChange={handleChangeFilterCourse}
          >
            <option value="0">コースを選択してください</option>
            {Array.isArray(courses) &&
              courses.length > 0 &&
              courses.map((course, index) => (
                <option value={course.id} key={index}>
                  {course.name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <LayoutWaitingApi
            waitingApi={waitingApi}
            className="w-full min-h-[200px]"
          >
            <table className="w-full text-[12px] font-[500]">
              <thead className="">
                <tr>
                  <TheadItem name="コース" />
                  <TheadItem name="購入講座名" />
                  <TheadItem name="販売価格（税込）" className="ml-[2px]"/>
                  <TheadItem name="販売開始" />
                  <TheadItem name="販売期限" />
                  <TheadItem name="詳細" />
                </tr>
              </thead>
              <tbody className="animate-[show_0.5s_ease-in-out]">
                {products &&
                  Array.isArray(products) &&
                  products.length > 0 &&
                  products.map((product, index) => (
                    <tr key={index} className="h-[50px] text-[11px]">
                      <td
                        className="max-w-[124px] truncate px-[10px]"
                        title={product?.course_name || ""}
                      >
                        {product?.course_name || ""}
                      </td>
                      <td
                        className="max-w-[182px] truncate text-[12px] font-[500] px-[10px]"
                        title={product?.name || ""}
                      >
                        {product?.name || ""}
                      </td>
                      <td className="w-[130px] text-center">
                        {getProductStatus(product.order_status, product.expiry_date) !== null  ? (
                          <button className={`text-white text-[10px] font-[700] px-[7px] py-[5px] rounded-[5px] cursor-default ${getProductStatus(product.order_status, product.expiry_date)?.color}`}>
                            {getProductStatus(product.order_status, product.expiry_date)?.label}
                          </button>
                        ) : (
                          `${ 
                            !!product?.price
                              ? formatNumberWithCommas(product?.price || 0)
                              : 0
                          }円`
                        )}
                      </td>
                      <td className="w-[90px] text-center">
                        {product?.start_date_sell
                          ? dayjs(product.start_date_sell).format(
                              "YYYY/MM/DD"
                            )
                          : "ー"}
                      </td>
                      <td className="w-[90px] text-center">
                        {product?.end_date_sell
                          ? dayjs(product.end_date_sell).format(
                              "YYYY/MM/DD"
                            )
                          : "ー"}
                      </td>
                      <td className="min-w-[70px] text-center">
                        <Link
                          className="bg-danger p-[5px] text-white rounded-[4px] font-[700]"
                          to={`${PATH_PRODUCT.DETAIL}?product_id=${product.id}`}
                        >
                          商品詳細
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </LayoutWaitingApi>
        </div>
      </div>
      {totalPages && totalPages !== 0 && (
        <Pagination
          handlePageChange={handlePageChange}
          totalItems={totalPages}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default ProductList;
