import { ElementProps } from "../../../types/globals";

type MemoTypeBgColorProps = ElementProps<HTMLDivElement> & {
  title: string;
  backgroundColorId: string;
  className: string;
};

const MemoTypeBgColor = ({
  backgroundColorId,
  title,
  className,
  ...props
}: MemoTypeBgColorProps) => {
  const backgroundColors = ["FFFFFF", "FFEBEB", "FEF9EF", "D7F5F2"];

  return (
    <div className={`flex flex-row items-center gap-[15px] ${className}`}>
      <div className="text-secondary text-[9px] font-[300]">{title}</div>
      {backgroundColors.map((backgroundColor) => (
        <div
          id={backgroundColor}
          className={`w-[20px] h-[20px] bg-[#${backgroundColor}] border border-secondary-light ${
            backgroundColorId === backgroundColor &&
            "border-[3px] !border-[#a3cbff] rounded-[2px]"
          }`}
          key={backgroundColor}
          {...props}
        ></div>
      ))}
    </div>
  );
};

export default MemoTypeBgColor;
