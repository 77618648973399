import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Sidebar from "../../../../components/student/Profile/Sidebar";
import StudentLayout from "../../../../layouts/student";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { swalError, swalLoading } from "../../../../services/helpers/swal";
import { User } from "../../../../types/user";
import { useStorage } from "../../../../hooks/useStorage";
import { setDefaultResultOrder } from "dns";
import PurchaseHistoryList from "../../../../components/student/Profile/Order/PurchaseHistoryList";

const PurchaseHistory = () => {
  const [purchaseHistory, setPurchaseHistory] = useState<any>({});
  const [submitData, setSubmitData] = useState<User>({});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [tempEmail, setTempEmail] = useState<string>("");
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  return (
    <>
      <StudentLayout sidebar={<Sidebar />} cover>
        <div className="w-full">
            {/* title */}
            <div className="border-b border-danger-light pb-[14px] w-full mb-[60px] text-[14px] font-[500] leading-[100%]">
                購入履歴・領収書
            </div>
            {/* purchase history */}
            <div className="mx-auto w-[90%]">
                <p className="pb-[30px] font-bold text-[14px]">購入履歴</p>
                <PurchaseHistoryList />
            </div> 
      </div>
      </StudentLayout>
    </>
  );
};

export default PurchaseHistory;
