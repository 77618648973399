/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BiTrendingUp } from "react-icons/bi";
import { FiPenTool, FiRefreshCw } from "react-icons/fi";
import { MdSchedule } from "react-icons/md";
import { MODE_EXECUTE_TEST } from "../../../../services/constants/student/unit";
import {
  formatTotalTestTime,
  minimizeFormatTime,
} from "../../../../services/helpers/formatTime";
import { TestData } from "../../../../types/student/unit";
import ProgressBar from "../../common/ProgressBar";
import { useStorage } from "../../../../hooks/useStorage";
import { StudentThemeType } from "../../../../types/admin/student_theme";

type HeaderExecuteTestProps = {
  testType: number;
  currentQuestion: number;
  totalQuestion: number;
  testData: TestData;
  isLimitTime: boolean;
  takenTime: number;
};

const HeaderExecuteTest = ({
  testType,
  currentQuestion,
  totalQuestion,
  testData,
  isLimitTime,
  takenTime,
}: HeaderExecuteTestProps) => {
  const maxPercent = 100;
  let percentage = Math.round((currentQuestion / totalQuestion) * 100);
  percentage = percentage > maxPercent ? maxPercent : percentage;
  const { getLocalStorage } = useStorage();
  const studentTheme: StudentThemeType = getLocalStorage("theme");
  const [time, setTime] = useState<number>(0);

  //make a stopwatch display
  useEffect(() => {
    if (testType !== MODE_EXECUTE_TEST.MAIN) return;

    const interval = setInterval(() => {
      setTime((time) => {
        return Number(takenTime) && time === 0
          ? time + 1000 + Number(takenTime)
          : time + 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [takenTime]);

  let ico: React.ReactElement, label: React.ReactElement;
  switch (testType) {
    case MODE_EXECUTE_TEST.PRACTICE:
      ico = (
        <div
          style={{
            backgroundColor: studentTheme.sub_color_first,
          }}
          className="w-[50px] h-[50px] bg-primary-light flex items-center justify-center text-white mr-[15px]"
        >
          <BiTrendingUp size={30} />
        </div>
      );

      label = (
        <div
          style={{
            backgroundColor: studentTheme.sub_color_first,
          }}
          className="py-[1px] px-2 bg-primary-light rounded-[5px]"
        >
          練習モード
        </div>
      );
      break;
    case MODE_EXECUTE_TEST.MAIN:
      ico = (
        <div className="w-[50px] h-[50px] bg-danger flex items-center justify-center text-white mr-[15px]">
          <FiPenTool size={25} />
        </div>
      );

      label = (
        <div className="py-[1px] px-2 bg-danger rounded-[5px]">本番モード</div>
      );
      break;
    case MODE_EXECUTE_TEST.REVISE:
      ico = (
        <div className="w-[50px] h-[50px] bg-success flex items-center justify-center text-white mr-[15px]">
          <FiRefreshCw size={25} />
        </div>
      );

      label = (
        <div className="py-[1px] px-2 bg-success rounded-[5px]">復習モード</div>
      );
      break;
    default:
      return <></>;
  }

  return (
    <div className="px-[29px] mt-[42px]">
      <div className="border-b border-danger-light pb-[22px] px-[13px] flex items-center justify-between">
        <div className="min-h-[75px] flex gap-[20px] items-center">
          <div className="">{ico}</div>
          <div className="">
            <div className="flex gap-[15px] items-center mb-[12.5px]">
              <div className="font-[700] text-[12px] leading-[17px] tracking-[0.02em] text-white">
                {label}
              </div>
              <div className="font-[500] text-[13px] leading-[100%] text-secondary truncate w-[370px] h-[16px]">
                {testData?.course_name ? `${testData?.course_name}｜` : ""}
                {testData?.lecture_name ? `${testData?.lecture_name}｜` : ""}
                {testData?.chapter_name ? `${testData?.chapter_name}` : ""}
              </div>
            </div>
            <div
              style={{ color: studentTheme.text_color }}
              className="text-secondary-dark mb-[10px] font-[700] text-[18px] leading-[100%] tracking-[0.02em] truncate w-[470px] h-[21px]"
            >
              {testData?.test_name || ""}
            </div>
            <div className="flex items-center text-secondary text-[12px] font-[500] leading-[100%] tracking-[0.02em] gap-[13px]">
              <div className="">{percentage}%</div>
              <div className="">
                ({currentQuestion}問目/{totalQuestion}
                問中)
              </div>
              <ProgressBar progress={percentage} />
            </div>
          </div>
        </div>
        {isLimitTime && (
          <div className="text-secondary text-[12px] leading-[100%] font-[500] tracking-[0.02em]">
            <div className="flex items-center gap-[7px] mb-[4px] justify-center">
              <MdSchedule size={21} />
              <div style={{ color: studentTheme.text_color }} className="">
                経過時間 {minimizeFormatTime(time)}
              </div>
            </div>
            <div className="border border-secondary rounded-[5px] px-[21px] py-[6px] flex items-center">
              制限時間：{formatTotalTestTime(testData?.time)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default HeaderExecuteTest;
