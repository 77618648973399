import React, { useState } from "react";
import ErrorBox from "../../../components/commons/form/ErrorBox";
import Sidebar from "../../../components/student/Profile/Sidebar";
import StudentLayout from "../../../layouts/student";
import { makeRequest } from "../../../services/axios/axios";
import { MESSAGE_ERROR } from "../../../services/constants/message";
import { API } from "../../../services/constants/route/api";
import {
  swalLoading,
  swalMessage,
  swalSuccess,
} from "../../../services/helpers/swal";
import { ErrorData } from "../../../types/globals";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../services/constants/route/router";
import { useStorage } from "../../../hooks/useStorage";

type PasswordData = {
  current_password?: string;
  new_password?: string;
  new_password_confirmation?: string;
};

const PasswordChanging = () => {
  const initData: PasswordData = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };
  const [submitData, setSubmitData] = useState<PasswordData>(initData);
  const [errors, setErrors] = useState<ErrorData>({});
  let navigate = useNavigate();
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitData({ ...submitData, [e.target.name]: e.target.value });
  };

  const onSubmitHandle = async () => {
    // swalLoading();

    const result = await makeRequest({
      method: "patch",
      url: API.STUDENT.UPDATE_PASSWORD,
      data: submitData,
    });

    if (!result.data) {
      setErrors(result.error as any);
      return swalMessage("", "", "error", {
        timer: 1000,
        showConfirmButton: false,
        footer: MESSAGE_ERROR.SOMETHINGS_WENT_WRONG,
      });
    }

    swalSuccess();
    setErrors({});
    setSubmitData(initData);
  };

  return (
    <StudentLayout sidebar={<Sidebar />} cover>
      <div className="w-full h-full h-[1075px]">
        {/* title */}
        <div className="border-b border-danger-light pb-[14px] w-full mb-[60px] text-[14px] font-[500] leading-[100%]">
          パスワード変更
        </div>

        <div className="pl-[32px]">
          {/* describe */}
          <div className="text-[16px] font-[700] leading-[100%]">
            パスワード変更
          </div>
          <div className="text-[12px] leading-[190%] mb-[43px]">
            <div className="">
              パスワードを変更するには、ログイン時に使用するパスワードを入力し、「変更する」ボタンをクリックして下さい。
            </div>
            <div
              style={{ color: studentTheme.main_color_first }}
              className="text-primary cursor-pointer"
              onClick={() => navigate(ROUTE.FORGOT_PASSWORD)}
            >
              パスワードを忘れた方はこちら
            </div>
          </div>

          {Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}

          <div className="flex items-center mb-[5px]">
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter pl-[14px] h-full">
              <div className="w-full flex justify-between items-center">
                <div className="min-h-[40px] flex items-center">
                  現在のパスワード
                </div>
                <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                  必須
                </div>
              </div>
            </div>
            <div className="ml-[13px] w-[55%]">
              <input
                type="password"
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[34px] rounded-[5px] border border-danger-light"
                name="current_password"
                value={submitData.current_password}
                onChange={onChangeHandle}
                required
              />
            </div>
          </div>

          <div className="flex items-center mb-[5px]">
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter pl-[14px] h-full">
              <div className="w-full flex justify-between items-center">
                <div className="min-h-[40px] flex items-center">
                  新パスワード
                </div>
                <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                  必須
                </div>
              </div>
            </div>
            <div className="ml-[13px] w-[55%]">
              <input
                type="password"
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[34px] rounded-[5px] border border-danger-light placeholder:text-secondary-light"
                placeholder="半角英数・記号のみ８〜20字"
                name="new_password"
                value={submitData.new_password}
                onChange={onChangeHandle}
                required
              />
            </div>
          </div>

          <div className="flex items-center min-h-[40px] mb-[54px]">
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px] h-full">
              <div className="w-full flex justify-between items-center">
                <div className="min-h-[40px] flex items-center">
                  新パスワード（確認用）
                </div>
                <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                  必須
                </div>
              </div>
            </div>
            <div className="ml-[13px] w-[55%] h-full">
              <div className="w-full">
                <input
                  type="password"
                  className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[34px] rounded-[5px] border border-danger-light"
                  name="new_password_confirmation"
                  value={submitData.new_password_confirmation}
                  onChange={onChangeHandle}
                  required
                />
                <div className="text-[10px] leading-[190%]">
                  ※確認のため同じパスワードを入力してください
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center items-center mb-[42px]">
            <button
              style={{ backgroundColor: studentTheme.main_color_first }}
              className="bg-primary px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
              onClick={onSubmitHandle}
            >
              送信する
            </button>
          </div>
        </div>
      </div>
    </StudentLayout>
  );
};

export default PasswordChanging;
