import React from 'react';
import SettingsConfig from '../../../components/admin/student/SettingsConfig';
import StudentBase from '.';

const StudentSettingPage = () => {
  return (
    <StudentBase title="受講者管理  ｜ 受講者設定">
      <SettingsConfig />
    </StudentBase>
  );
};

export default StudentSettingPage;