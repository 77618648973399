import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { ChartProps, Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

// interface PropsType extends Omit<ChartProps<'doughnut'>, 'type'>{
//   type?: string;
// }

const DoughnutChart = ({
  data,
  options,
  ...props
}: Omit<ChartProps<'doughnut'>, 'type'> & { type?: string }) => {
  return <Doughnut data={data} options={options} {...props}/>;
};

export default DoughnutChart;
