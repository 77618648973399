import type { ChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { ChartProps, Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export type BarChartProps = Omit<ChartProps<'bar'>, 'type'>;

const BarChart = ({ data, options, ...props }: BarChartProps) => {
  const defaultChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          callback: function (val, index) {
            // Hide every 2nd tick label
            if (typeof val === 'number') {
              return index % 2 === 0 ? this.getLabelForValue(val) : '';
            }
          },
          padding: 20,
        },

        border: {
          display: false,
        },
      },
    },
  };

  return <Bar data={data} options={options || defaultChartOptions} {...props} />;
};

export default BarChart;
