import React from "react";
import { Link, useLocation } from "react-router-dom";
import { PROFILE_SIDEBAR_DATA } from "../../../services/constants/student/sidebar";
import SidebarAvatar from "./SidebarAvatar";
import { User } from "../../../types/user";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

type SidebarProps = {
  data?: User;
};

const Sidebar = ({ data }: SidebarProps) => {
  const location = useLocation();
  const { studentTheme } = useStudentThemeContext();
  
  return (
    <section className="bg-white w-full max-w-[260px] flex justify-center h-full">
      <div className="w-full max-w-[210px] mt-[35px]">
        {/* avatar */}
        <SidebarAvatar />
        {/* menu */}
        <div className="w-full">
          {PROFILE_SIDEBAR_DATA.map((data, index) => (
            <div
              key={index}
              className="border-b border-danger-light pb-[28px] mb-[15px]"
            >
              <div
                style={{
                  color: studentTheme.main_color_first,
                }}
                className="font-[500] text-[14px] leading-[100%] tracking-[0.02em] text-primary mb-[15px]"
              >
                {data.label}
              </div>
              <div className="">
                {data.child.map((childData, childIndex) => (
                  <Link to={childData.href} key={childIndex}>
                    <div
                    style={{
                      backgroundColor: (childData.href === location.pathname || childData.href === `${location.pathname}/`) ? studentTheme.sub_color_second : ""
                    }}
                      className={`${
                        (childData.href === location.pathname ||
                          childData.href === `${location.pathname}/`) &&
                        "bg-warning-light"
                      } font-[500] text-[14px] leading-[100%] h-full min-h-[40px] flex items-center pl-[17px]`}
                    >
                      {childData.label}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
