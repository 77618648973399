import React from "react";

const ProgressBar = ({ percent }: { percent: number }) => {
  return (
    <div className="w-full text-center pb-[10px]">
      <div className="mb-[5px]">アップロード中: {percent.toFixed(2)}%</div>
      <div className="progress-bar">
        <div
          className="progress h-[10px] bg-primary rounded-lg"
          style={{ width: `${Math.round(percent)}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
