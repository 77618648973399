import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { ReportRatioFilterData } from '../../../../components/student/StudyTool/Report/ChartStudyRatio';
import { Object } from '../../../../types/globals';
import { formatRatioReportValue } from '../../../helpers/formatTime';

type ChartDataSet = ChartData<'bar'>['datasets'][0];

type StudyReportUtils<TType extends ChartType = 'bar'> = {
  chartRatioOptions: ChartOptions<TType>;
  initChartData: ChartData<TType>;
  genFilterOption: () => ReportRatioFilterData;
  genDataset: (
    label: ChartDataSet['label'],
    data: ChartDataSet['data'],
    totalLength: number,
    index: number,
  ) => ChartData<TType>['datasets'][0];
  genChartData: (dataset: ChartData<TType>['datasets']) => ChartData<TType>;
};

const generateRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const studyReportUtils: StudyReportUtils = {
  chartRatioOptions: {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }

            if (context.parsed.x !== null) {
              label += formatRatioReportValue(context.parsed.x);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        stacked: true,
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        stacked: true,
      },
    },
  },
  initChartData: {
    labels: [''],
    datasets: [],
  },
  genFilterOption: () => ({
    id: new Date().getTime(),
  }),
  genDataset: (label, data, totalLength, index) => {
    let borderRadius: number | Object[] = [{ bottomRight: 12, topRight: 12 }],
      borderSkipped = true;

    const isFirst = index === 0;
    const isLast = index === totalLength - 1;
    if (totalLength > 1) {
      if (isFirst) {
        borderRadius = [{ bottomLeft: 12, topLeft: 12 }];
        borderSkipped = false;
      }
      if (isLast) {
        borderRadius = [{ bottomRight: 12, topRight: 12 }];
        borderSkipped = false;
      }
    }

    if (totalLength === 1) {
      borderRadius = 11;
      borderSkipped = false;
    }

    return {
      label,
      data,
      backgroundColor: generateRandomColor(),
      maxBarThickness: 43,
      borderRadius,
      borderSkipped,
      borderWidth: 0,
    };
  },
  genChartData: (datasets) => ({ labels: [''], datasets }),
};

export { studyReportUtils };
