import React from "react";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { TbReload } from "react-icons/tb";

type SidebarGroupItemProps = {
  label?: string;
  children: React.ReactNode;
  reloadCourse?: boolean;
  handleRefetchCourses?: () => void;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const SidebarGroupItem = ({
  label,
  children,
  reloadCourse = false,
  handleRefetchCourses,
  ...props
}: SidebarGroupItemProps) => {
  const { studentTheme } = useStudentThemeContext();

  return (
    <div {...props}>
      {label && (
        <div className="flex justify-between items-center mb-[13px]">
          <div
            style={{ backgroundColor: studentTheme.main_color_first }}
            className="text-center pt-[5px] font-[500] text-[11px] leading-[100%] w-[80px] rounded-[5px] bg-primary text-white h-[22px]"
          >
            {label}
          </div>
          {reloadCourse && (
            <TbReload
              color={studentTheme.main_color_first}
              size={20}
              className="cursor-pointer"
              onClick={handleRefetchCourses}
            />
          )}
        </div>
      )}
      <div className="cursor-pointer">{children}</div>
    </div>
  );
};

export default SidebarGroupItem;
