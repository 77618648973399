import { getTabObj } from '../../../helpers/parseData';
import { PATH_ADMIN_COURSE } from '../../route/router';

const COURSE_TAB_ITEM = {
  COURSE_LIST: 'コース一覧',
  COURSE_REGISTRATION: 'コースの新規登録',
  LECTURE_REGISTRATION: '講義の新規登録',
  CHAPTER_REGISTRATION: '章の新規登録',
  // BULK_REGISTRATION: '一括登録',
};

const COURSE_TAB_DATA = [
  getTabObj(COURSE_TAB_ITEM.COURSE_LIST, PATH_ADMIN_COURSE.DEFAULT),
  getTabObj(COURSE_TAB_ITEM.COURSE_REGISTRATION, PATH_ADMIN_COURSE.COURSE_REGISTRATION),
  getTabObj(COURSE_TAB_ITEM.LECTURE_REGISTRATION, PATH_ADMIN_COURSE.LECTURE_REGISTRATION),
  getTabObj(COURSE_TAB_ITEM.CHAPTER_REGISTRATION, PATH_ADMIN_COURSE.CHAPTER_REGISTRATION),
  // getTabObj(COURSE_TAB_ITEM.BULK_REGISTRATION, PATH_ADMIN_COURSE.BULK_REGISTRATION),
];

const COURSE_TABLE_ITEM_TYPE = {
  COURSE: 'course',
  LECTURE: 'lecture',
  CHAPTER: 'chapter',
  UNIT: 'unit'
} as const;

export { COURSE_TAB_DATA, COURSE_TAB_ITEM, COURSE_TABLE_ITEM_TYPE };
