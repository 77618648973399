import {
  SUBMIT_EXECUTE_TEST_TYPE,
  TEST_ANSWER_STATUS,
} from "../../../../services/constants/student/unit";
import { ElementProps } from "../../../../types/globals";

type AnsweredProps = {
  isCorrect: boolean;
  keyOrder: number;
  randomOrder?: number;
  textColor?: string;
} & ElementProps<HTMLButtonElement>;

const Answered = ({
  isCorrect,
  keyOrder,
  className,
  randomOrder,
  textColor,
  ...props
}: AnsweredProps) => {
  return (
    <button
      data-random-order={`${randomOrder}`}
      id={`${SUBMIT_EXECUTE_TEST_TYPE.CHANGE}`}
      className={`${className}`}
      {...props}
    >
      <div className="flex justify-items-center h-[40px] border">
        <div className="w-[60px] h-[30px] m-[5px] bg-warning-lighter text-center cursor-pointer">
          <span
            style={{
              color: textColor,
              borderColor: textColor,
            }}
            className="font-semibold border-b border-secondary-dark text-[14px]"
          >
            {keyOrder + 1}
          </span>
        </div>
        <div className="flex-1 w-[calc(100% - 60px)] pt-[8px] text-center text-[14px]">
          <span
            style={{
              borderColor: textColor,
            }}
            className={
              isCorrect ? "border-b border-secondary-dark" : "text-[#FE6D73]"
            }
          >
            {isCorrect
              ? TEST_ANSWER_STATUS.ANSWERED
              : TEST_ANSWER_STATUS.UNANSWERED}
          </span>
        </div>
      </div>
    </button>
  );
};

export default Answered;
