import { RxCaretSort } from "react-icons/rx";
import StudentStatusButton from "./StudentStatusButton";
import { useSectionManagementContext } from "../../../../context/Section/Management";
import { useState, useEffect } from "react";
import { Section } from "../../../../types/admin/section";
import { sortArrayByKey } from "../../../../services/helpers/parseData";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";

const TableManagement = () => {
  const {
    mainSections,
    students,
    checkedStudentIds,
    checkedObj,
    setCheckedStudents,
    setCheckedStudentIds,
    setCheckedObj,
    setStudents,
  } = useSectionManagementContext();
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [sortedName, setSortedName] = useState<boolean>(false);
  const [sortedUsername, setSortedUsername] = useState<boolean>(false);
  const [sortedStatus, setSortedStatus] = useState<boolean>(false);
  const [sortedSectionName, setSortedSectionName] = useState<boolean>(false);
  const { getParamValue } = useObjectRoutes();
  const sectionId = getParamValue("section_id");

  const handleCheckedStudent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentCheckedChildId = e.target.getAttribute("data-id");
    const isChecked = e.target.checked;

    if (currentCheckedChildId) {
      setCheckedObj({ ...checkedObj, [currentCheckedChildId]: isChecked });
    } else {
      setCheckedAll(!checkedAll);
      const newCheckedObj = checkedObj;

      students &&
        students.forEach((student) => {
          if (!student?.is_disabled)
            newCheckedObj[student.id as number] = isChecked;
        });

      setCheckedObj({ ...checkedObj, ...newCheckedObj });
    }
  };

  const isCheckSectionMain = (sectionId: Number) => {
    let section = mainSections?.find((section) => section.id === sectionId);
    return typeof section !== "undefined";
  };

  useEffect(() => {
    if (checkedStudentIds && !!checkedStudentIds.length) return;
    setCheckedAll(false);
  }, [checkedStudentIds]);

  useEffect(() => {
    if (!students) return;
    const checkedArray = Object.entries(checkedObj);
    const filteredEntries = checkedArray.filter(
      ([key, value]) => value === true
    );
    const filteredIds = Object.keys(Object.fromEntries(filteredEntries));

    const parsedFilteredIds: number[] = [];
    const filteredStudents: Section["students"] = [];

    filteredIds.forEach((id) => {
      const studentById = students.find((student) => student.id === Number(id));

      if (studentById) {
        parsedFilteredIds.push(Number(id));
        filteredStudents.push(studentById);
      }
    });

    setCheckedStudentIds(parsedFilteredIds);
    setCheckedStudents(filteredStudents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedObj]);

  useEffect(() => {
    if (!students) return;
    const sortedArr = sortArrayByKey(students, "first_name");

    if (sortedName) return setStudents(sortedArr);
    return setStudents(sortedArr.reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedName]);

  useEffect(() => {
    if (!students) return;
    const sortedArr = sortArrayByKey(students, "username");

    if (sortedUsername) return setStudents(sortedArr);
    return setStudents(sortedArr.reverse());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedUsername]);

  useEffect(() => {
    if (!students) return;
    const sortedArr = sortArrayByKey(students, "status");

    if (sortedStatus) return setStudents(sortedArr);
    return setStudents(sortedArr.reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedStatus]);

  useEffect(() => {
    if (!students) return;
    const sortedArr = sortArrayByKey(students, "section_name");

    if (sortedStatus) return setStudents(sortedArr);
    return setStudents(sortedArr.reverse());
  }, [sortedSectionName]);

  return (
    <section className="w-full mt-[30px]">
      <table className="student_table">
        <thead>
          <tr className="text-[13px]">
            <th className="w-[5%] !text-center !pl-0">
              <input
                type="checkbox"
                name="check_student"
                id=""
                data-id={null}
                onChange={handleCheckedStudent}
                checked={checkedAll}
              />
            </th>
            <th className="w-[30%]">
              <div className="flex items-center gap-[11px]">
                氏名
                <RxCaretSort
                  className="cursor-pointer text-secondary"
                  size={20}
                  onClick={() => setSortedName(!sortedName)}
                />
              </div>
            </th>
            {sectionId && isCheckSectionMain(parseInt(sectionId)) && (
              <th className="w-[25%]">
                <div className="flex items-center gap-[11px]">
                  下位セクション
                  <RxCaretSort
                    className="cursor-pointer text-secondary"
                    size={20}
                    onClick={() => setSortedSectionName(!sortedSectionName)}
                  />
                </div>
              </th>
            )}
            <th className="w-[25%]">
              <div className="flex items-center gap-[11px]">
                ログインID
                <RxCaretSort
                  className="cursor-pointer text-secondary"
                  size={20}
                  onClick={() => setSortedUsername(!sortedUsername)}
                />
              </div>
            </th>
            <th className="min-w-[130px] w-[15%]">
              <div className="flex items-center gap-[11px]">
                ステータス
                <RxCaretSort
                  className="cursor-pointer text-secondary"
                  size={20}
                  onClick={() => setSortedStatus(!sortedStatus)}
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {students && students.length ? (
            <>
              {students.map((student) => (
                <tr key={student.id}>
                  <td className="!text-center !pl-0">
                    <input
                      type="checkbox"
                      name="check_student"
                      id=""
                      className={`${
                        student.is_disabled
                          ? "bg-gray-300 pointer-events-none"
                          : "cursor-pointer"
                      }`}
                      data-id={student.id}
                      onChange={handleCheckedStudent}
                      checked={checkedObj[student.id as number]}
                      disabled={student.is_disabled}
                    />
                  </td>
                  <td className="text-[14px] font-[500] leading-[100%] text-primary">{`${student.last_name} ${student.first_name}`}</td>
                  {sectionId && isCheckSectionMain(parseInt(sectionId)) && (
                    <td className="text-[12px] leading-[100%]">
                      {student.section_name ?? "-"}
                    </td>
                  )}
                  <td className="text-[12px] leading-[100%]">
                    {student.username}
                  </td>
                  <td>
                    <div className="w-full flex justify-center">
                      <StudentStatusButton status={student.status || 0} />
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr className="">
              <td colSpan={6}>
                <div className="text-center">データがありません</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </section>
  );
};

export default TableManagement;
