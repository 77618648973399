/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import EventBoard from "../../Schedule/EventBoard";
import EventForm from "./EventForm";
import { useSearchParams } from "react-router-dom";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import {
  EventsInMonth,
  EventSchedule,
  Event as EventType,
} from "../../../../types/student/event";
import {
  getCommonDate,
  getDate,
  getFirstCalendarDate,
  getLastCalendarDate,
  isValidDate,
} from "../../../../services/helpers/date";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import EventList from "./EventList";

const Event = () => {
  const { getParamValue } = useObjectRoutes();
  const initDate = isValidDate(getParamValue("date"))
    ? new Date(getParamValue("date") as string)
    : new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(initDate);
  const [, setSearchParams] = useSearchParams();
  const [eventsInMonth, setEventsInMonth] = useState<EventsInMonth>({});
  const [eventsInDay, setEventsInDay] = useState<EventSchedule[]>([]);
  const [isHandleEvent, setIsHandleEvent] = useState<boolean>(false);
  const getState = getParamValue("state");

  useEffect(() => {
    // Set current params to chosen date
    if (!isValidDate(selectedDate)) return;
    if (!getState || getCommonDate(initDate) !== getCommonDate(selectedDate)) {
      setSearchParams({ date: selectedDate.toISOString().slice(0, 10) });
    }

    // get first date and end date in calendar
    const firstOfDate = getFirstCalendarDate(selectedDate);
    const endOfMonth = getLastCalendarDate(selectedDate);

    // Fetch current event data by chosen date
    const fetchEventData = async () => {
      const eventsInMonthsParamsObj = {
        start_date: firstOfDate,
        end_date: endOfMonth,
      };

      const eventsInMonth = request.get(
        `${API.SCHEDULE.LIST}?${paramizeObject(eventsInMonthsParamsObj)}`,
        (events) => {
          setEventsInMonth(events);
          if (events[getCommonDate(selectedDate)] !== undefined) {
            setEventsInDay(events[getCommonDate(selectedDate)]);
          } else {
            setEventsInDay([]);
          }
        }
      );

      await Promise.all([eventsInMonth]);
    };

    fetchEventData();
    setIsHandleEvent(false);
  }, [selectedDate, isHandleEvent]);

  return (
    <section className="flex justify-between mt-[15px] gap-[63px]">
      <div className="w-full max-w-[260px]">
        <EventBoard
          onRefHandle={(e) =>
            setSelectedDate(new Date(e?.current?.value || new Date()))
          }
          existedEvents={eventsInMonth}
          initialDate={initDate}
        />
      </div>
      <div className="w-full max-w-[478px]">
        {Array.isArray(eventsInDay) && eventsInDay.length > 0 && !getState ? (
          <EventList
            currentDate={getCommonDate(initDate)}
            setIsHandleEvent={setIsHandleEvent}
          />
        ) : (
          <EventForm
            events={eventsInDay}
            initSelectedDate={selectedDate}
            setIsHandleEvent={setIsHandleEvent}
          />
        )}
      </div>
    </section>
  );
};

export default Event;
