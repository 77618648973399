import type { FC } from "react";
import { Link } from "react-router-dom";

interface LogoProps {
  otherStyle: string | undefined;
  children?: JSX.Element;
  logo_admin_path?: string;
}

const Logo: FC<LogoProps> = ({ otherStyle, logo_admin_path }) => {
  return (
    <Link to={"/"}>
      <div className={otherStyle}>
        <img
          className="w-full h-full object-cover"
          src={logo_admin_path}
          alt="None"
        />
      </div>
    </Link>
  );
};

export default Logo;
