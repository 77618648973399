import React, { useState, useEffect } from 'react';
import { isUsableArr } from '../../../../services/helpers/etc';
import RevisionItem from './RevisionItem';
import { REVISION_LIST_PERIOD_OPTION_DATA } from '../../../../services/constants/student/report';
import ReviseHistoryList from './ReviseHistoryList';
import { Revision } from '../../../../types/student/studyToolRevise';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { paramizeObject } from '../../../../services/helpers/parseData';
import { MESSAGE_SPECIFIED } from '../../../../services/constants/message';
import awardIcon from '../../../../assests/images/icons/award.svg';

type RevisionReviewDashboardFilter = {
  period: number;
};

const RevisionReviewDashboard = () => {
  const [revisions, setRevisions] = useState<Revision[]>([]);
  const [filter, setFilter] = useState<RevisionReviewDashboardFilter>({ period: 0 });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetch = async () => {
      await request.get(
        `${API.REVISION_REVIEW.GET_STUDY_REVISION_LIST}?${paramizeObject(filter)}`,
        setRevisions,
      );
    };
    fetch();
  }, [filter]);

  return (
    <section className="mx-[25px]">
      <div className="text-[14px] font-[700] leading-[20px] tracking-[2%] mt-[30px]">
        復習テスト
      </div>
      <div className="w-[730px] gap-[10px] overflow-x-auto flex pb-[10px] mt-[17px]">
        {isUsableArr(revisions) &&
          revisions.map((revision, index) => (
            <RevisionItem key={revision.id || index} revision={revision} />
          ))}

        <div className="text-secondary">
          {revisions && Array.isArray(revisions) && !revisions.length ? (
            <div className="flex items-center gap-[10px]">
              <div className="w-[24px] h-[24px] rounded-full bg-danger-light flex justify-center items-center">
                <img src={awardIcon} alt="" />
              </div>
              <div className="text-[13.61px] font-[500] leading-[19.71px] tracking-[2%]">
                {MESSAGE_SPECIFIED.REVISION_REVIEW.NO_DATA_FOUND}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className="text-[13.6px] font-[700] tracking-[0.272px] mt-[55px] mb-[13px]">
        復習履歴
      </div>
      <div className="items-center text-secondary">
        <div className="flex flex-col">
          <div className="text-[10px] tracking-[0.2px] mb-[1.2px]">期間</div>
          <select
            name="period"
            className="w-[108px] h-[32px] rounded-[16px] text-[11px] border border-danger-light"
            onChange={handleChange}
          >
            {REVISION_LIST_PERIOD_OPTION_DATA.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <ReviseHistoryList />
    </section>
  );
};

export default RevisionReviewDashboard;
