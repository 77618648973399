import React from 'react';
import StudentLayout from '../../../../layouts/student';
import { WithChildren } from '../../../../types/globals';
import Title from '../../../../components/student/MyPage/Title';
import '../../../../assests/styles/studyTool.css'

const RevisionReviewPageBase = ({ children }: WithChildren) => {
  return (
    <StudentLayout gap>
      <Title content="復習" />
      {children}
    </StudentLayout>
  );
};

export default RevisionReviewPageBase;
