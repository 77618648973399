import React, { useState, useRef, useEffect } from "react";

const ParagraphExpander = ({ text }: { text: string }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const paragraphRef = useRef<HTMLParagraphElement>(null);

  const maxLines = 3;

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    const paragraphElement = paragraphRef.current;
    if (paragraphElement) {
      const computedStyle = window.getComputedStyle(paragraphElement);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const maxHeight = lineHeight * maxLines;
      const actualHeight = paragraphElement.clientHeight;
      setShowButton(actualHeight > maxHeight);
      toggleExpansion();
    }
  }, [text]);

  return (
    <>
      <p
        ref={paragraphRef}
        className={`${
          isExpanded ? "" : "line-clamp-3"
        } whitespace-pre-wrap max-w-[800px] max-sm:mx-[5px]`}
      >
        {text}
      </p>
      {showButton && (
        <button
          onClick={toggleExpansion}
          className="relative mt-[15px] text-secondary flex items-center justify-center flex-col text-[11px] border-[1px] border-secondary-light rounded-full min-h-[24px] px-[37px] pb-[2px]"
        >
          {isExpanded ? "隠す" : "もっと読む"}
          <div
            className={`${
              isExpanded ? "up_polygon" : "down_polygon"
            } w-[15px] h-[7px] bg-secondary-light`}
          ></div>
        </button>
      )}
    </>
  );
};

export default ParagraphExpander;
