import type { Object } from "../../types/globals";

const isEmptyObj = (object?: Object): boolean => {
  return !object || Object.keys(object).length === 0;
};

const isEmptyArr = (arr?: Array<any>): boolean => {
  return !arr || arr.length === 0;
};

const isUsableArr = (arr?: Array<any>): boolean => {
  return Array.isArray(arr) && !isEmptyArr(arr);
};

export { isEmptyObj, isEmptyArr, isUsableArr };
