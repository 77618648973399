import React, { useState, useEffect } from "react";
import TableGroupItem from "./TableGroupItem";
import { useSectionListContext } from "../../../../context/Section/List";
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
} from "../../../../services/helpers/swal";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import BaseModal from "../../commons/BaseModal";
import TableHeadItem from "../../commons/TableHeadItem";

const SectionTable = ({ ...props }: React.HTMLProps<HTMLElement>) => {
  const { mainSections, sections, setSections, selectedSection } =
    useSectionListContext();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [errors, setErrors] = useState<string>("");

  useEffect(() => {
    if (mainSections && mainSections.length > 0) return;

    const fetch = async () => {
      // swalLoading();

      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_SECTION.GET_SECTION_LIST}`,
      });

      if (!result.status) return swalError();

      setSections([...sections, ...result.data]);
      swalClose();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    // swalLoading();
    const result = await makeRequest({
      method: "post",
      url: `${API.ADMIN_SECTION.CREATE_SUBSECTION}`,
      data: { name, parent_section_id: selectedSection.id },
    });

    if (!result.status) {
      if (result.message) {
        setErrors(result.message);
      }

      return swalError();
    }
    const currentParentSection = sections.find(
      (section) => section.id === selectedSection.id
    );
    if (!currentParentSection) return;

    currentParentSection.sub_section =
      currentParentSection.sub_section === undefined
        ? [result.data]
        : [...currentParentSection.sub_section, result.data];

    const newSections = sections.filter(
      (section) => section.id !== selectedSection.id
    );
    setErrors("");
    setSections([...newSections, currentParentSection]);
    swalSuccess();
    setIsOpenModal(false);
  };

  return (
    <section {...props}>
      <div className="flex bg-secondary-extralight items-center justify-between w-full">
        <TableHeadItem className="w-[40%] !border-l-0 !justify-start !pl-[10px]">
          受講者セクション名
        </TableHeadItem>
        <TableHeadItem className="w-[20%]">メンバー管理</TableHeadItem>
        <TableHeadItem className="w-[10%]">並び順</TableHeadItem>
        <TableHeadItem className="w-[20%]">ステータス</TableHeadItem>
        <TableHeadItem className="w-[10%]">設定</TableHeadItem>
        <TableHeadItem className="w-[20%] !border-r-0 text-secondary-extralight">
          1
        </TableHeadItem>
      </div>
      <div className="mt-[3px]">
        {mainSections &&
          mainSections.map((item, index) => (
            <TableGroupItem
              isDownDisabled={index === mainSections.length - 1}
              isUpDisabled={index === 0}
              data={item}
              key={item.id}
              setIsOpenModal={setIsOpenModal}
            />
          ))}
      </div>

      {isOpenModal && (
        <BaseModal
          setIsOpen={setIsOpenModal}
          justifyDirection="end"
          onClick={handleSubmit}
        >
          <div className="text-center pt-[222px] pb-[167px]">
            <div className="text-[16px] font-[700] leading-[100%] tracking-[0.02em] text-primary mb-[24px]">
              セクションを新規登録する
            </div>
            <div className="mb-[12px]">
              「{selectedSection.name}」セクション内に下層セクションを作成します
            </div>

            <div className="">
              <input
                type="text"
                name="name"
                id=""
                onChange={(e) => setName(e.target.value)}
                className="max-w-[550px] h-[40px] border !border-secondary-light mb-[18px] pl-[13px] text-[12px] leading-[100%]"
                placeholder="セクション名を入力してください。"
              />
              {errors && (
                <div className="text-[12px] font-[500] leading-[190%] text-danger">
                  {errors}
                </div>
              )}
            </div>
          </div>
        </BaseModal>
      )}
    </section>
  );
};

export default SectionTable;
