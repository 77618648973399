const FILTER = {
  OPTION: {
    RECORD_LIMIT: [
      {
        label: '10件表示',
        value: 10,
      },
      {
        label: '20件表示',
        value: 20,
      },
      {
        label: '30件表示',
        value: 30,
      },
      {
        label: '40件表示',
        value: 40,
      },
      {
        label: '50件表示',
        value: 50,
      },
    ],
    MOUNT_ORGANIZATION: [
      {
        label: 'セクション名を選択',
      },
    ],
  },
};

const STATUS_BOX_TYPE = {
  DEFAULT: 'default',
  PUBLIC: 'public',
} as const;

const FORM_SETTING_TYPE = {
  CREATE: 'create',
  EDIT: 'edit',
} as const;


export { FILTER, STATUS_BOX_TYPE, FORM_SETTING_TYPE };
