/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import LineChart, { LineChartProps } from "../../../commons/charts/LineChart";
import { ChartData } from "chart.js";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { isEmptyObj, isUsableArr } from "../../../../services/helpers/etc";
import { CourseOption, ReportFilter } from "./ReportBoard";
import { BarChartProps } from "../../../commons/charts/BarChart";
import dayjs from "dayjs";
import { swalMessage } from "../../../../services/helpers/swal";
import { MESSAGE_ERROR } from "../../../../services/constants/message";
import {
  paramizeObject,
  truncate,
} from "../../../../services/helpers/parseData";
import { useStorage } from "../../../../hooks/useStorage";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type ReportProcessFilter = {
  course_id?: number;
  lecture_id?: number;
} & ReportFilter;

export type ChartStudyProcessProps = {
  filter?: ReportFilter;
  data?: BarChartProps["data"];
  courseOptions?: CourseOption[];
} & Omit<LineChartProps, "data">;

const ChartStudyProcess = ({
  filter,
  data,
  courseOptions,
  ...props
}: ChartStudyProcessProps) => {
  const { studentTheme } = useStudentThemeContext();
  const initialChartData: ChartData<"line"> = {
    labels: [],
    datasets: [
      {
        data: [],
        borderColor: studentTheme.main_color_first,
        tension: 0.3,
        borderWidth: 6,
      },
    ],
  };

  const _setCharData = (
    labels: (typeof initialChartData)["labels"],
    data: (typeof initialChartData)["datasets"][0]["data"]
  ) => {
    setChartData({
      labels: labels || [],
      datasets: [
        {
          data: data || [],
          borderColor: studentTheme.main_color_first,
          tension: 0.3,
          borderWidth: 6,
        },
      ],
    });
  };
  const filterStorageKey = "filterStudyProcess";
  const { getLocalStorage, setLocalStorage } = useStorage();

  const initialCurrentFilter = {
    ...(getLocalStorage(filterStorageKey) || {}),
    ...filter,
  };
  const [currentFilter, setCurrentFilter] =
    useState<ReportProcessFilter>(initialCurrentFilter);

  const [lectures, setLectures] = useState<CourseOption["lectures"]>([]);

  useEffect(() => {
    const initialLectures =
      courseOptions?.find((course) => course.id === currentFilter.course_id)
        ?.lectures || [];
    setLectures(initialLectures);
  }, [courseOptions]);

  useEffect(() => {
    const filterData = { ...(currentFilter || {}), ...filter };
    setCurrentFilter(filterData);
    setLocalStorage(filterStorageKey, filterData);
  }, [filter]);

  //Fetch data
  useEffect(() => {
    if (!filter) return;
    if (dayjs(filter.start_date).diff(filter.end_date) > 0) {
      swalMessage("", MESSAGE_ERROR.END_DATE_BIGGER_START_DATE, "error", {
        confirmButtonColor: studentTheme.main_color_first,
      });
      return;
    }

    const fetch = async () => {
      await request.get(
        `${API.REPORT.GET_STUDY_PROCESS}?${paramizeObject(currentFilter)}`,
        (res) => {
          if (isEmptyObj(res)) return;
          _setCharData(Object.keys(res) as string[], Object.values(res));
        }
      );
    };

    if (currentFilter && currentFilter.course_id && currentFilter.lecture_id)
      fetch();
  }, [currentFilter]);

  const [chartData, setChartData] = useState(initialChartData);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let filterData: ReportProcessFilter = filter || {};
    if (e.target.name === "course_id") {
      filterData = { ...filterData, lecture_id: 0 };
      const currentCourse = courseOptions?.find(
        (course) => course.id === Number(e.target.value)
      );
      setLectures(currentCourse?.lectures || []);
    }
    filterData = { ...filterData, [e.target.name]: Number(e.target.value) };
    filterData = { ...currentFilter, ...filterData };
    setLocalStorage(filterStorageKey, filterData);
    setCurrentFilter(filterData);
  };

  return (
    <section>
      <div className="flex items-center gap-[41px] pl-[13px] mt-[29px] mb-[5px] text-secondary">
        <div className="flex flex-col">
          コース
          <select
            name="course_id"
            className="h-[32px] rounded-[16px] text-[11px] border border-danger-light w-[calc(100%+25px)]"
            onChange={handleChange}
            value={currentFilter.course_id}
          >
            <option value="">コースを選択</option>
            {isUsableArr(courseOptions) &&
              courseOptions?.map((course) => (
                <option key={course.id} value={course.id}>
                  {truncate(course.name, 50)}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-col w-full max-w-[250px]">
          講義
          <select
            name="lecture_id"
            className="h-[32px] rounded-[16px] text-[11px] border border-danger-light w-[calc(100%+40px)] max-sm:w-full"
            onChange={handleChange}
            value={currentFilter?.lecture_id}
          >
            <option value="0">講義を選択</option>
            {isUsableArr(lectures) &&
              lectures.map((lecture) => (
                <option key={lecture.id} value={lecture.id}>
                  {truncate(lecture.name, 50)}
                </option>
              ))}
          </select>
        </div>
      </div>
      <LineChart data={chartData} {...props} />
    </section>
  );
};

export default ChartStudyProcess;
