import React, { ChangeEvent } from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { FILTER } from "../../../../services/constants/admin/main";

type TableActionProps = {
    changeHandle: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleDeleteSelected: () => void;
}

const TableAction = ({
  changeHandle,
  handleDeleteSelected
}: TableActionProps) => {
  return (
    <div className="flex justify-between items-center pb-[20px]">
      <div className="flex gap-[10px]">
        {/* search */}
        <div className="relative">
          <input
            type="text"
            name="query"
            className="h-full max-w-[200px] pl-[12px] text-[11px] leading-[100%] min-h-[30px] text-secondary-dark"
            placeholder="商品名の検索"
            onChange={changeHandle}
          />
          <BiSearchAlt2
            className="absolute text-secondary-light top-[6px] right-[10px]"
            size={18}
          />
        </div>

        {/* limit */}
        <div className="flex items-end gap-[10px]">
          <div className="relative">
            <select
              name="limit"
              className="min-h-[30px] pr-[33px] pl-[7px] text-secondary-dark text-[11px] leading-[100%] w-[95px]"
              onChange={changeHandle}
            >
              {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
                <option value={item.value} className="" key={index}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-[5px]">
        <div className="text-[13px] text-secondary-dark font-[400]">
          チェックした商品を
        </div>
        <div>
          <button className="w-[58px] h-[30px] bg-danger text-white text-[14px] font-[500] rounded-[5px]" onClick={handleDeleteSelected}>
            削除
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableAction;
