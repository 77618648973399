import TabCard from "../../../components/admin/commons/TabCard";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { useTabFinder } from "../../../hooks/useTabFinder";
import AdminLayout from "../../../layouts/admin";
import { PRODUCT_TAB_DATA } from "../../../services/constants/admin/pages/product";
import { PATH_ADMIN_PRODUCT } from "../../../services/constants/route/router";
import { WithChildren } from "../../../types/globals";
import { useStorage } from "../../../hooks/useStorage";
import { Navigate } from "react-router-dom";
import React from "react";

type ProductMainProp = {
  title?: string;
};

const ProductBase = ({ title, children }: WithChildren<ProductMainProp>) => {
  const { stringSearchParam } = useObjectRoutes();
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_PRODUCT));
  const { getLocalStorage } = useStorage();
  const paymentFeature = getLocalStorage("payment_feature");

  if (!paymentFeature) {
    return <Navigate to={"/404"} replace={true}/>
  }

  return (
    <AdminLayout>
      <TabCard
        tabData={PRODUCT_TAB_DATA}
        tabIndex={stringSearchParam ? -1 : tabIndex}
        title={title}
      >
        {children}
      </TabCard>
    </AdminLayout>
  );
};

export default ProductBase;
