import React, { useEffect, useState } from "react";
import { LEARNING_TOOLS } from "../../../../services/constants/student/learning";
import LearningToolType from "../LearningToolType";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";

const MenuScroll = () => {
  const navigate = useNavigate();
  const [showDiv, setShowDiv] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY; 

    if (currentScrollY > lastScrollY) {
      // Scrolling down
      setShowDiv(true);
    } else {
      // Scrolling up
      setShowDiv(false);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);
  return (
    <div
      className="bottom-0 w-full flex justify-center items-center m-0 p-0 lg:hidden"
      style={{
        position: "fixed",
        opacity: showDiv ? 1 : 0,
        transform: showDiv ? "translateY(0)" : "translateY(50px)",
        transition: "opacity 0.5s, transform 0.5s",
        pointerEvents: showDiv ? "auto" : "none",
      }}
    >
      <div className=" relative w-full rounded-[5px] pb-[11px] animate-[show_0.5s_ease-in-out]">
        <div className="absolute z-2 top-[-11px] left-[20px] flex justify-center items-center rounded-t-[5px] cursor-pointer bg-[#A49F92] opacity-70 w-[25px] h-[11px]"></div>
        <div
          className="absolute z-100 top-[-13px] left-[20px] flex justify-center items-center rounded-[5px] cursor-pointer"
          onClick={() => setShowDiv(false)}
        >
          <IoClose
            size={24}
            className="text-white relative z-100"
            color="white"
          />
        </div>
        <div className="bg-[#A49F92] opacity-70 top-0 left-0 w-full h-full absolute z-[-1]"></div>
        <div className="mt-[20px] mx-[21px] mb-[14px] font-[500] text-[14px] leading-[100%]"></div>
        <div className="flex gap-[5px] justify-center px-[10px]">
          {LEARNING_TOOLS.map((tool, index) => (
            <div
              className="border border-danger-light bg-white rounded-[5px] w-[105px] h-[85px] cursor-pointer hover:bg-[#00000026] relative z-2"
              key={tool.type}
              onClick={() => navigate(tool.href)}
            >
              <div
                className="mt-[20.75px] flex justify-center"
                style={{ marginBottom: 15 - index }}
              >
                <LearningToolType type={tool.type} />
              </div>
              <div className="font-[500] text-[12px] leading-[100%] flex justify-center ">
                {tool.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuScroll;
