/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import QuestionContent, {
  QuestionContentOptions,
} from "../ExecuteTest/QuestionContent";
import { TestExplanation } from "../../../../types/student/unit";
import QuestionAnswerContent from "./QuestionAnswerContent";
import { API } from "../../../../services/constants/route/api";
import { request } from "../../../../services/axios/axios";
import { isEmptyObj } from "../../../../services/helpers/etc";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import Title from "../../MyPage/Title";
import LearningType from "../../Learning/Type";
import { UNIT_TYPE } from "../../../../services/constants/admin/pages/material";
import { formatTotalTestTime } from "../../../../services/helpers/formatTime";
import { PATH_COURSE } from "../../../../services/constants/route/router";
import qs from "qs";

const Explanation = () => {
  const [explainData, setExplainData] = useState<TestExplanation>({});
  const { searchParamsObject, stringSearchParam, stringHashParam, navigate } =
    useObjectRoutes();

  useEffect(() => {
    if (isEmptyObj(searchParamsObject)) return;

    const fetchExplanation = async () =>
      await request.get(
        `${API.COURSE.GET_EXPLANATION}?${stringSearchParam}`,
        setExplainData
      );

    if (!explainData || isEmptyObj(explainData)) fetchExplanation();
  }, [searchParamsObject]);

  useEffect(() => {
    if (isEmptyObj(explainData)) return;

    const timer = setTimeout(() => {
      //scroll to targeted question
      const element = document.getElementById(stringHashParam);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }, 300);

    return () => clearTimeout(timer);
  }, [explainData]);

  const contentOptions: QuestionContentOptions = {
    autoHeightContent: true,
    disabled: true,
  };

  const backQueryParam = qs.stringify({ unit_id: explainData?.unit_id || 0 });

  return (
    <div>
      {/* header */}
      <Title
        content={`${
          explainData?.course_name || ``
        }|${explainData?.lecture_name || ``}`}
        lectureId={explainData.lecture_id}
        courseId={explainData.course_id}
        withSeperator
      />
      <div className="w-full flex items-center mb-[5px] mt-[30px] gap-[15px] border-b pb-[15px]">
        <LearningType type={UNIT_TYPE.TEST} />
        <div className="font-[700] text-[18px] leading-[20px] tracking-[0.02em]">
          {explainData?.test_name || ""}
        </div>
      </div>

      <div className="text-[12px] mb-[15px] leading-[190%] text-secondary text-right">
        問題　{formatTotalTestTime(explainData?.estimate_time_required)}／
        {explainData?.question_quantity || "-"}問／合格ライン
        {explainData?.passing_score || "-"}点（{explainData?.total_score || "-"}
        点満点）
      </div>
      {/* content */}

      {!!explainData?.questions?.length &&
        explainData.questions.map((question, index) => (
          <div
            id={`question_${index + 1}`}
            className={`border-t border-secondary-light pt-[17px] ${
              index > 0 && "mt-[25px]"
            }`}
            key={`explanation_${index}`}
          >
            <div className="ml-[-22px]">
              <QuestionContent
                question={question || {}}
                currentStep={index}
                options={contentOptions}
              />
            </div>

            <QuestionAnswerContent question={question || {}} />
          </div>
        ))}

      <div
        className="bg-secondary-light text-[18px] font-[700] leading-[100%] text-white rounded-[5px] py-[10px] px-[30px] w-fit mt-[25px] cursor-pointer mb-[20px]"
        onClick={() => {
          if (!explainData || !explainData.unit_id) return;
          navigate(`${PATH_COURSE.UNIT_DETAIL}?${backQueryParam}`);
        }}
      >
        この単元のトップへ戻る
      </div>
    </div>
  );
};

export default Explanation;
