import Explanation from '../../../components/student/Course/Explanation/Explanation';
import StudentLayout from '../../../layouts/student';

const ExplanationPage = () => {
  return (
    <StudentLayout gap>
      <Explanation />
    </StudentLayout>
  );
};

export default ExplanationPage;
