import { EventSchedule } from "../../../types/student/event";
import EventBoardListItem from "./EventBoardListItem";
import { isUsableArr } from "../../../services/helpers/etc";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { useEventBoardContext } from "../../../context/EventBoardContext";
import dayjs from "dayjs";

type ScheduleEventListProps = {
  eventsInMonth?: EventSchedule[];
};

const ScheduleEventBoardList = ({ eventsInMonth }: ScheduleEventListProps) => {
  const { studentTheme } = useStudentThemeContext();
  const { month } = useEventBoardContext();
  
  return (
    <div
      className={`w-[230px] min-h-0 h-[284px] pr-[5px] ${
        eventsInMonth !== undefined && eventsInMonth?.length > 4
          ? "overflow-y-auto"
          : ""
      }`}
    >
      {isUsableArr(eventsInMonth) ? (
        eventsInMonth
          ?.filter((event) => {
            if (dayjs(event.start_time).get("month") + 1 === month) {
              return event;
            }
          })
          .map((event, index) => (
            <EventBoardListItem key={index} data={event} />
          ))
      ) : (
        <div className="text-center text-[14px] font-[400] mt-[20px]">
          予定がありません
        </div>
      )}
    </div>
  );
};

export default ScheduleEventBoardList;
