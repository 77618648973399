import React, { useState } from "react";
import { Lecture } from "../../../../types/admin/lecture";
import LectureListItem from "./LectureListItem";

const LectureList = ({lectureList}: {lectureList: Lecture[]}) => {
  return (
    
    <div className="w-full border border-danger-light rounded-[5px] mb-[15px] px-[21px] py-[18px]">
      <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
        講座一覧
      </div>
      {lectureList.map((lecture) => (
        <LectureListItem lectureData={lecture} key={lecture.id}/>
      ))}
    </div>
  );  
};

export default LectureList;
