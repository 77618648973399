import React, { useState, useContext, useEffect } from "react";
import { ChildrenWithProps, SetStateAction } from "../../types/globals";
import { Section } from "../../types/admin/section";
import { sortArrayByKey } from "../../services/helpers/parseData";
import { Filter } from "../../types/admin/main";

interface CheckedObj {
  [key: string]: boolean;
}

interface SectionManagementContextType {
  sections: Section[];
  setSections: (sections: Section[]) => void;
  mainSections: Section[];
  subSections: (parentSectionId?: number) => Section[];
  selectedSection: Section;
  setSelectedSection: (sectionId: Section["id"]) => void;
  students: Section["students"];
  setStudents: (students?: Section["students"]) => void;
  checkedStudents: Section["students"];
  setCheckedStudents: (students?: Section["students"]) => void;
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  modalStep: number;
  setModalStep: React.Dispatch<React.SetStateAction<number>>;
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  checkedStudentIds: number[];
  setCheckedStudentIds: React.Dispatch<React.SetStateAction<number[]>>;
  checkedObj: CheckedObj;
  setCheckedObj: React.Dispatch<React.SetStateAction<CheckedObj>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  triggerRefreshCourseManagement: number;
  setTriggerRefreshCourseManagement: SetStateAction<number>;
}

const SectionManagementContext =
  React.createContext<SectionManagementContextType>({
    sections: [],
    setSections: () => {},
    mainSections: [],
    subSections: () => [],
    selectedSection: {},
    setSelectedSection: () => {},
    students: [],
    setStudents: () => {},
    checkedStudents: [],
    setCheckedStudents: () => {},
    isOpenModal: false,
    setIsOpenModal: () => {},
    modalStep: 0,
    setModalStep: () => {},
    filter: {},
    setFilter: () => {},
    checkedStudentIds: [],
    setCheckedStudentIds: () => {},
    checkedObj: {},
    setCheckedObj: () => {},
    title: "",
    setTitle: () => {},
    triggerRefreshCourseManagement: 0,
    setTriggerRefreshCourseManagement: () => {},
  });

const SectionManagementProvider = ({ children }: ChildrenWithProps) => {
  const [sections, _setSections] = useState<Section[]>([]);
  const [selectedSection, _setSelectedSection] = useState<Section>({});
  const [students, _setStudents] = useState<Section["students"]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({});
  const [checkedStudents, setCheckedStudents] = useState<Section["students"]>(
    []
  );
  const [modalStep, setModalStep] = useState<number>(0);
  const [checkedStudentIds, setCheckedStudentIds] = useState<number[]>([]);
  const [checkedObj, setCheckedObj] = useState<CheckedObj>({});
  const [title, setTitle] = useState<string>("");
  const [mainSections, setMainSections] = useState<Section[]>([]);
  const [triggerRefreshCourseManagement, setTriggerRefreshCourseManagement] =
    useState<number>(0);

  useEffect(() => {
    setMainSections(
      sections.filter((section) => section.parent_section_id === null)
    );
  }, [sections]);

  const setSections = (sections: Section[]) => {
    const uniqueSections = sections
      .reverse()
      .filter(
        (section, index, a) =>
          a.findIndex((_section) => _section.id === section.id) === index
      );
    _setSections(sortArrayByKey(uniqueSections, "order"));
  };

  const setStudents = (students: Section["students"]) => {
    if (!students) return [];

    const uniqueStudents = students.filter(
      (section, index, a) =>
        a.findIndex((_section) => _section.id === section.id) === index
    );
    _setStudents(uniqueStudents);
  };

  const setSelectedSection = (sectionId: Section["id"]) =>
    _setSelectedSection(
      sections.find((section) => section.id === sectionId) || {}
    );

  const subSections = (parentSectionId?: number) => {
    if (parentSectionId)
      return sections.filter(
        (section) => section.parent_section_id === parentSectionId
      );
    return sections.filter((section) => section.parent_section_id !== null);
  };

  return (
    <SectionManagementContext.Provider
      value={{
        sections,
        setSections,
        mainSections,
        subSections,
        selectedSection,
        setSelectedSection,
        students,
        setStudents,
        isOpenModal,
        setIsOpenModal,
        filter,
        setFilter,
        checkedStudents,
        setCheckedStudents,
        modalStep,
        setModalStep,
        checkedStudentIds,
        setCheckedStudentIds,
        checkedObj,
        setCheckedObj,
        title,
        setTitle,
        triggerRefreshCourseManagement,
        setTriggerRefreshCourseManagement,
      }}
    >
      {children}
    </SectionManagementContext.Provider>
  );
};

export default SectionManagementProvider;

export const useSectionManagementContext = () =>
  useContext(SectionManagementContext);
