import StudentCartBase from ".";
import StudentCart from "../../../components/student/Cart/StudentCart";

const StudentCartPage = () => {
  return (
    <StudentCartBase>
      <StudentCart />
    </StudentCartBase>
  );
};

export default StudentCartPage;
