import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import AuthLayout from "../layouts/AuthLayout";
import { StudentThemeType } from "../types/admin/student_theme";
import { useLoginSettingContext } from "../context/LoginSettingContext";
import VerifyCodeStep from "../components/student/CompleteRegistration/VerifyCodeStep";
import CompleteRegistrationStep from "../components/student/CompleteRegistration/CompleteRegistrationStep";
import ResendMailCompleteStudentRegistrationStep from "../components/student/CompleteRegistration/ResendMailCompleteStudentRegistrationStep";
import {
  COMPLETE_REGISTRATION_STEP,
  RESEND_MAIL_COMPLETE_REGISTRATION_STEP,
  STUDENT_VERIFIED_STEP,
  VERIFY_STEP
} from "../services/constants/student/completeStudentRegistration";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { useObjectRoutes } from "../hooks/useObjectRoutes";
import StudentVerifiedStep from "../components/student/CompleteRegistration/StudentVeifiedStep";

const ResendMailCompleteStudentRegistration = () => {
  const { getParamValue } = useObjectRoutes();
  const [step, setStep] = useState(NaN);
  let accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const [token, setToken] = useState(accessToken);
  const [studentThemeColor, setStudentThemeColor] = useState<StudentThemeType>(
    {}
  );
  const { studentTheme, fetching } = useLoginSettingContext();
  let paramsToken = getParamValue("token");

  const handleStep = (step: number) => {
    setStep(step)
  }

  useEffect(() => {
    if (!fetching) {
      setStudentThemeColor(studentTheme);
    }
  }, [fetching]);

  useEffect(() => {
    const fetcher = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.AUTH.CHECK_STUDENT_NOT_VERIFIED}?token=${paramsToken}`,
      });
      if(!result.status) {
        setStep(STUDENT_VERIFIED_STEP)
        return;
      }
      setStep(VERIFY_STEP)
    }
    fetcher()
  }, [])

  const renderStepRegister = (step: number) => {
    switch (step) {
      case RESEND_MAIL_COMPLETE_REGISTRATION_STEP:
        return (
          <ResendMailCompleteStudentRegistrationStep
            handleStep={handleStep}
            studentThemeColor={studentThemeColor}
          />
        );
      case COMPLETE_REGISTRATION_STEP:
        return (
          <CompleteRegistrationStep
            handleStep={handleStep}
            studentThemeColor={studentThemeColor}
          />
        );
      case VERIFY_STEP:
        return (
          <VerifyCodeStep
            handleStep={handleStep}
            studentThemeColor={studentThemeColor}
          />
        );
      case STUDENT_VERIFIED_STEP:
        return (
          <StudentVerifiedStep />
        );
      default:
        return (
          <>
            <div className="skeleton bg-secondary-light rounded shadow-lg sm:w-[750px] max-sm:w-full h-[300px] mb-[42px] mt-[30px] mx-auto max-sm:mt-[25px] max-sm:px-[10px] max-sm:mb-[32px] animate-[show_0.5s_ease-in-out]"></div>
          </>
        );
    }
  };

  return (
    <>
      {token ? (
        <Navigate to={ROUTE.MY_PAGE.DEFAULT} replace={true} />
      ) : (
        <AuthLayout>
          <>{renderStepRegister(step)}</>
        </AuthLayout>
      )}
    </>
  );
};

export default ResendMailCompleteStudentRegistration;
