import React from 'react'
import FileList from '../../../components/admin/Folder/List/FileList';

const FoldersFileListPage = () => {
  return (
    <FileList/>
  );
};

export default FoldersFileListPage
