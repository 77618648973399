import { EventSchedule } from "../../../../types/student/event";
import ScheduleEventType from "../../Schedule/EventType";
import { MdOutlineNotificationsNone } from "react-icons/md";
import ToggleSwitch from "../../../commons/form/ToggleSwitch";
import { FiTrash2 } from "react-icons/fi";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import { BiEditAlt } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { STATE_EVENT_FORM } from "../../../../services/constants/student/event";
import dayjs from "dayjs";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type EventListItemProps = {
  event: EventSchedule;
  handleDelete: Function;
};

const EventListItem = ({ event, handleDelete }: EventListItemProps) => {
  const eventType = event?.event_type;
  const [, setSearchParams] = useSearchParams();
  const { searchParamsObject } = useObjectRoutes();
  const { studentTheme } = useStudentThemeContext();

  return (
    <div className="border-b border-danger-light mt-[20px] pb-[10px] pl-[10px] w-[95%]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-[10px]">
          {/* event type */}
          <div
            className={`rounded-[8.333px] w-[140px] h-[30px] flex justify-start pl-[1.5px] items-center ${
              !eventType?.icon_name ? "!border !border-danger-light" : ""
            }`}
            style={{
              background:
                eventType?.id === 4
                  ? studentTheme.main_color_first
                  : eventType?.background_color,
              color: eventType?.name_color,
            }}
          >
            <ScheduleEventType
              name={eventType?.icon_name || ""}
              size={24}
              className="mx-[10px]"
            />
            <div
              className={`text-[14px] font-[500] ${
                eventType?.icon_name ? "" : "pl-[10px]"
              }`}
              style={{ color: eventType?.name_color }}
            >
              {eventType?.name}
            </div>
          </div>

          <div className="text-danger-light text-[24px] flex items-center gap-[5px]">
            <MdOutlineNotificationsNone />
            <ToggleSwitch
              inputName="is_notice"
              disabled
              defaultChecked={event && event.is_notice}
            />
            <div className="text-[12px] font-[400] leading-[100%] ml-[6.5px]">
              通知する
            </div>
          </div>
        </div>

        <div className="flex gap-[5px] items-center">
          <div
            className="bg-secondary-light rounded-[5px] w-[30px] h-[28px] flex justify-center items-center cursor-pointer"
            onClick={() => handleDelete(event.id)}
          >
            <FiTrash2 className="text-white" size={19} />
          </div>
          <div className="text-secondary text-[9px] font-[500] leading-[120%]">
            予定を削除
          </div>
        </div>
      </div>

      <div className="text-secondary text-[12px] font-[400] leading-[120%] mt-[6px] mb-[22px]">
        {formatDateTime(
          event.start_time,
          TYPE_DATE_FORMAT.FULL_TO_MINUTE_DEFAULT
        )}
        ～
        {formatDateTime(
          event.end_time,
          TYPE_DATE_FORMAT.FULL_TO_MINUTE_DEFAULT
        )}
      </div>

      <div className="text-[12px] font-[400] leading-[170%] tracking-[0.24px] mb-[10px]">
        {event.note}
      </div>

      <div className="flex justify-end">
        <div
          style={{
            backgroundColor: studentTheme.sub_color_first,
          }}
          className="bg-primary-light w-[146px] h-[27px] rounded-[8.333px] flex items-center justify-center gap-[10px] text-white cursor-pointer"
          onClick={() => {
            setSearchParams({
              ...searchParamsObject,
              state: STATE_EVENT_FORM.EDIT,
              event_id: `${event.id}`,
            });
          }}
        >
          <BiEditAlt size={20} />
          <div className="text-[13px] font-[500] tracking-[0.26px]">
            予定を編集する
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventListItem;
