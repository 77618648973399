import StudyToolBase from ".";
import MemoDetail from "../../../../components/student/Memo/MemoDetail";

const MemoView = () => {
  return (
    <StudyToolBase>
      <MemoDetail />
    </StudyToolBase>
  );
};

export default MemoView;
