import StudentPolicyBase from ".";
import Policy from "../../../components/student/Policy/Policy";

const TermOfUsePage = () => {
  return (
    <StudentPolicyBase title="利用規約">
      <Policy />
    </StudentPolicyBase>
  );
};

export default TermOfUsePage;
