import { useParams } from "react-router-dom";
import ProductBase from ".";
import ProductUpsert from "../../../components/admin/Product/Form/ProductUpsert";

const ProductUpsertPage = () => {
  const params = useParams();
  const title = params.id ? '商品更新' : '商品新規追加';
  
  return (
    <ProductBase title={`講座販売 ｜ ${title}`}>
      <ProductUpsert />
    </ProductBase>
  );
};

export default ProductUpsertPage;