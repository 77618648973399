import React, { FormEvent, useEffect, useState } from "react";
import UnitTestListItem from "./UnitTestListItem";
import BaseModal from "../../commons/BaseModal";
import QuestionForm from "./Form/QuestionForm";
import { Question } from "../../../../types/admin/unit";
import { useNavigate } from "react-router-dom";
import { swalError, swallConfirm } from "../../../../services/helpers/swal";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { Direction } from "../../../../types/globals";
import { DIRECTION } from "../../../../services/constants/globals";
import Plus from "../../../commons/icons/PlusMinus/Plus";
import { Question as QuestionData } from "../../../../types/student/unit";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import QuestionContent from "../../../student/Course/ExecuteTest/QuestionContent";
import { CREATION_UNIT_TEST_TYPE } from "../../../../services/constants/admin/pages/material";
import { PATH_ADMIN_MATERIAL } from "../../../../services/constants/route/router";

const UnitTestList = ({
  questionData,
  unitId,
  handleSubmit,
  question_quantity,
}: {
  questionData: Question[];
  unitId?: number | string;
  handleSubmit: (e: FormEvent<HTMLButtonElement>, submitType?: number) => void;
  question_quantity?: number | string;
}) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const { getParamValue } = useObjectRoutes();
  const questionId = getParamValue("preview_question_id");
  const [questionPreviewData, setQuestionPreviewData] =
    useState<QuestionData>();
  const [isPreviewOpenModal, setIsPreviewOpenModal] = useState<boolean>(false);
  const [waitingAPI, setWaitingAPI] = useState<boolean>(true);

  useEffect(() => {
    if (!questionId) return;
    setWaitingAPI(true);
    const fetchDetail = async () => {
      await request.get(
        `${API.ADMIN_UNIT.QUESTION_DETAIL}?question_id=${questionId}`,
        (questionData) => {
          setQuestionPreviewData(questionData || {});
          setWaitingAPI(false);
        }
      );
    };

    fetchDetail();
  }, [questionId]);
  const navigate = useNavigate();

  useEffect(() => {
    questionData.sort((a, b) => {
      if (a.order === undefined || b.order === undefined) return 0;
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
    setQuestions(questionData);
  }, [questionData]);

  const handleReset = async () => {
    await request.get(
      `${API.ADMIN_UNIT.DETAIL}?unit_id=${unitId}`,
      (questionData) => {
        const sortedQuestions = [...questionData.questions];
        sortedQuestions.sort((a, b) => {
          if (a.order === undefined || b.order === undefined) return 0;
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        setQuestions(sortedQuestions);
      }
    );
  };

  const handleDelete = (id?: number | string) => {
    if (!id) return;
    swallConfirm(async () => {
      await request.delete(
        API.ADMIN_UNIT.DELETE_QUESTION,
        { question_id: id },
        () => {
          handleReset();
        }
      );
    }, "この問題を削除してもよろしいですか？");
  };

  const handleChangeOrder = async (
    id?: number | string,
    direction?: Direction
  ) => {
    if (!id) return;
    const endpoint = API.ADMIN_UNIT.UPDATE_QUESTION_ORDER;
    let currentArray = questions;
    const currentRecord = currentArray.find((item) => item.id === id) || {};
    const currentIndex = currentArray.findIndex((item) => item.id === id);
    const siblingIndex =
      direction === DIRECTION.UP ? currentIndex - 1 : currentIndex + 1;
    const siblingRecord = currentArray[siblingIndex];

    if (!currentRecord || !siblingRecord) return;

    const currentSubmitData = {
      question_id: currentRecord.id,
      order:
        direction === DIRECTION.UP
          ? (currentRecord.order || 0) - 1
          : (currentRecord.order || 0) + 1,
    };
    const siblingSubmitData = {
      question_id: siblingRecord.id,
      order:
        direction === DIRECTION.UP
          ? (siblingRecord.order || 0) + 1
          : (siblingRecord.order || 0) - 1,
    };

    const currentCoursePromise = request.patch(endpoint, currentSubmitData);
    const siblingCoursePromise = request.patch(endpoint, siblingSubmitData);

    await Promise.all([currentCoursePromise, siblingCoursePromise]);

    handleReset();
  };

  const handleClickPlusButton = () => {
    if(question_quantity && (Number(questions.length) >= Number(question_quantity))) {
      swalError();
      return;
    }
    setIsOpenModal(true);
    navigate(unitId ? `?unit_id=${unitId}` : "./");
  }

  return (
    <section className="">
      {unitId && (
        <div className="flex items-center gap-[15px]">
          <div className="flex items-center justify-center w-full max-w-[140px] min-h-[30px] text-[14px] font-[500] leading-[100%] text-primary border border-primary rounded-[5px]">
            テスト設問登録
          </div>
          <div className="text-[12px] leading-[190%]">
            出題数に合わせて入力フィールドを追加できます。
          </div>
        </div>
      )}
      <div className="mt-[22px]">
        {questions &&
          questions.map((item, index) => (
            <UnitTestListItem
              setIsOpenModal={setIsOpenModal}
              data={item}
              className="mb-[15px]"
              handleDelete={handleDelete}
              handleChangeOrder={handleChangeOrder}
              isUpDisabled={index === 0}
              isDownDisabled={index === questions.length - 1}
              setIsPreviewOpenModal={setIsPreviewOpenModal}
              key={index}
            />
          ))}

        {unitId && question_quantity && (Number(questions.length) < Number(question_quantity)) &&(
          <div className="flex flex-col justify-center items-center">
            <button
              type="button"
              onClick={handleClickPlusButton}
            >
              <Plus />
            </button>
            <p className="text-[12px] font-[500] mt-[10px]">出題枠を追加</p>
          </div>
        )}

        <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[19px]">
          <button
            className="min-w-[180px] bg-secondary-light px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
            onClick={() => navigate(PATH_ADMIN_MATERIAL.DEFAULT)}
          >
            キャンセル
          </button>
          <button
            className="min-w-[228px] bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
            onClick={(e) => handleSubmit(e)}
          >
            下書きとして保存
          </button>
          <button
            disabled={!!unitId && (!questions || questions.length === 0)}
            className={`min-w-[132px] px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%] max-h-[43px] flex items-center ${
              !!unitId && (!questions || questions.length === 0)
                ? "bg-secondary-light cursor-not-allowed"
                : "bg-primary"
            }`}
            onClick={(e) => handleSubmit(e, CREATION_UNIT_TEST_TYPE.PUBLISH)}
          >
            <div className="">{unitId ? "公開" : "出題枠を追加"}</div>
          </button>
        </div>
        {isOpenModal && (
          <BaseModal
            width={1400}
            height={700}
            setIsOpen={setIsOpenModal}
            withSubmit={false}
            clickOutside={false}
          >
            <QuestionForm
              setQuestionModal={setIsOpenModal}
              resetQuestion={handleReset}
              questionLength={questions.length + 1}
            />
          </BaseModal>
        )}
        {isPreviewOpenModal && (
          <BaseModal
            setIsOpen={setIsPreviewOpenModal}
            width={1010}
            height={400}
            justifyDirection="end"
            withSubmit={false}
          >
            <>
              {waitingAPI ? (
                <div className="w-full h-full flex items-center justify-center">
                  <div className="w-[30px] h-[30px] rounded-full border-[4px] border-secondary border-t-[4px] border-t-transparent animate-spin"></div>
                </div>
              ) : (
                <QuestionContent
                  question={questionPreviewData as any}
                  currentStep={(questionPreviewData?.order || 1) - 1}
                />
              )}
            </>
          </BaseModal>
        )}
      </div>
    </section>
  );
};

export default UnitTestList;
