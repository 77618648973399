import { CourseItemType } from '../../../../types/admin/course';
import { COURSE_TABLE_ITEM_TYPE } from '../../../../services/constants/admin/pages/course';
import { MdOutlineDescription, MdOutlineOndemandVideo } from 'react-icons/md';
import { formatEstimationRequiredTime } from '../../../../services/helpers/formatTime';

type CreateButtonDisplayProps = {
  itemType: CourseItemType;
  time?: number;
  isTest?: boolean;
};

const CreateButtonDisplay = ({ itemType, time, isTest }: CreateButtonDisplayProps) => {
  switch (itemType) {
    case COURSE_TABLE_ITEM_TYPE.CHAPTER:
      return (
        <button className="w-full max-w-[140px] min-h-[22px] bg-secondary rounded-[5px] text-white px-[7.5px] pt-[3px] pb-[5px]">
          教材・テストを登録
        </button>
      );

    case COURSE_TABLE_ITEM_TYPE.UNIT:
      return (
        <div className="font-[500] text-[13px] leading-[100%] flex text-secondary-dark items-center">
          {isTest ? <MdOutlineDescription className='text-secondary mr-[3px]' size={26}/> : <MdOutlineOndemandVideo className='text-secondary mr-[5px]' size={22}/>} {formatEstimationRequiredTime(time)}
        </div>
      );

    default:
      return <></>;
  }
};

export default CreateButtonDisplay;
