import * as React from 'react';

const Book = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 .25H2.75A2.43 2.43 0 0 0 .25 2.6V15a2.85 2.85 0 0 0 2.94 2.75H15a.76.76 0 0 0 .75-.75V1A.76.76 0 0 0 15 .25Zm-.75 16H3.19A1.35 1.35 0 0 1 1.75 15a1.35 1.35 0 0 1 1.44-1.25h11.06v2.5Zm0-4H3.19a3 3 0 0 0-1.44.37V2.6a.94.94 0 0 1 1-.85h11.5v10.5Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
    <path
      d="M4.75 5.75h6.5a.75.75 0 1 0 0-1.5h-6.5a.75.75 0 0 0 0 1.5ZM4.75 9.25h6.5a.75.75 0 1 0 0-1.5h-6.5a.75.75 0 0 0 0 1.5Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
  </svg>
);

export default Book;
