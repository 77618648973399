const ANSWER_TYPE = {
  UNKNOWN: null,
  INCORRECT: 0,
  CORRECT: 1,
};

const MODE_EXECUTE_TEST = {
  REVISE: 1,
  MAIN: 2,
  PRACTICE: 3,
};

const MODE_EXECUTE_TEST_DATA = ["", "復習モード", "本番モード", "練習モード"];

const UNIT_STATUS = {
  UNIT_UNLEARNED_STATUS: 0,
  UNIT_COMPLETED_STATUS: 1,
  UNIT_LEARNING_STATUS: 2,
};

const SUBMIT_EXECUTE_TEST_TYPE = {
  PREVIOUS: "previous",
  ANSWER: "answer",
  PAUSE: "pause",
  FINISH: "finish",
  CHANGE: 'change'
};

const TEST_LATEST_REVIEW_TABLE_HEAD = [
  "No",
  "正誤",
  "得点",
  "前回",
  "要復習",
  "解説",
  "自己評価",
  "解答時間",
];

const TEST_REVIEW_TABLE_HEAD = [
  "問題",
  "今回",
  "得点",
  "要復習",
  "解説",
  "解答時間",
  "前回",
  "前々回",
  "3回前",
  "4回前",
  "5回前",
];

const TEST_HISTORY_TABLE_HEAD = [
  "実施日",
  "モード",
  "問題数",
  "正解数",
  "得点",
  "合否",
  "詳細",
];

const TEST_REVIEW = {
  NONE: 0,
  RATE_LIKE: 1,
  RATE_DISLIKE: 2,
};

const TEST_ANSWER_STATUS = {
  UNANSWERED: '未解答',
  ANSWERED: '解答済'
}

export {
  MODE_EXECUTE_TEST,
  ANSWER_TYPE,
  UNIT_STATUS,
  SUBMIT_EXECUTE_TEST_TYPE,
  TEST_LATEST_REVIEW_TABLE_HEAD,
  TEST_REVIEW_TABLE_HEAD,
  MODE_EXECUTE_TEST_DATA,
  TEST_HISTORY_TABLE_HEAD,
  TEST_REVIEW,
  TEST_ANSWER_STATUS
};
