import Event from "../../../../components/student/StudyTool/Schedule/Event";
import SchedulePageBase from ".";

const EventPage = () => {
  return (
    <SchedulePageBase>
      <Event />
    </SchedulePageBase>
  );
};

export default EventPage;
