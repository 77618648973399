import React, { ChangeEvent, FormEvent } from "react";
import EventFormDatePicker from "../../../student/StudyTool/Schedule/EventFormDatePicker";
import {
  SECTION_TYPE_EVENT_FORM_DATEPICKER,
  UNIT_EVENT_FORM_DATEPICKER,
} from "../../../../services/constants/student/event";
import CalendarDatePickerEvent from "../../../student/StudyTool/Schedule/CalendarDatePickerEvent";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import { Product } from "../../../../types/admin/product";
import { SetStateAction, ValueOf } from "../../../../types/globals";
import { ShowPickerStatus } from "../../../student/StudyTool/Schedule/EventForm";
import { MESSAGE_ERROR } from "../../../../services/constants/message";
import dayjs from "dayjs";

type ProductFormDataProps = {
  productFormData: Product;
  showStatuses: ShowPickerStatus[];
  setShowStatuses: SetStateAction<ShowPickerStatus[]>;
  showDatetimeSell: boolean;
  setProductFormData: SetStateAction<Product>;
  handleChangeForm: (
    e:
      | FormEvent<HTMLInputElement>
      | ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
};

const ProductFormDate = ({
  productFormData,
  showStatuses,
  showDatetimeSell,
  setShowStatuses,
  handleChangeForm,
  setProductFormData
}: ProductFormDataProps) => {

  const displayHandle = (
    section: ValueOf<typeof SECTION_TYPE_EVENT_FORM_DATEPICKER>,
    unit: ValueOf<typeof UNIT_EVENT_FORM_DATEPICKER>
  ): SetStateAction<ShowPickerStatus[]> | undefined => {
    if (!section || !unit || !showDatetimeSell) return;
    //find existed showings data
    const existedStatusBySection = showStatuses.find(
      (status) => status.section === section
    );

    //if exited one, its will be remove, otherwise add new one
    if (existedStatusBySection) {
      let filteredStatuses = showStatuses.filter(
        (status) => status.section !== section
      );
      //only one picker each section
      if (existedStatusBySection.unit !== unit) {
        const newStatus = {
          section,
          unit,
        };

        filteredStatuses = [...filteredStatuses, newStatus];
      }
      setShowStatuses(filteredStatuses);
    } else {
      const newStatus = {
        section,
        unit,
      };

      setShowStatuses([...showStatuses, newStatus]);
    }
  };

  const refHandle = (ref: React.RefObject<HTMLInputElement>) => {
    if (!ref || !ref.current) return alert(MESSAGE_ERROR.SOMETHINGS_WENT_WRONG);
    let prepareFormData = {
      ...productFormData,
      [ref.current.name]: ref.current.value,
    };

    // for-current-specs - only start_time allowed
    if (ref.current.name === "start_time") {
      const currentDate = formatDateTime(
        ref.current.value || new Date().toDateString(),
        TYPE_DATE_FORMAT.REGULAR_GLOBAL
      );

      const currentStartTime = formatDateTime(
        productFormData["start_date_sell"] || new Date().toDateString(),
        TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H
      );

      prepareFormData = {
        ...prepareFormData,
        start_date_sell: new Date(`${currentDate} ${currentStartTime}`),
      };
    }

    if(ref.current.name === "end_time") {
      const currentDate = formatDateTime(
        ref.current.value || new Date().toDateString(),
        TYPE_DATE_FORMAT.REGULAR_GLOBAL
      );

      const currentEndTime = formatDateTime(
        productFormData["end_date_sell"] || new Date().toDateString(),
        TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H
      );

      prepareFormData = {
        ...prepareFormData,
        end_date_sell: new Date(`${currentDate} ${currentEndTime}`),
      };
    }

    setProductFormData(prepareFormData);
  };

  return (
    <div className="w-full flex items-start gap-[30px] pt-[10px]">
      {/* start date */}
      <div>
        <div className="text-secondary border-b-[1px] border-success-extralight flex items-center justify-between gap-[6px] mb-[10px] pb-[8px] focus:border-transparent">
          <div className="text-[12px] font-[400] leading-[100%]">開始</div>
          <div className="">
            <EventFormDatePicker
              dateInput={productFormData?.start_date_sell || ""}
              section={SECTION_TYPE_EVENT_FORM_DATEPICKER.START}
              onDisplayHandler={displayHandle}
              showStatuses={showStatuses}
              isDisabled={!showDatetimeSell}
            />
          </div>
        </div>
        <div className="">
          {showStatuses.some(
            (status) =>
              status.section === SECTION_TYPE_EVENT_FORM_DATEPICKER.START &&
              status.unit === UNIT_EVENT_FORM_DATEPICKER.DATE
          ) && (
            <div className="mb-[20px]">
              <CalendarDatePickerEvent
                className="border border-secondary-extralight shadow-[0_4px_4px_0_rgba(123,123,123,0.25)] rounded-[5px] px-[13px] w-full max-w-[246px] focus:border-transparent focus:outline-none focus:ring-transparent"
                initialDate={productFormData?.start_date_sell || ""}
                onRefHandle={refHandle}
                inputName="start_date_sell"
                setShowStatuses={setShowStatuses}
              />
            </div>
          )}
          {showStatuses.some(
            (status) =>
              status.section === SECTION_TYPE_EVENT_FORM_DATEPICKER.START &&
              status.unit === UNIT_EVENT_FORM_DATEPICKER.TIME
          ) && (
            <div className="mb-[20px]">
              <input
                type="time"
                name="start_date_sell"
                id=""
                onChange={handleChangeForm}
                value={formatDateTime(
                  productFormData?.start_date_sell || "",
                  TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H
                )}
              />
            </div>
          )}
        </div>
      </div>
      <div className="text-[20px] text-secondary-dark text-center font-[400]">
        ~
      </div>
      {/* end date */}
      <div>
        <div className="text-secondary border-b-[1px] border-success-extralight flex items-center justify-between gap-[6px] mb-[10px] pb-[8px] focus:border-transparent">
          <div className="text-[12px] font-[400] leading-[100%]">終了</div>
          <div className="">
            <EventFormDatePicker
              dateInput={productFormData?.end_date_sell || ""}
              section={SECTION_TYPE_EVENT_FORM_DATEPICKER.END}
              onDisplayHandler={displayHandle}
              showStatuses={showStatuses}
              isDisabled={!showDatetimeSell}
            />
          </div>
        </div>
        <div className="">
          {showStatuses.some(
            (status) =>
              status.section === SECTION_TYPE_EVENT_FORM_DATEPICKER.END &&
              status.unit === UNIT_EVENT_FORM_DATEPICKER.DATE
          ) && (
            <div className="mb-[20px]">
              <CalendarDatePickerEvent
                className="border border-secondary-extralight` shadow-[0_4px_4px_0_rgba(123,123,123,0.25)] rounded-[5px] py-[12px] px-[13px] w-full max-w-[246px]"
                initialDate={
                  productFormData?.end_date_sell ||
                  productFormData?.start_date_sell ||
                  ""
                } // make initial value counting from start date
                onRefHandle={refHandle}
                inputName="end_date_sell"
                setShowStatuses={setShowStatuses}
              />
            </div>
          )}
          {showStatuses.some(
            (status) =>
              status.section === SECTION_TYPE_EVENT_FORM_DATEPICKER.END &&
              status.unit === UNIT_EVENT_FORM_DATEPICKER.TIME
          ) && (
            <div className="mb-[20px]">
              <input
                type="time"
                name="end_date_sell"
                id=""
                onChange={handleChangeForm}
                value={formatDateTime(
                  productFormData?.end_date_sell ||
                    productFormData?.start_date_sell ||
                    "", // make initial value counting from start date
                  TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H
                )}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductFormDate;
