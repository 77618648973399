import React from "react";
import StudentLayout from "../../../layouts/student";
import Title from "../../../components/student/MyPage/Title";
import StudyHistoryList from "../../../components/student/StudyHistory/List";

const StudyHistory = () => {
  return (
    <StudentLayout gap>
      <Title content="学習履歴" />
      <StudyHistoryList />
    </StudentLayout>
  );
};

export default StudyHistory;
