/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import type { TestData } from '../../../../types/student/unit';
import FooterExecuteTest from './Footer';
import HeaderExecuteTest from './Header';
import QuestionContent from './QuestionContent';
import { Object } from '../../../../types/globals';
import { getAnswerByType } from '../../../../services/utils/student/course';
import {
  MODE_EXECUTE_TEST,
  SUBMIT_EXECUTE_TEST_TYPE,
} from '../../../../services/constants/student/unit';
import { PATH_COURSE } from '../../../../services/constants/route/router';
import { swalError } from '../../../../services/helpers/swal';
import qs from 'qs';
import { isEmptyObj } from '../../../../services/helpers/etc';
import { useBeforeunload } from 'react-beforeunload';
import ListAnswered from './ListAnswered';
import AnswerContent from './AnswerContent';
import { filterUniqueArrayByKey } from '../../../../services/helpers/parseData';

export type SubmitAnswer =
  | {
      value: string;
      yield_code: string;
    }
  | number;

export type SubmitObject = {
  question_id: number;
  time: number;
  order: number;
  type: number;
  answers: SubmitAnswer[];
};

export type SubmitData = {
  test_history_id?: number;
  questions?: SubmitObject[];
  test_time?: number;
  test_data?: string;
};

type SubmitPauseData = {
  submittedData: SubmitData;
  fetchedData: TestData;
  testType: number;
  unitId: number;
  currentStep: number;
  takenTime: number;
};

const ExecuteTest = () => {
  const { getParamValue } = useObjectRoutes();
  const [testData, setTestData] = useState<TestData>({});
  const testHistoryId = getParamValue('test_history_id');
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [submitData, setSubmitData] = useState<SubmitData>({});
  const [startTime, setStartTime] = useState<number>(new Date().getTime());
  const [endTime, setEndTime] = useState<number>(new Date().getTime());
  const [unitId, setUnitId] = useState(Number(getParamValue('unit_id')));
  const [testType, setTestType] = useState(Number(getParamValue('test_type')));
  const { navigate, pathname, searchParamsObject } = useObjectRoutes();
  const [takenTime, setTakenTime] = useState<number>(0);
  const startTest = useMemo(() => new Date().getTime() + 1000, [testData]);
  const formRef = useRef<HTMLFormElement>(null);
  const [isShowAnswer, setIsShowAnswer] = useState<boolean>(false);

  const addRandomOrderToArray = (data?: TestData) => {
    if (!data || isEmptyObj(data)) return {};

    const parsedQuestions = data?.questions?.map((question, index) => ({
      ...question,
      randomOrder: ++index,
    }));
    return { ...data, questions: parsedQuestions };
  };

  useEffect(() => {
    // for new test
    let fetchQuestion;

    if (unitId && testType && pathname === PATH_COURSE.EXECUTE_TEST) {
      fetchQuestion = async () => {
        const queryParam = qs.stringify(searchParamsObject);

        const data = (await request.get(
          `${API.COURSE.GET_TEST}?${queryParam}`,
          () => {},
          (error) => {
            if (error === 404) {
              navigate('/404');
            }
          },
        )) as TestData;

        setTestData(addRandomOrderToArray(data));
        setEndTime(endTime + (data?.time || 0));
      };

      if (testData && testData?.questions?.length === 0) fetchQuestion();
    }

    // for continuous test
    if (testHistoryId && pathname === PATH_COURSE.CONTINUE_TEST) {
      fetchQuestion = async () => {
        request.get(
          `${API.COURSE.GET_PAUSED_TEST}?test_history_id=${Number(testHistoryId) || 0}`,
          (res: string) => {
            const dataParsed = JSON.parse(res) as SubmitPauseData;

            setTestData(addRandomOrderToArray(dataParsed?.fetchedData || {}));
            setSubmitData(dataParsed?.submittedData);
            setTestType(dataParsed?.testType);
            setUnitId(dataParsed?.unitId);
            setCurrentStep(dataParsed?.currentStep);
            setEndTime(endTime + (dataParsed?.fetchedData?.time || 0));

            if (dataParsed?.testType && dataParsed.testType === MODE_EXECUTE_TEST.MAIN) {
              setTakenTime(Number(dataParsed?.takenTime || 0));
            }
          },
        );
      };
    }

    if (testData && !testData?.questions?.length) fetchQuestion?.();
  }, [unitId, testType, testHistoryId]);

  //set a countdown to end the test
  useEffect(() => {
    //testData.time only exists when the test is limited
    if (isEmptyObj(testData) || !Number(testData.time)) return;

    const timer = setTimeout(
      () => submitFinish(submitData, true),
      endTime - new Date().getTime() - takenTime,
    );

    return () => clearTimeout(timer);
  }, [testData, takenTime, submitData]);

  //reload warning
  useBeforeunload((event) => {
    event.preventDefault();
    submitPause(submitData);
    return 'Would you like to exit?';
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //essential checking
    const currentSubmit = (e.nativeEvent as SubmitEvent)?.submitter?.id;
    if (!currentSubmit) return;

    if (
      currentSubmit === SUBMIT_EXECUTE_TEST_TYPE.FINISH &&
      testData?.questions?.length === currentStep
    ) {
      await submitFinish(submitData);
      return;
    }

    //form actions
    const form = e.currentTarget;
    const formData: Object = {};
    const inputs = form.querySelectorAll<HTMLInputElement | HTMLSelectElement>(
      'input[type="text"], input[type="hidden"], select[name], input[type="radio"]:checked, input[type="checkbox"]:checked',
    );
    inputs.forEach((input) => (formData[input.name] = input.value));

    const { question_id, order, type, ...plainAnswer } = formData;
    //get old question data time
    const oldQuestion = submitData?.questions?.find(
      (question) => Number(question.question_id) === Number(question_id),
    );
    const oldQuestionTime = oldQuestion?.time || 0;

    //prepare data for submit
    const currentQuestionSubmit: SubmitObject = {
      question_id: Number(question_id),
      time: new Date().getTime() - startTime + Number(oldQuestionTime),
      order: Number(order),
      type: Number(type),
      answers: getAnswerByType(plainAnswer),
    };

    const prepareQuestions: SubmitData['questions'] = submitData?.questions || [];
    if (currentSubmit !== SUBMIT_EXECUTE_TEST_TYPE.PAUSE) {
      prepareQuestions.push(currentQuestionSubmit);
    }
    const prepareSubmitData: SubmitData = {
      test_history_id: testData.test_history_id,
      //filter unique array item by latest update
      questions: filterUniqueArrayByKey(prepareQuestions.reverse(), 'question_id'),
      test_time: new Date().getTime() - startTest + takenTime,
    };

    //store for next answer
    setSubmitData(prepareSubmitData);
    // submit
    switch (currentSubmit) {
      case SUBMIT_EXECUTE_TEST_TYPE.ANSWER:
        if (
          testType !== MODE_EXECUTE_TEST.PRACTICE &&
          currentStep === (testData?.questions?.length || 0) - 1
        ) {
          await submitFinish(prepareSubmitData);
        }
        setStartTime(new Date().getTime());
        setCurrentStep?.(currentStep !== undefined ? currentStep + 1 : 0);

        if (testType === MODE_EXECUTE_TEST.PRACTICE) {
          setIsShowAnswer(!isShowAnswer);
        }

        break;
      case SUBMIT_EXECUTE_TEST_TYPE.PAUSE:
        await submitPause(prepareSubmitData);
        break;
      case SUBMIT_EXECUTE_TEST_TYPE.FINISH:
        await submitFinish(prepareSubmitData);
        break;
      case SUBMIT_EXECUTE_TEST_TYPE.CHANGE:
        setStartTime(new Date().getTime());
        setCurrentStep?.(
          Number((e.nativeEvent as SubmitEvent)?.submitter?.getAttribute('data-random-order')) - 1,
        );
        break;

      default:
        swalError();
        break;
    }
  };

  const submitFinish = async (submitDataArg: SubmitData, isTimeout?: boolean) => {
    let postData = submitDataArg;
    if (isTimeout) {
      const formData: Object = {};
      const inputs = formRef.current?.querySelectorAll<HTMLInputElement | HTMLSelectElement>(
        'input[type="text"], input[type="hidden"], select[name], input[type="radio"]:checked, input[type="checkbox"]:checked',
      );
      inputs?.forEach((input) => (formData[input.name] = input.value));

      const { question_id, order, type, ...plainAnswer } = formData;

      //get old question data time
      const oldQuestion = submitData?.questions?.find(
        (question) => Number(question.question_id) === Number(question_id),
      );
      const oldQuestionTime = oldQuestion?.time || 0;

      //prepare data for submit
      const currentQuestionSubmit: SubmitObject = {
        question_id: Number(question_id),
        time: new Date().getTime() - startTime + Number(oldQuestionTime),
        order: Number(order),
        type: Number(type),
        answers: getAnswerByType(plainAnswer),
      };

      const prepareQuestions: SubmitData['questions'] = submitData?.questions || [];
      prepareQuestions.push(currentQuestionSubmit);

      const prepareSubmitData: SubmitData = {
        test_history_id: testData.test_history_id,
        questions: filterUniqueArrayByKey(prepareQuestions.reverse(), 'question_id'),
        test_time: new Date().getTime() - startTest + takenTime,
      };
      postData = prepareSubmitData;
    }

    await request.post(API.COURSE.SUBMIT_FINISH_TEST, postData, () =>
      navigate(`${PATH_COURSE.UNIT_DETAIL}?unit_id=${unitId}`),
    );
  };

  const submitPause = async (submitDataArg: SubmitData) => {
    const onPauseData: SubmitPauseData = {
      submittedData: submitDataArg,
      fetchedData: testData,
      testType,
      unitId,
      currentStep,
      takenTime: new Date().getTime() - startTest + takenTime,
    };

    const submitPauseData: SubmitData = {
      test_history_id: testData.test_history_id || 0,
      test_data: JSON.stringify(onPauseData),
    };

    await request.patch(API.COURSE.SUBMIT_PAUSE_TEST, submitPauseData, () =>
      navigate(`${PATH_COURSE.UNIT_DETAIL}?unit_id=${unitId}`),
    );
  };

  return (
    <section className="bg-white w-full max-w-[828px] relative h-[99.5vh] overflow-y-auto">
      {/* header */}
      <HeaderExecuteTest
        testType={Number(testType)}
        currentQuestion={currentStep}
        totalQuestion={Number(testData?.questions?.length) || 1}
        testData={testData}
        isLimitTime={!!Number(testData?.time)}
        takenTime={takenTime}
      />

      {/* Answer content */}

      <form ref={formRef} onSubmit={handleSubmit} className="none">
        {testType === MODE_EXECUTE_TEST.PRACTICE && isShowAnswer && (
          <AnswerContent
            questionOrder={currentStep}
            submitQuestionData={submitData?.questions}
            questions={testData?.questions}
            isShowAnswer={isShowAnswer}
            setIsShowAnswer={setIsShowAnswer}
          />
        )}

        {/* main content */}
        <div className={`min-h-[calc(100vh-600px)] ${isShowAnswer && 'hidden'}`}>
          {!!testData?.questions?.length && (
            <QuestionContent
              question={testData.questions[currentStep] || {}}
              currentStep={currentStep}
              options={{ answeredData: submitData.questions }}
            />
          )}
        </div>

        {/* footer */}
        <FooterExecuteTest
          isShowAnswer={isShowAnswer}
          isDisable={currentStep === testData?.questions?.length}
          setIsShowAnswer={setIsShowAnswer}
        />

        {/* List questions */}
        <ListAnswered
          testData={testData}
          submitData={submitData}
          currentStep={currentStep}
          className={isShowAnswer ? 'hidden' : ''}
        />
      </form>
    </section>
  );
};

export default ExecuteTest;
