import React from 'react';
import ButtonExecuteTest from './Button';
import { SUBMIT_EXECUTE_TEST_TYPE } from '../../../../services/constants/student/unit';

type FooterExecuteTestType = {
  isDisable: boolean,
  isShowAnswer: boolean,
  setIsShowAnswer: React.Dispatch<React.SetStateAction<boolean>>
}

const FooterExecuteTest = ({ isDisable, isShowAnswer, setIsShowAnswer }: FooterExecuteTestType) => {
  return (
    <div className={`w-full flex justify-center mb-[50px]`}>
      <div className="">
        <div className="w-full flex justify-center mb-[51px]">
          {isShowAnswer ?
            <div
              className={`${isDisable ? 'hidden' : ''} rounded-[5px] font-[700] text-[18px] leading-[100%] text-white px-[30px] py-[10px] bg-danger cursor-pointer`}
              onClick={() => {
                if (!isDisable) return setIsShowAnswer(!isShowAnswer)
              }}
            >
              次の問題へ
            </div> :
            <ButtonExecuteTest
              className="bg-danger"
              type="submit"
              id={`${SUBMIT_EXECUTE_TEST_TYPE.ANSWER}`}
            >
              解答する
            </ButtonExecuteTest>}
        </div>
        <div className="flex gap-[20px]">
          <ButtonExecuteTest
            className="bg-secondary-light"
            id={`${SUBMIT_EXECUTE_TEST_TYPE.PAUSE}`}
          >
            一時中断する
          </ButtonExecuteTest>

          <ButtonExecuteTest
            className="bg-secondary-light"
            id={`${SUBMIT_EXECUTE_TEST_TYPE.FINISH}`}
          >
            終了・採点する
          </ButtonExecuteTest>
        </div>
      </div>
    </div>
  );
};

export default FooterExecuteTest;
