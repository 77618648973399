import { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import katex from "katex";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "katex/dist/katex.min.css";
import CustomToolBar from "./CustomToolBar";
import BaseModal from "../../admin/commons/BaseModal";
import UploadQuestionImage from "../../admin/commons/UploadQuestionImage";
import { UNIT_FILE_TYPE } from "../../../services/constants/admin/pages/material";
import LatexSymbolModal from "../../admin/commons/LatexSymbolModal";
import Quill from "quill";
import "./CustomFormulaBlot";
import CustomToolBarStudent from "./CustomToolBarStudent";
import CustomToolBarProduct from "./CustomToolBarProduct";

(window as any).katex = katex;

type RichTextEditorProps = {
  value?: string | null;
  changeHandler?: (html: string) => void;
  className?: string;
  toolbarId: string;
  placeholder?: string;
  width?: string;
  isReadOnly?: boolean;
  isStudent?: boolean;
};

const RichTextEditorProduct = ({
  value,
  changeHandler,
  className,
  toolbarId,
  placeholder,
  width,
  isReadOnly,
  isStudent,
}: RichTextEditorProps) => {
  const quillRef = useRef<ReactQuill>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenFormulaModal, setIsOpenFormulaModal] = useState<boolean>(false);
  const [resourceLink, setResourceLink] = useState<string>("");
  const imageHandler = () => {
    setIsOpenModal(true);
  };
  const undoChange = () => {
    (quillRef.current?.getEditor() as any).history.undo();
  };
  const redoChange = () => {
    (quillRef.current?.getEditor() as any).history.redo();
  };

  const formulaHandler = () => {
    setIsOpenFormulaModal(true);
  };

  const insertFormula = (equation: string) => {
    // @ts-ignore
    const editor = quillRef?.current?.getEditor();
    // @ts-ignore
    const range = editor.getSelection(true);
    // @ts-ignore
    editor.insertEmbed(range.index, "formula", equation, Quill.sources.USER);
    // @ts-ignore
    editor.setSelection(range.index + 1, Quill.sources.SILENT);
  };

  useEffect(() => {
    if (!resourceLink) return;
    // @ts-ignore
    const editor = quillRef?.current?.getEditor();
    // @ts-ignore
    const range = editor.getSelection(true);
    // @ts-ignore
    editor.insertEmbed(range.index, "image", resourceLink);
  }, [resourceLink]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: "#" + toolbarId,
        handlers: {
          undo: undoChange,
          redo: redoChange,
          image: imageHandler,
          formula: formulaHandler,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    }),
    []
  );

  const formats = [
    "font",
    "header",
    "color",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "code",
    "list",
    "bullet",
    "link",
    "image",
    "code-block",
    "formula",
  ];

  document
    .querySelectorAll(".ql-color, .ql-font, .ql-header, .ql-link, .ql-align")
    .forEach((tool) => {
      tool.addEventListener("mousedown", function (event) {
        event.preventDefault();
        event.stopPropagation();
      });
    });

  return (
    <>
      <div className={`${width} border-[1px]`}>
        <CustomToolBarProduct
          id={toolbarId}
          className={isReadOnly ? "hidden" : ""}
        />
        <ReactQuill
          className={`${className} max-h-[283px] transition-all overflow-y-auto overflow-x-auto border-0`}
          value={value as any}
          onChange={changeHandler}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          ref={quillRef}
          theme={"snow"}
          readOnly={isReadOnly}
        />
      </div>
    </>
  );
};

export default RichTextEditorProduct;
