import { useParams } from "react-router-dom";
import CouponUpsert from "../../../components/admin/Coupon/CouponUpsert";
import CouponBase from ".";

const CouponUpsertPage = () => {
  const params = useParams();
  const title = params.id ? 'クーポン更新' : ' クーポン新規登録  ';
  
  return (
    <CouponBase title={`講座販売 ｜ ${title}`}>
      <CouponUpsert />
    </CouponBase>
  );
};

export default CouponUpsertPage;