import React, { useContext, useEffect, useState } from "react";
import { ChildrenWithProps, SetStateAction } from "../types/globals";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { LoginSettingType } from "../types/admin/login_setting";
import { useStorage } from "../hooks/useStorage";
import { STUDENT_THEME_DEFAULT } from "../services/constants/admin/pages/student_theme";
import { StudentThemeType } from "../types/admin/student_theme";
import { HEADER_TITLE_DEFAULT } from "../services/constants/globals";

type LoginSettingContextType = {
  loginSetting: LoginSettingType;
  setLoginSetting: SetStateAction<LoginSettingType>;
  studentTheme: StudentThemeType;
  setStudentTheme: SetStateAction<StudentThemeType>;
  fetching: boolean;
  setFetching: SetStateAction<boolean>;
};

const LoginSettingContext = React.createContext<LoginSettingContextType>({
  loginSetting: {},
  setLoginSetting: () => {},
  studentTheme: {},
  setStudentTheme: () => {},
  fetching: true,
  setFetching: () => {},
});

const LoginSettingContextProvider = ({ children }: ChildrenWithProps) => {
  const { getLocalStorage, setLocalStorage } = useStorage();
  const loginSettingStorage = getLocalStorage("login_setting");
  const studentThemeStorage = getLocalStorage("theme");
  const [loginSetting, setLoginSetting] =
    useState<LoginSettingType>(loginSettingStorage);
  const [studentTheme, setStudentTheme] =
    useState<StudentThemeType>(studentThemeStorage);
  const [fetching, setFetching] = useState<boolean>(true);
  const subdomain = window.location.hostname.split(".")[0];

  const handleConfigFavicon = (loginSetting: LoginSettingType) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.setAttribute("rel", "icon");
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    if (loginSetting.favicon_path_url) {
      link.setAttribute("href", loginSetting.favicon_path_url);
    }

    let title = document.querySelector("title");
    if (title) {
      title.innerHTML = loginSetting.system_name || HEADER_TITLE_DEFAULT;
    }
  };

  useEffect(() => {
    const configStudent = async () => {
      const fetchLoginSetting = async () => {
        const result = await makeRequest({
          method: "get",
          url: `${API.LOGIN_SETTING.GET_DETAIL}?sub_domain=${subdomain}`,
        });
        const data = result.data;
        if (!data) {
          setFetching(false);
          return;
        }

        handleConfigFavicon(data);
        setLoginSetting(data);
        setLocalStorage("login_setting", data);
      };

      const fetchStudentTheme = async () => {
        const result = await makeRequest({
          method: "get",
          url: `${API.STUDENT_THEME_SETTING.GET_DETAIL}?sub_domain=${subdomain}`,
        });

        const studentTheme = !result.data ? STUDENT_THEME_DEFAULT : result.data;
        setStudentTheme(studentTheme);
        setLocalStorage("theme", studentTheme);
      };

      if (
        !loginSettingStorage ||
        !studentThemeStorage ||
        studentThemeStorage == null
      ) {
        try {
          await Promise.all([fetchLoginSetting(), fetchStudentTheme()]);
          setFetching(false);
        } catch (error) {
          setFetching(false);
        }
        return;
      }

      if (loginSettingStorage) {
        setLoginSetting(loginSettingStorage);
        setFetching(false);
        handleConfigFavicon(loginSettingStorage);
      }
    };

    configStudent();
  }, []);

  useEffect(() => {
    handleConfigFavicon(loginSetting);
  }, [loginSetting]);

  return (
    <LoginSettingContext.Provider
      value={{
        fetching,
        loginSetting,
        studentTheme,
        setStudentTheme,
        setFetching,
        setLoginSetting,
      }}
    >
      {children}
    </LoginSettingContext.Provider>
  );
};

export default LoginSettingContextProvider;

export const useLoginSettingContext = () => useContext(LoginSettingContext);
