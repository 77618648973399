import React, { ChangeEvent, useState } from "react";
import BaseModal from "../../../commons/BaseModal";
import UploadFile from "../../../commons/UploadFile";
import { convertTimestampToDateTime } from "../../../../../services/helpers/formatTime";
import {
  UNIT_LESSON_MAIN_TYPE_DATA,
  UNIT_LESSON_SUB_TYPE_DATA,
  UNIT_FILE_TYPE,
} from "../../../../../services/constants/admin/pages/material";
import {
  MainResource,
  SubResource,
  Unit,
} from "../../../../../types/admin/unit";
import RichTextEditor from "../../../../commons/form/TextEditor";
import ProgressBar from "../../../commons/ProgressBar";

type LessonFileTypeSelectProps = {
  mainFile: File | null;
  setMainFile: React.Dispatch<React.SetStateAction<File | null>>;
  subFile: File | null;
  setSubFile: React.Dispatch<React.SetStateAction<File | null>>;
  unitFormData: Unit;
  setUnitFormData: React.Dispatch<React.SetStateAction<Unit>>;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
  mainFileFolderId: string;
  subFileFolderId: string;
  setMainFileFolderId: React.Dispatch<React.SetStateAction<string>>;
  setSubFileFolderId: React.Dispatch<React.SetStateAction<string>>;
  setMainFileFolderCode: React.Dispatch<React.SetStateAction<number | null>>;
  setSubFileFolderCode: React.Dispatch<React.SetStateAction<number | null>>;
  version?: string;
  unitUpdatedAt?: string | Date;
  mainResource: MainResource | null;
  subResource: SubResource | null;
  setMainResource: React.Dispatch<React.SetStateAction<MainResource | null>>;
  setSubResource: React.Dispatch<React.SetStateAction<SubResource | null>>;
  processPercent: number;
};

const LessonTypeSelect = ({
  mainFile,
  setMainFile,
  subFile,
  setSubFile,
  unitFormData,
  handleChange,
  setMainFileFolderId,
  setSubFileFolderId,
  setMainFileFolderCode,
  setSubFileFolderCode,
  mainFileFolderId,
  subFileFolderId,
  version,
  unitUpdatedAt,
  mainResource,
  subResource,
  setMainResource,
  setSubResource,
  setUnitFormData,
  processPercent,
}: LessonFileTypeSelectProps) => {
  const [isOpenModal1, setIsOpenModal1] = useState<boolean>(false);
  const [isOpenModal2, setIsOpenModal2] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [uploadDate1, setUploadDate1] = useState<string>("");
  const [uploadDate2, setUploadDate2] = useState<string>("");
  const subResourceType = [
    UNIT_FILE_TYPE.TYPE_IMAGE,
    UNIT_FILE_TYPE.TYPE_EDITOR,
    UNIT_FILE_TYPE.TYPE_PDF,
  ];
  const handleFileTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (
      e.target.name === "main_resource_type" &&
      subResourceType.includes(Number(e.target.value))
    ) {
      setSubResource(null);
      setUnitFormData((prev) => ({
        ...prev,
        sub_resource_content: null,
        sub_resource_type: null,
        sub_resource_downloadable: 0,
      }));
    }
    if (e.target.name === "main_resource_type") {
      setUnitFormData((prev) => ({
        ...prev,
        main_resource_content: null,
      }));
      setMainFile(null);
      setMainResource(null);
      return;
    }
    if (e.target.name === "sub_resource_type") {
      setUnitFormData((prev) => ({
        ...prev,
        sub_resource_content: null,
      }));
      setSubFile(null);
      setSubResource(null);
      return;
    }
  };

  return (
    <>
      <div className="w-full flex items-center mb-[5px]">
        <div className="rounded-[5px] font-[500px] flex items-center justify-center border-primary border text-[14px] leading-[100%] text-primary w-[140px] h-[30px] mr-[15px]">
          メイン教材の登録
        </div>
        <div className="mr-[25px] text-[12px] font-[400]">
          ページ上部に表示するメインの教材を設定します
        </div>
      </div>
      <div className="px-[30px] py-[15px] border-b border-secondary-light mb-[40px]">
        <div className="mb-[35px]">
          <div className="w-full flex items-center mb-[10px]">
            <div className="mr-[28.5px] text-[13px] font-[500]">
              教材の種類を選択
            </div>
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mb-[3px]">
              必須
            </div>
          </div>
          <div className="text-secondary-dark w-full max-w-[920px] h-[50px] bg-[#F9F9F9] items-center px-3 flex">
            {UNIT_LESSON_MAIN_TYPE_DATA.map((data, index) => (
              <label
                className="inline-flex items-center mr-[20.5px]"
                key={index}
              >
                <input
                  type="radio"
                  className="form-radio text-primary-light border border-success-extralight"
                  name="main_resource_type"
                  value={data.value}
                  checked={
                    data.value === Number(unitFormData.main_resource_type)
                  }
                  onChange={handleFileTypeChange}
                />
                <span className="ml-[8px] text-[12px] font-[400] leading-[100%]">
                  {data.label}
                </span>
              </label>
            ))}
          </div>
        </div>
        {Number(unitFormData.main_resource_type) ===
        UNIT_FILE_TYPE.TYPE_EDITOR ? (
          <>
            <div className="w-full flex items-center mb-[10px]">
              <div className="mr-[28.5px] text-[13px] font-[500]">
                編集画面で教材を作成する
              </div>
            </div>
            <div className="w-full">
              <RichTextEditor
                value={unitFormData.main_resource_content}
                changeHandler={(html: string) => {
                  setUnitFormData((prev) => ({
                    ...prev,
                    main_resource_content: html,
                  }));
                }}
                toolbarId="toolbar"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="py-[15px] mb-[35px]">
              <div className="w-full flex items-center mb-[5px]">
                <div className="mr-[25px] text-[13px] font-[500]">
                  受講者によるダウンロードの可否（メイン教材ファイル）
                </div>
                <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px] mb-[5px]">
                  必須
                </div>
              </div>
              <div className="text-secondary-dark w-full max-w-[340px] h-[50px] bg-[#F9F9F9] items-center px-3 flex ">
                <label className="inline-flex items-center mr-[15px]">
                  <input
                    type="radio"
                    className="form-radio text-primary-light border border-success-extralight"
                    name="main_resource_downloadable"
                    value={1}
                    onChange={handleChange}
                    checked={unitFormData.main_resource_downloadable == 1}
                  />
                  <span className="ml-[8px] text-[12px] font-[400] leading-[100%]">
                    ダウンロード可
                  </span>
                </label>
                <label className="inline-flex items-center mr-[20px]">
                  <input
                    type="radio"
                    className="form-radio text-primary-light border border-success-extralight ml-[33.5px]"
                    name="main_resource_downloadable"
                    value={0}
                    onChange={handleChange}
                    checked={unitFormData.main_resource_downloadable == 0}
                  />
                  <span className="ml-[8px] text-[12px] font-[400] leading-[100%]">
                    ダウンロード不可
                  </span>
                </label>
              </div>
            </div>
            {mainFile ? (
              <div className="p-[20px] bg-secondary-morelighter w-full max-w-[710px] min-h-[180px]">
                <div className="font-[500] text-[14px] mb-[15px]">
                  登録済みの教材ファイル
                </div>
                <div className="flex w-full with-roboto font-[500] text-[14px] text-secondary mb-[30px]">
                  <div className="p-[10px] min-h-[36px] bg-secondary-extralight w-full max-w-[150px]">
                    登録済みファイル
                  </div>
                  <div className="p-[10px] min-h-[36px] bg-white border-r-[1px] border-secondary-light w-full max-w-[200px]">
                    {mainFile.name}
                  </div>
                  <div className="p-[10px] min-h-[36px] font-[400] text-[12px] bg-white border-r-[1px] border-secondary-light w-full max-w-[220px]">
                    更新：{uploadDate1}
                  </div>
                  <div
                    className="p-[10px] min-h-[36px] bg-white w-full max-w-[100px] text-primary"
                    onClick={() => setIsOpenModal1(true)}
                  >
                    編集／削除
                  </div>
                </div>
                {processPercent > 0 && <ProgressBar percent={processPercent} />}
                <div className="w-full flex justify-center items-center mb-[15px]">
                  <button
                    type="button"
                    className="min-w-[260px] bg-secondary px-[32.5px] py-[8px] text-white rounded-[10px] font-[700] text-[15px] leading-[100%]"
                    onClick={() => {
                      setMainFile(null);
                      setMainResource(null);
                    }}
                  >
                    この教材ファイルを削除する
                  </button>
                </div>
              </div>
            ) : mainResource ? (
              <div className="p-[20px] bg-secondary-morelighter w-full max-w-[710px] min-h-[180px]">
                <div className="font-[500] text-[14px] mb-[15px]">
                  登録済みの教材ファイル
                </div>
                <div className="flex w-full with-roboto font-[500] text-[14px] text-secondary mb-[30px]">
                  <div className="p-[10px] min-h-[36px] bg-secondary-extralight w-full max-w-[150px]">
                    登録済みファイル
                  </div>
                  <div className="p-[10px] min-h-[36px] bg-white border-r-[1px] border-secondary-light w-full max-w-[200px]">
                    {mainResource.origin_filename}
                  </div>
                  <div className="p-[10px] min-h-[36px] font-[400] text-[12px] bg-white border-r-[1px] border-secondary-light w-full max-w-[220px]">
                    更新：
                    {convertTimestampToDateTime(
                      mainResource.updated_at,
                      false,
                      true
                    )}
                  </div>
                  <div
                    className="p-[10px] min-h-[36px] bg-white w-full max-w-[100px] text-primary cursor-pointer"
                    onClick={() => setIsOpenModal1(true)}
                  >
                    編集／削除
                  </div>
                </div>
                {processPercent > 0 && <ProgressBar percent={processPercent} />}
                <div className="w-full flex justify-center items-center mb-[15px]">
                  <button
                    type="button"
                    className="min-w-[260px] bg-secondary px-[32.5px] py-[8px] text-white rounded-[10px] font-[700] text-[15px] leading-[100%]"
                    onClick={() => {
                      setMainFile(null);
                      setMainResource(null);
                    }}
                  >
                    この教材ファイルを削除する
                  </button>
                </div>
              </div>
            ) : (
              <div className="px-[30px] py-[15px] mb-[35px]">
                <div className="w-full flex items-center mb-[10px]">
                  <div className="mr-[25px] text-[13px] font-[500]">
                    教材ファイルのアップロード
                  </div>
                </div>
                <div className="pl-[10px]">
                  <div className="font-[700] text-[13px] text-primary">
                    注意事項
                  </div>
                  <div className="font-[400] text-[12px] mb-[15px]">
                    ・アップロードできる動画、音声ファイル容量の上限は2GBです。
                    <br />
                    ・スライドPDFのファイル容量は3MB以下を推奨しています（上限100MB）
                    <br />
                    ・視聴画像の推奨サイズは509x300px以下です（上限10MB）
                    <br />
                    ・ファイルは1つずつ選択してアップロードをしてください（複数選択不可）
                    <br />
                  </div>
                  <div className="font-[700] text-[14px] text-secondary">
                    教材ファイルを選択
                  </div>
                  <div className="h-[60px] w-[400px] bg-[#FAF7F0] flex items-center px-2 gap-x-[10px] mb-[15px]">
                    <button
                      type="button"
                      className="w-[105px] h-[38px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]"
                      onClick={() => {
                        setIsOpenModal1(true);
                        setMainFileFolderId("");
                      }}
                    >
                      ファイルを選択
                    </button>
                    <p className="text-[10px] font-[400]">選択されていません</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {Number(unitFormData.sub_resource_type) === UNIT_FILE_TYPE.TYPE_EDITOR &&
      (Number(unitFormData.main_resource_type) === UNIT_FILE_TYPE.TYPE_AUDIO ||
        Number(unitFormData.main_resource_type) ===
          UNIT_FILE_TYPE.TYPE_VIDEO) ? (
        <>
          <div className="w-full flex items-center mb-[5px]">
            <div className="rounded-[5px] font-[500px] flex items-center justify-center border-primary border text-[14px] leading-[100%] text-primary w-[140px] h-[30px] mr-[15px]">
              副教材の登録
            </div>
            <div className="mr-[25px] text-[12px] font-[400]">
              動画や音声ファイルと同時に視聴する教材がある場合
            </div>
          </div>
          <div className="px-[30px] py-[15px] border-b border-secondary-light">
            <div className="mb-[35px]">
              <div className="w-full flex items-center mb-[10px]">
                <div className="mr-[28.5px] text-[13px] font-[500]">
                  教材の種類を選択
                </div>
              </div>
              <div className="text-secondary-dark w-full max-w-[920px] h-[50px] bg-[#F9F9F9] items-center px-3 flex">
                {UNIT_LESSON_SUB_TYPE_DATA.map((data, index) => (
                  <label
                    className="inline-flex items-center mr-[33px]"
                    key={index}
                  >
                    <input
                      type="radio"
                      className="form-radio text-primary-light border border-success-extralight"
                      name="sub_resource_type"
                      value={data.value}
                      checked={
                        data.value === Number(unitFormData.sub_resource_type)
                      }
                      onChange={handleFileTypeChange}
                    />
                    <span className="ml-[8px] text-[12px] font-[400] leading-[100%]">
                      {data.label}
                    </span>
                  </label>
                ))}
              </div>
            </div>
            <div className="w-full flex items-center mb-[10px]">
              <div className="mr-[28.5px] text-[13px] font-[500]">
                編集画面で教材を作成する
              </div>
            </div>
            <div className="w-full max-h-[283px] overflow-y-auto overflow-x-auto">
              <RichTextEditor
                value={unitFormData.sub_resource_content}
                changeHandler={(html: string) => {
                  setUnitFormData((prev) => ({
                    ...prev,
                    sub_resource_content: html,
                  }));
                }}
                toolbarId="toolbar"
              />
            </div>
          </div>
        </>
      ) : (Number(unitFormData.main_resource_type) ===
          UNIT_FILE_TYPE.TYPE_VIDEO ||
          Number(unitFormData.main_resource_type) ===
            UNIT_FILE_TYPE.TYPE_AUDIO) &&
        Number(unitFormData.sub_resource_type) !==
          UNIT_FILE_TYPE.TYPE_EDITOR ? (
        <>
          <div className="w-full flex items-center mb-[5px]">
            <div className="rounded-[5px] font-[500px] flex items-center justify-center border-primary border text-[14px] leading-[100%] text-primary w-[140px] h-[30px] mr-[15px]">
              副教材の登録
            </div>
            <div className="mr-[25px] text-[12px] font-[400]">
              動画や音声ファイルと同時に視聴する教材がある場合
            </div>
          </div>
          <div className="px-[30px] py-[15px] border-b border-secondary-light">
            <div className="mb-[35px]">
              <div className="w-full flex items-center mb-[10px]">
                <div className="mr-[28.5px] text-[13px] font-[500]">
                  教材の種類を選択
                </div>
              </div>
              <div className="text-secondary-dark w-full max-w-[920px] h-[50px] bg-[#F9F9F9] items-center px-3 flex mb-[35px]">
                {UNIT_LESSON_SUB_TYPE_DATA.map((data, index) => (
                  <label
                    className="inline-flex items-center mr-[33px]"
                    key={index}
                  >
                    <input
                      type="radio"
                      className="form-radio text-primary-light border border-success-extralight"
                      name="sub_resource_type"
                      value={data.value}
                      checked={
                        data.value === Number(unitFormData.sub_resource_type)
                      }
                      onChange={handleFileTypeChange}
                    />
                    <span className="ml-[8px] text-[12px] font-[400] leading-[100%]">
                      {data.label}
                    </span>
                  </label>
                ))}
              </div>
              <div className="py-[15px] mb-[35px]">
                <div className="w-full flex items-center mb-[5px]">
                  <div className="mr-[25px] text-[13px] font-[500]">
                    受講者によるダウンロードの可否（副教材ファイル）
                  </div>
                </div>
                <div className="text-secondary-dark w-full max-w-[340px] h-[50px] bg-[#F9F9F9] items-center px-3 flex ">
                  <label className="inline-flex items-center mr-[15px]">
                    <input
                      type="radio"
                      className="form-radio text-primary-light border border-success-extralight"
                      name="sub_resource_downloadable"
                      value={1}
                      onChange={handleChange}
                      checked={unitFormData.sub_resource_downloadable == 1}
                    />
                    <span className="ml-[8px] text-[12px] font-[400] leading-[100%]">
                      ダウンロード可
                    </span>
                  </label>
                  <label className="inline-flex items-center mr-[20px]">
                    <input
                      type="radio"
                      className="form-radio text-primary-light border border-success-extralight ml-[33.5px]"
                      name="sub_resource_downloadable"
                      value={0}
                      onChange={handleChange}
                      checked={unitFormData.sub_resource_downloadable == 0}
                    />
                    <span className="ml-[8px] text-[12px] font-[400] leading-[100%]">
                      ダウンロード不可
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {subFile ? (
              <div className="p-[20px] bg-secondary-morelighter w-full max-w-[710px] min-h-[180px]">
                <div className="font-[500] text-[14px] mb-[15px]">
                  登録済みの教材ファイル
                </div>
                <div className="flex w-full with-roboto font-[500] text-[14px] text-secondary mb-[30px]">
                  <div className="p-[10px] min-h-[36px] bg-secondary-extralight w-full max-w-[150px]">
                    登録済みファイル
                  </div>
                  <div className="p-[10px] min-h-[36px] bg-white border-r-[1px] border-secondary-light w-full max-w-[200px]">
                    {subFile.name}
                  </div>
                  <div className="p-[10px] min-h-[36px] font-[400] text-[12px] bg-white border-r-[1px] border-secondary-light w-full max-w-[220px]">
                    更新：{uploadDate2}
                  </div>
                  <div
                    className="p-[10px] min-h-[36px] bg-white w-full max-w-[100px] text-primary"
                    onClick={() => setIsOpenModal2(true)}
                  >
                    編集／削除
                  </div>
                </div>
                {processPercent > 0 && <ProgressBar percent={processPercent} />}
                <div className="w-full flex justify-center items-center mb-[15px]">
                  <button
                    type="button"
                    className="min-w-[260px] bg-secondary px-[32.5px] py-[8px] text-white rounded-[10px] font-[700] text-[15px] leading-[100%]"
                    onClick={() => {
                      setSubFile(null);
                      setSubResource(null);
                    }}
                  >
                    この教材ファイルを削除する
                  </button>
                </div>
              </div>
            ) : subResource ? (
              <div className="p-[20px] bg-secondary-morelighter w-full max-w-[710px] min-h-[180px]">
                <div className="font-[500] text-[14px] mb-[15px]">
                  登録済みの教材ファイル
                </div>
                <div className="flex w-full with-roboto font-[500] text-[14px] text-secondary mb-[30px]">
                  <div className="p-[10px] min-h-[36px] bg-secondary-extralight w-full max-w-[150px]">
                    登録済みファイル
                  </div>
                  <div className="p-[10px] min-h-[36px] bg-white border-r-[1px] border-secondary-light w-full max-w-[200px]">
                    {subResource.origin_filename}
                  </div>
                  <div className="p-[10px] min-h-[36px] font-[400] text-[12px] bg-white border-r-[1px] border-secondary-light w-full max-w-[220px]">
                    更新：
                    {convertTimestampToDateTime(
                      subResource.updated_at,
                      false,
                      true
                    )}
                  </div>
                  <div
                    className="p-[10px] min-h-[36px] bg-white w-full max-w-[100px] text-primary"
                    onClick={() => setIsOpenModal2(true)}
                  >
                    編集／削除
                  </div>
                </div>
                {processPercent > 0 && <ProgressBar percent={processPercent} />}
                <div className="w-full flex justify-center items-center mb-[15px]">
                  <button
                    type="button"
                    className="min-w-[260px] bg-secondary px-[32.5px] py-[8px] text-white rounded-[10px] font-[700] text-[15px] leading-[100%]"
                    onClick={() => {
                      setSubFile(null);
                      setSubResource(null);
                    }}
                  >
                    この教材ファイルを削除する
                  </button>
                </div>
              </div>
            ) : (
              <div className="px-[30px] py-[15px] mb-[35px]">
                <div className="w-full flex items-center mb-[10px]">
                  <div className="mr-[25px] text-[13px] font-[500]">
                    教材ファイルのアップロード
                  </div>
                </div>
                <div className="pl-[10px]">
                  <div className="font-[700] text-[13px] text-primary">
                    注意事項
                  </div>
                  <div className="font-[400] text-[12px] mb-[15px]">
                    ・アップロードできる動画、音声ファイル容量の上限は2GBです。
                    <br />
                    ・スライドPDFのファイル容量は3MB以下を推奨しています（上限100MB）
                    <br />
                    ・視聴画像の推奨サイズは509x300px以下です（上限10MB）
                    <br />
                    ・ファイルは1つずつ選択してアップロードをしてください（複数選択不可）
                    <br />
                  </div>
                  <div className="font-[700] text-[14px] text-secondary">
                    教材ファイルを選択
                  </div>
                  <div className="h-[60px] w-[400px] bg-[#FAF7F0] flex items-center px-2 gap-x-[10px] mb-[15px]">
                    <button
                      type="button"
                      className="w-[105px] h-[38px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]"
                      onClick={() => {
                        setIsOpenModal2(true);
                        setSubFileFolderId("");
                      }}
                    >
                      ファイルを選択
                    </button>
                    <p className="text-[10px] font-[400]">選択されていません</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div></div>
      )}
      {isOpenModal1 && (
        <BaseModal
          setIsOpen={setIsOpenModal1}
          width={1010}
          height={718}
          justifyDirection="end"
        >
          <UploadFile
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            setFile={setMainFile}
            fileType={unitFormData.main_resource_type}
            setIsOpen={setIsOpenModal1}
            setUploadDate={setUploadDate1}
            setFolderId={setMainFileFolderId}
            folderId={mainFileFolderId}
            setFolderCode={setMainFileFolderCode}
            setResource={setMainResource}
            isDisabled={!mainFileFolderId}
          />
        </BaseModal>
      )}
      {isOpenModal2 && (
        <BaseModal
          setIsOpen={setIsOpenModal2}
          width={1010}
          height={718}
          justifyDirection="end"
        >
          <UploadFile
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            setFile={setSubFile}
            fileType={unitFormData.sub_resource_type}
            setIsOpen={setIsOpenModal2}
            setUploadDate={setUploadDate2}
            setFolderId={setSubFileFolderId}
            folderId={subFileFolderId}
            setFolderCode={setSubFileFolderCode}
            setResource={setSubResource}
            isDisabled={!subFileFolderId}
          />
        </BaseModal>
      )}
    </>
  );
};

export default LessonTypeSelect;
