import TabCard from "../../../components/admin/commons/TabCard";
import AdminLayout from "../../../layouts/admin";
import { PATH_ADMIN_COURSE } from "../../../services/constants/route/router";
import { useTabFinder } from "../../../hooks/useTabFinder";
import { COURSE_TAB_DATA } from "../../../services/constants/admin/pages/course";
import { WithChildren } from "../../../types/globals";
import "../../../assests/styles/course.css";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";

type CourseMainProp = {
  title?: string;
};

const CourseBase = ({ title, children }: WithChildren<CourseMainProp>) => {
  const { stringSearchParam } = useObjectRoutes();
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_COURSE));

  return (
    <AdminLayout>
      <TabCard tabData={COURSE_TAB_DATA} tabIndex={stringSearchParam ? -1 : tabIndex} title={title}>
        {children}
      </TabCard>
    </AdminLayout>
  );
};

export default CourseBase;
