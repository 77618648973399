import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { IoMdRemove } from "react-icons/io";
import { BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Course, CourseItemType } from "../../../../types/admin/course";
import { Lecture } from "../../../../types/admin/lecture";
import { Chapter } from "../../../../types/admin/chapter";
import { DIRECTION } from "../../../../services/constants/globals";
import StatusButtonDisplay from "./StatusButtonDisplay";
import {
  PATH_ADMIN_COURSE,
  PATH_ADMIN_UNIT,
} from "../../../../services/constants/route/router";
import { getCountLabel } from "../../../../services/utils/admin/course";
import { FiTrash2 } from "react-icons/fi";
import { COURSE_TABLE_ITEM_TYPE } from "../../../../services/constants/admin/pages/course";
import { MemoriedExpandStatus } from "./Table";
import CreateButtonDisplay from "./CreateButtonDisplay";
import { Direction } from "../../../../types/globals";
import { PATH_ADMIN_MATERIAL } from "../../../../services/constants/route/router";

type CourseTableItemProps = {
  isUpDisabled?: boolean;
  isDownDisabled?: boolean;
  className?: string;
  data: Course | Lecture | Chapter;
  onOrder?: (
    id: number,
    direction: Direction,
    itemType: CourseItemType,
    parentId: number | null
  ) => void;
  itemType: CourseItemType;
  expandData?: MemoriedExpandStatus;
  onDelete?: (
    id: number,
    itemType: CourseItemType,
    parentId: number | null
  ) => void;
  onExpandFetch?: (
    id?: number,
    itemType?: CourseItemType,
    isExpand?: boolean
  ) => void;
  parentId?: number;
};

const TableItem = ({
  isUpDisabled = false,
  isDownDisabled = false,
  data,
  itemType,
  expandData,
  onExpandFetch,
  onOrder,
  onDelete,
  parentId,
}: CourseTableItemProps) => {
  let widthStyleByType = "w-[50%]";
  let marginStyleByType = "";
  let creationTarget = PATH_ADMIN_COURSE.LECTURE_REGISTRATION;

  switch (itemType) {
    case COURSE_TABLE_ITEM_TYPE.LECTURE:
      marginStyleByType = "ml-[20px] w_lecture";
      widthStyleByType = "w_inner_lecture";
      creationTarget = PATH_ADMIN_COURSE.CHAPTER_REGISTRATION;
      break;
    case COURSE_TABLE_ITEM_TYPE.CHAPTER:
      marginStyleByType = "ml-[43px] w_chapter";
      widthStyleByType = "w_inner_chapter";
      creationTarget = PATH_ADMIN_COURSE.DEFAULT;
      break;
    default:
      break;
  }

  return (
    <div
      className={`${marginStyleByType} min-h-[50px] border border-secondary-light rounded-[5px] flex items-center w-full text-[13px] font-[500] leading-[100%] mb-[4px]`}
    >
      <div className={`${widthStyleByType} flex items-center text-secondary`}>
        <div className="ml-[16px] mr-[16px] cursor-pointer">
          {itemType !== COURSE_TABLE_ITEM_TYPE.CHAPTER && (
            <>
              {expandData && expandData[`${itemType}_${data.id}`] ? (
                <IoMdRemove
                  size={22}
                  onClick={() => onExpandFetch?.(data.id, itemType, false)}
                  className="hover:text-secondary-lesslight"
                />
              ) : (
                <BsPlusLg
                  size={22}
                  onClick={() => onExpandFetch?.(data.id, itemType, true)}
                  className="hover:text-secondary-lesslight"
                />
              )}
            </>
          )}
        </div>
        <p className={`${widthStyleByType} w-full flex-1 leading-[150%]`}>{data.name}</p>
      </div>

      <div className="w-[5%] flex justify-center items-center text-[13px] font-[500] leading-[100%]">{`${
        itemType === COURSE_TABLE_ITEM_TYPE.COURSE ? data.lectures_count : data.child_count
      }${getCountLabel(itemType)}`}</div>

      <div className="flex w-[12%] justify-between items-center px-[1%]">
        <div className="w-[30px] h-[24px] appearance-none justify-center text-right p-[5px] border border-secondary-light rounded-[2px]">
          {data.order}
        </div>
        <AiOutlineArrowUp
          className={`items-center ${
            isUpDisabled
              ? "text-secondary-extralight cursor-not-allowed"
              : "text-secondary cursor-pointer"
          }`}
          size={22}
          onClick={() => {
            data.id &&
              !isUpDisabled &&
              onOrder?.(data.id, DIRECTION.UP, itemType, parentId || null);
          }}
        />
        <AiOutlineArrowDown
          className={` items-center ${
            isDownDisabled
              ? "text-secondary-extralight cursor-not-allowed"
              : "text-secondary cursor-pointer"
          }`}
          size={22}
          onClick={() =>
            data.id &&
            !isDownDisabled &&
            onOrder?.(data.id, DIRECTION.DOWN, itemType, parentId || null)
          }
        />
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <StatusButtonDisplay status={data.status} />
      </div>
      <div className="w-[5%] flex justify-center items-center text-secondary cursor-pointer">
        <FiTrash2
          size={20}
          onClick={() =>
            data.id && onDelete?.(data.id, itemType, parentId || null)
          }
        />
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <Link
          to={`${PATH_ADMIN_COURSE.DEFAULT}/${itemType}?${itemType}_id=${data.id}`}
        >
          <button className="w-full max-w-[45px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary text-white text-[12px] font-[500] leading-[100%]">
            設定
          </button>
        </Link>
      </div>
      <div className="w-[20%] flex justify-center items-center">
        <Link
          to={itemType !== "chapter" ? `${creationTarget}?with_${itemType}_id=${data.id}` : `${PATH_ADMIN_MATERIAL.UNIT_UPSERT}?with_${itemType}_id=${data.id}`}
          className="max-w-[140px] rounded-[5px]"
        >
          <CreateButtonDisplay itemType={itemType} />
        </Link>
      </div>
    </div>
  );
};

export default TableItem;
