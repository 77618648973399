import React, {  } from "react";

type ProductFormItemProps = {
  name?: string;
  isRequired?: boolean;
  children?: React.ReactNode;
};

const ProductFormItem = ({
  name,
  isRequired,
  children,
}: ProductFormItemProps) => {
  return (
    <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px]">
      <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[250px] bg-[#EFF1F0] flex pl-[14px]">
        <div className="w-full flex justify-between py-[12px]">
          <div className="">{name}</div>
          {isRequired ? (
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
              必須
            </div>
          ) : (
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-primary-light font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
              任意
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};

export default ProductFormItem;
