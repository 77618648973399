import React, { useState } from "react";
import LearningType from "../../../Learning/Type";
import { Unit as UnitType } from "../../../../../types/student/unit";
import { UNIT_TYPE } from "../../../../../services/constants/admin/pages/material";
import { LEARNING_TYPE } from "../../../../../services/constants/student/learning";
import {
  MODE_EXECUTE_TEST,
  UNIT_STATUS,
} from "../../../../../services/constants/student/unit";
import { useNavigate } from "react-router-dom";
import { PATH_COURSE } from "../../../../../services/constants/route/router";
import dayjs from "dayjs";
import { useObjectRoutes } from "../../../../../hooks/useObjectRoutes";
const Unit = ({
  finalUnit = false,
  unitData,
}: {
  finalUnit?: boolean;
  unitData: UnitType;
}) => {
  const navigate = useNavigate();
  const { getParamValue } = useObjectRoutes();
  const courseId = getParamValue("course_id");

  const formatDateTime = (inputDate?: string) => {
    if (!inputDate) return "";
    return dayjs(inputDate).format("YYYY年MM月DD日");
  };

  const getUnitTestType = () => {
    if (!unitData.test_type) return "";
    switch (unitData.test_type) {
      case MODE_EXECUTE_TEST.PRACTICE:
        return "練習";
      case MODE_EXECUTE_TEST.MAIN:
        return "本番";
      case MODE_EXECUTE_TEST.REVISE:
        return "復習";
      default:
        return "";
    }
  };
  const getUnitStatus = (status?: number) => {
    switch (status) {
      case UNIT_STATUS.UNIT_UNLEARNED_STATUS:
        return "(履修前)";
        break;
      case UNIT_STATUS.UNIT_LEARNING_STATUS:
        return "(学習中)";
        break;
      case UNIT_STATUS.UNIT_COMPLETED_STATUS:
        return "(終了)";
        break;
      default:
        return "";
        break;
    }
  };

  return (
    <div
      className={`flex items-center justify-between pl-[45px] min-h-[50px] ${
        unitData.status === UNIT_STATUS.UNIT_LEARNING_STATUS
          ? "bg-[#ffe6e5] mt-[5px]"
          : "bg-white"
      } border-b border-danger-light relative`}
      onClick={() =>
        navigate(
          `${PATH_COURSE.UNIT_DETAIL}?unit_id=${unitData.id}&course_id=${courseId}`
        )
      }
    >
      <div className="flex items-center">
        <LearningType type={unitData.type} status={unitData.status} />
        <div className="min-w-[250px] ml-[10px] pt-[7px] max-sm:min-w-[150px]">
          <div className="text-[12px] font-[500] text-secondary max-w-[300px] max-sm:max-w-[100px] truncate">
            {unitData.name || ""} {getUnitStatus(unitData.status || 0)}{" "}
          </div>
          <div className="text-secondary-light text-[8px] font-[500] flex gap-[15px] w-full">
            <p>学習 {unitData.total_learned || 0}</p>
            {unitData.type === LEARNING_TYPE.WRITE && (
              <p>
                ({getUnitTestType()} {unitData.total_learned_with_mode || 0}{" "}
                正答率 {unitData.percent_correct || 0}%)
              </p>
            )}
            {unitData.status === UNIT_STATUS.UNIT_COMPLETED_STATUS && (
              <p>
                <span className="mr-[2px]">学習日</span>
                {formatDateTime(unitData.time_complete_at)}
              </p>
            )}
          </div>
        </div>
      </div>
      {unitData.status === UNIT_STATUS.UNIT_LEARNING_STATUS && (
        <div className="text-[12px] font-[500] bg-white leading-[100%] text-danger border border-danger flex items-center justify-center w-[50px] max-w-[86px] h-[20px] mr-[16px] cursor-pointer">
          学習中
        </div>
      )}
      {!finalUnit && (
        <div className="w-[20px] down_polygon h-[10px] bg-danger-light absolute top-[47px] left-[47px] z-30 " />
      )}
    </div>
  );
};

export default Unit;
