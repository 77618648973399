import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BREADCRUMB_SEPARATOR } from "../../../services/constants/globals";
import { BreadCrumbRoute } from "../../../types/student/main";
import {
  PATH_COURSE,
  PATH_PRODUCT,
  PATH_STUDENT_CART,
  PATH_STUDY_TOOL,
  ROUTE,
} from "../../../services/constants/route/router";
import { makeRequest, request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { useStorage } from "../../../hooks/useStorage";
import { StudentThemeType } from "../../../types/admin/student_theme";

const breadcrumbRoutes: BreadCrumbRoute[] = [
  { id: "1", label: " マイページトップ", path: ROUTE.MY_PAGE.DEFAULT },
  {
    id: "2",
    label: "受講コース一覧",
    parentId: "1",
    path: `${ROUTE.MY_PAGE.DEFAULT}/${ROUTE.MY_PAGE.COURSE_LIST}`,
  },
  {
    id: "3",
    label: "学習履歴",
    parentId: "1",
    path: `${ROUTE.MY_PAGE.DEFAULT}/${ROUTE.MY_PAGE.STUDY_HISTORY}`,
  },
  { id: "4", label: "コース", path: PATH_COURSE.DEFAULT },
  {
    id: "5",
    label: "講座",
    parentId: "4",
    path: PATH_COURSE.LECTURE_DETAIL,
  },
  { id: "6", label: "単元", parentId: "5", path: PATH_COURSE.UNIT_DETAIL },
  { id: "8", label: "お客様情報", path: ROUTE.PROFILE.DEFAULT },
  {
    id: "9",
    label: "メールアドレス変更",
    parentId: "8",
    path: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.MAIL_CHANGING}`,
  },
  {
    id: "10",
    label: "パスワード変更",
    parentId: "8",
    path: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.PASSWORD_CHANGING}`,
  },
  // Study tools
  {
    id: "11",
    label: "学習レポート",
    parentId: "1",
    path: PATH_STUDY_TOOL.REPORT,
  },
  {
    id: "12",
    label: "講座名テキストダミー",
    parentId: "1",
    path: PATH_STUDY_TOOL.REVISION_REVIEW,
  },
  {
    id: "13",
    label: "メモ",
    parentId: "1",
    path: PATH_STUDY_TOOL.MEMO,
  },
  {
    id: "14",
    label: "学習スケジュール",
    parentId: "1",
    path: PATH_STUDY_TOOL.SCHEDULE.DEFAULT,
  },
  {
    id: "15",
    label: "講座購入",
    parentId: "1",
    path: PATH_PRODUCT.DEFAULT,
  },
  {
    id: "16",
    label: "商品詳細",
    parentId: "15",
    path: PATH_PRODUCT.DETAIL,
  },
  {
    id: "17",
    label: "カート",
    parentId: "1",
    path: PATH_STUDENT_CART.DEFAULT,
  },
  // purchase history
  {
    id: "18",
    label: "購入履歴・領収書",
    parentId: "8",
    path: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.PURCHASE_HISTORY}`,
  },
];

const Breadcrumb = () => {
  const location = useLocation();
  const { getLocalStorage } = useStorage();
  const studentTheme: StudentThemeType = getLocalStorage("theme");
  const generateBreadcrumbs = (
    routes: BreadCrumbRoute[],
    currentPath: string
  ): React.ReactNode[] => {
    const breadcrumbs: React.ReactNode[] = [];
    const routeMap: Record<string, BreadCrumbRoute> = {};

    for (const route of routes) {
      routeMap[route.id] = route;
    }

    const findParentRoutes = (parentId: string | undefined) => {
      if (parentId) {
        const parentRoute = routeMap[parentId];
        if (parentRoute) {
          breadcrumbs.unshift(
            <Link key={parentRoute.id} to={parentRoute.path || ""}>
              {parentRoute.label}
            </Link>
          );
          findParentRoutes(parentRoute.parentId);
        }
      }
    };

    const currentRoute = routes.find((route) => route.path === currentPath);
    if (currentRoute) {
      breadcrumbs.unshift(
        <Link key={currentRoute.id} to={currentRoute.path || ""}>
          {currentRoute.label}
        </Link>
      );
      findParentRoutes(currentRoute.parentId);
    }

    return breadcrumbs;
  };

  const breadcrumbs = generateBreadcrumbs(breadcrumbRoutes, location.pathname);

  return (
    <div
      style={{
        backgroundColor: studentTheme.main_color_first
      }}
      className="h-[30px] w-full flex justify-center items-center"
    >
      <div className="w-full max-w-[1088px]">
        <div className="text-white text-[12px] leading-[100%] tracking-[0.02em ] font-[500] max-sm:ml-[10px]">
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              {breadcrumb}
              {index < breadcrumbs.length - 1 && BREADCRUMB_SEPARATOR}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
