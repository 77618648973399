import * as React from 'react';

const Pen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.592 3.857c.932-1.01 1.397-1.514 1.892-1.808a3.883 3.883 0 0 1 3.879-.059c.503.28.983.77 1.943 1.751.96.98 1.44 1.471 1.714 1.985a4.116 4.116 0 0 1-.057 3.962c-.288.506-.782.982-1.77 1.933L12.438 22.943c-1.872 1.803-2.808 2.705-3.978 3.162-1.17.457-2.456.423-5.028.356l-.35-.01c-.783-.02-1.175-.03-1.403-.288-.227-.259-.196-.658-.134-1.455l.034-.433c.175-2.246.262-3.368.7-4.377.439-1.01 1.195-1.829 2.708-3.467L16.592 3.857ZM15.25 4 24 12.75"
      stroke={`${props.color || '#8FC2D5'}`}
      strokeWidth={1.875}
      strokeLinejoin="round"
    />
    <path
      d="M16.5 26.5h10"
      stroke={`${props.color || '#8FC2D5'}`}
      strokeWidth={1.875}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Pen;
