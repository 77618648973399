import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Sidebar from "../../../components/student/Profile/Sidebar";
import VerifyingEmailModal from "../../../components/student/Profile/VerifyingEmailModal";
import StudentLayout from "../../../layouts/student";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalError, swalLoading } from "../../../services/helpers/swal";
import { User } from "../../../types/user";
import { useStorage } from "../../../hooks/useStorage";

const MailChanging = () => {
  const [userData, setUserData] = useState<User>({});
  const [submitData, setSubmitData] = useState<User>({});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [tempEmail, setTempEmail] = useState<string>("");
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  useEffect(() => {
    const asyncFn = async () => {
      Swal.showLoading();
      const userResult = await makeRequest({
        method: "get",
        url: API.USER.INFO,
      });

      if (!userResult.data) {
        return swalError();
      }

      setUserData(userResult.data);
      Swal.close();
    };

    asyncFn();
  }, [isOpenModal]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSubmitData({
      email: e.target.value,
    });
  };

  const onSubmitHandle = async (email?: string) => {
    swalLoading();
    let updateMailData = submitData;
    if (email) {
      updateMailData = { email };
    }
    const result = await makeRequest({
      method: "patch",
      url: API.STUDENT.UPDATE_EMAIL,
      data: updateMailData,
    });

    if (!result.data) {
      return swalError(result.message);
    }

    //verify token
    setTempEmail(submitData.email || "");
    Swal.close();
    setIsOpenModal(true);
    setSubmitData({});
  };

  return (
    <>
      <StudentLayout sidebar={<Sidebar />} cover>
        <div className="w-full">
          {/* title */}
          <div className="border-b border-danger-light pb-[14px] w-full mb-[60px] text-[14px] font-[500] leading-[100%]">
            お客様情報
          </div>

          <div className="pl-[32px]">
            {/* describe */}
            <div className="text-[16px] font-[700] leading-[100%]">
              メールアドレス変更
            </div>
            <div className="text-[12px] leading-[190%] mb-[43px]">
              <div className="">
                新しいメールアドレスを入力し「送信する」ボタンをクリックしてください。
              </div>
              <div className="">
                アドレス変更手続きのメールを送信しますので、受信メールに記載されたURLからアドレス変更を完了してください。
              </div>
            </div>

            {/* gmail */}
            <div className="w-full mb-[5px]">
              <div className="flex items-center h-[40px]">
                <div className="w-full max-w-[220px] pl-[14px] py-[12px] bg-warning-lighter font-[500] text-[14px] leading-[100%]">
                  現在のメールアドレス
                </div>
                <div className="w-full max-w-[485px] flex justify-between pl-[13px]">
                  <div className="mb-[-4px]">{userData.email}</div>
                </div>
              </div>
            </div>

            <div className="flex items-center h-0 min-h-[40px] mb-[42px]">
              <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px] h-full">
                <div className="w-full flex justify-between">
                  <div className="">新しいメールアドレス</div>
                  <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                    必須
                  </div>
                </div>
              </div>
              <div className=" ml-[13px] w-[55%] flex items-center">
                <input
                  className="text-[13px] leading-[100%] pl-[10px] h-[34px]"
                  onChange={handleOnChange}
                  value={submitData.email}
                />
              </div>
            </div>

            <div className="w-full flex justify-center items-center mb-[42px]">
              <button
                style={{ backgroundColor: studentTheme.main_color_first }}
                className="bg-primary px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
                onClick={() => onSubmitHandle()}
              >
                送信する
              </button>
            </div>
          </div>
        </div>
      </StudentLayout>
      {isOpenModal && (
        <VerifyingEmailModal
          setIsOpen={setIsOpenModal}
          email={tempEmail}
          setSubmitData={setSubmitData}
          resubmitCode={onSubmitHandle}
          //will remove when context applied
          userData={userData}
          setUserData={setUserData}
        />
      )}
    </>
  );
};

export default MailChanging;
