import StudentPolicyBase from ".";
import Policy from "../../../components/student/Policy/Policy";

const PrivacyPolicyPage = () => {
  return (
    <StudentPolicyBase title="個人情報保護方針">
      <Policy />
    </StudentPolicyBase>
  );
};

export default PrivacyPolicyPage;
