import React, { useState, useContext } from "react";
import { ChildrenWithProps } from "../types/globals";
import { Student } from "../types/admin/student";

interface StudentContextType {
  students: Student[];
  setStudents: React.Dispatch<React.SetStateAction<Student[]>>;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
}

const StudentContext = React.createContext<StudentContextType>({
  students: [],
  setStudents: () => {},
  totalPages: 1,
  setTotalPages: () => {},
});

const StudentProvider = ({ children }: ChildrenWithProps) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  return (
    <StudentContext.Provider
      value={{ students, setStudents, totalPages, setTotalPages }}
    >
      {children}
    </StudentContext.Provider>
  );
};

export default StudentProvider;

export const useStudentContext = () => useContext(StudentContext);
