import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
  MainResource,
  SubResource,
  Unit,
  UnitForm,
} from "../../../../types/admin/unit";
import LessonTypeSelect from "./Form/LessonTypeSelect";
import UnitChapterSelect from "./Form/UnitChapterSelect";
import UnitEstimationTime from "./Form/UnitEstimationRequiredTime";
import styles from "../../../../assests/styles/material.module.css";
import { makeRequest, request } from "../../../../services/axios/axios";
import { CREATION_UNIT_TEST_TYPE } from "../../../../services/constants/admin/pages/material";
import { API } from "../../../../services/constants/route/api";
import { PATH_ADMIN_MATERIAL } from "../../../../services/constants/route/router";
import { ErrorData } from "../../../../types/globals";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { getSubdomainFromUrl } from "../../../../services/helpers/domain";
import { convertTimestampToDateTime } from "../../../../services/helpers/formatTime";
import {
  swalError,
  swalLoading,
  swalSuccess,
  swallConfirm,
} from "../../../../services/helpers/swal";
import AWS, { S3 } from "aws-sdk";
import ErrorBox from "../../../commons/form/ErrorBox";
import { TbTrash } from "react-icons/tb";
import { DELETE_CONFIRM_TITLE } from "../../../../services/constants/message";
import { PREFIX_PATH_S3, SERVICE_ID } from "../../../../services/constants/globals";
import { useAuthContext } from "../../../../context/AuthContext";
import { getUploadFilePath } from "../../../../services/helpers/uploadFile";

const bytesToMegabytes = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2);
};
const UnitLessonCreate = ({
  createType,
  unitData,
}: {
  createType: number;
  unitData: UnitForm;
}) => {
  const [version, setVersion] = useState<string>("");
  const [unitUpdatedAt, setUnitUpdatedAt] = useState<string | Date>("");
  const [mainResource, setMainResource] = useState<MainResource | null>(null);
  const [subResource, setSubResource] = useState<SubResource | null>(null);
  const [mainFile, setMainFile] = useState<File | null>(null);
  const [subFile, setSubFile] = useState<File | null>(null);
  const [mainFileFolderId, setMainFileFolderId] = useState<string>("");
  const [subFileFolderId, setSubFileFolderId] = useState<string>("");
  const [mainFileFolderCode, setMainFileFolderCode] = useState<number | null>(
    null
  );
  const [subFileFolderCode, setSubFileFolderCode] = useState<number | null>(
    null
  );
  const [unitFormData, setUnitFormData] = useState<UnitForm>({
    type: 1,
    status: 0,
    main_resource_type: 1,
    main_resource_downloadable: 0,
  });
  const [errors, setErrors] = useState<ErrorData>({});
  const { getParamValue, navigate } = useObjectRoutes();
  const unitId = getParamValue("unit_id");
  const withChapterId = getParamValue("with_chapter_id");
  const [processPercent, setProcessPercent] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { user } = useAuthContext()
  const oz_id = user.organization_id;

  useEffect(() => {
    if (!unitId) return;
    if (createType !== 1) return;
    if (unitData.version) setVersion(unitData.version);
    if (unitData.updated_at) setUnitUpdatedAt(unitData.updated_at);
    if (unitData.main_resource) setMainResource(unitData.main_resource);
    if (unitData.sub_resource) setSubResource(unitData.sub_resource);
    setUnitFormData({
      unit_id: unitData.id,
      chapter_id: unitData.chapter ? unitData.chapter.id : undefined,
      type: unitData.type,
      name: unitData.name,
      description: unitData.description,
      estimate_time_required: unitData.estimate_time_required,
      completion_conditions_in_percent:
        unitData.completion_conditions_in_percent,
      main_resource_type: unitData.main_resource_type,
      status: unitData.status,
      main_resource_content: unitData.main_resource_content,
      main_resource_downloadable: unitData.main_resource_downloadable,
      sub_resource_type: unitData.sub_resource_type,
      sub_resource_content: unitData.sub_resource_content,
      sub_resource_downloadable: unitData.sub_resource_downloadable,
    });
  }, [unitData]);

  useEffect(() => {
    setUnitFormData((prev) => ({
      ...prev,
      chapter_id: Number(withChapterId) || 0,
    }));
  }, [withChapterId]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "main_resource_type" || name === "sub_resource_type") {
      setUnitFormData({ ...unitFormData, [name]: Number(value) });
      return;
    }
    setUnitFormData({ ...unitFormData, [name]: value });
  };

  const handleFileUpload = async (
    file: File,
    folderCode: number,
    folderId: string
  ) => {
    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    const fileName = `${Date.now()}.${fileExtension}`;
    const folderPath = getUploadFilePath(oz_id, folderCode);
    let contentType = "";
    switch (fileExtension) {
      case "pdf":
        contentType = "application/pdf";
        break;
      case "mp3":
        contentType = "audio/mpeg";
        break;
      case "mp4":
        contentType = "video/mp4";
        break;
      case "jpg":
      case "jpeg":
        contentType = "image/jpeg";
        break;
      case "png":
        contentType = "image/png";
        break;
      default:
        contentType = "application/octet-stream";
        break;
    }
      
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

    AWS.config.update({
      accessKeyId,
      secretAccessKey,
    });

    const upload = new S3.ManagedUpload({
      params: {
        Bucket: process.env.REACT_APP_AWS_BUCKET || "",
        Key: folderPath + fileName,
        Body: file,
        ContentType: contentType,
        ACL: "public-read",
      },
    });

    upload.on("httpUploadProgress", (progress) => {
      const percentUploaded = (progress.loaded / progress.total) * 100;
      setProcessPercent(percentUploaded);
    });

    try {
      await upload.promise();
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    let time = null;
    if (fileExtension === "mp3" || fileExtension === "mp4") {
      const mediaElement = document.createElement("audio");
      mediaElement.src = URL.createObjectURL(file);

      await new Promise<void>((resolve, reject) => {
        mediaElement.addEventListener("loadedmetadata", () => {
          time = Math.round(mediaElement.duration * 1000);
          resolve();
        });
        mediaElement.addEventListener("error", () => {
          reject();
        });
      });

      URL.revokeObjectURL(mediaElement.src);
    }

    const data = {
      resource_folder_id: folderId,
      filename: fileName,
      origin_filename: file.name,
      content_type: fileExtension,
      size: bytesToMegabytes(file.size),
      path: `${folderPath}${fileName}`,
    };

    if (time) {
      (data as any).time = time;
    }
    const result = await makeRequest({
      method: "post",
      url: API.ADMIN_FOLDER.UPLOAD_FILE,
      data,
    });

    if (!result.status) {
      setErrors(result.error ? result.error : {});
      await s3
        .deleteObject({
          Bucket: process.env.REACT_APP_AWS_BUCKET || "",
          Key: folderPath + fileName,
        })
        .promise();
      return null;
    }
    setErrors({});
    return result.data.id;
  };

  const handleSubmit = (
    e: FormEvent<HTMLButtonElement>,
    submitType?: number
  ) => {
    e.preventDefault();
    swallConfirm(
      async () => {
        setIsDisabled(true);

        let mainResourceId = mainResource?.id || null;
        let subResourceId = subResource?.id || null;

        if (mainFile && mainFileFolderCode && mainFileFolderId) {
          mainResourceId = await handleFileUpload(
            mainFile,
            mainFileFolderCode,
            mainFileFolderId
          );
          if (mainResourceId === null) return;
        }

        if (subFile && subFileFolderCode && subFileFolderId) {
          subResourceId = await handleFileUpload(
            subFile,
            subFileFolderCode,
            subFileFolderId
          );
          if (subResourceId === null) return;
        }

        // swalLoading();

        let newFormData = {
          ...unitFormData,
          status: submitType || CREATION_UNIT_TEST_TYPE.DRAFT,
          main_resource_id: mainResourceId,
          sub_resource_id: subResourceId,
          chapter_id: unitFormData.chapter_id || Number(withChapterId),
        };

        const endpoint = unitId
          ? API.ADMIN_UNIT.UPDATE_UNIT_LESSON
          : API.ADMIN_UNIT.CREATE_UNIT_LESSON;

        if (unitId) {
          return await request.patch(
            endpoint,
            newFormData,
            (updatePublishUnitLesson) => navigate(`${PATH_ADMIN_MATERIAL.UNIT}?course_id=${updatePublishUnitLesson.course_id}`),
            async (error) => {
              setErrors((prev) => ({ ...prev, ...error }));
              if (!mainFile || !subFile) return;
              const payload = [];
              if (mainResourceId) {
                payload.push(mainResourceId);
              }

              if (subResourceId) {
                payload.push(subResourceId);
              }
              await request.delete(
                API.ADMIN_FOLDER.DELETE_FILE,
                { resource_ids: payload },
                (success) => {},
                (error) => setErrors((prev) => ({ ...prev, ...error })),
                { withSuccess: false, withLoading: false }
              );
            },
            { withSuccess: true, withLoading: false }
          );
        }

        await request.post(
          endpoint,
          newFormData,
          (createdPublishUnitLesson) => navigate(`${PATH_ADMIN_MATERIAL.UNIT}?course_id=${createdPublishUnitLesson.course_id}`),
          async (error) => {
            setErrors((prev) => ({ ...prev, ...error }));
            if (!mainFile || !subFile) return;
            const payload = [];
            if (mainResourceId) {
              payload.push(mainResourceId);
            }

            if (subResourceId) {
              payload.push(subResourceId);
            }
            await request.delete(
              API.ADMIN_FOLDER.DELETE_FILE,
              { resource_ids: payload },
              (success) => {},
              (error) => setErrors((prev) => ({ ...prev, ...error })),
              { withSuccess: false, withLoading: false }
            );
          },
          { withSuccess: true, withLoading: false }
        );
        
        setIsDisabled(false);
      },
      "この教材を公開しても<br/>よろしいですか？",
      "公開後の教材は受講者が閲覧可能です",
      "公開する",
      submitType !== CREATION_UNIT_TEST_TYPE.PUBLISH
    );
  };

  const handleDeleteUnit = () => {
    if (!unitId) return;
    swallConfirm(async () => {
      await request.delete(
        API.ADMIN_UNIT.DELETE,
        { unit_id: unitId },
        (success) => navigate(PATH_ADMIN_MATERIAL.DEFAULT),
        setErrors,
        { withSuccess: true, withLoading: true }
      );
    }, DELETE_CONFIRM_TITLE.UNIT);
  };

  return (
    <form className={`text-secondary-dark ${styles.form_styles}`}>
      {!!Object.keys(errors).length && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}
      <div className="w-full flex items-center mb-[5px]">
        <div className="rounded-[5px] font-[500px] flex items-center justify-center border-primary border text-[14px] leading-[100%] text-primary w-[140px] h-[30px] mr-[15px]">
          基本情報登録
        </div>
        <div className="mr-[25px] text-[12px] font-[400]">
          教材の登録カテゴリ（章）など基本情報を設定します
        </div>
      </div>
      <div className="px-[30px] py-[15px] border-b border-secondary-ligh mb-[30px]">
        <div className="mb-[20px]">
          <div className="w-full flex items-center mb-[10px]">
            <div className="mr-[28.5px] text-[13px] font-[500]">単元名</div>
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mb-[3px]">
              必須
            </div>
          </div>
          <div className="w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] placeholder-secondary-light"
              name="name"
              value={unitFormData.name}
              onChange={handleChange}
              placeholder="単元名を入力してください。"
            />
          </div>
        </div>
        <div className="mb-[20px]">
          <div className="w-full flex items-center mb-[10px]">
            <div className="mr-[28.5px] text-[13px] font-[500]">
              登録カテゴリ（コース\講義\章）設定
            </div>
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mb-[3px">
              必須
            </div>
          </div>
          <div className="w-full flex items-center">
            <UnitChapterSelect
              value={unitFormData.chapter_id || Number(withChapterId || 0)}
              onChange={handleChange}
              unitId={unitId}
            />
          </div>
        </div>
        <div className="mb-[20px]">
          <div className="w-full flex items-center mb-[10px]">
            <div className="mr-[28.5px] text-[13px] font-[500]">概要説明</div>
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mb-[3px]">
              必須
            </div>
          </div>
          <div className="w-full flex items-center">
            <textarea
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[70px] w-full pt-[10px] my-[5px] border-[#EFF1F0] placeholder-secondary-light"
              name="description"
              placeholder="この単元の簡単な説明を入力してください"
              value={unitFormData.description}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="mb-[20px]">
          <div className="w-full flex items-center mb-[10px]">
            <div className="mr-[28.5px] text-[13px] font-[500]">
              所要時間の目安
            </div>
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mb-[3px]">
              必須
            </div>
          </div>
          <div className="w-full flex items-center">
            <UnitEstimationTime
              formData={unitFormData}
              setFormData={setUnitFormData}
            />
          </div>
        </div>
        <div className="mb-[20px]">
          <div className="w-full flex items-center mb-[5px]">
            <div className="mr-[28.5px] text-[13px] font-[500]">進捗制御</div>
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mb-[3px]">
              必須
            </div>
          </div>
          <div className="text-[12px] font-[400] mb-[5px]">修了条件を設定</div>
          <div className="w-full flex items-center">
            <input
              type="number"
              className="text-[13px] leading-[100%] flex items-center text-right pr-[9px] h-[30px]  border-[#EFF1F0] mr-[10px] max-w-[90px]"
              name="completion_conditions_in_percent"
              value={unitFormData.completion_conditions_in_percent}
              onChange={handleChange}
              placeholder="0"
            />
            <span className="mr-[10px] font-[400] text-[12px]">
              %以上の教材視聴で単元を修了とする
            </span>
          </div>
        </div>

        {version && unitUpdatedAt && (
          <div className="mb-[20px]">
            <div className="w-full flex items-center mb-[5px]">
              <div className="mr-[28.5px] text-[13px] font-[500]">更新情報</div>
            </div>
            <div className="text-secondary-dark w-full text-[12px] font-[400] max-w-[920px] h-[50px] bg-[#F9F9F9] items-center pl-[21px] flex ">
              <p className="mr-[60px]">
                最終更新日：
                {convertTimestampToDateTime(unitUpdatedAt, false, true)}
              </p>
              <p>ファイルバージョン： ver{version}</p>
            </div>
          </div>
        )}
      </div>
      <LessonTypeSelect
        mainFile={mainFile}
        subFile={subFile}
        setMainFile={setMainFile}
        setSubFile={setSubFile}
        unitFormData={unitFormData}
        handleChange={handleChange}
        mainFileFolderId={mainFileFolderId}
        subFileFolderId={subFileFolderId}
        setMainFileFolderId={setMainFileFolderId}
        setSubFileFolderId={setSubFileFolderId}
        setMainFileFolderCode={setMainFileFolderCode}
        setSubFileFolderCode={setSubFileFolderCode}
        mainResource={mainResource}
        subResource={subResource}
        version={version}
        unitUpdatedAt={unitUpdatedAt}
        setMainResource={setMainResource}
        setSubResource={setSubResource}
        setUnitFormData={setUnitFormData}
        processPercent={processPercent}
      />
      <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[19px]">
        {unitId && (
          <button
            type="button"
            className="border border-secondary px-[50px] py-[10px] text-secondary rounded-[10px] font-[700] text-[18px] leading-[100%] max-h-[38px] flex items-center mr-[50px]"
            onClick={handleDeleteUnit}
          >
            <TbTrash className="ml-[4px] " size={30} />
            <div className="">この単元を削除</div>
          </button>
        )}
        <button
          type="button"
          className={`min-w-[180px] ${isDisabled ? "opacity-80" : ""} bg-secondary-light px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]`}
          onClick={() => navigate(PATH_ADMIN_MATERIAL.DEFAULT)}
          disabled={isDisabled}
        >
          キャンセル
        </button>
        <button
          type="button"
          className={`min-w-[228px] ${isDisabled ? "opacity-80" : ""} bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]`}
          onClick={(e) => handleSubmit(e)}
          disabled={isDisabled}
        >
          下書きとして保存
        </button>
        <button
          className={`min-w-[132px] ${isDisabled ? "opacity-80" : ""} bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%] max-h-[43px] flex items-center`}
          onClick={(e) => handleSubmit(e, CREATION_UNIT_TEST_TYPE.PUBLISH)}
          disabled={isDisabled}
        >
          <div className="">公開</div>
        </button>
      </div>
    </form>
  );
};

export default UnitLessonCreate;
