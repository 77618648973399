import React from "react";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

type CircularProgressProps = {
  sqSize?: number;
  percentage?: number;
  strokeWidth?: number;
  blue?: boolean;
  green?: boolean;
};

const CircularProgress = ({
  sqSize = 100,
  percentage = 25,
  strokeWidth = 5,
  blue = false,
  green = false,
  ...props
}: CircularProgressProps & React.SVGProps<SVGSVGElement>) => {
  // Size of the enclosing square
  const currentSize = sqSize;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${currentSize} ${currentSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;
  const { studentTheme } = useStudentThemeContext();
  const strokeColor = blue
    ? studentTheme.sub_color_first
    : green
    ? studentTheme.lecture_top_color
    : studentTheme.main_color_second;
    
  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox} {...props}>
      <circle
        className="fill-none stroke-success-lighter"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className={`fill-none ${
          blue
            ? "stroke-primary-light"
            : green
            ? "stroke-success"
            : "stroke-warning"
        } circle_progress`}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          stroke: strokeColor,
        }}
      />
    </svg>
  );
};

export default CircularProgress;
