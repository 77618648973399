/* eslint-disable react-hooks/exhaustive-deps */
import { Chart, ChartData } from "chart.js";
import BarChart, { BarChartProps } from "../../../commons/charts/BarChart";
import { studyReportUtils } from "../../../../services/utils/student/studyTool/studyReport";
import { useStorage } from "../../../../hooks/useStorage";
import { useState, useEffect } from "react";
import { CourseOption, ReportFilter } from "./ReportBoard";
import { isUsableArr } from "../../../../services/helpers/etc";
import ChartStudyRatioOption from "./ChartStudyRatioOption";
import { MdAdd } from "react-icons/md";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { truncate } from "../../../../services/helpers/parseData";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

export type ReportRatioFilterData = {
  course_id?: number;
  lecture_id?: number;
  label?: string;
  id: number;
};

export type ReportRatioFilter = {
  data?: ReportRatioFilterData[];
} & ReportFilter;

export type ChartStudyRatioProps = {
  filter?: ReportFilter;
  data?: BarChartProps["data"];
  courseOptions?: CourseOption[];
} & Omit<BarChartProps, "data">;

type CourseRatiosResponse = {
  time_learned: number | string;
  course_id: number;
  course_name: string;
  lecture_id: number;
  lecture_name: string;
};

const ChartStudyRatio = ({
  data,
  courseOptions,
  filter,
  ...props
}: ChartStudyRatioProps) => {
  const {
    chartRatioOptions,
    initChartData,
    genFilterOption,
    genDataset,
    genChartData,
  } = studyReportUtils;
  const { studentTheme } = useStudentThemeContext();
  const chartOptions = chartRatioOptions || {};
  const initialFilterOption: ReportRatioFilter = {
    ...filter,
    data: [genFilterOption()],
  };

  const filterStorageKey = "filterStudyRatio";
  const { getLocalStorage, setLocalStorage } = useStorage();
  const initCurrentFilter = !!String(getLocalStorage(filterStorageKey)).length
    ? getLocalStorage(filterStorageKey)
    : initialFilterOption;

  //states
  const [chartData, setChartData] = useState<ChartData<"bar">>(initChartData);
  const [currentFilter, setCurrentFilter] =
    useState<ReportRatioFilter>(initCurrentFilter);
  const [isAddAction, setIsAddAction] = useState<boolean>(false);

  const onAddOptionHandle = () => {
    let currentOption = currentFilter?.data || [];
    currentOption.push(genFilterOption());

    const finalData = { ...currentFilter, data: currentOption };
    setCurrentFilter(finalData);
    setIsAddAction(true);
  };

  useEffect(() => {
    setCurrentFilter({ ...filter, data: currentFilter?.data || [] });
  }, [filter]);

  useEffect(() => {
    setIsAddAction(false);

    if (!currentFilter || !currentFilter.data?.length || isAddAction) return;
    setLocalStorage(filterStorageKey, currentFilter);
    let submitFilter: ReportRatioFilter = { ...currentFilter, data: [] };

    for (const option of currentFilter.data) {
      if (option["course_id"] && option["lecture_id"]) {
        submitFilter.data?.push(option);
      }
    }

    const fetch = async () => {
      await request.post(
        `${API.REPORT.GET_STUDY_RATIO}`,
        submitFilter,
        (courseRatioRes: CourseRatiosResponse[]) => {
          let datasets: ChartData<"bar">["datasets"] = [];
          if (isUsableArr(courseRatioRes)) {
            courseRatioRes.forEach((courseRatio, index) => {
              datasets.push(
                genDataset(
                  `${truncate(courseRatio.course_name, 50)} / ${truncate(
                    courseRatio.lecture_name,
                    50
                  )}`,
                  [Number(courseRatio.time_learned)],
                  courseRatioRes.length,
                  index
                )
              );
            });
          }
          setChartData(genChartData(datasets));
        }
      );
    };

    fetch();
  }, [currentFilter]);

  const chosenLectureIds =
    currentFilter.data?.map((course) => Number(course.lecture_id)) || [];

  return (
    <section className="mt-[20px]">
      {isUsableArr(currentFilter?.data) &&
        currentFilter?.data?.map((optionGroup, index) => (
          <ChartStudyRatioOption
            key={optionGroup.id}
            isLast={index === (currentFilter.data?.length || 0) - 1}
            isFirst={index === 0}
            optionGroup={optionGroup}
            courseOptions={courseOptions}
            chosenLectureIds={chosenLectureIds}
            setCurrentFilter={setCurrentFilter}
          />
        ))}
      <div
      style={{backgroundColor: studentTheme.main_color_first}}
        className="cursor-pointer rounded-[5px] bg-primary text-white text-[10px] tracking-[0.2px] w-full max-w-[124px] h-0 min-h-[24px] flex items-center font-[400] mt-[12px] ml-[7px]"
        onClick={onAddOptionHandle}
      >
        <MdAdd size={20} className="pl-[5px]" />
        <div className="mt-[-1px]">科目/講義を追加</div>
      </div>
      <div className="">
        <div className="h-[100px]">
          <BarChart data={chartData} options={chartOptions} {...props} />
        </div>
        <div className="flex flex-wrap">
          {chartData &&
            isUsableArr(chartData.datasets) &&
            chartData.datasets.map((dataset, index) => (
              <div
                key={index}
                className="flex gap-[7.18px] mb-[18px] mr-[18px]"
              >
                <div
                  className="w-[18px] h-[18px] rounded-full"
                  style={{ backgroundColor: dataset.backgroundColor as string }}
                  title={dataset.backgroundColor as string}
                ></div>
                <div className="text-[12.564px] font-[400] text-secondary tracking-[0.251px]">
                  {dataset.label}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default ChartStudyRatio;
