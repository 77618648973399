import AWS, { S3 } from "aws-sdk";

const uploadFileToS3 = async (file: File, pathFile: string) => {
    const s3 = new S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
    AWS.config.update({
        accessKeyId,
        secretAccessKey,
    });

    // Upload file on S3.
    let contentType = file.type;
    const upload = new S3.ManagedUpload({
        params: {
            Bucket: process.env.REACT_APP_AWS_BUCKET || "",
            Key: pathFile,
            Body: file,
            ContentType: contentType,
            ACL: "public-read",
        },
    });

    try {
        await upload.promise();
    } catch (error) {
        console.error("Error uploading file:", error);
        return;
    }
};

const deleteFile = (pathFile: string) => {
    if (!pathFile) return;

    const s3 = new S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    var params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET || "",
        Key: pathFile,
    };

    try {
        s3.deleteObject(params, function (err, data) {
            if (err) console.log(err, err.stack); // error
            else console.log(); // deleted
        });
    } catch (error) {
        console.error("Error deleting file:", error);
        return;
    }
}

export { uploadFileToS3, deleteFile }