/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReviewChangeButton from "./ReviewChangeButton";
import {
  TEST_LATEST_REVIEW_TABLE_HEAD,
  TEST_REVIEW,
} from "../../../../services/constants/student/unit";
import AnswerType from "./Type";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { useStudentUnitContext } from "../../../../context/StudentUnitContext";
import {
  RowTestReviewTable,
  TestHistory,
} from "../../../../types/student/unit";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import qs from "qs";
import { isEmptyArr, isEmptyObj } from "../../../../services/helpers/etc";
import { MESSAGE_COMMON } from "../../../../services/constants/message";
import {
  formatReviewTestTime,
  formatTotalTestTime,
} from "../../../../services/helpers/formatTime";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { PATH_COURSE } from "../../../../services/constants/route/router";
import Unknown from "../../../commons/icons/Learning/Unknown";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type TestLatestReviewTableProps = {
  unitId?: number;
  historyId?: number;
};

export type FetchTestReviewParams = {
  student_test_history_id: number;
  amount_compare_test: number;
};

export type ExplanationParams = {
  student_test_history_id: number;
};

type SubmitRate = {
  detail_test_history_id: number;
  rate: number;
};

const TestLatestReviewTable = ({
  unitId,
  historyId,
}: TestLatestReviewTableProps) => {
  const { navigate } = useObjectRoutes();
  const { showSettings, currentHistoryId, setCurrentHistory, setShowSettings } =
    useStudentUnitContext();
  const [testReviews, setTestReviews] = useState<RowTestReviewTable[]>([]);
  const [testStatistic, setTestStatistic] = useState<TestHistory>({});
  const [triggerRefetch, setTriggerRefetch] = useState<number>(0);
  const limitTest = 2; //for current test and nearest of it.
  const { studentTheme } = useStudentThemeContext();
  const explanationParamObject: ExplanationParams = {
    student_test_history_id: Number(historyId),
  };
  const explanationSearchParam = qs.stringify(explanationParamObject);

  useEffect(() => {
    if (!unitId) return;
    if (!historyId) {
      setShowSettings({
        ...showSettings,
        isReviewTestTable: !showSettings.isReviewTestTable,
      });
    }

    const fetchTestReviews = () => {
      const paramObj: FetchTestReviewParams = {
        amount_compare_test: 1,
        student_test_history_id: Number(currentHistoryId || historyId),
      };
      const queryParam = qs.stringify(paramObj);

      request.get(`${API.COURSE.GET_STATISTIC_TEST}?${queryParam}`, (res) => {
        setTestReviews(res?.detail_round_data || []);
        setTestStatistic(res?.test_round_data || {});
        setCurrentHistory(res?.test_round_data || {});
      });
    };

    if (isEmptyArr(testReviews) || !!historyId) fetchTestReviews();
  }, [unitId, currentHistoryId, triggerRefetch]);

  const useableQuestion = (questions: RowTestReviewTable["questions"]) => {
    if (
      !Array.isArray(questions) ||
      typeof questions !== "object" ||
      isEmptyArr(questions) ||
      questions.length > limitTest ||
      questions.length < 0
    ) {
      return false;
    }

    return true;
  };

  const handleRating = async (review: RowTestReviewTable, rateType: number) => {
    if (
      rateType === undefined ||
      isEmptyObj(review) ||
      !review.detail_test_history_id
    )
      return;

    const { detail_test_history_id, rate } = review;

    const submitData: SubmitRate = {
      detail_test_history_id: detail_test_history_id || 0,
      rate: rate === rateType ? TEST_REVIEW.NONE : rateType,
    };

    await request.patch(
      API.COURSE.UPDATE_RATE_DETAIL_TEST_HISTORY,
      submitData,
      () => setTriggerRefetch(new Date().getTime())
    );
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-[10px] mt-[35px]">
        <div className="font-[500] text-[14px] leading-[100%]">
          テスト結果詳細
        </div>
        <ReviewChangeButton
          onClick={() =>
            setShowSettings({ ...showSettings, isLatestHistory: false })
          }
        >
          この単元のテスト結果一覧
        </ReviewChangeButton>
      </div>

      {!isEmptyArr(testReviews) ? (
        <div className="mb-[20px]">
          <div className="flex items-start gap-[20px] max-h-[280px] overflow-y-auto">
            {Array(2)
              .fill(null)
              .map((_, tableIndex) => (
                <table
                  key={`table_${tableIndex}`}
                  className="w-full text-sm text-left text-gray-500 table-auto overflow-scroll"
                >
                  <thead
                    style={{ color: studentTheme.text_color }}
                    className="text-base bg-[#EFF1F0] text-center"
                  >
                    <tr className="min-h-[30px] text-[13px] font-[500]">
                      {TEST_LATEST_REVIEW_TABLE_HEAD.map((label) => (
                        <th
                          className="px-0 border-x-4 border-white whitespace-nowrap"
                          key={`label_${label}`}
                        >
                          {label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {testReviews &&
                      !!testReviews.length &&
                      testReviews.map((review, index) => (
                        <React.Fragment key={`row_${index}`}>
                          {((tableIndex === 0 && (index + 1) % 2 !== 0) ||
                            (tableIndex === 1 && (index + 1) % 2 === 0)) && (
                            <tr
                              style={{ color: studentTheme.text_color }}
                              className="min-h-[30px] text-[13px] font-[500] min-h-[50px]"
                              key={`left_${index}`}
                            >
                              <td className="bg-warning-lighter text-center border-x-4 border-white">
                                {index + 1}
                              </td>
                              <td>
                                <AnswerType
                                  type={
                                    useableQuestion(review?.questions)
                                      ? review.questions[0]?.is_correct
                                      : null
                                  }
                                />
                              </td>
                              <td className="text-center">
                                {review?.score || "0"}
                              </td>
                              <td>
                                <div className="text-center">
                                  <AnswerType
                                    type={
                                      useableQuestion(review?.questions)
                                        ? review.questions[1]?.is_correct
                                        : null
                                    }
                                  />
                                  <div className="text-[8px] leading-[100%] mt-[1px]">
                                    {useableQuestion(review?.questions) &&
                                      review.questions[1]?.is_correct !==
                                        null &&
                                      review.questions[1]?.is_correct !==
                                        undefined &&
                                      formatTotalTestTime(
                                        review.questions[1]?.time
                                      )}
                                  </div>
                                </div>
                              </td>
                              <td className="">
                                {useableQuestion(review?.questions) &&
                                !review?.review ? (
                                  <div className="flex justify-center items-center">
                                    <Unknown />
                                  </div>
                                ) : (
                                  <div className="flex justify-center items-center">
                                    要
                                  </div>
                                )}
                              </td>
                              <td
                                style={{
                                  color: studentTheme.main_color_first,
                                }}
                                className="cursor-pointer text-primary"
                                onClick={() =>
                                  navigate(
                                    `${
                                      PATH_COURSE.EXPLANATION
                                    }?${explanationSearchParam}#question_${
                                      index + 1
                                    }`
                                  )
                                }
                              >
                                解説
                              </td>
                              <td className="flex justify-center gap-[5.5px] items-center min-h-[50px]">
                                <div className="flex flex-col gap-[2px]">
                                  <div
                                    className={`bg-success-extralight rounded-[2px] text-white w-[18px] h-[18px] flex items-center justify-center p-[2px] cursor-pointer ${
                                      Number(review?.rate || 0) ===
                                      TEST_REVIEW.RATE_LIKE
                                        ? "!bg-primary-light"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleRating(
                                        review,
                                        TEST_REVIEW.RATE_LIKE
                                      )
                                    }
                                  >
                                    <LuThumbsUp size={20} />
                                  </div>
                                  <div
                                    className={`flex items-center text-[8px] with_roboto font-[500] text-secondary-light h-[8px] ${
                                      Number(review?.rate || 0) ===
                                      TEST_REVIEW.RATE_LIKE
                                        ? "!text-primary-light"
                                        : ""
                                    }`}
                                  >
                                    good
                                  </div>
                                </div>
                                <div className="flex flex-col gap-[2px]">
                                  <div
                                    className={`bg-success-extralight rounded-[2px] text-white w-[18px] h-[18px] flex items-center justify-center p-[2px] cursor-pointer ${
                                      Number(review?.rate || 0) ===
                                      TEST_REVIEW.RATE_DISLIKE
                                        ? "!bg-danger"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleRating(
                                        review,
                                        TEST_REVIEW.RATE_DISLIKE
                                      )
                                    }
                                  >
                                    <LuThumbsDown size={20} />
                                  </div>
                                  <div
                                    className={`flex items-center text-[8px] with_roboto font-[500] text-secondary-light h-[8px] ${
                                      Number(review?.rate || 0) ===
                                      TEST_REVIEW.RATE_DISLIKE
                                        ? "!text-danger"
                                        : ""
                                    }`}
                                  >
                                    bad
                                  </div>
                                </div>
                              </td>
                              <td className="text-center  text-[12px] leading-[100%]">
                                {formatReviewTestTime(review?.time)}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              ))}
          </div>

          <div className="flex justify-between items-center bg-warning-lighter h-[50px] pr-[13px] border-x-4 border-white">
            <div className="pl-[5px] text-[12px] leading-[100%]">計</div>
            <div className="font-[500] text-[20px] leading-[100%] ">
              {testStatistic.score || "-"}
              <span className="font-[700] text-[15px] leading-[100%] text-secondary-light">
                点/{testStatistic.total_score || "-"}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-[20px] bg-warning-lighter p-[5px] !pl-[10px]">
          {MESSAGE_COMMON.NO_DATA_FOUND}
        </div>
      )}
    </div>
  );
};

export default TestLatestReviewTable;
