import React from "react";

const HelpCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22.5244C17.5228 22.5244 22 18.0473 22 12.5244C22 7.00157 17.5228 2.52441 12 2.52441C6.47715 2.52441 2 7.00157 2 12.5244C2 18.0473 6.47715 22.5244 12 22.5244Z"
        stroke={`${props.color || "#227C9D"}`}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.08984 9.5245C9.32495 8.85616 9.789 8.2926 10.3998 7.93363C11.0106 7.57465 11.7287 7.44343 12.427 7.56321C13.1253 7.68298 13.7587 8.04602 14.2149 8.58802C14.6712 9.13003 14.9209 9.81602 14.9198 10.5245C14.9198 12.5245 11.9198 13.5245 11.9198 13.5245"
        stroke={`${props.color || "#227C9D"}`}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17.5244H12.01"
        stroke={`${props.color || "#227C9D"}`}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HelpCircleIcon;
