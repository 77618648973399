import React from "react";
import { CHAPTER_STATUS } from "../../../../../services/constants/student/chapter";
import { BsCheck2 } from "react-icons/bs";
import { MdOutlineSchool } from "react-icons/md";

const ChapterStatus = ({ status = 4, isExpanded }: { status?: number; isExpanded?: boolean }) => {
  switch (status) {
    case CHAPTER_STATUS.CHAPTER_STATUS_COMPLETED:
      return (
        <div
          className={`w-[24px] h-[24px] rounded-full text-danger-light border-[2px] border-danger-light flex items-center justify-center ${isExpanded ? "bg-[#FE6D73] border-none text-white" : ""}`}
        >
          <BsCheck2 size={20} />
        </div>
      );
    case CHAPTER_STATUS.CHAPTER_STATUS_UNLEARNED:
      return (
        <div
          className={`w-[24px] h-[24px] rounded-full flex items-center justify-center text-white pr-[0.5px] ${isExpanded ? "bg-[#FE6D73]" : "bg-[#EBE4D8]"}`}
        >
          <MdOutlineSchool size={20} />
        </div>
      );
    case CHAPTER_STATUS.CHAPTER_STATUS_LEARNING:
      return (
        <div
          className={`w-[24px] h-[24px] rounded-full flex items-center justify-center text-white pr-[0.5px] ${isExpanded ? "bg-[#FE6D73]" : "bg-[#EBE4D8]"}`}
        >
          <MdOutlineSchool size={20} />
        </div>
      );
    default:
      return <div></div>;
  }
};

export default ChapterStatus;
