import React, { ChangeEvent, useEffect } from "react";
import { useState } from "react";
import StudentTable from "./StudentTable";
import StudentFilter from "./StudentFilter";
import { useStudentContext } from "../../../context/StudentContext";
import { USER_STATUS } from "../../../services/constants/user";
import StudentTableCommonAction from "./StudentTableCommonAction";
import { makeRequest } from "../../../services/axios/axios";
import {
  swalClose,
  swalError,
  swalLoading,
  swalMessage,
  swalSuccess,
  swallConfirm,
} from "../../../services/helpers/swal";
import { paramizeObject } from "../../../services/helpers/parseData";
import { API } from "../../../services/constants/route/api";
import { Student } from "../../../types/admin/student";

type FilterType = {
  query: string;
  limit: number;
  page: number;
  status?: string;
  sortType?: number;
  section_id?: number | string;
};

const StudentList = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    query: "",
    page: 1,
    sortType: 1,
    status: USER_STATUS.ACTIVE.toString()
  });
  const [currentPage, setCurrentPage] = useState(1);
  const { students, setStudents } = useStudentContext();
  const [_students, _setStudents] = useState<Student[]>(students || []);
  const { setTotalPages } = useStudentContext();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMainSection, setSelectedMainSection] = useState<string>("");

  const resetTable = () => {
    setSelectedIds([]);
    setSelectAll(false);
    setCurrentPage(1);
  };

  const checkSelectAll = (currentStudents: Student[]) => {
    let currentPageStudentIds = currentStudents?.map((student) => student.id);
    let allCurrentPageStudentsSelected = currentPageStudentIds.every((id) =>
      selectedIds.includes(id)
    );
    if (allCurrentPageStudentsSelected) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedIds(students?.map((s) => s.id));
    } else {
      setSelectedIds([]);
    }
  };

  const deleteSelected = async () => {
    if (selectedIds.length === 0) {
      return;
    }
    swallConfirm(async () => {
      // swalLoading();
      const result = await makeRequest({
        method: "delete",
        url: API.ADMIN_STUDENT.DELETE_STUDENT,
        data: { student_ids: selectedIds },
      });
      if (!result.status) {
        return swalError();
      }
      _setStudents(_students?.filter((s) => !selectedIds.includes(s.id)));
      resetTable();
      swalSuccess();
      changePaginate(1);
    }, "チェックした受講者を削除して<br>よろしいですか？ ");
  };

  const toggleSelect = (id: number) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds(selectedIds?.filter((sId) => sId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const isStudentSelected = (id: number) => selectedIds?.includes(id);

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };
  useEffect(() => {
    _setStudents(students);
  }, [students]);

  useEffect(() => {
    const fetch = async () => {
      // swalLoading();

      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_STUDENT.GET_STUDENT_LIST}?${paramizeObject(filter)}`,
      });

      if (!result.data) return swalError();
      checkSelectAll(result.data.students);
      setStudents(result.data.students);
      setTotalPages(result.data.total_page);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.type === "checkbox") {
      const target = e.target as HTMLInputElement;
      if (target.checked) {
        setFilter({
          ...filter,
          status: "",
          ["page"]: 1,
        });
        resetTable();
        return;
      } else {
        setFilter({ ...filter, status: USER_STATUS.ACTIVE.toString(), page: 1 });
        resetTable();
        return;
      }
    }
    if (e.target.name === "mainSection") {
      setSelectedMainSection(e.target.value);
      setFilter({ ...filter, section_id: e.target.value, page: 1 });
      resetTable();
      return;
    }
    if (e.target.name === "subSection") {
      if (e.target.value === "") {
        setFilter({ ...filter, section_id: selectedMainSection, page: 1 });
        resetTable();
        return;
      }
      setFilter({ ...filter, section_id: e.target.value, page: 1 });
      resetTable();
      return;
    }
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <div>
      <StudentTableCommonAction />
      <StudentFilter
        changeHandle={changeHandle}
        deleteSelected={deleteSelected}
        selectedMainSection={selectedMainSection}
      />
      <StudentTable
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        _students={_students}
        toggleSelect={toggleSelect}
        selectAll={selectAll}
        toggleSelectAll={toggleSelectAll}
        isStudentSelected={isStudentSelected}
      />
    </div>
  );
};

export default StudentList;
