import React, { useEffect, useState } from "react";
import BackToTopButton from "../../components/commons/buttons/BackToTopButton";
import { Link } from "react-router-dom";
import { PATH_POLICY } from "../../services/constants/route/router";
import { useStudentThemeContext } from "../../context/StudentThemeContext";

const Footer = () => {
  const { studentTheme } = useStudentThemeContext();

  return (
    <div
      style={{ backgroundColor: studentTheme.main_color_first }}
      className="flex items-center justify-center h-[40px] text-white text-[12px] leading-[100%] w-full bg-primary max-lg:h-[20px]"
    >
      <div className="w-full max-w-[1048px] flex items-center justify-between max-lg:justify-end">
        <div className="flex items-center justify-items-start gap-[30px] text-white max-lg:hidden max-lg:justify-end">
          <Link to={PATH_POLICY.TERM_OF_USE}>利用規約</Link>
          <Link to={PATH_POLICY.PRIVACY_POLICY}>個人情報保護方針</Link>
          <Link to={PATH_POLICY.COMMERCIAL_TRANSACTION_LAW}>
            特定商取引法に基づく表記
          </Link>
        </div>
        <div className="flex items-center ">
          <span className="inline-block align-middle mr-[51px]">
            Copyright ©2023 manabite. All Rights Reserved.
          </span>
          <BackToTopButton
            width={40}
            height={40}
            className=" max-lg:h-[20px] max-lg:w-[20px] "
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
