import { ElementProps } from "../../../types/globals";

type ToggleSwitchProps = {
  inputName?: string;
  disabled?: boolean;
  isChecked?: boolean;
} & ElementProps<HTMLInputElement>;

const ToggleSwitch = ({
  inputName,
  className,
  disabled = false,
  isChecked,
  ...props
}: ToggleSwitchProps) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        name={inputName || ""}
        className={`sr-only peer ${className}`}
        disabled={disabled}
        checked={isChecked}
        {...props}
      />
      <div
        className={`w-[38px] h-[18px] bg-secondary-light peer
       peer-focus:outline-none rounded-[10px] peer-checked:bg-danger 
       peer-checked:after:translate-x-[18.5px] after:absolute after:top-[2px] after:left-[3px]
       after:bg-white after:rounded-full after:h-[14px] after:w-[14px] after:transition-all ${
         disabled ? "!cursor-not-allowed" : ""
       }`}
      ></div>
    </label>
  );
};

export default ToggleSwitch;
