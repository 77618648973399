import React from 'react';

const SmallButton = ({
  children,
  isDisabled = false,
  className,
  ...props
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      disabled={isDisabled}
      className={`text-[12px] font-[700] leading-[17px] flex items-center text-white justify-center py-[10px] px-[8px] rounded-[5px] min-h-[32px] ${
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer '
      } ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default SmallButton;