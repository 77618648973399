/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import TestHistoryTable from "./TestHistoryTable";
import TestReviewTable from "./TestReviewTable";
import TestStatistics from "./TestStatistics";
import TestLatestReviewTable from "./TestLatestReviewTable";
import { useStudentUnitContext } from "../../../../context/StudentUnitContext";
import { useEffect } from "react";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { TestHistory, TestPausedHistory } from "../../../../types/student/unit";
import qs from "qs";
import { isEmptyArr, isEmptyObj } from "../../../../services/helpers/etc";
import { UNIT_TYPE } from "../../../../services/constants/admin/pages/material";
import TestModeGroup from "./TestModeGroup";
import {
  DELETE_CONFIRM_CONTENT,
  DELETE_CONFIRM_TITLE,
  MESSAGE_COMMON,
} from "../../../../services/constants/message";
import SmallButton from "../../common/SmallButton";
import { LuTrash2 } from "react-icons/lu";
import { swallConfirm } from "../../../../services/helpers/swal";
import { formatTotalTestTime } from "../../../../services/helpers/formatTime";

export type TestHistoryParams = {
  unit_id: number;
};

const UnitTest = () => {
  const {
    showSettings,
    unitData,
    currentHistory,
    triggerRefetch,
    setCurrentHistory,
    setTriggerRefetch,
    setCurrentHistoryId,
  } = useStudentUnitContext();
  const [testHistories, setTestHistories] = useState<TestHistory[]>([]);
  const [testPausedHistories, setTestPausedHistories] = useState<
    TestPausedHistory[]
  >([]);
  const [noHistoryData, setNoHistoryData] = useState<boolean>(true);
  const params: TestHistoryParams = {
    unit_id: unitData?.id || 0,
  };
  const queryParam = qs.stringify(params);

  useEffect(() => {
    if (!unitData || !unitData.id || unitData.type !== UNIT_TYPE.TEST) return;
    const testHistoryFetcher = request.get(
      `${API.COURSE.GET_LIST_TEST_HISTORY}?${queryParam}`,
      (res) => {
        setNoHistoryData(!(Array.isArray(res) && res.length > 0));
        setTestHistories(res);
      }
    );

    const testStatisticFetcher = request.get(
      `${API.COURSE.GET_STATISTIC_TEST}?${queryParam}`,
      (res) => {
        setCurrentHistory(res?.test_round_data);
        setCurrentHistoryId(res?.test_round_data?.id);
      }
    );

    const testHistoryOnPauseFetcher = request.get(
      `${API.COURSE.GET_LIST_TEST_HISTORY_ON_PAUSE}?${queryParam}`,
      setTestPausedHistories
    );

    const fetches = [
      testHistoryFetcher,
      testStatisticFetcher,
      testHistoryOnPauseFetcher,
    ];

    const willFetch = async () => await Promise.all(fetches);
    if (
      isEmptyObj(currentHistory) &&
      isEmptyArr(testHistories) &&
      isEmptyArr(testPausedHistories)
    ) {
      willFetch();
    }
  }, [triggerRefetch, unitData.id]);

  if (isEmptyObj(unitData)) return <></>;

  const handleDeleteHistory = () => {
    if (isEmptyObj(currentHistory)) return;

    const deleteData = {
      student_test_history_id: currentHistory.id || 0,
    };

    swallConfirm(async () => {
      await request.delete(API.COURSE.DELETE_TEST_HISTORY, deleteData, () =>
        setTriggerRefetch(new Date().getTime())
      );
    }, DELETE_CONFIRM_TITLE.TEST_HISTORY, DELETE_CONFIRM_CONTENT.TEST_HISTORY);
  };
  
  return (
    <section>
      <div className="text-[12px] leading-[190%] text-secondary text-right border-t border-danger-light mb-[15px] pt-[5px] pr-[7px] mt-[-11px]">
        問題　
        {unitData?.is_main_mode && `${formatTotalTestTime(unitData?.estimate_time_required)}／`}
        {currentHistory?.question_quantity || "-"}問／合格ライン
        {unitData?.passing_score || "-"}点（{currentHistory?.total_score || "-"}
        点満点）
      </div>

      {showSettings.isLatestHistory && <TestStatistics data={currentHistory} noHistoryData={noHistoryData} />}
      {showSettings.isReviewTestTable && (
        <>
          {showSettings.isLatestHistory ? (
            <TestLatestReviewTable
              unitId={unitData?.id}
              historyId={currentHistory?.id}
            />
          ) : (
            <TestReviewTable
              unitId={unitData?.id}
              historyId={currentHistory?.id}
            />
          )}

          <div className="mt-[-5px] ml-[4px] mb-[35px]">
            <SmallButton
              className="flex items-center bg-secondary-light text-[11px] font-[700] leading-[100%] !py-[5.5px] !px-[10px]"
              onClick={handleDeleteHistory}
            >
              <LuTrash2 size={20} className="mr-[5px]" /> 履歴を削除する
            </SmallButton>
          </div>
        </>
      )}

      <TestModeGroup testPausedHistories={testPausedHistories} testHistories={testHistories} unitData={unitData}/>

      <div className="w-full border border-danger-light rounded-[5px] mb-[15px] pt-[20px] px-[21px]">
        <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
          履歴
        </div>
        {!isEmptyArr(testHistories) ? (
          <TestHistoryTable data={testHistories} />
        ) : (
          <div className="mb-[20px] bg-warning-lighter p-[5px] !pl-[10px]">
            {MESSAGE_COMMON.NO_DATA_FOUND}
          </div>
        )}
      </div>
    </section>
  );
};

export default UnitTest;
