import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Unit from "./Unit";
import ChapterStatus from "./ChapterType";
import { Chapter as ChapterType } from "../../../../../types/student/chapter";
import { Unit as UnitType } from "../../../../../types/admin/unit";
import { request } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";
import {
  getPercent,
  parsePercent,
} from "../../../../../services/helpers/parseData";
import { useStudentThemeContext } from "../../../../../context/StudentThemeContext";
const Chapter = ({ chapterData }: { chapterData: ChapterType }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [unitList, setUnitList] = useState<UnitType[]>([]);
  const toggleExpansion = () => setIsExpanded(!isExpanded);
  const unitsCompleted = chapterData.amount_unit_done || 0;
  const unitTotal = chapterData.amount_unit || 0;
  const unitDonePercentage = getPercent(unitsCompleted, unitTotal);
  const { studentTheme } = useStudentThemeContext();

  const handleExpand = async () => {
    if (!!isExpanded) {
      toggleExpansion();
      return;
    }
    await request.get(
      `${API.LECTURE.GET_UNIT_IN_CHAPTER}?chapter_id=${chapterData.id}`,
      (unitList) => {
        setUnitList(unitList || []);
        toggleExpansion();
      }
    );
  };
  return (
    <div className="cursor-pointer min-h-[40px] border border-danger-light w-full text-[14px] font-[500] leading-[100%] mb-[12px] bg-[#FEFCF9] flex flex-col justify-center">
      <div className="flex items-center justify-between min-h-[40px] pl-[10px]">
        <div className="flex items-center w-full max-w-[395px]">
          <ChapterStatus
            status={chapterData.status || 0}
            isExpanded={isExpanded}
          />
          <span className="ml-[5px] max-w-[300px] truncate min-h-[15px] max-sm:max-w-[100px]">
            {chapterData.name || ""}{" "}
          </span>
        </div>
        <div className="w-full max-w-[215px]">
          <div className="w-full rounded-full h-[10px] bg-success-extralight">
            <div
              className={`bg-primary-light h-[10px] rounded-full`}
              style={{
                width: `${parsePercent(unitDonePercentage)}%`,
                backgroundColor: studentTheme.sub_color_first,
              }}
            ></div>
          </div>
        </div>
        <div className="ml-[16px] text-secondary-disabled font-[700] with_roboto w-full max-w-[30px]">
          {unitsCompleted ?? "-"}/{unitTotal ?? "-"}
        </div>
        <div className="text-secondary">
          <div className="ml-[16px] mr-[16px] cursor-pointer text-secondary-light">
            {
              <>
                {isExpanded ? (
                  <IoIosArrowUp size={18} onClick={handleExpand} />
                ) : (
                  <IoIosArrowDown size={18} onClick={handleExpand} />
                )}
              </>
            }
          </div>
        </div>
      </div>
      {isExpanded &&
        unitList &&
        unitList.map((unitData, index) => (
          <Unit
            key={unitData.id}
            unitData={unitData}
            finalUnit={index === unitList.length - 1}
          />
        ))}
    </div>
  );
};

export default Chapter;
