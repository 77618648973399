import React from 'react';
import ExecuteTest from '../../../components/student/Course/ExecuteTest/ExecuteTest';

const ExecuteTestPage = () => {
  return (
    <section className="w-full flex justify-center bg-secondary min-h-screen pt-[5px]">
      <ExecuteTest />
    </section>
  );
};

export default ExecuteTestPage;
