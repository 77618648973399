/* eslint-disable react-hooks/exhaustive-deps */
import TableHeadItem from '../../commons/TableHeadItem';
import { useCourseListContext } from '../../../../context/CourseContext';
import TableItem from './TableItem';
import { COURSE_TABLE_ITEM_TYPE } from '../../../../services/constants/admin/pages/course';
import { Fragment, useState, useEffect } from 'react';
import { getDataByParent } from '../../../../services/utils/admin/course';
import { swallConfirm } from '../../../../services/helpers/swal';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { Course, CourseItemType } from '../../../../types/admin/course';
import {
  DELETE_CONFIRM_CONTENT,
  DELETE_CONFIRM_TITLE,
} from '../../../../services/constants/message';
import type { Direction, Object } from '../../../../types/globals';
import { DIRECTION } from '../../../../services/constants/globals';
import { Chapter } from '../../../../types/admin/chapter';
import { Lecture } from '../../../../types/admin/lecture';

export type MemoriedExpandStatus = {
  [key: string]: boolean;
};

type TableItemType = Course | Lecture | Chapter;
type CurrentArray<T = TableItemType> = Array<T>;

type DeleteData = {
  course_id?: number;
  lecture_id?: number;
  chapter_id?: number;
};

type UpdatedObject = {
  id?: number;
  itemType?: CourseItemType;
  parentId?: number | null;
};

const Table = () => {
  const { courses, setCourses, lectures, setLectures, chapters, setChapters } =
    useCourseListContext();
  const [memoriedOpenStatus, setMemoriedOpenStatus] = useState<MemoriedExpandStatus>({});
  const [updatedObjectInfo, setUpdatedObjectInfo] = useState<UpdatedObject>({});

  useEffect(() => {
    const fetch = async () =>
      await request.get(`${API.ADMIN_COURSE.LIST}?sort=order`, (res) =>
        setCourses([...courses, ...res]),
      );

    fetch();
  }, []);

  const handleExpand = async (id?: number, itemType?: CourseItemType, isExpand?: boolean) => {
    if (!id || !itemType) return;
    if (!isExpand)
      return setMemoriedOpenStatus({ ...memoriedOpenStatus, [`${itemType}_${id}`]: false });

    let urlByType = `${API.ADMIN_LECTURE.LIST}?${itemType}_id=${id}`;
    if (itemType === COURSE_TABLE_ITEM_TYPE.LECTURE)
      urlByType = `${API.ADMIN_CHAPTER.LIST}?${itemType}_id=${id}`;

    const resAction: <T extends Object>(res: Array<T>) => void = (res) => {
      switch (itemType) {
        case COURSE_TABLE_ITEM_TYPE.COURSE:
          setLectures([...lectures, ...res]);
          break;

        case COURSE_TABLE_ITEM_TYPE.LECTURE:
          setChapters([...chapters, ...res]);
          break;

        default:
          break;
      }
    };

    await request.get(urlByType, resAction);
    setMemoriedOpenStatus({ ...memoriedOpenStatus, [`${itemType}_${id}`]: isExpand || false });
  };

  const handleDelete = (id: number, itemType: CourseItemType, parentId: number | null) => {
    if (!id || !itemType) return;

    let deleteData: DeleteData = { course_id: id };
    let url = API.ADMIN_COURSE.DELETE;
    let confirmTitle = DELETE_CONFIRM_TITLE.COURSE;
    let confirmContent = DELETE_CONFIRM_CONTENT.COURSE;

    switch (itemType) {
      case COURSE_TABLE_ITEM_TYPE.LECTURE:
        deleteData = { lecture_id: id };
        url = API.ADMIN_LECTURE.DELETE;
        confirmTitle = DELETE_CONFIRM_TITLE.LECTURE;
        confirmContent = DELETE_CONFIRM_CONTENT.LECTURE;

        break;
      case COURSE_TABLE_ITEM_TYPE.CHAPTER:
        deleteData = { chapter_id: id };
        url = API.ADMIN_CHAPTER.DELETE;
        confirmTitle = DELETE_CONFIRM_TITLE.CHAPTER;
        confirmContent = DELETE_CONFIRM_CONTENT.CHAPTER;
        break;
      default:
        break;
    }

    swallConfirm(
      async () => {
        await request.delete(url, deleteData, () =>
          setUpdatedObjectInfo({ id: id, itemType: itemType, parentId }),
        );
      },
      confirmTitle,
      confirmContent,
    );
  };

  const handleOrder = async (
    id: number,
    direction: Direction,
    itemType: CourseItemType,
    parentId: number | null,
  ) => {
    let currentArray: CurrentArray = courses,
      updateEndpoint: string = API.ADMIN_COURSE.UPDATE_ORDER;

    switch (itemType) {
      case COURSE_TABLE_ITEM_TYPE.LECTURE:
        currentArray = lectures.filter((lecture) => lecture.course_id === Number(parentId));
        updateEndpoint = API.ADMIN_LECTURE.UPDATE_ORDER;
        break;

      case COURSE_TABLE_ITEM_TYPE.CHAPTER:
        currentArray = chapters.filter((chapter) => chapter.lecture_id === Number(parentId));
        updateEndpoint = API.ADMIN_CHAPTER.UPDATE_ORDER;
        break;

      default:
        break;
    }

    const currentRecord: TableItemType = currentArray.find((item) => item.id === id) || {};
    const currentIndex = currentArray.findIndex((item) => item.id === id);
    const siblingIndex = direction === DIRECTION.UP ? currentIndex - 1 : currentIndex + 1;
    const siblingRecord = currentArray[siblingIndex];

    if (!currentRecord || !siblingRecord) return;

    const currentSubmitData = {
      [`${itemType}_id`]: currentRecord.id,
      order:
        direction === DIRECTION.UP
          ? (currentRecord.order || 0) - 1
          : (currentRecord.order || 0) + 1,
    };
    const siblingSubmitData = {
      [`${itemType}_id`]: siblingRecord.id,
      order:
        direction === DIRECTION.UP
          ? (siblingRecord.order || 0) + 1
          : (siblingRecord.order || 0) - 1,
    };

    const currentCoursePromise = request.patch(updateEndpoint, currentSubmitData);
    const siblingCoursePromise = request.patch(updateEndpoint, siblingSubmitData);

    await Promise.all([currentCoursePromise, siblingCoursePromise]);
    setUpdatedObjectInfo({ id: id, itemType: itemType, parentId });
  };

  useEffect(() => {
    const fetch = async () => {
      if (!updatedObjectInfo || !Object.keys(updatedObjectInfo).length) return;

      let getEndpoint: string = API.ADMIN_COURSE.LIST;
      let responseTarget = [] as Course[];
      let actionCallback: <T extends Object>(res: T[]) => void = setCourses;
      switch (updatedObjectInfo.itemType) {
        case COURSE_TABLE_ITEM_TYPE.LECTURE:
          getEndpoint = `${API.ADMIN_LECTURE.LIST}?${COURSE_TABLE_ITEM_TYPE.COURSE}_id=${updatedObjectInfo.parentId}`;
          responseTarget = [
            ...lectures.filter(
              (lecture) => lecture.course_id !== Number(updatedObjectInfo.parentId),
            ),
          ];
          actionCallback = setLectures;
          break;

        case COURSE_TABLE_ITEM_TYPE.CHAPTER:
          getEndpoint = `${API.ADMIN_CHAPTER.LIST}?${COURSE_TABLE_ITEM_TYPE.LECTURE}_id=${updatedObjectInfo.parentId}`;
          responseTarget = [
            ...chapters.filter(
              (chapter) => chapter.lecture_id !== Number(updatedObjectInfo.parentId),
            ),
          ];
          actionCallback = setChapters;
          break;

        default:
          break;
      }

      await request.get(getEndpoint, (res) => actionCallback([...responseTarget, ...res]));
    };

    fetch();
  }, [updatedObjectInfo]);

  return (
    <section>
      <div className="flex bg-secondary-extralight items-center justify-between w-full mt-[15px] mb-[8px]">
        <TableHeadItem className="w-[50%] !border-l-0 !justify-start !pl-[10px]">
          学習コース
        </TableHeadItem>
        <TableHeadItem className="w-[5%]">数</TableHeadItem>
        <TableHeadItem className="w-[12%]">並び順</TableHeadItem>
        <TableHeadItem className="w-[10%]">ステータス</TableHeadItem>
        <TableHeadItem className="w-[5%]">削除</TableHeadItem>
        <TableHeadItem className="w-[10%]">設定</TableHeadItem>
        <TableHeadItem className="w-[20%] !border-r-0 text-secondary-extralight"></TableHeadItem>
      </div>

      <div className="">
        {courses &&
          courses.map((course, courseIndex) => (
            <Fragment key={`${COURSE_TABLE_ITEM_TYPE.COURSE}_${course.id}`}>
              <TableItem
                data={course}
                itemType={COURSE_TABLE_ITEM_TYPE.COURSE}
                expandData={memoriedOpenStatus}
                onExpandFetch={handleExpand}
                onDelete={handleDelete}
                onOrder={handleOrder}
                isUpDisabled={courseIndex === 0}
                isDownDisabled={courseIndex === courses.length - 1}
              />
              <div className="">
                {lectures &&
                  memoriedOpenStatus[`${COURSE_TABLE_ITEM_TYPE.COURSE}_${course.id}`] &&
                  course.id &&
                  getDataByParent(lectures, course.id, COURSE_TABLE_ITEM_TYPE.COURSE).map(
                    (lecture, lectureIndex) => (
                      <Fragment key={`${COURSE_TABLE_ITEM_TYPE.LECTURE}_${lecture.id}`}>
                        <TableItem
                          data={lecture}
                          itemType={COURSE_TABLE_ITEM_TYPE.LECTURE}
                          expandData={memoriedOpenStatus}
                          onExpandFetch={handleExpand}
                          onDelete={handleDelete}
                          onOrder={handleOrder}
                          isUpDisabled={lectureIndex === 0}
                          isDownDisabled={
                            lectureIndex ===
                            getDataByParent(lectures, course.id, COURSE_TABLE_ITEM_TYPE.COURSE)
                              .length -
                              1
                          }
                          parentId={course.id}
                        />
                        <div className="">
                          {chapters &&
                            memoriedOpenStatus[`${COURSE_TABLE_ITEM_TYPE.LECTURE}_${lecture.id}`] &&
                            lecture.id &&
                            getDataByParent(
                              chapters,
                              lecture.id,
                              COURSE_TABLE_ITEM_TYPE.LECTURE,
                            ).map((chapter, chapterIndex) => (
                              <TableItem
                                data={chapter}
                                key={`${COURSE_TABLE_ITEM_TYPE.CHAPTER}_${chapter.id}`}
                                itemType={COURSE_TABLE_ITEM_TYPE.CHAPTER}
                                onDelete={handleDelete}
                                onOrder={handleOrder}
                                isUpDisabled={chapterIndex === 0}
                                isDownDisabled={
                                  chapterIndex ===
                                  getDataByParent(
                                    chapters,
                                    lecture.id,
                                    COURSE_TABLE_ITEM_TYPE.LECTURE,
                                  ).length -
                                    1
                                }
                                parentId={lecture.id}
                              />
                            ))}
                        </div>
                      </Fragment>
                    ),
                  )}
              </div>
            </Fragment>
          ))}
      </div>
    </section>
  );
};

export default Table;
