import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { useStorage } from "../../../hooks/useStorage";
import { parsePercent } from "../../../services/helpers/parseData";

type ProgressbarProps = {
  width?: number;
  height?: number;
  progress?: number;
};

const ProgressBar = ({
  width = 240,
  height = 8,
  progress = 0,
}: ProgressbarProps) => {
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  
  return (
    <div className="w-full" style={{ width: `${width}px` }}>
      <div
        className="w-full rounded-full bg-success-extralight"
        style={{ height: `${height}px` }}
      >
        <div
          className={`bg-primary-light h-[8px] rounded-full`}
          style={{
            width: `${parsePercent(progress)}%`,
            backgroundColor: studentTheme.sub_color_first,
          }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
