import React from "react";
import { COURSE_TYPE } from "../../../services/constants/student/course";
import { Course } from "../../../types/student/course";
import LearningRead from "../../commons/icons/Learning/Read";
import LearningWrite from "../../commons/icons/Learning/Write";
import award from "../../../assests/images/icons/award.svg";
import { BsCheck2 } from "react-icons/bs";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const CourseType = ({ status }: { status: Course["status"] }) => {
  const { studentTheme } = useStudentThemeContext();

  switch (status) {
    case COURSE_TYPE.DONE:
      return (
        <div className="w-[24px] h-[24px] rounded-full bg-danger-light flex items-center justify-center">
          <img src={award} />
        </div>
      );

    default:
      return (
        <div
          style={{
            backgroundColor: studentTheme.main_color_second,
          }}
          className="w-[24px] h-[24px] rounded-full bg-warning flex items-center justify-center"
        >
          <LearningRead />
        </div>
      );
  }
};

export default CourseType;
