import PaymentKonbiniAfter from "../../../components/student/Payment/PaymentKonbiniAfter";
import StudentLayout from "../../../layouts/student";
import PaymentInvoiceSuccess from "../../../components/student/Payment/PaymentInvoiceSuccess";

interface OrderSuccessPageProp {
  successType?: number
}

const OrderSuccessPage = ({ successType = 1 }: OrderSuccessPageProp) => {
  const COMBINI_SUCCESS_PAGE = 1;
  const INVOICE_SUCCESS_PAGE = 2;

  const renderSuccessPage = (successPageType: number) => {
    switch (successPageType) {
      case  INVOICE_SUCCESS_PAGE:
        return <PaymentInvoiceSuccess/>
      default:
        return <PaymentKonbiniAfter/>
    }
  }

  return (
    <StudentLayout sidebar={(<></>)}>
      {renderSuccessPage(successType)}
    </StudentLayout>
  )
};

export default OrderSuccessPage;
