import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import ReportHeader from "./ReportHeader";
import { TYPE_CHART } from "../../../../services/constants/student/studytool";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import ReportActivityList from "./ReportActivityList";
import {
  REPORT_PERIOD_OPTION,
  REPORT_PERIOD_OPTION_DATA,
  REPORT_UNIT_OPTION,
  REPORT_UNIT_OPTION_DATA,
} from "../../../../services/constants/student/report";
import { useState, useEffect } from "react";
import { getFullDate } from "../../../../services/helpers/formatTime";
import dayjs from "dayjs";
import ReactDatePicker from "react-datepicker";
import "../../../../assests/styles/learningToolReport.css";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import ChartDisplayer from "./ChartDisplayer";

export type ReportFilter = {
  period?: number;
  start_date?: string;
  end_date?: string;
  display?: number;
};

export type LectureOption = {
  id: number;
  name: string;
};

export type CourseOption = {
  id: number;
  name: string;
  lectures: LectureOption[];
};

const ReportBoard = () => {
  const { getParamValue } = useObjectRoutes();
  const typeChart = getParamValue("type") || TYPE_CHART.STUDY_TIME;

  const [filter, setFilter] = useState<ReportFilter>({
    period: REPORT_PERIOD_OPTION.CUSTOM_DATE,
    display: REPORT_UNIT_OPTION.DAY,
    start_date: getFullDate(dayjs().subtract(6, "day").format()),
    end_date: getFullDate(),
  });
  const [courseOptions, setCourseOption] = useState<CourseOption[]>([]);

  useEffect(() => {
    if (typeChart === TYPE_CHART.STUDY_TIME) return;

    const fetch = async () =>
      await request.get(API.COURSE.GET_LIST_COURSE_STUDY_TOOL, setCourseOption);
    fetch();
  }, [typeChart]);

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    let value: number | string = e.target.value;
    value = Number(e.target.value);
    if (e.target.type === "date") {
      value = String(e.target.value);
    }

    let additionProperties = {};
    const subDayFromNow = (day: number) =>
      getFullDate(
        dayjs()
          .subtract(day || 6, "day")
          .format()
      );

    if (e.target.name === "period") {
      switch (Number(e.target.value)) {
        case REPORT_PERIOD_OPTION._7_DAYS:
          additionProperties = {
            start_date: subDayFromNow(6),
            end_date: getFullDate(),
          };
          break;

        case REPORT_PERIOD_OPTION._30_DAYS:
          additionProperties = {
            start_date: subDayFromNow(29),
            end_date: getFullDate(),
          };
          break;

        case REPORT_PERIOD_OPTION._90_DAYS:
          additionProperties = {
            start_date: subDayFromNow(89),
            end_date: getFullDate(),
          };
          break;

        default:
          additionProperties = {
            start_date: subDayFromNow(6),
            end_date: getFullDate(),
          };
          break;
      }
    }

    setFilter({
      ...filter,
      [e.target.name]: value,
      ...additionProperties,
    });
  };

  const isCustomDate = filter.period === REPORT_PERIOD_OPTION.CUSTOM_DATE;

  return (
    <div className="min-h-[900px] rounded-[5px] my-[20px] text-secondary-light text-[12px] font-[500] max-sm:mx-[5px]">
      <div className="w-full max-w-[770px] border border-danger-light rounded-[14px] px-[15px] max-sm:px-[5px]">
        <ReportHeader />
        <div className="mx-[10px]">
          <ChartDisplayer
            typeChart={typeChart}
            filter={filter}
            courseOptions={courseOptions}
          />
          {typeChart === TYPE_CHART.STUDY_TIME && (
            <div className="absolute text-[9px] tracking-[0.179] text-secondary mt-[-18px] ml-[25px]">
              時間
            </div>
          )}
          {typeChart === TYPE_CHART.STUDY_PROCESS && (
            <div className="absolute text-[9px] tracking-[0.179] text-secondary mt-[-25px] ml-[43px]">
              %
            </div>
          )}
        </div>
        <div className="flex gap-[15px] items-center my-[20px] ml-[20px] text-secondary max-sm:hidden">
          <div className="flex flex-col">
            期間
            <select
              name="period"
              className="w-[108px] h-[32px] rounded-[16px] text-[11px] border border-danger-light"
              onChange={handleChange}
            >
              {REPORT_PERIOD_OPTION_DATA.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center gap-[5px]">
            <div className="flex flex-col">
              開始日
              <ReactDatePicker
                onChange={(date) =>
                  setFilter((prev) => ({
                    ...prev,
                    start_date: getFullDate(date as Date),
                  }))
                }
                dateFormat="MM/dd"
                className={`w-full max-w-[108px] h-[32px] !rounded-[16px] text-[11px] text-secondary border-danger-light cursor-pointer date_picker_bg ${
                  !isCustomDate && "!bg-warning-lighter !cursor-not-allowed"
                }`}
                disabled={!isCustomDate}
                selected={new Date(filter.start_date || new Date())}
              />
            </div>
            <MdOutlineKeyboardDoubleArrowRight
              size={18}
              className="mt-[15px]"
            />
            <div className="flex flex-col">
              終了日
              <div className="relative">
                <ReactDatePicker
                  onChange={(date) =>
                    setFilter((prev) => ({
                      ...prev,
                      end_date: getFullDate(date as Date),
                    }))
                  }
                  dateFormat="MM/dd"
                  className={`w-full max-w-[108px] h-[32px] !rounded-[16px] text-[11px] text-secondary border-danger-light cursor-pointer date_picker_bg ${
                    !isCustomDate && "!bg-warning-lighter !cursor-not-allowed"
                  }`}
                  disabled={!isCustomDate}
                  selected={new Date(filter.end_date || new Date())}
                />
              </div>
            </div>
          </div>
          {typeChart === TYPE_CHART.STUDY_TIME && (
            <div className="flex flex-col w-full max-w-[108px]">
              表示単位
              <select
                name="display"
                className="h-[32px] rounded-[16px] text-[11px] border border-danger-light"
                onChange={handleChange}
              >
                {REPORT_UNIT_OPTION_DATA.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {/* responsive smartphone */}
        <div className="flex flex-col gap-[15px] items-center my-[20px] ml-[20px] text-secondary sm:hidden">
          <div className="flex justify-center items-center gap-[30px]">
            <div className="flex flex-col">
              期間
              <select
                name="period"
                className="w-[108px] h-[32px] rounded-[16px] text-[11px] border border-danger-light"
                onChange={handleChange}
              >
                {REPORT_PERIOD_OPTION_DATA.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {typeChart === TYPE_CHART.STUDY_TIME && (
              <div className="flex flex-col w-[108px]">
                表示単位
                <select
                  name="display"
                  className="h-[32px] rounded-[16px] text-[11px] border border-danger-light"
                  onChange={handleChange}
                >
                  {REPORT_UNIT_OPTION_DATA.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="flex items-center gap-[5px]">
            <div className="flex flex-col">
              開始日
              <ReactDatePicker
                onChange={(date) =>
                  setFilter((prev) => ({
                    ...prev,
                    start_date: getFullDate(date as Date),
                  }))
                }
                dateFormat="MM/dd"
                className={`w-full max-w-[108px] h-[32px] !rounded-[16px] text-[11px] text-secondary border-danger-light cursor-pointer date_picker_bg ${
                  !isCustomDate && "!bg-warning-lighter !cursor-not-allowed"
                }`}
                disabled={!isCustomDate}
                selected={new Date(filter.start_date || new Date())}
              />
            </div>
            <MdOutlineKeyboardDoubleArrowRight
              size={18}
              className="mt-[15px]"
            />
            <div className="flex flex-col">
              終了日
              <div className="relative">
                <ReactDatePicker
                  onChange={(date) =>
                    setFilter((prev) => ({
                      ...prev,
                      end_date: getFullDate(date as Date),
                    }))
                  }
                  dateFormat="MM/dd"
                  className={`w-full max-w-[108px] h-[32px] !rounded-[16px] text-[11px] text-secondary border-danger-light cursor-pointer date_picker_bg ${
                    !isCustomDate && "!bg-warning-lighter !cursor-not-allowed"
                  }`}
                  disabled={!isCustomDate}
                  selected={new Date(filter.end_date || new Date())}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReportActivityList />
    </div>
  );
};

export default ReportBoard;
