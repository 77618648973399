import * as React from 'react';

const CartMini = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#a)"
      stroke="#fff"
      strokeWidth={1.167}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5.25 12.833a.583.583 0 1 0 0-1.166.583.583 0 0 0 0 1.166ZM11.667 12.833a.583.583 0 1 0 0-1.166.583.583 0 0 0 0 1.166ZM.583.583h2.334L4.48 8.394a1.167 1.167 0 0 0 1.167.94h5.67a1.167 1.167 0 0 0 1.166-.94l.934-4.894H3.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CartMini;
