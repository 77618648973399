/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import UnitLessonCreate from "./UnitLessonCreate";
import { UNIT_TYPE } from "../../../../services/constants/admin/pages/material";
import UnitTest from "./UnitTest";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { Unit } from "../../../../types/admin/unit";

const UnitCreate = () => {
  const [createType, setCreateType] = useState<number>(1);
  const [unitData, setUnitData] = useState<Unit>({});
  const { getParamValue } = useObjectRoutes();
  const unitId = getParamValue("unit_id");

  useEffect(() => {
    if (!unitId || (unitData && Object.keys(unitData).length)) return;

    const fetchDetail = async () => {
      await request.get(
        `${API.ADMIN_UNIT.DETAIL}?unit_id=${unitId}`,
        (unitData) => {
          setCreateType(Number(unitData.type) || 0);
          setUnitData(unitData || {});
        }
      );
    };

    fetchDetail();
  }, [unitId]);

  const onCreateTypeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCreateType(parseInt(e.target.value));

  return (
    <>
      <div className="px-[30px] py-[15px] border-b border-secondary-light mb-[40px]">
        {!unitId && (
          <div className="w-full flex items-center mb-[5px]">
            <div className="mr-[25px] text-[13px] font-[500]">
              登録するタイプを選択してください
            </div>
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px] mb-[5px]">
              必須
            </div>
          </div>
        )}
        <div className="text-secondary-dark w-full max-w-[275px] h-[50px] bg-[#F9F9F9] items-center px-3 flex justify-between">
          {!unitId ? (
            <>
              <label className="inline-flex items-center mr-[15px]">
                <input
                  type="radio"
                  className="form-radio text-primary-light border border-success-extralight"
                  name="formType"
                  value={UNIT_TYPE.LESSON}
                  checked={createType === UNIT_TYPE.LESSON}
                  onChange={onCreateTypeChange}
                  disabled={!!unitId}
                />
                <span className="ml-[8px] text-[16px] font-[400] leading-[100%]">
                  教材
                </span>
              </label>
              <label className="inline-flex items-center mr-[20px]">
                <input
                  type="radio"
                  className="form-radio text-primary-light border border-success-extralight"
                  name="formType"
                  value={UNIT_TYPE.TEST}
                  checked={createType === UNIT_TYPE.TEST}
                  onChange={onCreateTypeChange}
                  disabled={!!unitId}
                />
                <span className="ml-[8px] text-[16px] font-[400] leading-[100%]">
                  テスト・解説
                </span>
              </label>{" "}
            </>
          ) : (
            <div>
              登録タイプ：{" "}
              {createType === UNIT_TYPE.LESSON ? "教材" : "テスト・解説"}
            </div>
          )}
        </div>
      </div>
      {createType === UNIT_TYPE.LESSON ? (
        <UnitLessonCreate createType={createType} unitData={unitData} />
      ) : (
        <UnitTest unitData={unitData} createType={createType} />
      )}
    </>
  );
};

export default UnitCreate;
