import { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ProfileSidebar } from "../../types/student/main";
import { useLoginSettingContext } from "../../context/LoginSettingContext";

const MenuMobileItem = ({ item }: { item: ProfileSidebar }) => {
  const [isShow, setIsShow] = useState<boolean>(true);
  const { studentTheme } = useLoginSettingContext();

  return (
    <div className="py-[10px] border-b border-secondary-light">
      <div
        style={{ color: studentTheme.main_color_first }}
        className="flex justify-between items-center text-primary"
      >
        <div className="text-[16px] font-[700]">{item.label}</div>
        <div onClick={() => setIsShow(!isShow)}>
          {isShow ? <BsChevronDown /> : <BsChevronUp />}
        </div>
      </div>
      <div className={`mt-[10px] ${isShow ? "" : "hidden"}`}>
        {item?.child?.map((itemChild, itemChildIndex) => {
          return (
            <div className="px-[20px] py-[10px]" key={itemChildIndex}>
              <Link to={itemChild?.href || "#"} className="">
                {itemChild.label}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MenuMobileItem;
