import { useEffect, useState } from 'react';
import { Memo } from '../../../types/student/memo';
import { FiSave } from 'react-icons/fi';
import RichTextEditor from '../../commons/form/TextEditor';
import { request } from '../../../services/axios/axios';
import { API } from '../../../services/constants/route/api';
import { PATH_STUDY_TOOL } from '../../../services/constants/route/router';
import { ErrorData } from '../../../types/globals';
import MemoTypeBgColor from './MemoTypeBgColor';
import { useNavigate } from 'react-router-dom';
import MemoSelectFolder from './MemoSelectFolder';
import ErrorBox from '../../commons/form/ErrorBox';
import { useStudentThemeContext } from '../../../context/StudentThemeContext';
import { getTextByHtml } from '../../../services/helpers/parseData';

const MemoCreate = () => {
  const navigate = useNavigate();
  const { studentTheme } = useStudentThemeContext();
  const [errors, setErrors] = useState<ErrorData>({});
  const [backgroundColorId, setBackgroundColorId] = useState<string>('');
  const [memo, setMemo] = useState<Memo>({
    memo_folder_id: 0,
    background_color_code: 'FFFFFF',
  });
  const memoFolderId = JSON.parse(localStorage.getItem('memoFolderId') || '0');

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let colorId = e.currentTarget.id;
    setBackgroundColorId(colorId);
    setMemo({ ...memo, background_color_code: colorId });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setMemo({ ...memo, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    await request.post(
      `${API.MEMO.UPSERT_MEMO}`,
      memo,
      (upsertMemo) => {
        if (upsertMemo && upsertMemo.id) {
          setErrors({});
          localStorage.setItem(`memoFolderId`, String(upsertMemo?.memo_folder_id));
          return navigate(`${PATH_STUDY_TOOL.DETAIL_MEMO}/${upsertMemo.id}`);
        }
      },
      (errors) => {
        setErrors(errors);
      },
      { withSuccess: true },
    );
  };

  useEffect(() => {
    setMemo({ ...memo, memo_folder_id: memoFolderId });
  }, []);

  return (
    <div className="max-w-[806px] px-[30px] text-secondary-light">
      {errors && Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}
      <MemoSelectFolder handleChange={handleChange} memoFolderId={memo.memo_folder_id || 0} />
      <div className="py-[12px]">
        <input
          type="text"
          name="name"
          className="w-full px-[20px] py-[10px] text-secondary-dark placeholder:text-secondary-light placeholder:text-[16px] placeholder:font-[700] !rounded-none"
          placeholder="メモタイトルを入力"
          onChange={handleChange}
        />
      </div>
      <MemoTypeBgColor
        title="色の変更"
        backgroundColorId={backgroundColorId}
        onClick={handleOnClick}
        className="pb-[25px] border-b border-danger-light"
      />
      <div className="mt-[8px]">
        <RichTextEditor
          value={memo.content}
          changeHandler={(html: string) => {
            setMemo({ ...memo, content: html, content_origin: getTextByHtml(html) });
          }}
          toolbarId="toolbar"
          className="h-[200px] text-secondary-dark !border-0"
          isStudent={true}
        />
      </div>
      <div className="flex justify-end gap-[8px] mt-[8px] h-[28px] text-white text-[12px] font-[700]">
        <button
          className="h-[28px] px-[20px] bg-secondary-light rounded-[20px] cursor-pointer"
          onClick={() => navigate(PATH_STUDY_TOOL.MEMO)}
        >
          キャンセル
        </button>
        <button
          style={{ backgroundColor: studentTheme.main_color_first }}
          className="flex items-center gap-[7px] px-[20px] bg-primary rounded-[20px]"
          onClick={handleSubmit}
        >
          <FiSave className="" size={18} />
          作成
        </button>
      </div>
    </div>
  );
};

export default MemoCreate;
