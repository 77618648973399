import SectionManagement from '../../../components/admin/Section/Management/Management';
import SectionBase from '.';
import SectionManagementProvider from '../../../context/Section/Management';

const SectionManagementPage = () => {
  return (
    <SectionBase title="セクション管理 ｜ セクション管理">
      <SectionManagementProvider>
        <SectionManagement />
      </SectionManagementProvider>
    </SectionBase>
  );
};

export default SectionManagementPage;
