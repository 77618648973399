import React from 'react';
import CourseBase from '.';
import StudentCSV from '../../../components/admin/student/StudentCSV';
import TableCommonAction from '../../../components/admin/commons/TableCommonAction';

const BulkRegistrationPage = () => {
  return (
    <CourseBase title="学習コース管理 ｜ 設定・一括登録">
      <TableCommonAction className="mb-[40px]" createBtnLabel="新規コース登録" />
      <StudentCSV />
    </CourseBase>
  );
};

export default BulkRegistrationPage;
