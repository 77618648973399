import { CourseItemType } from '../../../../types/admin/course';
import { COURSE_TABLE_ITEM_TYPE } from '../../../../services/constants/admin/pages/course';

type CreateButtonDisplayProps = {
  itemType: CourseItemType;
};

const CreateButtonDisplay = ({ itemType }: CreateButtonDisplayProps) => {
  switch (itemType) {
    case COURSE_TABLE_ITEM_TYPE.COURSE:
      return (
        <button className="w-full max-w-[140px] min-h-[22px] bg-danger rounded-[5px] text-white px-[7.5px] pt-[3px] pb-[5px]">
          講義を追加
        </button>
      );

    case COURSE_TABLE_ITEM_TYPE.LECTURE:
      return (
        <button className="w-full max-w-[140px] min-h-[22px] bg-success rounded-[5px] text-white px-[7.5px] pt-[3px] pb-[5px]">
          章を追加
        </button>
      );

    case COURSE_TABLE_ITEM_TYPE.CHAPTER:
      return (
        <button className="w-full max-w-[140px] min-h-[22px] bg-secondary rounded-[5px] text-white px-[7.5px] pt-[3px] pb-[5px]">
          教材・テストを登録
        </button>
      );

    default:
      return <></>;
  }
};

export default CreateButtonDisplay;
