/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReviewChangeButton from "./ReviewChangeButton";
import { useStudentUnitContext } from "../../../../context/StudentUnitContext";
import { TEST_REVIEW_TABLE_HEAD } from "../../../../services/constants/student/unit";
import AnswerType from "./Type";
import {
  RowTestReviewTable,
  TestHistory,
} from "../../../../types/student/unit";
import { isEmptyArr } from "../../../../services/helpers/etc";
import {
  ExplanationParams,
  FetchTestReviewParams,
} from "./TestLatestReviewTable";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import qs from "qs";
import { MESSAGE_COMMON } from "../../../../services/constants/message";
import { formatTotalTestTime } from "../../../../services/helpers/formatTime";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { PATH_COURSE } from "../../../../services/constants/route/router";
import Unknown from "../../../commons/icons/Learning/Unknown";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type TestReviewTableProps = {
  unitId?: number;
  historyId?: number;
};

const TestReviewTable = ({ unitId, historyId }: TestReviewTableProps) => {
  const { showSettings, currentHistoryId, setShowSettings, setCurrentHistory } =
    useStudentUnitContext();
  const [testReviews, setTestReviews] = useState<RowTestReviewTable[]>([]);
  const [testStatistic, setTestStatistic] = useState<TestHistory>({});
  const limitTest = 6; //for current test and nearest of it.
  const { navigate } = useObjectRoutes();
  const { studentTheme } = useStudentThemeContext();
  useEffect(() => {
    if (!unitId) return;
    if (!historyId) {
      setShowSettings({
        ...showSettings,
        isReviewTestTable: !showSettings.isReviewTestTable,
        isLatestHistory: true,
      });
    }

    const fetchTestReviews = () => {
      const paramObj: FetchTestReviewParams = {
        amount_compare_test: 5,
        student_test_history_id: Number(currentHistoryId || historyId),
      };
      const queryParam = qs.stringify(paramObj);

      request.get(`${API.COURSE.GET_STATISTIC_TEST}?${queryParam}`, (res) => {
        setTestReviews(res?.detail_round_data || []);
        setTestStatistic(res?.test_round_data || {});
        setCurrentHistory(res?.test_round_data || {});
      });
    };

    if (isEmptyArr(testReviews) || !!historyId) fetchTestReviews();
  }, [unitId, currentHistoryId]);

  const useableQuestion = (questions: RowTestReviewTable["questions"]) => {
    if (
      !Array.isArray(questions) ||
      typeof questions !== "object" ||
      isEmptyArr(questions) ||
      questions.length > limitTest ||
      questions.length < 0
    )
      return false;

    return true;
  };

  const explanationParamObject: ExplanationParams = {
    student_test_history_id: Number(historyId),
  };

  const explanationSearchParam = qs.stringify(explanationParamObject);

  return (
    <div className="">
      <div className="flex justify-between items-center mb-[10px] mt-[35px]">
        <div className="font-[500] text-[14px] leading-[100%]">
          テスト結果一覧
        </div>
        <ReviewChangeButton
          onClick={() =>
            setShowSettings({ ...showSettings, isLatestHistory: true })
          }
        >
          最新結果の詳細に戻る
        </ReviewChangeButton>
      </div>

      {!isEmptyArr(testReviews) ? (
        <>
          <div
            className={`${
              testReviews[0]?.questions?.length > 1
                ? "max-h-[670px]"
                : "max-h-[560px]"
            } overflow-y-auto`}
          >
            <table className="w-full text-sm text-left text-gray-500 table-auto overflow-scroll">
              <thead
                style={{ color: studentTheme.text_color }}
                className="text-base text-[#2E2E2E] bg-[#EFF1F0] text-center"
              >
                <tr className="min-h-[30px] text-[13px] font-[500]">
                  {TEST_REVIEW_TABLE_HEAD.map((label, index) => (
                    <th
                      key={`head_${index}`}
                      scope="col"
                      className={`px-0 border-x-4 border-white whitespace-nowrap ${
                        index > 6 ? "bg-secondary-disabled" : ""
                      }`}
                    >
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {testReviews &&
                  !!testReviews.length &&
                  testReviews.map((review, index) => (
                    <tr
                      style={{ color: studentTheme.text_color }}
                      key={`body_${index}`}
                      className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E] h-[50px]"
                    >
                      <td className="bg-warning-lighter text-center border-x-4 border-white">
                        {index + 1}
                      </td>
                      <td className="py-4 text-center">
                        <AnswerType
                          type={
                            useableQuestion(review?.questions)
                              ? review.questions[0]?.is_correct
                              : null
                          }
                        />
                      </td>
                      <td className="py-4 text-center">
                        {review?.score || "0"}
                      </td>
                      <td className="py-4 text-center justify-center">
                        {useableQuestion(review?.questions) &&
                        !review?.review ? (
                          <div className="flex justify-center items-center">
                            <Unknown />
                          </div>
                        ) : (
                          "要"
                        )}
                      </td>
                      <td
                        style={{
                          color: studentTheme.main_color_first,
                        }}
                        className="py-4 text-center text-primary text-[12px] leading-[100%] cursor-pointer"
                        onClick={() =>
                          navigate(
                            `${
                              PATH_COURSE.EXPLANATION
                            }?${explanationSearchParam}#question_${index + 1}`
                          )
                        }
                      >
                        解説
                      </td>
                      <td className="py-4 text-center">
                        {formatTotalTestTime(review?.time)}
                      </td>
                      {useableQuestion(review?.questions) &&
                        review.questions?.slice(1)?.map((question, index) => (
                          <td
                            key={`question_${index}`}
                            className="py-4 text-center"
                          >
                            <div className="text-center">
                              <AnswerType type={question?.is_correct} />
                              {question?.is_correct !== null && (
                                <div className="text-[10px] leading-[100%] mt-[4px]">
                                  {formatTotalTestTime(question?.time)}
                                </div>
                              )}
                            </div>
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="flex items-center bg-warning-lighter h-[50px] pr-[13px] border border-x-4 border-x-transparent border-y-1 border-secondary-extralight mb-[20px] ml-[4px]">
            <div
              style={{ color: studentTheme.text_color }}
              className="pl-[5px]"
            >
              計
            </div>
            <div className="pl-[100px]">
              {testStatistic.score || "-"}
              <span className="font-[700] text-[15px] leading-[100%] text-secondary-light">
                点/{testStatistic.total_score || "-"}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="mb-[20px] bg-warning-lighter p-[5px] !pl-[10px]">
          {MESSAGE_COMMON.NO_DATA_FOUND}
        </div>
      )}
    </div>
  );
};

export default TestReviewTable;
