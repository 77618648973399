import React, { ChangeEvent, useEffect, useState } from 'react';
import { makeRequest } from '../../../../services/axios/axios';
import { swalClose, swalError, swallConfirm, swalSuccess, } from '../../../../services/helpers/swal';
import { paramizeObject } from '../../../../services/helpers/parseData';
import { API } from '../../../../services/constants/route/api';
import { File, Folder } from '../../../../types/admin/folder';
import { HiPlus } from 'react-icons/hi';
import CircularProgress from '../../../commons/charts/CircularProgress';
import BaseModal from '../../commons/BaseModal';
import FileTitle from './FileTitle';
import FileTable from './FileTable';
import FileUploadFolder from '../../commons/FileUploadFolder';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import FolderFileFilter from '../FolderFileFilter';
import FolderBase from '../../../../pages/admin/Folder';
import { ROUTE } from "../../../../services/constants/route/router";
import { useNavigate } from "react-router-dom";

type FilterType = {
  limit: number;
  page: number;
  sortType?: number;
  resource_folder_id: string | null;
};
const megabytesToGigabytes = (megabytes: number) => {
  return megabytes / 1024;
};
const FileList = () => {
  const { getParamValue } = useObjectRoutes();
  const folderId = getParamValue('folder_id');
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    page: 1,
    sortType: 1,
    resource_folder_id: folderId,
  });
  const [folderInfo, setFolderInfo] = useState<Folder>();
  const [folderName, setFolderName] = useState<string>('');
  const [moveFolderId, setMoveFolderId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [usedStorage, setUsedStorage] = useState<number>(0);
  const [totalStorage, setTotalStorage] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenMoveModal, setIsOpenMoveModal] = useState<boolean>(false);
  const [folderList, setFolderList] = useState<Folder[]>([]);
  const [fileInfo, setFileInfo] = useState<File | null>(null);
  let navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const result = await makeRequest({
        method: 'get',
        url: `${API.ADMIN_FOLDER.LIST}?sortType=1&limit=1000`,
      });

      if (!result.data) {
        swalError();
      }

      setFolderList(result.data.resource_folders);
      swalClose();
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      // swalLoading();
      const [result, storageResults] = await Promise.all([
        makeRequest({
          method: 'get',
          url: `${API.ADMIN_FOLDER.FILE}?${paramizeObject(filter)}`,
        }),
        makeRequest({
          method: 'get',
          url: `${API.ADMIN_FOLDER.CAPACITY}`,
        }),
      ]);

      if (result.data) {
        checkSelectAll(result.data.resources);
        setFolderInfo(result.data.folder_info);
        setFiles(result.data.resources);
        setTotalPages(result.data.total_page);
      }

      if (storageResults.data) {
        setUsedStorage(megabytesToGigabytes(storageResults.data['used_storage']));
        setTotalStorage(storageResults.data.total);
      }

      if (!result.data) {
        navigate(`/${ROUTE.ADMIN_PREFIX}/${ROUTE.FOLDER_MANAGEMENT.DEFAULT}/folder`, {replace: true});
      }

      if (storageResults.data === null) {
        return swalError()
      }
      swalClose();
    };

    const timer = setTimeout(() => {
      fetchFiles();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const resetTable = () => {
    setSelectedIds([]);
    setSelectAll(false);
    setCurrentPage(1);
  };
  const handleCreateFolder = async () => {
    // swalLoading();
    const result = await makeRequest({
      method: 'post',
      url: `${API.ADMIN_FOLDER.CREATE}`,
      data: { name: folderName },
    });

    if (!result.status) return swalError();

    swalSuccess();
    setFolderName('');
    setIsOpenModal(false);
  };
  const checkSelectAll = (currentFiles: File[]) => {
    let currentPageFileIds = currentFiles.map((file) => file.id);
    let allCurrentPageFilesSelected = currentPageFileIds.every((id) => selectedIds.includes(id));
    if (allCurrentPageFilesSelected) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedIds(files.map((s) => s.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleFileOverwrite = (file: File) => {
    setFileInfo(file);
    setIsOpenModal(true);
  };

  const handleCreateFile = () => {
    setFileInfo(null);
    setIsOpenModal(true);
  };
  const deleteSelected = async () => {
    if (selectedIds.length === 0) {
      return;
    }
    swallConfirm(
      async () => {
        // swalLoading();
        const result = await makeRequest({
          method: 'delete',
          url: API.ADMIN_FOLDER.DELETE_FILE,
          data: { resource_ids: selectedIds },
        });
        if (!result.status) {
          return swalError();
        }
        resetTable();
        swalSuccess();
        changePaginate(1);
      },
      'チェックしたファイルを<br/>削除してよろしいですか？',
      'このファイルを使用している場合、<br/>ページ内の画像が表示されない、または<br/>リンク切れが発生するため<br/>確認のうえ削除してください',
    );
  };

  const moveSelected = async () => {
    if (selectedIds.length === 0) {
      return;
    }
    swallConfirm(
      async () => {
        // swalLoading();
        const result = await makeRequest({
          method: 'patch',
          url: API.ADMIN_FOLDER.MOVE_FILE,
          data: {
            resource_ids: selectedIds,
            to_resource_folder_id: moveFolderId,
          },
        });
        if (!result.status) {
          return swalError();
        }
        setMoveFolderId('');
        resetTable();
        swalSuccess();
        setIsOpenMoveModal(false);
        changePaginate(1);
      },
      'チェックしたファイルの<br/>カテゴリを移動して</br>よろしいですか？',
      'このファイルを使用している場合、</br>埋め込みタグが変更となります</br>ページ内の画像が表示されない、または</br>リンク切れが発生するため</br>確認のうえ移動してください',
      '移動する',
    );
  };

  const toggleSelect = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((sId) => sId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const isFileSelected = (id: number) => selectedIds.includes(id);

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  const changeHandle = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <FolderBase
      title={`ファイル管理 ｜ カテゴリ一覧 ${folderInfo?.name ? ` ｜ ${folderInfo?.name}` : ''}`}
    >
      <div>
        <div className="flex justify-between w-full mt-[40px] mb-[10px] border-b">
          <div>
            <div className="text-[12px] font-[400] mb-[15px] leading-[190%]">
              動画や写真ファイルを分類して保存するための「カテゴリ（フォルダ）」を作成します。
              <br/>
              カテゴリを作成した後にファイルをアップロートすることができます。
              <br/>
              ※カテゴリ内にカテゴリは作成できません
              <br/>
            </div>
            <button
              className={`bg-primary text-white text-[14px] font-[700] leading-[17px] flex items-end py-[10px] px-[30px] rounded-[10px] min-h-[34px] cursor-pointer bg-primary`}
              onClick={() => handleCreateFile()}
            >
              <HiPlus size={20}/>
              <div className="ml-[8px]">新規ファイルをアップロード</div>
            </button>
          </div>
          {!!totalStorage ? (
            <div
              className="flex w-full max-w-[280px] h-[96px] border rounded-[5px] items-center justify-center mb-[60px]">
              <div className="w-full max-w-[61px] h-[61px] relative">
                <div
                  className="absolute top-[3px] left-0 w-full h-[61px] rounded-full bg-white flex items-center justify-center">
                  <div className="text-center">
                    <div className={`text-[12px] font-[500] leading-[100%] text- mb-[8px]`}>
                      <span className="text-[16.8px] font-[700] leading-[100%] with_roboto text-primary">
                        {((Number(usedStorage.toFixed(2)) / totalStorage) * 100).toFixed(2)}
                      </span>
                      <span className="text-[16.8px] font-[700] leading-[100%] with_roboto text-primary">
                      </span>
                      <span className="text-[8.4px] font-[500] text-primary leading-[100%]">%</span>
                    </div>
                  </div>
                </div>
                <CircularProgress
                  blue={true}
                  sqSize={80}
                  strokeWidth={8}
                  percentage={(usedStorage / totalStorage) * 100}
                  className="absolute top-[-10px] left-[-10px]"
                />
              </div>
              <div className="text-[12px] font-[500] ml-[16px]">
                ストレージ容量：
                <span className="text-[16px] text-primary">{`${totalStorage}GB`}</span>
                <br/>
                {`使用中：${usedStorage.toFixed(2)}GB`}
              </div>
            </div>
          ) : (
            <div className="skeleton w-[280px] h-[96px] rounded-[5px] bg-secondary-lighter"></div>
          )}
        </div>
        <FileTitle folderTitle={folderInfo?.name}/>
        <FolderFileFilter
          changeHandle={changeHandle}
          deleteSelected={deleteSelected}
          setIsOpenMoveModal={setIsOpenMoveModal}
        />

        <FileTable
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          toggleSelect={toggleSelect}
          selectAll={selectAll}
          toggleSelectAll={toggleSelectAll}
          isFileSelected={isFileSelected}
          files={files}
          totalPages={totalPages}
          handleFileOverwrite={handleFileOverwrite}
        />
        {isOpenModal && (
          <BaseModal
            setIsOpen={setIsOpenModal}
            width={1010}
            height={fileInfo ? 740 : 616}
            justifyDirection="end"
            submitLabel="アップロード"
          >
            <FileUploadFolder
              setIsOpen={setIsOpenModal}
              folderId={folderId}
              resetTable={resetTable}
              changePaginate={changePaginate}
              folderCode={folderInfo?.code}
              fileInfo={fileInfo}
              usedStorage={usedStorage}
              totalStorage={totalStorage}
            />
          </BaseModal>
        )}
        {isOpenMoveModal && (
          <BaseModal
            setIsOpen={setIsOpenMoveModal}
            width={1010}
            height={616}
            justifyDirection="end"
            onClick={moveSelected}
            submitLabel="移動する"
          >
            <div className="pt-[100px] pb-[167px] flex items-center flex-col">
              <div
                className="text-center text-[16px] font-[700] leading-[100%] tracking-[0.02em] text-primary mb-[24px]">
                選択したファイルを別のカテゴリへ移動します
              </div>
              <div className="w-full max-w-[301px] min-h-[131px] mb-[40px]">
                {selectedIds &&
                  files
                    .filter((file) => selectedIds.includes(file.id))
                    .map((selectedFiles) => (
                      <div className="mb-[12px] font-[400] text-[16px] ml-[20.5px] mt-[10px] mb-[25px]">
                        {selectedFiles.origin_filename}
                      </div>
                    ))}
                <div className="w-full max-w-[301px] h-[34px] bg-secondary-extralight">
                  <div className="font-[500] text-[14px] text-secondary py-2 px-1 text-center">
                    現在のカテゴリ：
                    {folderInfo && (
                      <span className="font-[400] text-[14px] text-black">{folderInfo.name}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full max-w-[380px]">
                <div className="font-[400] text-[14px] mb-[8px]">移動先のカテゴリを選択</div>
                <select
                  className="h-[50px] pr-[33px] pl-[15.5px] mb-[20px] appearance-none text-secondary-dark font-[400] text-[12px] leading-[100%] w-full overflow-y-auto"
                  name="folder_id"
                  onChange={(e) => setMoveFolderId(e.target.value)}
                >
                  <option value="">選択してください</option>
                  {folderList.map((folder) => (
                    <option key={folder.id} value={folder.id}>
                      {folder.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </BaseModal>
        )}
      </div>
    </FolderBase>
  );
};

export default FileList;
