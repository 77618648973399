import { getTabObj } from "../../../helpers/parseData";
import { PATH_ADMIN_COUPON, PATH_ADMIN_PRODUCT } from "../../route/router";

const PRODUCT_TAB_ITEM = {
  PRODUCT_LIST: "商品一覧",
  PRODUCT_REGISTRATION: "商品新規追加",
  COUPON_LIST: "クーポン管理",
  COUPON_REGISTRATION: "クーポン新規追加",
};

const PRODUCT_TAB_DATA = [
  getTabObj(PRODUCT_TAB_ITEM.PRODUCT_LIST, PATH_ADMIN_PRODUCT.DEFAULT),
  getTabObj(PRODUCT_TAB_ITEM.PRODUCT_REGISTRATION, PATH_ADMIN_PRODUCT.CREATE),
  getTabObj(
    PRODUCT_TAB_ITEM.COUPON_LIST,
    PATH_ADMIN_COUPON.DEFAULT
  ),
  getTabObj(
    PRODUCT_TAB_ITEM.COUPON_REGISTRATION,
    PATH_ADMIN_COUPON.CREATE
  ),
];

const EXPIRY_DATE = [
  {
    label: "無限期間",
    display: "",
    value: 0
  },
  {
    label: "購入後の日数",
    display: "日",
    value: 1
  },
  {
    label: "購入後の週数",
    display: "週",
    value: 2
  },
  {
    label: "購入後の月数",
    display: "月",
    value: 3
  },
  {
    label: "購入後の年数",
    display: "年",
    value: 4
  },
];

const RESOURCE_TYPE = {
  IMAGE: 5,
  VIDEO: 2,
  URL: 3,
};

const PRODUCT_RESOURCE_TYPE_DATA = [
  { label: "写真（jpg，png）", value: 5 },
  { label: "動画", value: 2 },
  { label: "youtube,Vimeo", value: 3 },
];

const EXPIRY_DATE_DEFAULT = {
  label: "無限期間",
  display: "",
};

const INVENTORY_UNLIMITED = 0;
const INVENTORY_LIMITED = 1;
const VALIDITY_PERIOD_TYPE = ["無し", "日", "週", "月", "年"];

export {
  PRODUCT_TAB_DATA,
  PRODUCT_TAB_ITEM,
  EXPIRY_DATE,
  RESOURCE_TYPE,
  PRODUCT_RESOURCE_TYPE_DATA,
  EXPIRY_DATE_DEFAULT,
  VALIDITY_PERIOD_TYPE
};
