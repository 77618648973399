import React from "react";
import RichTextEditor from "../../commons/form/TextEditor";
const TextEditorDisplay = ({ htmlData, className }: { htmlData: string, className?: string }) => {
  return (
    <div className={className}>
      <RichTextEditor value={htmlData} toolbarId="main" isReadOnly/>
    </div>
  );
};

export default TextEditorDisplay;
