import React from 'react';
import StudentBase from '.';
import StudentCreate from '../../../components/admin/student/StudentCreate';

const StudentCreatePage = () => {
  return (
    <StudentBase title="受講者管理 ｜ 受講者新規追加">
      <StudentCreate/>
    </StudentBase>
  );
};

export default StudentCreatePage;
