import { SetStateAction, Dispatch, createContext, useState, useContext } from "react";
import { ChildrenWithProps } from "../types/globals";

type SidebarContextType = {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
};

const SidebarContext = createContext<SidebarContextType>({
  showSidebar: true,
  setShowSidebar: () => {},
});

const SidebarContextProvider = ({ children }: ChildrenWithProps) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  return (
    <SidebarContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContextProvider;
export const useSidebarContextProvider = () => useContext(SidebarContext);