import { AiOutlineArrowRight } from "react-icons/ai";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ReviseHistory } from "../../../../types/student/studyToolRevise";
import { PATH_COURSE } from "../../../../services/constants/route/router";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

const ReviseHistoryItem = ({ data }: { data: ReviseHistory }) => {
  const navigate = useNavigate();
  const { studentTheme } = useStudentThemeContext();

  const formatDateTime = (inputDate?: string) => {
    if (!inputDate) return "";
    return dayjs(inputDate).format("MM月DD日 HH:mm");
  };

  const redirectParams = {
    unit_id: data.test_id,
    course_id: data.course_id,
  };

  const redirectTarget = `${PATH_COURSE.UNIT_DETAIL}?${paramizeObject(
    redirectParams
  )}`;

  return (
    <div className="flex min-h-[71px] mb-[25px]">
      <div className="font-[700] text-[10px] text-secondary with_roboto leading-[100%] w-full max-w-[100px] flex pl-[14px] pt-[10px]">
        <div className="mt-[10px]">
          {formatDateTime(data.updated_at as string)}
        </div>
      </div>
      <div className="w-full mr-[21px] pt-[5px]">
        <div className="text-[13px] font-[400] w-full px-[16px] py-[12px] justify-between bg-[#FEF9EF] rounded-[10px] max-w-[610px] min-h-[45px] border border-danger-light">
          <div className="flex w-full justify-between">
            <div
              className="mr-[15px] text-[12px] font-[400] truncate"
              title={data.title}
            >
              {data.title}
            </div>
            <div className="text-[8.5px] tracking-[0.17px] text-secondary">
              <span
                style={{ color: studentTheme.main_color_second }}
                className="text-warning text-[17px] font-[700] tracking-[0.34px] mr-[4px]"
              >
                {data.correct_question_quantity || 0}
              </span>
              /{data.question_quantity || 0}
            </div>
          </div>

          <div className="flex justify-end">
            <button
              className={`bg-white border border-danger-light text-secondary text-[9.7px] font-[500] leading-[14px] flex py-[7px] rounded-[15px] min-h-[28px] w-full max-w-[138px] cursor-pointer items-center justify-center mt-[13px]`}
              onClick={() => navigate(redirectTarget)}
            >
              同じテストを受ける
              <AiOutlineArrowRight className="ml-[5px]" size={12} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviseHistoryItem;
