import React, { useState } from "react";
import "../../../assests/styles/modal.css";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalMessage, swalSuccess } from "../../../services/helpers/swal";
import { User } from "../../../types/user";
import Loading from "../../commons/icons/Loading";
import { useStorage } from "../../../hooks/useStorage";
import { StudentThemeType } from "../../../types/admin/student_theme";

type Props = {
  email: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitData: React.Dispatch<React.SetStateAction<User>>;
  //will remove when context applied
  userData: User;
  setUserData: React.Dispatch<React.SetStateAction<User>>;
  resubmitCode: (email?: string) => Promise<void>;
};

const VerifyingEmailModal = ({
  email,
  setIsOpen,
  userData,
  setUserData,
  setSubmitData,
  resubmitCode,
}: Props) => {
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { getLocalStorage } = useStorage();
  const studentTheme: StudentThemeType = getLocalStorage("theme");

  const handleSubmit = async () => {
    setIsSubmitting(true);
    // swalLoading();

    const result = await makeRequest({
      method: "post",
      url: API.AUTH.VERIFY_EMAIL,
      data: { code: verifyCode },
    });

    if (result.error) {
      setIsSubmitting(false);
      return swalMessage(
        "エラー",
        `${result.error[Object.keys(result.error)[0]]}`,
        "error"
      );
    }

    setIsSubmitting(false);
    swalSuccess();
    setSubmitData({ email: "" });
    setIsOpen(false);
  };

  return (
    <section>
      <div className="dark_bg" onClick={() => setIsOpen(false)} />
      <div className="centered z-[1000]">
        <div className="modal">
          <div
            style={{ color: studentTheme.text_color }}
            className="w-[680px] min-h-[470px]"
          >
            <div className="flex justify-center mb-[51px]">
              <div className="w-full max-w-[500px] min-h-[40px] text-[12px] leading-[140%] flex justify-center items-center bg-danger-lighter mt-[47px] rounded-[5px]">
                新しいメールアドレスからのログインのため、本人確認をお願いします
              </div>
            </div>
            <div className="text-center">
              <div className="font-[700] text-[18px] leading-[100%] tracking-[0.02em] mb-[30px]">
                確認コードを入力
              </div>

              <div className="flex justify-center mb-[31px]">
                <div className="w-[450px] text-[14px] leading-[150%] px-[30px]">
                  {email}　宛に確認コードを送信しました。
                  メッセージに記載された６桁の数字を入力してください。
                </div>
              </div>

              <div className="">
                <input
                  type="text"
                  name="code"
                  id=""
                  onChange={(e) => setVerifyCode(e.target.value)}
                  className="max-w-[430px] h-[40px] border !border-secondary-light mb-[18px] pl-[10px]"
                />
                <div
                  style={{ color: studentTheme.main_color_first }}
                  onClick={() => resubmitCode(email)}
                  className="text-center text-primary text-[14px] leading-[150%] mb-[52px] cursor-pointer"
                >
                  確認コードを再送する
                </div>
              </div>
            </div>
            <div className="flex gap-[10px] justify-center w-full bg-secondary-extralight items-center h-[80px] absolute bottom-0 rounded-b-[10px]">
              <button
                className="bg-white text-secondary text-[14px] leading-[20px] w-fit px-[24px] py-[12px] rounded-[5px]"
                onClick={() => setIsOpen(false)}
              >
                キャンセル
              </button>
              <button
                className="bg-danger text-white text-[14px] leading-[20px] w-full max-w-[104px] h-[44px] rounded-[5px] verify_email_btn"
                onClick={handleSubmit}
              >
                {isSubmitting ? <Loading /> : "送信する"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyingEmailModal;
