import React, { HTMLProps } from "react";
import { MdOutlineCreate } from "react-icons/md";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const EditButton = ({
  label,
  className = '',
  ...props
}: {
  className?: string
  label: string;
} & React.HTMLProps<HTMLDivElement>) => {
  const { studentTheme } = useStudentThemeContext();
  return (
    <div {...props}>
      <div
        style={{ backgroundColor: studentTheme.main_color_first }}
        className={`${className} cursor-pointer text-white font-[700] text-[12px] leading-[17px] tracking-[0.02em] bg-primary rounded-[20px] flex justify-center items-center w-fit pl-[22px] pr-[20px] h-[28px] gap-[9px]`}
      >
        <MdOutlineCreate size={17} />
        {label}
      </div>
    </div>
  );
};

export default EditButton;
