import React from "react";

const Material = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 2C6.25 1.58579 6.58579 1.25 7 1.25H16.5C16.6989 1.25 16.8897 1.32902 17.0303 1.46967L21.5303 5.96967C21.671 6.11032 21.75 6.30109 21.75 6.5V19C21.75 19.4142 21.4142 19.75 21 19.75C20.5858 19.75 20.25 19.4142 20.25 19V6.81066L16.1893 2.75H7C6.58579 2.75 6.25 2.41421 6.25 2ZM7 9.25C6.58579 9.25 6.25 9.58579 6.25 10C6.25 10.4142 6.58579 10.75 7 10.75H10C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25H7ZM6.25 14C6.25 13.5858 6.58579 13.25 7 13.25H8C8.41421 13.25 8.75 13.5858 8.75 14C8.75 14.4142 8.41421 14.75 8 14.75H7C6.58579 14.75 6.25 14.4142 6.25 14ZM6.25 18C6.25 17.5858 6.58579 17.25 7 17.25H14C14.4142 17.25 14.75 17.5858 14.75 18C14.75 18.4142 14.4142 18.75 14 18.75H7C6.58579 18.75 6.25 18.4142 6.25 18ZM3.75 6.5C3.75 6.08579 4.08579 5.75 4.5 5.75H13.25V8.4C13.25 9.14558 13.8544 9.75 14.6 9.75H17.25V20.5C17.25 20.9142 16.9142 21.25 16.5 21.25H4.5C4.08579 21.25 3.75 20.9142 3.75 20.5V6.5ZM18.7489 8.69514C18.7496 8.71289 18.75 8.73069 18.75 8.74853V20.5C18.75 21.7426 17.7426 22.75 16.5 22.75H4.5C3.25736 22.75 2.25 21.7426 2.25 20.5V6.5C2.25 5.25736 3.25736 4.25 4.5 4.25H14.2515C14.2693 4.25 14.2871 4.25035 14.3049 4.25106C14.321 4.25035 14.3372 4.25 14.3536 4.25C14.6462 4.25 14.9269 4.36627 15.1339 4.57322L15.2061 4.64541L18.3546 7.79393C18.3548 7.79416 18.355 7.79438 18.3553 7.79461L18.4268 7.86612C18.6337 8.07307 18.75 8.35377 18.75 8.64645C18.75 8.66276 18.7496 8.679 18.7489 8.69514Z"
        fill={props.color || "#BEBEBE"}
      />
    </svg>
  );
};

export default Material;
