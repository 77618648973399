import StudyToolBase from ".";
import MemoBoard from "../../../../components/student/Memo/MemoBoard";

const MemoList = () => {
  return (
    <StudyToolBase>
      <MemoBoard />
    </StudyToolBase>
  );
};

export default MemoList;
