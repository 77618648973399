import StudentLayout from "../../../layouts/student";
import LectureTop from "../../../components/student/Course/Lectures/LectureTop";

const Lectures = () => {
  return (
    <StudentLayout gap>
      <LectureTop/>
    </StudentLayout>
  );
};

export default Lectures;
