import CourseBase from '.';
import CourseList from '../../../components/admin/Course/List/List';
import TableCommonAction from '../../../components/admin/commons/TableCommonAction';
import CourseListProvider from '../../../context/CourseContext';
import { useObjectRoutes } from '../../../hooks/useObjectRoutes';
import { PATH_ADMIN_COURSE } from '../../../services/constants/route/router';
import '../../../assests/styles/course.css';

const CourseListPage = () => {
  const { navigate } = useObjectRoutes();

  return (
    <CourseBase title="学習コース管理 ｜ 学習コース一覧">
      <TableCommonAction
        className="mb-[20px]"
        createBtnLabel="新規コース登録"
        onCreate={() => navigate(PATH_ADMIN_COURSE.COURSE_REGISTRATION)}
      />
      <CourseListProvider>
        <CourseList />
      </CourseListProvider>
    </CourseBase>
  );
};

export default CourseListPage;
