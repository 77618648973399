import React from "react";
import LearningListItem from "./ListItem";
import { Unit } from "../../../types/student/unit";

const LearningList = ({
  unitLearningList,
  isMyPageTop,
}: {
  unitLearningList?: Unit[];
  isMyPageTop?: boolean;
}) => {
  return (
    <div
      className="w-full border border-danger-light rounded-[5px] mb-[15px] px-[21px] py-[12px]
                max-sm:border-none max-sm:bg-white max-sm:mb-0 max-sm:p-[16px] animate-[show_0.5s_ease-in-out]"
    >
      <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
        最近の学習
      </div>
      <div
        className={`max-h-[264px] ${
          unitLearningList && unitLearningList?.length > 3
            ? "overflow-y-scroll pr-[10px]"
            : ""
        }`}
      >
        {unitLearningList &&
          unitLearningList.map((learning) => (
            <LearningListItem
              key={learning.id}
              data={learning}
              isMyPageTop={isMyPageTop}
            />
          ))}
      </div>
    </div>
  );
};

export default LearningList;
