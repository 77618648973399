import React from "react";
import { HiPlus } from "react-icons/hi";
import RoundedButton from "../commons/RoundButton";
import { Link, useLocation } from "react-router-dom";
import { PATH_ADMIN_STUDENT } from "../../../services/constants/route/router";

const StudentTableCommonAction = ({
  ...props
}: React.HTMLProps<HTMLElement>) => {
  const currentLocation = useLocation();
  return (
    <section {...props}>
      <div className="flex justify-end gap-[10px] pr-[43px]">
        {currentLocation.pathname === "/admin/student/create" ? (
          // <RoundedButton isDisabled>
          //   <div className='flex items-center justify-center'>
          //     <HiPlus size={20} />
          //     <div className="ml-[12px]">新規受講者登録</div>
          //   </div>
          // </RoundedButton>
          <></>
        ) : (
          <Link to={PATH_ADMIN_STUDENT.CREATE} className="rounded-[15px]">
            <RoundedButton>
              <div className='flex items-center justify-center'>
                <HiPlus size={20} />
                <div className="ml-[12px]">新規受講者登録</div>
              </div>
            </RoundedButton>
          </Link>
        )}
        {/* TableActionButton hidden for later phases */}
        {/* <RoundedButton isDisabled>
          <IoMdDownload size={18} />
          <div className="ml-[7px]">CSVテンプレートをダウンロード</div>
        </RoundedButton>
        <RoundedButton>
          <TbFileDescription size={18} />
          <div className="ml-[10px]">CSVインポート</div>
        </RoundedButton>
        <RoundedButton>
          <IoMdExit size={20} />
          <div className="ml-[7px]">CSVエクスポート</div>
        </RoundedButton> */}
      </div>
    </section>
  );
};

export default StudentTableCommonAction;
