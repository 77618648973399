import { useState } from "react";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { formatNumberWithCommas, paramizeObject } from "../../../../services/helpers/parseData";
import { Order, OrderDetail, ReceiptSetting } from "../../../../types/student/order";
import Pagination from "./Pagination";
import BaseModal from "../../../admin/commons/BaseModal";
import ReceiptPreview from "../../../Export/ReceiptPreview";
import { swalError, swallConfirm } from "../../../../services/helpers/swal";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useNavigate } from "react-router-dom";
import { ORDER_STATUS } from "../../../../services/constants/admin/pages/purchaseHistory";
import "../../../../assests/styles/receiptPrint.css"

const PurchaseHistoryTable = ({
  handlePageChange,
  currentPage,
  orders,
  totalPages,
  receiptSettingData,
  setOrders,
  setReceiptSetting,
  setTotalPages,
  filter
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  orders: Order[];
  totalPages: number;
  receiptSettingData?: ReceiptSetting,
  setOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  setReceiptSetting: React.Dispatch<React.SetStateAction<ReceiptSetting | undefined>>;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  filter: any
}) => {
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [currentOrder, setCurrentOrder] = useState<Order>();
    const navigate = useNavigate()

    const handleDownloadPDF = async () => {
        window.print();
        if(currentOrder) {
            await request.post(
                API.ORDERS.PUBLIC_RECEIPT,
                {order_id: currentOrder.id},
                () => {
                    const fetcher = async () => {
                        await request.get(
                            `${API.ORDERS.PURCHASE_HISTORY}?${paramizeObject(filter)}`,
                            (data) => {
                                setOrders(data.orders);
                                setReceiptSetting(data.receipt_setting);
                                setTotalPages(data.total_pages);
                            }
                        );
                    }
                    fetcher();
                },
                () => {}
            );
        }
        setIsShowPreview(false);
    }

    const handleRePublicReceipt = (order: Order) => {
        swallConfirm(async () => {
            await request.post(
                API.ORDERS.REPUBLIC_RECEIPT,
                {order_id: order.id},
                () => {
                    const fetcher = async () => {
                        await request.get(
                            `${API.ORDERS.PURCHASE_HISTORY}?${paramizeObject(filter)}`,
                            (data) => {
                                setOrders(data.orders);
                                setReceiptSetting(data.receipt_setting);
                                setTotalPages(data.total_pages);
                            }
                        );
                    }
                    fetcher();
                },
                () => swalError()
            );
        }, "領収書を再発行しますか？", undefined, "再発行");
    }

    return (
        <>
            {isShowPreview &&
                <BaseModal 
                    setIsOpen={setIsShowPreview} 
                    justifyDirection="end"
                    submitLabel="PDFダウンロード"
                    onClick={handleDownloadPDF}
                >
                    <div className="mb-[0px] mt-[20px] flex justify-start w-[80%] m-auto">
                        <div className="w-fit pr-[20px] text-lg text-primary self-center">領収書の確認</div>
                        <div className="flex-1 bg-danger-lighter rounded-[2px] py-[10px] px-[15px] my-[7px] text-xs">
                            <p>実際のPDFとは文字のサイズなどが異なる場合がございます。</p>
                            <p>下の「PDFダウンロード」ボタンからダウンロードしてご確認ください</p>
                        </div>
                    </div>
                    <ReceiptPreview 
                        receiptSettingData={receiptSettingData}
                        receiptData={currentOrder}
                        otherStyle="w-[70%] mx-auto max-h-[80%] overflow-y-auto"
                    />
                </BaseModal>
            }
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
                    <tr className="min-h-[50px] text-[13px] font-[500]">
                    <th
                        scope="col"
                        className="2xl:px-[115px] xl:px-[100px] lg:px-[50px] px-[50px] py-[5px] border-x-4 border-white whitespace-nowrap"
                    >
                        購入講座名
                    </th>
                    <th
                        scope="col"
                        className="2xl:px-[30px] xl:px-[25px] lg:px-[15px] px-[15px]  py-[5px] border-x-4 border-white whitespace-nowrap"
                    >
                        購入日
                    </th>
                    <th
                        scope="col"
                        className="2xl:px-[30px] xl:px-[25px] lg:px-[15px] px-[15px] py-[5px] border-x-4 border-white whitespace-nowrap"
                    >
                        購入金額
                    </th>
                    <th
                        scope="col"
                        className="2xl:px-[65px] xl:px-[25px] lg:px-[15px] px-[15px] py-[5px] border-x-4 border-white whitespace-nowrap"
                    >
                        領収書
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {orders &&
                    orders.map((order) => (
                        <tr
                            key={order.id}
                            className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
                        >
                            <td className="px-6 py-4 whitespace-nowrap">
                                {order.order_details.map((order_detail:  OrderDetail) => (
                                    <div>{order_detail.product_name}</div>
                                ))
                                }
                            </td>
                            <td className="text-center whitespace-nowrap">
                                {formatDateTime(order?.created_at, TYPE_DATE_FORMAT.SHORT_DEFAULT)}
                            </td>
                            <td className="text-center whitespace-nowrap">
                                ￥{formatNumberWithCommas(order?.total)}
                            </td>
                            <td className="">
                                {/* download button */}
                                { order.status === ORDER_STATUS.PAID ?
                                    <div className="flex items-center justify-center">
                                        {!order?.is_receipt_public ?
                                            <button 
                                                className="w-full max-w-[160px] min-h-[22px] bg-[#E8C998] rounded-[5px] text-white px-[7.5px] pt-[3px] pb-[5px] font-500 font-normal text-[15px]"
                                                onClick={() => {
                                                    setCurrentOrder(order)
                                                    setIsShowPreview(true)
                                                }}
                                            >
                                                領収書ダウンロード
                                            </button> :
                                            <button 
                                                className="w-full max-w-[100px] min-h-[22px] bg-secondary rounded-[5px] text-white px-[7.5px] pt-[3px] pb-[5px] font-500 font-normal text-[15px]"
                                                onClick={() => {
                                                    setCurrentOrder(order);
                                                    handleRePublicReceipt(order);
                                                }}
                                            >
                                                再発行する
                                            </button>                    
                                        } 
                                    </div> : 
                                    <div className="flex items-center justify-center">ー</div>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
            {totalPages !== 0 && (
                <Pagination
                totalItems={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                />
            )}
        </>
    );
};

export default PurchaseHistoryTable;
