import classNames from "classnames";
import { MdRemove } from "react-icons/md";

interface ColorOptionProps {
  color: string;
  wrapperClassName?: string;
  onChange: (color: string) => void;
  handleRemove: (color: string) => void;
}

function ColorOption({
  color,
  onChange,
  wrapperClassName,
  handleRemove,
}: ColorOptionProps) {
  return (
    <div className={classNames(wrapperClassName)}>
      <div
        className="round cursor-pointer"
        style={{ backgroundColor: color }}
        onClick={() => onChange(color)}
      />
      <div
        className="absolute top-[-5px] left-[20px] text-[10px] w-[15px] h-[15px] bg-white rounded-full border cursor-pointer hover:bg-secondary-light"
        onClick={() => handleRemove(color)}
      >
        <div className="flex justify-center items-center">
          <MdRemove size={12} className="text-center" />
        </div>
      </div>
    </div>
  );
}

export default ColorOption;
