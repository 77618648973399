import {
  PATH_ADMIN_CHAPTER,
  PATH_ADMIN_COURSE,
  PATH_ADMIN_LECTURE,
  PATH_ADMIN_SECTION,
  PATH_ADMIN_STUDENT,
  PATH_ADMIN_UNIT,
  PATH_ADMIN_FOLDER,
  PATH_ADMIN_PRODUCT,
  PATH_ADMIN_COUPON,
  PATH_ADMIN_TERMS_OF_USE,
} from './router';

const DEFAULT_PREFIX_ROUTE = {
  MYPAGE: '/my-page',
  STUDENT: '/student',
  ADMIN: '/admin',
  SECTION: '/section',
  COURSE: '/course',
  LECTURE: '/lecture',
  CHAPTER: '/chapter',
  UNIT: '/unit',
  TEST: '/test',
  MEMO: '/memo',
  REPORT: '/report',
  SCHEDULE: '/schedule-event',
  EVENT_TYPE: '/event-type',
  STUDENT_ACTIVITY_LOG: '/student-activity-log',
  REVISION_REVIEW: '/revision-review',
  PRODUCT: '/product',
  CART: '/cart',
  PAYMENT: '/payment',
  POLICY: '/policy',
  LOGIN_SETTING: '/login-setting',
  STUDENT_THEME: '/student-theme-setting',
  ORDER: '/orders'
};

const API = {
  AUTH: {
    VERIFY_DOMAIN: '/verify-domain',
    REGISTER: '/register',
    LOGIN: '/login',
    LOGOUT: '/logout',
    SEND_EMAIL_FORGOT_PASSWORD: '/send-email-forgot-password',
    SOCIAL_LOGIN: '/social-login',
    CHANGE_PWD: '/update-pwd',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_EMAIL_REGISTER: '/verify-email-for-register',
    COMPLETE_STUDENT_REGISTRATION: '/complete-student-registration',
    CANCEL_STUDENT_REGISTRATION: '/cancel-student-registration',
    CHECK_STUDENT_NOT_VERIFIED: '/check-student-not-verified',
    RESEND_VERIFY_EMAIL: '/resend-verify-email-for-register',
    VERIFY_PALETTE_LOGIN: '/login-with-palette-token',
    VERIFY_CHANGE_EMAIL: '/verify-change-email',
    RESEND_VERIFY_CHANGE_EMAIL: '/resend-verify-change-email'
  },
  USER: {
    INFO: '/user',
  },
  MYPAGE: {
    COURSE_LIST: `${DEFAULT_PREFIX_ROUTE.COURSE}/list-course-of-current-user`,
    GET_MYPAGE_STATISTICS: `${DEFAULT_PREFIX_ROUTE.MYPAGE}/statistical`,
    GET_MYPAGE_UNIT_DOING: `${DEFAULT_PREFIX_ROUTE.MYPAGE}/get-list-unit-doing-status-of-user`,
    LATEST_COURSE_LIST: `${DEFAULT_PREFIX_ROUTE.MYPAGE}/get-list-course-in-my-page-top`,
  },
  PROFILE: {
    UPDATE_AVATAR: `${DEFAULT_PREFIX_ROUTE.STUDENT}/update-profile-avatar`,
  },
  UNIT: {
    DETAIL: `${DEFAULT_PREFIX_ROUTE.UNIT}/get-unit-data`,
    LIST_UNIT_IN_CHAPTER: `${DEFAULT_PREFIX_ROUTE.UNIT}/get-list-unit-of-chapter`,
    UNIT_PROGRESS: `${DEFAULT_PREFIX_ROUTE.CHAPTER}/get-chapter-progress-in-a-lecture`,
    UPDATE_USER_HISTORY: `${DEFAULT_PREFIX_ROUTE.UNIT}/update-user-study-history`,
  },

  STUDENT: {
    // /admin/student/...
    UPDATE_INFORMATION: `${DEFAULT_PREFIX_ROUTE.STUDENT}/update-student-information`,
    UPDATE_PROFILE_SETTING: `${DEFAULT_PREFIX_ROUTE.STUDENT}/update-student-profile-setting`,
    UPDATE_EMAIL: `${DEFAULT_PREFIX_ROUTE.STUDENT}/update-email`,
    UPDATE_PASSWORD: `${DEFAULT_PREFIX_ROUTE.STUDENT}/update-pwd`,
  },

  COURSE: {
    // /course/....
    GET_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/get-test-content`,
    SUBMIT_FINISH_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/submit-test`,
    SUBMIT_PAUSE_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/save-unfinished-test`,
    GET_PAUSED_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/get-continue-test`,
    GET_STATISTIC_TEST: `${DEFAULT_PREFIX_ROUTE.TEST}/get-test-round-data`,
    GET_LIST_TEST_HISTORY: `${DEFAULT_PREFIX_ROUTE.TEST}/get-list-test-round`,
    GET_LIST_TEST_HISTORY_ON_PAUSE: `${DEFAULT_PREFIX_ROUTE.TEST}/get-list-test-round-on-pause`,
    DELETE_TEST_CONTINUOUS_HISTORY: `${DEFAULT_PREFIX_ROUTE.TEST}/delete-test-round-on-pause`,
    GET_EXPLANATION: `${DEFAULT_PREFIX_ROUTE.TEST}/get-explanation-for-test`,
    COURSE_DETAIL: `${DEFAULT_PREFIX_ROUTE.COURSE}/detail-course`,
    COURSE_STATISTICS: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-course-top-statistical`,
    LECTURE_OF_CURRENT_COURSE: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-list-lecture-in-course-top`,
    LIST_UNIT_DOING: `${DEFAULT_PREFIX_ROUTE.UNIT}/get-list-unit-doing-status-in-a-course`,
    DELETE_TEST_HISTORY: `${DEFAULT_PREFIX_ROUTE.TEST}/delete-student-test-history`,
    UPDATE_RATE_DETAIL_TEST_HISTORY: `${DEFAULT_PREFIX_ROUTE.TEST}/update-rate-detail-test-history`,
    GET_LIST_STUDY_HISTORY: `${DEFAULT_PREFIX_ROUTE.COURSE}/list-study-history-of-user`,
    GET_LIST_COURSE_STUDY_TOOL: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-list-course-study-tool`,
    GET_LIST_COURSE_FOR_COURSE_SHOP: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-list-course-for-course-shop`,
    GET_LIST_COURSE_OF_USER: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-list-course-of-user`,
    GET_LIST_COURSE_LATEST: `${DEFAULT_PREFIX_ROUTE.COURSE}/get-list-course-latest`
  },

  LECTURE: {
    CHAPTER_OF_CURRENT_LECTURE: `${DEFAULT_PREFIX_ROUTE.LECTURE}/get-list-chapter-in-lecture-top`,
    LECTURE_DETAIL: `${DEFAULT_PREFIX_ROUTE.LECTURE}/detail-lecture`,
    LECTURE_STATISTICS: `${DEFAULT_PREFIX_ROUTE.LECTURE}/get-lecture-top-statistical`,
    GET_UNIT_IN_CHAPTER: `${DEFAULT_PREFIX_ROUTE.LECTURE}/get-list-unit-in-chapter`,
    CHECK_LECTURE_PURCHASED: `${DEFAULT_PREFIX_ROUTE.LECTURE}/check-lecture-purchased`
  },

  SCHEDULE: {
    UPSERT: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/upsert-event`,
    LIST: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/list-events`,
    LIST_EVENT_TYPE: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/list-event-type`,
    LIST_SCHEDULE_CALENDAR: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/list-schedule-event-calendar`,
    DELETE_SCHEDULE_EVENT: `${DEFAULT_PREFIX_ROUTE.SCHEDULE}/delete`,
  },

  MEMO: {
    GET_LIST_MEMO_FOLDER: `${DEFAULT_PREFIX_ROUTE.MEMO}/list-folder`,
    UPSERT_MEMO_FOLDER: `${DEFAULT_PREFIX_ROUTE.MEMO}/upsert-folder`,
    DELETE_MEMO_FOLDER: `${DEFAULT_PREFIX_ROUTE.MEMO}/delete-folder`,
    UPSERT_MEMO: `${DEFAULT_PREFIX_ROUTE.MEMO}/upsert-memo`,
    GET_MEMO_DETAIL: `${DEFAULT_PREFIX_ROUTE.MEMO}/get-memo`,
    GET_MEMO_IN_A_FOLDER: `${DEFAULT_PREFIX_ROUTE.MEMO}/get-memo-in-a-folder`,
    GET_MEMO_BY_ID: `${DEFAULT_PREFIX_ROUTE.MEMO}/get-memo-by-id`,
    DELETE_MEMO: `${DEFAULT_PREFIX_ROUTE.MEMO}/delete-memo`,
  },

  REPORT: {
    GET_STUDY_TIME: `${DEFAULT_PREFIX_ROUTE.REPORT}/get-time-learned-statistical`,
    GET_STUDY_PROCESS: `${DEFAULT_PREFIX_ROUTE.REPORT}/get-study-process-statistical`,
    GET_STUDY_RATIO: `${DEFAULT_PREFIX_ROUTE.REPORT}/get-study-course-ratio`,
  },

  REVISION_REVIEW: {
    GET_STUDY_REVISION_LIST: `${DEFAULT_PREFIX_ROUTE.REVISION_REVIEW}/get-study-revision-list`,
    GET_REVISE_HISTORY_LIST: `${DEFAULT_PREFIX_ROUTE.REVISION_REVIEW}/get-revise-history-list`,
  },

  STUDENT_ACTIVITY_LOG: {
    GET_STUDENT_ACTIVITY_LOG: `${DEFAULT_PREFIX_ROUTE.STUDENT_ACTIVITY_LOG}/list`,
  },

  PRODUCT: {
    LIST: `${DEFAULT_PREFIX_ROUTE.PRODUCT}/list`,
    DETAIL: `${DEFAULT_PREFIX_ROUTE.PRODUCT}/get-product-detail`,
  },

  CART: {
    LIST: `${DEFAULT_PREFIX_ROUTE.CART}/get-list-cart-item`,
    ADD_TO_CART: `${DEFAULT_PREFIX_ROUTE.CART}/add-to-cart`,
    MOVE_PRODUCT: `${DEFAULT_PREFIX_ROUTE.CART}/move-product-cart`,
    UPDATE_QUANTITY_PRODUCT: `${DEFAULT_PREFIX_ROUTE.CART}/update-product-cart-quantity`,
    DELETE_PRODUCT: `${DEFAULT_PREFIX_ROUTE.CART}/delete-product-cart`,
    ENTER_COUPON_CODE: `${DEFAULT_PREFIX_ROUTE.CART}/enter-coupon-code`
  },

  PAYMENT: {
    GET_URL_CHECKOUT: `${DEFAULT_PREFIX_ROUTE.PAYMENT}/get-url-checkout`,
    GET_PAYMENT_SETTING: `${DEFAULT_PREFIX_ROUTE.PAYMENT}/get-payment-setting`
  },

  POLICY: {
    GET_LIST_POLICY: `${DEFAULT_PREFIX_ROUTE.POLICY}/get-list-policy`,
    GET_DETAIL_POLICY: `${DEFAULT_PREFIX_ROUTE.POLICY}/get-detail-policy`,
  },

  LOGIN_SETTING: {
    GET_DETAIL: `${DEFAULT_PREFIX_ROUTE.LOGIN_SETTING}/get-detail`
  },

  STUDENT_THEME_SETTING: {
    GET_DETAIL: `${DEFAULT_PREFIX_ROUTE.STUDENT_THEME}/get-detail`
  },

  ADMIN_SECTION: {
    // /admin/section/...
    GET_SECTION_LIST: `${PATH_ADMIN_SECTION.DEFAULT}/list-section`,
    GET_MAINSECTION_LIST: `${PATH_ADMIN_SECTION.DEFAULT}/list-main-section`,
    GET_SUBSECTION_LIST: `${PATH_ADMIN_SECTION.DEFAULT}/list-sub-section`,
    UPDATE_SECTION: `${PATH_ADMIN_SECTION.DEFAULT}/update-section-order`,
    GET_SECTION_STUDENT: `${PATH_ADMIN_SECTION.DEFAULT}/section-student-info`,
    CREATE_SECTION: `${PATH_ADMIN_SECTION.DEFAULT}/create-main-section`,
    CREATE_SUBSECTION: `${PATH_ADMIN_SECTION.DEFAULT}/create-sub-section`,
    GET_SECTION_INFO: `${PATH_ADMIN_SECTION.DEFAULT}/section-info`,
    UPDATE_SECTION_INFO: `${PATH_ADMIN_SECTION.DEFAULT}/update-main-section`,
    UPDATE_SUBSECTION_INFO: `${PATH_ADMIN_SECTION.DEFAULT}/update-sub-section`,
    GET_MAIN_SECTION: `${PATH_ADMIN_SECTION.DEFAULT}/list-main-section`,
    DELETE_SECTION: `${PATH_ADMIN_SECTION.DEFAULT}/delete-section`,
    ADD_USERS_TO_SECTION: `${PATH_ADMIN_SECTION.DEFAULT}/add-users-to-section`,
    MOVE_USERS_TO_SECTION: `${PATH_ADMIN_SECTION.DEFAULT}/change-section-of-users`,
    REMOVE_USERS_TO_SECTION: `${PATH_ADMIN_SECTION.DEFAULT}/remove-users-from-section`,
    SEARCH_STUDENT: `${PATH_ADMIN_STUDENT.DEFAULT}/list-student-not-in-specific-section`,
  },

  ADMIN_STUDENT: {
    // /admin/student/...
    GET_STUDENT_LIST: `${PATH_ADMIN_STUDENT.DEFAULT}/list-students`,
    GET_STUDENT_DETAILS: `${PATH_ADMIN_STUDENT.DEFAULT}/detail-student`,
    CREATE_STUDENT: `${PATH_ADMIN_STUDENT.DEFAULT}/create-student`,
    UPDATE_STUDENT: `${PATH_ADMIN_STUDENT.DEFAULT}/update-student`,
    GET_SETTINGS: `${PATH_ADMIN_STUDENT.DEFAULT}/list-setting-student-info-display`,
    UPDATE_SETTINGS: `${PATH_ADMIN_STUDENT.DEFAULT}/update-setting-display-info`,
    DELETE_STUDENT: `${PATH_ADMIN_STUDENT.DEFAULT}/delete-user`,
  },

  ADMIN_COURSE: {
    // /admin/course/...
    LIST: `${PATH_ADMIN_COURSE.DEFAULT}/list-course`,
    LIST_COURSE_FOR_PRODUCT: `${PATH_ADMIN_COURSE.DEFAULT}/get-list-course-for-product`,
    DELETE: `${PATH_ADMIN_COURSE.DEFAULT}/delete-course`,
    CREATE: `${PATH_ADMIN_COURSE.DEFAULT}/create-course`,
    DETAIL: `${PATH_ADMIN_COURSE.DEFAULT}/detail-course`,
    UPDATE: `${PATH_ADMIN_COURSE.DEFAULT}/update-course`,
    UPDATE_ORDER: `${PATH_ADMIN_COURSE.DEFAULT}/update-order-course`,
  },

  ADMIN_LECTURE: {
    // /admin/lecture/...
    LIST: `${PATH_ADMIN_LECTURE.DEFAULT}/list-lecture`,
    LIST_FOR_PRODUCT: `${PATH_ADMIN_LECTURE.DEFAULT}/get-list-lecture-for-product`,
    DELETE: `${PATH_ADMIN_LECTURE.DEFAULT}/delete-lecture`,
    CREATE: `${PATH_ADMIN_LECTURE.DEFAULT}/create-lecture`,
    DETAIL: `${PATH_ADMIN_LECTURE.DEFAULT}/detail-lecture`,
    UPDATE: `${PATH_ADMIN_LECTURE.DEFAULT}/update-lecture`,
    UPDATE_ORDER: `${PATH_ADMIN_LECTURE.DEFAULT}/update-order-lecture`,
  },

  ADMIN_CHAPTER: {
    // /admin/chapter/...
    LIST: `${PATH_ADMIN_CHAPTER.DEFAULT}/list-chapter`,
    DELETE: `${PATH_ADMIN_CHAPTER.DEFAULT}/delete-chapter`,
    CREATE: `${PATH_ADMIN_CHAPTER.DEFAULT}/create-chapter`,
    DETAIL: `${PATH_ADMIN_CHAPTER.DEFAULT}/detail-chapter`,
    UPDATE: `${PATH_ADMIN_CHAPTER.DEFAULT}/update-chapter`,
    UPDATE_ORDER: `${PATH_ADMIN_CHAPTER.DEFAULT}/update-order-chapter`,
  },

  ADMIN_UNIT: {
    // /admin/unit/...
    LIST: `${PATH_ADMIN_UNIT.DEFAULT}/list-unit`,
    DELETE: `${PATH_ADMIN_UNIT.DEFAULT}/delete-unit`,
    CREATE: `${PATH_ADMIN_UNIT.DEFAULT}/create-unit`,
    CREATE_UNIT_TEST: `${PATH_ADMIN_UNIT.DEFAULT}/test/create-test`,
    UPDATE_UNIT_TEST: `${PATH_ADMIN_UNIT.DEFAULT}/test/update-test`,
    CREATE_UNIT_LESSON: `${PATH_ADMIN_UNIT.DEFAULT}/lesson/create-lesson`,
    UPDATE_UNIT_LESSON: `${PATH_ADMIN_UNIT.DEFAULT}/lesson/update-lesson`,
    DETAIL: `${PATH_ADMIN_UNIT.DEFAULT}/detail-unit`,
    UPDATE: `${PATH_ADMIN_UNIT.DEFAULT}/update-unit`,
    UPDATE_ORDER: `${PATH_ADMIN_UNIT.DEFAULT}/update-order-unit`,
    CREATE_TEST_QUESTION: `${PATH_ADMIN_UNIT.DEFAULT}/test/create-question`,
    UPDATE_TEST_QUESTION: `${PATH_ADMIN_UNIT.DEFAULT}/test/update-question`,
    QUESTION_DETAIL: `${PATH_ADMIN_UNIT.DEFAULT}/test/get-question-info`,
    DELETE_QUESTION: `${PATH_ADMIN_UNIT.DEFAULT}/test/delete-question`,
    UPDATE_QUESTION_ORDER: `${PATH_ADMIN_UNIT.DEFAULT}/test/update-order-question`,
  },

  ADMIN_FOLDER: {
    LIST: `${PATH_ADMIN_FOLDER.FOLDER}/list-folder`,
    FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/list-resource`,
    CAPACITY: `${PATH_ADMIN_FOLDER.FOLDER}/total-capacity`,
    CREATE: `${PATH_ADMIN_FOLDER.FOLDER}/add-new-folder`,
    DELETE_FOLDER: `${PATH_ADMIN_FOLDER.FOLDER}/delete-resource-folder`,
    UPLOAD_FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/save-upload-resource-info`,
    UPDATE_FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/update-content-resource`,
    DELETE_FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/delete-resource`,
    UPDATE: `${PATH_ADMIN_FOLDER.FOLDER}/update-resource-folder`,
    MOVE_FILE: `${PATH_ADMIN_FOLDER.DEFAULT}/resource/change-folder-of-resource`,
  },

  ADMIN_PRODUCT: {
    LIST: `${PATH_ADMIN_PRODUCT.DEFAULT}/get-list-product`,
    CREATE: `${PATH_ADMIN_PRODUCT.DEFAULT}/create-product`,
    UPDATE: `${PATH_ADMIN_PRODUCT.DEFAULT}/update-product`,
    DETAIL: `${PATH_ADMIN_PRODUCT.DEFAULT}/get-product-detail`,
    DELETE: `${PATH_ADMIN_PRODUCT.DEFAULT}/delete-product`
  },

  ADMIN_COUPON: {
    LIST: `${PATH_ADMIN_COUPON.DEFAULT}/list-coupon`,
    CREATE: `${PATH_ADMIN_COUPON.DEFAULT}/upsert`,
    UPDATE: `${PATH_ADMIN_COUPON.DEFAULT}/update`,
    DETAIL: `${PATH_ADMIN_COUPON.DEFAULT}/detail`,
    DELETE: `${PATH_ADMIN_COUPON.DEFAULT}/delete`,
  },
  ADMIN_TERM_SERVICE: {
    LIST: `${PATH_ADMIN_TERMS_OF_USE.DEFAULT}/policy/get-list`,
    DETAIL: `${PATH_ADMIN_TERMS_OF_USE.DEFAULT}/policy/get-detail`,
    UPDATE: `${PATH_ADMIN_TERMS_OF_USE.DEFAULT}/policy/update`
  },
  ADMIN_LOGIN_SETTING: {
    DETAIL: `${PATH_ADMIN_TERMS_OF_USE.DEFAULT}/login-setting/detail`,
    UPDATE: `${PATH_ADMIN_TERMS_OF_USE.DEFAULT}/login-setting/update`,
    UPLOAD_FILE: `${PATH_ADMIN_TERMS_OF_USE.DEFAULT}/login-setting/upload-file`
  },

  ADMIN_STUDENT_THEME_SETTING: {
    UPDATE: `${PATH_ADMIN_TERMS_OF_USE.STUDENT_THEME_SETTING}/update`,
    GET_DETAIL: `${PATH_ADMIN_TERMS_OF_USE.STUDENT_THEME_SETTING}/detail`,
  },

  ORDERS: {
    PURCHASE_HISTORY: `${DEFAULT_PREFIX_ROUTE.ORDER}/get-purchase-history`,
    PUBLIC_RECEIPT: `${DEFAULT_PREFIX_ROUTE.ORDER}/public-receipt`,
    REPUBLIC_RECEIPT: `${DEFAULT_PREFIX_ROUTE.ORDER}/republic-receipt`,
  }
};

export { API };
