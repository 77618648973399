import { Navigate } from "react-router-dom";
import { PATH_ADMIN_SECTION } from "../services/constants/route/router";
import CartContextProvider from "../context/CartContext";
import StudentThemeContextProvider from "../context/StudentThemeContext";
import FeaturePurchasedContextProvider from "../context/FeaturePurchasedContext";

type CheckRoleStudentProps = {
  children: JSX.Element | JSX.Element[];
};

const CheckRoleStudent = ({ children }: CheckRoleStudentProps) => {
  let user = localStorage.getItem("user");
  let is_student = false;
  if (user) {
    is_student = JSON.parse(user)?.is_student;
  }

  return (
    <>
      {is_student ? (
        <FeaturePurchasedContextProvider>
          <StudentThemeContextProvider>
            <CartContextProvider>{children}</CartContextProvider>
          </StudentThemeContextProvider>
        </FeaturePurchasedContextProvider>
      ) : (
        <Navigate to={PATH_ADMIN_SECTION.DEFAULT} replace={true} />
      )}
    </>
  );
};

export default CheckRoleStudent;
