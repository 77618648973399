import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SideBarItem } from "../../../types/globals";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const SidebarItem = ({
  icon,
  label,
  name,
  urlAttribute,
  notificationCount,
  courseListId,
  ...props
}: SideBarItem &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getParamValue } = useObjectRoutes();
  const courseId = getParamValue("course_id");
  const handleNavigate = () => {
    if (urlAttribute) {
      navigate(urlAttribute);
    }
  };
  const { studentTheme } = useStudentThemeContext();
  
  return (
    <div
      style={{
        backgroundColor: location.pathname === urlAttribute || Number(courseId) === courseListId ? studentTheme.sub_color_second : ""
      }}
      className={`flex items-center w-full h-[40px] pl-[3px] relative rounded-[8px] ${props.className} hover:bg-[#00000026] hover:opacity-90`}
      data-url-attribute={urlAttribute}
      data-name={name}
      onClick={handleNavigate}
    >
      {icon}
      <span className="font-[500] text-[14px] leading-[100%] truncate w-full max-w-[130px] flex items-center min-h-[19px]">
        {label}
      </span>
      {notificationCount && (
        <span className="text-[12px] with_roboto w-[24px] h-[24px] bg-danger text-white rounded-full flex items-center justify-center absolute right-0">
          {notificationCount}
        </span>
      )}
    </div>
  );
};

export default SidebarItem;
