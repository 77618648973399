import TabCard from '../../../components/admin/commons/TabCard';
import AdminLayout from '../../../layouts/admin';
import { PATH_ADMIN_FOLDER } from '../../../services/constants/route/router';
import { useTabFinder } from '../../../hooks/useTabFinder';
import { WithChildren } from '../../../types/globals';
import { FOLDER_TAB_DATA } from '../../../services/constants/admin/pages/folder';

type CourseMainProp = {
  title?: string;
};

const FolderBase = ({ title, children }: WithChildren<CourseMainProp>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_FOLDER).slice(2));
  return (
    <AdminLayout>
      <TabCard tabData={FOLDER_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </AdminLayout>
  );
};

export default FolderBase;