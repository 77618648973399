import * as React from 'react';

const Calendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={26} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.5 1.5V4m-15-2.5V4M1.125 14.304c0-5.447 0-8.17 1.565-9.862C4.255 2.75 6.774 2.75 11.813 2.75h2.374c5.039 0 7.558 0 9.123 1.692 1.565 1.692 1.565 4.415 1.565 9.862v.642c0 5.447 0 8.17-1.565 9.862-1.565 1.692-4.084 1.692-9.122 1.692h-2.376c-5.038 0-7.557 0-9.122-1.692-1.565-1.692-1.565-4.415-1.565-9.862v-.642ZM1.75 9h22.5"
      stroke={`${props.color || '#8FC2D5'}`}
      strokeWidth={1.875}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Calendar;
