import React from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { RxListBullet } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { PATH_COURSE } from "../../../services/constants/route/router";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const UnitButtonGroup = ({
  courseId,
  lectureId,
  nextUnitId,
  prevUnitId,
  textColor,
}: {
  courseId?: number;
  lectureId?: number;
  nextUnitId?: number;
  prevUnitId?: number;
  textColor?: string;
}) => {
  const navigate = useNavigate();
  const isPrevDisabled = !prevUnitId;
  const isNextDisabled = !nextUnitId;

  const handlePrevClick = () => {
    if (prevUnitId) {
      navigate(`?unit_id=${prevUnitId}`);
    }
  };

  const handleNextClick = () => {
    if (nextUnitId) {
      navigate(`?unit_id=${nextUnitId}`);
    }
  };
  return (
    <div className="flex items-center justify-center gap-[15px] mb-[45px] max-sm:gap-[15px]">
      <button
        style={{ color: textColor }}
        onClick={handlePrevClick}
        className={`px-[20px] py-[10px] rounded-[5px] font-[500] text-[16px] leading-[100%] h-[44px] flex items-center w-[203px] gap-[20px] max-sm:w-[210px] max-sm:gap-0 max-sm:text-[12px] max-sm:px-[5px] max-sm:justify-between ${
          isPrevDisabled
            ? "bg-secondary-light text-white cursor-not-allowed"
            : "bg-secondary-extralight text-black"
        }`}
        disabled={isPrevDisabled}
      >
        <AiOutlineLeft className="ml-[4px] max-sm:ml-0" size={15} />
        <div className="">前の単元へ戻る</div>
      </button>
      <button
        style={{ color: textColor }}
        onClick={() =>
          navigate(
            `${PATH_COURSE.LECTURE_DETAIL}?lecture_id=${
              lectureId || ""
            }&course_id=${courseId || ""}`
          )
        }
        className="bg-secondary-extralight px-[20px] py-[10px] text-black rounded-[5px] font-[500] text-[16px] leading-[100%] h-[44px] flex items-center w-[203px] gap-[20px] max-sm:w-[190px] max-sm:gap-0 max-sm:text-[12px] max-sm:px-[5px] max-sm:justify-between"
      >
        <RxListBullet className="ml-[4px]" size={20} />
        <div className="">講座トップ</div>
      </button>
      <button
        onClick={handleNextClick}
        className={`px-[20px] py-[10px] text-white rounded-[5px] font-[500] text-[16px] leading-[100%] h-[44px] flex items-center w-[203px] gap-[20px] max-sm:justify-between
          max-sm:text-[12px] max-sm:px-[5px] max-sm:w-[210px] max-sm:gap-0 ${
            isNextDisabled
              ? "bg-secondary-light cursor-not-allowed"
              : "bg-danger"
          }`}
        disabled={isNextDisabled}
      >
        <div className="">次の単元へ進む</div>
        <AiOutlineRight className="ml-[4px] text-white max-sm:ml-0" size={15} />
      </button>
    </div>
  );
};

export default UnitButtonGroup;
