import React from "react";
import RoundedButton from "../../admin/commons/RoundButton";
import { BsDownload } from "react-icons/bs";
import JSZip from "jszip";
import { UNIT_FILE_TYPE } from "../../../services/constants/admin/pages/material";
import FileSaver from "file-saver";

const DownloadZip = ({
  url,
  resourceType,
  downloadable,
}: {
  url?: string;
  resourceType?: number | null;
  downloadable?: number | boolean;
}) => {
  const handleDownload = async () => {
    const zip = new JSZip();
    const resourceLink = url || "";
    if (resourceType === UNIT_FILE_TYPE.TYPE_VIDEO) {
      const response = await fetch(resourceLink);
      const blob = await response.blob();
      zip.file("video.mp4", blob);
    }

    if (resourceType === UNIT_FILE_TYPE.TYPE_AUDIO) {
      const response = await fetch(resourceLink);
      const blob = await response.blob();
      zip.file("audio.mp3", blob);
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });
    FileSaver.saveAs(zipBlob, "content.zip");
  };

  return (
    <>
      {downloadable ? (
        <RoundedButton
          isDisabled={!downloadable}
          onClick={handleDownload}
          className="mb-[40px] flex items-center"
        >
          <BsDownload size={18} />
          <div className="ml-[7px] text-left">
            <span className="text-[10px] font-[700]">ZIPファイル</span>
            <br />
            <span className="text-[13px] font-[700]">
              この教材をダウンロード
            </span>
          </div>
        </RoundedButton>
      ) : ''}
    </>
  );
};

export default DownloadZip;
