import { SidebarChildItem, SidebarItem } from "../../../types/admin/main";
import {
  PATH_ADMIN_COUPON,
  PATH_ADMIN_COURSE,
  PATH_ADMIN_FOLDER,
  PATH_ADMIN_MATERIAL,
  PATH_ADMIN_PRODUCT,
  PATH_ADMIN_SECTION,
  PATH_ADMIN_STUDENT,
  PATH_ADMIN_TERMS_OF_USE,
} from "../route/router";
import { COURSE_TAB_ITEM } from "./pages/course";
import { SECTION_TAB_ITEM } from "./pages/section";
import Section from "../../../components/commons/icons/Sidebar/Section";
import Student from "../../../components/commons/icons/Sidebar/Student";
import Course from "../../../components/commons/icons/Sidebar/Course";
import Resource from "../../../components/commons/icons/Sidebar/Resource";
import Material from "../../../components/commons/icons/Sidebar/Material";
import Product from "../../../components/commons/icons/Sidebar/Product";
import Setting from "../../../components/commons/icons/Setting";

const child = (
  href: SidebarChildItem["href"],
  label: SidebarChildItem["label"]
) => ({
  href: href,
  label: label,
});

const item = (
  label: SidebarItem["label"],
  href: SidebarItem["href"],
  icon: SidebarItem["icon"],
  ...childs: SidebarItem["childs"]
) => ({
  label: label,
  href: href,
  icon: icon,
  childs: childs.map(({ href, label }: SidebarChildItem) => child(href, label)),
});

const ADMIN_SIDEBAR_DATA = [
  // Sidebar Items hidden for later phases
  // item(
  //   '基本情報設定',
  //   child('/#', '進捗レポート'),
  //   child('/#', 'データ分析'),
  //   child('/#', '通知'),
  //   child('/#', 'データ処理状況一覧'),
  // ),
  item(
    "セクション管理",
    '',
    Section,
    child(PATH_ADMIN_SECTION.DEFAULT, SECTION_TAB_ITEM.LIST),
    child(PATH_ADMIN_SECTION.MANAGEMENT, SECTION_TAB_ITEM.MANAGEMENT)
  ),
  item(
    "受講者管理",
    '',
    Student,
    child(PATH_ADMIN_STUDENT.DEFAULT, "受講者一覧"),
    child(PATH_ADMIN_STUDENT.CREATE, "受講者新規追加")
    // child(PATH_ADMIN_STUDENT.CSV, '受講者一括追加'),
    // child(PATH_ADMIN_STUDENT.SETTING, '受講者設定'),
  ),
  item(
    "学習コース管理",
    '',
    Course,
    child(PATH_ADMIN_COURSE.DEFAULT, COURSE_TAB_ITEM.COURSE_LIST),
    child(
      PATH_ADMIN_COURSE.COURSE_REGISTRATION,
      COURSE_TAB_ITEM.COURSE_REGISTRATION
    ),
    child(
      PATH_ADMIN_COURSE.LECTURE_REGISTRATION,
      COURSE_TAB_ITEM.LECTURE_REGISTRATION
    ),
    child(
      PATH_ADMIN_COURSE.CHAPTER_REGISTRATION,
      COURSE_TAB_ITEM.CHAPTER_REGISTRATION
    )
    // child(PATH_ADMIN_COURSE.BULK_REGISTRATION, COURSE_TAB_ITEM.BULK_REGISTRATION),
  ),
  item(
    "ファイル管理",
    '',
    Resource,
    child(PATH_ADMIN_FOLDER.FOLDER, "カテゴリ一覧"),
    child(PATH_ADMIN_FOLDER.FILE, "画像埋め込みタグ一覧")
  ),
  item(
    "教材管理",
    '',
    Material,
    child(PATH_ADMIN_MATERIAL.DEFAULT, "教材一覧"),
    child(PATH_ADMIN_MATERIAL.UNIT_UPSERT, "新規教材作成・登録")
  ),
  item(
    "講座販売",
    '',
    Product,
    child(PATH_ADMIN_PRODUCT.DEFAULT, "商品一覧"),
    child(PATH_ADMIN_PRODUCT.CREATE, "商品新規追加"),
    child(PATH_ADMIN_COUPON.DEFAULT, "クーポン管理"),
    child(PATH_ADMIN_COUPON.CREATE, "クーポン新規追加")
  ),
  item(
    "各種設定",
    '',
    Setting,
    child(PATH_ADMIN_TERMS_OF_USE.LOGIN_PAGE_SETTING, "ログインページ"),
    child(PATH_ADMIN_TERMS_OF_USE.STUDENT_THEME_SETTING, "受講者画面設定"),
    child(PATH_ADMIN_TERMS_OF_USE.TERMS_OF_SERVICE, "ご利用規約設定"),
  )
  // Sidebar Items hidden for later phases
  // item(
  //   'お知らせ',
  //   child('/#', 'お知らせ一覧'),
  //   child('/#', 'お知らせ新規作成'),
  //   child('/#', 'テンプレート一覧'),
  // ),
  // item(
  //   'スケジュール管理',
  //   child('/#', 'テキストダミーテキスト'),
  //   child('/#', 'テキストダミーテキスト'),
  //   child('/#', 'テキストダミーテキスト'),
  // ),
  // item(
  //   '各種設定',
  //   child('/#', 'ログインページ'),
  //   child('/#', '受講者画面設定'),
  //   child('/#', 'プロフィール設定'),
  //   child('/#', 'ローケル設定'),
  //   child('/#', 'IP制御'),
  //   child('/#', 'コンバージョン計測'),
  //   child('/#', 'ご利用規約設定'),
  // ),
  // item(
  //   'ヘルプ',
  //   child('/#', 'お知らせ'),
  //   child('/#', 'よくある質問'),
  //   child('/#', '各種マニュアル・テンプレート'),
  //   child('/#', 'マナビテへのお問い合わせ'),
  // ),
];

export { ADMIN_SIDEBAR_DATA };
