/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LearningType from "../Learning/Type";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { UnitForm, UnitProgress } from "../../../types/admin/unit";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import UnitsProgress from "./UnitsProgress";
import Title from "../MyPage/Title";
import UnitLesson from "./Lesson/UnitLesson";
import { LEARNING_TYPE } from "../../../services/constants/student/learning";
import UnitTest from "./Test/UnitTest";
import { useStudentUnitContext } from "../../../context/StudentUnitContext";
import { useNavigate } from "react-router-dom";
import { useFeaturePurchasedContext } from "../../../context/FeaturePurchasedContext";
import LayoutWaitingApi from "../common/LayoutWaitingApi";
import { useStorage } from "../../../hooks/useStorage";

const UnitDetail = () => {
  const { getParamValue } = useObjectRoutes();
  const unitId = getParamValue("unit_id");
  const { unitData, setUnitData } = useStudentUnitContext();
  const [prevUnitData, setPrevUnitData] = useState<UnitForm>({});
  const [nextUnitData, setNextUnitData] = useState<UnitForm>({});
  const [unitProgressData, setUnitProgressData] = useState<UnitProgress>({});
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const navigate = useNavigate();
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  useEffect(() => {
    if (!unitId) return;

    const fetchUnitDetailPage = async () => {
      const fetchUnitDetail = request.get(
        `${API.UNIT.DETAIL}?unit_id=${unitId}`,
        (unitGroupData) => {
          setUnitData(unitGroupData.current_lesson);
          setPrevUnitData(unitGroupData.prev_lesson);
          setNextUnitData(unitGroupData.next_lesson);
        },
        (error) => {
          if (error === 404) {
            navigate("/404");
          }
        }
      );

      const fetchUnitProgress = request.get(
        `${API.UNIT.UNIT_PROGRESS}?unit_id=${unitId}`,
        (unitProgressData) => {
          setUnitProgressData(unitProgressData || {});
        },
        () => {},
        { withError: false, withClose: false }
      );

      try {
        await Promise.all([fetchUnitDetail, fetchUnitProgress]);
        setWaitingApi(false);
      } catch (error) {
        setWaitingApi(false);
      }
    };

    fetchUnitDetailPage();
  }, [unitId]);

  return (
    <>
      <Title
        content={`${unitData.course_name || ""}|${
          unitData.lecture_name || ""
        } `}
        lectureId={unitData.lecture_id}
        courseId={unitData.course_id}
        withSeperator
      />
      <div
        className={`w-full flex items-center mb-[26px] mt-[30px] gap-[15px] animate-[show_0.5s_ease-in-out] max-sm:ml-[5px] max-sm:mt-[5px] max-sm:mb-[0px]`}
      >
        <LearningType type={unitData.type} />
        <div
          style={{ color: studentTheme.text_color }}
          className="font-[700] text-[#2E2E2E] text-[18px] leading-[20px] tracking-[0.02em] max-w-[700px] flex items-center truncate h-[30px]"
        >
          {unitData.name || ""}
        </div>
      </div>

      <div className="flex flex-col items-center gap-[20px]">
        <div className="flex items-center gap-[20px]">
          <LayoutWaitingApi
            waitingApi={waitingApi}
            className={`w-[500px] min-h-[283px] ${
              waitingApi ? "block" : "hidden"
            }`}
          ></LayoutWaitingApi>
          <LayoutWaitingApi
            waitingApi={waitingApi}
            className={`w-[250px] min-h-[283px] ${
              waitingApi ? "block" : "hidden"
            }`}
          ></LayoutWaitingApi>
        </div>
        <LayoutWaitingApi
          waitingApi={waitingApi}
          className={`w-full min-h-[50px] ${waitingApi ? "block" : "hidden"}`}
        ></LayoutWaitingApi>
      </div>
      {!waitingApi && (
        <div className="animate-[show_0.5s_ease-in-out]">
          {unitData.type === LEARNING_TYPE.READ && (
            <UnitLesson unitData={unitData} unitId={unitId} />
          )}
          {unitData.type === LEARNING_TYPE.WRITE && <UnitTest />}
        </div>
      )}
      <UnitsProgress
        courseId={unitData.course_id}
        lectureId={unitData.lecture_id}
        nextUnitData={nextUnitData || {}}
        prevUnitData={prevUnitData || {}}
        unitProgressData={unitProgressData}
        waitingApi={waitingApi}
      />
    </>
  );
};

export default UnitDetail;
