import {
  PATH_ADMIN_COUPON,
  PATH_ADMIN_COURSE,
  PATH_ADMIN_FOLDER,
  PATH_ADMIN_MATERIAL,
  PATH_ADMIN_PRODUCT,
  PATH_ADMIN_SECTION,
  PATH_ADMIN_TERMS_OF_USE,
  ROUTE,
} from "../services/constants/route/router";
import { RouteObject } from "react-router";
import StudentListPage from "../pages/admin/Student/List";
import StudentCreatePage from "../pages/admin/Student/Create";
import StudentCSVPage from "../pages/admin/Student/CSV";
import StudentSettingPage from "../pages/admin/Student/Setting";
import SettingPage from "../pages/admin/Section/Setting";
import CourseListPage from "../pages/admin/Course/List";
import SectionListPage from "../pages/admin/Section/List";
import SectionManagementPage from "../pages/admin/Section/Management";
import StudentUpdatePage from "../pages/admin/Student/Details";
import CourseRegistrationPage from "../pages/admin/Course/CourseRegistration";
import LectureRegistrationPage from "../pages/admin/Course/LectureRegistration";
import ChapterRegistrationPage from "../pages/admin/Course/ChapterRegistration";
import BulkRegistrationPage from "../pages/admin/Course/BulkRegistration";
import MaterialUnitListPage from "../pages/admin/Material/UnitList";
import MaterialCourseListPage from "../pages/admin/Material/CourseList";
import MaterialUnitUpsertPage from "../pages/admin/Material/UnitUpsert";
import FolderListPage from "../pages/admin/Folder/List";
import FileListPage from "../pages/admin/Folder/Files";
import FoldersFileListPage from "../pages/admin/Folder/FoldersFileList";
import ProductListPage from "../pages/admin/Product/List";
import ProductUpsertPage from "../pages/admin/Product/Upsert";
import CouponUpsertPage from "../pages/admin/Coupon/Upsert";
import CouponListPage from "../pages/admin/Coupon/List";
import TermOfService from "../pages/admin/TermOfUse/TermOfService";
import TermServiceDetail from "../pages/admin/TermOfUse/TermServiceDetail";
import LoginPageSetting from "../pages/admin/TermOfUse/LoginPageSetting";
import StudentThemePage from "../pages/admin/TermOfUse/StudentTheme";

type CustomRouterObject = {};

const adminRouter: Array<RouteObject & CustomRouterObject> = [
  {
    path: ROUTE.ADMIN_PREFIX,
    children: [
      {
        path: PATH_ADMIN_SECTION.DEFAULT,
        children: [
          { index: true, element: <SectionListPage /> },
          {
            path: PATH_ADMIN_SECTION.MANAGEMENT,
            element: <SectionManagementPage />,
          },
          {
            path: PATH_ADMIN_SECTION.SETTING,
            element: <SettingPage />,
          },
        ],
      },
      {
        path: ROUTE.STUDENT_MANAGEMENT.DEFAULT,
        children: [
          { index: true, element: <StudentListPage /> },
          {
            path: ROUTE.STUDENT_MANAGEMENT.CREATE,
            element: <StudentCreatePage />,
          },
          {
            path: ROUTE.STUDENT_MANAGEMENT.CSV,
            element: <StudentCSVPage />,
          },
          {
            path: ROUTE.STUDENT_MANAGEMENT.SETTING,
            element: <StudentSettingPage />,
          },
          {
            path: `${ROUTE.STUDENT_MANAGEMENT.DETAILS}/:id`,
            element: <StudentUpdatePage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_COURSE.DEFAULT,
        children: [
          { index: true, element: <CourseListPage /> },
          {
            path: PATH_ADMIN_COURSE.COURSE_REGISTRATION,
            element: <CourseRegistrationPage />,
          },
          {
            path: PATH_ADMIN_COURSE.LECTURE_REGISTRATION,
            element: <LectureRegistrationPage />,
          },
          {
            path: PATH_ADMIN_COURSE.CHAPTER_REGISTRATION,
            element: <ChapterRegistrationPage />,
          },
          {
            path: PATH_ADMIN_COURSE.BULK_REGISTRATION,
            element: <BulkRegistrationPage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_MATERIAL.DEFAULT,
        children: [
          { index: true, element: <MaterialCourseListPage /> },
          {
            path: PATH_ADMIN_MATERIAL.UNIT,
            element: <MaterialUnitListPage />,
          },
          {
            path: PATH_ADMIN_MATERIAL.UNIT,
            element: <MaterialUnitListPage />,
          },
          {
            path: PATH_ADMIN_MATERIAL.UNIT_UPSERT,
            element: <MaterialUnitUpsertPage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_FOLDER.DEFAULT,
        children: [
          { index: true, element: <FolderListPage /> },
          {
            path: PATH_ADMIN_FOLDER.FOLDER,
            children: [
              { index: true, element: <FolderListPage /> },
              {
                path: PATH_ADMIN_FOLDER.FOLDER_FILE,
                element: <FoldersFileListPage />,
              },
            ],
          },
          {
            path: PATH_ADMIN_FOLDER.FILE,
            children: [{ index: true, element: <FileListPage /> }],
          },
        ],
      },
      {
        path: PATH_ADMIN_PRODUCT.DEFAULT,
        children: [
          { index: true, element: <ProductListPage /> },
          {
            path: PATH_ADMIN_PRODUCT.CREATE,
            element: <ProductUpsertPage />,
          },
          {
            path: `${PATH_ADMIN_PRODUCT.DETAIL}/:id`,
            element: <ProductUpsertPage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_COUPON.DEFAULT,
        children: [
          { index: true, element: <CouponListPage /> },
          {
            path: PATH_ADMIN_COUPON.CREATE,
            element: <CouponUpsertPage />,
          },
          {
            path: `${PATH_ADMIN_COUPON.DETAIL}/:id`,
            element: <CouponUpsertPage />,
          },
        ],
      },
      {
        path: PATH_ADMIN_TERMS_OF_USE.DEFAULT,
        children: [
          {
            path: PATH_ADMIN_TERMS_OF_USE.LOGIN_PAGE_SETTING,
            element: <LoginPageSetting />,
          },
          {
            path: PATH_ADMIN_TERMS_OF_USE.TERMS_OF_SERVICE,
            element: <TermOfService />,
          },
          {
            path: PATH_ADMIN_TERMS_OF_USE.TERMS_OF_SERVICE_DETAIL,
            element: <TermServiceDetail />,
          },
          {
            path: PATH_ADMIN_TERMS_OF_USE.STUDENT_THEME_SETTING,
            element: <StudentThemePage />,
          },
        ],
      },
    ],
  },
];

export default adminRouter;
