import React, { useEffect, useState } from "react";
import LearningType from "../../Learning/Type";
import { Unit } from "../../../../types/admin/unit";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useNavigate } from "react-router-dom";
import { UNIT_STATUS } from "../../../../services/constants/student/unit";
import LayoutWaitingApi from "../../common/LayoutWaitingApi";
const UnitListItem = ({
  unitName,
  unitType,
  id,
  unitId,
  status,
}: {
  unitName?: string;
  unitType?: number;
  id?: number;
  unitId: string | null;
  status?: number;
}) => {
  const navigate = useNavigate();

  const getUnitStatus = (status?: number) => {
    switch (status) {
      case UNIT_STATUS.UNIT_UNLEARNED_STATUS:
        if (id === Number(unitId)) {
          return "(学習中)";
        }
        return "(履修前)";
        break;
      case UNIT_STATUS.UNIT_LEARNING_STATUS:
        return "(学習中)";
        break;
      case UNIT_STATUS.UNIT_COMPLETED_STATUS:
        return "(終了)";
        break;
      default:
        return "";
        break;
    }
  };

  const shortenedUnitName =
    unitName && unitName.length > 11
      ? `${unitName.slice(0, 11)}... `
      : unitName;
  const currentUnit = id === Number(unitId) ? "bg-danger-lighter" : "";

  return (
    <div
      className={`w-full flex border-b border-[1px] border-danger-light text-secondary items-center min-h-[40px] pl-[10px] gap-[15px] text-[11px] font-[500] ${currentUnit} cursor-pointer hover:bg-[#00000026]`}
      onClick={() => navigate(`?unit_id=${id}`)}
    >
      <LearningType type={unitType} status={status} />
      {`${shortenedUnitName} ${getUnitStatus(status)}`}
    </div>
  );
};
const UnitList = ({
  unitId,
  chapterId,
  chapterName,
}: {
  unitId: string | null;
  chapterId?: string | number;
  chapterName?: string;
}) => {
  const [unitListData, setUnitListData] = useState<Unit[]>([]);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  useEffect(() => {
    if (!unitId) return;
    const fetchUnitList = async () => {
      await request.get(
        `${API.UNIT.LIST_UNIT_IN_CHAPTER}?chapter_id=${chapterId}`,
        (unitListData) => {
          setUnitListData(unitListData || []);
          setWaitingApi(false);
        }
      );
    };
    fetchUnitList();
  }, [chapterId, unitId]);
  return (
    <div className="w-full max-w-[250px] max-h-[283px] animate-[show_0.5s_ease-in-out] max-sm:max-w-full">
      <div className="bg-secondary-light flex px-2 items-center text-white text-[13px] font-[700] h-[33px]">
        <div className="truncate w-full">{chapterName || ""}</div>
      </div>
      <div className="overflow-y-scroll max-h-[250px]">
        <LayoutWaitingApi
          waitingApi={waitingApi}
          className="max-w-[250px] max-h-[250px]"
        >
          {unitListData.map((unit) => (
            <UnitListItem
              key={unit.id}
              unitName={unit.name}
              unitType={unit.type}
              id={unit.id}
              unitId={unitId}
              status={unit.study_status}
            />
          ))}
        </LayoutWaitingApi>
      </div>
    </div>
  );
};

export default UnitList;
