import React, { useState, useEffect, useMemo } from "react";
import { BiTrendingUp } from "react-icons/bi";
import { FiPenTool, FiRefreshCw } from "react-icons/fi";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { PATH_COURSE } from "../../../../services/constants/route/router";
import { MODE_EXECUTE_TEST } from "../../../../services/constants/student/unit";
import SmallButton from "../../common/SmallButton";
import qs from "qs";
import { TestHistory, TestPausedHistory } from "../../../../types/student/unit";
import { Unit } from "../../../../types/admin/unit";
import {
  formatDateTime,
  formatTotalTestTime,
} from "../../../../services/helpers/formatTime";
import { getPercent } from "../../../../services/helpers/parseData";
import type { Object } from "../../../../types/globals";
import { isEmptyArr, isEmptyObj } from "../../../../services/helpers/etc";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { TestHistoryParams } from "./UnitTest";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type TestModeGroupItemProps = {
  type: number;
  unit: Unit;
  testPausedHistories: TestPausedHistory[];
  testHistories: TestHistory[];
};

type ExecuteParamType = {
  unit_id?: number;
  test_type?: number;
  is_random_order?: number;
  is_limit_time?: number;
  is_limit_scope?: number;
};

type ContinuousExecuteParamType = {
  test_history_id: number;
};

type DeleteHistoryParamType = {
  student_test_history_id: number;
};

const TestModeGroupItem = ({
  type,
  unit,
  testPausedHistories,
  testHistories,
}: TestModeGroupItemProps) => {
  const { navigate } = useObjectRoutes();

  //current history
  //only change when testHistories changed
  const intCurrentHistory = useMemo(() => {
    return !isEmptyArr(testPausedHistories) ? testPausedHistories[0] : {};
  }, [testPausedHistories]);

  const [currentHistory, setCurrentHistory] =
    useState<TestHistory>(intCurrentHistory);
  const { studentTheme } = useStudentThemeContext();

  useEffect(() => {
    if (isEmptyObj(intCurrentHistory)) return;
    setCurrentHistory(intCurrentHistory);
  }, [intCurrentHistory]);

  //prepare params for navigate NEW test
  //each type give each param key
  const executeParamObj: ExecuteParamType = useMemo(() => {
    return {
      unit_id: unit?.id,
      test_type: type,
      ...(type === MODE_EXECUTE_TEST.PRACTICE && {
        is_random_order: 0, //first option is defaulted
      }),
      ...(type === MODE_EXECUTE_TEST.MAIN && {
        is_limit_time: 1, //first option is defaulted
      }),
      ...(type === MODE_EXECUTE_TEST.REVISE && {
        is_limit_scope: 1, //first option is defaulted
      }),
    };
  }, [unit?.id]);

  const [paramObj, setParamObj] = useState<ExecuteParamType>(executeParamObj);

  useEffect(() => {
    setParamObj(executeParamObj);
  }, [executeParamObj]);

  const executeQueryParam = qs.stringify(paramObj);

  //prepare params for navigate PAUSED test
  const continuousExecuteParamObj: ContinuousExecuteParamType = {
    test_history_id: currentHistory?.id || 0,
  };
  const continuousExecuteQueryParam = qs.stringify(continuousExecuteParamObj);

  //check existed paused test to navigate
  const handleNavigate = () => {
    if (isEmptyObj(currentHistory)) {
      return navigate(`${PATH_COURSE.EXECUTE_TEST}?${executeQueryParam}`);
    }

    return navigate(
      `${PATH_COURSE.CONTINUE_TEST}?${continuousExecuteQueryParam}`
    );
  };

  //delete a history test
  const deleteHistoryParamObj: DeleteHistoryParamType = {
    student_test_history_id: currentHistory.id || 0,
  };

  const handleDeleteHistory = async () => {
    if (!currentHistory || !currentHistory.id) return;
    await request.delete(
      API.COURSE.DELETE_TEST_CONTINUOUS_HISTORY,
      deleteHistoryParamObj
    );

    //re-fetch after delete
    const params: TestHistoryParams = {
      unit_id: unit?.id || 0,
    };
    const queryParam = qs.stringify(params);

    await request.get(
      `${API.COURSE.GET_LIST_TEST_HISTORY_ON_PAUSE}?${queryParam}`,
      (res) => {
        const historyRes = !isEmptyArr(res) ? res[0] : {};
        setCurrentHistory(historyRes);
      }
    );
  };

  // each type give each content
  let icon: React.ReactElement,
    color: string,
    colorScheme: string,
    navigateLabel: string,
    testOptionLabel: string,
    title: string,
    desc: string,
    optionData: Object[];

  const isEmptyHistory = isEmptyObj(currentHistory);

  switch (type) {
    case MODE_EXECUTE_TEST.PRACTICE:
      icon = <BiTrendingUp size={30} />;
      desc = "問題と解説が交互に表示されます";
      colorScheme = "bg-primary-light";
      color = "text-primary";
      title = "練習モード";
      navigateLabel = isEmptyHistory
        ? "練習モードでテストをする"
        : "続きから始める";
      testOptionLabel = "出題順";
      optionData = [
        {
          label: "通常",
          value: 0,
        },
        {
          label: "ランダム",
          value: 1,
        },
      ];
      break;
    case MODE_EXECUTE_TEST.MAIN:
      icon = <FiPenTool size={25} />;
      desc = "本番形式で全問題を解答します";
      colorScheme = "bg-danger";
      color = "text-danger";
      title = "本番モード";
      navigateLabel = isEmptyHistory
        ? "本番モードでテストをする"
        : "続きから始める";
      testOptionLabel = "制限時間";
      optionData = [
        {
          label: `${formatTotalTestTime(unit?.estimate_time_required)}`,
          value: 1,
        },
        {
          label: "制限なし",
          value: 0,
        },
      ];
      break;
    case MODE_EXECUTE_TEST.REVISE:
      icon = <FiRefreshCw size={30} />;
      desc = "範囲を指定して問題を出題できます";
      colorScheme = "bg-success";
      color = "text-success";
      title = "復習モード";
      navigateLabel = isEmptyHistory
        ? "復習モードでテストをする"
        : "続きから始める";
      testOptionLabel = "出題範囲";
      optionData = [
        {
          label: "前回間違えた場所",
          value: 1,
        },
        {
          label: "すべて",
          value: 0,
        },
      ];
      break;
    default:
      return <></>;
  }

  const testCount = Array.isArray(testHistories)
    ? testHistories.filter((history) => history.test_type === type).length
    : 0;

  return (
    <div className="flex flex-col gap-[11px] text-secondary text-[9px] font-[500] w-full max-w-[235px]">
      <div className="w-full border border-danger-light pt-[15px]">
        <div className="flex items-center mb-[15px] pl-[11.5px]">
          <div
            className={`w-[50px] h-[50px] ${colorScheme} flex items-center justify-center text-white mr-[4px]`}
          >
            {icon}
          </div>
          <div className="px-[7px]">
            <p>{desc}</p>
            <p className={`${color} text-[18px] font-700`}>{title}</p>
            <p>
              問題数 {unit?.question_quantity}　学習履歴：{testCount}回
            </p>
          </div>
        </div>
        <div
          style={{ color: studentTheme.text_color }}
          className="flex gap-[2px] min-h-[24px] text-[9px] leading-[100%]"
        >
          <div className="bg-success-lighter w-full max-w-[54px] py-[4px] text-center text-[9px] font-[500] max-w-[56px] min-h-[24px] flex items-center justify-center">
            {testOptionLabel}
          </div>
          <div className="bg-success-lighter w-full flex items-center pl-[5px] justify-start">
            {optionData.map((data, index) => (
              <label
                key={`${index}_${type}`}
                className="inline-flex items-center mr-[10px]"
              >
                <input
                  type="radio"
                  className="form-radio text-primary-light border border-success-extralight"
                  name={`test_${type}}`}
                  defaultChecked={
                    type === MODE_EXECUTE_TEST.PRACTICE
                      ? unit?.is_shuffle_questions === data.value
                      : index === 0
                  }
                  onClick={() =>
                    setParamObj({
                      ...paramObj,
                      ...(type === MODE_EXECUTE_TEST.PRACTICE && {
                        is_random_order: data.value,
                      }),
                      ...(type === MODE_EXECUTE_TEST.MAIN && {
                        is_limit_time: data.value,
                      }),
                      ...(type === MODE_EXECUTE_TEST.REVISE && {
                        is_limit_scope: data.value,
                      }),
                    })
                  }
                />
                <span className="ml-[5px]">{data.label}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
      <div className="flex px-[15px] gap-[20px] justify-center items-center">
        <SmallButton className={colorScheme} onClick={handleNavigate}>
          {navigateLabel}
        </SmallButton>
        {!!currentHistory && !!currentHistory.id && (
          <>
            <SmallButton className="bg-secondary" onClick={handleDeleteHistory}>
              破棄する
            </SmallButton>
          </>
        )}
      </div>
      {!isEmptyObj(currentHistory) && (
        <div className="flex flex-col items-center">
          <div className="text-danger font-[500] text-[13px] mb-[10px]">
            未完了の問題があります
          </div>
          <div className="w-full max-w-[210px]">
            <div className="w-full rounded-full h-[8px] bg-success-extralight">
              <div
                className={`bg-primary h-[8px] rounded-full`}
                style={{
                  width: `${getPercent(
                    currentHistory.current_step,
                    currentHistory.question_quantity
                  )}%`,
                  backgroundColor: studentTheme.main_color_first,
                }}
              ></div>
            </div>
          </div>
          <div
            style={{ color: studentTheme.text_color }}
            className="text-[10px] w-full flex justify-between items-center mt-[5px] px-[10px]"
          >
            <div className="">
              <span className="mr-[14px]">進捗</span>
              <span className="text-danger font-[700] text-[14px]">
                {currentHistory.current_step || 0}/
                {currentHistory.question_quantity || 0}
              </span>
            </div>
            <div className="">{formatDateTime(currentHistory.created_at)}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestModeGroupItem;
