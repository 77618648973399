import React, { useState, useEffect } from "react";
import { makeRequest } from "../../../services/axios/axios";
import { MESSAGE_ERROR } from "../../../services/constants/message";
import { API } from "../../../services/constants/route/api";
import {
  swalLoading,
  swalMessage,
  swalSuccess,
} from "../../../services/helpers/swal";
import { ErrorData } from "../../../types/globals";
import { User } from "../../../types/user";
import ErrorBox from "../../commons/form/ErrorBox";
import { Section } from "../../../types/admin/section";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

type UserInformationProps = {
  data: User;
  isEdit?: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

type UserInformationData = {
  username: string;
  first_name: string;
  last_name: string;
  first_name_kana: string;
  last_name_kana: string;
  sections: { name?: string; parent_section_name?: string }[];
};

const UserInformation = ({
  data,
  isEdit = false,
  setIsEdit,
}: UserInformationProps) => {
  const [userInformation, setUserInformation] = useState<UserInformationData>(
    data as UserInformationData
  );
  const [errors, setErrors] = useState<ErrorData>({});
  const { studentTheme } = useStudentThemeContext();
  useEffect(() => {
    setUserInformation(data as UserInformationData);
  }, [data]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInformation({
      ...userInformation,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitHandle = async () => {
    // swalLoading();

    const result = await makeRequest({
      method: "patch",
      url: API.STUDENT.UPDATE_INFORMATION,
      data: userInformation,
    });

    if (!result.data) {
      setErrors(result.error as ErrorData);
      return swalMessage("", "", "error", {
        timer: 1000,
        showConfirmButton: false,
        footer: MESSAGE_ERROR.SOMETHINGS_WENT_WRONG,
      });
    }

    swalSuccess();
    setIsEdit(false);
    setErrors({});
  };

  return !isEdit ? (
    <div className="mb-[80px] mt-[20.5px]">
      <div className="flex mb-[5px]">
        <div className="font-[500] min-h-[40px] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px] h-full">
          ログインID
        </div>
        <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
          {userInformation.username}
        </div>
      </div>
      <div className="flex h-[40px] mb-[5px]">
        <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px] h-full">
          お名前
        </div>
        <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
          {userInformation.first_name}　{userInformation.last_name}
        </div>
      </div>
      <div className="flex h-[40px] mb-[5px]">
        <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px] h-full">
          お名前（カナ）
        </div>
        <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
          {userInformation.first_name_kana}　{userInformation.last_name_kana}
        </div>
      </div>
      <div className="flex min-h-[40px]">
        <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px]">
          セクション
        </div>
        <div className="flex flex-col gap-[10px] justify-center py-[9px]">
          {userInformation?.sections?.map((section, index) => (
            <div className="flex" key={index}>
              {section.parent_section_name && (
                <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
                  {section.parent_section_name || ""}
                </div>
              )}
              <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
                {section.name || ""}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <>
      {Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}
      <div className="mt-[20.5px]">
        <div className="flex mb-[5px] gap-[13px] min-h-[40px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">ログインID</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="w-[56%]">
            <div className="flex justify-between">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[16px] border border-danger-light rounded-[5px] w-[100%] h-[34px]"
                name="username"
                value={userInformation.username}
                onChange={handleOnChange}
              />
            </div>
            <div className="text-[10px] leading-[190%]">
              ※半角英数のみ８〜20
            </div>
          </div>
        </div>
        <div className="flex mb-[5px] gap-[13px] min-h-[40px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px]">
            お名前
          </div>
          <div className="w-[56%]">
            <div className="flex justify-between">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[16px] border border-danger-light rounded-[5px] h-[34px] w-full max-w-[205px]"
                name="first_name"
                value={userInformation.first_name}
                onChange={handleOnChange}
              />
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[16px] border border-danger-light rounded-[5px] w-[56%] h-[34px] w-full max-w-[205px]"
                name="last_name"
                value={userInformation.last_name}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>
        <div className="flex mb-[5px] gap-[13px] min-h-[40px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px]">
            お名前（カナ）
          </div>
          <div className="w-[56%]">
            <div className="flex justify-between">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[16px] border border-danger-light rounded-[5px] h-[34px] w-full max-w-[205px]"
                name="first_name_kana"
                value={userInformation.first_name_kana}
                onChange={handleOnChange}
              />
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[16px] border border-danger-light rounded-[5px] w-[56%] h-[34px] w-full max-w-[205px]"
                name="last_name_kana"
                value={userInformation.last_name_kana}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>

        <div className="flex min-h-[40px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[220px] bg-warning-lighter flex items-center pl-[14px]">
            セクション
          </div>
          <div className="flex flex-col gap-[10px] justify-center py-[9px]">
            {userInformation?.sections?.map((section, index) => (
              <div className="flex" key={index}>
                {section.parent_section_name && (
                  <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
                    {section.parent_section_name || ""}
                  </div>
                )}
                <div className="text-[13px] leading-[100%] flex items-center pl-[29px]">
                  {section.name || ""}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center items-center mb-[110px] mt-[18px]">
          <button
            style={{ backgroundColor: studentTheme.main_color_first }}
            className="bg-primary px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
            onClick={onSubmitHandle}
          >
            保存する
          </button>
        </div>
      </div>
    </>
  );
};

export default UserInformation;
