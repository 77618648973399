import React from "react";
import { ElementProps } from "../../../../types/globals";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type ReviewChangeButtonProps = {
  children: React.ReactNode;
} & ElementProps<HTMLDivElement>;

const ReviewChangeButton = ({
  children,
  className,
  ...props
}: ReviewChangeButtonProps) => {
  const { studentTheme } = useStudentThemeContext();

  return (
    <div
      style={{
        backgroundColor: studentTheme.main_color_first,
      }}
      className={`bg-primary font-[700] text-[11px] leading-[100%] p-[10px] text-white rounded-[5px] cursor-pointer ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default ReviewChangeButton;
