import React from "react";
import ProductList from "../../../components/student/Product/ProductList";
import ProductBase from ".";

const ListProductPage = () => {
  return (
    <ProductBase>
      <ProductList />
    </ProductBase>
  );
};

export default ListProductPage;
