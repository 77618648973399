import React, { useState, useEffect } from "react";
import {
  DAY_OF_THE_WEEK,
  MAX_BAR_HEIGHT,
} from "../../../services/constants/student/chart";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { STUDENT_THEME_COLOR_DEFAULT } from "../../../services/constants/admin/pages/student_theme";

/**
 * @deprecated since phase 2.0 - 2023/08/30
 */

const VerticalBar = ({
  blue = false,
  data,
  props,
}: {
  blue?: boolean;
  data: number[];
  props?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}) => {
  const maxTime = Math.max(...data) || 1;
  const { studentTheme } = useStudentThemeContext();
  const [style, setStyle] = useState<Object>({});
  
  useEffect(() => {
    const backgroundColor = blue
      ? studentTheme?.sub_color_first || STUDENT_THEME_COLOR_DEFAULT.MAIN_1
      : studentTheme?.main_color_second || STUDENT_THEME_COLOR_DEFAULT.MAIN_2;

    setStyle({
      backgroundColor: backgroundColor,
    });
  }, []);

  return (
    <div {...props}>
      <div className="flex flex-col items-center w-full w-[140px] min-h-[70px]">
        <div className="flex items-end flex-grow w-full mt-2 space-x-2">
          {DAY_OF_THE_WEEK.map((label, index) => (
            <div
              key={index}
              className="relative flex flex-col items-center flex-grow pb-5 group"
            >
              <div
                className={`relative flex justify-center w-full max-w-[10px]`}
                style={{
                  height: `${
                    ((data[index] || 0) / maxTime) * MAX_BAR_HEIGHT
                  }px`,
                  ...style,
                }}
              />
              <span className="absolute bottom-0 text-[10px] font-[500] leading-[100%] text-secondary">
                {label}
              </span>
              <div className="absolute bottom-[20px] w-[22px] h-[1px] bg-secondary-light"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VerticalBar;
