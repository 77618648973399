import { PATH_STUDY_TOOL } from "../route/router";

const LEARNING_TYPE = {
  READ: 1,
  WRITE: 2,
  WRITE_DONE: 3,
  READ_DONE: 4
};

const LEARNING_TOOLS = [
  {
    type: 1,
    label: '学習レポート',
    href: PATH_STUDY_TOOL.REPORT
  },
  {
    type: 2,
    label: 'スケジュール',
    href: PATH_STUDY_TOOL.SCHEDULE.DEFAULT
  },
  {
    type: 3,
    label: 'メモ',
    href: PATH_STUDY_TOOL.MEMO
  },
  {
    type: 4,
    label: '復習',
    href: PATH_STUDY_TOOL.REVISION_REVIEW
  },
];

const LEARNING_TOOL_TYPE = {
  READ: 1,
  SCHEDULE: 2,
  WRITE: 3,
  TEST: 4,
};

export { LEARNING_TYPE, LEARNING_TOOLS, LEARNING_TOOL_TYPE };
