import React from "react";
import TermOfUseBase from ".";
import ListTermOfService from "../../../components/admin/TermOfUse/TermOfService/List";

const TermOfService = () => {
  return (
    <TermOfUseBase title="システム設定｜ 利用規約">
      <ListTermOfService />
    </TermOfUseBase>
  );
};

export default TermOfService;
