import React, { ChangeEvent, useEffect } from 'react';
import { useState } from 'react';
import { makeRequest } from '../../../../services/axios/axios';
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
  swallConfirm,
} from '../../../../services/helpers/swal';
import { paramizeObject } from '../../../../services/helpers/parseData';
import { API } from '../../../../services/constants/route/api';
import FolderFilter from '../Filter';
import FolderTable from './FolderTable';
import { Folder } from '../../../../types/admin/folder';
import { HiPlus } from 'react-icons/hi';
import CircularProgress from '../../../commons/charts/CircularProgress';
import BaseModal from '../../commons/BaseModal';
import Spinner from '../../../commons/icons/Animations/Spinner';

type FilterType = {
  limit: number;
  page: number;
  sortType?: number;
};
const megabytesToGigabytes = (megabytes: number) => {
  return megabytes / 1024;
};
const FolderList = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    page: 1,
    sortType: 1,
  });
  const [folderName, setFolderName] = useState<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [usedStorage, setUsedStorage] = useState<number>(0);
  const [totalStorage, setTotalStorage] = useState<number>(0);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState<boolean>(false);
  const [updateFolderName, setUpdateFolderName] = useState<string>();
  const [updateFolderId, setUpdateFolderId] = useState<number | null>(null);
  const [errorsCreate, setErrorsCreate] = useState<string>('');
  const [errorsUpdate, setErrorsUpdate] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchFolder = async () => {
      // swalLoading();

      const [result, storageResults] = await Promise.all([
        makeRequest({
          method: 'get',
          url: `${API.ADMIN_FOLDER.LIST}?${paramizeObject(filter)}`,
        }),
        makeRequest({
          method: 'get',
          url: `${API.ADMIN_FOLDER.CAPACITY}`,
        }),
      ]);

      if (!result.data || storageResults.data === null) return swalError();
      checkSelectAll(result.data.resource_folders);
      setFolders(result.data.resource_folders);
      setTotalPages(result.data.total_page);
      setUsedStorage(megabytesToGigabytes(storageResults.data['used_storage']));
      setTotalStorage(storageResults.data.total);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetchFolder();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const resetTable = () => {
    setSelectedIds([]);
    setSelectAll(false);
    setCurrentPage(1);
  };
  const handleCreateFolder = async () => {
    // swalLoading();
    setIsSubmitting(true);
    const result = await makeRequest({
      method: 'post',
      url: `${API.ADMIN_FOLDER.CREATE}`,
      data: { name: folderName },
    });

    if (!result.status) {
      setIsSubmitting(false);
      if (result.message) {
        setErrorsCreate(result.message);
      }
      return swalError();
    }
    setIsSubmitting(false);
    setErrorsCreate('');
    resetTable();
    swalSuccess();
    setFolderName('');
    setIsOpenCreateModal(false);
    changePaginate(1);
  };

  const handleUpdateFolder = async () => {
    // swalLoading();
    const result = await makeRequest({
      method: 'patch',
      url: `${API.ADMIN_FOLDER.UPDATE}`,
      data: { resource_folder_id: updateFolderId, name: updateFolderName },
    });
    if (!result.status) {
      if (result.message) {
        setErrorsUpdate(result.message);
      }
      return swalError();
    }
    setErrorsUpdate('');
    resetTable();
    swalSuccess();
    setUpdateFolderId(null);
    setFolderName('');
    setIsOpenUpdateModal(false);
    changePaginate(1);
  };
  const checkSelectAll = (currentFolders: Folder[]) => {
    let currentPageFolderIds = currentFolders.map((folder) => folder.id);
    let allCurrentPageFoldersSelected = currentPageFolderIds.every((id) =>
      selectedIds.includes(id),
    );
    if (allCurrentPageFoldersSelected) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedIds(folders.map((s) => s.id));
    } else {
      setSelectedIds([]);
    }
  };

  const deleteSelected = async () => {
    if (selectedIds.length === 0) {
      return;
    }

    let messageTitle = "チェックしたカテゴリを削除し<br/>てよろしいですか？ ";
    let cancelText = "キャンセル";

    const selectedFolders = folders.filter((folder) => selectedIds.includes(folder.id));
    const checkAllFolderEmpty = selectedFolders.every((folder) => folder.file_quantity === 0);

    if(!checkAllFolderEmpty) {
      messageTitle = "カテゴリーを削除するにはカテゴリ内のファイルを削除してください。";
      cancelText = "閉じる";
    }

    swallConfirm(
      async () => {
        // swalLoading();
        const result = await makeRequest({
          method: 'delete',
          url: API.ADMIN_FOLDER.DELETE_FOLDER,
          data: { resource_folder_id: selectedIds },
        });
        if (!result.status) {
          return swalError();
        }
        resetTable();
        swalSuccess();
        changePaginate(1);
      }, 
      messageTitle, '', "削除する", false, checkAllFolderEmpty, cancelText
    );
  };

  const toggleSelect = (id: number) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((sId) => sId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const isFolderSelected = (id: number) => selectedIds.includes(id);

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  const changeHandle = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <div>
      <div className="flex justify-between w-full mt-[40px] mb-[53px]">
        <div>
          <div className="text-[12px] font-[400] mb-[15px] leading-[190%]">
            動画や写真ファイルを分類して保存するための「カテゴリ（フォルダ）」を作成します。
            <br />
            カテゴリを作成した後にファイルをアップロートすることができます。
            <br />
            ※カテゴリ内にカテゴリは作成できません
            <br />
          </div>
          <button
            className={`bg-primary text-white text-[14px] font-[700] leading-[17px] flex items-end py-[10px] px-[30px] rounded-[10px] min-h-[34px] cursor-pointer`}
            onClick={() => setIsOpenCreateModal(true)}
          >
            <HiPlus size={20} />
            <div className="ml-[8px]">新規カテゴリを登録する</div>
          </button>
        </div>
        {!!totalStorage ? (
          <div className="flex w-full max-w-[280px] h-[96px] border rounded-[5px] items-center justify-center">
            <div className="w-full max-w-[61px] h-[61px] relative">
              <div className="absolute top-[3px] left-0 w-full h-[61px] rounded-full bg-white flex items-center justify-center">
                <div className="text-center">
                  <div className={`text-[12px] font-[500] leading-[100%] mb-[10 px] `}>
                    <span className="text-[16.8px] font-[700] leading-[100%] with_roboto text-primary">
                      {((Number(usedStorage.toFixed(2)) / totalStorage) * 100).toFixed(2)}
                    </span>
                    <span className="text-[8.4px] font-[500] text-primary leading-[100%]">%</span>
                  </div>
                </div>
              </div>
              <CircularProgress
                blue={true}
                sqSize={80}
                strokeWidth={8}
                percentage={(usedStorage / totalStorage) * 100}
                className="absolute top-[-8px] left-[-11px]"
              />
            </div>
            <div className="text-[12px] font-[500] ml-[16px]">
              ストレージ容量：
              <span className="text-[16px] text-primary">{`${totalStorage}GB`}</span>
              <br />
              {`使用中：${usedStorage.toFixed(2)}GB`}
            </div>
          </div>
        ) : (
          <div className="skeleton w-[280px] h-[96px] rounded-[5px] bg-secondary-lighter"></div>
        )}
      </div>
      <FolderFilter changeHandle={changeHandle} deleteSelected={deleteSelected} />
      <FolderTable
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        toggleSelect={toggleSelect}
        selectAll={selectAll}
        toggleSelectAll={toggleSelectAll}
        isFolderSelected={isFolderSelected}
        folders={folders}
        totalPages={totalPages}
        setIsOpenUpdateModal={setIsOpenUpdateModal}
        setUpdateFolderId={setUpdateFolderId}
        setUpdateFolderName={setUpdateFolderName}
      />
      {isOpenCreateModal && (
        <BaseModal
          setIsOpen={setIsOpenCreateModal}
          width={1010}
          height={616}
          justifyDirection="end"
          onClick={handleCreateFolder}
          submitLabel="新規作成"
        >
          <div className="text-center pt-[222px] pb-[167px]">
            <div className="text-[16px] font-[700] leading-[100%] tracking-[0.02em] text-primary mb-[24px]">
              カテゴリを新規登録する
            </div>
            <div className="mb-[12px]">新規カテゴリ（フォルダ）を作成します</div>

            <div className="w-full flex flex-col items-center justify-center">
              <div className="relative w-[550px]">
                <input
                  type="text"
                  name="name"
                  id=""
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  className="h-[40px] border !border-secondary-light mb-[18px] pl-[13px] text-[12px] leading-[100%] placeholder-secondary-light"
                  placeholder="カテゴリ名を入力してください。"
                />
                {isSubmitting && (
                  <div className="absolute top-[8px] right-[15px]">
                    <Spinner color="#7A7A7A" />
                  </div>
                )}
              </div>
              {errorsCreate && (
                <div className="text-[12px] font-[500] leading-[190%] text-danger">
                  {errorsCreate}
                </div>
              )}
            </div>
          </div>
        </BaseModal>
      )}
      {isOpenUpdateModal && (
        <BaseModal
          setIsOpen={setIsOpenUpdateModal}
          width={1010}
          height={616}
          justifyDirection="end"
          onClick={handleUpdateFolder}
          submitLabel="保存"
        >
          <div className="text-center pt-[222px] pb-[167px]">
            <div className="text-[16px] font-[700] leading-[100%] tracking-[0.02em] text-primary mb-[24px]">
              カテゴリを編集する
            </div>
            <div className="mb-[12px]">カテゴリ（フォルダ）を編集します</div>

            <div className="">
              <input
                type="text"
                name="name"
                id=""
                value={updateFolderName}
                onChange={(e) => setUpdateFolderName(e.target.value)}
                className="max-w-[550px] h-[40px] border !border-secondary-light mb-[18px] pl-[13px] text-[12px] leading-[100%] placeholder-secondary-light"
                placeholder="カテゴリ名を入力してください。"
              />
              {errorsUpdate && (
                <div className="text-[12px] font-[500] leading-[190%] text-danger">
                  {errorsUpdate}
                </div>
              )}
            </div>
          </div>
        </BaseModal>
      )}
    </div>
  );
};

export default FolderList;
