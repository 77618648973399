import MaterialBase from '.';
import UnitCreate from '../../../components/admin/Material/Upsert/UnitCreate';
import UpsertUnitProvider from '../../../context/UpsertUnitContext';

const MaterialUnitUpsertPage = () => {
  return (
    <MaterialBase title="教材設定 ｜ 教材・テストの新規登録">
      <UpsertUnitProvider>
        <UnitCreate />
      </UpsertUnitProvider>
    </MaterialBase>
  );
};

export default MaterialUnitUpsertPage;
