import React from 'react';

const LearningWrite = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={15} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        clipPath="url(#a)"
        stroke={`${props.color || '#fff'}`}
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7.8 12.336 12.336 7.8l1.944 1.944-4.536 4.536L7.8 12.336Z" />
        <path d="m11.688 8.448-.972-4.86L1.32 1.32l2.268 9.396 4.86.972 3.24-3.24ZM1.32 1.32l4.916 4.916" />
        <path d="M7.152 8.448a1.296 1.296 0 1 0 0-2.592 1.296 1.296 0 0 0 0 2.592Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill={`${props.color || '#fff'}`}
            transform="translate(.6 .6)"
            d="M0 0h14.4v14.4H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LearningWrite;
