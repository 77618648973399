/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useState } from "react";
import { Course } from "../../../../../types/admin/course";
import { Lecture } from "../../../../../types/admin/lecture";
import { Chapter } from "../../../../../types/admin/chapter";
import { makeRequest, request } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";
import { useObjectRoutes } from "../../../../../hooks/useObjectRoutes";
import { useUpsertUnitContext } from "../../../../../context/UpsertUnitContext";

type UnitChapterSelectProps = {
  value?: number | string;
  onChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
  unitId?: string | null | number;
};

type SelectedCourse = {
  course_id?: number;
  lecture_id?: number;
  chapter_id?: number;
};

const UnitChapterSelect = ({ value, onChange, unitId }: UnitChapterSelectProps) => {
  const { courses, lectures, chapters } = useUpsertUnitContext();
  const [selectedCourse, setSelectedCourse] = useState<SelectedCourse>({
    chapter_id: Number(value) || 0,
  });
  const [filteredLectures, setFilteredLectures] = useState<Lecture[]>([]);
  const [filteredChapters, setFilteredChapters] = useState<Chapter[]>([]);

  //selected for current navigating from list
  useEffect(() => {
    if (!courses.length || !lectures.length || !chapters.length) return;

    const currentChapter = chapters.find(
      (chapter) => chapter.id === Number(selectedCourse.chapter_id)
    );
    if (!currentChapter) return;

    const currentLecture = lectures.find(
      (lecture) => lecture.id === Number(currentChapter.lecture_id)
    );
    if (!currentLecture) return;

    const currentCourse = courses.find(
      (course) => course.id === Number(currentLecture.course_id)
    );
    if (!currentCourse) return;

    setSelectedCourse({
      course_id: currentCourse.id,
      lecture_id: currentLecture.id,
      chapter_id: currentChapter.id,
    });
  }, [courses, lectures, chapters]);

  //setting course, lecture values based on passed in chapter_id
  useEffect(() => {
    if (!value) return;

    const chapter = chapters.find((chapter) => chapter.id === Number(value));
    if (!chapter) return;

    const lecture = lectures.find(
      (lecture) => lecture.id === Number(chapter.lecture_id)
    );
    if (!lecture) return;

    const course = courses.find(
      (course) => course.id === Number(lecture.course_id)
    );
    if (!course) return;

    setSelectedCourse({
      course_id: course.id,
      lecture_id: lecture.id,
      chapter_id: chapter.id,
    });
  }, [value, courses, lectures, chapters]);

  //filtering for lectures, chapters
  useEffect(() => {
    if (selectedCourse.course_id)
      setFilteredLectures([
        ...lectures.filter(
          (lecture) => lecture.course_id === Number(selectedCourse.course_id)
        ),
      ]);
    if (selectedCourse.lecture_id)
      setFilteredChapters([
        ...chapters.filter(
          (lecture) => lecture.lecture_id === Number(selectedCourse.lecture_id)
        ),
      ]);
  }, [selectedCourse]);

  // onChange handle for courses section only
  const handleChangeCourse = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse({ ...selectedCourse, [e.target.name]: e.target.value });
  };

  return (
    <div className="flex w-full gap-[25px]">
      <select
        name="course_id"
        id=""
        className={`w-full pl-[9.5px] pr-[20px] truncate ${
          selectedCourse.course_id ? "text-black" : "text-secondary-light"
        } ${!!unitId ? "bg-none" : ""}`}
        onChange={handleChangeCourse}
        disabled={!!unitId}
        value={selectedCourse.course_id || 0}
      >
        <option value="0">コースを選択してください</option>
        {courses &&
          courses.length &&
          courses.map((course) => (
            <option value={course.id} key={course.id}>
              {course.name}
            </option>
          ))}
      </select>

      <select
        name="lecture_id"
        id=""
        className={`w-full pl-[9.5px] pr-[20px] truncate ${
          selectedCourse.lecture_id ? "text-black" : "text-secondary-light"
        } ${!!unitId ? "bg-none" : ""}`}
        onChange={handleChangeCourse}
        disabled={!!unitId}
        value={selectedCourse.lecture_id || 0}
      >
        <option value="0">講義を選択してください</option>
        {filteredLectures &&
          filteredLectures &&
          filteredLectures.map((lecture) => (
            <option value={lecture.id} key={lecture.id}>
              {lecture.name}
            </option>
          ))}
      </select>

      <select
        name="chapter_id"
        id=""
        className={`w-full pl-[9.5px] pr-[20px] truncate ${
          selectedCourse.chapter_id ? "text-black" : "text-secondary-light"
        } ${!!unitId ? "bg-none" : ""}`}
        value={selectedCourse.chapter_id || 0}
        disabled={!!unitId}
        onChange={onChange}
      >
        <option value="0">章を選択してください</option>
        {filteredChapters &&
          filteredChapters.length &&
          filteredChapters.map((chapter) => (
            <option value={chapter.id} key={chapter.id}>
              {chapter.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default UnitChapterSelect;
