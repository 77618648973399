import { useState } from 'react';
import TableCommonAction from '../../commons/TableCommonAction';
import SectionTitle from '../Title';
import Form from './Form';

const Setting = () => {
  const [title, setTitle] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <section>
      <TableCommonAction onCreate={() => setIsOpenModal(true)}/>
      <SectionTitle isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} content={title} withSubtitle withButton/>
      <div className="">
        <Form setTitle={setTitle}/>
      </div>
    </section>
  );
};

export default Setting;
