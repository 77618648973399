import { ChangeEvent } from "react";
import { CartItem } from "../../../types/student/cart";
import StudentCartTable from "./StudentCartTable";
import { CART_COMMON } from "../../../services/constants/student/cart";

type StudentCartTableProps = {
  cartItems: CartItem[];
  selectedIds: number[];
  handleMove: (status: number) => void;
  toggleSelect: (id: number) => void;
  isSelected: (id: number) => boolean;
  handleDelete: (artItemId?: number, status?: number) => void;
  handleChangeQuantity: (
    e: ChangeEvent<HTMLInputElement>,
    cartItemId: number
  ) => void;
};

const StudentCartBuyLater = ({
  cartItems,
  selectedIds,
  handleMove,
  toggleSelect,
  isSelected,
  handleDelete,
  handleChangeQuantity,
}: StudentCartTableProps) => {
  return (
    <div className="w-full bg-success-lighter py-[15px] px-[10px] mb-[50px]">
      <div className="flex items-center justify-between">
        <div className="text-[14px] text-left font-[600]">
          「あとで買う」に入っている商品
        </div>
        <div className="flex items-center gap-[5px] text-[12px]">
          <span className="font-[400]">チェックした商品を</span>
          <button
            className={`px-[10px] py-[2px] bg-danger rounded-[5px] text-white hover:opacity-80 ${
              selectedIds.length ? "active:scale-90" : ""
            }`}
            disabled={!selectedIds.length}
            onClick={() => handleDelete(undefined, CART_COMMON.CART_BUY_LATER)}
          >
            削除
          </button>
          <button
            className={`px-[10px] py-[2px] bg-white border border-secondary-light text-secondary rounded-[5px] hover:opacity-80 ${
              selectedIds.length ? "active:scale-90" : ""
            }`}
            disabled={!selectedIds.length}
            onClick={() => handleMove(CART_COMMON.CART_IN_SHOP)}
          >
            カートに戻す
          </button>
        </div>
      </div>
      <div className="mt-[15px]">
        <StudentCartTable
          cartItems={cartItems?.filter(
            (cartItem) => cartItem.status === CART_COMMON.CART_BUY_LATER
          )}
          isSelected={isSelected}
          toggleSelect={toggleSelect}
          handleDelete={handleDelete}
          handleChangeQuantity={handleChangeQuantity}
        />
      </div>
    </div>
  );
};

export default StudentCartBuyLater;
