import React from "react";
import { Community } from "../../../types/student/community";

const CommunityListItem = ({
  data,
  isLast,
}: {
  data: Community;
  isLast: boolean;
}) => {
  return (
    <div
      className={`w-full max-w-[220px] min-[320px]:max-w-full ${
        !isLast && "border-b pb-[8px] mb-[8px]"
      } border-dashed border-danger-light`}
    >
      <div className="bg-white p-[8px] w-full max-sm:max-h-[48px]">
        <div className="flex mb-[8px]">
          <div className="mr-[10px]">
            {data?.avatar_url ? (
              <img
                src={data.avatar_url}
                alt={data.name}
                className="w-[30px] h-[30px]"
              />
            ) : (
              <div className="w-[30px] h-[30px] bg-secondary-light rounded-[5px]"></div>
            )}
          </div>
          <div className="font-[500] text-[12px] leading-[120%] tracking-[0.02em] text-primary max-sm:hidden">
            {data.title}
          </div>
          <div className="hidden max-sm:flex max-sm:flex-col max-w-[270px]">
            <div className="font-[500] text-[12px] leading-[120%] tracking-[0.02em] text-primary max-[320px]:max-w-[200px] truncate">
              {data.title}
            </div>
            <div className="font-[500] text-[10px] leading-[14px] tracking-[0.02em] text-secondary max-[320px]:max-w-[200px] truncate">
              {data.content}
            </div>
          </div>
        </div>
        <div className="font-[500] text-[10px] leading-[14px] tracking-[0.02em] text-secondary max-sm:hidden">
          {data.content}
        </div>
      </div>
    </div>
  );
};

export default CommunityListItem;
