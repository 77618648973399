import React from 'react';
import Setting from '../../../components/admin/Section/Setting/Setting';
import SectionBase from '.';

const SectionSettingPage = () => {
  return (
    <SectionBase title="セクション管理 ｜ 設定">
      <Setting />
    </SectionBase>
  );
};

export default SectionSettingPage;
