import React from "react";

type ButtonPaymentMethodType = {
  payment_method_choose: string;
  payment_value: string;
  payment_label: string;
  handleClick: (payment_value: string) => void;
};

const ButtonPaymentMethod = ({
  payment_method_choose,
  payment_value,
  payment_label,
  handleClick,
}: ButtonPaymentMethodType) => {
  return (
    <button
      className={`w-[133px] p-[10px] border border-secondary-light rounded-[5px] text-center text-[14px] text-black font-[500] tracking-[0.1px] ${
        payment_method_choose === payment_value
          ? "shadow-md bg-secondary-light"
          : ""
      }`}
      onClick={() => handleClick(payment_value)}
    >
      {payment_label}
    </button>
  );
};

export default ButtonPaymentMethod;
