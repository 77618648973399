import { Navigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import FeaturePurchasedContextProvider from "../context/FeaturePurchasedContext";

type CheckRoleAdminProps = {
  children: JSX.Element | JSX.Element[]
}

const CheckRoleAdmin = ({ children }: CheckRoleAdminProps) => {
  let user = localStorage.getItem('user');
  let is_student = false;
  if (user) {
    is_student = JSON.parse(user)?.is_student;
  }
  return (
    <>
      {
        !is_student ? (
          <FeaturePurchasedContextProvider>
            {children}
          </FeaturePurchasedContextProvider>
        ) : (
          <Navigate to={ROUTE.MY_PAGE.DEFAULT} replace={true}/>
        )
      }
    </>
  )
}

export default CheckRoleAdmin;
