import { useNavigate } from 'react-router-dom';
import { LEARNING_TOOLS } from '../../../services/constants/student/learning';
import LearningToolType from './LearningToolType';

const LearningTool = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full border border-danger-light rounded-[5px] pb-[11px] animate-[show_0.5s_ease-in-out]">
      <div className="mt-[20px] mx-[21px] mb-[14px] font-[500] text-[14px] leading-[100%]">
        学習ツール
      </div>
      <div className="flex gap-[5px] justify-center">
        {LEARNING_TOOLS.map((tool, index) => (
          <div
            className="border border-danger-light rounded-[5px] w-[105px] h-[85px] cursor-pointer hover:bg-[#00000026]"
            key={tool.type}
            onClick={() => navigate(tool.href)}
          >
            <div className="mt-[20.75px] flex justify-center" style={{ marginBottom: 15 - index }}>
              <LearningToolType type={tool.type} />
            </div>
            <div className="font-[500] text-[12px] leading-[100%] flex justify-center ">
              {tool.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LearningTool;
