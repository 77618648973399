/* eslint-disable react-hooks/exhaustive-deps */
import { isUsableArr } from "../../../../services/helpers/etc";
import { useState, useEffect } from "react";
import { Lecture } from "../../../../types/student/lecture";
import { RiDeleteBinLine } from "react-icons/ri";
import { ReportRatioFilter, ReportRatioFilterData } from "./ChartStudyRatio";
import { CourseOption } from "./ReportBoard";
import { SetStateAction } from "../../../../types/globals";
import { truncate } from "../../../../services/helpers/parseData";

type ChartStudyRatioOptionProps = {
  isLast: boolean;
  isFirst: boolean;
  optionGroup: ReportRatioFilterData;
  courseOptions?: CourseOption[];
  chosenLectureIds: number[];
  setCurrentFilter?: SetStateAction<ReportRatioFilter>;
};

const ChartStudyRatioOption = ({
  isLast,
  isFirst,
  optionGroup,
  courseOptions,
  chosenLectureIds,
  setCurrentFilter,
}: ChartStudyRatioOptionProps) => {
  const initOptionData = {
    course_id: 0,
    lecture_id: 0,
  };

  const [lectures, setLectures] = useState<Lecture[]>([]);
  const [optionData, setOptionData] = useState<ReportRatioFilterData>(
    optionGroup || initOptionData
  );
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let currentOptionData = optionData;
    if (e.target.name === "course_id") {
      currentOptionData = { ...currentOptionData, lecture_id: 0 };
      const currentCourse = courseOptions?.find(
        (course) => course.id === Number(e.target.value)
      );
      setLectures(currentCourse?.lectures || []);
    }
    currentOptionData = {
      ...currentOptionData,
      [e.target.name]: Number(e.target.value),
    };
    setOptionData(currentOptionData);

    if (e.target.name === "course_id") return;
    setCurrentFilter?.((currentFilter) => {
      let newOptions = currentFilter.data || [];
      let modifiedOptionIndex = newOptions.findIndex(
        (option) => option.id === optionGroup.id
      );

      newOptions[modifiedOptionIndex] = {
        ...newOptions[modifiedOptionIndex],
        ...currentOptionData,
      };

      return { ...currentFilter, data: newOptions };
    });
  };

  const removeHandle = (optionId: number) => {
    setCurrentFilter?.((currentFilter) => {
      let newOptions = currentFilter.data || [];
      newOptions = newOptions.filter((option) => option.id !== optionId);
      return { ...currentFilter, data: newOptions };
    });
  };

  useEffect(() => {
    const initialLectures =
      courseOptions?.find((course) => course.id === optionGroup.course_id)
        ?.lectures || [];
    setLectures(initialLectures);
  }, [courseOptions]);

  return (
    <div className="flex items-center gap-[31px] pl-[8px] mt-[10px] mb-[5px] text-secondary">
      <div className="flex flex-col">
        {isFirst && "コース"}
        <select
          name="course_id"
          className="h-[32px] rounded-[16px] text-[11px] border border-danger-light w-[calc(100%+25px)]"
          onChange={handleChange}
          value={optionData.course_id}
        >
          <option value="">コースを選択</option>
          {isUsableArr(courseOptions) &&
            courseOptions?.map((course) => (
              <option key={course.id} value={course.id}>
                {truncate(course.name, 50)}
              </option>
            ))}
        </select>
      </div>
      <div className="flex flex-col w-full max-w-[250px]">
        {isFirst && "講義"}
        <select
          name="lecture_id"
          className="h-[32px] rounded-[16px] text-[11px] border border-danger-light w-[calc(100%+40px)] max-sm:w-full"
          onChange={handleChange}
          value={optionData.lecture_id}
        >
          <option value="0">講義を選択</option>
          {isUsableArr(lectures) &&
            lectures.map((lecture) => (
              <option
                key={lecture.id}
                value={lecture.id}
                disabled={chosenLectureIds.includes(Number(lecture.id))}
                className={`${
                  chosenLectureIds.includes(Number(lecture.id)) &&
                  "bg-secondary-lighter"
                }`}
              >
                {truncate(lecture.name, 50)}
              </option>
            ))}
        </select>
      </div>
      {!isLast && (
        <div
          className={`text-gray cursor-pointer ml-[20px] ${
            isFirst && "mb-[-13px]"
          }`}
        >
          <RiDeleteBinLine
            size={18}
            onClick={() => removeHandle(optionGroup.id)}
          />
        </div>
      )}
    </div>
  );
};

export default ChartStudyRatioOption;
