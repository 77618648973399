import { Quill } from "react-quill";

const CustomToolBarProduct = ({
  id,
  className,
}: {
  id: string;
  className?: string;
}) => {
  let icons = Quill.import("ui/icons");
  icons[
    "undo"
  ] = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
  <path d="M10.5 3.25H2.88438L5.12625 1.00875L4.25 0.125L0.5 3.875L4.25 7.625L5.12625 6.74062L2.88625 4.5H10.5C11.4946 4.5 12.4484 4.89509 13.1517 5.59835C13.8549 6.30161 14.25 7.25544 14.25 8.25C14.25 9.24456 13.8549 10.1984 13.1517 10.9017C12.4484 11.6049 11.4946 12 10.5 12H5.5V13.25H10.5C11.8261 13.25 13.0979 12.7232 14.0355 11.7855C14.9732 10.8479 15.5 9.57608 15.5 8.25C15.5 6.92392 14.9732 5.65215 14.0355 4.71447C13.0979 3.77678 11.8261 3.25 10.5 3.25Z" fill="#2E2E2E"/>
  </svg>`;

  icons[
    "redo"
  ] = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
  <path d="M5.5 3.25H13.1156L10.8737 1.00875L11.75 0.125L15.5 3.875L11.75 7.625L10.8737 6.74062L13.1137 4.5H5.5C4.50544 4.5 3.55161 4.89509 2.84835 5.59835C2.14509 6.30161 1.75 7.25544 1.75 8.25C1.75 9.24456 2.14509 10.1984 2.84835 10.9017C3.55161 11.6049 4.50544 12 5.5 12H10.5V13.25H5.5C4.17392 13.25 2.90215 12.7232 1.96447 11.7855C1.02678 10.8479 0.5 9.57608 0.5 8.25C0.5 6.92392 1.02678 5.65215 1.96447 4.71447C2.90215 3.77678 4.17392 3.25 5.5 3.25Z" fill="#2E2E2E"/>
  </svg>`;

  icons[
    "code-block"
  ] = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none">
  <path d="M19.375 5L15 9.375L14.1187 8.49375L17.6063 5L14.1187 1.50625L15 0.625L19.375 5ZM0.625 5L5 0.625L5.88125 1.50625L2.39375 5L5.88125 8.49375L5 9.375L0.625 5Z" fill="#2E2E2E"/>
</svg>`;

  const colors = [
    "black",
    "white",
    "red",
    "lime",
    "blue",
    "yellow",
    "cyan",
    "pink",
    "silver",
    "gray",
    "maroon",
    "olive",
    "green",
    "purple",
    "teal",
    "navy",
  ];

  document
    .querySelectorAll(".ql-color, .ql-font, .ql-header, .ql-link, .ql-align")
    .forEach((tool) => {
      tool.addEventListener("mousedown", function (event) {
        event.preventDefault();
        event.stopPropagation();
      });
    });

  return (
    <div id={id} className={`bg-secondary-extralight ${className}`}>
      <span className="ql-formats">
        <button className="ql-undo"></button>
        <button className="ql-redo"></button>
        <select className="ql-header" defaultValue="">
          <option value="1" />
          <option value="2" />
          <option value="3" />
          <option value="4" />
          <option value="" />
        </select>
        <select className="ql-align" defaultValue={""}>
          <option value={"center"}></option>
          <option value={"right"}></option>
          <option value={"justify"}></option>
          <option value={""}></option>
        </select>
        <select className="ql-color">
          {colors.map((col, index) => (
            <option value={col} key={index} />
          ))}
        </select>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-strike"></button>
        <button className="ql-code-block"></button>
        <button className="ql-list" value={"ordered"}></button>
        <button className="ql-list" value={"bullet"}></button>
        <button className="ql-link"></button>
      </span>
    </div>
  );
};

export default CustomToolBarProduct;
