import { Answer, TestData } from "../../../../types/student/unit";
import Correct from "../../../commons/icons/Learning/Correct";
import Incorrect from "../../../commons/icons/Learning/Incorrect";
import { SubmitAnswer, SubmitData } from "./ExecuteTest";
import Unknown from "../../../commons/icons/Learning/Unknown";
import {
  KATAKANA_ALPHABET,
  QUESTION_TYPE,
} from "../../../../services/constants/admin/pages/material";

type AnswerContentType = {
  questionOrder: number;
  submitQuestionData?: SubmitData["questions"];
  questions?: TestData["questions"];
  className?: string;
  isShowAnswer: boolean;
  setIsShowAnswer: React.Dispatch<React.SetStateAction<boolean>>;
};

type ShowCorrectAnswerColumnType = {
  content?: string;
  isTrue?: boolean;
  linkImage?: string;
};

type ShowIsCorrecSignColumnType = {
  answerId?: number;
  answerFillIn?: SubmitAnswer;
  isTrue?: boolean;
};

const QUESTION_TYPE_RADIO = [
  QUESTION_TYPE.RADIO_BUTTON,
  QUESTION_TYPE.IMAGE_SELECTION,
  QUESTION_TYPE.LISTENING,
];

const QUESTION_SHOW_IS_CORRECT_COLUMN = [
  QUESTION_TYPE.PULL_DOWN_SELECTION,
  QUESTION_TYPE.IMAGE_SELECTION,
];

const QUESTION_SHOW_NUMBER_CORRECT_ANSWERS = [
  QUESTION_TYPE.PULL_DOWN_SELECTION,
  QUESTION_TYPE.CHECK_BOX,
  QUESTION_TYPE.FILL_IN_BLANK,
];

const SHOW_IS_CORRECT_SIGN = [
  QUESTION_TYPE.CHECK_BOX,
  QUESTION_TYPE.RADIO_BUTTON,
  QUESTION_TYPE.LISTENING,
];

const AnswerContent = ({
  questionOrder,
  submitQuestionData,
  questions,
  className,
  isShowAnswer,
  setIsShowAnswer,
}: AnswerContentType) => {
  if (!submitQuestionData || !questions) return <></>;

  const currentQuestion = questions[questionOrder - 1];
  const answersQuestion = currentQuestion.answers;
  const currentQuestionSubmit = submitQuestionData[0];
  const answersQuestionSubmit = currentQuestionSubmit.answers;
  const questionType = Number(currentQuestion.type);
  const studentThemeStorage = localStorage.getItem("theme");
  const studentTheme = studentThemeStorage
    ? JSON.parse(studentThemeStorage)
    : "";

  const isShowRightWrongColumn = (questionType: number): boolean =>
    QUESTION_SHOW_IS_CORRECT_COLUMN.includes(questionType);
  const isCorrectAnswer = (answerId: number) =>
    answersQuestionSubmit.includes(answerId);
  const isCorrectQuestionTypeFillIn = (answer: SubmitAnswer) => {
    if (typeof answer === "number") return;

    let findAnswer = answersQuestion
      .filter(
        (answerQuestion) => answerQuestion.yield_code === answer.yield_code
      )
      .find(
        (answerQuestion) =>
          answerQuestion.content?.toLowerCase() === answer.value.toLowerCase()
      );

    return typeof findAnswer !== "undefined";
  };

  const isCorrectQuestion = () => {
    let totalAnswerCorrect = 0;

    if (questionType === QUESTION_TYPE.PULL_DOWN_SELECTION) {
      let answersCorrect = currentQuestion.answers.filter(
        (question) => Number(question.is_true) === 1
      );
      let answersDefault = currentQuestion.answers.filter(
        (question) => Number(question.is_default) === 1
      );

      answersDefault.forEach((answer, index) => {
        if (isCorrectAnswer(Number(answersCorrect[index].id)))
          ++totalAnswerCorrect;
      });

      return totalAnswerCorrect === answersCorrect.length;
    }

    if (questionType === QUESTION_TYPE.CHECK_BOX) {
      let isCorrect = true;
      answersQuestion.forEach((answer) => {
        if (isCorrectAnswer(Number(answer.id)) && !answer.is_true)
          isCorrect = false;
      });

      return isCorrect;
    }

    if (QUESTION_TYPE_RADIO.includes(questionType)) {
      let isCorrect = false;
      answersQuestion.forEach((answer) => {
        if (isCorrectAnswer(Number(answer.id)) && answer.is_true)
          isCorrect = true;
      });

      return isCorrect;
    }

    if (questionType === QUESTION_TYPE.FILL_IN_BLANK) {
      answersQuestionSubmit.forEach((answer) => {
        if (isCorrectQuestionTypeFillIn(answer)) ++totalAnswerCorrect;
      });

      return totalAnswerCorrect === answersQuestionSubmit.length;
    }
  };

  const amountAnswersCorrect = () => {
    let amountCorrect = 0;

    if (questionType === QUESTION_TYPE.PULL_DOWN_SELECTION) {
      let answersCorrect = currentQuestion.answers.filter(
        (question) => Number(question.is_true) === 1
      );
      let answersDefault = currentQuestion.answers.filter(
        (question) => Number(question.is_default) === 1
      );

      answersDefault.forEach((answer, index) => {
        if (isCorrectAnswer(Number(answersCorrect[index].id))) ++amountCorrect;
      });
    }

    if (questionType === QUESTION_TYPE.CHECK_BOX) {
      answersQuestion.forEach((answerQuestion) => {
        if (
          answerQuestion.is_true &&
          isCorrectAnswer(Number(answerQuestion.id))
        )
          ++amountCorrect;
      });
    }

    if (questionType === QUESTION_TYPE.FILL_IN_BLANK) {
      answersQuestionSubmit.forEach((answerQuestion) => {
        if (isCorrectQuestionTypeFillIn(answerQuestion)) ++amountCorrect;
      });
    }

    return amountCorrect;
  };

  const AnswerCorrect = ({
    content,
    className,
  }: {
    content: string;
    className?: string;
  }) => {
    return (
      <div className={`flex items-center gap-[15px] ${className}`}>
        <Correct />
        <span className="w-[90px]">{content}</span>
      </div>
    );
  };

  const AnswerWrong = ({
    content,
    className,
  }: {
    content: string;
    className?: string;
  }) => {
    return (
      <div className={`flex items-center gap-[15px] ${className}`}>
        <Incorrect />
        <span className="w-[90px]">{content}</span>
      </div>
    );
  };

  const HeaderAnswer = () => {
    let isCheckQuestionType =
      QUESTION_SHOW_NUMBER_CORRECT_ANSWERS.includes(questionType);
    let totalAnswer = 0;
    if (questionType === QUESTION_TYPE.PULL_DOWN_SELECTION) {
      totalAnswer = currentQuestion.answers.filter(
        (question) => Number(question.is_default) === 1
      ).length;
    }
    if (questionType === QUESTION_TYPE.CHECK_BOX) {
      totalAnswer = answersQuestion.filter((answer) => {
        if (answer.is_true) return answer;
      }).length;
    }

    if (questionType === QUESTION_TYPE.FILL_IN_BLANK)
      totalAnswer = answersQuestionSubmit.length;

    return (
      <div className="flex items-center gap-[15px] px-[25px] py-[20px] text-[20px] font-bold">
        {isCorrectQuestion() ? (
          <AnswerCorrect content={isCheckQuestionType ? "全問正解" : "正解"} />
        ) : (
          <AnswerWrong content="不正解" />
        )}
        {isCheckQuestionType && (
          <div>
            {amountAnswersCorrect()} 問正解／ {totalAnswer}問中
          </div>
        )}
      </div>
    );
  };

  const ShowQuestion = ({ answer }: { answer: Answer }) => {
    return questionType === 1 && Number(answer.is_default) === 1 ? (
      <>{answer.content}</>
    ) : (
      <></>
    );
  };

  const ShowAnswerChoosed = ({ answer }: { answer?: Answer }) => {
    if (QUESTION_TYPE_RADIO.includes(questionType))
      return (
        <input
          style={{
            color: studentTheme.sub_color_first,
            // backgroundColor: studentTheme.sub_color_first
          }}
          type="radio"
          className="checked:bg-primary-light"
          defaultChecked={isCorrectAnswer(Number(answer?.id))}
          disabled
        />
      );

    if (questionType === QUESTION_TYPE.CHECK_BOX)
      return (
        <input
          style={{
            color: studentTheme.sub_color_first,
          }}
          type="checkbox"
          className="pointer-events-none"
          defaultChecked={isCorrectAnswer(Number(answer?.id))}
          disabled
        />
      );

    return (
      <span dangerouslySetInnerHTML={{ __html: answer?.content || "" }}></span>
    );
  };

  const ShowMyAnswer = ({
    answer,
    answerFill,
  }: {
    answer?: Answer;
    answerFill?: string;
  }) => {
    let content;
    if (questionType === QUESTION_TYPE.FILL_IN_BLANK)
      content = answerFill || <Unknown />;

    if (typeof answer !== "undefined")
      content = <ShowAnswerChoosed answer={answer} />;

    return (
      <td className="w-[72px] px-[15px]">
        <div className="flex justify-center px-[15px]">{content}</div>
      </td>
    );
  };

  const ShowIsCorrectColumn = ({
    answer,
    index,
  }: {
    answer: Answer;
    index: number;
  }) => {
    if (isShowRightWrongColumn(questionType)) {
      return (
        <td className="w-[60px]">
          <div className="flex justify-center">
            {questionType === QUESTION_TYPE.IMAGE_SELECTION ? (
              KATAKANA_ALPHABET[index]
            ) : (
              <ShowQuestion answer={answer} />
            )}
          </div>
        </td>
      );
    }

    return <></>;
  };

  const ShowIsCorrectSignColumn = ({
    answerId,
    answerFillIn,
    isTrue,
  }: ShowIsCorrecSignColumnType) => {
    let result;

    if (typeof answerId !== "undefined") {
      result = isCorrectAnswer(answerId) ? (
        isTrue ? (
          <Correct />
        ) : (
          <Incorrect />
        )
      ) : (
        <Unknown />
      );
    }

    if (
      questionType === QUESTION_TYPE.PULL_DOWN_SELECTION &&
      typeof answerId !== "undefined"
    ) {
      result = isCorrectAnswer(answerId) ? <Correct /> : <Incorrect />;
    }

    if (
      questionType === QUESTION_TYPE.FILL_IN_BLANK &&
      typeof answerFillIn !== "undefined"
    ) {
      result = isCorrectQuestionTypeFillIn(answerFillIn) ? (
        <Correct />
      ) : (
        <Incorrect />
      );
    }

    return (
      <td className="w-[60px]">
        <div className="flex justify-center">{result}</div>
      </td>
    );
  };

  const ShowCorrectAnswerColumn = ({
    content,
    isTrue,
    linkImage,
  }: ShowCorrectAnswerColumnType) => {
    return currentQuestion.type === QUESTION_TYPE.IMAGE_SELECTION ? (
      <td>
        <div className="p-[5px]">
          <img
            src={linkImage}
            style={{ height: "60px", width: "100px" }}
            alt=""
          />
        </div>
      </td>
    ) : (
      <td className="max-w-[220px] px-[15px]">
        <div className="flex items-center gap-[10px]">
          {SHOW_IS_CORRECT_SIGN.includes(Number(currentQuestion?.type)) && (
            <div>{isTrue ? <Correct /> : <Incorrect />}</div>
          )}
          <div
            className="overflow-hidden"
            dangerouslySetInnerHTML={{ __html: content || "" }}
          ></div>
        </div>
      </td>
    );
  };

  const AnswerContent = () => {
    let questionType = Number(currentQuestion.type);

    if (questionType === QUESTION_TYPE.FILL_IN_BLANK) {
      return (
        <>
          {answersQuestionSubmit.map((answer, index) => {
            if (typeof answer === "number") return <></>;
            let answers = answersQuestion
              .filter(
                (answerQuestion) =>
                  answerQuestion?.yield_code === answer?.yield_code
              )
              .map((answerQuestion) => {
                return answerQuestion.content;
              })
              .join(", ");

            return (
              <>
                {typeof answer !== "number" ? (
                  <tr
                    className="text-center h-[30px] py-[5px] border-b"
                    key={index}
                  >
                    <ShowIsCorrectSignColumn answerFillIn={answer} />
                    <ShowMyAnswer answerFill={answer.value} />
                    <ShowCorrectAnswerColumn content={answers} />
                  </tr>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </>
      );
    }

    if (questionType === QUESTION_TYPE.PULL_DOWN_SELECTION) {
      let answersCorrect = currentQuestion.answers.filter(
        (question) => Number(question.is_true) === 1
      );
      let answersDefault = currentQuestion.answers.filter(
        (question) => Number(question.is_default) === 1
      );

      return (
        <>
          {answersDefault.map((answer, index) => {
            let findAnswer = answersQuestion.find(
              (answerQuestion) =>
                answerQuestion.id === answersQuestionSubmit[index]
            );

            return (
              <tr
                className="text-center h-[30px] py-[5px] border-b"
                key={index}
              >
                <ShowIsCorrectColumn answer={answer} index={index} />
                <ShowIsCorrectSignColumn
                  answerId={Number(answersCorrect[index].id)}
                />
                <ShowMyAnswer answer={findAnswer} />
                <ShowCorrectAnswerColumn
                  content={answersCorrect[index].content || ""}
                />
              </tr>
            );
          })}
        </>
      );
    }

    return (
      <>
        {currentQuestion.answers.map((answer, index) => {
          return (
            <tr className="text-center h-[30px] py-[5px] border-b" key={index}>
              <ShowIsCorrectColumn answer={answer} index={index} />
              <ShowIsCorrectSignColumn
                answerId={Number(answer.id)}
                isTrue={answer.is_true}
              />
              <ShowMyAnswer answer={answer} />
              <ShowCorrectAnswerColumn
                content={answer.content || ""}
                isTrue={answer.is_true}
                linkImage={answer.resource_link}
              />
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <div className="min-h-[300px] mb-[100px]">
      <div className="mx-[43px] mt-[33px]">
        <div className="mb-[33px] font-[700] text-[20px] leading-[100%] tracking-[0.02em]">
          問題 {questionOrder}
          <div className="min-w-[700px] border-8 border-primary-light mt-[30px]">
            <HeaderAnswer />
            <div className="px-[25px] pb-[25px]">
              <table className="w-full min-w-[660px] text-sm">
                <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
                  <tr className="min-h-[50px] text-[13px] font-[500] m-0">
                    {isShowRightWrongColumn(questionType) && (
                      <th className="w-[60px] 2xl:px-[25px] py-[5px] xl:px-[5px] lg:px-0 px-0 border-r-4 border-white whitespace-nowrap">
                        問題
                      </th>
                    )}
                    <th className="w-[80px] 2xl:px-[25px] py-[5px] xl:px-[5px] lg:px-0 px-0 border-x-4 border-white whitespace-nowrap">
                      正誤
                    </th>
                    <th className="min-w-[72px] max-w-[220px] px-[15px] border-x-4 border-white whitespace-nowrap">
                      あなたの解答
                    </th>
                    <th className="min-w-[220] px-[15px] border-l-4 border-white whitespace-nowrap">
                      正解
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <AnswerContent />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerContent;
