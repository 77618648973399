import { MemoFolder } from "../../../types/student/memoFolder";
import { useEffect, useState } from "react";
import { makeRequest, request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { Memo } from "../../../types/student/memo";
import MemoFilter from "./MemoFilter";
import { MdFolder, MdNotes } from "react-icons/md";
import MemoItem from "./MemoItem";
import { TbFileDescription } from "react-icons/tb";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { PATH_STUDY_TOOL } from "../../../services/constants/route/router";
import { swalError, swalSuccess, swallConfirm } from "../../../services/helpers/swal";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";
import { FiTrash2 } from "react-icons/fi";
import LayoutWaitingApi from "../common/LayoutWaitingApi";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { MdOutlineEdit } from "react-icons/md";

export type DataDeleteMemo = {
  id: number;
};

const MemoBoard = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorData>({});
  const [memos, setMemos] = useState<Memo[]>([]);
  const [memoFolders, setMemoFolders] = useState<MemoFolder[]>([]);
  const [memoFolderId, setMemoFolderId] = useState<number>(0);
  const [filterMemo, setFilterMemo] = useState<string>("");
  const [isChangeDisplay, setIsChangeDisplay] = useState<boolean>(false);
  const [editMemoFolderId, setEditMemoFolderId] = useState<number>(0);
  const [nameMemoFolder, setNameMemoFolder] = useState<string>("");
  const [isOpenPop, setIsOpenPop] = useState<number>(0);
  const [totalMemo, setTotalMemo] = useState<number>(0);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const { studentTheme } = useStudentThemeContext();

  const handleDoupleClick = (id: number, nameMemoFolder: string) => {
    setNameMemoFolder(nameMemoFolder);
    setEditMemoFolderId(id);
  };

  const updateMemoFolder = async (memoFolderName: string) => {
    if (editMemoFolderId > 0 && memoFolderName !== nameMemoFolder) {
      setEditMemoFolderId(0);
      const result = await makeRequest({
        method: "post",
        url: API.MEMO.UPSERT_MEMO_FOLDER,
        data: {
          id: editMemoFolderId,
          name: nameMemoFolder,
        },
      });

      if (!result.status) {
        setErrors(result.error || {});
        return false;
      }

      setErrors({});
      await request.get(`${API.MEMO.GET_LIST_MEMO_FOLDER}`, (memoFolders) => {
        setMemoFolders(memoFolders);
      });
    }

    setEditMemoFolderId(0);
  };

  const createMemoFolder = async () => {
    const result = await makeRequest({
      method: "post",
      url: API.MEMO.UPSERT_MEMO_FOLDER,
      data: { name: "" },
    });

    if (!result.status) {
      swalError();
      setErrors(result.error || {});
      return false;
    }

    setErrors({});
    await request.get(`${API.MEMO.GET_LIST_MEMO_FOLDER}`, (memoFolders) => {
      setMemoFolders(memoFolders);
    });
  };

  const deleteMemo = async (dataDelete: DataDeleteMemo) => {
    const result = await makeRequest({
      method: "delete",
      url: API.MEMO.DELETE_MEMO,
      data: dataDelete,
    });

    if (!result.status) {
      swalError();
      setErrors(result.error || {});
      return false;
    }

    await request.get(`${API.MEMO.GET_LIST_MEMO_FOLDER}`, (memoFolders) => {
      setMemoFolders(memoFolders);
    });

    let countMemo = totalMemo - 1;
    setTotalMemo(countMemo);

    setErrors({});
    swalSuccess();
  };

  const deleteFolderHandler = async () => {
    const result = await request.delete(`${API.MEMO.DELETE_MEMO_FOLDER}`, {
      id: memoFolderId,
    });

    if (result) {
      const folders = memoFolders?.filter(
        (memoFolder) => memoFolder.id !== memoFolderId
      );
      setMemoFolders(folders);
      setMemoFolderId(0);
      localStorage.setItem("memoFolderId", "0");
    }
  }

  const deleteMemoFolder = async () => {
    swallConfirm(
      () => deleteFolderHandler(),
      'フォルダの中身がすべて削除されてしまいますがよろしいでしょうか。'
    );
  };

  useEffect(() => {
    const fetch = async () => {
      await request.get(
        `${API.MEMO.GET_LIST_MEMO_FOLDER}`,
        (memoFolders) => {
          setMemoFolders(memoFolders);
          setMemos([]);
          setMemoFolderId(
            JSON.parse(localStorage.getItem("memoFolderId") || "0")
          );
          setWaitingApi(false);
        },
        () => {},
        { withError: false, withClose: false }
      );
    };

    setErrors({});
    const timer = setTimeout(() => {
      fetch();
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let totalMemo = 0;
    let listMemos: Array<Memo> = [];
    memoFolders.forEach((memoFolder: MemoFolder) => {
      totalMemo += Number(memoFolder.child_count);
      listMemos = [...listMemos, ...memoFolder.memos];
    });

    setMemos(listMemos);
    setTotalMemo(totalMemo);

    if (memoFolderId !== 0) {
      const memoFolder = memoFolders.find(
        (memoFolder) => memoFolder.id === memoFolderId
      );

      if (memoFolder !== undefined) setMemos(memoFolder.memos);
      setEditMemoFolderId(0);
    }
  }, [memoFolderId, memoFolders]);

  useEffect(() => {
    let url = `${API.MEMO.GET_MEMO_IN_A_FOLDER}?query=${filterMemo}`;
    if (memoFolderId !== 0) url += `&memo_folder_id=${memoFolderId}`;

    const fetch = async () => {
      await request.get(
        url,
        (memos) => {
          setMemos(memos);
        },
        () => {},
        { withError: false, withClose: false }
      );
    };

    const timer = setTimeout(() => {
      fetch();
    }, 1000);

    return () => clearTimeout(timer);
  }, [filterMemo]);

  return (
    <div className="min-h-[900px] rounded-[5px] my-[20px] text-secondary-light text-[12px] font-[500]">
      {errors && Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}
      <div className="flex gap-[20px] text-secondary font-[500]">
        <div className="w-[200px] min-h-[150px] py-[10px]">
          {/* Filter */}
          <MemoFilter name="name" handleChange={setFilterMemo} />

          {/* Add folder  */}
          <div className="w-full">
            <button
              className={`flex items-center gap-[7px] w-full h-[25px] py-[4px] text-secondary my-[10px] cursor-pointer rounded-lg`}
              onClick={createMemoFolder}
            >
              <BiPlus
                className="border border-secondary rounded-lg ml-[16px]"
                size={18}
              />
              <span className="w-[140px] text-[14px] text-left whitespace-nowrap">
                フォルダを追加
              </span>
            </button>
          </div>

          {/* List folder */}
          <div
            className={`w-full max-h-[395px] pr-[5px] ${
              memoFolders.length > 10 ? "overflow-y-scroll" : ""
            }`}
          >
            {memoFolders.length > 0 && (
              <div
                style={{
                  backgroundColor:
                    memoFolderId === 0
                      ? studentTheme.sub_color_second
                      : "",
                }}
                className={`flex items-center rounded-[8px] text-[12px] h-[35px] mb-[5px] cursor-pointer hover:bg-[#00000026] ${
                  memoFolderId === 0
                    ? "bg-warning-light"
                    : "bg-secondary-morelighter"
                }`}
                onClick={() => {
                  setMemoFolderId(0);
                  localStorage.setItem("memoFolderId", "0");
                }}
              >
                <MdFolder
                  className="text-secondary-light py-[6px] pl-[12px]"
                  size={30}
                />
                <div className="w-[140px] text-[12px] pl-[6px] py-[6px] whitespace-nowrap truncate">
                  すべてのメモ
                </div>
                <div className="flex-shrink ml-[10px] px-[6px] text-secondary-light">
                  {totalMemo}
                </div>
              </div>
            )}
            <LayoutWaitingApi
              waitingApi={waitingApi}
              className="w-full h-[395px]"
            >
              <div className="animate-[show_0.5s_ease-in-out]">
                {memoFolders?.map((memoFolder) => (
                  <div
                    className="pb-[5px]"
                    key={memoFolder?.id}
                    onClick={() => {
                      setMemoFolderId(memoFolder.id);
                      localStorage.setItem(
                        "memoFolderId",
                        JSON.stringify(memoFolder.id)
                      );
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          memoFolderId === memoFolder.id
                            ? studentTheme.sub_color_second
                            : "",
                      }}
                      className={`flex items-center rounded-[8px] text-[12px] h-[35px] cursor-pointer hover:bg-[#00000026] ${
                        memoFolder !== undefined &&
                        memoFolderId === memoFolder.id
                          ? "bg-warning-light"
                          : "bg-secondary-morelighter"
                      }`}
                    >
                      <MdFolder
                        className="text-secondary-light py-[6px] pl-[12px]"
                        size={30}
                      />
                      <div
                        className="w-[140px] text-[12px] pl-[6px] py-[6px] whitespace-nowrap truncate"
                        onDoubleClick={() =>
                          handleDoupleClick(memoFolder.id, memoFolder.name)
                        }
                      >
                        {memoFolder.id === editMemoFolderId ? (
                          <form
                            onSubmit={() => updateMemoFolder(memoFolder.name)}
                            className="z-50"
                          >
                            <input
                              name="name"
                              value={nameMemoFolder}
                              onChange={(e) =>
                                setNameMemoFolder(e.target.value)
                              }
                              onBlur={() => updateMemoFolder(memoFolder.name)}
                              className="px-[10px]"
                              max={250}
                              autoFocus
                            />
                          </form>
                        ) : (
                          memoFolder.name
                        )}
                      </div>
                      <div className="flex-shrink ml-[10px] px-[6px] text-secondary-light">
                        {memoFolder.child_count || 0}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </LayoutWaitingApi>
          </div>
        </div>

        {/* List memo */}
        <div className="w-full max-h-[800]">
          <div className="flex justify-between items-center">
            <div className="flex justify-start">
              <button
                className={`flex items-center gap-[3px] h-[24px] text-[12px] text-white font-[500] bg-secondary-light rounded-[3px] px-[8px] py-[3px] ${
                  memoFolderId > 0 ? "" : "hidden"
                }`}
                onClick={deleteMemoFolder}
              >
                フォルダを削除 <FiTrash2 size={17} />
              </button>
              <button
                className={`flex items-center gap-[3px] h-[24px] text-[12px] text-white font-[500] bg-secondary-light rounded-[3px] px-[8px] py-[3px] mx-[8px] ${
                  memoFolderId > 0 ? "" : "hidden"
                }`}
                onClick={() => {
                  handleDoupleClick(memoFolderId, memoFolders.find(memo => memo.id === memoFolderId)?.name || "")
                }}
              >
                フォルダ名編集 <MdOutlineEdit size={17} />
              </button>
            </div>
            <div className="flex items-center justify-end gap-[14px]">
              <div className="flex items-center w-[85px] h-[30px] bg-warning-lighter rounded-[15px]">
                <div
                  style={{
                    backgroundColor: !isChangeDisplay
                      ? studentTheme.main_color_second
                      : "",
                  }}
                  className={`flex ml-[3.6px] justify-center w-[37px] py-[2px] rounded-[20px] cursor-pointer ${
                    !isChangeDisplay && "bg-warning"
                  }`}
                  onClick={() => setIsChangeDisplay(false)}
                >
                  <TbFileDescription
                    className={!isChangeDisplay ? "text-white" : ""}
                    size={18}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: isChangeDisplay
                      ? studentTheme.main_color_second
                      : "",
                  }}
                  className={`flex justify-center rounded-[20px] w-[37px] py-[3px] ml-[4.4px] cursor-pointer ${
                    isChangeDisplay && "bg-warning "
                  }`}
                  onClick={() => {
                    setIsChangeDisplay(true);
                  }}
                >
                  <MdNotes
                    className={isChangeDisplay ? "text-white" : ""}
                    size={17}
                  />
                </div>
              </div>
              <button
                style={{
                  backgroundColor: studentTheme.main_color_first,
                }}
                className="flex items-center justify-end w-[140px] h-[34px] bg-primary rounded-[18px] text-[12px] text-white font-[700] cursor-pointer"
                onClick={() => navigate(PATH_STUDY_TOOL.CREATE_MEMO)}
              >
                <BiPlus className="" size={17} />
                <span className="tracking-[0.24px] py-[10px] pr-[17px] pl-[7px]">
                  メモを作成する
                </span>
              </button>
            </div>
          </div>
          <LayoutWaitingApi
            waitingApi={waitingApi}
            className="w-full h-[170px] mt-[20px]"
          >
            <div className="animate-[show_0.5s_ease-in-out]">
              {memos.length > 0 && (
                <div
                  className={`grid ${
                    isChangeDisplay ? "grid-cols-1" : "grid-cols-2"
                  } gap-[15px] mt-[30px] pr-[5px] ${
                    memos.length > 10
                      ? `overflow-y-scroll ${
                          isChangeDisplay ? "max-h-[715px]" : "max-h-[910px]"
                        }`
                      : ""
                  }`}
                >
                  {memos?.map((memo) => (
                    <MemoItem
                      memo={memo}
                      key={memo?.id}
                      isChangeDisplay={isChangeDisplay}
                      setMemoFolders={setMemoFolders}
                      handleDeleteMemo={deleteMemo}
                      isOpenPop={isOpenPop}
                      setIsOpenPop={setIsOpenPop}
                    />
                  ))}
                </div>
              )}
            </div>
          </LayoutWaitingApi>

          {memos.length === 0 && memoFolderId !== 0 && (
            <div className="text-center text-secondary text-[14px] font-[500] pt-[20px]">
              メモがありません
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemoBoard;
