import { ChangeEvent, useEffect, useState } from "react";
import { API } from "../../../../services/constants/route/api";
import { request } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import PurchaseHistoryTable from "./PurchaseHistoryTable";
import Swal from "sweetalert2";
import { Order, ReceiptSetting } from "../../../../types/student/order";

type FilterType = {
  query: string;
  limit: number;
  page: number;
  sortType?: number;
  section_id?: number | string;
};

const PurchaseHistoryList = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    query: "",
    page: 1,
    sortType: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState<Order[]>([]);
  const [receiptSetting, setReceiptSetting] = useState<ReceiptSetting>();
  const [totalPages, setTotalPages] = useState<number>(0);

  const resetTable = () => {
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  useEffect(() => {
    const fetch = async () => {
        Swal.showLoading();
        await request.get(
            `${API.ORDERS.PURCHASE_HISTORY}?${paramizeObject(filter)}`,
            (data) => {
                setOrders(data.orders);
                setReceiptSetting(data.receipt_setting);
                setTotalPages(data.total_pages);
            }
        );
        Swal.close();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.type === "checkbox") {
      const target = e.target as HTMLInputElement;
      if (target.checked) {
        setFilter({
          ...filter,
          "page": 1,
        });
        resetTable();
        return;
      } else {
        setFilter({ ...filter, page: 1 });
        resetTable();
        return;
      }
    }
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <div>
      <PurchaseHistoryTable
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        orders={orders}
        totalPages={totalPages}
        receiptSettingData={receiptSetting}
        setOrders={setOrders}
        setReceiptSetting={setReceiptSetting}
        setTotalPages={setTotalPages}
        filter={filter}
      />
    </div>
  );
};

export default PurchaseHistoryList;