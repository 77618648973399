import React from 'react';
import StudentCSV from '../../../components/admin/student/StudentCSV';
import StudentBase from '.';

const StudentCSVPage = () => {
  return (
    <StudentBase title="受講者管理 ｜ 受講者CSV登録">
      <StudentCSV/>
    </StudentBase>
  );
};

export default StudentCSVPage;
