import React, { ChangeEvent, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import Button from "../../components/Login/Button";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ROUTE } from "../../services/constants/route/router";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { swalError, swalMessage } from "../../services/helpers/swal";
import { makeRequest } from "../../services/axios/axios";
import { API } from "../../services/constants/route/api";
import { ErrorData } from "../../types/globals";
import ErrorBox from "../../components/commons/form/ErrorBox";
import { useStorage } from "../../hooks/useStorage";
import { getSubdomainFromUrl } from "../../services/helpers/domain";

dayjs.extend(isSameOrBefore);

const UpdateNewPwd = () => {
  const subdomain = getSubdomainFromUrl();
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  // Link: /update-new-pwd/:token
  const [dataResetPassword, setDataResetPassword] = useState({
    username: "",
    temporary_password: "",
    password: "",
    password_confirmation: "",
    subdomain: subdomain,
  });
  const [errors, setErrors] = useState<ErrorData>({});
  // Check token expired
  let { token } = useParams() || null;
  let navigate = useNavigate();
  if (!token) {
    return <Navigate to={ROUTE.HOME} replace={true} />;
  }
  let data = decodeToken(`${token}`) as {
    expired: string;
  };

  if (data && data.expired) {
    let expiredIn = dayjs.unix(parseInt(data.expired));
    if (!dayjs().isSameOrBefore(expiredIn)) {
      swalMessage("エラー", "リンクの有効期限が切れました。", "error");
      return <Navigate to={ROUTE.HOME} replace={true} />;
    }
  } else {
    return <Navigate to={ROUTE.HOME} replace={true} />;
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;

    setDataResetPassword({ ...dataResetPassword, [name]: value });
  };

  const handleChangePwd = async () => {
    // swalLoading();
    let result = await makeRequest({
      method: "post",
      url: API.AUTH.CHANGE_PWD,
      data: dataResetPassword,
    });

    if (result.status) {
      navigate(ROUTE.LOGIN);
      return;
    }

    let error = result.error;
    setErrors(error || {});
    swalError();
    return;
  };

  return (
    <AuthLayout>
      <form
        style={{ color: studentTheme.text_color }}
        className="mb-[45px] mt-[30px] mx-auto max-sm:mt-[25px] max-sm:mx-[10px]"
      >
        <div className="w-[670px] max-sm:w-[100%] h-auto mb-[30px] bg-white px-[25px] max-sm:px-[15px] border border-danger-light">
          <p className="mt-[30px] mb-[20px] text-[15px] max-sm:mt-[25px]">
            ログイン
          </p>
          {errors && !!Object.keys(errors).length && (
            <div className="mb-[20px] text-left">
              <ErrorBox errors={errors} />
            </div>
          )}
          <div className="mx-[20px] border-b border-danger-light max-sm:mx-0">
            <div className="mb-[20px] mx-[60px] max-sm:mx-0">
              <input
                type="text"
                name="username"
                onChange={handleChangeInput}
                className="border !border-danger-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full min-h-[50px] py-[14px] placeholder:text-sm placeholder:text-[#9E9E9F]"
                placeholder="メールアドレス　または　ログインID"
                required
              />
            </div>
            <div className="mb-[30px] max-sm:mb-[35px] mx-[60px] max-sm:mx-0">
              <input
                type="password"
                placeholder="メールに記載された仮パスワード"
                name="temporary_password"
                onChange={handleChangeInput}
                className="border border-danger-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full min-h-[50px] py-[14px] placeholder:text-[#9E9E9F]"
                required
              />
            </div>
          </div>

          <p className="mt-[15px] mb-[20px] text-[15px]">
            新しいパスワードを設定してください
          </p>
          <div className="text-left mx-[10px] max-sm:mx-0 pb-[50px]">
            <div className="flex max-sm:flex-col items-center mb-[18px] max-sm:mb-[19px]">
              <div className="w-full flex items-center max-sm:justify-start">
                <p className="w-[168px] text-[14px]">新パスワード</p>
                <p className="bg-[#FE6D73] border rounded-lg text-[9px] font-[500] p-[3px] text-white px-[5px] w-[32px] h-[21px] mr-[21px] flex justify-center items-center">
                  必須
                </p>
              </div>
              <div className="w-full max-sm:mt-[10px]">
                <input
                  type="password"
                  name="password"
                  onChange={handleChangeInput}
                  className="border border-danger-light text-sm w-[380px] max-sm:w-[100%] h-[50px] rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] placeholder:text-[#9E9E9F]"
                  placeholder="半角英数・記号のみ８〜20字"
                  required
                />
              </div>
            </div>
            <div className="text-left max-sm:flex-col items-center">
              <div className="flex max-sm:flex-col items-center mb-[18px] max-sm:mb-[19px]">
                <div className="max-sm:w-full flex items-center max-sm:justify-start">
                  <p className="w-[168px] text-[14px]">
                    新パスワード（確認用）
                  </p>
                  <p className="bg-[#FE6D73] border rounded-lg text-[9px] font-[500] p-[3px] text-white px-[5px] w-[32px] h-[21px] mr-[21px] flex justify-center items-center">
                    必須
                  </p>
                </div>
                <div className="w-[380px] max-sm:w-full h-[50px] max-sm:mt-[10px]">
                  <input
                    type="password"
                    name="password_confirmation"
                    onChange={handleChangeInput}
                    className="border border-danger-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                    required
                  />
                  <p className="text-left text-[10px] font-[400] leading-[190%] mt-[2px]">
                    ※確認のため同じパスワードを入力してください
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: studentTheme.main_color_first,
          }}
          content={"パスワードの変更"}
          otherstyle={
            "bg-primary text-white max-sm:text-[18px] max-sm:font-[700]"
          }
          type={"button"}
          onClick={handleChangePwd}
        />
      </form>
    </AuthLayout>
  );
};

export default UpdateNewPwd;
