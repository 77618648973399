import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PATH_PRODUCT,
  PATH_STUDENT_CART,
} from "../../../services/constants/route/router";
import { MdOutlineInventory } from "react-icons/md";
import { makeRequest, request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";
import { FiShoppingCart } from "react-icons/fi";
import {
  extractVimeoEmbedURL,
  extractYouTubeEmbedURL,
  formatNumberWithCommas,
} from "../../../services/helpers/parseData";
import {
  swalClose,
  swalError,
  swalLoading,
  swalMessage,
} from "../../../services/helpers/swal";
import { ProductDetailType } from "../../../types/student/product";
import { CartItem } from "../../../types/student/cart";
import { useCartContext } from "../../../context/CartContext";
import dayjs from "dayjs";
import { VALIDITY_PERIOD_TYPE } from "../../../services/constants/admin/pages/product";
import LayoutWaitingApi from "../common/LayoutWaitingApi";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { HttpStatusCode } from "axios";

const ProductDetail = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState<ProductDetailType>();
  const [errors, setErrors] = useState<ErrorData>({});
  const urlSearchParams = new URLSearchParams(window.location.search);
  const productId = urlSearchParams.get("product_id");
  const { cartItems, setCartItems, setTotalProduct } = useCartContext();
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const { studentTheme } = useStudentThemeContext();
  const [cartItem, setCartItem] = useState<CartItem>({
    product_id: Number(productId),
    name: "",
    quantity: 1,
    price: 0,
    status: 1,
  });

  useEffect(() => {
    if (productId) {
      const fetchProduct = async () => {
        await request.get(
          `${API.PRODUCT.DETAIL}?product_id=${productId}`,
          (product) => {
            setProduct(product);
            setCartItem({
              ...cartItem,
              price: product.price,
              name: product.name,
            });

            if (
              product.stock === 0 ||
              (product?.start_date_sell && dayjs(product.start_date_sell).isAfter(dayjs(), 'second')) ||
              (product?.end_date_sell && dayjs(product.end_date_sell).isBefore(dayjs(), "second"))
            ) {
              setDisableBtn(true);
            }
            setWaitingApi(false);
          },
          (errors) => {
            if (errors === HttpStatusCode.NotFound) {
              navigate("/404");
            }
            setErrors(errors);
            setWaitingApi(false);
          }
        );
      };

      fetchProduct();
    } else {
      navigate("/404");
    }
  }, [productId]);

  const handleChangeQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    const quantity = Number(e.target.value);

    if (
      product?.stock === null ||
      (product?.stock && product.stock > 0 && product.stock >= quantity)
    ) {
      setCartItem({ ...cartItem, quantity: quantity });
      return;
    }

    if (
      (product?.stock && product.stock > 0 && product.stock < quantity) ||
      product?.stock === 0
    ) {
      swalMessage(
        "",
        `${product.name}は在庫切れのため購入できません`,
        "error",
        { timer: 1500, showConfirmButton: false }
      );
      return;
    }
  };

  const checkResourceType = (resourceUrl: string) => {
    const resourceArr = resourceUrl.split(".");
    return resourceArr[resourceArr.length - 1];
  };

  const getEmbedUrlFromUrl = (url: string) => {
    let domain = new URL(url);
    let hostName = domain.hostname;
    if (
      hostName.toString().includes("youtube") ||
      hostName.toString().includes("youtu.be")
    ) {
      return extractYouTubeEmbedURL(url);
    }

    if (hostName.toString().includes("vimeo")) return extractVimeoEmbedURL(url);
  };

  const addToCart = async () => {
    if (product?.stock === 0) {
      swalMessage(
        "",
        `${product.name}は在庫切れのため購入できません`,
        "error",
        { timer: 1500, showConfirmButton: false }
      );
      return;
    }

    // swalLoading();
    const result = await makeRequest({
      method: "post",
      url: API.CART.ADD_TO_CART,
      data: cartItem,
    });

    if (result.status) {
      swalMessage("カートに追加しました！", "", "success", {
        timer: 1000,
        showConfirmButton: false,
      });
      cartItem.id = result.data.id;
    } else {
      swalError();
    }

    if (cartItems.length) {
      let cartItemExist = cartItems.find(
        (parseCartItem) => parseCartItem.product_id === cartItem.product_id
      );

      if (cartItemExist) {
        cartItemExist.quantity += cartItem.quantity;
      } else {
        cartItems.push(cartItem);
      }

      setTotalProduct(cartItems.length);
      setCartItems(cartItems);
      localStorage.setItem("cart_items", JSON.stringify(cartItems));
    } else {
      setTotalProduct(1);
      setCartItems([cartItem]);
      localStorage.setItem("cart_items", JSON.stringify([cartItem]));
    }
  };

  const proceedToPurchase = async () => {
    await addToCart();

    navigate(PATH_STUDENT_CART.DEFAULT);
  };

  return (
    <section className="px-[20px]">
      {!!Object.keys(errors).length && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}
      <div className="py-[15px]">
        <Link
          to={PATH_PRODUCT.DEFAULT}
          className="text-white text-[14px] font-[500] tracking-[0.28px] bg-secondary-light px-[21px] py-[5px] rounded-[8px]"
        >
          商品一覧へ戻る
        </Link>
      </div>
      <div className="flex gap-[30px]">
        <LayoutWaitingApi
          waitingApi={waitingApi}
          className="w-[510px] h-[200px]"
        >
          <div className="w-[510px] animate-[show_0.5s_ease-in-out]">
            <div className="text-[11px] font-[400] tracking-[0.22px]">
              {product && product?.course_name + " > " + product?.name}
            </div>
            <div
              style={{
                color: studentTheme.main_color_first,
              }}
              className="flex items-center gap-[10px] text-primary mt-[12.5px] mb-[20px] pb-[10px] border-b border-b-secondary-light"
            >
              <MdOutlineInventory size={24} className="" />
              <div
                className="max-w-[450px] text-[20px] font-[700] truncate"
                title={product?.name}
              >
                {product?.name}
              </div>
            </div>
            <div className="mb-[20px]">
              {product?.resource_url ? (
                <iframe
                  title="image-preview-product"
                  src={getEmbedUrlFromUrl(product?.resource_url) || ""}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  className="w-full h-[300px]"
                ></iframe>
              ) : checkResourceType(product?.resource?.resource_link || "") ===
                "mp4" ? (
                <video
                  controls
                  className="w-full h-[300px]"
                  src={product?.resource?.resource_link}
                ></video>
              ) : (
                <img
                  src={
                    product?.resource_url
                      ? product.resource_url
                      : product?.resource?.resource_link
                  }
                  alt=""
                  className="w-full object-cover"
                />
              )}
            </div>
            <div className="text-[12px] leading-[22.8px] font-[400] mb-[20px]">
              {product?.overview}
            </div>
          </div>
        </LayoutWaitingApi>
        <div className="w-[190px] max-h-[290px] border border-danger-light px-[13px] py-[18px] rounded-[5px]">
          <LayoutWaitingApi waitingApi={waitingApi} className="h-[25px] w-full">
            <div
              style={{
                color: studentTheme.main_color_first,
              }}
              className="max-h-[42px] line-clamp-2 text-primary text-[14px] font-[700] leading-[21px] animate-[show_0.5s_ease-in-out]"
              title={product?.name}
            >
              {product?.name}
            </div>
          </LayoutWaitingApi>
          <div className="flex gap-[20px] justify-end items-center my-[10px]">
            <div className="text-[14px] font-[500] leading-[20px] tracking-[0.1px]">
              数量
            </div>
            <div>
              <input
                type="number"
                min={1}
                max={999}
                name="quantity"
                className="w-[40px] min-h-[36px] text-[14px] p-0 pl-0 input-number-product"
                placeholder="1"
                value={cartItem.quantity}
                onChange={handleChangeQuantity}
              />
            </div>
          </div>
          <div className="flex gap-[20px] justify-end items-center my-[10px]">
            <div className="text-[14px] font-[500] leading-[20px] tracking-[0.1px]">
              有効期間
            </div>
            <div className="h-[36px] min-w-[39px] p-[6px] border border-[#ebe4d8] rounded-[5px] text-[14px] font-[400] text-center">
              {product?.expiry_quantity}
              {product?.expiry_type !== null
                ? VALIDITY_PERIOD_TYPE[product?.expiry_type || 0]
                : ""}
            </div>
          </div>
          <LayoutWaitingApi waitingApi={waitingApi} className="h-[25px] w-full">
            <div className="text-[22px] text-danger leading-[22px] font-[700] text-right mr-[5px] animate-[show_0.5s_ease-in-out]">
              {formatNumberWithCommas(product?.price || 0)}円
              <span className="text-[9px] leading-[12.6px] font-[400]">
                （税込）
              </span>
            </div>
          </LayoutWaitingApi>
          <div className="h-auto px-[10px] mt-[16px]">
            <button
              className={`flex items-center gap-[10px] text-white px-[11.67px] py-[4.5px] rounded-[8.3px] mb-[16px] ${
                disableBtn
                  ? "bg-secondary-light cursor-not-allowed"
                  : "bg-danger cursor-pointer"
              }`}
              onClick={addToCart}
              disabled={disableBtn}
            >
              <FiShoppingCart size={18} className="text-white" />
              <span className="text-[14px] font-[500] tracking-[0.28px]">
                カートに追加
              </span>
            </button>
            <button
              className={`flex items-center gap-[10px] text-[14px] text-white font-[500] tracking-[0.28px] px-[11.67px] py-[4.5px] rounded-[8.3px] ${
                disableBtn
                  ? "bg-secondary-light cursor-not-allowed"
                  : "bg-danger cursor-pointer"
              }`}
              disabled={disableBtn}
              onClick={proceedToPurchase}
            >
              購入手続きへ進む
            </button>
          </div>
        </div>
      </div>
      <div
        className="px-[14px] py-[30px]"
        dangerouslySetInnerHTML={{
          __html: product?.description || "",
        }}
      ></div>
    </section>
  );
};

export default ProductDetail;
