import * as React from 'react';

const Hamburger = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 6.75H4a.75.75 0 0 1 0-1.5h11a.75.75 0 1 1 0 1.5ZM15 2.25H4a.75.75 0 0 1 0-1.5h11a.75.75 0 1 1 0 1.5ZM15 11.25H4a.75.75 0 1 1 0-1.5h11a.75.75 0 1 1 0 1.5ZM1 2.5a1 1 0 0 1-.38-.07 1.46 1.46 0 0 1-.33-.22A1 1 0 0 1 0 1.5 1.05 1.05 0 0 1 .29.79.93.93 0 0 1 .62.58a1 1 0 0 1 .76 0 1 1 0 0 1 .33.21c.183.192.286.445.29.71a1 1 0 0 1-.29.71c-.1.088-.21.162-.33.22A1 1 0 0 1 1 2.5ZM1 7a1 1 0 0 1-.38-.08 1.15 1.15 0 0 1-.33-.21A1 1 0 0 1 0 6a1.05 1.05 0 0 1 .29-.71 1.15 1.15 0 0 1 .33-.21A1 1 0 0 1 1.2 5l.18.06.18.09c.052.037.103.077.15.12.187.197.291.458.29.73a1 1 0 0 1-1 1ZM1 11.5a1 1 0 0 1-.38-.07 1.46 1.46 0 0 1-.33-.22 1.15 1.15 0 0 1-.21-.33.94.94 0 0 1 0-.76 1.15 1.15 0 0 1 .21-.33 1 1 0 0 1 1.09-.21 1 1 0 0 1 .33.21c.088.097.16.209.21.33a.94.94 0 0 1 0 .76 1.15 1.15 0 0 1-.21.33 1 1 0 0 1-.71.29Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
  </svg>
);

export default Hamburger;
