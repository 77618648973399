import { MdFolder } from "react-icons/md";
import { MemoFolder } from "../../../types/student/memoFolder";
import { useEffect, useState } from "react";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";

type MemoSelectFolderProps = {
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  memoFolderId?: number;
};

const MemoSelectFolder = ({
  handleChange,
  memoFolderId,
}: MemoSelectFolderProps) => {
  const [memoFolders, setMemoFolders] = useState<MemoFolder[]>([])

  useEffect(() => {
    const fetch = async () => {
      await request.get(
        `${API.MEMO.GET_LIST_MEMO_FOLDER}`,
        (memoFolders) => {
          setMemoFolders(memoFolders);

        },
        () => {},
        { withError: false, withClose: false }
      );
    };

    fetch();
  }, [])

  return (
    <div className="flex items-center gap-[12.5px] h-[30px]">
      <MdFolder size={30} className="text-secondary-light" />
      <select
        name="memo_folder_id"
        className="w-[156px] pl-[6.5px] pr-[24px] truncate text-secondary"
        onChange={handleChange}
        value={memoFolderId}
      >
        <option value="0">フォルダーを選択</option>
        {memoFolders?.map((folder) => (
          <option value={folder.id} key={folder.id}>
            {folder.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default MemoSelectFolder;
