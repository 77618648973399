import React from "react";
import { ChildrenWithProps } from "../../types/globals";
import Header from "./Header";
import Sidebar from "./Sidebar";
import RequiredAuth from "../../middleware/auth";
import ScrollToTop from "../../components/commons/etcs/ScrollToTop";
import CheckRoleAdmin from "../../middleware/checkRoleAdmin";

const AdminLayout = ({
  children,
  gap = false,
  ...props
}: { gap?: boolean } & ChildrenWithProps) => {
  return (
    <>
      <ScrollToTop />
      <RequiredAuth>
        <CheckRoleAdmin>
          <section className="min-h-screen" {...props}>
            <div
              className=""
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                position: "relative",
                zIndex: "10",
              }}
            >
              <Header />
            </div>
            <div className="w-full flex min-h-screen bg-success-lighter">
              <div className="w-full flex justify-between">
                <div className="flex w-full items-start">
                  <Sidebar />
                  <div className="w-full px-[32px] pt-[30px]">{children}</div>
                </div>
              </div>
            </div>
          </section>
        </CheckRoleAdmin>
      </RequiredAuth>
    </>
  );
};

export default AdminLayout;
