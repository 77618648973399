import React, { useState } from "react";
import { Question } from "../../../../types/student/unit";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { placeAnswerContent } from "../../../../services/utils/student/course";
import { isEmptyObj } from "../../../../services/helpers/etc";

type QuestionContentProps = {
  question: Question;
};

const QuestionAnswerContent = ({ question }: QuestionContentProps) => {
  const [isExpand, setIsExpand] = useState<boolean>(true);

  if (isEmptyObj(question)) return <></>;

  return (
    <div className="mt-[31px] px-[25px]">
      {/* show label */}
      <div
        className="text-warning-medium font-[700] text-[15px] leading-[100%] w-full flex justify-center items-center gap-[6px] mb-[10px] cursor-pointer"
        onClick={() => setIsExpand(!isExpand)}
      >
        <div className="">解答・解説を隠す</div>
        <div className="">
          {isExpand ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
      </div>

      {/* explain */}
      {isExpand && (
        <div className="mt-[10px] border border-danger-light rounded-[5px] py-[8px] px-[9px]">
          <div className="bg-secondary-morelighter rounded-[5px] py-[12px] px-[13px]">
            <div className="font-[700] text-[16px] leading-[100%]">解答</div>
            <div className="py-[14px] px-[10px]">
              <div
                dangerouslySetInnerHTML={{
                  __html: placeAnswerContent(question) || "",
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionAnswerContent;
