import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TYPE_DATE_FORMAT } from "../../../services/constants/globals";
import { formatDateTime } from "../../../services/helpers/formatTime";
import { EventSchedule } from "../../../types/student/event";
import ScheduleEventType from "./EventType";
import { PATH_STUDY_TOOL } from "../../../services/constants/route/router";
import { getCommonDate } from "../../../services/helpers/date";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const EventBoardListItem = ({ data }: { data: EventSchedule }) => {
  const eventType = data.event_type;
  const date = getCommonDate(data.start_time);
  const [style, setStyle] = useState<Object>({
    background: eventType?.background_color,
    color: eventType?.name_color,
  });
  const { studentTheme } = useStudentThemeContext();

  useEffect(() => {
    if (eventType && eventType.id === 4) {
      setStyle({ ...style, backgroundColor: studentTheme.main_color_first });
    }
  }, []);
  return (
    <Link to={`${PATH_STUDY_TOOL.SCHEDULE.EVENT.DEFAULT}?date=${date}`}>
      <div className="border border-danger-light rounded-[5px] px-[8px] py-[10px] mb-[10px] cursor-pointer">
        <div
          className={`rounded-[5px] w-[84px] h-[18px] flex justify-start pl-[7px] items-center gap-[3.28px] mb-[5px] ${
            !eventType?.icon_name ? "!border !border-danger-light" : ""
          }`}
          style={style}
        >
          <ScheduleEventType name={eventType?.icon_name || ""} />
          <div
            className="text-[10px] font-[500] leading-[14px] tracking-[0.02em] text-danger"
            style={{ color: eventType?.name_color }}
          >
            {eventType?.name}
          </div>
        </div>
        <div
          className="text-[11px] font-[500] leading-[16px] tracking-[0.02em]"
          style={{ color: eventType?.description_color }}
        >
          {formatDateTime(data?.start_time, TYPE_DATE_FORMAT.MONTH_DAY)}
          {eventType?.description}
        </div>
      </div>
    </Link>
  );
};

export default EventBoardListItem;
