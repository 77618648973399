import { BiSearchAlt2 } from "react-icons/bi";
import RoundedButton from "../../commons/RoundButton";
import { HiPlus } from "react-icons/hi";
import { ChangeEvent, useEffect, useState } from "react";
import { FILTER } from "../../../../services/constants/admin/main";
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
  swallConfirm,
} from "../../../../services/helpers/swal";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useSectionManagementContext } from "../../../../context/Section/Management";
import { paramizeObject } from "../../../../services/helpers/parseData";
import BaseModal from "../../commons/BaseModal";
import StudentSearchItem from "./StudentSearchItem";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { User } from "../../../../types/user";
import { Section } from "../../../../types/admin/section";
import { swalMessage } from "../../../../services/helpers/swal";
import { TRANS_STUDENT_ACTION } from "../../../../services/constants/admin/pages/section";
import { IoIosCloseCircleOutline } from "react-icons/io";

type SubmitAddDataType = {
  user_ids?: number[];
  to_section_id?: number;
};

type SubmitMoveDataType = {
  user_ids?: number[];
  to_section_id?: number;
  current_section_id?: number;
};

const TableActionManagement = () => {
  const {
    students,
    filter,
    isOpenModal,
    modalStep,
    checkedStudents,
    mainSections,
    checkedStudentIds,
    title,
    setStudents,
    setFilter,
    setIsOpenModal,
    setModalStep,
    setCheckedStudents,
    setCheckedStudentIds,
    setCheckedObj,
    setTriggerRefreshCourseManagement,
  } = useSectionManagementContext();
  const { getParamValue } = useObjectRoutes();
  const sectionId = getParamValue("section_id");
  const [name, setName] = useState<string>("");
  const [searchData, setSearchData] = useState<User[]>([]);
  const [targetSubsection, setTargetSubsection] = useState<
    Section["sub_section"]
  >([]);
  const [selectedSectionId, setSelectedSectionId] = useState<number>(0);
  const [selectedSubsectionId, setSelectedSubsectionId] = useState<number>(0);
  const [isSubmittable, setIsSubmittable] = useState<boolean>(true);
  const [submitAddData, setSubmitAddData] = useState<SubmitAddDataType>({});
  const [submitMoveData, setSubmitMoveData] = useState<SubmitMoveDataType>({});

  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleChangeModal = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setName(e.target.value);
  };

  const resetStates = () => {
    setTargetSubsection([]);
    setCheckedStudentIds([]);
    setCheckedStudents([]);
    setSelectedSectionId(0);
    setSelectedSubsectionId(0);
    setCheckedObj({});
    setTriggerRefreshCourseManagement(new Date().getTime());
  };

  useEffect(() => {
    if (!name) return;
    const timer = setTimeout(() => {
      const fetchUser = async () => {
        // swalLoading();
        const result = await makeRequest({
          method: "get",
          url: `${API.ADMIN_SECTION.SEARCH_STUDENT}?not_in_section=${sectionId}&search_query=${name}`,
        });

        if (!result.data) return swalError();
        const filteredSearchData = (result.data as User[]).filter(
          (user) => !checkedStudentIds.includes(user.id || 0)
        );

        setSearchData(filteredSearchData);
        swalClose();
      };

      fetchUser();
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (!sectionId || !Object.keys(filter).length) return;

    const timer = setTimeout(async () => {
      // swalLoading();

      const paramsObj = {
        section_id: sectionId,
        search: filter?.search ? filter.search.trim() : "",
        limit: filter.limit || "",
      };

      const result = await makeRequest({
        method: "get",
        url: `${
          API.ADMIN_SECTION.GET_SECTION_STUDENT
        }?${paramizeObject(paramsObj)}`,
      });

      if (!result.data) return swalError();

      resetStates();
      setIsOpenModal(false);
      if (students) setStudents(result.data);
      swalClose();
    }, 500);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const targetSectionId = selectedSubsectionId || selectedSectionId;

    if (targetSectionId === Number(sectionId)) return setIsSubmittable(false);

    setIsSubmittable(true);
    setSubmitMoveData({ ...submitMoveData, to_section_id: targetSectionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSectionId, selectedSubsectionId]);

  useEffect(() => {
    const dataSet = { ...submitAddData, user_ids: checkedStudentIds };
    setSubmitAddData(dataSet);
    setSubmitMoveData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedStudentIds]);

  const handleSelectSection = async (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.name === "sub_section") {
      setSelectedSubsectionId(Number(e.target.value));
      return;
    }

    setSelectedSectionId(Number(e.target.value));

    // swalLoading();
    const result = await makeRequest({
      method: "get",
      url: `${API.ADMIN_SECTION.GET_SUBSECTION_LIST}?main_section_id=${e.target.value}`,
    });

    if (!result.status) return swalError();
    setTargetSubsection(result.data);
    swalClose();
  };

  const handleMoveStudent = async () => {
    if (
      !students ||
      !checkedStudents ||
      !checkedStudents.length ||
      !checkedStudentIds ||
      !checkedStudentIds.length ||
      !selectedSectionId
    )
      return;

    if (!isSubmittable)
      return swalMessage(
        "Not submittable",
        "Can not add student in the same section",
        "warning"
      );

    // swalLoading();
    const result = await makeRequest({
      method: "patch",
      url: API.ADMIN_SECTION.MOVE_USERS_TO_SECTION,
      data: submitMoveData,
    });

    if (!result.status) return swalError();
    setStudents(
      students.filter((student) => !checkedStudentIds.includes(student.id || 0))
    );

    resetStates();
    swalSuccess();
    setIsOpenModal(false);
  };

  const handleAddStudent = async () => {
    if (
      !students ||
      !checkedStudents ||
      !checkedStudents.length ||
      !checkedStudentIds ||
      !checkedStudentIds.length
    )
      return;

    // swalLoading();
    const result = await makeRequest({
      method: "patch",
      url: API.ADMIN_SECTION.ADD_USERS_TO_SECTION,
      data: submitMoveData,
    });

    if (!result.status) return swalError();

    setStudents([...checkedStudents, ...students]);

    resetStates();
    swalSuccess();
    setIsOpenModal(false);
  };

  const handleRemoveStudents = async () => {
    if (!checkedStudentIds || !checkedStudentIds.length) return;

    swallConfirm(async () => {
      // swalLoading();
      const result = await makeRequest({
        method: "patch",
        url: API.ADMIN_SECTION.REMOVE_USERS_TO_SECTION,
        data: { user_ids: checkedStudentIds, section_id: sectionId },
      });

      if (!result.status) return swalError();

      const newStudents = students?.filter(
        (student) => !checkedStudentIds.includes(student.id || 0)
      );
      setStudents(newStudents);

      setCheckedStudents([]);
      setCheckedStudentIds([]);
      swalSuccess();
    }, "チェックした受講者を削除して よろしいですか？");
  };

  return (
    <section className="w-full">
      <RoundedButton
        onClick={() => {
          if (!sectionId) return;
          setIsOpenModal(true);
          setSubmitAddData({
            to_section_id: Number(sectionId),
            user_ids: checkedStudentIds,
          });
          setModalStep(TRANS_STUDENT_ACTION.ADD);
          resetStates();
        }}
        className={`${sectionId || "!cursor-not-allowed"}`}
      >
        <div className="flex items-center justify-center">
          <HiPlus size={18} className="mr-[12px]" />
          このセクションに受講者を登録する
        </div>
      </RoundedButton>
      <div className="mt-[31px]">
        <div className="flex items-center justify-between font-[500] text-[12px] leading-[100%] text-white h-[28px]">
          <div className="flex items-center justify-start gap-[5px]">
            <span className="text-secondary-dark">チェックした受講者を</span>
            <button
              className={`cursor-pointer bg-danger rounded-[5px] py-[8px] px-[15px] ${
                (sectionId && checkedStudentIds.length) || "!cursor-not-allowed"
              }`}
              onClick={handleRemoveStudents}
            >
              削除
            </button>
            <button
              className={`cursor-pointer bg-secondary-light rounded-[5px] py-[8px] px-[15px] ${
                (sectionId && checkedStudentIds.length) || "!cursor-not-allowed"
              }`}
              onClick={() => {
                if (!sectionId || !checkedStudentIds.length) return;
                setIsOpenModal(true);
                setSubmitMoveData({
                  to_section_id: selectedSectionId,
                  user_ids: checkedStudentIds,
                  current_section_id: Number(sectionId),
                });
                setModalStep(TRANS_STUDENT_ACTION.MOVE);
              }}
            >
              セクションを移動
            </button>
          </div>

          {/* limit */}
          <div className="flex items-end gap-[10px]">
            <div className="relative">
              <select
                name="limit"
                id=""
                className="min-h-[30px] pr-[33px] pl-[7px] text-secondary-dark text-[11px] w-[95px]"
                onChange={changeHandle}
                value={filter?.limit || FILTER.OPTION.RECORD_LIMIT[0].value}
              >
                {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
                  <option value={item.value} className="" key={index}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>

            {/* search */}
            <div className="relative">
              <input
                type="text"
                name="search"
                id=""
                className="h-full max-w-[200px] pl-[12px] text-[11px] leading-[100%] min-h-[30px] text-secondary-dark"
                placeholder="受講者を検索"
                onChange={changeHandle}
                value={filter?.search || ""}
              />
              <BiSearchAlt2
                className="absolute top-[7px] right-[9.26px] text-secondary-light"
                size={18}
              />
            </div>
          </div>
        </div>
      </div>

      {isOpenModal && (
        <>
          {modalStep === TRANS_STUDENT_ACTION.ADD && (
            <BaseModal
              setIsOpen={setIsOpenModal}
              justifyDirection="end"
              onClick={handleAddStudent}
              height={550}
              submitLabel="新規登録"
            >
              <div className="text-center pt-[103px]">
                <div className="font-[700] text-[16px] leading-[100%] text-primary mb-[44px]">
                  セクション「{title}」に新規メンバーを追加します
                </div>
                <div className="flex justify-center mb-[50px]">
                  <div className="max-w-[500px]">
                    <div className="text-[14px] leading-[20px] mb-[8px] text-left">
                      メールアドレスまたは名前からセクションにメンバーを追加
                    </div>
                    <div className="text-[12px] leading-[17px] text-secondary mb-[8px] text-left">
                      複数人同時に追加可能です
                    </div>
                    <div className="relative">
                      <input
                        type="text"
                        name="name"
                        id=""
                        className="text-[14px] leading-[100%] h-[40px]"
                        placeholder="メンバーを検索"
                        onChange={handleChangeModal}
                        value={name}
                      />
                      <BiSearchAlt2
                        className="absolute top-[11.26px] right-[11.26px] text-secondary-light"
                        size={18}
                      />
                      <div className="absolute top-12 left-0 z-[1000] max-h-[250px] overflow-y-auto w-full bg-white shadow-2xl rounded-[5px] text-left pl-[10px]">
                        {name &&
                          searchData.map((student) => (
                            <StudentSearchItem
                              key={student.id}
                              student={student}
                              className="!max-w-[350px] !min-h-[50px] rounded-[5px] mb-[5px] !justify-start !cursor-pointer hover:bg-secondary-lighter"
                              onClick={() => {
                                if (
                                  !checkedStudents ||
                                  !checkedStudentIds ||
                                  !student.id
                                )
                                  return;
                                setCheckedStudents([
                                  ...checkedStudents,
                                  student,
                                ]);
                                setCheckedStudentIds([
                                  ...checkedStudentIds,
                                  student.id,
                                ]);
                                setName("");
                                setSearchData([]);
                              }}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mb-[20px]">
                  <div className="w-full max-w-[460px] flex gap-[40px] overflow-auto pb-[10px] pt-[10px]">
                    {checkedStudents &&
                      checkedStudents.map((student) => (
                        <div className="relative" key={student.id}>
                          <IoIosCloseCircleOutline
                            size={20}
                            className="absolute top-[-10px] right-[-10px] text-secondary-light bg-white cursor-pointer hover:w-[22px] hover:bg-secondary-extralight rounded-full"
                            onClick={() => {
                              if (!students) return;
                              setCheckedStudents(
                                checkedStudents.filter(
                                  (checkedStudent) =>
                                    checkedStudent.id !== student.id
                                )
                              );

                              setCheckedStudentIds(
                                checkedStudentIds.filter(
                                  (checkedStudentId) =>
                                    checkedStudentId !== student.id
                                )
                              );
                            }}
                          />
                          <StudentSearchItem
                            key={student.id}
                            student={student}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </BaseModal>
          )}
          {modalStep === TRANS_STUDENT_ACTION.MOVE && (
            <BaseModal
              setIsOpen={setIsOpenModal}
              justifyDirection="end"
              onClick={handleMoveStudent}
              height={630}
              submitLabel="移動する"
            >
              <div className="text-center pt-[103px]">
                <div className="font-[700] text-[16px] leading-[100%] text-primary mb-[44px] mb-[24px]">
                  選択中された受講生を別のセクションへ移動します
                </div>
                <div className="flex justify-center mb-[20px]">
                  <div className="max-w-[510px] flex gap-[40px] overflow-auto pb-[10px]">
                    {checkedStudents &&
                      checkedStudents.map((student) => (
                        <StudentSearchItem key={student.id} student={student} />
                      ))}
                  </div>
                </div>
                <div className="flex justify-center w-full mb-[40px]">
                  <div className="w-full max-w-[372px] bg-secondary-extralight font-[500px] text-[14px] leading-[14px] tracking-[2%] flex items-center gap-[10px] min-h-[34px] pl-[20px]">
                    <div className="text-secondary">現在のセクション：</div>
                    <div className="font-[400]">{title}</div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div className="w-full max-w-[372px] font-[500] text-[14px] leading-[24px] mb-[5px] text-left">
                    移動先のセクションを選択
                  </div>
                </div>

                <div className="mb-[5px]">
                  <select
                    name="section"
                    id=""
                    className="min-h-[50px] w-full max-w-[380px] text-[14px] leading-[16px] text-secondary pl-[17px]"
                    onChange={handleSelectSection}
                    value={selectedSectionId}
                  >
                    <option value="">セクションを選択してください</option>
                    {mainSections.map((section) => (
                      <option value={section.id} key={section.id}>
                        {section.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">
                  <select
                    name="sub_section"
                    id=""
                    className="min-h-[50px] w-full max-w-[380px] text-[14px] leading-[16px] text-secondary pl-[17px]"
                    onChange={handleSelectSection}
                    value={selectedSubsectionId}
                  >
                    <option value="">サブセクションを選択してください</option>
                    {targetSubsection &&
                      targetSubsection.map((subsection) => (
                        <option value={subsection.id} key={subsection.id}>
                          {subsection.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </BaseModal>
          )}
        </>
      )}
    </section>
  );
};

export default TableActionManagement;
