import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { BACKGROUND_DATA } from "../../../../services/constants/admin/pages/term_of_use";
import { ColorResult, SketchPicker } from "react-color";
import Add from "../../../commons/icons/Add";
import ColorOption from "./LoginPageSettingComponent/ColorOption";
import { LoginSettingType } from "../../../../types/admin/login_setting";
import { API } from "../../../../services/constants/route/api";
import { request } from "../../../../services/axios/axios";
import {
  swalClose,
  swalError,
  swalLoading,
  swalMessage,
  swalSuccess,
} from "../../../../services/helpers/swal";
import { MESSAGE_COMMON } from "../../../../services/constants/message";
import AWS, { S3 } from "aws-sdk";
import { getSubdomainFromUrl } from "../../../../services/helpers/domain";
import { useLoginSettingContext } from "../../../../context/LoginSettingContext";
import ErrorBox from "../../../commons/form/ErrorBox";
import { ErrorData } from "../../../../types/globals";
import {
  PREFIX_PATH_S3,
  SERVICE_ID,
} from "../../../../services/constants/globals";
import { useAuthContext } from "../../../../context/AuthContext";
import ImageInternalButton from "../../../commons/buttons/ImageInternalButton";
import { aS } from "@fullcalendar/core/internal-common";
import { BACKGROUND_LOGIN_TYPE } from "../../../../services/constants/admin/pages/loginSetting";
import LogoDefault from "../../../../assests/images/default_avatar.webp";
import { AiOutlineUpload } from "react-icons/ai";

type StempPicturesType = {
  background_image_login: File | undefined;
  logo_admin: File | undefined;
  logo_login: File | undefined;
  favicon: File | undefined;
};
type StempPicturePathWatingDeleteType = {
  background_image_login: string | null;
  logo_admin: string | null;
  logo_login: string | null;
  favicon: string | null;
};
type IsDeletePictureType = {
  background_image_login: boolean;
  logo_admin: boolean;
  logo_login: boolean;
  favicon: boolean;
};

const LoginPageSettingEdit = () => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputUpdateLoginAdminPhoto = useRef<HTMLInputElement>(null);
  const inputUpdateFaviconPhoto = useRef<HTMLInputElement>(null);
  const inputUpdateAvatarPhoto = useRef<HTMLInputElement>(null);
  const [loginSettingFormData, setLoginSettingFormData] =
    useState<LoginSettingType>({
      background_login_type: BACKGROUND_LOGIN_TYPE.COLOR,
      sub_domain: window.location.hostname.split(".")[0],
    });
  const colorWhite = "#FFFFFF";
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>(colorWhite);
  const [colorData, setColorData] = useState<string[]>([]);
  const [colorAdd, setColorAdd] = useState<string>("");
  const [isMouseEnter, setIsMouseEnter] = useState<boolean>(false);
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
  const { setLoginSetting } = useLoginSettingContext();
  const [errors, setErrors] = useState<ErrorData>({});
  const { user } = useAuthContext();
  const oz_id = user.organization_id;
  const [stempPictures, setStempPictures] = useState<StempPicturesType>({
    background_image_login: undefined,
    logo_admin: undefined,
    logo_login: undefined,
    favicon: undefined,
  });
  const [isDeletePicture, setIsDeletePicture] = useState<IsDeletePictureType>({
    background_image_login: false,
    logo_admin: false,
    logo_login: false,
    favicon: false,
  });
  const [stempPicturePathWatingDelete, setStempPicturePathWatingDelete] =
    useState<StempPicturePathWatingDeleteType>({
      background_image_login: null,
      logo_admin: null,
      logo_login: null,
      favicon: null,
    });
  const [isUpdateDatabase, setIsUpdateDatabase] = useState<boolean>(false);

  // const handleChangeComplete = (color: ColorResult) => {
  //   if (color.hex === backgroundColor) {
  //     setColorData([...colorData, color.hex]);
  //   }
  // };

  const handleChange = (color: ColorResult) => {
    setBackgroundColor(color.hex);
  };

  const handleChangeForm = (e: ChangeEvent<HTMLInputElement>) => {
    const currentInput = e.target;
    let newLoginSettingFormData = loginSettingFormData;

    switch (currentInput.type) {
      case "radio":
        newLoginSettingFormData = {
          ...newLoginSettingFormData,
          [currentInput.name]: Number(currentInput.value),
        };
        break;
      default:
        newLoginSettingFormData = {
          ...newLoginSettingFormData,
          [currentInput.name]: currentInput.value,
        };
        break;
    }

    setLoginSettingFormData(newLoginSettingFormData);
  };

  const removeColorData = (color: string) => {
    if (colorData.length === 1 || color === backgroundColor) {
      setBackgroundColor("");
    }

    setColorData((colorData) =>
      colorData.filter((colorItem) => colorItem !== color)
    );
  };

  const toggleColorPicker = () => {
    setColorAdd(colorWhite);
    setBackgroundColor(colorWhite);
    setShowColorPicker(!showColorPicker);
  };

  const handleDocumentClick = () => {
    if (showColorPicker && !isMouseEnter) {
      setColorData([...colorData, backgroundColor]);
      setColorAdd("");
      setShowColorPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick, true);
    return () => {
      document.removeEventListener("click", handleDocumentClick, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMouseEnter, showColorPicker]);

  useEffect(() => {
    setLoginSettingFormData({
      ...loginSettingFormData,
      background_login_color: backgroundColor,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundColor]);

  useEffect(() => {
    const fetchLoginSetting = async () => {
      await request.get(
        API.ADMIN_LOGIN_SETTING.DETAIL,
        (data) => {
          setLoginSettingFormData({ ...loginSettingFormData, ...data });
          if (data.background_login_color) {
            setColorData([...colorData, data.background_login_color]);
            setBackgroundColor(data.background_login_color);
          }
          setStempPicturePathWatingDelete({
            ...stempPicturePathWatingDelete,
            background_image_login: data?.background_image_login_path
              ? data?.background_image_login_path
              : "",
            logo_login: data?.logo_login_path ? data?.logo_login_path : "",
            logo_admin: data?.logo_admin_path ? data?.logo_admin_path : "",
            favicon: data?.favicon_path ? data?.favicon_path : "",
          });
        },
        () => {}
      );
    };
    fetchLoginSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePictureS3 = (path: string | null) => {
    // Delete the file on S3 if any.
    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const pathFile = path;
    if (pathFile) {
      var params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET || "",
        Key: pathFile,
      };

      s3.deleteObject(params, function (err, data) {
        if (err) console.error(err, err.stack); // error
      });
    }
  };

  const saveStempPictures = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputRef: React.RefObject<HTMLInputElement>
  ) => {
    const files = event.target.files;

    const attribute = event.target.name;
    let fileTypeConfig;

    switch (attribute) {
      case "logo_login_path":
      case "logo_admin_path":
        fileTypeConfig = {
          mimeTypes: ["image/svg+xml", "image/png"],
          maxSize: 1 * 1024 * 1024,
          maxSizeName: "1MB",
        };
        break;
      case "favicon_path":
        fileTypeConfig = {
          mimeTypes: ["image/x-icon", "image/vnd.microsoft.icon"],
          maxSize: 1 * 1024 * 1024,
          maxSizeName: "1MB",
        };
        break;
      case "background_image_login_path":
        fileTypeConfig = {
          mimeTypes: ["image/svg+xml", "image/png", "image/jpg", "image/jpeg"],
          maxSize: 15 * 1024 * 1024,
          maxSizeName: "15MB",
        };
        break;
      default:
        break;
    }

    if (!files) return;
    const file = files[0];

    if (!fileTypeConfig?.mimeTypes.includes(file.type)) {
      swalMessage("", MESSAGE_COMMON.INVALID_FILE_TYPE, "error", {
        timer: 1500,
        showConfirmButton: false,
      });
      if (inputRef.current !== null) {
        inputRef.current.value = "";
      }
      return;
    }

    if (file.size > fileTypeConfig.maxSize) {
      swalMessage(
        "",
        `添付ファイルのサイズが${fileTypeConfig.maxSizeName}を越えています`,
        "error",
        { timer: 1500, showConfirmButton: false }
      );
      if (inputRef.current !== null) {
        inputRef.current.value = "";
      }
      return;
    }

    let nameStempPicture = attribute.replace("_path", "");

    if (files) {
      setLoginSettingFormData({
        ...loginSettingFormData,
        [`${attribute}_url`]: URL.createObjectURL(files[0]),
        [attribute]: "exist",
      });
      setStempPictures({ ...stempPictures, [nameStempPicture]: files[0] });
    }
  };

  const handleUploadFile = async (file: File | null, attribute: string) => {
    if (!file) return;

    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    const fileName = `${Date.now()}.${fileExtension}`;
    const folderPath =
      PREFIX_PATH_S3.FEATURE +
      SERVICE_ID +
      "/logo/" +
      PREFIX_PATH_S3.OZ +
      oz_id +
      "/";

    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
    AWS.config.update({
      accessKeyId,
      secretAccessKey,
    });

    // Upload file on S3.
    let contentType = file.type;
    const upload = new S3.ManagedUpload({
      params: {
        Bucket: process.env.REACT_APP_AWS_BUCKET || "",
        Key: folderPath + fileName,
        Body: file,
        ContentType: contentType,
        ACL: "public-read",
      },
    });
    try {
      await upload.promise();
      const data = {
        filename: fileName,
        origin_filename: file.name,
        content_type: fileExtension,
        path: `${folderPath}${fileName}`,
      };

      const s3Path = process.env.REACT_APP_AWS_S3_URL;
      let dataState: LoginSettingType;

      switch (attribute) {
        case "background_image_login":
          dataState = {
            background_image_login_path: data.path,
            background_image_login_path_url: s3Path + "/" + data.path,
          };
          break;
        case "logo_login":
          dataState = {
            logo_login_path: data.path,
            logo_login_path_url: s3Path + "/" + data.path,
          };

          break;
        case "logo_admin":
          dataState = {
            logo_admin_path: data.path,
            logo_admin_path_url: s3Path + "/" + data.path,
          };
          break;
        case "favicon":
          dataState = {
            favicon_path: data.path,
            favicon_path_url: s3Path + "/" + data.path,
          };
          break;
      }
      setLoginSettingFormData((prev) => ({ ...prev, ...dataState }));
    } catch (error) {
      swalError();
      console.error("Error uploading file:", error);
      return;
    }
  };

  const handleUpdateDatabase = async () => {
    await request.patch(
      API.ADMIN_LOGIN_SETTING.UPDATE,
      loginSettingFormData,
      (data) => {
        setLoginSettingFormData(data);
        setLoginSetting(data);
        setIsDisableButton(false);
        swalClose();
        if (localStorage.getItem("login_setting")) {
          localStorage.removeItem("login_setting");
        }

        if (data.background_login_color !== null) {
          setColorData([data.background_login_color]);
        }
      },
      (errors) => {
        setErrors(errors);
        //if update db fail then delete file in s3
        Object.keys(loginSettingFormData).map((key, i) => {
          if (
            key === "background_image_login_path" ||
            key === "logo_admin_path" ||
            key === "favicon_path" ||
            key === "logo_login_path"
          ) {
            if (
              loginSettingFormData[key] === undefined ||
              loginSettingFormData[key] === null
            )
              return;
            removePictureS3(loginSettingFormData[key] as string);
          }
        });
      },
      { withSuccess: true }
    );
  };

  useEffect(() => {
    if (isUpdateDatabase) {
      handleUpdateDatabase();
      setIsUpdateDatabase(false);
    }
  }, [isUpdateDatabase]);

  const handleSubmit = async () => {
    swalLoading();
    setIsDisableButton(true);
    let promiseArr: Promise<void>[] = [];
    Object.keys(stempPictures).map(async function (key, index) {
      if (stempPictures[key as keyof StempPicturesType] !== undefined) {
        promiseArr.push(
          handleUploadFile(
            stempPictures[key as keyof StempPicturesType] || null,
            key
          )
        );
        //Delete old picture if add another picture without press "x" button
        if (
          stempPicturePathWatingDelete[
            key as keyof StempPicturePathWatingDeleteType
          ]
        ) {
          removePictureS3(
            stempPicturePathWatingDelete[
              key as keyof StempPicturePathWatingDeleteType
            ]
          );
        }
      }
      if (
        stempPictures[key as keyof StempPicturesType] === undefined &&
        isDeletePicture[key as keyof IsDeletePictureType]
      ) {
        removePictureS3(
          stempPicturePathWatingDelete[
            key as keyof StempPicturePathWatingDeleteType
          ]
        );
      }
    });
    if (promiseArr.length > 0) {
      Promise.all(promiseArr).then((resolve) => {
        setIsUpdateDatabase(true);
      });
    } else {
      setIsUpdateDatabase(true);
    }
  };

  return (
    <>
      {errors && Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}
      <div className="py-[20px]">
        <div className="flex flex-col">
          <div>システム名称</div>
          <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px]">
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
              <div className="w-full flex justify-between">
                <div className="">システム名称</div>
                <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                  必須
                </div>
              </div>
            </div>
            <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px] mr-[30px]">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
                name="system_name"
                value={loginSettingFormData.system_name}
                onChange={handleChangeForm}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-[50px]">
          <div>ログインページ　ロゴ</div>
          <div className="text-[12px]">
            ログイン画面に表示されるロゴです。（推奨サイズ　幅180 x 高さ120px）
          </div>
          <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[10px] pl-[14px]">
              ロゴ（.svg, .png形式／最大1MB）
            </div>

            <div className="text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%]">
              <div className="flex items-center">
                {loginSettingFormData.logo_login_path &&
                loginSettingFormData.logo_login_path !== undefined ? (
                  <div className="relative w-[330px] my-[5px] mr-[16px] overflow-hidden">
                    <ImageInternalButton
                      removeImage={() => {
                        if (inputUpdateAvatarPhoto.current) {
                          setIsDeletePicture({
                            ...isDeletePicture,
                            logo_login: true,
                          });
                          inputUpdateAvatarPhoto.current.value = "";
                        }
                        setLoginSettingFormData({
                          ...loginSettingFormData,
                          logo_login_path_url: undefined,
                          logo_login_path: null,
                        });
                        setStempPictures({
                          ...stempPictures,
                          logo_login: undefined,
                        });
                      }}
                      editImage={() => inputUpdateAvatarPhoto.current?.click()}
                    />
                    <img
                      className=" object-cover border-gray-200 my-[5px]"
                      src={loginSettingFormData.logo_login_path_url}
                      alt="Profile Avatar"
                    />
                  </div>
                ) : (
                  <div
                    className="w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
                    onClick={() => inputUpdateAvatarPhoto.current?.click()}
                  ></div>
                )}
                <label
                  htmlFor="logo_login_path"
                  className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]"
                >
                  ファイルを選択
                </label>
                <input
                  type="file"
                  id="logo_login_path"
                  name="logo_login_path"
                  accept="image/svg+xml, image/png"
                  className="hidden"
                  ref={inputUpdateAvatarPhoto}
                  onChange={(e) => {
                    saveStempPictures(e, inputUpdateAvatarPhoto);
                  }}
                  placeholder="ファイルを選択"
                />
                {!loginSettingFormData.logo_login_path_url && (
                  <div className="ml-[10px] text-[10px]">
                    選択されていません
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-[50px]">
          <div>管理画面　ロゴ</div>
          <div className="text-[12px]">
            管理画面の左上に表示されるロゴです。（推奨サイズ　幅250 x 高さ60px）
          </div>
          <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[10px] pl-[14px]">
              ロゴ（.svg, .png形式／最大1MB）
            </div>

            <div className="text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%]">
              <div className="flex items-center">
                {loginSettingFormData.logo_admin_path &&
                loginSettingFormData.logo_admin_path !== undefined ? (
                  <div className="relative w-[330px] my-[5px] mr-[16px] overflow-hidden">
                    <ImageInternalButton
                      removeImage={() => {
                        if (inputUpdateLoginAdminPhoto.current) {
                          inputUpdateLoginAdminPhoto.current.value = "";
                          setIsDeletePicture({
                            ...isDeletePicture,
                            logo_admin: true,
                          });
                        }
                        setLoginSettingFormData({
                          ...loginSettingFormData,
                          logo_admin_path_url: undefined,
                          logo_admin_path: null,
                        });
                        setStempPictures({
                          ...stempPictures,
                          logo_admin: undefined,
                        });
                      }}
                      editImage={() =>
                        inputUpdateLoginAdminPhoto.current?.click()
                      }
                    />
                    <img
                      className=" w-auto object-cover border-gray-200"
                      src={loginSettingFormData.logo_admin_path_url}
                      alt="Profile Avatar"
                    />
                  </div>
                ) : (
                  <div
                    className="w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
                    onClick={() => inputUpdateLoginAdminPhoto.current?.click()}
                  ></div>
                )}
                <label
                  htmlFor="logo_admin_path"
                  className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]"
                >
                  ファイルを選択
                </label>
                <input
                  type="file"
                  id="logo_admin_path"
                  name="logo_admin_path"
                  accept="image/svg+xml, image/png"
                  className="hidden"
                  ref={inputUpdateLoginAdminPhoto}
                  onChange={(e) =>
                    saveStempPictures(e, inputUpdateLoginAdminPhoto)
                  }
                  placeholder="ファイルを選択"
                />
                {!loginSettingFormData.logo_admin_path && (
                  <div className="ml-[10px] text-[10px]">
                    選択されていません
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-[50px]">
          <div>ファビコン</div>
          <div className="text-[12px]">
            ブラウザのタブや、webページをお気に入り登録した際にタイトル横に表示されるアイコンです。（推奨サイズ　幅25
            x 高さ25px）
          </div>
          <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[10px] pl-[14px]">
              ロゴ（.ico形式／最大1MB）
            </div>

            <div className="text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%] pt-[3px]">
              <div className="flex justify-start bg-white items-center object-cover border border-gray-200 text-gray-900 text-sm focus:ring-blue-100 focus:border-blue-100 w-full pr-[22px] pl-[10px] py-[5px] mb-[5px]">
                <div className="bg-[#F4F4F4] flex justify-center items-center w-[40px] h-[40px] max-w-[40px] max-h-[40px]">
                  <input
                    type="file"
                    id="favicon_path"
                    name="favicon_path"
                    accept="image/x-icon"
                    className="hidden"
                    ref={inputUpdateFaviconPhoto}
                    onChange={(e) =>
                      saveStempPictures(e, inputUpdateFaviconPhoto)
                    }
                    placeholder="ファイルを選択"
                  />
                  {loginSettingFormData.favicon_path ? (
                    <img
                      src={
                        loginSettingFormData.favicon_path
                          ? loginSettingFormData.favicon_path_url
                          : LogoDefault
                      }
                      className="object-cover w-full h-full"
                    />
                  ) : (
                    <div className="w-full h-full text-[#7A7A7A] leading-[100%] mt-[10px] pl-[5px]">
                      <p>企業</p>
                      <p>ロゴ</p>
                    </div>
                  )}
                </div>
                {loginSettingFormData.favicon_path ? (
                  <>
                    <div className="relative ml-[70px] mr-[15px] mb-[35px]">
                      <ImageInternalButton
                        removeImage={() => {
                          if (inputUpdateFaviconPhoto.current) {
                            inputUpdateFaviconPhoto.current.value = "";
                            setIsDeletePicture({
                              ...isDeletePicture,
                              favicon: true,
                            });
                          }
                          setLoginSettingFormData({
                            ...loginSettingFormData,
                            favicon_path_url: undefined,
                            favicon_path: null,
                          });
                          setStempPictures({
                            ...stempPictures,
                            favicon: undefined,
                          });
                        }}
                        editImage={() => {
                          inputUpdateFaviconPhoto.current?.click();
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="bg-[#7E869E] text-white rounded-full mx-[15px]"
                      onClick={() => inputUpdateFaviconPhoto.current?.click()}
                    >
                      <AiOutlineUpload size={20} className="px-1 py-1" />
                    </button>
                    <p className="text-[10px]">選択されていません</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-[50px] mb-[20px]">
          <div>ログインページ　背景デザイン</div>
          <div className="text-[12px]">
            ログイン画面の背景を変更できます。単色または画像を指定してください。（画像の場合：推奨サイズ　幅1440
            x 高さ880px）
          </div>
          <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex justify-between items-center pl-[14px]">
              <div>背景</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
            <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px] mr-[30px]">
              {BACKGROUND_DATA.slice(0, 2).map((item, index) => (
                <label
                  className="inline-flex items-center mr-[15px]"
                  key={index}
                >
                  <input
                    type="radio"
                    className="form-radio text-primary-light border border-success-extralight"
                    name="background_login_type"
                    value={item.value}
                    onChange={handleChangeForm}
                    checked={
                      loginSettingFormData.background_login_type === item.value
                    }
                  />
                  <span className="ml-[8px] text-[12px] leading-[100%] cursor-pointer">
                    {item.label}
                  </span>
                </label>
              ))}
            </div>
          </div>
          <div
            className={`${
              loginSettingFormData.background_login_type !==
              BACKGROUND_LOGIN_TYPE.COLOR
                ? "hover:cursor-not-allowed bg-secondary-lighter opacity-50 z-[1000]"
                : ""
            } flex mb-[2px] border-b-[1.5px] `}
          >
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[10px] pl-[14px]">
              背景色の選択
            </div>
            <div
              className={`${
                loginSettingFormData.background_login_type !==
                BACKGROUND_LOGIN_TYPE.COLOR
                  ? "pointer-events-none "
                  : ""
              } text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%]`}
            >
              <div className="flex items-center">
                <div
                  className="rectangle m-[15px]"
                  style={{ backgroundColor: backgroundColor }}
                ></div>
                <div className="flex flex-col">
                  <div className="p-[5px]">選択中の色</div>
                  <div className="text-secondary-dark flex items-center">
                    <input
                      className="text-[13px] leading-[100%] px-[11px] py-[8px] w-[90px] h-[30px] border-[#EFF1F0]"
                      name="background_login_color"
                      value={backgroundColor}
                      onChange={(e) =>
                        setBackgroundColor(e.currentTarget.value)
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-[10px] px-[10px]">
                  {colorData.map((item, index) => (
                    <ColorOption
                      key={index}
                      color={item}
                      onChange={setBackgroundColor}
                      wrapperClassName="relative"
                      handleRemove={removeColorData}
                    />
                  ))}
                  {colorAdd && (
                    <div className="">
                      <div
                        className="round cursor-pointer"
                        style={{ backgroundColor: colorAdd }}
                        onClick={() => setBackgroundColor(colorAdd)}
                      />
                    </div>
                  )}
                </div>
                {showColorPicker && (
                  <div className="relative">
                    <div
                      className="absolute bottom-0 left-0 z-10"
                      onMouseEnter={() => setIsMouseEnter(true)}
                      onMouseLeave={() => setIsMouseEnter(false)}
                    >
                      <SketchPicker
                        color={backgroundColor}
                        // onChangeComplete={handleChangeComplete}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
                <button type="button" onClick={toggleColorPicker}>
                  <Add />
                </button>
                <div className="text-[12px] ml-[10px]">ユーザー設定の色</div>
              </div>
            </div>
          </div>
          <div
            className={`${
              loginSettingFormData.background_login_type !==
              BACKGROUND_LOGIN_TYPE.PICTURE
                ? "hover:cursor-not-allowed bg-secondary-lighter opacity-50 relative z-[1000]"
                : ""
            } flex min-h-[40px] mb-[2px] border-b-[1.5px]`}
          >
            <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[10px] pl-[14px]">
              背景画像を選択（最大15MB）
            </div>
            <div className="text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%]">
              <div
                className={`${
                  loginSettingFormData.background_login_type !==
                  BACKGROUND_LOGIN_TYPE.PICTURE
                    ? "pointer-events-none"
                    : ""
                } flex items-center`}
              >
                {loginSettingFormData.background_image_login_path &&
                loginSettingFormData.background_image_login_path !==
                  undefined ? (
                  <div className="relative w-[330px] object-cover mr-4 border-gray-200 my-[5px]">
                    <ImageInternalButton
                      removeImage={() => {
                        if (inputFileRef.current) {
                          inputFileRef.current.value = "";
                          setIsDeletePicture({
                            ...isDeletePicture,
                            background_image_login: true,
                          });
                        }
                        setLoginSettingFormData({
                          ...loginSettingFormData,
                          background_image_login_path_url: undefined,
                          background_image_login_path: null,
                        });
                        setStempPictures({
                          ...stempPictures,
                          background_image_login: undefined,
                        });
                      }}
                      editImage={() => inputFileRef.current?.click()}
                    />
                    <img
                      className="w-[330px] object-cover mr-4 border-gray-200 my-[5px]"
                      src={
                        loginSettingFormData?.background_image_login_path_url ||
                        undefined
                      }
                      alt="Profile Avatar"
                    />
                  </div>
                ) : (
                  <div
                    className="w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
                    onClick={() => inputFileRef.current?.click()}
                  ></div>
                )}
                <label
                  htmlFor="background_image_login_path"
                  className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]"
                >
                  ファイルを選択
                </label>
                <input
                  type="file"
                  id="background_image_login_path"
                  name="background_image_login_path"
                  accept="image/*"
                  className="hidden"
                  ref={inputFileRef}
                  onChange={(e) => saveStempPictures(e, inputFileRef)}
                  placeholder="ファイルを選択"
                />
                {!loginSettingFormData.background_image_login_path && (
                  <div className="ml-[10px] text-[10px]">dummyfilename.jpg</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mb-[28px] mt-[26px]">
        <button
          className={`${
            isDisableButton
              ? "bg-secondary-extralight cursor-not-allowed"
              : "bg-primary"
          } px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]`}
          onClick={() => {
            if (isDisableButton) return;
            handleSubmit();
          }}
        >
          設定を保存
        </button>
      </div>
    </>
  );
};

export default LoginPageSettingEdit;
