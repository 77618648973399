import React from "react";
import LearningType from "../Learning/Type";
import ChapterStatus from "../Course/Lectures/Chapters/ChapterType";
import UnitButtonGroup from "../common/UnitButtonGroup";
import { UnitForm, UnitProgress } from "../../../types/admin/unit";
import { getTimeObjectFromMilliseconds } from "../../../services/helpers/formatTime";
import { useNavigate } from "react-router-dom";
import { getPercent, parsePercent } from "../../../services/helpers/parseData";
import { CHAPTER_STATUS } from "../../../services/constants/student/chapter";
import LayoutWaitingApi from "../common/LayoutWaitingApi";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const UnitsProgress = ({
  courseId,
  lectureId,
  nextUnitData,
  prevUnitData,
  unitProgressData,
  waitingApi,
}: {
  courseId?: number;
  lectureId?: number;
  nextUnitData: UnitForm;
  prevUnitData: UnitForm;
  unitProgressData: UnitProgress;
  waitingApi: boolean;
}) => {
  const nextUnitTimeRequired = getTimeObjectFromMilliseconds(nextUnitData.time);
  const navigate = useNavigate();
  const unitProgressPercent = getPercent(
    unitProgressData.total_chapter_done,
    unitProgressData.total_chapter
  );
  const { studentTheme } = useStudentThemeContext();

  return (
    <div className="w-full border border-danger-light rounded-[5px] mb-[15px] py-2 px-2">
      <div className="w-full bg-[#FAF7F0] pt-3 pb-1 px-3 rounded-[5px] mb-[45px]">
        <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
          次の単元
        </div>
        <LayoutWaitingApi waitingApi={waitingApi} className="w-full h-[100px]">
          <>
            {Object.keys(nextUnitData).length > 0 && (
              <div
                className={`flex items-center justify-between pl-[20px] min-h-[90px] bg-white border-b border-danger-light relative mb-[15px]`}
              >
                <div className="flex items-center">
                  <LearningType type={nextUnitData.type} />
                  <div className="min-w-[150px] ml-[20px] pt-[7px]">
                    <div className="text-[11px] font-[500] text-secondary max-w-[500px] truncate">
                      {nextUnitData.chapter_name || ""}
                    </div>
                    <div
                      style={{ color: studentTheme.text_color }}
                      className="text-[16px] text-black font-[500] max-w-[500px] truncate max-sm:max-w-[120px]"
                    >
                      {nextUnitData.name || ""}
                    </div>
                    <div className="text-secondary-light text-[8px] font-[500] flex gap-[15px] w-full mt-[2px]">
                      {`${String(nextUnitTimeRequired.hours || 0).padStart(
                        2,
                        "0"
                      )} : ${String(nextUnitTimeRequired.minutes || 0).padStart(
                        2,
                        "0"
                      )} : ${String(nextUnitTimeRequired.seconds || 0).padStart(
                        2,
                        "0"
                      )}`}
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => navigate(`?unit_id=${nextUnitData.id}`)}
                    className="text-[14px] font-[700] bg-danger leading-[100%] text-white rounded-[5px] border border-danger flex items-center justify-center max-w-[138px] h-[34px] mr-[27.5px] cursor-pointer max-sm:text-[12px] px-[5px]"
                  >
                    次の単元へ進む
                  </button>
                </div>
              </div>
            )}
            <div
              className={`min-h-[40px] border border-danger-light w-full text-[14px] font-[500] leading-[100%] mb-[12px] bg-[#FEFCF9] flex flex-col justify-center`}
            >
              <div className="flex items-center  min-h-[40px] pl-[20px]">
                <div className="flex items-center w-[60%]">
                  <ChapterStatus
                    status={CHAPTER_STATUS.CHAPTER_STATUS_UNLEARNED}
                  />
                  <span className="ml-[20px]">これまでの学習状況</span>
                </div>
                <div className="w-full max-w-[275px] mx-[5px] max-sm:max-w-[100px] max-sm:text-[12px]">
                  <div className="w-full rounded-full h-[8px] bg-success-extralight">
                    <div
                      className={`bg-primary-light h-[8px] rounded-full`}
                      style={{
                        width: `${parsePercent(unitProgressPercent)}%`,
                        backgroundColor: studentTheme.sub_color_first,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="ml-[16px] text-secondary-disabled font-[700] with_roboto mr-[27.5px] whitespace-nowrap">
                  <span
                    style={{ color: studentTheme.main_color_first }}
                    className="text-[24px] text-primary"
                  >
                    {unitProgressData.total_chapter_done}
                  </span>
                  /{unitProgressData.total_chapter}{" "}
                  <span className="text-[10px] font-[500]">章</span>
                </div>
              </div>
            </div>
          </>
        </LayoutWaitingApi>
      </div>
      <UnitButtonGroup
        courseId={courseId}
        lectureId={lectureId}
        nextUnitId={nextUnitData?.id}
        prevUnitId={prevUnitData?.id}
        textColor={studentTheme.text_color}
      />
    </div>
  );
};

export default UnitsProgress;
