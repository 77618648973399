import React from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { Question } from "../../../../types/admin/unit";
import { UNIT_TEST_QUESTION_TYPE_DATA } from "../../../../services/constants/admin/pages/material";
import { useNavigate } from "react-router-dom";
import { Direction } from "../../../../types/globals";
import { DIRECTION } from "../../../../services/constants/globals";
import { HiOutlineEye } from "react-icons/hi";

type UnitTestListItemProps = {
  data?: Question;
  className?: string;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: (id?: number | string) => void;
  handleChangeOrder: (id?: number | string, direction?: Direction) => void;
  isUpDisabled?: boolean;
  isDownDisabled?: boolean;
  setIsPreviewOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const UnitTestListItem = ({
  data,
  className,
  setIsOpenModal,
  handleDelete,
  handleChangeOrder,
  isUpDisabled,
  isDownDisabled,
  setIsPreviewOpenModal,
}: UnitTestListItemProps) => {
  const navigate = useNavigate();

  function getLabelByValue(value?: number | string) {
    const questionType = UNIT_TEST_QUESTION_TYPE_DATA.find(
      (item) => item.value === value
    );
    return questionType ? questionType.label : "";
  }

  return (
    <div
      className={`border border-secondary-light rounded-[5px] min-h-[50px] text-secondary-dark flex font-[500] items-center ${
        className || ""
      }`}
    >
      <div className="flex gap-[18px] w-[15%] pl-[15px]">
        <HiOutlineEye
          className="text-secondary-light text-[16px] leading-[100%] cursor-pointer"
          size={25}
          onClick={() => {
            navigate(
              `?unit_id=${data?.unit_id}&preview_question_id=${data?.id}`
            );
            setIsPreviewOpenModal(true);
          }}
        />
        問題{data?.order || ""}
      </div>
      <div className="text-[14px] leading-[100%] w-[30%]">
      出題形式: {getLabelByValue(data?.type)}
      </div>
      <div className="text-[12px] leading-[100%] w-[10%] flex justify-center">
        <div className="flex gap-[10px] items-center">
          配点
          <input
            type="number"
            name=""
            id=""
            disabled
            value={data?.score}
            className="border !border-secondary-light rounded-[2px] max-w-[40px] h-[24px]"
          />
        </div>
      </div>
      <div className="text-[12px] leading-[100%] w-[10%] flex justify-center">
        <div className="flex gap-[10px] items-center">
          出題順
          <input
            type="number"
            name=""
            id=""
            disabled
            value={data?.order}
            className="border !border-secondary-light rounded-[2px] max-w-[35px] h-[24px]"
          />
        </div>
      </div>
      <div className="flex text-secondary-light w-[15%] justify-center">
        <div className="flex">
          <AiOutlineArrowUp
            className={`items-center ${
              isUpDisabled
                ? "text-secondary-extralight cursor-not-allowed"
                : "text-secondary cursor-pointer"
            }`}
            size={22}
            onClick={() => handleChangeOrder(data?.id, DIRECTION.UP)}
          />
          <AiOutlineArrowDown
            className={`items-center ${
              isDownDisabled
                ? "text-secondary-extralight cursor-not-allowed"
                : "text-secondary cursor-pointer"
            }`}
            size={22}
            onClick={() => handleChangeOrder(data?.id, DIRECTION.DOWN)}
          />
        </div>
      </div>
      <div
        className="min-h-[30px] w-[140px] font-[500] font-[14px] leading-[100%] flex items-center justify-center rounded-[5px] bg-primary text-white w-[15%] cursor-pointer"
        onClick={() => {
          navigate(`?unit_id=${data?.unit_id}&question_id=${data?.id}`);
          setIsOpenModal(true);
        }}
      >
        問題を編集
      </div>
      <div
        className="w-[10%] flex justify-center cursor-pointer"
        onClick={() => handleDelete(data?.id)}
      >
        <FiTrash2 className="text-secondary-light" size={20} />
      </div>
    </div>
  );
};

export default UnitTestListItem;
