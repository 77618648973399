import React from 'react';

const LearningRead = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={16} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.891.727C13.164.498 12.366.4 11.6.4c-1.276 0-2.65.262-3.6.982C7.051.662 5.676.4 4.4.4 3.124.4 1.75.662.8 1.382v9.589c0 .163.164.327.327.327.066 0 .099-.033.164-.033.884-.425 2.16-.72 3.11-.72 1.275 0 2.65.262 3.599.982.884-.556 2.487-.982 3.6-.982 1.08 0 2.193.197 3.11.688.065.032.097.032.163.032a.352.352 0 0 0 .327-.327V1.382c-.393-.295-.818-.491-1.309-.655Zm0 8.837c-.72-.23-1.505-.328-2.291-.328-1.113 0-2.716.426-3.6.982V2.691c.884-.557 2.487-.982 3.6-.982.786 0 1.571.098 2.291.327v7.528Z"
        fill={`${props.color || '#fff'}`}
      />
      <path
        d="M11.6 4.327c.576 0 1.132.06 1.636.17v-.994a8.84 8.84 0 0 0-1.636-.158c-1.113 0-2.12.19-2.945.544v1.086c.74-.419 1.767-.648 2.945-.648ZM8.655 5.63v1.086c.74-.418 1.767-.648 2.945-.648.576 0 1.132.06 1.636.17v-.994a8.84 8.84 0 0 0-1.636-.157c-1.113 0-2.12.196-2.945.543ZM11.6 6.834c-1.113 0-2.12.19-2.945.544v1.086c.74-.419 1.767-.648 2.945-.648.576 0 1.132.059 1.636.17v-.995a8.311 8.311 0 0 0-1.636-.157Z"
        fill={`${props.color || '#fff'}`}
      />
    </svg>
  );
};

export default LearningRead;
