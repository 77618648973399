import React, { ChangeEvent, useEffect } from "react";
import { useState } from "react";
import { makeRequest } from "../../../../services/axios/axios";
import {
  swalClose,
  swalError,
  swalLoading,
} from "../../../../services/helpers/swal";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { API } from "../../../../services/constants/route/api";

import { File } from "../../../../types/admin/folder";

import AllFileTable from "./AllFileTable";
import AllFileFilter from "../AllFileFilter";
import FileUploadFolder from "../../commons/FileUploadFolder";
import BaseModal from "../../commons/BaseModal";

type FilterType = {
  limit: number;
  page: number;
  sortType?: number;
};

const AllFileList = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    page: 1,
    sortType: 1,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [files, setFiles] = useState<File[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [fileInfo, setFileInfo] = useState<File | null>(null);

  useEffect(() => {
    const fetchFolder = async () => {
      // swalLoading();

      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_FOLDER.FILE}?${paramizeObject(filter)}`,
      });

      if (!result.data) return swalError();
      setFiles(result.data.resources);
      setTotalPages(result.data.total_page);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetchFolder();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const resetTable = () => {
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  const handleFileOverwrite = (file: File) => {
    setFileInfo(file);
    setIsOpenModal(true);
  };

  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <div>
      <AllFileFilter changeHandle={changeHandle} />
      <AllFileTable
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        files={files}
        totalPages={totalPages}
        handleFileOverwrite={handleFileOverwrite}
      />
      {isOpenModal && (
        <BaseModal
          setIsOpen={setIsOpenModal}
          width={1010}
          height={740}
          justifyDirection="end"
          submitLabel="アップロード"
        >
          <FileUploadFolder
            setIsOpen={setIsOpenModal}
            resetTable={resetTable}
            changePaginate={changePaginate}
            fileInfo={fileInfo}
          />
        </BaseModal>
      )}
    </div>
  );
};

export default AllFileList;
