import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import {
  formatReviewTestTime,
  getTimeObjectFromMilliseconds,
} from "../../../services/helpers/formatTime";
import {
  extractDateStringIntoObject,
  getPercent,
  parsePercent,
} from "../../../services/helpers/parseData";
import { Statistics } from "../../../types/student/course";
import CircularProgress from "../../commons/charts/CircularProgress";
import DoughnutChart from "../../commons/charts/Doughnut";
import VerticalBar from "../../commons/charts/VerticalBar";
type StatisticalChartProps = {
  blue?: boolean;
  title?: string;
  statistics?: Statistics;
};
const StatisticalChart = ({
  blue = false,
  title,
  statistics,
}: StatisticalChartProps) => {
  const targetTimePerWeek = 90000000;
  const totalTimeLearnedInSevenDays = getTimeObjectFromMilliseconds(
    Number(statistics?.time_learned?.total_time_learned_in_seven_day || 1)
  );
  const timeLearnedPercentDone = getPercent(
    statistics?.time_learned?.total_time_learned_in_seven_day,
    targetTimePerWeek
  );
  const totalTimeLearnedInCourse = getTimeObjectFromMilliseconds(
    Number(statistics?.time_learned?.total_time_learned || 1)
  );
  const lecturesTotal = statistics?.lectures?.total_lectures || 0;
  const lecturesCompleted = statistics?.lectures?.total_lectures_completed || 0;
  const lecturesDonePercentage = getPercent(lecturesCompleted, lecturesTotal);
  const unitLessonAmount = statistics?.units?.lessons?.total_unit || 0;
  const unitTestAmount = statistics?.units?.tests?.total_unit || 0;
  const totalUnitAmount = unitLessonAmount + unitTestAmount;
  const totalUnitLessonTime = getTimeObjectFromMilliseconds(
    Number(statistics?.units?.lessons?.time_learned || 1)
  );
  const detailUnitLessonTime = formatReviewTestTime(
    Number(statistics?.units?.lessons?.time_learned)
  );
  const totalUnitTestTime = getTimeObjectFromMilliseconds(
    Number(statistics?.units?.tests?.time_learned || 1)
  );
  const detailUnitTestTime = formatReviewTestTime(
    Number(statistics?.units?.tests?.time_learned)
  );
  const startDateOfWeek = extractDateStringIntoObject(
    statistics?.time_learned_in_a_week?.start_date_of_week || "00-00-00"
  );
  const endDateOfWeek = extractDateStringIntoObject(
    statistics?.time_learned_in_a_week?.end_date_of_week || "00-00-00"
  );
  const formatDateTimeStatistics = () => {
    if (!startDateOfWeek || !endDateOfWeek) return "";
    return `${startDateOfWeek.year}年${startDateOfWeek.month}月${startDateOfWeek.day}日 〜 ${endDateOfWeek.year}年${endDateOfWeek.month}月${endDateOfWeek.day}日`;
  };
  const { studentTheme } = useStudentThemeContext();
  const data = {
    datasets: [
      {
        data: [unitTestAmount, 0, unitLessonAmount],
        backgroundColor: [
          blue
            ? studentTheme.sub_color_first || "rgba(143,194,213,0.5)"
            : studentTheme.sub_color_second || "rgba(232,201,152, 0.5)",
          "rgba(190, 190, 190, 0.2)",
          blue ? studentTheme.main_color_first : studentTheme.main_color_second,
        ],
        hoverOffset: 4,
        radius: 38,
        borderRadius: 10,
        offset: 5,
        cutout: "80%",
        rotation: 45,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return (
    <>
      <section
        className={`${
          blue ? "bg-warning-extralight" : "bg-secondary-morelighter"
        } h-[334px] px-[16px] py-[20px] mb-[20px] max-[320px]:px-0 max-md:min-h-[450px]
        max-[320px]:min-h-[600px] max-sm:min-h-[435px] max-sm:px-[5px] max-sm:py-[10px] max-sm:mx-[10px] max-sm:flex max-sm:flex-col max-sm:items-center animate-[show_0.5s_ease-in-out]`}
      >
        <div
          style={{
            backgroundColor: blue
              ? studentTheme.main_color_first
              : studentTheme.page_top_color,
          }}
          className={`w-auto max-w-[500px] h-[24px] ${
            blue ? "bg-primary" : "bg-success-light"
          }  text-white rounded-[5px] inline-block px-[15px] py-[1px] text-center mb-[26px]
          max-sm:w-full max-sm:h-[27px] max-sm:pt-[3px] max-sm:text-[16px] max-sm:max-w-[307px] truncate`}
        >
          <div className="font-[700] text-white text-[14px] leading-[20px] tracking-[0.02em] w-full truncate">
            {title || ""}
          </div>
        </div>

        <div
          className="flex gap-[28.5px] justify-center 
        max-[320px]:flex-col max-[320px]:w-full max-[320px]:items-center
        max-sm:px-[6px] max-sm:gap-[10px]"
        >
          <div className="w-full max-w-[200px] h-[200px] relative max-sm:w-[190px] max-sm:h-[190px]">
            <div
              className="absolute top-0 left-0 w-full h-[200px] rounded-full bg-white flex items-center justify-center
              max-sm:h-[190px] max-sm:w-[190px] "
            >
              <div className="text-center">
                <div className="text-[14px] font-[700] leading-[20px] tracking-[0.02em] text-secondary mb-[6.86px]">
                  学習時間
                </div>
                <div className="text-[11px] leading-[100%] tracking-[0.02em] text-secondary mb-[6.94px]">
                  7日間合計
                </div>
                <div
                  style={{
                    color: blue
                      ? studentTheme.main_color_first
                      : studentTheme.page_top_color,
                  }}
                  className={`text-[12px] font-[500] leading-[100%] ${
                    blue ? "text-primary" : "text-success-light"
                  }  mb-[16.61px]`}
                >
                  <span className="text-[24px] font-[700] leading-[100%] with_roboto mr-[2px]">
                    {totalTimeLearnedInSevenDays.hours || 0}
                  </span>
                  時間
                  <span className="text-[24px] font-[700] leading-[100%] with_roboto mx-[2px]">
                    {totalTimeLearnedInSevenDays.minutes || 0}
                  </span>
                  分
                </div>
              </div>
            </div>
            <CircularProgress
              blue={blue}
              sqSize={175}
              strokeWidth={17}
              percentage={timeLearnedPercentDone}
              className="absolute top-[12px] left-[12.5px] max-sm:left-[8px] max-sm:top-[8px]"
            />
          </div>
          <div className="w-full max-[320px]:w-full max-sm:w-[140px]">
            <div className="flex gap-[9px] mb-[9px] max-[320px]:mt-0 max-sm:flex-col max-sm:mt-[10%] max-md:mt-[17%] ">
              <div
                className="w-full max-w-[185px] h-[65px] bg-white border border-danger-light rounded-[5px] 
              max-[320px]:max-w-[320px] max-sm:h-[50px] max-sm:shrink-0 max-sm:min-w-[140px]"
              >
                <div
                  className="font-[500] text-[11px] leading-[16px] tracking-[0.02em] text-secondary mt-[10px] ml-[32px] mb-[4.5px]
                            max-sm:ml-[8px] max-sm:mt-[6px] max-sm:mb-0 max-sm:text-[10px]"
                >
                  総学習時間
                </div>
                <div
                  style={{
                    color: blue
                      ? studentTheme.main_color_first
                      : studentTheme.page_top_color,
                  }}
                  className={`text-[12px] font-[500] leading-[100%] ${
                    blue ? "text-primary" : "text-success-light"
                  } max-sm:text-center`}
                >
                  <span className="text-[24px] font-[700] leading-[100%] with_roboto mr-[2px] ml-[34px] max-sm:ml-0 max-sm:text-[22px]">
                    {totalTimeLearnedInCourse.hours || 0}
                  </span>
                  時間
                  <span className="text-[24px] font-[700] leading-[100%] with_roboto mx-[2px] max-sm:text-[22px] ">
                    {totalTimeLearnedInCourse.minutes || 0}
                  </span>
                  分
                </div>
              </div>
              <div className="w-full h-[65px] bg-white border border-danger-light rounded-[5px] max-sm:h-[50px]">
                <div
                  className="font-[500] text-[11px] leading-[16px] tracking-[0.02em] text-secondary mt-[10px] ml-[20px] mb-[4.5px]
                            max-sm:hidden"
                >
                  修了講座
                </div>
                <div
                  className="hidden font-[500] text-[11px] leading-[16px] tracking-[0.02em] text-secondary mt-[10px] ml-[20px] mb-[4.5px]
                            max-sm:block max-sm:ml-[8px] max-sm:mt-[6px] max-sm:mb-0  max-sm:text-[10px]"
                >
                  学習状況（講座数）
                </div>
                <div className="flex max-[320px]:items-center max-[320px]:justify-center max-md:justify-center max-md:items-center max-md:gap-[5px]]">
                  <div
                    style={{
                      color: blue
                        ? studentTheme.main_color_first
                        : studentTheme.page_top_color,
                    }}
                    className={`text-[24px] font-[700] with_roboto leading-[100%] ${
                      blue ? "text-primary" : "text-success-light"
                    }  ml-[26px] mr-[48px] max-[320px]:shrink-0 max-[320px]:w-[100px] max-sm:ml-0 max-sm:mr-0 max-md:mr-[10px] max-sm:flex-1 max-sm:text-center max-sm:text-[22px]`}
                  >
                    {lecturesCompleted || 0}
                    <span className="text-[16px] font-[700] leading-[100%] with_roboto text-secondary-light max-sm:text-[14px]">
                      /{lecturesTotal || 0}
                    </span>
                  </div>
                  <div className="w-full max-w-[160px] mt-[12px] overflow-hidden rounded-full max-[320px]:max-w-[200px] max-sm:flex-1 max-sm:max-w-[60px] max-sm:mr-[5px] max-md:max-w-[80px] max-md:mt-0">
                    <div className="w-full rounded-full h-[12px] bg-success-extralight">
                      <div
                        style={{
                          width: `${parsePercent(lecturesDonePercentage)}%`,
                          backgroundColor: blue
                            ? studentTheme.sub_color_first
                            : studentTheme.main_color_second,
                        }}
                        className={`${
                          blue ? "bg-primary-light" : "bg-warning"
                        } h-[12px] rounded-full`}
                      ></div>
                    </div>
                  </div>
                </div>
                {/* <div className="hidden max-sm:flex justify-center mt-[20px]">
                  <button
                    style={{ backgroundColor: studentTheme.page_top_color }}
                    className="w-[121px] h-[29px] bg-success-light rounded-[4px] text-white text-[13px] text-center font-[500]"
                  >
                    目標を設定する
                  </button>
                </div> */}
              </div>
            </div>
            {/* Chartbar desktop */}
            <div className="w-full h-[170px] bg-white border border-danger-light rounded-[5px] py-[15px] px-[17px] max-md:hidden">
              <div className="flex justify-start items-center mb-[12px]">
                <div
                  style={{
                    backgroundColor: blue
                      ? studentTheme.main_color_first
                      : studentTheme.page_top_color,
                  }}
                  className={`w-full max-w-[110px] h-[24px] ${
                    blue ? "bg-primary" : "bg-success-light"
                  }  text-white rounded-[5px] flex items-center justify-center mr-[9px]`}
                >
                  <div className="font-[500] text-white text-[14px] leading-[20px] tracking-[0.02em]">
                    学習レポート
                  </div>
                </div>
                <div className="text-[11px] leading-[100%] tracking-[0.03em] text-secondary">
                  {formatDateTimeStatistics()}
                </div>
              </div>

              <div className="flex justify-center h-[95px]">
                <div className="w-full border-r border-danger-light h-full">
                  <div
                    style={{
                      color: blue
                        ? studentTheme.main_color_first
                        : studentTheme.page_top_color,
                    }}
                    className={`text-[13px] font-[500] leading-[19px] tracking-[0.02em] ${
                      blue ? "text-primary" : "text-success-light"
                    }  text-center`}
                  >
                    曜日別
                  </div>
                  <div className="px-[45px] mt-[10px]">
                    <VerticalBar
                      blue={blue}
                      data={
                        statistics?.time_learned_in_a_week
                          ?.time_learned_everyday_in_week || []
                      }
                    />
                  </div>
                </div>
                <div className="w-full text-center">
                  <div
                    style={{
                      color: blue
                        ? studentTheme.main_color_first
                        : studentTheme.page_top_color,
                    }}
                    className={`text-[13px] font-[500] leading-[19px] tracking-[0.02em] ${
                      blue ? "text-primary" : "text-success-light"
                    } `}
                  >
                    内容別
                  </div>
                  <div className="relative w-[100%] h-[100%]">
                    <DoughnutChart
                      data={data}
                      options={options}
                      className="absolute top-0 left-0"
                    />
                    <div className="text-center absolute min-w-[100px] top-[30px] left-[-3px]">
                      <div
                        style={{
                          color: blue
                            ? studentTheme.main_color_first
                            : studentTheme.page_top_color,
                        }}
                        className={`${
                          blue ? "text-primary" : "text-success-light"
                        }  with_roboto font-[700] text-[24px] leading-[100%] mb-[1px] text-center`}
                      >
                        {totalUnitAmount || 0}
                      </div>
                      <div className="text-secondary font-[400] text-[9px] leading-[100%]">
                        単元
                      </div>
                    </div>
                    <div className="text-secondary absolute top-[15px] right-[15px] flex justify-center gap-[12px]">
                      <div className="font-[500] text-[11px] leading-[16px] text-right">
                        <div className="mb-[6px]">講義</div>
                        <div className="mb-[6px]">問題</div>
                      </div>
                      <div className="font-[500] text-[11.76px] leading-[100%] text-left mt-[2px]">
                        <div title={detailUnitLessonTime} className="mb-[8px]">
                          <span className="text-[16.8px] mr-[2.1px]">
                            {totalUnitLessonTime.hours || 0}
                          </span>
                          時間
                          <span className="text-[16.8px] mx-[2.1px]">
                            {totalUnitLessonTime.minutes || 0}
                          </span>
                          分
                        </div>
                        <div title={detailUnitTestTime} className="mb-[10px]">
                          <span className="text-[16.8px] mr-[2.1px]">
                            {totalUnitTestTime.hours || 0}
                          </span>
                          時間
                          <span className="text-[16.8px] mx-[2.1px]">
                            {totalUnitTestTime.minutes || 0}
                          </span>
                          分
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Chartbar responsive phone */}
        <div
          className="hidden w-full sm:h-[170px] bg-white border border-danger-light rounded-[5px] py-[15px] px-[17px] min-[320px]:h-[160px]
          max-md:block max-md:mt-[10px] max-md:h-[190px] max-sm:py-[10px] max-sm:px-[10px] max-sm:mt-[10px] max-[320px]:mt-[50px]"
        >
          <div className="flex justify-start items-center gap-[9px] mb-[12px] max-sm:gap-[20px]">
            <div
              style={{
                backgroundColor: blue
                  ? studentTheme.main_color_first
                  : studentTheme.page_top_color,
              }}
              className={`w-full max-w-[110px] h-[24px] ${
                blue ? "bg-primary" : "bg-success-light"
              }  text-white rounded-[5px] flex items-center justify-center
              max-sm:max-w-[92px] max-sm:h-[22px]`}
            >
              <div
                className="font-[500] text-white text-[14px] leading-[20px] tracking-[0.02em]
                          max-sm:text-[12px]"
              >
                内容別
              </div>
            </div>
            <div className="text-[11px] leading-[100%] tracking-[0.03em] text-secondary font-[400]">
              {formatDateTimeStatistics()}
            </div>
          </div>

          <div className="flex justify-center h-[95px] w-full">
            <div className="w-full border-r border-danger-light h-full max-sm:border-none max-[320px]:max-w-[120px] max-sm:max-w-[150px]">
              <div
                style={{
                  color: blue
                    ? studentTheme.main_color_first
                    : studentTheme.page_top_color,
                }}
                className={`text-[13px] font-[500] leading-[19px] tracking-[0.02em] ${
                  blue ? "text-primary" : "text-success-light"
                }  text-center max-sm:text-left`}
              >
                曜日別
              </div>
              <div className="w-full px-[45px] mt-[10px] max-sm:px-[10px]">
                <VerticalBar
                  blue={blue}
                  data={
                    statistics?.time_learned_in_a_week
                      ?.time_learned_everyday_in_week || []
                  }
                />
              </div>
            </div>
            <div className="w-full text-center">
              <div
                style={{
                  color: blue
                    ? studentTheme.main_color_first
                    : studentTheme.page_top_color,
                }}
                className={`text-[13px] font-[500] leading-[19px] tracking-[0.02em] ${
                  blue ? "text-primary" : "text-success-light"
                } max-sm:text-left`}
              >
                内容別
              </div>
              <div className="relative w-[100%] h-[100%]">
                <DoughnutChart
                  data={data}
                  options={options}
                  className="absolute top-0 left-0 max-[320px]:!w-[64px] max-[320px]:!h-[64px] max-sm:!w-[80px] max-sm:!h-[80px] max-sm:top-[8px] max-sm:left-[10px]"
                />
                <div className="text-center absolute max-sm:w-[40px] max-sm:h-[40px] max-md:top-[32px] max-md:left-[30px] max-[320px]:top-[28px] max-[320px]:left-[22px] max-sm:flex max-sm:flex-col max-sm:items-center">
                  <div
                    style={{
                      color: blue
                        ? studentTheme.main_color_first
                        : studentTheme.page_top_color,
                    }}
                    className={`max-sm:max-w-[40px] text-center ${
                      blue ? "text-primary" : "text-success-light"
                    }  with_roboto font-[700] text-[24px] leading-[100%] mb-[1px] text-center max-sm:text-[20px] max-[320px]:text-[18px]`}
                  >
                    {totalUnitAmount || 0}
                  </div>
                  <div className="text-secondary font-[400] text-[9px] leading-[100%] max-sm:text-[9px]">
                    単元
                  </div>
                </div>
                <div className="text-secondary absolute top-[20px] right-0 flex justify-center gap-[12px] pr-[5px]">
                  <div className="font-[500] text-[11px] leading-[16px] text-right max-sm:text-[10px]">
                    <div className="mb-[6px] max-sm:mb-[3px]">講義</div>
                    <div className="mb-[6px] max-sm:mb-[3px]">問題</div>
                  </div>
                  <div className="font-[500] text-[11.76px] leading-[100%] text-left mt-[2px] max-sm:text-[8px]">
                    <div title={detailUnitLessonTime} className="mb-[8px]">
                      <span className="text-[16.8px] mr-[2.1px] max-sm:text-[14px]">
                        {totalUnitLessonTime.hours || 0}
                      </span>
                      時間
                      <span className="text-[16.8px] mx-[2.1px] max-sm:text-[14px]">
                        {totalUnitLessonTime.minutes || 0}
                      </span>
                      分
                    </div>
                    <div title={detailUnitTestTime} className="mb-[10px]">
                      <span className="text-[16.8px] mr-[2.1px] max-sm:text-[14px]">
                        {totalUnitTestTime.hours || 0}
                      </span>
                      時間
                      <span className="text-[16.8px] mx-[2.1px] max-sm:text-[14px]">
                        {totalUnitTestTime.minutes || 0}
                      </span>
                      分
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Configure Statistics hidden for future phases  */}
        <div className="w-full flex justify-center max-w-[195px] mt-[-26px]">
          {/* Configure Statistics hidden for future phases */}
          {/* <div
            className={`w-full max-w-[93px] h-[21px] ${
              blue
                ? "bg-secondary-extralight text-secondary"
                : "bg-success-light text-white"
            } rounded-[5px] flex items-center justify-center`}
          >
            <div className="text-[11px] leading-[100%]">目標を設定する</div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default StatisticalChart;
