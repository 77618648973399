import React, { ChangeEvent, FormEvent } from "react";

type FormInputProps = {
  labelName?: string;
  className?: string;
  id?: string;
  handleChange: (
    e:
      | FormEvent<HTMLInputElement>
      | ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const FormInput = ({
  labelName,
  className,
  id,
  handleChange,
  ...props
}: FormInputProps) => {
  return (
    <>
      <input
        className={`${className}`}
        onChange={handleChange}
        id={id}
        {...props}
      />
      <label htmlFor={id} className="text-[14px] text-secondary-dark font-[500] ml-[5px]">
        {labelName}
      </label>
    </>
  );
};

export default FormInput;
