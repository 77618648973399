import * as React from 'react';

const Home = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={18} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17 7.75a.74.74 0 0 1-.45-.15L9 1.94 1.45 7.6a.75.75 0 1 1-.9-1.2l8-6a.75.75 0 0 1 .9 0l8 6a.75.75 0 0 1 .15 1 .74.74 0 0 1-.6.35Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
    <path
      d="M15 15.75H3a.76.76 0 0 1-.75-.75V5.5a.75.75 0 0 1 1.5 0v8.75h10.5V5.5a.75.75 0 1 1 1.5 0V15a.76.76 0 0 1-.75.75Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
    <path
      d="M11 15.75a.76.76 0 0 1-.75-.75V8.75h-2.5V15a.75.75 0 1 1-1.5 0V8A.76.76 0 0 1 7 7.25h4a.76.76 0 0 1 .75.75v7a.76.76 0 0 1-.75.75Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
  </svg>
);

export default Home;
