import { PROFILE_SIDEBAR_DATA } from "../../services/constants/student/sidebar";
import defaultLogo from "../../assests/images/default_avatar.webp";
import MenuMobileItem from "./MenuMobileItem";
import { AiOutlineCamera } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../services/constants/route/router";
import { request } from "../../services/axios/axios";
import { API } from "../../services/constants/route/api";
import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { swalError, swalMessage } from "../../services/helpers/swal";
import { MESSAGE_COMMON } from "../../services/constants/message";
import { deleteFile, uploadFileToS3 } from "../../services/helpers/s3Helper";
import { PREFIX_PATH_S3, SERVICE_ID } from "../../services/constants/globals";
import { useStorage } from "../../hooks/useStorage";

const MenuMobile = ({ isOpenProfile = false }: { isOpenProfile: boolean }) => {
  let { setUser, user } = useAuthContext();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [avatarURL, setAvatarURL] = useState<string | null | undefined>(
    user?.avatar_url
  );
  const folderName = "avatar";
  const oz_id = user.organization_id;
  const { setLocalStorage } = useStorage();
  const navigate = useNavigate();

  useEffect(() => {
    setAvatarURL(user?.avatar_url);
  }, [user]);

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;
    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; // Size in MB

    // Check file type
    if (!fileType.startsWith("image/")) {
      swalMessage("", MESSAGE_COMMON.INVALID_FILE_TYPE, "error", {
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    // Check file size
    if (fileSize > 2) {
      swalMessage("", `添付ファイルのサイズが2MBを越えています`, "error", {
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    // setAvatar(file);
    setAvatarURL(URL.createObjectURL(file));
    handleSubmit(file);
  };

  const handleSubmit = async (file: File) => {
    let pathFile = "";

    try {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      const fileName = `${Date.now()}.${fileExtension}`;
      pathFile =
        PREFIX_PATH_S3.FEATURE +
        SERVICE_ID +
        "/" +
        folderName +
        "/" +
        PREFIX_PATH_S3.OZ +
        oz_id +
        "/" +
        fileName;
      await uploadFileToS3(file, pathFile);

      await request.post(
        API.PROFILE.UPDATE_AVATAR,
        { path_file: pathFile },
        (userData) => {
          deleteFile(user.avatar || "");
          setUser(userData || {});
          setLocalStorage("user", userData);
        },
        () => {
          deleteFile(pathFile);
        },
        { withSuccess: true, withLoading: true, hasFileRequest: true }
      );
    } catch (error) {
      console.log("Error: ", error);
      swalError();
    }
  };

  return (
    <div
      className={`absolute top-[50px] right-[5%] max-[320px]:right-[-25%] w-[250px] min-h-[380px] max-[320px]:w-[250px] bg-white p-[20px] z-[1000]
        before:contents-[''] before:block before:w-[24px] before:h-[24px] before:bg-white before:transform before:rotate-45 before:absolute before:top-[-10px] before:right-[10px] max-[320px]:before:right-[30px] ${
          isOpenProfile ? "" : "hidden"
        }
        transition duration-200 ease-in-out`}
    >
      <div className="flex flex-col justify-center items-center py-[10px] border-b border-danger-light">
        <div className="relative w-[80px] h-[80px] bg-secondary-morelighter">
          <div>
            <img
              src={avatarURL ? avatarURL : defaultLogo}
              width={42}
              height={42}
              className="object-cover w-[80px] h-[80px]"
              alt="Profile Avatar"
              onClick={() => inputFileRef.current?.click()}
            />
          </div>
          <div className="absolute bottom-0 right-0 w-[19px] h-[15px] bg-secondary-light">
            <AiOutlineCamera size={16} className="mx-[1.8px] text-white" />
          </div>
        </div>
        <div
          className="text-[12px] font-[400] mt-[10px]"
          onClick={() => inputFileRef.current?.click()}
        >
          プロフィール写真を変更
        </div>
        <input
          type="file"
          id="image"
          name="image"
          accept="image/*"
          className="hidden"
          ref={inputFileRef}
          onChange={handleAvatarChange}
          placeholder="ファイルを選択"
        />
        <div className="text-[16px] font-[500] my-[20px]">
          {" "}
          {(user?.last_name || "") + (user?.first_name || "")} さん
        </div>
      </div>
      <div>
        {PROFILE_SIDEBAR_DATA.map((item, index) => (
          <MenuMobileItem item={item} key={index} />
        ))}
        <div
          className="pt-[30px] text-secondary text-[16px] text-center cursor-pointer"
          onClick={() => navigate(ROUTE.LOGOUT)}
        >
          ログアウト
        </div>
      </div>
    </div>
  );
};

export default MenuMobile;
