import { useNavigate } from "react-router-dom";
import { PATH_ADMIN_COUPON } from "../../../../services/constants/route/router";

type TableActionProps = {
  handleDelete: () => void;
};

const TableAction = ({ handleDelete }: TableActionProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-[19px] text-[16px] text-white font-[700] mt-[40px] mb-[20px] px-[5px]">
      <button
        className="px-[50px] py-[6px] bg-secondary-light rounded-[10px]"
        onClick={handleDelete}
      >
        削除
      </button>
      <button
        className="px-[50px] py-[6px] bg-primary rounded-[10px]"
        onClick={() => navigate(PATH_ADMIN_COUPON.CREATE)}
      >
        クーポン新規追加
      </button>
    </div>
  );
};

export default TableAction;
