import React from "react";

const Button = ({
  children,
  className,
  ...props
}: {
  children?: React.ReactNode;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button className={`flex items-center gap-[10px] ${className}`} {...props}>
    {children}
  </button>;
};

export default Button;
