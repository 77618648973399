/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormItem from "../../commons/FormItem";
import type { ErrorData, Object } from "../../../../types/globals";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import {
  Course,
  CourseItemType,
  FormSettingType,
} from "../../../../types/admin/course";
import ErrorBox from "../../../commons/form/ErrorBox";
import { Lecture } from "../../../../types/admin/lecture";
import StatusBox from "../../commons/StatusBox";
import CancelButton from "../../commons/CancelButton";
import SubmitButton from "../../commons/SubmitButton";
import { PATH_ADMIN_COURSE } from "../../../../services/constants/route/router";
import { Section } from "../../../../types/admin/section";
import {
  DELETE_CONFIRM_CONTENT,
  DELETE_CONFIRM_TITLE,
} from "../../../../services/constants/message";
import { FORM_SETTING_TYPE } from "../../../../services/constants/admin/main";
import { FiTrash2 } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import FormSectionGroup from "./FormSectionGroup";
import { swallConfirm } from "../../../../services/helpers/swal";
import { COURSE_TABLE_ITEM_TYPE } from "../../../../services/constants/admin/pages/course";
import { PUBLIC_STATUS_DATA } from "../../../../services/constants/globals";

export type SectionGroupType = {
  parent_section_id?: number;
  sub_section_id?: number;
};

type FormInfo = {
  itemType?: CourseItemType;
  settingType?: FormSettingType;
  title?: string;
  submitUrl?: string;
  deleteUrl?: string;
};

type Label = {
  name: string;
  status: string;
  description: string;
  deleteBtn: string;
  confirmDeleteContent: string;
};

const Form = () => {
  const { pathname, getParamValue, navigate } = useObjectRoutes();
  const courseId = getParamValue("course_id");
  const lectureId = getParamValue("lecture_id");
  const chapterId = getParamValue("chapter_id");

  const withCourseId = getParamValue("with_course_id");
  const withLectureId = getParamValue("with_lecture_id");

  const initLabel = {
    name: "コースの名称",
    status: "ステータス",
    description: "コースの概要",
    deleteBtn: "このコースを削除",
    confirmDeleteContent: DELETE_CONFIRM_CONTENT.COURSE,
  };
  const [label, setLabel] = useState<Label>(initLabel);

  const [formInfo, setFormInfo] = useState<FormInfo>({
    settingType: FORM_SETTING_TYPE.CREATE,
    title: "新規コース登録",
  });
  const [formData, setFormData] = useState<Object>({});

  const [sectionGroups, setSectionGroups] = useState<SectionGroupType[]>([
    { parent_section_id: 0, sub_section_id: 0 },
  ]);
  const [errors, setErrors] = useState<ErrorData>({});

  const [courses, setCourses] = useState<Course[]>([]);
  const [lectures, setLectures] = useState<Lecture[]>([]);
  const [filteredLectures, setFilteredLectures] = useState<Lecture[]>([]);
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    const sectionFetch = async () =>
      await request.get(API.ADMIN_SECTION.GET_SECTION_LIST, setSections);
    const courseFetch = async () =>
      await request.get(API.ADMIN_COURSE.LIST, setCourses);
    const lectureFetch = async () =>
      await request.get(API.ADMIN_LECTURE.LIST, setLectures);

    const syncFormData: <T extends Object>(res: T) => void = (res) => {
      delete res.id;
      setFormData({ ...formData, ...res });
    };

    const getDetailData = async (
      endpoint: string,
      itemType: CourseItemType,
      id: number
    ) => await request.get(`${endpoint}?${itemType}_id=${id}`, syncFormData);

    const courseWithLectureFetch = async () =>
      await Promise.all([courseFetch(), lectureFetch()]);

    let formInfoInstruct = formInfo;

    if (!!courseId || !!lectureId || !!chapterId)
      formInfoInstruct = {
        ...formInfoInstruct,
        settingType: FORM_SETTING_TYPE.EDIT,
      };

    switch (pathname) {
      case PATH_ADMIN_COURSE.COURSE_REGISTRATION:
        formInfoInstruct = {
          ...formInfoInstruct,
          itemType: COURSE_TABLE_ITEM_TYPE.COURSE,
          title: !courseId ? "新規コース登録" : "コース詳細",
          submitUrl:
            formInfoInstruct.settingType === FORM_SETTING_TYPE.CREATE
              ? API.ADMIN_COURSE.CREATE
              : API.ADMIN_COURSE.UPDATE,
          deleteUrl: API.ADMIN_COURSE.DELETE,
        };
        setLabel(initLabel);
        sectionFetch();

        if (formInfoInstruct.settingType !== FORM_SETTING_TYPE.EDIT) break;
        getDetailData(
          API.ADMIN_COURSE.DETAIL,
          formInfoInstruct.itemType as CourseItemType,
          Number(courseId)
        );
        break;
      case PATH_ADMIN_COURSE.LECTURE_REGISTRATION:
        formInfoInstruct = {
          ...formInfoInstruct,
          itemType: COURSE_TABLE_ITEM_TYPE.LECTURE,
          title: !lectureId ? "講義の新規登録" : "講義の新規登録",
          submitUrl:
            formInfoInstruct.settingType === FORM_SETTING_TYPE.CREATE
              ? API.ADMIN_LECTURE.CREATE
              : API.ADMIN_LECTURE.UPDATE,
          deleteUrl: API.ADMIN_LECTURE.DELETE,
        };
        setLabel({
          name: "講義の名称",
          status: "ステータス",
          description: "講義の概要",
          deleteBtn: "この講義を削除",
          confirmDeleteContent: DELETE_CONFIRM_CONTENT.LECTURE,
        });
        courseFetch();

        if (withCourseId) {
          setFormData({ ...formData, course_id: withCourseId });
          break;
        }

        if (formInfoInstruct.settingType !== FORM_SETTING_TYPE.EDIT) break;
        getDetailData(
          API.ADMIN_LECTURE.DETAIL,
          formInfoInstruct.itemType as CourseItemType,
          Number(lectureId)
        );

        break;
      case PATH_ADMIN_COURSE.CHAPTER_REGISTRATION:
        formInfoInstruct = {
          ...formInfoInstruct,
          itemType: COURSE_TABLE_ITEM_TYPE.CHAPTER,
          title: !chapterId ? "章の新規登録" : "章の新規登録",
          submitUrl:
            formInfoInstruct.settingType === FORM_SETTING_TYPE.CREATE
              ? API.ADMIN_CHAPTER.CREATE
              : API.ADMIN_CHAPTER.UPDATE,
          deleteUrl: API.ADMIN_CHAPTER.DELETE,
        };
        setLabel({
          name: "章の名称",
          status: "ステータス",
          description: "章の概要",
          deleteBtn: "この章を削除",
          confirmDeleteContent: DELETE_CONFIRM_CONTENT.CHAPTER,
        });
        courseWithLectureFetch();

        if (withLectureId) {
          setFormData({ ...formData, lecture_id: withLectureId });
          break;
        }

        if (formInfoInstruct.settingType !== FORM_SETTING_TYPE.EDIT) break;
        getDetailData(
          API.ADMIN_CHAPTER.DETAIL,
          formInfoInstruct.itemType as CourseItemType,
          Number(chapterId)
        );
        break;

      default:
        break;
    }

    setFormInfo(formInfoInstruct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    let newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };

    if (e.target.name === "course_id")
      newFormData = {
        ...newFormData,
        lecture_id: 0,
      };

    setFormData(newFormData);
  };

  useEffect(() => {
    if (!courseId || formInfo.itemType !== COURSE_TABLE_ITEM_TYPE.COURSE)
      return;
    let newSectionGroups: SectionGroupType[] = [];
    const sections = formData.sections || [];

    for (const section of sections) {
      newSectionGroups.push({
        parent_section_id:
          section.parent_section_id !== null
            ? section.parent_section_id
            : section.id,
        sub_section_id: section.parent_section_id !== null ? section.id : 0,
      });
    }

    setSectionGroups(newSectionGroups);
  }, [formData.sections]);

  useEffect(() => {
    if (!formData.course_id) return;

    setFilteredLectures(
      lectures.filter(
        (lecture) => lecture.course_id === Number(formData.course_id)
      )
    );
  }, [formData.course_id, lectures]);

  useEffect(() => {
    if (!formData.lecture_id || !lectures || !courses) return;

    const currentLecture = lectures.find(
      (lecture) => lecture.id === Number(formData.lecture_id)
    );
    if (!currentLecture) return;

    const currentCourseByLecture = courses.find(
      (course) => course.id === currentLecture.course_id
    );
    if (!currentCourseByLecture) return;

    setFormData({ ...formData, course_id: currentCourseByLecture.id });
  }, [formData.lecture_id, lectures, courses]);

  const handleRemoveSectionGroup = () =>
    setSectionGroups(sectionGroups.slice(0, -1));
  const handleAddSectionGroup = () =>
    setSectionGroups([
      ...sectionGroups,
      { parent_section_id: 0, sub_section_id: 0 },
    ]);

  const handleSubmit = async () => {
    const sectionIds: number[] = [];
    for (const group of sectionGroups) {
      const currentSectionId = group.sub_section_id || group.parent_section_id;
      sectionIds.push(currentSectionId || 0);
    }

    const sanitizedSectionIds = sectionIds.filter((id) => id !== 0);
    const submitData = {
      ...formData,
      ...(sanitizedSectionIds && { section_ids: sanitizedSectionIds }),
      ...(lectureId && { lecture_id: lectureId }),
      ...(courseId && { course_id: courseId }),
      ...(chapterId && { chapter_id: chapterId }),
    };

    let submitAction = request.patch;
    if (formInfo.settingType !== FORM_SETTING_TYPE.EDIT)
      submitAction = request.post;

    await submitAction(
      formInfo.submitUrl || API.ADMIN_COURSE.CREATE,
      submitData,
      (res) => navigate(PATH_ADMIN_COURSE.DEFAULT),
      setErrors
    );
  };

  const handleDelete = async () => {
    if (!courseId && !lectureId && !chapterId) return;

    const deleteData = {
      ...(lectureId && { lecture_id: lectureId }),
      ...(courseId && { course_id: courseId }),
      ...(chapterId && { chapter_id: chapterId }),
    };

    const deleteTitle = `${courseId ? `${DELETE_CONFIRM_TITLE.COURSE}` : ""}${
      lectureId ? `${DELETE_CONFIRM_TITLE.LECTURE}` : ""
    }${chapterId ? `${DELETE_CONFIRM_TITLE.CHAPTER}` : ""}`;
    swallConfirm(
      async () => {
        await request.delete(
          formInfo.deleteUrl || API.ADMIN_COURSE.DELETE,
          deleteData,
          () => navigate(PATH_ADMIN_COURSE.DEFAULT),
          () => {},
          { withSuccess: true }
        );
      },
      deleteTitle,
      label.confirmDeleteContent
    );
  };

  return (
    <section className="mt-[20px]">
      {Object.keys(errors).length > 0 && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}

      {(formInfo.itemType === COURSE_TABLE_ITEM_TYPE.LECTURE ||
        formInfo.itemType === COURSE_TABLE_ITEM_TYPE.CHAPTER) && (
        <div className="mb-[20px] text-[11px] leading-[100%] text-secondary-dark">
          <div className="mb-[9px]">学習コースを選択</div>
          <select
            name="course_id"
            id=""
            className={`w-full max-w-[320px] h-[30px] pl-[13px] pr-[20px] text-[12px] truncate ${
              !!(lectureId || chapterId) ? "bg-none" : ""
            }`}
            value={formData.course_id || 0}
            onChange={handleOnChange}
            disabled={!!(lectureId || chapterId)}
          >
            <option value="0" disabled>
              選択してください
            </option>
            {courses &&
              courses.map((course) => (
                <option value={course.id} key={course.id}>
                  {course.name}
                </option>
              ))}
          </select>
        </div>
      )}

      {formInfo.itemType === COURSE_TABLE_ITEM_TYPE.CHAPTER && (
        <div className="mb-[20px] text-[11px] leading-[100%] text-secondary-dark">
          <div className="mb-[9px]">講義を選択</div>
          <select
            name="lecture_id"
            id=""
            className={`w-full max-w-[320px] h-[30px] pl-[13px] pr-[20px] text-[12px] truncate ${
              !!chapterId ? "bg-none" : ""
            }`}
            value={formData.lecture_id || 0}
            onChange={handleOnChange}
            disabled={!!chapterId}
          >
            <option value="0" disabled>
              選択してください
            </option>
            {filteredLectures.map((lecture) => (
              <option value={lecture.id} key={lecture.id}>
                {lecture.name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="w-full h-[1px] bg-secondary-light mb-[15px] mt-[40px]"></div>
      <div className="text-[14px] font-[500] leading-[100%] mb-[20px]">
        {formInfo.title}
      </div>

      <FormItem label={label.name} withRequired>
        <input
          type="text"
          name="name"
          id=""
          required
          value={formData.name}
          onChange={handleOnChange}
        />
      </FormItem>

      <FormItem label={label.status} withRequired>
        <StatusBox
          handleOnChange={handleOnChange}
          value={formData.status}
          data={PUBLIC_STATUS_DATA}
        />
      </FormItem>

      {formInfo.itemType &&
        formInfo.itemType === COURSE_TABLE_ITEM_TYPE.COURSE &&
        pathname === PATH_ADMIN_COURSE.COURSE_REGISTRATION && (
          <FormItem label="受講対象セクション" withRequired>
            {sectionGroups &&
              sectionGroups.map((group, index) => (
                <FormSectionGroup
                  key={index}
                  groupIndex={index}
                  sections={sections}
                  subSections={
                    sections.find(
                      (section) => section.id === group.parent_section_id
                    )?.sub_section || []
                  }
                  deletable={index !== 0 && index === sectionGroups.length - 1}
                  data={group}
                  sectionGroups={sectionGroups}
                  setSectionGroups={setSectionGroups}
                  onRemoveSectionGroup={handleRemoveSectionGroup}
                />
              ))}

            <div
              className="text-secondary-dark text-[12px] leading-[100%] flex items-center gap-[10px] mt-[20px] mb-[15px] ml-[3px] cursor-pointer"
              onClick={handleAddSectionGroup}
            >
              <GrAdd className="text-secondary" size={15} />
              受講対象となるセクションを追加する
            </div>
          </FormItem>
        )}

      <FormItem label={label.description}>
        <textarea
          name="description"
          id=""
          className="min-h-[90px]"
          onChange={handleOnChange}
          value={formData.description}
        />
      </FormItem>

      <div className="flex justify-center gap-[20px] mt-[66px] mb-[8px]">
        {formInfo.settingType === FORM_SETTING_TYPE.EDIT && (
          <div
            className="border border-secondary text-secondary rounded-[10px] text-[16px] font-[500] leading-[100%] flex gap-[15px] min-h-[36px] w-full max-w-[254px] justify-center items-center cursor-pointer"
            onClick={handleDelete}
          >
            <div className="">
              <FiTrash2 size={21} />
            </div>
            <div className="">{label.deleteBtn}</div>
          </div>
        )}

        <div className="flex gap-[20px]">
          <CancelButton
            navigateTo={PATH_ADMIN_COURSE.DEFAULT}
            className="!rounded-[10px]"
          />
          <SubmitButton onSubmit={handleSubmit} className="!rounded-[10px]" />
        </div>
      </div>
    </section>
  );
};

export default Form;
