import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  STUDENT_MENU_BORDER_INDEX,
  STUDENT_MENU_DATA,
} from "../../services/constants/student/menu";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { PROFILE_SIDEBAR_DATA_MENU } from "../../services/constants/student/sidebar";
import { ElementProps } from "../../types/globals";
import { useStorage } from "../../hooks/useStorage";

type MenuProps = {
  isMobile?: boolean;
} & ElementProps<HTMLDivElement>;

const Menu = ({ isMobile, ...props }: MenuProps) => {
  const [isClickDown, setIsClickDown] = useState<boolean>(false);
  const BtnChevronDown = ({ index }: { index: number }) => {
    if (index === 0) {
      return isClickDown ? (
        <BsChevronDown size={20} />
      ) : (
        <BsChevronUp size={20} />
      );
    }

    return <></>;
  };
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  return (
    <div
      {...props}
      style={{
        backgroundColor: studentTheme.main_color_first,
      }}
      className={`w-[220px] max-h-[500px] bg-primary text-white px-[10px] sm:py-[33px] max-sm:py-[15px] rounded-[5px] ${
        props.className
      } ${isClickDown ? "overflow-y-scroll" : ""}`}
    >
      {STUDENT_MENU_DATA.map((item, index) => (
        <div key={index}>
          <div
            className={`mb-[15px] cursor-pointer ${
              STUDENT_MENU_BORDER_INDEX.includes(index) &&
              "pb-[15px] border-b border-white"
            } ${index === 0 ? "flex justify-between items-center" : ""} ${
              isClickDown && index === 0 ? "border-none mb-0" : ""
            }`}
            onClick={() => {
              if (index === 0) setIsClickDown(!isClickDown);
            }}
          >
            {/* {STUDENT_MENU[menuKey as keyof typeof STUDENT_MENU]} */}
            <Link to={item.href}>{item.label}</Link>
            {isMobile && <BtnChevronDown index={index} />}
          </div>
          <div
            className={`bg-white ${
              isMobile && index === 0 && isClickDown ? "" : "hidden"
            } mb-[10px] px-[20px] py-[10px] transition-[height] duration-300`}
          >
            {PROFILE_SIDEBAR_DATA_MENU.map((sidebarItem, index) => {
              return (
                <div className="text-[14px] py-[8px]" key={index}>
                  <Link to={sidebarItem.href}>{sidebarItem.label}</Link>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Menu;
