import StudentPolicyBase from ".";
import Policy from "../../../components/student/Policy/Policy";

const CommercialPage = () => {
  return (
    <StudentPolicyBase title="特定商取引法に基づく表記">
      <Policy />
    </StudentPolicyBase>
  );
};

export default CommercialPage;
