import React, { useState } from "react";
import CommunityListItem from "./ListItem";
import dummyData from "../../../dummy.json";
import { Community } from "../../../types/student/community";

const CommunityList = () => {
  const [communityList, setCommunityList] = useState<Community[]>(
    dummyData.communityList
  );

  return (
    <div className="w-full h-[351px] border border-danger-light rounded-[5px] py-[8px] px-[7px] min-[320px]:bg-white max-sm:h-auto">
      <div className="bg-secondary-morelighter py-[12px] px-[13px] relative">
        <div className="bg-success w-full max-w-[90px] h-[20px] rounded-[5px] text-white text-[11px] font-[500] leading-[100%] mb-[13px] flex items-center justify-center">
          コミュニティ
        </div>
        <div className="down_polygon w-[10px] h-[8px] bg-success absolute top-[32px] left-[50px]"></div>
        <div className="">
          {communityList.map((community, index) => (
            <CommunityListItem
              key={community.id}
              data={community}
              isLast={index === communityList.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityList;
