import React, { useContext, useEffect, useState } from "react";
import { StudentThemeType } from "../types/admin/student_theme";
import { ChildrenWithProps, SetStateAction } from "../types/globals";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { useStorage } from "../hooks/useStorage";

type StudentThemeContextType = {
  studentTheme: StudentThemeType;
  setStudentTheme: SetStateAction<StudentThemeType>;
  fetching: boolean;
  setFetching: SetStateAction<boolean>;
  isSmartPhoneScreen: boolean;
  setIsSmartPhoneScreen: SetStateAction<boolean>;
};

const StudentThemeContext = React.createContext<StudentThemeContextType>({
  studentTheme: {},
  setStudentTheme: () => {},
  fetching: true,
  setFetching: () => {},
  isSmartPhoneScreen: false,
  setIsSmartPhoneScreen: () => {},
});

const StudentThemeContextProvider = ({ children }: ChildrenWithProps) => {
  const [studentTheme, setStudentTheme] = useState<StudentThemeType>({});
  const [fetching, setFetching] = useState<boolean>(true);
  const { setLocalStorage, getLocalStorage } = useStorage();
  const studentThemeStorage = getLocalStorage("theme");
  const user = getLocalStorage("user");
  const subdomain = window.location.hostname.split(".")[0];
  const [isSmartPhoneScreen, setIsSmartPhoneScreen] = useState<boolean>(false);

  const checkScreenWidth = () => {
    if (window.innerWidth > 600) {
      setIsSmartPhoneScreen(false);
    } else {
      setIsSmartPhoneScreen(true);
    }
  };

  useEffect(() => {
  window.addEventListener("resize", checkScreenWidth);
  document.addEventListener("DOMContentLoaded", checkScreenWidth);
  window.addEventListener("load", checkScreenWidth);
  checkScreenWidth();
  }, [window.innerWidth]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    let url: string;
    if (studentThemeStorage && studentThemeStorage != null) {
      setStudentTheme(studentThemeStorage);
      setFetching(false);
      return;
    }

    if (user.is_student) {
      url = API.STUDENT_THEME_SETTING.GET_DETAIL;
    }

    if (!user.is_student) {
      url = API.ADMIN_STUDENT_THEME_SETTING.GET_DETAIL;
    }

    const fetchStudentThemeAdmin = async () => {
      const result = await makeRequest({
        method: "get",
        url: url,
      });

      setLocalStorage("theme", result.data);
      setStudentTheme(result.data);
      setFetching(false);
    };

    fetchStudentThemeAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StudentThemeContext.Provider
      value={{
        fetching,
        studentTheme,
        setFetching,
        setStudentTheme,
        isSmartPhoneScreen,
        setIsSmartPhoneScreen,
      }}
    >
      {children}
    </StudentThemeContext.Provider>
  );
};

export default StudentThemeContextProvider;

export const useStudentThemeContext = () => useContext(StudentThemeContext);
