import { useNavigate } from "react-router-dom";
import { PATH_COURSE } from "../../../services/constants/route/router";
import Seperator from "../../commons/icons/Seperator";
import React, { useEffect, useState } from "react";
import CartButton from "../../commons/buttons/CartButton";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const Title = ({
  content,
  courseId,
  lectureId,
  withSeperator,
}: {
  content?: string;
  courseId?: string | number;
  lectureId?: string | number;
  withSeperator?: boolean;
}) => {
  const navigate = useNavigate();
  const { studentTheme, isSmartPhoneScreen } = useStudentThemeContext();
  const [style, setStyle] = useState<object>({});

  useEffect(() => {
    setStyle({
      backgroundColor: studentTheme.background_color,
      color: studentTheme.text_color,
    });
  }, [studentTheme]);

  const handleContentSeperate = () => {
    if (!content) return "";
    if (withSeperator) {
      const words = content.split("|");
      const separatedContent = words.map((word, index) => (
        <React.Fragment key={index}>
          {word}
          {index < words.length - 1 && <Seperator />}
        </React.Fragment>
      ));
      return (
        <div className="flex gap-[15px] max-w-[600px] truncate min-h-[30px] items-center">
          {separatedContent}
        </div>
      );
    }
    return content;
  };

  return (
    <div className="w-full">
      {lectureId && courseId ? (
        <section className="border-b pb-[14px] flex items-center justify-between mb-[10px] max-sm:mx-[10px] max-sm:flex-col max-sm:border-none max-sm:mb-0 max-sm:pb-0">
          <div className="font-[600] text-[14px] leading-[100%] secondary-dark line_clamp w-[75%] max-sm:flex max-sm:w-full max-sm:justify-start max-sm:border-b">
            {handleContentSeperate()}
          </div>
          <div className="flex items-center h-[34px] max-sm:justify-end max-sm:w-full">
            <button
              style={{
                backgroundColor: studentTheme.main_color_first,
              }}
              onClick={() =>
                navigate(
                  `${PATH_COURSE.LECTURE_DETAIL}?lecture_id=${lectureId}&course_id=${courseId}`
                )
              }
              className="flex items-center justify-center w-[101px] h-[20px] text-white bg-primary font-[700] text-[12px] rounded-[20px] mr-[27px] max-sm:mr-[10px]"
            >
              講座トップ
            </button>
            {courseId && !lectureId && (
              <button
                style={{
                  backgroundColor: studentTheme.main_color_first,
                }}
                onClick={() =>
                  navigate(`${PATH_COURSE.DEFAULT}?course_id=${courseId}`)
                }
                className="flex items-center justify-center min-w-[138px] h-[20px] text-white bg-primary font-[700] text-[12px] rounded-[20px] py-[4px] px-[20px] mr-[27px] max-sm:mr-[10px]"
              >
                受講コーストップ
              </button>
            )}
            {/* CartButton hidden for later phases */}
            <CartButton />
          </div>
        </section>
      ) : (
        <section className="border-b flex items-center mb-[10px] max-sm:border-none">
          <div
            className="flex items-center h-[37px] justify-between w-full max-sm:border-b max-sm:px-[10px] pb-[14px]"
            style={isSmartPhoneScreen ? style : {}}
          >
            {content?.length !== undefined && content?.length > 2 ? (
              <div className="flex items-center justify-between w-full max-sm:border-b-[2px] max-sm:border-secondary-lighter">
                <div className="font-[600] text-[14px] leading-[100%] secondary-dark line_clamp w-[75%]  max-sm:pb-[3px] max-sm:w-full">
                  {handleContentSeperate()}
                </div>
                <div className="flex items-center h-[20px] max-sm:justify-end max-sm:w-[35%] max-sm:pb-[2px]">
                  <CartButton />
                </div>
              </div>
            ) : (
              <div className="flex items-center h-[19px] max-sm:justify-end max-sm:w-full max-sm:pb-[2px] max-sm:border-b-[2px] max-sm:border-secondary-lighter">
                <CartButton />
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default Title;
