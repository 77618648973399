import { getAdminPath, getPath } from '../../helpers/router';

const ROUTE = {
  //home
  HOME: '/',
  DOMAIN_LOGIN: '/site-login',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_EMAIL: '/verify-email',
  COMPLETE_STUDENT_REGISTRATION: '/complete-student-registration',
  GG_CALLBACK: '/google.callback',
  REDIRECT: '/redirect-to-subdomain',
  RESEND_VERIFY: 'resend-verify-email',
  SUBMIT_FORGOT_PASSWORD_DONE: '/email-has-been-sent',
  UPDATE_NEW_PWD: '/update-new-pwd',
  PALETTE_LOGIN: '/palette-login',
  MY_PAGE: {
    DEFAULT: '/my-page',
    COURSE_LIST: 'course-list',
    STUDY_HISTORY: 'study-history',
  },
  // STUDENT

  //my-page
  MY_PAGE_PREFIX: 'my-page',
  //courses
  COURSES: {
    DEFAULT: 'courses',
    LECTURES: 'lectures',
    UNITS: 'units',
    DETAIL: 'units/:id',
    EXECUTE_TEST: 'execute-test',
    CONTINUE_TEST: 'continue-test',
    EXPLANATION: 'explanation',
  },
  STUDY_HISTORY: 'study-history',

  //profile
  PROFILE: {
    DEFAULT: '/profile',
    MAIL_CHANGING: 'mail-changing',
    PASSWORD_CHANGING: 'password-changing',
    PURCHASE_HISTORY: 'purchase-history',
  },

  STUDY_TOOL: {
    DEFAULT: 'study-tool',
    SCHEDULE: {
      DEFAULT: 'schedule',
      CALENDAR: {
        DEFAULT: 'calendar',
      },
      EVENT: {
        DEFAULT: 'event',
      },
    },

    REPORT: {
      DEFAULT: 'report',
    },

    REVISION_REVIEW: {
      DEFAULT: 'revision-review',
    },

    MEMO: {
      DEFAULT: 'memo',
      CREATE: 'create',
      EDIT: 'edit',
      DETAIL: 'detail',
    },
  },
  PRODUCT: {
    DEFAULT: 'product',
    DETAIL: 'detail'
  },
  CART: {
    DEFAULT: 'my-cart',
    PAYMENT: 'payment',
    ORDER_SUCCESS: 'order-success'
  },
  POLICY: {
    DEFAULT: 'policy',
    TERM_OF_USE: 'term-of-use',
    PRIVACY_POLICY: "privacy-policy",
    COMMERCIAL_TRANSACTION_LAW: "commercial-transaction-law" 
  },

  // ADMIN
  ADMIN_PREFIX: 'admin',
  //section
  SECTION_MANAGEMENT: {
    DEFAULT: 'section',
    SETTING: 'setting',
    DETAIL: 'detail',
  },
  //student
  STUDENT_MANAGEMENT: {
    DEFAULT: 'student',
    SETTING: 'setting',
    CREATE: 'create',
    CSV: 'csv',
    DETAILS: 'details',
  },
  //course
  COURSE_MANAGEMENT: {
    DEFAULT: 'course',
    COURSE: 'course',
    LECTURE: 'lecture',
    CHAPTER: 'chapter',
    BULK_REGISTRATION: 'bulk-registration',
  },
  //folder
  FOLDER_MANAGEMENT: {
    DEFAULT: 'resource-management',
    FOLDER: 'folder',
    FILE: 'file',
  },
  //material
  MATERIAL_MANAGEMENT: {
    DEFAULT: 'material',
    UNIT: 'unit',
    UPSERT: 'upsert',
  },
  //product
  PRODUCT_MANAGEMENT: {
    DEFAULT: 'product',
    CREATE: 'create',
    DETAIL: 'detail'
  },

  // coupon
  COUPON_MANAGEMENT: {
    DEFAULT: 'coupon',
    CREATE: 'create',
    DETAIL: 'detail'
  },

  // terms of use setting:
  TERMS_OF_USE: {
    DEFAULT: 'setting',
    LOGIN_PAGE_SETTINGS: 'login-page-setting',
    STUDENT_THEME_SETTINGS: {
      DEFAULT: 'student-theme-setting',
      DETAIL: 'detail',
      UPDAET: 'update',
    },
    TEMRS_OF_SERVICE: {
      DEFAULT: 'terms-service',
      DETAIL: 'detail'
    },
  }
};

//FULL_PATH
const PATH_ADMIN_COURSE = {
  DEFAULT: getAdminPath(ROUTE.COURSE_MANAGEMENT.DEFAULT),
  COURSE_REGISTRATION: getAdminPath(
    ROUTE.COURSE_MANAGEMENT.DEFAULT,
    ROUTE.COURSE_MANAGEMENT.COURSE,
  ),
  LECTURE_REGISTRATION: getAdminPath(
    ROUTE.COURSE_MANAGEMENT.DEFAULT,
    ROUTE.COURSE_MANAGEMENT.LECTURE,
  ),
  CHAPTER_REGISTRATION: getAdminPath(
    ROUTE.COURSE_MANAGEMENT.DEFAULT,
    ROUTE.COURSE_MANAGEMENT.CHAPTER,
  ),
  BULK_REGISTRATION: getAdminPath(
    ROUTE.COURSE_MANAGEMENT.DEFAULT,
    ROUTE.COURSE_MANAGEMENT.BULK_REGISTRATION,
  ),
};

const PATH_ADMIN_FOLDER = {
  DEFAULT: getAdminPath(ROUTE.FOLDER_MANAGEMENT.DEFAULT),
  FOLDER_FILE: getAdminPath(
    ROUTE.FOLDER_MANAGEMENT.DEFAULT,
    ROUTE.FOLDER_MANAGEMENT.FOLDER,
    ROUTE.FOLDER_MANAGEMENT.FILE,
  ),
  FOLDER: getAdminPath(ROUTE.FOLDER_MANAGEMENT.DEFAULT, ROUTE.FOLDER_MANAGEMENT.FOLDER),
  FILE: getAdminPath(ROUTE.FOLDER_MANAGEMENT.DEFAULT, ROUTE.FOLDER_MANAGEMENT.FILE),
};

const PATH_ADMIN_LECTURE = {
  DEFAULT: getAdminPath(ROUTE.COURSE_MANAGEMENT.LECTURE),
};

const PATH_ADMIN_CHAPTER = {
  DEFAULT: getAdminPath(ROUTE.COURSE_MANAGEMENT.CHAPTER),
};

const PATH_ADMIN_UNIT = {
  DEFAULT: getAdminPath(ROUTE.MATERIAL_MANAGEMENT.UNIT),
};

const PATH_ADMIN_SECTION = {
  DEFAULT: getAdminPath(ROUTE.SECTION_MANAGEMENT.DEFAULT),
  MANAGEMENT: getAdminPath(ROUTE.SECTION_MANAGEMENT.DEFAULT, ROUTE.SECTION_MANAGEMENT.DETAIL),
  SETTING: getAdminPath(ROUTE.SECTION_MANAGEMENT.DEFAULT, ROUTE.SECTION_MANAGEMENT.SETTING),
};

const PATH_ADMIN_STUDENT = {
  DEFAULT: getAdminPath(ROUTE.STUDENT_MANAGEMENT.DEFAULT),
  CREATE: getAdminPath(ROUTE.STUDENT_MANAGEMENT.DEFAULT, ROUTE.STUDENT_MANAGEMENT.CREATE),
  // Tab hidden for future phases
  // CSV: getAdminPath(
  //   ROUTE.STUDENT_MANAGEMENT.DEFAULT,
  //   ROUTE.STUDENT_MANAGEMENT.CSV
  // ),
  // SETTING: getAdminPath(
  //   ROUTE.STUDENT_MANAGEMENT.DEFAULT,
  //   ROUTE.STUDENT_MANAGEMENT.SETTING
  // ),
};

const PATH_ADMIN_PRODUCT = {
  DEFAULT: getAdminPath(ROUTE.PRODUCT_MANAGEMENT.DEFAULT),
  CREATE: getAdminPath(ROUTE.PRODUCT_MANAGEMENT.DEFAULT, ROUTE.PRODUCT_MANAGEMENT.CREATE),
  DETAIL: getAdminPath(ROUTE.PRODUCT_MANAGEMENT.DEFAULT, ROUTE.PRODUCT_MANAGEMENT.DETAIL),
};

const PATH_ADMIN_COUPON = {
  DEFAULT: getAdminPath(ROUTE.COUPON_MANAGEMENT.DEFAULT),
  CREATE: getAdminPath(ROUTE.COUPON_MANAGEMENT.DEFAULT, ROUTE.COUPON_MANAGEMENT.CREATE),
  DETAIL: getAdminPath(ROUTE.COUPON_MANAGEMENT.DEFAULT, ROUTE.COUPON_MANAGEMENT.DETAIL),
};

const PATH_MYPAGE_COURSES = {
  DEFAULT: getPath(ROUTE.MY_PAGE_PREFIX, ROUTE.COURSES.DEFAULT),
  LECTURES: getAdminPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.LECTURES),
};

const PATH_ADMIN_MATERIAL = {
  DEFAULT: getAdminPath(ROUTE.MATERIAL_MANAGEMENT.DEFAULT),
  UNIT: getAdminPath(ROUTE.MATERIAL_MANAGEMENT.DEFAULT, ROUTE.MATERIAL_MANAGEMENT.UNIT),
  UNIT_UPSERT: getAdminPath(
    ROUTE.MATERIAL_MANAGEMENT.DEFAULT,
    ROUTE.MATERIAL_MANAGEMENT.UNIT,
    ROUTE.MATERIAL_MANAGEMENT.UPSERT,
  ),
};

const PATH_ADMIN_TERMS_OF_USE = {
  DEFAULT: getAdminPath(ROUTE.TERMS_OF_USE.DEFAULT),
  LOGIN_PAGE_SETTING: getAdminPath(ROUTE.TERMS_OF_USE.DEFAULT, ROUTE.TERMS_OF_USE.LOGIN_PAGE_SETTINGS),
  STUDENT_THEME_SETTING: getAdminPath(ROUTE.TERMS_OF_USE.DEFAULT, ROUTE.TERMS_OF_USE.STUDENT_THEME_SETTINGS.DEFAULT),
  TERMS_OF_SERVICE: getAdminPath(ROUTE.TERMS_OF_USE.DEFAULT, ROUTE.TERMS_OF_USE.TEMRS_OF_SERVICE.DEFAULT),
  TERMS_OF_SERVICE_DETAIL: getAdminPath(ROUTE.TERMS_OF_USE.DEFAULT, ROUTE.TERMS_OF_USE.TEMRS_OF_SERVICE.DEFAULT, ROUTE.TERMS_OF_USE.TEMRS_OF_SERVICE.DETAIL),
}

const PATH_COURSE = {
  DEFAULT: getPath(ROUTE.COURSES.DEFAULT),
  EXECUTE_TEST: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.EXECUTE_TEST),
  CONTINUE_TEST: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.CONTINUE_TEST),
  LECTURE_DETAIL: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.LECTURES),
  UNIT_DETAIL: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.LECTURES, ROUTE.COURSES.UNITS),
  EXPLANATION: getPath(ROUTE.COURSES.DEFAULT, ROUTE.COURSES.EXPLANATION),
};

const PATH_STUDY_TOOL = {
  DEFAULT: getPath(ROUTE.MY_PAGE_PREFIX, ROUTE.STUDY_TOOL.DEFAULT),

  SCHEDULE: {
    DEFAULT: getPath(
      ROUTE.MY_PAGE_PREFIX,
      ROUTE.STUDY_TOOL.DEFAULT,
      ROUTE.STUDY_TOOL.SCHEDULE.DEFAULT,
    ),
    CALENDAR: {
      DEFAULT: getPath(
        ROUTE.MY_PAGE_PREFIX,
        ROUTE.STUDY_TOOL.DEFAULT,
        ROUTE.STUDY_TOOL.SCHEDULE.DEFAULT,
        ROUTE.STUDY_TOOL.SCHEDULE.CALENDAR.DEFAULT,
      ),
    },
    EVENT: {
      DEFAULT: getPath(
        ROUTE.MY_PAGE_PREFIX,
        ROUTE.STUDY_TOOL.DEFAULT,
        ROUTE.STUDY_TOOL.SCHEDULE.DEFAULT,
        ROUTE.STUDY_TOOL.SCHEDULE.EVENT.DEFAULT,
      ),
    },
  },
  MEMO: getPath(ROUTE.MY_PAGE_PREFIX, ROUTE.STUDY_TOOL.DEFAULT, ROUTE.STUDY_TOOL.MEMO.DEFAULT),
  CREATE_MEMO: getPath(
    ROUTE.MY_PAGE_PREFIX,
    ROUTE.STUDY_TOOL.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.CREATE,
  ),
  DETAIL_MEMO: getPath(
    ROUTE.MY_PAGE_PREFIX,
    ROUTE.STUDY_TOOL.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.DETAIL,
  ),
  EDIT_MEMO: getPath(
    ROUTE.MY_PAGE_PREFIX,
    ROUTE.STUDY_TOOL.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.DEFAULT,
    ROUTE.STUDY_TOOL.MEMO.EDIT,
  ),

  // Report
  REPORT: getPath(ROUTE.MY_PAGE_PREFIX, ROUTE.STUDY_TOOL.DEFAULT, ROUTE.STUDY_TOOL.REPORT.DEFAULT),

  // Revision review
  REVISION_REVIEW: getPath(
    ROUTE.MY_PAGE_PREFIX,
    ROUTE.STUDY_TOOL.DEFAULT,
    ROUTE.STUDY_TOOL.REVISION_REVIEW.DEFAULT,
  ),
};

const PATH_PRODUCT = {
  DEFAULT: getPath(ROUTE.PRODUCT.DEFAULT),
  DETAIL: getPath(ROUTE.PRODUCT.DEFAULT, ROUTE.PRODUCT.DETAIL)
};

const PATH_STUDENT_CART = {
  DEFAULT: getPath(ROUTE.CART.DEFAULT),
  PAYMENT: getPath(ROUTE.CART.PAYMENT),
  ORDER_SUCCESS: getPath(ROUTE.CART.ORDER_SUCCESS)
};

const PATH_POLICY = {
  DEFAULT: getPath(ROUTE.POLICY.DEFAULT),
  TERM_OF_USE: getPath(ROUTE.POLICY.DEFAULT, ROUTE.POLICY.TERM_OF_USE),
  PRIVACY_POLICY: getPath(ROUTE.POLICY.DEFAULT, ROUTE.POLICY.PRIVACY_POLICY),
  COMMERCIAL_TRANSACTION_LAW: getPath(ROUTE.POLICY.DEFAULT, ROUTE.POLICY.COMMERCIAL_TRANSACTION_LAW),
}

export {
  ROUTE,
  PATH_ADMIN_COURSE,
  PATH_ADMIN_SECTION,
  PATH_ADMIN_STUDENT,
  PATH_MYPAGE_COURSES,
  PATH_ADMIN_MATERIAL,
  PATH_ADMIN_LECTURE,
  PATH_ADMIN_CHAPTER,
  PATH_ADMIN_UNIT,
  PATH_ADMIN_FOLDER,
  PATH_ADMIN_PRODUCT,
  PATH_ADMIN_COUPON,
  PATH_ADMIN_TERMS_OF_USE,
  PATH_COURSE,
  PATH_STUDY_TOOL,
  PATH_PRODUCT,
  PATH_STUDENT_CART,
  PATH_POLICY
};
