import React, { useContext, useState } from "react";
import { Product } from "../types/admin/product";
import { ChildrenWithProps } from "../types/globals";

type ProductListContextType = {
  products: Product[];
  setProducts: (courses: Product[]) => void;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
};

const ProductListContext = React.createContext<ProductListContextType>({
  products: [],
  setProducts: () => {},
  totalPages: 1,
  setTotalPages: () => {},
});

const ProductListProvider = ({ children }: ChildrenWithProps) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  
  return (
    <ProductListContext.Provider
      value={{ products, setProducts, totalPages, setTotalPages }}
    >
      {children}
    </ProductListContext.Provider>
  );
};

export default ProductListProvider;

export const useProductListContext = () => useContext(ProductListContext);
