import React, { ChangeEvent, FormEvent } from "react";
import { convertTimestampToDateTime } from "../../../../services/helpers/formatTime";
import ProgressBar from "../../commons/ProgressBar";
import {
  PRODUCT_RESOURCE_TYPE_DATA,
  RESOURCE_TYPE,
} from "../../../../services/constants/admin/pages/product";
import { MainResource } from "../../../../types/admin/unit";
import { SetStateAction } from "../../../../types/globals";
import { Product } from "../../../../types/admin/product";

type ProductFormUploadFile = {
  file: File | null;
  resource: MainResource | null;
  uploadDate: string;
  processPercent: number;
  typeResource: number;
  productFormData: Product;
  setIsOpenModal: SetStateAction<boolean>;
  setFile: SetStateAction<File | null>;
  setResource: SetStateAction<MainResource | null>;
  setTypeResource: SetStateAction<number>;
  setFileFolderId: SetStateAction<string>;
  handleChangeForm: (
    e:
      | FormEvent<HTMLInputElement>
      | ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
};

const ProductFormUploadFile = ({
  file,
  resource,
  uploadDate,
  processPercent,
  typeResource,
  productFormData,
  setIsOpenModal,
  setFile,
  setResource,
  setTypeResource,
  handleChangeForm,
  setFileFolderId,
}: ProductFormUploadFile) => {
  return (
    <div className="text-secondary-dark ml-[13px] w-full flex flex-col justify-center mx-[5px] mr-[30px] py-[10px]">
      {file || resource ? (
        <div>
          <div className="p-[20px] bg-secondary-morelighter w-full max-w-[710px] min-h-[180px]">
            <div className="font-[500] text-[14px] mb-[15px]">
              登録済みの教材ファイル
            </div>
            <div className="flex w-full with-roboto font-[500] text-[14px] text-secondary mb-[30px]">
              <div className="p-[10px] min-h-[36px] bg-secondary-extralight w-full max-w-[150px]">
                登録済みファイル
              </div>
              <div className="p-[10px] min-h-[36px] bg-white border-r-[1px] border-secondary-light w-full max-w-[200px]">
                {file?.name || resource?.origin_filename}
              </div>
              <div className="p-[10px] min-h-[36px] font-[400] text-[12px] bg-white border-r-[1px] border-secondary-light w-full max-w-[220px]">
                更新：
                {uploadDate ||
                  convertTimestampToDateTime(resource?.updated_at, false, true)}
              </div>
              <div
                className="p-[10px] min-h-[36px] bg-white w-full max-w-[100px] text-primary"
                onClick={() => setIsOpenModal(true)}
              >
                編集／削除
              </div>
            </div>
            {/* Process bar */}
            {processPercent > 0 && <ProgressBar percent={processPercent} />}
            <div className="w-full flex justify-center items-center mb-[15px]">
              <button
                type="button"
                className="min-w-[260px] bg-secondary px-[32.5px] py-[8px] text-white rounded-[10px] font-[700] text-[15px] leading-[100%]"
                onClick={() => {
                  setFile(null);
                  setResource(null);
                }}
              >
                この教材ファイルを削除する
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex text-[10px] font-[400] leading-[100%] items-center w-full">
            {PRODUCT_RESOURCE_TYPE_DATA.map((resourceType, index) => (
              <div className="flex items-center mr-[22px]" key={index}>
                <input
                  type="radio"
                  name="resource"
                  id={resourceType.label}
                  className="cursor-pointer text-primary-light "
                  value={resourceType.value}
                  onChange={(e) => {
                    setTypeResource(Number(e.currentTarget.value));
                  }}
                  checked={resourceType.value === typeResource}
                />
                <label
                  htmlFor={resourceType.label}
                  className="text-[12px] ml-[5px] text-secondary-dark font-[500] cursor-pointer"
                >
                  {resourceType.label}
                </label>
              </div>
            ))}
          </div>
          {typeResource === RESOURCE_TYPE.URL ? (
            <div className="mt-[10px] w-full">
              <input
                type="text"
                name="resource_url"
                id=""
                onChange={handleChangeForm}
                value={productFormData?.resource_url || ""}
              />
            </div>
          ) : (
            <div className="flex items-center mt-[10px]">
              <div className="h-[60px] w-[400px] bg-[#F4F4F4] flex items-center px-2 gap-x-[10px] mb-[15px]">
                <button
                  type="button"
                  className="w-[105px] h-[38px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[2px] border-[#BEBEBE]"
                  onClick={() => {
                    setIsOpenModal(true);
                    setFileFolderId("");
                  }}
                >
                  ファイルを選択
                </button>
                <p className="text-[10px] font-[400]">選択されていません</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductFormUploadFile;
