import StudentLayout from "../../../layouts/student";
import CourseTop from "../../../components/student/Course/CourseTop";

const Courses = () => {
  return (
    <StudentLayout gap>
      <CourseTop />
    </StudentLayout>
  );
};

export default Courses;
