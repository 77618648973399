import { useNavigate } from "react-router-dom";
import { Course } from "../../../types/student/course";
import { RiArrowRightSLine } from "react-icons/ri";
import { PATH_COURSE } from "../../../services/constants/route/router";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const CourseListItem = ({ data }: { data: Course }) => {
  const navigate = useNavigate();
  const { studentTheme } = useStudentThemeContext();

  return (
    <div className="flex h-[34px] border-b border-danger-light mb-[8px] items-center justify-between ml-[16px] pr-[11.5px]">
      <div className="">
        <div
          style={{
            color: studentTheme.main_color_first,
          }}
          className="font-[500] text-[14px] leading-[100%] text-primary truncate max-[320px]:max-w-[120px] max-sm:max-w-[180px] md:max-w-[280px] leading-[150%]"
          title={data?.name || ""}
        >
          {data?.name || ""}
        </div>
      </div>
      <div
        className="text-[10px] font-[500] leading-[100%] text-danger border border-danger flex items-center justify-center w-full max-w-[86px] h-[20px] cursor-pointer hover:bg-[#00000026]"
        onClick={() => navigate(`${PATH_COURSE.DEFAULT}?course_id=${data.id}`)}
      >
        コーストップ{" "}
        <RiArrowRightSLine className="ml-[1px] mr-[-2px]" size={15} />
      </div>
    </div>
  );
};

export default CourseListItem;
