import MaterialBase from '.';
import MaterialCourseList from '../../../components/admin/Material/List/Course';
import TableCommonAction from '../../../components/admin/commons/TableCommonAction';
import { useObjectRoutes } from '../../../hooks/useObjectRoutes';
import { PATH_ADMIN_MATERIAL } from '../../../services/constants/route/router';

const MaterialCourseListPage = () => {
  const { navigate } = useObjectRoutes();

  return (
    <MaterialBase title="教材設定 ｜ 教材一覧">
      <TableCommonAction
        className="mb-[20px]"
        createBtnLabel="新規教材作成・登録"
        onCreate={() => navigate(PATH_ADMIN_MATERIAL.UNIT_UPSERT)}
      />
      <MaterialCourseList />
    </MaterialBase>
  );
};

export default MaterialCourseListPage;
