const STUDENT_THEME_COLOR_DEFAULT = {
    BACKGROUND: '#FAEBD2',
    MAIN_1: '#227C9D',
    MAIN_2: '#FAC46E',
    SUB_1: '#8FC2D5',
    SUB_2: '#FAEBD2',
    TEXT: '#2E2E2E',
    PAGE_TOP: '#749F91',
    LECTURE_TOP: '#14AD9E',
}

const STUDENT_THEME_DEFAULT = {
    background_color: STUDENT_THEME_COLOR_DEFAULT.BACKGROUND,
    main_color_first: STUDENT_THEME_COLOR_DEFAULT.MAIN_1,
    main_color_second: STUDENT_THEME_COLOR_DEFAULT.MAIN_2,
    sub_color_first: STUDENT_THEME_COLOR_DEFAULT.SUB_1,
    sub_color_second: STUDENT_THEME_COLOR_DEFAULT.SUB_2,
    page_top_color: STUDENT_THEME_COLOR_DEFAULT.PAGE_TOP,
    lecture_top_color: STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP,
    text_color: STUDENT_THEME_COLOR_DEFAULT.TEXT,
}

export { STUDENT_THEME_COLOR_DEFAULT, STUDENT_THEME_DEFAULT };