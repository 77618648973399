import { Product } from "../../../../types/admin/product";
import { useProductListContext } from "../../../../context/ProductContext";
import Pagination from "../../student/Pagination";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { PATH_ADMIN_PRODUCT } from "../../../../services/constants/route/router";
import { formatNumberWithCommas } from "../../../../services/helpers/parseData";
import TableHeadItem from "./TableHeadItem";
import { useState } from "react";
import { IoMdInformationCircleOutline } from 'react-icons/io';
import TooltipDisplay from "../../../commons/etcs/ToolTipDisplay";

type TableProps = {
  _products: Product[];
  currentPage: number;
  handlePageChange: (number: number) => void;
  selectAll: boolean;
  toggleSelectAll: () => void;
  isProductSelected: (id: number) => boolean;
  toggleSelect: (id: number) => void;
};

const Table = ({
  _products,
  currentPage,
  handlePageChange,
  selectAll,
  toggleSelectAll,
  isProductSelected,
  toggleSelect,
}: TableProps) => {
  const { totalPages } = useProductListContext();
  const formatDatetime = "YYYY/MM/DD HH:mm";
  const [displayTooltipStatus, setDisplayTooltipStatus] = useState<boolean>(false);

  return (
    <>
      <div className="relative">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <TableHeadItem className="w-[5%]">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
              </TableHeadItem>
              <TableHeadItem className="w-[18%]">商品ID</TableHeadItem>
              <TableHeadItem className="w-[30%]">商品名</TableHeadItem>
              <TableHeadItem className="w-[14%]">販売開始</TableHeadItem>
              <TableHeadItem className="w-[14%]">販売期限</TableHeadItem>
              <TableHeadItem className="w-[12%] pl-[15px]">
                {displayTooltipStatus &&
                  <div className="relative">
                    <TooltipDisplay
                      content={"購入者側には、税込価格で表示されます。"}
                      className="absolute top-[-70px] left-[-25px]"
                    />
                  </div>
                }
                <div className="flex justify-start items-center">
                  販売価格（税抜）
                  <IoMdInformationCircleOutline
                    size={20}
                    className="cursor-pointer text-secondary"
                    onMouseEnter={() =>
                      setDisplayTooltipStatus(true)
                    }
                    onMouseLeave={() =>
                      setDisplayTooltipStatus(false)
                    }
                  />
                </div>
              </TableHeadItem>
              <TableHeadItem className="w-[7%]">編集</TableHeadItem>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(_products) &&
              _products &&
              !!_products.length &&
              _products.map((product, index) => (
                <tr
                  className="h-[50px] border-b border-success-extralight text-[12px] text-secondary-dark font-[400]"
                  key={index}
                >
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={isProductSelected(Number(product.id))}
                      onChange={() => toggleSelect(Number(product.id))}
                    />
                  </td>
                  <td className="text-center">{product.id}</td>
                  <td className="">{product.name}</td>
                  <td className="text-center">
                    {product?.start_date_sell !== null
                      ? dayjs(product?.start_date_sell).format(formatDatetime)
                      : "販売期限なし"}
                  </td>
                  <td className="text-center">
                    {product?.end_date_sell !== null
                      ? dayjs(product?.end_date_sell).format(formatDatetime)
                      : "販売期限なし"}
                  </td>
                  <td className="text-center">
                    ￥
                    {product.is_sale
                      ? formatNumberWithCommas(product?.sale_price || "")
                      : formatNumberWithCommas(product?.price || "")}
                  </td>
                  <td className="text-center">
                    <Link
                      className="w-[45px] text-[12px] text-white font-[500] bg-primary px-[10.5px] py-[4px] rounded-[5px]"
                      to={`${PATH_ADMIN_PRODUCT.DETAIL}/${product.id}`}
                    >
                      編集
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages && totalPages !== 0 && (
        <Pagination
          handlePageChange={handlePageChange}
          totalItems={totalPages}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default Table;
