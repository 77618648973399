import * as React from "react";

const Seperator = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2"
    height="12"
    viewBox="0 0 2 12"
    fill="none"
  >
    <path d="M1 0V12" stroke="#BEBEBE" />
  </svg>
);

export default Seperator;
