import React from 'react';

type StatusButtonDisplayProps = {
  status?: number;
};

const StatusButtonDisplay = ({ status }: StatusButtonDisplayProps) => {
  let childComp;
  if (status) {
    childComp = (
      <div className="w-full h-full px-[6px] py-[5px] text-danger border border-danger rounded-[5px] cursor-default">
        公開
      </div>
    );
  } else {
    childComp = (
      <div className="w-full h-full px-[6px] py-[5px] text-white bg-secondary-light rounded-[5px] cursor-default">
        非公開
      </div>
    );
  }

  return (
    <div className="w-full max-w-[45px] h-[22px] text-[10px] text-center font-[500] leading-[100%]">{childComp}</div>
  );
};

export default StatusButtonDisplay;
