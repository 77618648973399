import React from "react";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { useTabFinder } from "../../../hooks/useTabFinder";
import AdminLayout from "../../../layouts/admin";
import TabCard from "../../../components/admin/commons/TabCard";
import { WithChildren } from "../../../types/globals";
import { TERMS_OF_USE_TAB_DATA } from "../../../services/constants/admin/pages/term_of_use";
import { PATH_ADMIN_TERMS_OF_USE } from "../../../services/constants/route/router";

type TermOfUseProp = {
  title?: string;
};

const TermOfUseBase = ({ title, children }: WithChildren<TermOfUseProp>) => {
  const { stringSearchParam } = useObjectRoutes();
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_TERMS_OF_USE));

  return (
    <AdminLayout>
      <TabCard
        tabData={TERMS_OF_USE_TAB_DATA}
        tabIndex={stringSearchParam ? -1 : ((tabIndex || 1) - 1)}
        title={title}
      >
        {children}
      </TabCard>
    </AdminLayout>
  );
};

export default TermOfUseBase;
