import React, { useEffect, useState } from "react";
import Title from "./Title";
import StatisticalChart from "./StatisticalChart";
import LearningList from "../Learning/List";
import LearningTool from "./LearningTool";
import CourseList from "../Course/List";
import CommunityList from "../Community/List";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { Unit } from "../../../types/student/unit";
import { Course, Statistics } from "../../../types/student/course";
import { isEmptyArr, isEmptyObj } from "../../../services/helpers/etc";
import {
  getCommonDate,
  getCurrentDayOfDate,
  getDate,
  getFirstCalendarDate,
  getLastCalendarDate,
  isValidDate,
} from "../../../services/helpers/date";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { paramizeObject } from "../../../services/helpers/parseData";
import { EventsInMonth } from "../../../types/student/event";
import EventBoard from "../Schedule/EventBoard";
import { PATH_STUDY_TOOL } from "../../../services/constants/route/router";
import NoticeList from "../Notice/List";
import { MdSchedule } from "react-icons/md";
import Logo from "../../Login/Logo";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { LEARNING_TOOLS } from "../../../services/constants/student/learning";
import LearningToolType from "./LearningToolType";
import Menu from "../../../layouts/student/Menu";
import { HiOutlineUserCircle } from "react-icons/hi";
import LayoutWaitingApi from "../common/LayoutWaitingApi";
import { useStorage } from "../../../hooks/useStorage";

const MyPageTop = () => {
  const navigate = useNavigate();
  const [myPageStatistics, setMyPageStatistics] = useState<Statistics>({});
  const [courseList, setCourseList] = useState<Course[]>([]);
  const [unitLearningList, setUnitLearningList] = useState<Unit[]>([]);
  const { getParamValue } = useObjectRoutes();
  const initDate = isValidDate(getParamValue("date"))
    ? new Date(getParamValue("date") as string)
    : new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(initDate);
  const [, setSearchParams] = useSearchParams();
  const [eventsInMonth, setEventsInMonth] = useState<EventsInMonth>({});
  const [isClick, setIsClick] = useState<boolean>(true);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const { getLocalStorage, setLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  const statisticalStorage = getLocalStorage("statistical_page_top");

  const fetchMyPageStatistics = () => {
    request.get(API.MYPAGE.GET_MYPAGE_STATISTICS, (myPageStatisticsData) => {
      setMyPageStatistics(myPageStatisticsData || {});
      setLocalStorage("statistical_page_top", {
        statistical: myPageStatisticsData,
        date_reset_at: getCommonDate(new Date()),
      });
    });
  };

  useEffect(() => {
    const fetchMyPageData = async () => {
      const getMyPageStatistics = () => {
        if (statisticalStorage && statisticalStorage.statistical) {
          const dateResetAt = statisticalStorage.date_reset_at;
          const dateTime1: Date = new Date(dateResetAt);
          const dateTime2: Date = new Date(getCommonDate(new Date()));
          const differenceInHours: number =
            (dateTime2.getTime() - dateTime1.getTime()) / (1000 * 60 * 60);

          if (differenceInHours === 24) {
            fetchMyPageStatistics();
          } else {
            setMyPageStatistics(statisticalStorage.statistical);
          }
        } else {
          fetchMyPageStatistics();
        }
      };
      const getUnitUserHistory = request.get(
        API.MYPAGE.GET_MYPAGE_UNIT_DOING,
        (unitDoingList) => {
          setUnitLearningList(unitDoingList || []);
        }
      );
      const getCourseList = request.get(
        API.COURSE.GET_LIST_COURSE_LATEST,
        (courseListData) => {
          setCourseList(courseListData || []);
        }
      );

      try {
        await Promise.all([
          getMyPageStatistics(),
          getCourseList,
          getUnitUserHistory,
        ]);
        setWaitingApi(false);
      } catch (error) {
        setWaitingApi(false);
      }
    };
    if (
      isEmptyArr(unitLearningList) &&
      isEmptyObj(myPageStatistics) &&
      isEmptyArr(courseList)
    ) {
      fetchMyPageData();
    }

    if (!isValidDate(selectedDate)) return;
    else {
      // get first date and end date in calendar
      const firstOfDate = getFirstCalendarDate(selectedDate);
      const endOfMonth = getLastCalendarDate(firstOfDate);
      // console.log("firstOfDate", firstOfDate);
      // console.log("endOfMonth", endOfMonth);
      // Fetch current event data by chosen date
      const fetchEventData = async () => {
        const eventsInMonthsParamsObj = {
          start_date: firstOfDate,
          end_date: endOfMonth,
        };

        await request.get(
          `${API.SCHEDULE.LIST}?${paramizeObject(eventsInMonthsParamsObj)}`,
          (events) => {
            setEventsInMonth(events);
          }
        );
      };

      if (getCurrentDayOfDate(selectedDate) !== getCurrentDayOfDate(initDate)) {
        navigate(
          `${PATH_STUDY_TOOL.SCHEDULE.EVENT.DEFAULT}?date=${getCommonDate(
            selectedDate
          )}`
        );
      }

      fetchEventData();
    }
  }, [selectedDate]);

  return (
    <>
      <Title content="マイページトップ" />
      <LayoutWaitingApi
        waitingApi={waitingApi}
        className="w-full h-[330px] mb-[20px]"
      >
        <StatisticalChart
          title="あなたの学習時間（受講コース合計)"
          statistics={myPageStatistics}
        />
      </LayoutWaitingApi>

      {/* <NoticeList /> */}
      {/* List course and unit Tablet & Desktop screen*/}
      <section className="flex gap-[32px] max-sm:hidden">
        <div className={`w-[478px] sm:w-full max-md:w-full`}>
          <LayoutWaitingApi
            waitingApi={waitingApi}
            className="w-full min-h-[150px] mb-[20px]"
          >
            <LearningList unitLearningList={unitLearningList} isMyPageTop />
          </LayoutWaitingApi>

          <LayoutWaitingApi
            waitingApi={waitingApi}
            className="w-full min-h-[150px] mb-[20px]"
          >
            <CourseList data={courseList} />
          </LayoutWaitingApi>

          <LearningTool />
        </div>
        <div className="w-[260px] max-md:hidden">
          {/* Schedule event */}
          <LayoutWaitingApi
            waitingApi={waitingApi}
            className="w-[260px] min-h-[500px] mb-[20px]"
          >
            <EventBoard
              onRefHandle={(e) =>
                setSelectedDate(new Date(e?.current?.value || new Date()))
              }
              existedEvents={eventsInMonth}
              initialDate={selectedDate}
            />
          </LayoutWaitingApi>
          {/* CommunityList hidden for later phases  */}
          {/* <CommunityList /> */}
        </div>
      </section>

      {/* List course, unit, CommunityList phone screen*/}
      <section className="hidden max-sm:block">
        <div className="w-full flex items-end">
          <div
            style={{
              backgroundColor: `${
                isClick === true ? studentTheme.main_color_first : "#EBE4D8"
              }`,
            }}
            className={`flex flex-1 items-center justify-center gap-[8px] mb-[-1px] text-[14px] text-[700] rounded-t-lg cursor-pointer ${
              isClick === true
                ? "h-[40px] text-white bg-primary"
                : "h-[36px] text-secondary-light bg-danger-light"
            }`}
            onClick={() => setIsClick(true)}
          >
            <MdSchedule size={21} />
            <div>最近の学習</div>
          </div>
          <div
            style={{
              backgroundColor: `${
                isClick === false ? studentTheme.main_color_first : "#EBE4D8"
              }`,
            }}
            className={`flex flex-1 items-center justify-center gap-[8px] mb-[-1px] text-[14px] text-[700] rounded-t-lg cursor-pointer ${
              isClick === false
                ? "h-[40px] text-white bg-primary"
                : "h-[36px] text-secondary-light bg-danger-light"
            }`}
            onClick={() => setIsClick(false)}
          >
            <HiOutlineUserCircle size={21} />
            <div>受講コース一覧</div>
          </div>
        </div>
        <div>
          <div
            style={{
              backgroundColor: studentTheme.main_color_first,
            }}
            className={`${
              isClick === true ? "block bg-primary" : "hidden"
            } p-[15px]`}
          >
            <LearningList unitLearningList={unitLearningList} isMyPageTop />
          </div>
          <div
            style={{
              backgroundColor: studentTheme.main_color_first,
            }}
            className={`${
              isClick === false ? "block bg-primary" : "hidden"
            } p-[15px]`}
          >
            <CourseList data={courseList} />
          </div>
        </div>
        <div className="hidden mt-[15px] px-[10px]">
          <CommunityList />
        </div>
      </section>
    </>
  );
};

export default MyPageTop;
