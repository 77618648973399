const PERIOD = [
  {
    value: 1,
    label: '期間を選択',
  },
  {
    value: 2,
    label: '直近 7日',
  },
  {
    value: 3,
    label: '過去30日間',
  },
  {
    value: 4,
    label: '過去90日間',
  },
];

const DISPLAY = ['1日', '1週', '1月'];

const TYPE_CHART = {
  STUDY_TIME: 'study_time',
  STUDY_PROCESS: 'study_process',
  STUDY_COURSE: 'study_course',
};

const STUDENT_ACTIVITY_LOG_TYPE = {
  UNIT_TEST: 0,
  UNIT_LESSON: 1,
  CREATE_MEMO: 2,
};

export { PERIOD, DISPLAY, TYPE_CHART, STUDENT_ACTIVITY_LOG_TYPE };
