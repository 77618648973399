import * as React from 'react';

const BookOpen = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={28} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 5.5V23"
      stroke={`${props.color || '#8FC2D5'}`}
      strokeWidth={1.875}
      strokeLinecap="round"
    />
    <path
      d="M6.476 2.107C10.652 2.902 13.14 4.565 14 5.52c.859-.955 3.348-2.618 7.524-3.413 2.116-.403 3.174-.604 4.075.168.901.772.901 2.025.901 4.533v9.01c0 2.293 0 3.44-.578 4.155-.579.716-1.852.958-4.398 1.443-2.27.432-4.04 1.12-5.323 1.813-1.261.68-1.892 1.021-2.201 1.021-.309 0-.94-.34-2.201-1.021-1.282-.692-3.054-1.38-5.323-1.813-2.546-.485-3.82-.727-4.398-1.443-.578-.715-.578-1.862-.578-4.154V6.808c0-2.508 0-3.761.901-4.533.901-.772 1.959-.571 4.075-.168Z"
      stroke={`${props.color || '#8FC2D5'}`}
      strokeWidth={1.875}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BookOpen;
