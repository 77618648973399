import { Coupon } from "../../../../types/admin/coupons";
import { Link } from "react-router-dom";
import { PATH_ADMIN_COUPON } from "../../../../services/constants/route/router";
import Pagination from "../../student/Pagination";
import { formatNumberWithCommas } from "../../../../services/helpers/parseData";

type TableProps = {
  coupons: Coupon[];
  totalPages: number;
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
  isCouponSelected: (id: number) => boolean;
  toggleSelect: (id: number) => void;
};

const Table = ({
  coupons,
  totalPages,
  currentPage,
  handlePageChange,
  isCouponSelected,
  toggleSelect,
}: TableProps) => {
  return (
    <>
      <div className="relative overflow-x-auto min-h-[500px]">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="min-w-[50px] py-[2px] border-x-4 border-white whitespace-nowrap "
              ></th>
              <th
                scope="col"
                className="2xl:px-[50px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                コード
              </th>
              <th
                scope="col"
                className="2xl:px-[50px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                枚数
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                割引金額・割引率
              </th>
              <th
                scope="col"
                className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                対象者
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              ></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(coupons) &&
              coupons &&
              !!coupons.length &&
              coupons.map((coupon, index) => (
                <tr
                  className="h-[50px] border-b border-success-extralight text-[12px] text-secondary-dark font-[400]"
                  key={index}
                >
                  <td className="text-center px-[8px]">
                    <input
                      type="checkbox"
                      checked={isCouponSelected(Number(coupon.id))}
                      onChange={() => toggleSelect(Number(coupon.id))}
                    />
                  </td>
                  <td className="text-center">{coupon.code}</td>
                  <td className="px-[20px]">{coupon.quantity}</td>
                  <td className="text-center">
                    {`${formatNumberWithCommas(coupon.amount || 0)} ${!!coupon.type ? "%" : "円"}`}
                  </td>
                  <td className="px-[20px]">{coupon.object_of_use}</td>
                  <td className="text-center">
                    <Link
                      className="w-[45px] text-[12px] text-white font-[500] bg-primary px-[10.5px] py-[4px] rounded-[5px]"
                      to={`${PATH_ADMIN_COUPON.DETAIL}/${coupon.id}`}
                    >
                      編集
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages && totalPages !== 0 && (
        <Pagination
          handlePageChange={handlePageChange}
          totalItems={totalPages}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default Table;
