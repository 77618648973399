import { IoSettingsOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { IconType } from "react-icons";

type ItemType = {
  label: string;
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>> | React.FC | IconType;
};

const ADMIN_MENU = {
  LOGOUT: "ログアウト",
  PROFILE: "プロフィール編集", 
};

const ADMIN_MENU_DATA: ItemType[] = [
  {
    label: ADMIN_MENU.LOGOUT,
    href: "/logout",
    icon: MdLogout,
  },
];

const ADMIN_MENU_BORDER_INDEX = [0, 3, 7, 10];

export { ADMIN_MENU, ADMIN_MENU_BORDER_INDEX, ADMIN_MENU_DATA };
