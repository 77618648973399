import React, { useEffect, useRef, useState } from "react";
import SelectStudentBirthDate from "./SelectStudentBirthDate";
import { GENDER_DATA } from "../../../services/constants/student/pages/profile";
import {
  swalClose,
  swalError,
  swalLoading,
  swalMessage,
  swalSuccess,
  swallConfirm,
} from "../../../services/helpers/swal";
import { makeRequest, request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { Student } from "../../../types/admin/student";
import { TbTrash } from "react-icons/tb";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsCheck2Circle } from "react-icons/bs";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";
import postal_code from "japan-postal-code";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATH_ADMIN_STUDENT } from "../../../services/constants/route/router";
import { USER_STATUS } from "../../../services/constants/user";
import ImageInternalButton from "../../commons/buttons/ImageInternalButton";
import PenEdit from "../../commons/icons/PenEdit";
import { PICTURE_TYPE } from "../../../services/constants/globals";
import { MESSAGE_COMMON } from "../../../services/constants/message";

type MainSection = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_section_id: null;
  status: number;
  student_count: number;
  sub_sections: SubSection[];
};

type SubSection = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_section_id: number;
  status: number;
  student_count: number;
};

type SectionGroup = {
  mainSectionId: number;
  subSectionId: number;
};

const StudentUpdate = () => {
  const [studentFormData, setStudentFormData] = useState<
    Student & { new_email?: string }
  >({
    status: 0,
  } as any);
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [errors, setErrors] = useState<ErrorData>({});
  const [mainSections, setMainSections] = useState<MainSection[]>([]);
  const [sectionGroups, setSectionGroups] = useState<SectionGroup[]>([
    { mainSectionId: 0, subSectionId: 0 },
  ]);
  const [status, setStatus] = useState<number>();
  const [gender, setGender] = useState<number>();
  const [zipCode, setZipCode] = useState<string>("");
  const [zipCodePrefix, setZipCodePrefix] = useState<string>("");
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
  const [isChangeEmail, setIsChangeEmail] = useState<boolean>(false);
  const params = useParams();
  const navigate = useNavigate();
  const inputPrefixRef = useRef<HTMLInputElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchMainSection = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_SECTION.GET_SECTION_LIST}`,
      });

      if (!result.data) return swalError();

      const mainSections = result.data.map((mainSectionData: any) => {
        const subSections = mainSectionData.sub_section.map(
          (subSectionData: any) => ({
            id: subSectionData.id,
            name: subSectionData.name,
          })
        );
        return {
          id: mainSectionData.id,
          name: mainSectionData.name,
          sub_sections: subSections,
        };
      });
      setMainSections(mainSections);
      swalClose();
    };

    const fetchExistingStudentDetails = async () => {
      // swalLoading();
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_STUDENT.GET_STUDENT_DETAILS}?student_id=${params.id}`,
      });
      const student = result.data;
      if (student.new_email) {
        setIsChangeEmail(true);
      }
      if (student.avatar) {
        setStudentFormData({
          ...studentFormData,
          avatar_url: student.avatar_url,
        });
      }

      if (!student) return swalError();
      student["student_id"] = student["id"];
      if (!student["birthday"]) {
        delete student["birthday"];
      }
      delete student["id"];
      delete student["avatar"];
      for (let key in student) {
        if (student[key] === null) {
          delete student[key];
        }
      }

      if (student["zip_code"] && student["zip_code"].length === 7) {
        setZipCode(student["zip_code"].substring(0, 3));
        setZipCodePrefix(student["zip_code"].substring(3));
      }

      const existingSections = student.sections || [];
      let newSectionGroups = existingSections.map((existingSection: any) => {
        if (existingSection.parent_section_id === null) {
          // Only main section exists
          return {
            mainSectionId: existingSection.id,
            subSectionId: 0,
          };
        } else {
          // Main section and sub section both exist
          return {
            mainSectionId: existingSection.parent_section_id,
            subSectionId: existingSection.id,
          };
        }
      });

      if (existingSections.length === 0) {
        newSectionGroups = [
          {
            mainSectionId: 0,
            subSectionId: 0,
          },
        ];
      }
      setStudentFormData(student);
      setStatus(student.status);
      setGender(student.gender);
      setSectionGroups(newSectionGroups);
      swalClose();
    };

    fetchExistingStudentDetails();
    fetchMainSection();
  }, []);

  const handleMainSectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newSectionGroups = [...sectionGroups];
    newSectionGroups[index].mainSectionId = parseInt(event.target.value);
    newSectionGroups[index].subSectionId = 0;
    setSectionGroups(newSectionGroups);
  };
  const handleSubSectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newSectionGroups = [...sectionGroups];
    newSectionGroups[index].subSectionId = parseInt(event.target.value);
    setSectionGroups(newSectionGroups);
  };

  const handleAddSectionGroup = () => {
    setSectionGroups([...sectionGroups, { mainSectionId: 0, subSectionId: 0 }]);
  };

  const handleDeleteSectionGroup = (index: number) => {
    const newSectionGroups = [...sectionGroups];
    newSectionGroups.splice(index, 1);
    setSectionGroups(newSectionGroups);
  };

  const isSectionGroupValid = (index: number) => {
    const sectionGroup = sectionGroups[index];
    return (
      sectionGroup.mainSectionId !== 0 &&
      sectionGroup.subSectionId !== 0 &&
      !sectionGroups.some(
        (sg, i) =>
          i !== index &&
          sg.mainSectionId === sectionGroup.mainSectionId &&
          sg.subSectionId === sectionGroup.subSectionId
      )
    );
  };
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/^\d{0,3}$/.test(input)) {
      setZipCode(input);
      if (input.length === 3 && inputPrefixRef.current) {
        inputPrefixRef.current.focus();
      }
    }
  };
  const handleZipCodePrefixChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = e.target.value;
    if (/^\d{0,4}$/.test(input)) {
      setZipCodePrefix(input);
    }
  };
  const handleZipCodePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData("text/plain");
    const matches = pastedData.match(/^(\d{3})-(\d{4})$/);
    if (matches) {
      setZipCode(matches[1]);
      setZipCodePrefix(matches[2]);
      e.preventDefault();
    }
  };
  const emailValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { name, value } = e.target;
    setStudentFormData({ ...studentFormData, [name]: value });
    if (value.match(re)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const file =
      (e.target as HTMLInputElement).files &&
      (e.target as HTMLInputElement).files?.[0];
    if (file) {
      const byteSize = 2097152;
      if (file.size > byteSize) {
        setErrors({
          maxSize: ["ファイルサイズが2MBの最大制限を超えています。"],
        });
        return;
      }
      if (!PICTURE_TYPE.includes(file.type)) {
        setErrors({
          type: [MESSAGE_COMMON.INVALID_FILE_TYPE],
        });
        return;
      }
      setStudentFormData({ ...studentFormData, [name]: file });
      return;
    }
    if (name === "status") {
      setStatus(Number(value));
    }
    if (name === "gender") {
      setGender(Number(value));
    }

    setStudentFormData({ ...studentFormData, [name]: value });
  };

  const syncingZipCode = () => {
    postal_code.get(`${zipCode}${zipCodePrefix}`, (object) =>
      setStudentFormData({
        ...studentFormData,
        province: object.prefecture,
        ward: object.city,
        address: object.area,
        building: object.street,
      })
    );
  };

  const handleDelete = async () => {
    swallConfirm(async () => {
      // swalLoading();
      const result = await makeRequest({
        method: "delete",
        url: API.ADMIN_STUDENT.DELETE_STUDENT,
        data: { student_ids: [studentFormData.student_id] },
      });
      if (!result.status) {
        return swalError();
      }
      swalSuccess();
      navigate(PATH_ADMIN_STUDENT.DEFAULT);
    }, "この受講者を削除して<br>よろしいですか？");
  };

  const handleSubmit = async () => {
    if (isDisableButton) return;

    swalLoading();
    setIsDisableButton(true);
    let zipcode = `${zipCode}${zipCodePrefix}`;
    const filteredSectionGroups = sectionGroups
      .flatMap(({ mainSectionId, subSectionId }) =>
        subSectionId !== 0
          ? [subSectionId]
          : mainSectionId !== 0
          ? [mainSectionId]
          : []
      )
      .filter((num) => num !== 0)
      .filter((num, index, self) => self.indexOf(num) === index);
    const result = await makeRequest({
      method: "post",
      url: API.ADMIN_STUDENT.UPDATE_STUDENT,
      data: {
        ...studentFormData,
        section_ids: filteredSectionGroups,
        zip_code: zipcode,
      },
      hasFileRequest: true,
    });

    if (!result.status) {
      setIsDisableButton(false);
      setErrors(result.error as ErrorData);
      return swalError();
    }

    swalSuccess();
    navigate(PATH_ADMIN_STUDENT.DEFAULT);
  };

  return (
    <>
      <div className="mt-[40px]">基本情報</div>
      <div className="mb-[25px] mt-[10px]">
        {errors && Object.keys(errors).length > 0 && (
          <ErrorBox errors={errors} />
        )}
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">ログインID（半角英数のみ８〜20）</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px] mr-[30px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
              name="username"
              value={studentFormData.username}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">メールアドレス</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] mx-[5px] mr-[30px] py-[10px]">
            <div className="flex items-center">
              <div className="basis-[50%] text-[13px] leading-[100%] flex items-center pl-[12px] h-[30px] max-w-[550px]">
                {studentFormData.email}
              </div>
              {studentFormData.status === USER_STATUS.CONFIRMING ||
              !studentFormData.status ? (
                <div className="basis-[50%] min-w-[90px] ml-[16px] pl-[12px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                  <RiErrorWarningLine
                    className="absolute top-[2px] left-[4px]"
                    size={18}
                  />
                  <p className="pl-[12px]">確認中</p>
                </div>
              ) : (
                <div className="min-w-[90px] ml-[5px] pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                  <BsCheck2Circle
                    className="absolute top-[2px] left-[4px]"
                    size={18}
                  />
                  <p className="pl-[12px]">確認済み</p>
                </div>
              )}
            </div>
            <div className="w-full mt-[12px]">
              {isChangeEmail ? (
                <div className="flex items-center gap-x-[12px]">
                  <div className="basis-[50%] ">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[12px] h-[30px] max-w-[550px"
                      name="new_email"
                      value={studentFormData.new_email || ""}
                      onChange={emailValidation}
                    />
                  </div>
                  <div className="basis-[50%] flex items-center gap-x-[16px]">
                    <div className="flex items-center gap-x-[5px] w-fit px-[7px] py-[2px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap text-xs">
                      <RiErrorWarningLine size={18} />
                      <p>確認中</p>
                    </div>
                    <span className="text-[12px]">
                      メールは確認されるまで、古いメールで利用可能
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className="w-fit max-h-[24px] flex items-center ml-[12px] px-[10px] py-[6px] bg-primary text-white text-[12px] font-[500] leading-[100%] rounded-[5px] cursor-pointer"
                  onClick={() => setIsChangeEmail(true)}
                >
                  <PenEdit />
                  メールアドレスを変更
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">ステータス</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="text-secondary-dark ml-[13px] pl-[12px] w-[85%] flex items-center mx-[5px]">
            <label className="inline-flex items-center mr-[15px]">
              <input
                type="radio"
                className="form-radio text-primary-light border border-success-extralight"
                name="status"
                value="1"
                checked={status === 1}
                onChange={handleChange}
              />
              <span className="ml-[8px] text-[12px] leading-[100%]">有効</span>
            </label>
            <label className="inline-flex items-center mr-[15px]">
              <input
                type="radio"
                className="form-radio text-primary-light border border-success-extralight"
                name="status"
                value="2"
                checked={status === 2}
                onChange={handleChange}
              />
              <span className="ml-[8px] text-[12px] leading-[100%]">
                停止中
              </span>
            </label>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">受講者氏名</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full mr-[21px]">
            <div className="text-secondary-dark w-full flex items-center mx-[5px]">
              <input
                className="text-[13px] leading-[100%] ml-[8px] flex items-center pl-[15.5px] h-[30px]"
                name="first_name"
                value={studentFormData.first_name}
                onChange={handleChange}
              />
            </div>
            <div className="text-secondary-dark] w-full flex items-center mx-[5px]">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]"
                name="last_name"
                value={studentFormData.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="">受講者氏名（カナ）</div>
          </div>
          <div className="flex justify-between w-full mr-[21px]">
            <div className="text-secondary-dark w-full flex items-center mx-[5px]">
              <input
                className="text-[13px] leading-[100%] ml-[8px] flex items-center pl-[15.5px] h-[30px]"
                name="first_name_kana"
                value={studentFormData.first_name_kana}
                onChange={handleChange}
              />
            </div>
            <div className="text-secondary-dark] w-full flex items-center mx-[5px]">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]"
                name="last_name_kana"
                value={studentFormData.last_name_kana}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pl-[14px] pt-[10px]">
            <div className="w-full flex justify-between">
              <div className="">セクション</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            {sectionGroups.map((sectionGroup, index) => (
              <div
                key={`section-${index}`}
                className="flex justify-between w-full max-h-[40px] mx-[5px]"
              >
                <div className="text-secondary-dark w-full flex items-center mx-[5px] relative">
                  <select
                    className="h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark text-[13px] leading-[100%] w-full"
                    name="section_ids"
                    onChange={(event) => handleMainSectionChange(event, index)}
                    value={sectionGroup.mainSectionId}
                  >
                    <option value=""></option>
                    {mainSections.map((mainSection) => (
                      <option
                        className="text-[13px] leading-[100%]"
                        key={mainSection.id}
                        value={mainSection.id}
                      >
                        {mainSection.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-secondary-dark w-full flex items-center mx-[5px] relative">
                  <select
                    className="h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark text-[13px] leading-[100%] w-full"
                    name="section_ids"
                    onChange={(event) => handleSubSectionChange(event, index)}
                    value={sectionGroup.subSectionId}
                  >
                    <option value="0"></option>
                    {mainSections
                      .find((ms) => ms.id === sectionGroup.mainSectionId)
                      ?.sub_sections.map((subSection) => (
                        <option
                          className="text-[13px] leading-[100%]"
                          key={subSection.id}
                          value={subSection.id}
                        >
                          {subSection.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="text-secondary-dark flex items-center w-[25px]">
                  {index > 0 ? (
                    <TbTrash
                      className="mb-[4px] text-[#7A7A7A]"
                      onClick={() => handleDeleteSectionGroup(index)}
                      size={50}
                    />
                  ) : (
                    <TbTrash
                      className="mb-[4px] text-secondary-light invisible"
                      size={50}
                    />
                  )}
                </div>
              </div>
            ))}
            <button
              className={`bg-primary text-white text-[12px] font-[500] leading-[17px] flex items-center justify-center ml-[10px] rounded-[5px] min-h-[25px] w-full max-w-[180px] cursor-pointer mb-[5px]`}
              onClick={handleAddSectionGroup}
            >
              <span className="text-[23px] mb-[3px] mr-[8px]">+</span>{" "}
              所属セクションを追加
            </button>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            郵便番号
          </div>

          <div className="text-secondary-dark ml-[13px] w-[40%] flex gap-[7px] items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
              name="zip_code"
              value={zipCode}
              onChange={handleZipCodeChange}
              onPaste={handleZipCodePaste}
            />
            <div className="w-[12px] bg-secondary-light h-[1px]"></div>
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
              name="zip_code_prefix"
              value={zipCodePrefix}
              onChange={handleZipCodePrefixChange}
              onPaste={handleZipCodePaste}
              ref={inputPrefixRef}
            />
            <div
              onClick={syncingZipCode}
              className={`px-[3.5px] ${
                zipCode || zipCodePrefix ? "bg-primary" : "bg-[#BEBEBE]"
              } text-white text-[9px] font-[500] leading-[100%] w-[52px] h-[22px] flex items-center justify-center rounded-[5px] cursor-pointer ml-[5px]`}
            >
              自動入力
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[12px] pl-[14px]">
            住所
          </div>

          <div className="text-secondary-dark ml-[13px] items-center text-[13px] leading-[100%] w-[85%] mr-[30px]">
            <div className="relative w-[300px]">
              <input
                name="province"
                className="text-[13px] h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark leading-[100%] min-w-[300px]"
                onChange={handleChange}
                value={studentFormData.province}
                placeholder="都道府県を入力"
              />
            </div>
            <input
              type="text"
              name="ward"
              value={studentFormData.ward}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[30px]"
              placeholder="例）港区"
              onChange={handleChange}
            />
            <input
              type="text"
              name="address"
              value={studentFormData.address}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[30px]"
              placeholder="例）赤坂1-2-34"
              onChange={handleChange}
            />
            <input
              type="text"
              name="building"
              value={studentFormData.building}
              id=""
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]"
              placeholder="例）パレットビル3階"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            電話番号
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mr-[30px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]"
              name="phone_number"
              value={studentFormData.phone_number}
              onChange={handleChange}
              placeholder="03-1234-5678"
            />
          </div>
        </div>
      </div>
      <div>プロフィール設定</div>
      <div className="mt-[10px]">
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            性別
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px] mr-[30px]">
            {GENDER_DATA.slice(0, 2).map((item, index) => (
              <label className="inline-flex items-center mr-[15px]" key={index}>
                <input
                  type="radio"
                  className="form-radio text-primary-light border border-success-extralight"
                  name="gender"
                  value={item.value}
                  onChange={handleChange}
                  checked={gender === item.value}
                />
                <span className="ml-[8px] text-[12px] leading-[100%]">
                  {item.label}
                </span>
              </label>
            ))}
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            生年月日
          </div>

          <div className="text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%]">
            <SelectStudentBirthDate
              studentFormData={studentFormData}
              setBirthDate={setStudentFormData}
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[10px] pl-[14px]">
            プロフィール画像
          </div>

          <div className="text-secondary-dark ml-[13px] w-[85%] text-[12px] leading-[100%]">
            <div className="flex items-center">
              {studentFormData.avatar || studentFormData.avatar_url ? (
                <div className="flex items-center relative  w-[330px] my-[5px] mr-[16px] overflow-hidden">
                  <ImageInternalButton
                    removeImage={() => {
                      if (inputFileRef.current) {
                        inputFileRef.current.value = "";
                      }
                      setStudentFormData({
                        ...studentFormData,
                        avatar: null,
                        avatar_url: null,
                      });
                    }}
                    editImage={() => inputFileRef.current?.click()}
                  />
                  <img
                    className="w-[330px] object-cover border-gray-200 my-[5px]"
                    src={
                      studentFormData.avatar
                        ? URL.createObjectURL(studentFormData.avatar)
                        : studentFormData.avatar_url || ""
                    }
                    alt="Profile Avatar"
                  />
                </div>
              ) : (
                <div
                  className="w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
                  onClick={() => inputFileRef.current?.click()}
                ></div>
              )}
              <label
                htmlFor="avatar"
                className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]"
              >
                ファイルを選択
              </label>
              <input
                type="file"
                id="avatar"
                name="avatar"
                accept="image/*"
                className="hidden"
                ref={inputFileRef}
                onChange={handleChange}
                placeholder="ファイルを選択"
              />
              {!studentFormData.avatar && (
                <div className="ml-[10px]">選択されていません</div>
              )}
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="">職業</div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[30%] flex items-center mx-[5px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
              name="job"
              value={studentFormData.job}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="">居住地</div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[30%] flex items-center mx-[5px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
              name="residence"
              value={studentFormData.residence}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px]">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pt-[12px] pl-[14px]">
            <div className="">その他</div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px] mr-[30px]">
            <textarea
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[140px] w-full pt-[10px] my-[5px]"
              name="other"
              value={studentFormData.other}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mb-[60px] mt-[60px] gap-10">
        <Link to={PATH_ADMIN_STUDENT.DEFAULT}>
          <button className="bg-[#BEBEBE] px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]">
            一覧画面へ戻る
          </button>
        </Link>
        <button
          className={`${
            isDisableButton
              ? "cursor-not-allowed pointer-events-none bg-secondary-extralight"
              : "bg-primary"
          }  px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]`}
          onClick={handleSubmit}
        >
          内容を変更する
        </button>
        <button
          className="bg-secondary px-[25px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%] max-h-[43px] flex items-center ml-[70px]"
          onClick={handleDelete}
        >
          <div className="">このアカウントを削除</div>
          <TbTrash className="ml-[4px] text-white " size={30} />
        </button>
      </div>
    </>
  );
};

export default StudentUpdate;
