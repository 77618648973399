import React, { useEffect, useState } from 'react';
import FormItem from '../../commons/FormItem';
import StatusBox from '../../commons/StatusBox';
import { FiTrash2 } from 'react-icons/fi';
import CancelButton from '../../commons/CancelButton';
import SubmitButton from '../../commons/SubmitButton';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import {
  swalClose,
  swalError,
  swalLoading,
  swalSuccess,
  swallConfirm,
} from '../../../../services/helpers/swal';
import { makeRequest } from '../../../../services/axios/axios';
import { PATH_ADMIN_SECTION } from '../../../../services/constants/route/router';
import { API } from '../../../../services/constants/route/api';
import { Section } from '../../../../types/admin/section';
import { scrollToTop } from '../../../../services/helpers/effect';
import ErrorBox from '../../../commons/form/ErrorBox';
import { ErrorData } from '../../../../types/globals';

type FormProps = {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};

const Form = ({ setTitle }: FormProps) => {
  const { getParamValue, navigate } = useObjectRoutes();
  const sectionId = getParamValue('section_id');
  const [currentSection, setCurrentSection] = useState<Section>({});
  const [parentSections, setParentSections] = useState<Section[]>([]);
  const [errors, setErrors] = useState<ErrorData>({});

  useEffect(() => {
    if (!sectionId) return;
    scrollToTop();

    const fetch = async () => {
      // swalLoading();

      const sectionInfoPromise = makeRequest({
        method: 'get',
        url: `${API.ADMIN_SECTION.GET_SECTION_INFO}?section_id=${sectionId}`,
      });

      const parentSectionPromise = makeRequest({
        method: 'get',
        url: API.ADMIN_SECTION.GET_MAIN_SECTION,
      });

      const result = await Promise.all([sectionInfoPromise, parentSectionPromise]);
      const [sectionInfoResult, parentSectionInfoResult] = result;

      if (!sectionInfoResult.status || !parentSectionInfoResult.status) return swalError();

      const mainSections = parentSectionInfoResult.data as Section[];
      const currentSection = sectionInfoResult.data as Section;

      let title = '';
      if (!currentSection.parent_section_id) {
        title = currentSection?.name || '';
      } else {
        const parentCurrentSection = mainSections.find(
          (section) => section.id === currentSection?.parent_section_id,
        );
        title = `${parentCurrentSection?.name}｜${currentSection?.name}`;
      }

      setTitle(title);
      setCurrentSection(sectionInfoResult.data);
      setParentSections(parentSectionInfoResult.data);
      swalClose();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    setCurrentSection({
      ...currentSection,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    if (!sectionId) return;

    // swalLoading();
    const result = await makeRequest({
      method: 'patch',
      url: `${
        currentSection.parent_section_id
          ? API.ADMIN_SECTION.UPDATE_SUBSECTION_INFO
          : API.ADMIN_SECTION.UPDATE_SECTION_INFO
      }`,
      data: { ...currentSection, section_id: currentSection.id },
    });

    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError();
    }
    swalSuccess();

    navigate(PATH_ADMIN_SECTION.DEFAULT);
  };

  const handleDelete = async () => {
    swallConfirm(
      async () => {
        // swalLoading();
        const result = await makeRequest({
          method: 'delete',
          url: API.ADMIN_SECTION.DELETE_SECTION,
          data: { section_id: currentSection.id },
        });

        if (!result.status) return swalError();
        swalSuccess();

        navigate(PATH_ADMIN_SECTION.DEFAULT);
      },
      'このセクションを削除して よろしいですか？',
      `セクションに含まれるサブセクションも削除されます。<br><br> セクションに含まれる受講者は削除されません。`,
    );
  };

  return (
    <section className="mt-[20px]">
      {Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}

      <FormItem label="セクション名" withRequired>
        <input
          type="text"
          name="name"
          id=""
          required
          value={currentSection.name}
          onChange={handleOnChange}
        />
      </FormItem>

      {currentSection.parent_section_id && (
        <FormItem label="親セクション">
          <select
            name="parent_section_id"
            id=""
            className="w-full max-w-[320px] py-[10px] pl-[13px] text-[12px] leading-[100%] h-[33px]"
            value={currentSection.parent_section_id}
            onChange={handleOnChange}
          >
            <option value="" disabled>
              選択してください
            </option>
            {parentSections.map((section, index) => (
              <option value={section.id} key={index}>
                {section.name}
              </option>
            ))}
          </select>
        </FormItem>
      )}

      <FormItem label="セクションID" withRequired>
        <input
          type="text"
          name="code"
          id=""
          required
          value={currentSection.code}
          onChange={handleOnChange}
        />
      </FormItem>

      <FormItem label="ステータス" withRequired>
        <StatusBox handleOnChange={handleOnChange} value={currentSection.status} />
      </FormItem>

      <FormItem label="セクション概要">
        <textarea
          name="description"
          id=""
          className="min-h-[90px]"
          onChange={handleOnChange}
          value={currentSection.description}
        />
      </FormItem>

      <div className="flex justify-center gap-[60px] mt-[66px] mb-[8px]">
        <div
          className="border border-secondary text-secondary rounded-[5px] text-[16px] font-[500] leading-[100%] flex gap-[15px] min-h-[36px] w-full max-w-[254px] justify-center items-center cursor-pointer"
          onClick={handleDelete}
        >
          <div className="">
            <FiTrash2 />
          </div>
          <div className="">このセクションを削除</div>
        </div>

        <div className="flex gap-[15px]">
          <CancelButton navigateTo={PATH_ADMIN_SECTION.DEFAULT} />
          <SubmitButton onSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  );
};

export default Form;
