import React, { useState, useEffect } from 'react';
import TableItem from './TableItem';
import { Section } from '../../../../types/admin/section';
import { swalClose, swalError, swalLoading } from '../../../../services/helpers/swal';
import { makeRequest } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { useSectionListContext } from '../../../../context/Section/List';
import { DIRECTION } from '../../../../services/constants/globals';
import { sortArrayByKey } from '../../../../services/helpers/parseData';

type SectionGroupItemProps = {
  isUpDisabled?: boolean;
  isDownDisabled?: boolean;
  data: Section;
  setIsOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DroppedRecord = {
  [key: string]: boolean;
};

const TableGroupItem = ({
  isUpDisabled,
  isDownDisabled,
  data,
  setIsOpenModal,
}: SectionGroupItemProps) => {
  const { sections, setSections } = useSectionListContext();
  const [childs, _setChilds] = useState<Section['sub_section']>([]);
  const [isDropDown, setIsDropDown] = useState<boolean>(true);

  const setChilds = (childs: Section['sub_section']) => {
    if (!childs) return [];
    _setChilds(sortArrayByKey(childs, 'order'));
  };

  useEffect(() => {
    setChilds(sortArrayByKey(data.sub_section || [], 'order'));
  }, [data.sub_section]);

  const orderHandle = async (
    sectionId: Section['id'],
    direction: 'up' | 'down' = DIRECTION.UP,
    isParent: boolean,
  ) => {
    if (!childs || !sections) return;

    //Down for current record
    const currentArray = isParent ? sections : childs;
    const currentSection = currentArray.find((childSection) => childSection.id === sectionId);
    if (!currentSection) return;

    // swalLoading();
    const result = await makeRequest({
      method: 'patch',
      url: `${API.ADMIN_SECTION.UPDATE_SECTION}`,
      data: {
        section_id: sectionId,
        order:
          direction === DIRECTION.DOWN
            ? (currentSection?.order || 0) + 1
            : (currentSection?.order || 0) - 1,
      },
    });

    if (!result.data) swalError();

    let sectionsParsed: Section[] = [];
    let childsParsed: Section[] = [];

    if (!result.data.parent_section_id) {
      sectionsParsed = [
        result.data,
        ...sections.filter((section) => section.id !== (result.data as Section).id),
      ];
      setSections(sectionsParsed);
    } else {
      childsParsed = [
        result.data,
        ...childs.filter((section) => section.id !== (result.data as Section).id),
      ];

      setChilds(childsParsed);
    }

    //Up for below record
    const currentSectionIndex = currentArray.findIndex((section) => section.id === sectionId);
    const siblingSection =
      direction === DIRECTION.DOWN
        ? currentArray[currentSectionIndex + 1]
        : currentArray[currentSectionIndex - 1];

    const siblingResult = await makeRequest({
      method: 'patch',
      url: `${API.ADMIN_SECTION.UPDATE_SECTION}`,
      data: {
        section_id: siblingSection.id,
        order:
          direction === DIRECTION.DOWN
            ? (siblingSection?.order || 0) - 1
            : (siblingSection?.order || 0) + 1,
      },
    });
    if (!siblingResult.data) return swalError();

    if (!siblingResult.data.parent_section_id) {
      sectionsParsed = [
        siblingResult.data,
        ...sectionsParsed.filter((section) => section.id !== (siblingResult.data as Section).id),
      ];
      setSections(sectionsParsed);
    } else {
      childsParsed = [
        siblingResult.data,
        ...childsParsed.filter((section) => section.id !== (siblingResult.data as Section).id),
      ];
      setChilds(childsParsed);
    }

    swalClose();
  };

  return (
    <div className="">
      <TableItem
        data={data}
        isDownDisabled={isDownDisabled}
        isUpDisabled={isUpDisabled}
        isDropDown={isDropDown}
        setIsDropDown={setIsDropDown}
        onOrder={orderHandle}
        setIsOpenModal={setIsOpenModal}
      />

      <div className="">
        {isDropDown &&
          childs &&
          childs.map((item, index) => (
            <TableItem
              data={item}
              key={item.id}
              isDownDisabled={index === childs.length - 1}
              isUpDisabled={index === 0}
              onOrder={orderHandle}
            />
          ))}
      </div>
    </div>
  );
};

export default TableGroupItem;
