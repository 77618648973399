import { useState, useEffect } from "react";
import { PATH_STUDENT_CART } from "../../../services/constants/route/router";
import { useCartContext } from "../../../context/CartContext";
import {
  swalClose,
  swalError,
  swalLoading,
  swalMessage,
} from "../../../services/helpers/swal";
import { PAYMENT_METHOD_TYPE } from "../../../services/constants/student/payment";
import { makeRequest, request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import axios from "axios";
import ButtonPaymentMethod from "./ButtonPaymentMethod";

type ProductPaymentType = {
  product_id: number;
  product_name: string;
  product_image: string;
  price: number;
  quantity: number;
};

type PaymentDataType = {
  products: ProductPaymentType[];
  payment_method_type: string;
  coupon_id?: number;
  stripe_coupon_id?: string;
  success_url: string;
  cancel_url: string;
};

type CouponDataType = {
  coupon_id?: number;
  amount_discount?: number;
  stripe_coupon_id?: string;
};

const Payment = () => {
  const { cartItems } = useCartContext();
  const [services, setServices] = useState<string[]>([]);

  const [couponData, setCouponData] = useState<CouponDataType>({
    amount_discount: 0,
  });
  const [paymentData, setPaymentData] = useState<PaymentDataType>({
    products: [],
    payment_method_type: "",
    success_url: window.location.origin + PATH_STUDENT_CART.ORDER_SUCCESS,
    cancel_url: window.location.origin + PATH_STUDENT_CART.DEFAULT,
  });

  const handleFetchCartItems = () => {
    const products = cartItems?.filter((cartItem) => cartItem.status == 1);

    let total: number = 0;
    let productsPayment: ProductPaymentType[] = [];
    products.forEach((product) => {
      productsPayment = [
        ...productsPayment,
        {
          product_id: product.product_id,
          product_name: product.name,
          product_image:
            "https://fastly.picsum.photos/id/1/200/200.jpg?hmac=jZB9EZ0Vtzq-BZSmo7JKBBKJLW46nntxq79VMkCiBG8",
          price: product.price,
          quantity: product.quantity,
        },
      ];

      total += product.price * product.quantity;
    });

    if (couponData && couponData.stripe_coupon_id) {
      const paymentDataNew = paymentData;

      setPaymentData({
        ...paymentDataNew,
        stripe_coupon_id: couponData.stripe_coupon_id,
        coupon_id: couponData.coupon_id,
        products: productsPayment,
      });
    } else {
      setPaymentData({ ...paymentData, products: productsPayment });
    }
  };

  const handleChoosePaymentMethodType = (paymentMethod: string) => {
    setPaymentData({ ...paymentData, payment_method_type: paymentMethod });
  };

  const handleRedirectToPaymentPage = async () => {
    try {
      if (paymentData.products.length === 0) return;

      if (paymentData.payment_method_type === "") {
        throw new Error("決済方法を指定してください。");
      }
      // swalLoading();
      const result = await makeRequest({
        method: "post",
        url: API.PAYMENT.GET_URL_CHECKOUT,
        data: paymentData,
      });

      if (!result.status) throw new Error(result.message)
      else if (result.status) {
        window.location.href = result.data;
      }
    } catch (error) {
      swalMessage("", String(error).replace("Error:", ""), "error", {
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleFetchPaymentMethod = async () => {
    // swalLoading();
    try {
      const subdomain = window.location.hostname.split(".")[0];
      const url =
        process.env.REACT_APP_API_PALETTE +
        `/api/payment-setting/get-payment-method?sub_domain=${subdomain}`;
      const response = (await axios.get(url)).data;
      setServices(response.result.data);
      swalClose();
    } catch (error) {
      swalError();
      return;
    }
  };

  useEffect(() => {
    handleFetchCartItems();
    handleFetchPaymentMethod();
    const couponData = localStorage.getItem("coupon_data");
    if (couponData) {
      const parseData = JSON.parse(couponData);
      setCouponData(parseData);
    }
  }, [cartItems]);

  return (
    <section className="w-full h-[100vh] bg-[#FEFCF9] p-[40px] whitespace-nowrap text-ellipsis font-[400] text-[12px]">
      <div className="w-full h-full shadow-[-5px_0_6px_0] shadow-zinc-300 bg-secondary-morelighter px-[29px] pt-[142px] pb-[125px]">
        <p className="text-center text-[14px] font-[400] leading-[140%] mb-[60px]">
          お支払い方法の選択
        </p>
        <div className="flex items-center justify-center gap-[30px] mb-[317px]">
          {services &&
            !!services.filter(
              (service: string) => service === PAYMENT_METHOD_TYPE.CARD.VALUE
            ).length && (
              <ButtonPaymentMethod
                payment_method_choose={paymentData.payment_method_type}
                payment_value={PAYMENT_METHOD_TYPE.CARD.VALUE}
                payment_label={PAYMENT_METHOD_TYPE.CARD.LABEL}
                handleClick={() =>
                  handleChoosePaymentMethodType(PAYMENT_METHOD_TYPE.CARD.VALUE)
                }
              />
            )}
          {services &&
            !!services.filter(
              (service: string) => service === PAYMENT_METHOD_TYPE.KONBINI.VALUE
            ).length && (
              <ButtonPaymentMethod
                payment_method_choose={paymentData.payment_method_type}
                payment_value={PAYMENT_METHOD_TYPE.KONBINI.VALUE}
                payment_label={PAYMENT_METHOD_TYPE.KONBINI.LABEL}
                handleClick={() =>
                  handleChoosePaymentMethodType(
                    PAYMENT_METHOD_TYPE.KONBINI.VALUE
                  )
                }
              />
            )}
        </div>
        <div className="flex justify-center mb-[125px]">
          <button
            className="w-[410px] px-[87px] pt-[13px] pb-[16px] text-white text-[16px] text-center font-[700] leading-[140%] trachking-[0.32px] bg-[#1C254F] shadow-md"
            onClick={handleRedirectToPaymentPage}
          >
            次に進む
          </button>
        </div>
      </div>
    </section>
  );
};

export default Payment;
