import { SubmitAnswer } from "../../../components/student/Course/ExecuteTest/ExecuteTest";
import { QuestionContentOptions } from "../../../components/student/Course/ExecuteTest/QuestionContent";
import type { Object } from "../../../types/globals";
import {
  Question,
  TestHistory,
  TestPausedHistory,
} from "../../../types/student/unit";
import {
  KATAKANA_ALPHABET,
  QUESTION_TYPE,
} from "../../constants/admin/pages/material";
import {
  decodeHTMLEntities,
  decryptWithAES,
  encryptWithAES,
  groupArrayByProperty,
} from "../../helpers/parseData";

const generateQuestionInputName = (
  type: number,
  questionId?: number,
  yieldCode?: string | number
) => {
  if (!questionId) return "";
  const result = `${type}_${questionId}_${yieldCode || ""}`;
  //avoiding yield will be error
  return encryptWithAES(result);
};

const getItemFromArrayByIndex = <T>(array: T[], index: number): T => {
  if (
    !array ||
    !array.length ||
    index === undefined ||
    index > array.length - 1
  )
    return 0 as T;
  return array[index];
};

const getQuestionPropertyByName = (keyName: string) => {
  //avoiding yield will be error
  //get current encrypted name
  const crKeyName = decryptWithAES(keyName);
  const splitted = crKeyName.split("_");

  return {
    type: Number(getItemFromArrayByIndex(splitted, 0)),
    questionId: Number(getItemFromArrayByIndex(splitted, 1)),
    yieldCode: getItemFromArrayByIndex(splitted, 2),
  };
};

const getAnswerByType = (object: Object): SubmitAnswer[] => {
  if (!object || !Object.keys(object).length) return [];

  const result: SubmitAnswer[] = [];

  for (const objectKey in object) {
    const { type, yieldCode } = getQuestionPropertyByName(objectKey);

    if (type === QUESTION_TYPE.FILL_IN_BLANK) {
      const resultData = {
        yield_code: yieldCode,
        value: object[objectKey],
      };
      result.push(resultData);
      continue;
    }

    result.push(Number(object[objectKey]));
  }

  return result;
};

//Pre-parsing for question content
const getContent = (plainContent?: string) => {
  if (!plainContent) return "";
  if (typeof plainContent !== "string") plainContent = String(plainContent);

  return decodeHTMLEntities(plainContent);
};

const placeYieldQuestionContent = (
  question: Question,
  options?: QuestionContentOptions
): string => {
  const { answers, type, id, title, order } = question;
  if (!answers || !answers.length) return "";
  let result: string;

  //find current answered question
  const currentAnsweredByQuestion = options?.answeredData?.find(
    (data) => data.question_id === id
  );
  const isAnswered =
    currentAnsweredByQuestion?.answers &&
    currentAnsweredByQuestion?.answers.length > 0;

  //render checked/selected for radio/checkbox type
  const renderSelectedByAnswered = (answerId: number) => {
    if (!isAnswered) return "";
    let renderText = "checked";

    if (type === QUESTION_TYPE.PULL_DOWN_SELECTION) {
      renderText = "selected";
    }

    return `${
      isAnswered && currentAnsweredByQuestion.answers.includes(answerId || 0)
        ? renderText
        : ""
    }`;
  };

  const renderExistedValueByAnswered = (yieldId?: string) => {
    if (!isAnswered || !yieldId) return "";
    const currentYield = currentAnsweredByQuestion.answers.find(
      (currentAnswered) =>
        typeof currentAnswered !== "number" &&
        currentAnswered.yield_code === yieldId
    );
    if (!currentYield || typeof currentYield === "number") return "";

    return currentYield.value;
  };

  let inputName = '';

  switch (type) {
    case QUESTION_TYPE.RADIO_BUTTON:
      inputName = generateQuestionInputName(type, id)
      result = `${answers
        .map((answer) => {
          const inputId = `radio_${answer.id}_${id}`;
          return `<div class="flex items-center gap-[10px] mb-[10px] font-[500] text-[14px] leading-[200%]"><input type="radio" name="${inputName}" id="${inputId}" value="${
            answer.id
          }" class="text-primary-light border border-success-extralight" ${
            options?.disabled && "disabled"
          } ${renderSelectedByAnswered(
            answer.id || 0
          )} /> <label for="${inputId}">${getContent(
            answer.content
          )}</label></div>`;
        })
        .join("")}`;

      break;
    case QUESTION_TYPE.CHECK_BOX:
      result = `${answers
        .map((answer) => {
          const inputId = `checkbox_${answer.id}_${id}`;
          const inputName = generateQuestionInputName(type, id, answer.id);
          return `<div class="flex items-center gap-[10px] mb-[10px] font-[500] text-[14px] leading-[200%]"><input type="checkbox" name="${inputName}" id="${inputId}" value="${
            answer.id
          }" ${options?.disabled && "disabled"} ${renderSelectedByAnswered(
            answer.id || 0
          )} /> <label for="${inputId}">${getContent(
            answer.content
          )}</label></div>`;
        })
        .join("")}`;

      break;
    case QUESTION_TYPE.PULL_DOWN_SELECTION:
      const groupedYield = groupArrayByProperty(answers, "yield_code");
      //create html string for each yield
      let groupedYieldHtml: { [key: string]: string } = {};
      !!Object.keys(groupedYield).length &&
        Object.keys(groupedYield).map((groupKey) => {
          const inputName = generateQuestionInputName(type, id, groupKey);

          return (groupedYieldHtml[groupKey] = `
            <select class="border-1 border-secondary-light rounded-none pl-[20px] pr-[35px] mx-[10px]" id="select_${groupKey}_${id}" name="${inputName}">
            ${
              !!groupedYield[groupKey].length &&
              groupedYield[groupKey].map(
                (option, index) =>
                  `<option value="${option.id}" ${index === 0 && "selected"} ${
                    options?.disabled && "disabled"
                  }
                  ${renderSelectedByAnswered(option.id || 0)}
                  >${option.content}</option>`
              )
            }
            </select>
          `);
        });

      //place content to yield
      let contentPullDown = title || "";
      for (const yieldCode in groupedYield) {
        contentPullDown = contentPullDown.replace(
          yieldCode,
          groupedYieldHtml[yieldCode]
        );
      }

      result = contentPullDown;
      break;

    case QUESTION_TYPE.IMAGE_SELECTION:
      inputName = generateQuestionInputName(type, id);

      result = `<div class="flex w-full flex-wrap justify-between">${answers
        .map((answer, index) => {
          const inputId = `radio_${answer.id}_${id}`;
          return `<div class="mb-[10px] font-[500] text-[14px] leading-[200%] mb-[20px]"><div class="flex items-center gap-[5px]"><div><input type="radio" name="${inputName}" id="${inputId}" value="${
            answer.id
          }" class="mb-[7px]" ${
            options?.disabled && "disabled"
          } ${renderSelectedByAnswered(
            answer.id || 0
          )} /></div><div class="font-[700[ text-[14px] leading-[100%] leading-[0.02em]"> ${
            KATAKANA_ALPHABET[index]
          }</div></div> <label for="${inputId}"><img alt="${
            answer.filename || answer.origin_filename
          }" src="${
            answer.resource_link || answer.answer_resource_link
          }" class="object-contain w-[210px] h-[170px]"/></label></div>`;
        })
        .join("")}</div>`;

      break;

    case QUESTION_TYPE.LISTENING:
      result = `${answers
        .map((answer) => {
          const inputId = `radio_${answer.id}_${id}`;
          const inputName = generateQuestionInputName(type, id);
          return `<div class="flex items-center gap-[10px] mb-[10px] font-[500] text-[14px] leading-[200%]"><input type="radio" name="${inputName}" id="${inputId}" value="${
            answer.id
          }" ${options?.disabled && "disabled"} ${renderSelectedByAnswered(
            answer.id || 0
          )} /> <label for="${inputId}">${getContent(
            answer.content
          )}</label></div>`;
        })
        .join("")}`;
      break;

    case QUESTION_TYPE.FILL_IN_BLANK:
      let contentFill = title || "";

      for (const answer of answers) {
        const inputId = `fill_${answer.id}_${id}`;
        const inputName = generateQuestionInputName(
          type,
          id,
          answer.yield_code
        );
        const yieldHtml = `<input type="text" id="${inputId}" name="${inputName}" class="mx-[10px] !border-1 !border-secondary-light !rounded-none pl-[20px] w-full max-w-[265px] min-h-[34px]" ${
          options?.disabled && "disabled"
        }
        value=${renderExistedValueByAnswered(answer?.yield_code)}
        >`;

        if (!answer.yield_code) continue;
        contentFill = contentFill.replace(answer.yield_code, yieldHtml);
      }

      result = contentFill;
      break;
    default:
      return "";
  }

  const essentialData = `<input type="hidden" name="question_id" value="${id}" />
  <input type="hidden" name="order" value="${order}" />
  <input type="hidden" name="type" value="${type}" />`;

  if (
    type === QUESTION_TYPE.PULL_DOWN_SELECTION ||
    type === QUESTION_TYPE.FILL_IN_BLANK
  )
    return `<div>${result}</div><div>${essentialData}</div>`;

  return `<div class="pl-[23px] pr-[55px]">
    <div class="mb-[22px] ${
      type === QUESTION_TYPE.LISTENING && "hidden"
    }">${title}</div>
    <div class="mb-[58px] overflow-y-auto pl-[4px] text-[14px] font-[500] leading-[200%]">${result}</div>
    <div>${essentialData}</div>
  </div>`;
};

const placeAnswerContent = (question: Question): string => {
  const { answers, type } = question;
  if (!answers || !answers.length) return "";

  //this will get only correct record of answers
  const correctAnswers = answers.filter((answer) => !!answer.is_true);

  let result: string;

  switch (type) {
    case QUESTION_TYPE.RADIO_BUTTON:
      result = `${correctAnswers
        .map((answer) => {
          return `<div class="flex items-center gap-[10px] mb-[10px] font-[500] text-[14px] leading-[200%]"><input type="radio" class="text-primary-light border border-success-extralight" disabled checked /> <label>${getContent(
            answer.content
          )}</label></div>`;
        })
        .join("")}`;

      break;
    case QUESTION_TYPE.CHECK_BOX:
      result = `${correctAnswers
        .map((answer) => {
          return `<div class="flex items-center gap-[10px] mb-[10px] font-[500] text-[14px] leading-[200%]"><input type="checkbox" disabled checked /> <label>${getContent(
            answer.content
          )}</label></div>`;
        })
        .join("")}`;

      break;
    case QUESTION_TYPE.PULL_DOWN_SELECTION:
      let contentPullDown = "";
      let indexCounter = 0;

      for (const answer of correctAnswers) {
        contentPullDown += `<div class="bg-white min-w-[450px] w-0 h-[30px] mb-[14px] text-[14px] font-[500] leading-[100%] tracking-[0.02em] flex"><div class="w-full max-w-[95px] h-full bg-danger-light flex items-center justify-center font-[700] text-[16px] leading-[100%]">${
          KATAKANA_ALPHABET[indexCounter]
        }</div><div class="pl-[21px] flex items-center">${getContent(
          answer.content
        )}</div></div>`;
        if (indexCounter < KATAKANA_ALPHABET.length - 1) indexCounter++;
      }

      result = contentPullDown;
      break;

    case QUESTION_TYPE.IMAGE_SELECTION:
      result = `<div class="flex w-full flex-wrap justify-between">${correctAnswers
        .map((answer, index) => {
          return `<div class="mb-[10px] font-[500] text-[14px] leading-[200%] mb-[20px]"><div class="flex items-center gap-[5px]"><div><input type="radio" value="${
            answer.id
          }" class="mb-[7px]" disabled checked /></div><div class="font-[700] text-[14px] leading-[100%] leading-[0.02em]"> ${
            KATAKANA_ALPHABET[index]
          }</div></div> <label for=""><img alt="${
            answer.filename || answer.origin_filename
          }" src="${
            answer.resource_link || answer.answer_resource_link
          }" class="object-contain w-[210px] h-[170px]"/></label></div>`;
        })
        .join("")}</div>`;

      break;

    case QUESTION_TYPE.LISTENING:
      result = `${correctAnswers
        .map((answer) => {
          return `<div class="flex items-center gap-[10px] mb-[10px] font-[500] text-[14px] leading-[200%]"><input class="text-primary-light border border-success-extralight" type="radio" disabled checked /><label>${getContent(
            answer.content
          )}</label></div>`;
        })
        .join("")}`;
      break;

    case QUESTION_TYPE.FILL_IN_BLANK:
      let contentFill = "";

      for (const answer of answers) {
        contentFill += `<div class="bg-white min-w-[450px] w-0 pl-[21px] h-[30px] mb-[14px]">${getContent(
          answer.content
        )}</div>`;
      }

      //addition text after print answer
      contentFill += `<div class="font-[500] text-[10px] leading-[190%] text-danger">入力した文字列との完全一致の場合のみ正解と判定されます。全角／半角の区別、漢字／カナの区別にご注意ください。</div>`;

      result = contentFill;
      break;
    default:
      return "";
  }

  return `<div class="mb-[20px]">${result}</div>
  <div>${question.explain || ""}</div>`;
};

const getHistoriesByType = (
  histories: TestPausedHistory[],
  type?: number
): Array<TestPausedHistory | TestHistory> => {
  if (!type || !histories || !histories.length) return [];

  return histories.filter((history) => history.test_type === type);
};

export {
  placeYieldQuestionContent,
  getAnswerByType,
  getHistoriesByType,
  placeAnswerContent,
};
