import React from "react";
import Chapter from "./Chapter";
import { ChapterList as ChapterListType } from "../../../../../types/student/lecture";

const ChapterList = ({
  chapterListData,
  waitingApi,
}: {
  chapterListData: ChapterListType;
  waitingApi: boolean;
}) => {
  return (
    <div className="w-full border border-danger-light rounded-[5px] mb-[15px] pt-[20px] px-[21px]">
      {!waitingApi ? (
        <div className="animate-[show_0.5s_ease-in-out]">
          <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
            {chapterListData.name || ""}
          </div>
          <div className="mb-[20px]">
            {chapterListData.chapters &&
              chapterListData.chapters.map((chapterInfo) => (
                <Chapter chapterData={chapterInfo} key={chapterInfo.id} />
              ))}
          </div>
        </div>
      ) : (
        <div className="skeleton w-full min-h-[150px] bg-secondary-lighter mb-[20px]"></div>
      )}
    </div>
  );
};

export default ChapterList;
