import StudentLayout from "../../../layouts/student";
import Title from "../../../components/student/MyPage/Title";
import CourseDetailList from "../../../components/student/Course/DetailList";

const CourseLists = () => {
  return (
    <StudentLayout gap>
      <Title content="受講コース一覧" />
        <CourseDetailList />
    </StudentLayout>
  );
};

export default CourseLists;