import React from "react";
import LearningType from "./Type";
import { RiArrowRightSLine } from "react-icons/ri";
import { Unit } from "../../../types/student/unit";
import { useNavigate } from "react-router-dom";
import { PATH_COURSE } from "../../../services/constants/route/router";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const LearningListItem = ({
  data,
  isMyPageTop,
}: {
  data: Unit;
  isMyPageTop?: boolean;
}) => {
  const { getParamValue } = useObjectRoutes();
  const courseId = getParamValue("course_id");
  const navigate = useNavigate();
  const handleRedirectToUnitPage = () => {
    isMyPageTop
      ? navigate(
          `${PATH_COURSE.UNIT_DETAIL}?unit_id=${data.id}&course_id=${data.course_id}`
        )
      : navigate(
          `${PATH_COURSE.UNIT_DETAIL}?unit_id=${data.id}&course_id=${courseId}`
        );
  };
  const { studentTheme } = useStudentThemeContext();

  return (
    <div
      className="flex justify-between h-[70px] pl-[18.5px] pt-[13px] pb-[14px] mt-[14px] border-b border-danger-light pr-[11.5px]
                max-sm:p-0 max-sm:max-h-[54px] max-sm:py-[10px]"
    >
      <div className="flex items-center gap-[12px]">
        <div className="">
          <LearningType type={data.type} />
        </div>
        <div className="max-w-[250px] max-sm:max-w-[190px] max-[320px]:max-w-[150px]">
          <div
            style={{ color: studentTheme.main_color_first }}
            className="font-[500] text-[14px] leading-[100%] text-primary mb-[7px] w-[100%] truncate cursor-pointer"
            onClick={handleRedirectToUnitPage}
          >
            {isMyPageTop
              ? `【${data.course_name || ""}】${data.name || ""}`
              : data.name || ""}
          </div>
          <div
            className={`text-[10px] leading-[140%] secondary-dark truncate text-[${studentTheme.text_color}]`}
          >
            {data.description || ""}
          </div>
        </div>
      </div>
      <div
        className="text-[10px] font-[500] leading-[100%] text-danger border border-danger flex items-center justify-center w-full max-w-[96px] h-[20px] mt-[5px] cursor-pointer
                  max-sm:max-w-[66px] max-sm:h-[34px] max-sm:p-[4px] max-sm:mt-0 max-sm:flex max-sm:flex-row max-sm:items-center max-sm:flex-wrap hover:bg-[#00000026]"
        onClick={handleRedirectToUnitPage}
      >
        <div className="max-sm:max-w-[40px]">学習中の単元へ</div>
        <div>
          <RiArrowRightSLine className="mr-[-2px]" size={15} />
        </div>
      </div>
    </div>
  );
};

export default LearningListItem;
