import { CourseItemType } from '../../../types/admin/course';
import { Object } from '../../../types/globals';
import { COURSE_TABLE_ITEM_TYPE } from '../../constants/admin/pages/course';

const getDataByParent = <T extends Object>(
  array?: Array<T>,
  id?: number,
  parentType?: CourseItemType,
): Array<T> => {
  if (!array || !array.length || id === undefined || !parentType) return [];
  return array.filter((item) => item[`${parentType}_id`] === id);
};

const getCountLabel = (itemType: CourseItemType) => {
  switch (itemType) {
    case COURSE_TABLE_ITEM_TYPE.COURSE:
      return `講義`;
    case COURSE_TABLE_ITEM_TYPE.LECTURE:
      return `章`;
    case COURSE_TABLE_ITEM_TYPE.CHAPTER:
      return `単元`;

    default:
      return '';
  }
};

export { getDataByParent, getCountLabel };
