import React from "react";
import "../../../assests/styles/skeleton.css";
import { WithChildren } from "../../../types/globals";

type LayoutWaitingApiType = {
  className?: string;
  waitingApi?: boolean;
} & WithChildren;

const LayoutWaitingApi = ({
  className,
  children,
  waitingApi,
}: LayoutWaitingApiType) => {
  return (
    <>
      {!waitingApi ? (
        <>{children}</>
      ) : (
        <div
          className={`skeleton bg-secondary-lighter mb-[20px] rounded-[5px] ${className}`}
        ></div>
      )}
    </>
  );
};

export default LayoutWaitingApi;
