import React from 'react';

type TableTitleProps = {
  subContent?: string;
  content?: string;
};

const TableTitle = ({ subContent, content }: TableTitleProps) => {
  return (
    <div className="text-[13px] font-[500] leading-[100%] text-secondary-dark">
      <div className="mt-[20px] pl-[10px] mb-[8px]">{subContent || ''}</div>
      <div className="min-h-[50px] w-full rounded-[5px] text-[18px] font-[700] leading-[100%] bg-primary text-white flex items-center pl-[21px]">
        {content || ''}
      </div>
    </div>
  );
};

export default TableTitle;
