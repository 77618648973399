import { FormEvent, useState, useEffect, ChangeEvent } from "react";
import {
  ElementProps,
  ErrorData,
  SetStateAction,
  ValueOf,
  WithChildren,
} from "../../../../types/globals";
import ToggleSwitch from "../../../commons/form/ToggleSwitch";
import dayjs from "../../../../services/dayjs";
import { EventSchedule, EventType } from "../../../../types/student/event";
import CalendarDatePicker from "../../Schedule/CalendarDatePicker";
import { MdOutlineNotificationsNone } from "react-icons/md";
import EventFormDatePicker from "./EventFormDatePicker";
import {
  SECTION_TYPE_EVENT_FORM_DATEPICKER,
  UNIT_EVENT_FORM_DATEPICKER,
} from "../../../../services/constants/student/event";
import { MESSAGE_ERROR } from "../../../../services/constants/message";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import { FiSave } from "react-icons/fi";
import { API } from "../../../../services/constants/route/api";
import { makeRequest, request } from "../../../../services/axios/axios";
import { isUsableArr } from "../../../../services/helpers/etc";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { PATH_STUDY_TOOL } from "../../../../services/constants/route/router";
import ErrorBox from "../../../commons/form/ErrorBox";
import { getCommonDate } from "../../../../services/helpers/date";
import CalendarDatePickerEvent from "./CalendarDatePickerEvent";
import { STUDENT_THEME_COLOR_DEFAULT } from "../../../../services/constants/admin/pages/student_theme";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type InputEventFormProps = {
  label: string;
};

export type ShowPickerStatus = {
  section?: ValueOf<typeof SECTION_TYPE_EVENT_FORM_DATEPICKER>;
  unit?: ValueOf<typeof UNIT_EVENT_FORM_DATEPICKER>;
};

type EventFormProps = {
  initSelectedDate: Date | string;
  events: EventSchedule[];
  setIsHandleEvent: React.Dispatch<React.SetStateAction<boolean>>;
};

const InputEventForm = ({
  label,
  children,
  ...props
}: WithChildren<ElementProps<HTMLDivElement> & InputEventFormProps>) => {
  return (
    <div {...props}>
      <div className="text-secondary border-b-[1px] border-success-extralight flex items-center justify-between mb-[10px] pb-[8px] focus:border-transparent">
        <div className="text-[12px] font-[400] leading-[100%]">{label}</div>
        <div className="">{children}</div>
      </div>
    </div>
  );
};

const EventForm = ({
  initSelectedDate,
  events,
  setIsHandleEvent,
}: EventFormProps) => {
  const { getParamValue } = useObjectRoutes();
  const [errors, setErrors] = useState<ErrorData>({});
  const [formData, setFormData] = useState<EventSchedule>({});
  const [showStatuses, setShowStatuses] = useState<ShowPickerStatus[]>([]);
  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [event, setEvent] = useState<EventSchedule>();
  const { navigate } = useObjectRoutes();
  const getEventId = getParamValue("event_id");
  const getState = getParamValue("state");
  const _24H_IN_MS = 82800000;
  const { studentTheme } = useStudentThemeContext();
  
  useEffect(() => {
    const fetch = async () => {
      await request.get(API.SCHEDULE.LIST_EVENT_TYPE, setEventTypes);
    };

    let prepareData: EventSchedule = {
      start_time: dayjs(initSelectedDate).startOf("day").format(),
      end_time: dayjs(initSelectedDate).endOf("day").format(),
      event_type_id: 0,
      note: ""
    };

    setFormData(prepareData);
    setErrors({});
    
    if (getEventId && events.length > 0) {
      const event = events.find((event) => event.id === Number(getEventId));
      if (event !== undefined) {
        setEvent(event);
        setFormData({
          ...formData,
          id: event.id,
          event_type_id: event.event_type?.id,
          is_notice: event.is_notice,
          start_time: event.start_time,
          end_time: event.end_time,
          note: event.note
        });
      }
    }

    fetch();
  }, [initSelectedDate, events]);

  const displayHandle = (
    section: ValueOf<typeof SECTION_TYPE_EVENT_FORM_DATEPICKER>,
    unit: ValueOf<typeof UNIT_EVENT_FORM_DATEPICKER>
  ): SetStateAction<ShowPickerStatus[]> | undefined => {
    if (!section || !unit) return;

    // for-current-specs - only start_time allowed
    if (section === "end" && unit === "date") return;

    //find existed showings data
    const existedStatusBySection = showStatuses.find(
      (status) => status.section === section
    );

    //if exited one, its will be remove, otherwise add new one
    if (existedStatusBySection) {
      let filteredStatuses = showStatuses.filter(
        (status) => status.section !== section
      );
      //only one picker each section
      if (existedStatusBySection.unit !== unit) {
        const newStatus = {
          section,
          unit,
        };

        filteredStatuses = [...filteredStatuses, newStatus];
      }
      setShowStatuses(filteredStatuses);
    } else {
      const newStatus = {
        section,
        unit,
      };

      setShowStatuses([...showStatuses, newStatus]);
    }
  };

  const formChangeHandle = (
    e?:
      | FormEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    if (!e || !e.currentTarget) return;

    const currentInput = e.currentTarget;
    let prepareData = formData;

    // checkbox handler
    switch (currentInput.type) {
      case "checkbox":
        prepareData = {
          ...prepareData,
          [currentInput.name]: (
            currentInput as FormEvent<HTMLInputElement>["currentTarget"]
          ).checked,
        };

        if (
          currentInput.name === "is_whole_day" &&
          !!(currentInput as FormEvent<HTMLInputElement>["currentTarget"])
            .checked
        ) {
          prepareData = {
            ...prepareData,
            start_time: dayjs(initSelectedDate).startOf("day").format(),
            end_time: dayjs(initSelectedDate).endOf("day").format(),
          };
        }

        break;
      case "time":
        // validate input is 'start_time' & 'end_time' for sure
        if (
          currentInput.name !== "start_time" &&
          currentInput.name !== "end_time"
        )
          return;

        // get current date, date format only (yyyy/MM/dd)
        // for-current-specs - only start_time allowed

        // const currentDate = formatDateTime(
        //   (formData[currentInput.name as keyof EventSchedule] as
        //     | string
        //     | number
        //     | Date) || new Date().toDateString(),
        //   TYPE_DATE_FORMAT.REGULAR_GLOBAL
        // );

        const currentDate = formatDateTime(
          formData["start_time"] || new Date().toDateString(),
          TYPE_DATE_FORMAT.REGULAR_GLOBAL
        );
        // end-for-current-specs

        // combine date with time
        prepareData = {
          ...prepareData,
          [currentInput.name]: dayjs(
            new Date(`${currentDate} ${currentInput.value}`)
          ).format(),
          // Set end time equal start time when choose start time.
          end_time: dayjs(
            new Date(`${currentDate} ${currentInput.value}`)
          ).format(),
        };
        break;

      default:
        prepareData = {
          ...prepareData,
          [currentInput.name]: currentInput.value,
        };

        break;
    }
    // set form data
    setFormData(prepareData);
  };

  const refHandle = (ref: React.RefObject<HTMLInputElement>) => {
    if (!ref || !ref.current) return alert(MESSAGE_ERROR.SOMETHINGS_WENT_WRONG);
    let prepareFormData = {
      ...formData,
      [ref.current.name]: ref.current.value,
    };

    // for-current-specs - only start_time allowed
    if (ref.current.name === "start_time") {
      const currentDate = formatDateTime(
        ref.current.value || new Date().toDateString(),
        TYPE_DATE_FORMAT.REGULAR_GLOBAL
      );

      const currentEndTime = formatDateTime(
        formData["end_time"] || new Date().toDateString(),
        TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H
      );

      prepareFormData = {
        ...prepareFormData,
        end_time: new Date(`${currentDate} ${currentEndTime}`),
      };
    }
    setFormData(prepareFormData);
  };

  const submitHandle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const result = await makeRequest({
      method: "post",
      url: API.SCHEDULE.UPSERT,
      data: formData,
    });

    if (!result.status) {
      setErrors(result.error ? result.error : {});
      return;
    }

    setIsHandleEvent(true);
    navigate(
      `${PATH_STUDY_TOOL.SCHEDULE.EVENT.DEFAULT}?date=${getParamValue("date")}`
    );
  };

  useEffect(() => {
    const styles = `
    .react-datepicker__day:hover {
      background: ${
        studentTheme.main_color_second || STUDENT_THEME_COLOR_DEFAULT.MAIN_2
      } !important;
      color: white;
    }
    .react-datepicker__day--today {
      background-color: ${
        studentTheme.main_color_second || STUDENT_THEME_COLOR_DEFAULT.MAIN_2
      } !important;
      border-radius: 0;
    }
    .react-datepicker__day--highlighted {
      background: ${
        studentTheme.sub_color_second || STUDENT_THEME_COLOR_DEFAULT.SUB_2
      } !important;
      border-radius: 0;
    }
    `;

    var styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }, []);

  return (
    <form className="pr-[20px]" onSubmit={submitHandle}>
      {!!Object.keys(errors).length && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}
      <div className="ml-[30px]">
        <div className="w-full max-w-[250px]">
          <InputEventForm label="終日">
            <ToggleSwitch
              onChange={formChangeHandle}
              inputName="is_whole_day"
              defaultChecked={
                event &&
                dayjs(event.end_time).diff(dayjs(event.start_time)) > _24H_IN_MS
              }
            />
          </InputEventForm>

          {/* start date */}
          <InputEventForm label="開始">
            <EventFormDatePicker
              dateInput={formData?.start_time}
              section={SECTION_TYPE_EVENT_FORM_DATEPICKER.START}
              onDisplayHandler={displayHandle}
              showStatuses={showStatuses}
              isWholeDay={formData.is_whole_day}
            />
          </InputEventForm>
          <div className="">
            {showStatuses.some(
              (status) =>
                status.section === SECTION_TYPE_EVENT_FORM_DATEPICKER.START &&
                status.unit === UNIT_EVENT_FORM_DATEPICKER.DATE
            ) && (
              <div className="border-b border-success-extralight pb-[10px] mb-[20px]">
                <CalendarDatePickerEvent
                  className="border border-secondary-extralight shadow-[0_4px_4px_0_rgba(123,123,123,0.25)] rounded-[5px] py-[12px] px-[13px] w-full max-w-[246px] focus:border-transparent focus:outline-none focus:ring-transparent"
                  initialDate={formData?.start_time}
                  onRefHandle={refHandle}
                  inputName="start_time"
                  setShowStatuses={setShowStatuses}
                />
              </div>
            )}
            {!formData?.is_whole_day &&
              showStatuses.some(
                (status) =>
                  status.section === SECTION_TYPE_EVENT_FORM_DATEPICKER.START &&
                  status.unit === UNIT_EVENT_FORM_DATEPICKER.TIME
              ) && (
                <div className="border-b border-success-extralight pb-[10px] mb-[20px]">
                  <input
                    type="time"
                    name="start_time"
                    id=""
                    onChange={formChangeHandle}
                    value={formatDateTime(
                      formData?.start_time,
                      TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H
                    )}
                  />
                </div>
              )}
          </div>

          {/* end date */}
          <InputEventForm label="終了">
            <EventFormDatePicker
              dateInput={formData?.end_time}
              section={SECTION_TYPE_EVENT_FORM_DATEPICKER.END}
              onDisplayHandler={displayHandle}
              showStatuses={showStatuses}
              isWholeDay={!!formData?.is_whole_day}
            />
          </InputEventForm>
          <div className="">
            {showStatuses.some(
              (status) =>
                status.section === SECTION_TYPE_EVENT_FORM_DATEPICKER.END &&
                status.unit === UNIT_EVENT_FORM_DATEPICKER.DATE
            ) && (
              <div className="border-b border-success-extralight pb-[10px] mb-[20px]">
                <CalendarDatePicker
                  className="border border-secondary-extralight` shadow-[0_4px_4px_0_rgba(123,123,123,0.25)] rounded-[5px] py-[12px] px-[13px] w-full max-w-[246px]"
                  initialDate={formData?.end_time || formData?.start_time} // make initial value counting from start date
                  onRefHandle={refHandle}
                  inputName="end_time"
                />
              </div>
            )}
            {!formData?.is_whole_day &&
              showStatuses.some(
                (status) =>
                  status.section === SECTION_TYPE_EVENT_FORM_DATEPICKER.END &&
                  status.unit === UNIT_EVENT_FORM_DATEPICKER.TIME
              ) && (
                <div className="border-b border-success-extralight pb-[10px] mb-[20px]">
                  <input
                    type="time"
                    name="end_time"
                    id=""
                    onChange={formChangeHandle}
                    value={formatDateTime(
                      formData?.end_time || formData?.start_time, // make initial value counting from start date
                      TYPE_DATE_FORMAT.TIME_HOUR_MINUTE_24H
                    )}
                  />
                </div>
              )}
          </div>
        </div>

        {/* event type */}
        <div className="mt-[10px]">
          <div className="text-secondary text-[10px] font-[500] leading-[120%] mb-[5px]">
            予定を登録
          </div>

          <div className="mt-[10px] flex justify-between">
            <div className="text-secondary">
              <div className="">
                <select
                  name="event_type_id"
                  id=""
                  className="text-[12px] font-[500] tracking-[0.24px] pr-[40px] border-[1.667px] border-danger-light rounded-[8.333px] bg-warning-lighter"
                  onChange={formChangeHandle}
                  value={formData.event_type_id}
                >
                  <option value="0" defaultValue="">
                    予定を選択する
                  </option>
                  {isUsableArr(eventTypes) &&
                    eventTypes.map((eventType) => (
                      <option value={eventType.id} key={eventType.id}>
                        {eventType.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="text-danger-light text-[24px] flex items-center gap-[5px]">
              <MdOutlineNotificationsNone />
              <ToggleSwitch
                onChange={formChangeHandle}
                inputName="is_notice"
                // defaultChecked={formData?.is_notice || event?.is_notice}
                isChecked={formData?.is_notice || false}
              />
              <div className="text-[12px] font-[400] leading-[100%] ml-[6.5px]">
                通知する
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* note */}
      <div className="mt-[40px] border-t border-success-extralight pt-[15px]">
        <div className="text-[12px] font-[500] leading-[100%] tracking-[0.24px] text-secondary">
          メモを追加
        </div>
        <div className="mt-[15px]">
          <textarea
            name="note"
            id=""
            className="w-full max-w-[420px] h-0 min-h-[135px] p-[10px]"
            onChange={formChangeHandle}
            value={formData?.note}
          ></textarea>
        </div>
      </div>

      <div className="flex justify-end items-center gap-[10px]">
        {getState === "edit" && (
          <button
            className="flex items-center h-[28px] px-[20px] font-[500] text-[12px] text-white bg-secondary-light rounded-[20px]"
            onClick={() =>
              navigate(
                `${PATH_STUDY_TOOL.SCHEDULE.EVENT.DEFAULT}?date=${getCommonDate(
                  event?.start_time
                )}`
              )
            }
          >
            キャンセル
          </button>
        )}
        <button
          style={{
            backgroundColor: studentTheme.main_color_first,
          }}
          className="my-[20px] bg-primary rounded-[20px] w-[90px] h-[30px] flex items-center justify-center text-white gap-[7px] text-[12px] font-[700] tracking-[0.24px]"
          type="submit"
        >
          <FiSave size={19} />
          保存
        </button>
      </div>
    </form>
  );
};

export default EventForm;
