import AWS, { S3 } from "aws-sdk";
import { ErrorData, SetStateAction } from "../../../types/globals";
import { makeRequest } from "../../axios/axios";
import { API } from "../../constants/route/api";
import { PREFIX_PATH_S3, SERVICE_ID } from "../../constants/globals";

const handleFileUpload = async (
    file: File,
    folderCode: number,
    folderId: string,
    setProcessPercent: SetStateAction<number>,
    setErrors: SetStateAction<ErrorData>
) => {
    const oz_id = JSON.parse(localStorage.getItem("user") || "").organization_id;
    const s3 = new S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    const fileName = `${Date.now()}.${fileExtension}`;
    const folderPath = PREFIX_PATH_S3.FEATURE + SERVICE_ID + "/" + PREFIX_PATH_S3.OZ + oz_id + `/${folderCode}/`;
    let contentType = "";
    switch (fileExtension) {
        case "mp4":
            contentType = "video/mp4";
            break;
        case "jpg":
        case "jpeg":
            contentType = "image/jpeg";
            break;
        case "png":
            contentType = "image/png";
            break;
        default:
            contentType = "application/octet-stream";
            break;
    }

    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

    AWS.config.update({
        accessKeyId,
        secretAccessKey,
    });

    const upload = new S3.ManagedUpload({
        params: {
            Bucket: process.env.REACT_APP_AWS_BUCKET || "",
            Key: folderPath + fileName,
            Body: file,
            ContentType: contentType,
            ACL: "public-read",
        },
    });

    upload.on("httpUploadProgress", (progress) => {
        const percentUploaded = (progress.loaded / progress.total) * 100;
        setProcessPercent(percentUploaded);
    });

    try {
        await upload.promise();
    } catch (error) {
        console.error("Error uploading file:", error);
    }

    let time = null;
    if (fileExtension === "mp3" || fileExtension === "mp4") {
        const mediaElement = document.createElement("audio");
        mediaElement.src = URL.createObjectURL(file);

        await new Promise<void>((resolve, reject) => {
            mediaElement.addEventListener("loadedmetadata", () => {
                time = Math.round(mediaElement.duration * 1000);
                resolve();
            });
            mediaElement.addEventListener("error", () => {
                reject();
            });
        });

        URL.revokeObjectURL(mediaElement.src);
    }

    const data = {
        resource_folder_id: folderId,
        filename: fileName,
        origin_filename: file.name,
        content_type: fileExtension,
        size: bytesToMegabytes(file.size),
        path: `${folderPath}${fileName}`,
    };

    if (time) {
        (data as any).time = time;
    }
    const result = await makeRequest({
        method: "post",
        url: API.ADMIN_FOLDER.UPLOAD_FILE,
        data,
    });

    if (!result.status) {
        setErrors(result.error ? result.error : {});
        await s3
            .deleteObject({
                Bucket: process.env.REACT_APP_AWS_BUCKET || "",
                Key: folderPath + fileName,
            })
            .promise();
        return null;
    }
    setErrors({});
    return result.data.id;
};

const bytesToMegabytes = (bytes: number) => {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
};

export { handleFileUpload }
