import React from "react";
import { isEmptyObj } from "../../../../services/helpers/etc";
import { useStudentUnitContext } from "../../../../context/StudentUnitContext";
import TestModeGroupItem from "./TestModeGroupItem";
import { UNIT_TEST_MODE_TYPE } from "../../../../services/constants/admin/pages/material";
import { TestHistory, TestPausedHistory } from "../../../../types/student/unit";
import { getHistoriesByType } from "../../../../services/utils/student/course";
import { Unit } from "../../../../types/admin/unit";

type TestModeGroupProps = {
  testPausedHistories: TestPausedHistory[];
  testHistories: TestHistory[];
  unitData: Unit;
};

const TestModeGroup = ({ testPausedHistories, testHistories, unitData }: TestModeGroupProps) => {
  if (isEmptyObj(unitData)) return <></>;

  return (
    <div className="w-full border border-danger-light rounded-[5px] mb-[15px] pt-[20px] px-[21px]">
      <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
        問題
      </div>
      <div className="flex gap-[12px] pb-[20px] justify-center">
        {UNIT_TEST_MODE_TYPE.map((typeObj) => (
          <React.Fragment key={typeObj.key}>
            {Object.keys(unitData).includes(typeObj.key) &&
              !!unitData[typeObj.key] && (
                <TestModeGroupItem
                  type={typeObj.id}
                  unit={unitData}
                  testPausedHistories={getHistoriesByType(testPausedHistories, typeObj.id)}
                  testHistories={getHistoriesByType(testHistories, typeObj.id)}
                />
              )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default TestModeGroup;
