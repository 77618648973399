import React, { useEffect, useState } from "react";
import TableTitle from "../../../commons/TableTitle";
import FormItem from "../../../commons/FormItem";
import RichTextEditor from "../../../../commons/form/TextEditor";
import {
  KATAKANA_ALPHABET,
  QUESTION_TYPE,
  UNIT_FILE_TYPE,
  UNIT_TEST_QUESTION_TYPE_DATA,
} from "../../../../../services/constants/admin/pages/material";
import { Question, QuestionAnswers } from "../../../../../types/admin/unit";
import PlusMinusButton from "../../../commons/PlusMinusButton";
import UploadQuestionImage from "../../../commons/UploadQuestionImage";
import BaseModal from "../../../commons/BaseModal";
import { API } from "../../../../../services/constants/route/api";
import { request } from "../../../../../services/axios/axios";
import { useNavigate } from "react-router-dom";
import { useObjectRoutes } from "../../../../../hooks/useObjectRoutes";
import { ErrorData } from "../../../../../types/globals";
import ErrorBox from "../../../../commons/form/ErrorBox";
import QuestionContent from "../../../../student/Course/ExecuteTest/QuestionContent";

const QuestionForm = ({
  setQuestionModal,
  resetQuestion,
  questionLength,
}: {
  setQuestionModal: React.Dispatch<React.SetStateAction<boolean>>;
  resetQuestion: () => void;
  questionLength: number;
}) => {
  const numbers = Array.from({ length: 99 }, (_, index) => index + 1);
  const navigate = useNavigate();
  const [questionFormData, setQuestionFormData] = useState<Question>({
    answers_data: [],
    type: QUESTION_TYPE.RADIO_BUTTON,
    answer_quantity: 1,
  });
  const [answersData, setAnswersData] = useState<QuestionAnswers[]>([
    { content: "", is_true: 0 },
  ]);
  const [answersGroupData, setAnswersGroupData] = useState<QuestionAnswers[][]>(
    []
  );
  const [errors, setErrors] = useState<ErrorData>({});
  const { getParamValue } = useObjectRoutes();
  const unitId = getParamValue("unit_id");
  const questionId = getParamValue("question_id");
  const [fileId, setFileId] = useState<number>(0);
  const [fileType, setFileType] = useState<number>(UNIT_FILE_TYPE.TYPE_IMAGE);
  const [isAttatchment, setIsAttatchment] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isPreviewOpenModal, setIsPreviewOpenModal] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState({});

  useEffect(() => {
    if (!questionId) return;
    const fetchDetail = async () => {
      await request.get(
        `${API.ADMIN_UNIT.QUESTION_DETAIL}?question_id=${questionId}`,
        (questionData) => {
          if (
            questionData.type === QUESTION_TYPE.PULL_DOWN_SELECTION ||
            questionData.type === QUESTION_TYPE.FILL_IN_BLANK
          ) {
            let filteredArray = questionData.answers;
            if (questionData.type === QUESTION_TYPE.PULL_DOWN_SELECTION) {
              filteredArray = questionData.answers.filter(
                (obj: { is_default: number }) => obj.is_default === 0
              );
            }
            setAnswersGroupData(
              filteredArray.reduce((acc: any[][], obj: { yield_code: any }) => {
                const index = acc.findIndex(
                  (arr) => arr[0]?.yield_code === obj.yield_code
                );
                if (index !== -1) {
                  acc[index].push(obj);
                } else {
                  acc.push([obj]);
                }
                return acc;
              }, [])
            );
          } else {
            setAnswersData(questionData.answers || []);
          }
          setQuestionFormData(questionData || {});
        }
      );
    };

    fetchDetail();
  }, [questionId]);

  const handleChange = (nameInput: string, content?: string) => {
    if (nameInput === "type") {
      if (Number(content) === QUESTION_TYPE.FILL_IN_BLANK) {
        setAnswersGroupData([
          [{ content: "", is_true: 1, yield_code: "$select1$" }],
        ]);
      } else if (Number(content) === QUESTION_TYPE.PULL_DOWN_SELECTION) {
        setAnswersGroupData([
          [{ content: "", is_true: 0, yield_code: "$select1$", is_default: 0 }],
        ]);
      } else {
        setAnswersData([{ content: "<p></p>", is_true: 0 }]);
      }
      setQuestionFormData({
        ...questionFormData,
        [nameInput]: content,
        answer_quantity: 1,
        answers_data: [],
      });
      return;
    }
    setQuestionFormData((prevFormData) => ({
      ...prevFormData,
      [nameInput]: content,
    }));
  };

  const handleAnswerChange = (index: number, content: string) => {
    setAnswersData((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = { ...updatedAnswers[index], content };
      return updatedAnswers;
    });
  };

  const handleCorrectAnswerChange = (index: number, yieldCode?: number) => {
    if (Number(questionFormData.type) === QUESTION_TYPE.PULL_DOWN_SELECTION) {
      setAnswersGroupData((prevGroups) => {
        const updatedGroups = prevGroups.map((group, groupIndex) => {
          if (groupIndex === Number(yieldCode)) {
            return group.map((answer, answerIndex) => {
              if (answerIndex === index) {
                return { ...answer, is_true: 1 };
              } else {
                return { ...answer, is_true: 0 };
              }
            });
          }
          return group;
        });
        return updatedGroups;
      });
    } else {
      setAnswersData((prevAnswers) => {
        const updatedAnswers = prevAnswers.map((answer, i) => {
          if (i === index) {
            return { ...answer, is_true: 1 };
          } else {
            return { ...answer, is_true: 0 };
          }
        });
        return updatedAnswers;
      });
    }
  };

  const handleCheckboxChange = (index: number, checked: number) => {
    setAnswersData((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index].is_true = checked;
      return updatedAnswers;
    });
  };
  const handleGroupAnswerChange = (
    yieldCode: number,
    index: number,
    content: string
  ) => {
    setAnswersGroupData((prevGroup) => {
      const updatedGroupData = [...prevGroup];
      updatedGroupData[yieldCode][index] = {
        ...updatedGroupData[yieldCode][index],
        content,
      };
      return updatedGroupData;
    });
  };

  const handleCreateAnswer = (yieldCode: number, isTrue?: boolean) => {
    const updatedGroupData = [...answersGroupData];
    if (Number(questionFormData.type) === QUESTION_TYPE.PULL_DOWN_SELECTION) {
      updatedGroupData[yieldCode].push({
        content: "",
        is_true: isTrue ? 1 : 0,
        yield_code: `$select${yieldCode + 1}$`,
        is_default: 0,
      });
    } else {
      updatedGroupData[yieldCode].push({
        content: "",
        is_true: isTrue ? 1 : 0,
        yield_code: `$select${yieldCode + 1}$`,
      });
    }
    setAnswersGroupData(updatedGroupData);
  };

  const handleDeleteAnswer = (yieldCode: number, index: number) => {
    const updatedGroupData = [...answersGroupData];
    updatedGroupData[yieldCode].splice(index, 1);
    setAnswersGroupData(updatedGroupData);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = parseInt(event.target.value);
    if (
      Number(questionFormData.type) === QUESTION_TYPE.PULL_DOWN_SELECTION ||
      Number(questionFormData.type) === QUESTION_TYPE.FILL_IN_BLANK
    ) {
      setAnswersGroupData((prevGroups) => {
        const currentAnswersCount = prevGroups.length;
        const updatedGroups = prevGroups.slice(0, selectedValue);
        if (selectedValue > currentAnswersCount) {
          const additionalAnswersCount = selectedValue - currentAnswersCount;
          for (let i = 0; i < additionalAnswersCount; i++) {
            if (
              Number(questionFormData.type) ===
              QUESTION_TYPE.PULL_DOWN_SELECTION
            ) {
              updatedGroups.push([
                {
                  content: "",
                  is_true: 0,
                  yield_code: `$select${prevGroups.length + i + 1}$`,
                  is_default: 0,
                },
              ]);
            } else {
              updatedGroups.push([
                {
                  content: "",
                  is_true: 1,
                  yield_code: `$select${prevGroups.length + i + 1}$`,
                },
              ]);
            }
          }
        }
        return updatedGroups;
      });
    } else {
      setAnswersData((prevAnswers) => {
        const currentAnswersCount = prevAnswers.length;
        const updatedAnswers = prevAnswers.slice(0, selectedValue);

        if (selectedValue > currentAnswersCount) {
          const additionalAnswersCount = selectedValue - currentAnswersCount;
          for (let i = 0; i < additionalAnswersCount; i++) {
            updatedAnswers.push({ content: "<p></p>", is_true: 0 });
          }
        }
        return updatedAnswers;
      });
    }
    setQuestionFormData({
      ...questionFormData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    let submitData = {
      ...questionFormData,
      answers: answersData,
      unit_id: unitId,
      id: "preview",
      type: Number(questionFormData.type),
    };
    if (
      Number(questionFormData.type) === QUESTION_TYPE.FILL_IN_BLANK ||
      Number(questionFormData.type) === QUESTION_TYPE.PULL_DOWN_SELECTION
    ) {
      submitData = { ...submitData, answers: answersGroupData.flat() };
    }
    setPreviewData(submitData);
  }, [isPreviewOpenModal]);

  const handleSubmit = async () => {
    let endpoint = API.ADMIN_UNIT.CREATE_TEST_QUESTION;
    let submitAction = request.post;
    let submitData = {
      ...questionFormData,
      answers_data: answersData,
      unit_id: unitId,
    };
    if (Number(questionFormData.type) === QUESTION_TYPE.FILL_IN_BLANK) {
      submitData = { ...submitData, answers_data: answersGroupData.flat() };
    }
    if (Number(questionFormData.type) === QUESTION_TYPE.PULL_DOWN_SELECTION) {
      const newAnswerGroupData = answersGroupData.map((innerArray, index) => [
        {
          content: generateKatakanaLabel(index + 1),
          is_true: 0,
          yield_code: innerArray[0].yield_code,
          is_default: 1,
        },
        ...innerArray,
      ]);
      submitData = { ...submitData, answers_data: newAnswerGroupData.flat() };
    }
    if (questionId) {
      submitAction = request.patch;
      endpoint = API.ADMIN_UNIT.UPDATE_TEST_QUESTION;
      submitData = { ...submitData, question_id: questionFormData.id };
    }
    await submitAction(
      endpoint,
      submitData,
      () => {
        setQuestionFormData({
          answers_data: [],
          type: 1,
          answer_quantity: 1,
        });
        resetQuestion();
        setQuestionModal(false);
      },
      setErrors,
      { withSuccess: false, withLoading: false }
    );
  };

  const generateKatakanaLabel = (number: number) => {
    if (number <= KATAKANA_ALPHABET.length) {
      return KATAKANA_ALPHABET[number - 1];
    } else {
      const repeatCount = Math.floor((number - 1) / KATAKANA_ALPHABET.length);
      const mainKatakana =
        KATAKANA_ALPHABET[(repeatCount - 1) % KATAKANA_ALPHABET.length];
      const subKatakana =
        KATAKANA_ALPHABET[(number - 1) % KATAKANA_ALPHABET.length];
      return mainKatakana + subKatakana;
    }
  };

  const answersContent = (() => {
    switch (Number(questionFormData.type)) {
      case QUESTION_TYPE.RADIO_BUTTON:
        return (
          <FormItem label="解答項目・正解" withRequired>
            <div className="text-[12px] font-[400] mb-[10px] ml-[12.5px]">
              正解<span className="ml-[32px]">解答項目</span>
            </div>
            {answersData.map((_, index) => (
              <div
                key={index}
                className="flex items-center mb-[16px] ml-[15.5px] gap-[30px]"
              >
                <input
                  onChange={() => handleCorrectAnswerChange(index)}
                  type="radio"
                  name="answers"
                  value={index + 1}
                  checked={answersData[index].is_true === 1}
                  className="form-radio text-primary-light border border-success-extralight mr-2"
                />
                <RichTextEditor
                  value={answersData[index]?.content || ""}
                  changeHandler={(content) =>
                    handleAnswerChange(index, content)
                  }
                  placeholder="解答を入力してください"
                  toolbarId={`tool${index}`}
                  className=""
                />
              </div>
            ))}
          </FormItem>
        );
      case QUESTION_TYPE.CHECK_BOX:
        return (
          <FormItem label="解答項目・正解" withRequired>
            <div className="text-[12px] font-[400] mb-[10px] ml-[12.5px]">
              正解<span className="ml-[32px]">解答項目</span>
            </div>
            {answersData.map((_, index) => (
              <div
                key={index}
                className="flex items-center mb-[16px] ml-[15.5px] gap-[30px]"
              >
                <input
                  type="checkbox"
                  name="answers"
                  value={index + 1}
                  checked={answersData[index].is_true === 1}
                  onChange={(event) =>
                    handleCheckboxChange(index, Number(event.target.checked))
                  }
                />
                <RichTextEditor
                  value={answersData[index]?.content || ""}
                  changeHandler={(content) =>
                    handleAnswerChange(index, content)
                  }
                  placeholder="解答を入力してください"
                  toolbarId={`tool${index}`}
                  className=""
                />
              </div>
            ))}
          </FormItem>
        );
      case QUESTION_TYPE.FILL_IN_BLANK:
        return (
          <FormItem label="解答項目・正解" withRequired>
            {answersGroupData.map((_, yieldCode) => (
              <>
                <div
                  key={yieldCode}
                  className="flex border-y-[1px] border-secondary-light text-[14px] text-secondary-dark font-[400] max-w-[180px] mb-[22px] mt-[15px]"
                >
                  <div className="bg-[#E1E3E2] pl-[12px] py-[3px] pr-[7px] whitespace-nowrap">
                    解答欄
                  </div>
                  <div className="bg-white px-[11px] py-[3px]">
                    {generateKatakanaLabel(yieldCode + 1)}
                  </div>
                  <div className="bg-secondary-extralight px-[10px] py-[3px]">{`$select${
                    yieldCode + 1
                  }$`}</div>
                </div>
                <div className="text-[12px] font-[400] mb-[10px] ml-[12.5px]">
                  正解<span className="ml-[32px]">解答項目</span>
                </div>
                {answersGroupData[yieldCode].map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center mb-[16px] ml-[14px] gap-[30px]"
                  >
                    <PlusMinusButton
                      clickHandler={() => handleDeleteAnswer(yieldCode, index)}
                      className="bg-secondary"
                      type={0}
                    />
                    <div className="text-[13px] leading-[19px] font-[500] text-secondary-dark">
                      <input
                        className="flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[700px]"
                        name="content"
                        value={
                          answersGroupData[yieldCode][index]?.content || ""
                        }
                        onChange={(event) =>
                          handleGroupAnswerChange(
                            yieldCode,
                            index,
                            event.target.value
                          )
                        }
                      />
                      <div className="text-danger">
                        入力した文字列との完全一致の場合のみ正解と判定されます。全角／半角の区別、漢字／カナの区別にご注意ください。
                      </div>
                    </div>
                  </div>
                ))}
                <div className="w-full flex items-center justify-center gap-[17px] mb-[12.5px]">
                  <PlusMinusButton
                    clickHandler={() => handleCreateAnswer(yieldCode, true)}
                    className="bg-secondary"
                    type={1}
                  />
                  <div>他の解答候補を登録する</div>
                </div>
              </>
            ))}
          </FormItem>
        );
      case QUESTION_TYPE.IMAGE_SELECTION:
        return (
          <FormItem label="解答項目・正解" withRequired>
            <div className="text-[14px] text-[500] text-secondary-dark mb-[29px] ml-[10px]">
              画像をアップロードしてください（.jpgまたは.png形式／最大1MB）
            </div>
            <div className="text-[12px] font-[400] mb-[10px] ml-[10px]">
              選択肢<span className="ml-[40px]">正解</span>
              <span className="ml-[25px]">解答項目</span>
            </div>
            {answersData.map((_, index) => (
              <div
                key={index}
                className="flex items-center mb-[16px] gap-[25px] ml-[15px]"
              >
                <div className="w-full max-w-[50px]">
                  {generateKatakanaLabel(index + 1)}
                </div>
                <input
                  onChange={() => handleCorrectAnswerChange(index)}
                  type="radio"
                  name="answers"
                  value={index + 1}
                  checked={answersData[index].is_true === 1}
                  className="form-radio text-primary-light border border-success-extralight mr-2"
                />
                <div className="flex w-full">
                  {answersData[index].answer_resource_link ? (
                    <img
                      className="w-[330px] object-cover mr-4 border-gray-200 my-[5px]"
                      src={answersData[index].answer_resource_link}
                    />
                  ) : (
                    <div
                      className="w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px] rounded-[5px]"
                      onClick={() => {
                        setFileType(UNIT_FILE_TYPE.TYPE_IMAGE);
                        setIsAttatchment(false);
                        setFileId(index);
                        setIsOpenModal(true);
                      }}
                    ></div>
                  )}
                  <div
                    className={`flex ${
                      answersData[index].origin_filename
                        ? "self-start"
                        : "items-center"
                    } mt-[3px] bg-secondary-extralight min-h-[32px] w-auto pl-[10.5px] pr-[46px] py-[6px] rounded-[5px]`}
                    onClick={() => {
                      setFileType(UNIT_FILE_TYPE.TYPE_IMAGE);
                      setIsAttatchment(false);
                      setFileId(index);
                      setIsOpenModal(true);
                    }}
                  >
                    <label className="flex items-center justify-center w-full max-w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE] px-[6px]">
                      ファイルを選択
                    </label>
                    {!answersData[index].origin_filename ? (
                      <div className="ml-[10px] text-[10px] whitespace-nowrap">
                        選択されていません
                      </div>
                    ) : (
                      <div className="ml-[10px] text-[10px]">
                        {answersData[index].origin_filename}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </FormItem>
        );
      case QUESTION_TYPE.LISTENING:
        return (
          <>
            <FormItem label="音声ファイル" withRequired>
              <div
                className="flex items-center mt-[3px] bg-secondary-extralight h-[32px] max-w-[280px] w-full pl-[10.5px] rounded-[5px]"
                onClick={() => {
                  setFileType(UNIT_FILE_TYPE.TYPE_AUDIO);
                  setIsAttatchment(true);
                  setIsOpenModal(true);
                }}
              >
                <label className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-[#BEBEBE]">
                  ファイルを選択
                </label>
                {!questionFormData.origin_filename ? (
                  <div className="ml-[10px] text-[10px]">
                    選択されていません
                  </div>
                ) : (
                  <div className="ml-[10px] text-[10px]">
                    {questionFormData.origin_filename}
                  </div>
                )}
              </div>
            </FormItem>
            <FormItem label="解答項目・正解" withRequired>
              <div className="text-[12px] font-[400] mb-[10px] ml-[15px]">
                正解<span className="ml-[30px]">解答項目</span>
              </div>
              {answersData.map((_, index) => (
                <div
                  key={index}
                  className="flex items-center mb-[16px] ml-[15px] gap-[30px]"
                >
                  <input
                    onChange={() => handleCorrectAnswerChange(index)}
                    type="radio"
                    name="answers"
                    value={index + 1}
                    checked={answersData[index].is_true === 1}
                    className="form-radio text-primary-light border border-success-extralight mr-2"
                  />
                  <RichTextEditor
                    value={answersData[index]?.content || ""}
                    changeHandler={(content) =>
                      handleAnswerChange(index, content)
                    }
                    placeholder="解答を入力してください"
                    toolbarId={`tool${index}`}
                    className=""
                  />
                </div>
              ))}
            </FormItem>
          </>
        );
      case QUESTION_TYPE.PULL_DOWN_SELECTION:
        return (
          <FormItem label="解答項目・正解" withRequired>
            {answersGroupData.map((_, yieldCode) => (
              <>
                <div
                  key={yieldCode}
                  className="flex border-y-[1px] border-secondary-light text-[14px] text-secondary-dark font-[400] max-w-[250px] mb-[22px] mt-[15px]"
                >
                  <div className="bg-[#E1E3E2] pl-[12px] py-[3px] pr-[7px] whitespace-nowrap">
                    プルダウンリスト
                  </div>
                  <div className="bg-white px-[11px] py-[3px]">
                    {generateKatakanaLabel(yieldCode + 1)}
                  </div>
                  <div className="bg-secondary-extralight px-[10px] py-[3px]">{`$select${
                    yieldCode + 1
                  }$`}</div>
                </div>
                <div className="text-[12px] font-[400] mb-[10px] ml-[10px]">
                  選択肢<span className="ml-[20px]">正解</span>
                  <span className="ml-[25px]">解答項目</span>
                </div>
                {answersGroupData[yieldCode].map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center mb-[16px] ml-[14px] gap-[30px]"
                  >
                    {answersGroupData[yieldCode].length === 1 ||
                    index === answersGroupData[yieldCode].length - 1 ? (
                      <PlusMinusButton
                        label="+"
                        clickHandler={() => handleCreateAnswer(yieldCode)}
                        className="bg-secondary"
                        type={1}
                      />
                    ) : (
                      <PlusMinusButton
                        label="-"
                        clickHandler={() =>
                          handleDeleteAnswer(yieldCode, index)
                        }
                        className="bg-secondary"
                        type={0}
                      />
                    )}
                    <input
                      onChange={() =>
                        handleCorrectAnswerChange(index, yieldCode)
                      }
                      type="radio"
                      name={`answers${yieldCode}`}
                      value={index + 1}
                      checked={answersGroupData[yieldCode][index].is_true === 1}
                      className="form-radio text-primary-light border border-success-extralight"
                    />
                    <RichTextEditor
                      value={answersGroupData[yieldCode][index]?.content || ""}
                      changeHandler={(content) =>
                        handleGroupAnswerChange(yieldCode, index, content)
                      }
                      placeholder="解答を入力してください"
                      toolbarId={`tool${yieldCode}${index}`}
                      className=""
                    />
                  </div>
                ))}
              </>
            ))}
          </FormItem>
        );
      default:
        return <div className="w-full max-w-[500px] h-[283px]"></div>;
    }
  })();

  return (
    <div className="bg-white rounded-[10px] w-full min-h-[100px] pb-[50px] ">
      <div className="pt-[30px] px-[28px] mb-[20px]">
        <div className="border-b border-secondary-light text-secondary-dark pb-[14px] font-[500] text-[16px] leading-[100%]">
          教材設定 ｜ 教材・テストの新規登録
        </div>
      </div>

      <div className="mx-[43px]">
        <TableTitle
          subContent="選択中の設問"
          content={`問題${questionFormData.order || questionLength}`}
        />
        {errors && !!Object.keys(errors).length && (
          <div className="mb-[20px]">
            <ErrorBox errors={errors} />
          </div>
        )}
        <div className="text-[14px] font-[500] leading-[100%] mt-[40px] mb-[3px]">
          基本情報
        </div>
        <FormItem label="出題形式" withRequired>
          <select
            name="type"
            className="w-[320px]"
            value={questionFormData.type}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            {UNIT_TEST_QUESTION_TYPE_DATA.map((questionType, index) => (
              <option key={index} value={questionType.value}>
                {questionType.label}
              </option>
            ))}
          </select>
        </FormItem>
        <FormItem label="設問内容" withRequired>
          <div className="flex">
            <RichTextEditor
              value={questionFormData.title}
              changeHandler={(content) => handleChange("title", content)}
              toolbarId="t1"
              className="h-[100px]"
              width="w-full"
              placeholder="設問内容（問題文）を入力してください"
            />
          </div>
        </FormItem>
        <FormItem label="解答項目数" withRequired>
          <select
            name="answer_quantity"
            className="w-[80px] h-[30px] border border-success-lighter rounded-[5px] px-2"
            onChange={handleSelectChange}
            value={questionFormData.answer_quantity}
          >
            {numbers.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </FormItem>
        <FormItem label="配点" withRequired>
          <div className="flex items-center gap-[6px] text-[12px] font-[400]">
            <input
              type="number"
              name="score"
              value={questionFormData.score}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              id=""
              placeholder="00"
              className="w-full max-w-[90px] text-left placeholder:text-secondary-light"
            />
            点
          </div>
        </FormItem>
        {answersContent}
        <FormItem label="解説文" withOptional>
          <RichTextEditor
            value={questionFormData.explain}
            changeHandler={(content) => handleChange("explain", content)}
            toolbarId="t2"
            className="h-[100px]"
            placeholder="設問内容（問題文）を入力してください"
          />
        </FormItem>
        <div className="w-full flex justify-center items-center mb-[58px] mt-[86px] gap-[19px]">
          <button
            type="button"
            className="min-w-[180px] bg-[#BEBEBE] px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
            onClick={() => setQuestionModal(false)}
          >
            キャンセル
          </button>
          <button
            type="button"
            className="min-w-[228px] bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]"
            onClick={() => setIsPreviewOpenModal(true)}
          >
            プレビュー
          </button>
          <button
            type="button"
            className="min-w-[132px] bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%] max-h-[43px] flex items-center"
            onClick={() => handleSubmit()}
          >
            <div className="">登録する</div>
          </button>
        </div>
      </div>
      {isOpenModal && (
        <BaseModal
          setIsOpen={setIsOpenModal}
          width={1010}
          height={718}
          justifyDirection="end"
        >
          <UploadQuestionImage
            fileType={fileType}
            setIsOpen={setIsOpenModal}
            setAnswers={setAnswersData}
            setQuestionForm={setQuestionFormData}
            isAttatchment={isAttatchment}
            index={fileId}
          />
        </BaseModal>
      )}
      {isPreviewOpenModal && (
        <BaseModal
          setIsOpen={setIsPreviewOpenModal}
          width={1010}
          height={400}
          justifyDirection="end"
          withSubmit={false}
        >
          <QuestionContent
            setIsOpen={setIsPreviewOpenModal}
            question={previewData as any}
            currentStep={(questionFormData.order || questionLength) - 1}
          />
        </BaseModal>
      )}
    </div>
  );
};

export default QuestionForm;
