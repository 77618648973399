import { ChangeEvent, useEffect, useState } from "react";
import Button from "../components/Login/Button";
import HelpCircleIcon from "../components/Login/HelpCircleIcon";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { PATH_ADMIN_SECTION, ROUTE } from "../services/constants/route/router";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalClose, swalError, swalMessage } from "../services/helpers/swal";
import AuthLayout from "../layouts/AuthLayout";
import { getSubdomainFromUrl } from "../services/helpers/domain";
import { useAuthContext } from "../context/AuthContext";
import { ErrorData } from "../types/globals";
import ErrorBox from "../components/commons/form/ErrorBox";
import { LoginSocialFacebook } from "reactjs-social-login";
import { socialLogin } from "../services/auth/socialLogin";
import { MESSAGE_ERROR } from "../services/constants/message";
import SNSButton from "../components/Login/SNSButton";
import FacebookLoginIcon from "../components/commons/icons/FacebookLoginIcon";
import GoogleIcon from "../components/commons/icons/GoogleIcon";
import { StudentThemeType } from "../types/admin/student_theme";
import { useLoginSettingContext } from "../context/LoginSettingContext";

const Login = () => {
  const [dataLogin, setDataLogin] = useState({
    username: "",
    password: "",
    remember_me: 0,
  });
  let navigate = useNavigate();
  let { setUser } = useAuthContext();
  const [errors, setErrors] = useState<ErrorData>({});
  // check token for remember or has been login before (session)
  let accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const [token, setToken] = useState(accessToken);
  const [studentThemeColor, setStudentThemeColor] = useState<StudentThemeType>(
    {}
  );
  const { studentTheme, fetching } = useLoginSettingContext();
  const [isSubmitting, setIsDisabled] = useState(false);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "remember_me") {
      setDataLogin({ ...dataLogin, [name]: Number(e.target.checked) });
      return;
    }

    setDataLogin({ ...dataLogin, [name]: value });
  };
  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsDisabled(true);
    let result = await makeRequest({
      method: "post",
      url: API.AUTH.LOGIN,
      data: {
        ...dataLogin,
        subdomain: getSubdomainFromUrl(),
      },
    });
    swalClose();

    if (result.status && result.data.token) {
      setIsDisabled(false);
      localStorage.setItem("accessToken", result.data.token);
      localStorage.setItem("user", JSON.stringify(result.data.user));
      setUser(result.data.user);
      if (result.data.user.is_student) {
        // window.location.href = ROUTE.MY_PAGE.DEFAULT;
        navigate(ROUTE.MY_PAGE.DEFAULT);
      } else {
        navigate(PATH_ADMIN_SECTION.DEFAULT);
      }
      return;
    }

    setIsDisabled(false);
    let error = result.error;
    setErrors(error || {});
    swalError(result.addition_message || "", 3000);
  };

  useEffect(() => {
    if (!fetching) {
      setStudentThemeColor(studentTheme);
    }
  }, [fetching]);

  return (
    <>
      {token ? (
        <Navigate to={ROUTE.MY_PAGE.DEFAULT} replace={true} />
      ) : (
        <AuthLayout>
          <>
            <form
              style={{ color: studentThemeColor.text_color }}
              className="max-sm:w-full mb-[42px] mt-[30px] mx-auto max-sm:mt-[25px] max-sm:px-[10px] max-sm:mb-[32px] animate-[show_0.5s_ease-in-out]"
              onSubmit={handleLogin}
            >
              <div className="sm:w-[450px] max-sm:w-full w-[370px] mb-[30px] max-sm:mb-[25px] bg-[#F8F7F6] px-[25px] border border-warning-light">
                <p className="mt-[30px] max-sm:mt-[25px] mb-[20px] text-[15px] text-[500]">
                  ログイン
                </p>
                {errors && !!Object.keys(errors).length && (
                  <div className="mb-[20px] text-left">
                    <ErrorBox errors={errors} />
                  </div>
                )}
                <div className="mb-[20px]">
                  <input
                    type="text"
                    name="username"
                    className="min-h-[50px] border border-warning-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F] placeholder:text-sm"
                    placeholder="メールアドレス　または　ログインID"
                    required
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="mb-[15px] max-sm:mb-[20px]">
                  <input
                    type="password"
                    placeholder="パスワード"
                    onChange={handleChangeInput}
                    name="password"
                    className="min-h-[50px] border border-warning-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F]"
                    required
                  />
                </div>
                <div className="flex items-center text-[12px] mb-[20px]">
                  <input
                    id="remember"
                    type="checkbox"
                    name={"remember_me"}
                    onChange={handleChangeInput}
                    className="w-[15px] h-[15px] border border-warning-light rounded"
                  />
                  <label
                    htmlFor="remember"
                    className="ml-2 text-[#5A5A5A] dark:text-gray-300 max-sm:text-[#5A5A5A] max-sm:fornt-[400]"
                  >
                    ログイン状態を保持
                  </label>
                </div>
              </div>

              <Button
                style={{ backgroundColor: studentThemeColor.main_color_first }}
                content={"ログイン"}
                otherstyle={"bg-primary text-white max-sm:text-[18px]"}
                type="submit"
                isSubmitting={isSubmitting}
              />

              <Link to={ROUTE.FORGOT_PASSWORD}>
                <div className="flex justify-center items-center mt-[16px] max-sm:mt-[18px]">
                  <HelpCircleIcon color={studentThemeColor.main_color_first} />
                  <p className="ml-[10px] text-[14px] align-middle">
                    パスワードをお忘れの方
                  </p>
                </div>
              </Link>
            </form>
            {/* Social Account Login hidden for future phases */}
            <p className="hidden mb-[20px] text-[0.94rem]">
              ほかのアカウントでログインする
            </p>

            <div className="hidden w-full justify-center">
              <LoginSocialFacebook
                isOnlyGetToken={true}
                appId={`${process.env.REACT_APP_FB_APP_ID}`}
                onReject={(err) => {
                  swalMessage("Error", "Error", "error");
                }}
                onResolve={async (data) => {
                  if (data.data) {
                    let result = (await socialLogin(
                      data.provider,
                      data.data.accessToken
                    )) as any;
                    if (result && result.status) {
                      if (result.data.token) {
                        localStorage.setItem("accessToken", result.data.token);
                        localStorage.setItem(
                          "user",
                          JSON.stringify(result.data.user)
                        );
                        setUser(result.data.user);
                        if (result.data.user.is_student) {
                          navigate(ROUTE.MY_PAGE.DEFAULT);
                        } else {
                          navigate(PATH_ADMIN_SECTION.DEFAULT);
                        }
                      } else {
                        swalMessage(
                          "Error",
                          MESSAGE_ERROR.SOMETHINGS_WENT_WRONG,
                          "error"
                        );
                      }
                    } else {
                      swalMessage("Error", result.message, "error");
                    }
                  }
                }}
              >
                <SNSButton>
                  <>
                    <FacebookLoginIcon className={"block mx-auto"} />
                    <span className="text-[7.5px] max-sm:text-secondary-dark max-sm:font-[400]">
                      facebookアカウント
                    </span>
                  </>
                </SNSButton>
              </LoginSocialFacebook>
              {/* <LoginSocialGoogle
                  isOnlyGetToken={true}
                  client_id={`${process.env.REACT_APP_GG_APP_ID}`}
                  onReject={err => {
                    swalMessage('Error', 'Error', 'error');
                  }}
                  onResolve={async data => {
                    if (data.data) {
                      let result = await socialLogin(data.provider, data.data.access_token) as any;
                      if (result && result.status) {
                        if (result.data.token) {
                          localStorage.setItem('accessToken', result.data.token);
                          localStorage.setItem('user', JSON.stringify(result.data.user));
                          setUser(result.data.user);
                          if (result.data.user.is_student) {
                            navigate(ROUTE.MY_PAGE.DEFAULT);
                          } else {
                            navigate(PATH_ADMIN_SECTION.DEFAULT);
                          }
                        } else {
                          swalMessage('Error', MESSAGE_ERROR.SOMETHINGS_WENT_WRONG, 'error');
                        }
                      } else {
                        swalMessage('Error', result.message, 'error');
                      }
                    }
                  }}
                > */}
              <SNSButton>
                <>
                  <GoogleIcon className={"block mx-auto"} />
                  <span className="text-[7.5px] max-sm:text-secondary-dark max-sm:font-[400]">
                    googleアカウント
                  </span>
                </>
              </SNSButton>
              {/* </LoginSocialGoogle>  */}
            </div>
          </>
        </AuthLayout>
      )}
    </>
  );
};

export default Login;
