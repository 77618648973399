import React, { useEffect, useRef, useState } from "react";
import cloud_upload from "../../../assests/images/icons/cloud_upload.svg";
import { BiSearchAlt2 } from "react-icons/bi";
import { UNIT_FILE_TYPE } from "../../../services/constants/admin/pages/material";
import { makeRequest, request } from "../../../services/axios/axios";
import { Folder, File as FolderFile } from "../../../types/admin/folder";
import { API } from "../../../services/constants/route/api";
import FileSearchItem from "../Material/Upsert/FileSearchItem";
import { MESSAGE_COMMON } from "../../../services/constants/message";
import { formatCapacity } from "../../../services/helpers/formatCapacity";
import { TYPE_CAPACITY } from "../../../services/constants/globals";
import { swalClose, swalError } from "../../../services/helpers/swal";
import { StorageType } from "../../../types/student/storageType";

type TabCardType = {
  tabData?: {
    label: string;
    href: string;
  }[];
  tabIndex?: number;
  setTabIndex?: React.Dispatch<React.SetStateAction<number>>;
  title?: string;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  fileType?: number | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadDate: React.Dispatch<React.SetStateAction<string>>;
  setFolderId: React.Dispatch<React.SetStateAction<string>>;
  setFolderCode: React.Dispatch<React.SetStateAction<number | null>>;
  folderId: string;
  setResource: React.Dispatch<React.SetStateAction<{} | null>>;
  isDisabled?: boolean;
};
type FileTypeConfig = {
  label: string;
  mimeTypes: string[];
  maxSize: number;
  maxSizeName: string;
};
const fileTypes: { [key: string]: FileTypeConfig } = {
  4: {
    label: "pdf",
    mimeTypes: ["application/pdf"],
    maxSize: 100 * 1024 * 1024,
    maxSizeName: "100MB",
  }, // 100MB
  5: {
    label: "image",
    mimeTypes: ["image/jpeg", "image/png"],
    maxSize: 10 * 1024 * 1024,
    maxSizeName: "10MB",
  }, // 10MB
  2: {
    label: "video",
    mimeTypes: ["video/mp4", "video/quicktime"],
    maxSize: 2 * 1024 * 1024 * 1024,
    maxSizeName: "2GB",
  }, // 2GB
  3: {
    label: "mp3",
    mimeTypes: ["audio/mpeg"],
    maxSize: 2 * 1024 * 1024 * 1024,
    maxSizeName: "1GB",
  }, // 1GB
};
const formatToJapanDateTime = (date: Date) => {
  return date.toLocaleString("ja-JP", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};
const UploadFile = ({
  tabData,
  tabIndex,
  setTabIndex,
  title,
  setFile,
  fileType,
  setIsOpen,
  setUploadDate,
  setFolderId,
  setFolderCode,
  folderId,
  setResource,
  isDisabled = false,
}: TabCardType) => {
  const [error, setError] = useState<string | null>("");
  const [folderList, setFolderList] = useState<Folder[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchData, setSearchData] = useState<FolderFile[]>([]);
  const [originFilename, setOriginFilename] = useState<string>("");
  const [storageData, setUsedStorageData] = useState<StorageType>({
    usedStorage: 0,
    totalStorage: 0,
  });
  const [disabled, setDisabled] = useState<boolean>(isDisabled);
  const [typeFile, setTypeFile] = useState<number>(() => {
    switch (fileType) {
      case UNIT_FILE_TYPE.TYPE_PDF:
        return UNIT_FILE_TYPE.TYPE_PDF;
      case UNIT_FILE_TYPE.TYPE_IMAGE:
        return UNIT_FILE_TYPE.TYPE_IMAGE;
      case UNIT_FILE_TYPE.TYPE_VIDEO:
        return UNIT_FILE_TYPE.TYPE_VIDEO;
      case UNIT_FILE_TYPE.TYPE_AUDIO:
        return UNIT_FILE_TYPE.TYPE_AUDIO;
      default:
        return UNIT_FILE_TYPE.TYPE_AUDIO;
    }
  });

  useEffect(() => {
    const fetch = async () => {
      const [result, storageResult] = await Promise.all([
        makeRequest({
          method: "get",
          url: `${API.ADMIN_FOLDER.LIST}?sortType=1&limit=1000`,
        }),

        makeRequest({
          method: "get",
          url: `${API.ADMIN_FOLDER.CAPACITY}`,
        }),
      ]);

      if (!result?.data || storageResult?.data === null) return swalError();
      setFolderList([...folderList, ...result?.data?.resource_folders]);
      setUsedStorageData({
        ...storageData,
        usedStorage: storageResult?.data?.used_storage,
        totalStorage: storageResult?.data?.total,
      });
      swalClose();
    };

    fetch();
  }, []);

  useEffect(() => {
    setDisabled(isDisabled);
  }, [isDisabled]);

  useEffect(() => {
    const fetchFileData = async () => {
      await request.get(
        `${
          API.ADMIN_FOLDER.FILE
        }?resource_folder_id=${folderId}&origin_filename=${originFilename}&resource_type=${
          fileType ? fileTypes[fileType].label : ""
        }`,
        (fileResults) => setSearchData(fileResults.resources)
      );
    };
    fetchFileData();
  }, [originFilename, folderId]);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (disabled) return;

    event.preventDefault();
    setError(null);
    const files = event.dataTransfer.files;
    handleFiles(files);
  };
  const handleFolderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") setFolderId("");
    const [id, code] = e.target.value.split("|");
    setFolderId(id);
    setFolderCode(Number(code));
  };
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFiles = (files: FileList | null) => {
    if (!files) return;

    const file = files[0];

    const fileTypeConfig = fileType ? fileTypes[fileType] : null;
    if (!fileTypeConfig) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    const allowedMimeTypes = fileTypeConfig.mimeTypes;
    const maxSize = fileTypeConfig.maxSize;

    if (!allowedMimeTypes.includes(file.type)) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    if (file.size > maxSize) {
      setError(
        `添付ファイルのサイズが${fileTypeConfig.maxSizeName}を越えています`
      );
      return;
    }

    const totalStorageFormatKB = formatCapacity(
      storageData?.totalStorage || 0,
      TYPE_CAPACITY.GB,
      TYPE_CAPACITY.KB
    );
    const usedStorageFormatKB = formatCapacity(
      storageData?.usedStorage || 0,
      TYPE_CAPACITY.MB,
      TYPE_CAPACITY.KB
    );
    const fileSizeFormatKB = formatCapacity(
      file.size,
      TYPE_CAPACITY.BYTE,
      TYPE_CAPACITY.KB
    );

    if (
      Number(fileSizeFormatKB + usedStorageFormatKB) >
      Number(totalStorageFormatKB)
    ) {
      setError(`十分な収納スペースがない`);
      return;
    }

    setError("");
    setFile(file);
    setUploadDate(formatToJapanDateTime(new Date()));
    setIsOpen(false);
  };

  return (
    <section className="w-full px-[245px] relative z-[1000]">
      <div
        className={`flex justify-center  gap-[10px] ${
          tabIndex === 0 ? "pt-[75px]" : "pt-[145px]"
        }`}
      >
        {["ファイルをアップロード", "ファイル管理から選択"].map(
          (tab, index) => (
            <div
              className={`w-full min-w-[250px] text-center min-h-[40px] flex items-end justify-center px-[15px] rounded-t-[10px] cursor-pointer ${
                tabIndex === index
                  ? "bg-primary text-white"
                  : "bg-success-extralight text-secondary"
              }`}
              key={index}
              onClick={() => setTabIndex?.(index)}
            >
              <div
                className={`w-full border-b-[3px] ${
                  tabIndex === index
                    ? "border-primary"
                    : "border-secondary-light"
                } border-primary text-[14px] font-[500] leading-[100%] tracking-[0.02] pb-[10px]`}
              >
                {tab}
              </div>
            </div>
          )
        )}
      </div>
      {tabIndex === 0 && (
        <div className="w-full py-[30px]">
          <div className="font-[700] text-[16px] text-primary flex justify-center mb-[20px]">
            ファイルをアップロード
          </div>
          <div className="font-[400] text-[14px] mb-[8px]">
            ファイルを格納する【ファイル管理のカテゴリ】を選択
          </div>
          <select
            className={`${
              folderId === ""
                ? "text-secondary-disabled"
                : "text-secondary-dark"
            } h-[40px] pr-[33px] pl-[15.5px] mb-[20px] appearance-none font-[400] text-[12px] w-full`}
            name="section_ids"
            onChange={handleFolderChange}
          >
            <option value="" className="hidden">
              選択してください
            </option>
            {folderList.map((folder) => (
              <option
                className="text-secondary-dark"
                key={folder.id}
                value={`${folder.id}|${folder.code}`}
              >
                {folder.name}
              </option>
            ))}
          </select>
          <div
            className={`${
              disabled ? "cursor-not-allowed" : ""
            } min-h-[170px] w-full flex flex-col items-center border border-dashed border-secondary-light py-[20px] mb-[20px]`}
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
          >
            <img
              src={cloud_upload}
              width={44}
              className="max-h-[30.78px] mb-[26px]"
              alt=""
            />
            <input
              ref={fileInputRef}
              id="file-input"
              type="file"
              className="hidden"
              onChange={handleFileInputChange}
              // accept={
              //   fileType === UNIT_FILE_TYPE.TYPE_IMAGE
              //     ? "image/jpeg, image/png, image/jpg"
              //     : fileType
              //       ? fileTypes[fileType].mimeTypes[0]
              //       : ""
              // }
              accept={fileTypes[typeFile].mimeTypes.join(",")}
            />
            <div className="text-[14px] font-[400] text-center mb-[15px]">
              ファイルを選択、またはドラッグ＆ドロップ
            </div>
            <button
              type="button"
              className={`${
                disabled
                  ? "cursor-not-allowed bg-success-lighter"
                  : "bg-primary"
              } w-full max-w-[116px] min-h-[37px] text-white  rounded-[5px] px-[16px] py-[10px] text-[12px] font-[700]`}
              onClick={() => {
                if (disabled) return;
                fileInputRef.current?.click();
              }}
            >
              ファイルを選択
            </button>
            {error !== "" && (
              <div className="text-danger mt-[10px]">{error}</div>
            )}
          </div>
          <div className="text-[12px] font-[400] mb-[94px]">
            ・アップロードできる動画、音声ファイル容量の上限は2GBです。
            <br />
            ・スライドPDFのファイル容量は3MB以下を推奨しています（上限100MB）
            <br />
            ・視聴画像の推奨サイズは509x300px以下です（上限10MB）
            <br />
            ・ファイルは1つずつ選択してアップロードをしてください（複数選択不可）
            <br />
          </div>
        </div>
      )}
      {tabIndex === 1 && (
        <div className="w-full py-[30px]">
          <div className="font-[700] text-[16px] text-primary flex justify-center mb-[40px]">
            「ファイル管理」にアップロードされた教材ファイルから選択
          </div>
          <div className="font-[400] text-[14px] mb-[8px]">カテゴリを選択</div>
          <select
            className={`${
              folderId === ""
                ? "text-secondary-disabled"
                : "text-secondary-dark"
            } h-[40px] pr-[33px] pl-[15.5px] mb-[20px] appearance-none font-[400] text-[12px] w-full`}
            name="section_ids"
            onChange={handleFolderChange}
          >
            <option value="" className="hidden">
              選択してください
            </option>
            {folderList.map((folder) => (
              <option key={folder.id} value={`${folder.id}|${folder.code}`}>
                {folder.name}
              </option>
            ))}
          </select>
          <div className="font-[400] text-[14px] mb-[8px]">
            ファイル名で検索
          </div>
          <div className="relative">
            <input
              type="text"
              name="originFilename"
              id=""
              className="w-full h-[40px] pl-[12px] placeholder-[#BEBEBE] text-[14px] font-[400] leading-[100%] border-[#E1E3E2] rounded-[5px]"
              placeholder="ファイル名を入力"
              onChange={(e) => setOriginFilename(e.target.value)}
              value={originFilename}
            />
            <BiSearchAlt2
              className="absolute top-[7px] right-[9.26px] text-secondary-light"
              size={22}
            />
            <div className="absolute top-12 left-0 z-[1000] max-h-[250px] overflow-y-auto w-full bg-white shadow-2xl rounded-[5px] text-left pl-[10px]">
              {originFilename &&
                searchData &&
                searchData.map((file) => (
                  <FileSearchItem
                    key={file.id}
                    file={file}
                    className="!max-w-[493px] !min-h-[50px] rounded-[5px] mb-[5px] !justify-start !cursor-pointer hover:bg-secondary-lighter"
                    onClick={() => {
                      setResource(file);
                      setOriginFilename("");
                      setSearchData([]);
                      setIsOpen(false);
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default UploadFile;
