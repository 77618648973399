import React from "react";
import { MdOutlineInventory } from "react-icons/md";
import { Revision } from "../../../../types/student/studyToolRevise";
import { PATH_COURSE } from "../../../../services/constants/route/router";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { useNavigate } from "react-router-dom";
import { MODE_EXECUTE_TEST } from "../../../../services/constants/student/unit";
import { DECISION } from "../../../../services/constants/globals";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type RevisionItemProps = {
  revision: Revision;
};

const RevisionItem = ({ revision }: RevisionItemProps) => {
  const navigate = useNavigate();
  const { studentTheme } = useStudentThemeContext();

  const tableCols = [
    {
      label: "単元名",
      field: "name",
    },
    {
      label: "問題数",
      field: "question_quantity",
    },
    {
      label: "問題",
      field: "question_range",
    },
    {
      label: "出題順",
      field: "is_shuffle_questions",
    },
  ];

  if (!revision) return <></>;

  const redirectParams = {
    unit_id: revision.id,
    test_type: MODE_EXECUTE_TEST.REVISE,
    is_limit_scope: DECISION.NO,
  };

  const redirectTarget = `${PATH_COURSE.EXECUTE_TEST}?${paramizeObject(
    redirectParams
  )}`;

  return (
    <div className="w-[236px] h-0 min-h-[244px] border border-danger-light rounded-[10px] py-[14px] px-[13px] shrink-0 relative">
      <table className="w-full">
        <tbody>
          {tableCols.map((tableCol) => (
            <tr className="w-full" key={tableCol.field}>
              <td className="w-[55px] font-[600] text-[12px] tracking-[0.238px] pb-[14px]">
                {revision[tableCol.field as keyof Revision] !== undefined
                  ? tableCol.label
                  : ""}
              </td>
              <td
                className={`font-[500] text-[12px] leading-[19px] tracking-[0.24px] text-secondary ${
                  tableCol.field === "name" ? "line_clamp_four" : "line_clamp"
                }`}
              >
                {tableCol.field === "is_shuffle_questions" ? (
                  <>
                    {!!revision[tableCol.field as keyof Revision]
                      ? "ランダムに出題"
                      : "１問目から順に出題"}
                  </>
                ) : (
                  <>{revision[tableCol.field as keyof Revision]}</>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{ backgroundColor: studentTheme.main_color_second }}
        className="w-full max-w-[210px] h-0 min-h-[30px] flex items-center justify-center bg-warning rounded-[15px] gap-[10px] text-white cursor-pointer mt-[16px] absolute bottom-[14px] left-[12px]"
        onClick={() => navigate(redirectTarget)}
      >
        <MdOutlineInventory size={18} />
        <div className="text-[12px] font-[700] tracking[0.238px]">
          復習テストを受験する
        </div>
      </div>
    </div>
  );
};

export default RevisionItem;
