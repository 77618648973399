import StudyToolBase from ".";
import MemoCreate from "../../../../components/student/Memo/MemoCreate";

const MemoRegistration = () => {
  return (
    <StudyToolBase>
      <MemoCreate />
    </StudyToolBase>
  );
};

export default MemoRegistration;