import TabCard from '../../../components/admin/commons/TabCard';
import AdminLayout from '../../../layouts/admin';
import { WithChildren } from '../../../types/globals';

type MaterialMainProp = {
  title?: string;
};

const MaterialBase = ({ title, children }: WithChildren<MaterialMainProp>) => {
  return (
    <AdminLayout>
      <TabCard tabData={[]} title={title}>
        {children}
      </TabCard>
    </AdminLayout>
  );
};

export default MaterialBase;
