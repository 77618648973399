import React from "react";
import StudentLayout from "../../../layouts/student";
import UnitDetail from "../../../components/student/Unit/UnitDetail";
import StudentUnitProvider from "../../../context/StudentUnitContext";

const UnitPage = () => {
  return (
    <StudentLayout gap>
      <StudentUnitProvider>
        <UnitDetail />
      </StudentUnitProvider>
    </StudentLayout>
  );
};

export default UnitPage;
