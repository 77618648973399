import React from "react";
import TermOfUseBase from ".";
import StudentThemeSettingEdit from "../../../components/admin/TermOfUse/StudentTheme/StudentThemeEdit";

const StudentThemePage = () => {
  return (
    <TermOfUseBase title="各種設定 ｜ 受講者画面設定">
        <StudentThemeSettingEdit />
    </TermOfUseBase>
  );
};

export default StudentThemePage;
