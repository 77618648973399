import { useEffect, useState } from "react";
import { Memo } from "../../../types/student/memo";
import { Link, useNavigate, useParams } from "react-router-dom";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { PATH_STUDY_TOOL } from "../../../services/constants/route/router";
import MemoFilter from "./MemoFilter";
import dayjs from "dayjs";
import MemoCreateButton from "./MemoCreateButton";
import { FiSave, FiTrash2 } from "react-icons/fi";
import { MdFolder, MdOutlineCreate } from "react-icons/md";
import RichTextEditor from "../../commons/form/TextEditor";
import MemoTypeBgColor from "./MemoTypeBgColor";
import MemoSelectFolder from "./MemoSelectFolder";
import { LuTrash2 } from "react-icons/lu";
import { DataDeleteMemo } from "./MemoBoard";
import ErrorBox from "../../commons/form/ErrorBox";
import { ErrorData } from "../../../types/globals";
import { hiddenImageElement } from "./MemoItem";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { swallConfirm } from "../../../services/helpers/swal";
import { getTextByHtml } from "../../../services/helpers/parseData";

const MemoDetail = () => {
  const [errors, setErrors] = useState<ErrorData>({});
  const [filter, setFilter] = useState("");
  const [memo, setMemo] = useState<Memo>({});
  const [memos, setMemos] = useState<Memo[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [memoFormData, setMemoFormData] = useState<Memo>(memo);
  const [isSaveUpdate, setIsSaveUpdate] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const paramId = Number(params.id);
  const memoFolderId = JSON.parse(localStorage.getItem("memoFolderId") || "0");
  const urlParams = new URLSearchParams(window.location.search);
  const withEditParamExists = urlParams.has("with_edit");
  const { studentTheme } = useStudentThemeContext();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void => {
    setMemoFormData({ ...memoFormData, [e.target.name]: e.target.value });
  };

  const handleClickChangeBgColor = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!isEdit) return;
    let colorId = e.currentTarget.id;
    setMemoFormData({ ...memoFormData, background_color_code: colorId });
  };

  const handleSubmitUpdate = async () => {
    await request.post(
      `${API.MEMO.UPSERT_MEMO}`,
      memoFormData,
      (upsertMemo) => {
        if (upsertMemo && upsertMemo.id) {
          setIsSaveUpdate(true);
        }
      }
    );
  };

  const handleDeleteMemo = async (dataDelete: DataDeleteMemo) => {
    await request.delete(`${API.MEMO.DELETE_MEMO}`, dataDelete);

    navigate(PATH_STUDY_TOOL.MEMO);
  };

  const deleteMemoFolderHandler = async () => {
    await request.delete(`${API.MEMO.DELETE_MEMO_FOLDER}`, {
      id: memo.memo_folder_id,
    });

    navigate(PATH_STUDY_TOOL.MEMO);
  }

  const deleteMemoFolder = async () => {
    swallConfirm(
      () => deleteMemoFolderHandler(),
      'フォルダの中身がすべて削除されてしまいますがよろしいでしょうか。'
    );
  };

  useEffect(() => {
    setMemoFormData(memo);
    setErrors({});
  }, [memo, isEdit]);

  useEffect(() => {
    const fetchMemos = async () => {
      await request.get(
        `${API.MEMO.GET_MEMO_BY_ID}?memo_id=${paramId}&query=${filter}`,
        (memos) => {
          setMemos(memos);
        }
      );
    };

    const timer = setTimeout(() => {
      fetchMemos();
    }, 500);
    if (isSaveUpdate) {
      setIsSaveUpdate(false);
      setIsEdit(false);
    }

    if (withEditParamExists) setIsEdit(true);
    return () => clearTimeout(timer);
  }, [filter, isSaveUpdate]);

  useEffect(() => {
    if (memos !== undefined) {
      let memo = memos.find((memo: Memo) => memo.id === paramId);
      if (memo !== undefined) setMemo(memo);
    }
  }, [paramId, memos]);

  return (
    <div className="min-h-[900px] mx-auto rounded-[5px] mb-[15px] text-secondary-light text-[12px] font-[500]">
      <div className="flex gap-[16px] text-secondary font-[500]">
        <div className="w-[208px] min-h-[150px]">
          <div
            style={{ color: studentTheme.text_color }}
            className="text-[16px] font-[700] overflow-ellipsis mb-[19px]"
          >
            {memo.nameFolder}
          </div>
          <MemoFilter name="name" handleChange={setFilter} />
          <div className="py-[14px]">
            <Link to={`${PATH_STUDY_TOOL.CREATE_MEMO}`}>
              <MemoCreateButton name="メモを追加" />
            </Link>
          </div>
          <div className="w-[208px] pb-[19px]">
            {memos?.map((memo) => {
              return (
                <div
                  style={{
                    backgroundColor:
                      memo.id === paramId ? studentTheme.sub_color_second : "",
                  }}
                  className={`mt-[5px] py-[4px] px-[15px] border border-danger-light rounded-[10px] cursor-pointer ${
                    memo.id === paramId
                      ? "bg-warning-light"
                      : "bg-secondary-morelighter"
                  }`}
                  key={memo.id}
                  onClick={() =>
                    navigate(`${PATH_STUDY_TOOL.DETAIL_MEMO}/${memo.id}`)
                  }
                >
                  <div
                    style={{ color: studentTheme.text_color }}
                    className="text-[13px] font-[700] truncate"
                    onClick={() => {
                      setIsEdit(false);
                    }}
                  >
                    {memo.name}
                  </div>
                  <div className="max-h-[20px] flex items-start gap-[7px] mt-[2px] font-[400] overflow-y-hidden">
                    <div className="text-[11px]">
                      {dayjs(memo.updated_at).format("YYYY/MM/DD")}
                    </div>
                    <div
                      className="test-[12px] truncate"
                      dangerouslySetInnerHTML={{
                        __html: hiddenImageElement(memo.content || ""),
                      }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-[590px]">
          {errors && Object.keys(errors).length > 0 && (
            <ErrorBox errors={errors} />
          )}
          {isEdit ? (
            <MemoSelectFolder
              memoFolderId={memoFormData.memo_folder_id}
              handleChange={handleChange}
            />
          ) : (
            <div className="flex justify-between items-center h-[30px]">
              <div className="flex items-center gap-[12.5px] h-[30px]">
                <MdFolder size={30} className="text-secondary-light" />
                {memo.nameFolder}
              </div>
              <div>
                <button
                  className="flex items-center gap-[3px] h-[24px] text-[12px] text-white font-[500] bg-secondary-light rounded-[3px] px-[8px] py-[3px]"
                  onClick={deleteMemoFolder}
                >
                  フォルダを削除 <FiTrash2 size={17} />
                </button>
              </div>
            </div>
          )}

          <div
            style={{ color: studentTheme.text_color }}
            className="py-[10px] text-[16px] font-[700]"
          >
            {isEdit ? (
              <input
                type="text"
                name="name"
                className="w-full px-[20px] py-[10px] placeholder:text-secondary-light placeholder:text-[16px] placeholder:font-[700] rounded-none"
                placeholder="メモタイトルを入力"
                onChange={handleChange}
                value={memoFormData?.name || ""}
              />
            ) : (
              memo?.name
            )}
          </div>

          <div className="text-[11px] font-[400]">
            {!isEdit && dayjs(memo?.updated_at).format("YYYY年MM月DD日　HH:mm")}
          </div>

          <MemoTypeBgColor
            title={isEdit ? "色の変更" : "色"}
            backgroundColorId={
              (isEdit
                ? memoFormData.background_color_code
                : memo?.background_color_code) || "FFFFFF"
            }
            className="pt-[14px]"
            onClick={handleClickChangeBgColor}
          />

          {isEdit ? (
            <div className="py-[8px] mt-[25px]">
              <RichTextEditor
                value={memoFormData?.content}
                toolbarId="toolbar"
                className="w-[590px] h-[172px]"
                changeHandler={(html: string) =>
                  setMemoFormData({ ...memoFormData, content: html, content_origin: getTextByHtml(html)})
                }
                isStudent={true}
              />
            </div>
          ) : (
            <div
              className="max-w-[590px] py-[30px] px-[20px] text-[12px] font-[400] text-justify break-words"
              dangerouslySetInnerHTML={{
                __html: memo?.content || "",
              }}
            ></div>
          )}
          <div
            className={`flex items-center justify-end text-white ${
              isEdit ? "gap-[8px]" : "gap-[17px]"
            }`}
          >
            {isEdit ? (
              <>
                <button
                  className="flex items-center h-[28px] px-[20px] font-[500] bg-secondary-light rounded-[20px]"
                  onClick={() => setIsEdit(false)}
                >
                  キャンセル
                </button>
                <button
                  style={{
                    backgroundColor: studentTheme.main_color_first,
                  }}
                  className="flex items-center h-[28px] px-[20px] font-[500] bg-primary rounded-[20px]"
                  onClick={handleSubmitUpdate}
                >
                  <FiSave className="text-white pr-[7px]" size={20} />
                  保存
                </button>
              </>
            ) : (
              <>
                <button
                  className="flex items-center h-[28px] px-[20px] font-[500] bg-secondary-light rounded-[20px]"
                  onClick={() =>
                    handleDeleteMemo({
                      id: memo.id || 0,
                    })
                  }
                >
                  <LuTrash2 size={20} className="pr-[7px]" /> 削除する
                </button>
                <button
                  className="flex items-center h-[28px] px-[20px] font-[500] bg-secondary-light rounded-[20px]"
                  onClick={() => setIsEdit(true)}
                >
                  <MdOutlineCreate size={20} className="pr-[7px]" /> 編集する
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemoDetail;
