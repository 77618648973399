import Button from "../../Login/Button";
import ErrorBox from "../../commons/form/ErrorBox";
import { useRef, useState } from "react";
import { ErrorData } from "../../../types/globals";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalError, swalSuccess } from "../../../services/helpers/swal";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import { ROUTE } from "../../../services/constants/route/router";
import { VERIFY_STEP } from "../../../services/constants/student/completeStudentRegistration";
import ImageInternalButton from "../../commons/buttons/ImageInternalButton";
import { MESSAGE_COMMON } from "../../../services/constants/message";
import { PICTURE_TYPE } from "../../../services/constants/globals";
import { AiOutlineUpload } from "react-icons/ai";
import LogoDefault from "../../../assests/images/default_avatar.webp";

interface CompleteRegistrationStepProp {
  studentThemeColor: any;
  handleStep: Function;
}

type StudentData = {
  first_name?: string;
  last_name?: string;
  avatar?: File | null;
  avatar_url?: string | null;
  password?: string;
  password_confirmation?: string;
  token?: string;
};

const CompleteRegistrationStep = (props: CompleteRegistrationStepProp) => {
  let navigate = useNavigate();
  let { studentThemeColor, handleStep } = props;
  const { getParamValue } = useObjectRoutes();
  let token = getParamValue("token");
  const { decodedToken } = useJwt(token as string);

  const [studentData, setStudentData] = useState<StudentData>({});
  const [errors, setErrors] = useState<ErrorData>({});
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleChangeInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    const file =
      (e.target as HTMLInputElement).files &&
      (e.target as HTMLInputElement).files?.[0];
    if (file) {
      const byteSize = 2097152;
      if (file.size > byteSize) {
        setErrors({
          maxSize: ["ファイルサイズが2MBの最大制限を超えています。"],
        });
        return;
      }
      if (!PICTURE_TYPE.includes(file.type)) {
        setErrors({
          type: [MESSAGE_COMMON.INVALID_FILE_TYPE],
        });
        return;
      }
      setStudentData({ ...studentData, [name]: file });
      return;
    }

    setStudentData({ ...studentData, [name]: value });
  };

  const completeStudentRegistration = async (e: any) => {
    e.preventDefault();
    const result = await makeRequest({
      method: "post",
      url: API.AUTH.COMPLETE_STUDENT_REGISTRATION,
      data: {
        ...studentData,
        token: token,
      },
      hasFileRequest: true,
    });
    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError();
    }

    swalSuccess();
    navigate(ROUTE.LOGIN);
  };

  const removeImage = () => {
    setStudentData({ ...studentData, avatar: undefined });
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  return (
    <form
      style={{ color: studentThemeColor.text_color }}
      className="max-sm:w-full mb-[42px] mt-[30px] mx-auto max-sm:mt-[25px] max-sm:px-[10px] max-sm:mb-[32px] animate-[show_0.5s_ease-in-out]"
      onSubmit={completeStudentRegistration}
    >
      <div className="sm:w-[450px] max-sm:w-full mb-[30px] max-sm:mb-[25px] bg-[#F8F7F6] px-[25px] border border-warning-light">
        <p className="mt-[30px] max-sm:mt-[25px] mb-[20px] text-[17px] font-bold">
          プロフィール作成（無料）
        </p>
        {errors && !!Object.keys(errors).length && (
          <div className="mb-[20px] text-left">
            <ErrorBox errors={errors} />
          </div>
        )}
        <div className="mb-[20px] mt-[10px]">
          <p className="text-[14px] font-bold text-left mb-[10px]">
            メールアドレス
          </p>
          <p className="text-left ml-[5px] font-normal">
            {(decodedToken as any)?.email}
          </p>
        </div>
        <div className="mb-[15px] mt-[10px]">
          <p className="text-[14px] font-bold text-left mb-[8px]">
            パスワード<span className="text-danger">*</span>
            （半角英数・記号のみ８〜20字）
          </p>
          <input
            type="password"
            name="password"
            onChange={handleChangeInput}
            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
            required
          />
        </div>
        <div className="mb-[15px] mt-[10px]">
          <p className="text-[14px] font-bold text-left mb-[8px]">
            パスワード（確認用）<span className="text-danger">*</span>
          </p>
          <input
            type="password"
            name="password_confirmation"
            onChange={handleChangeInput}
            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
            required
          />
        </div>
        <div className="mb-[15px] mt-[10px]">
          <p className="text-[14px] font-bold text-left mb-[8px]">
            ログインID<span className="text-danger">*</span>
            （半角英数のみ８〜20字）
          </p>
          <input
            type="text"
            name="username"
            onChange={handleChangeInput}
            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
            placeholder="loginname"
            required
          />
        </div>
        <div className="mb-[10px] mt-[10px]">
          <p className="text-[14px] font-bold text-left mb-[8px]">
            氏名<span className="text-danger">*</span>
          </p>
          <div className="flex items-center">
            <input
              type="text"
              name="last_name"
              onChange={handleChangeInput}
              className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] mr-[20px] "
              placeholder="鈴木"
            />
            <input
              type="text"
              name="first_name"
              onChange={handleChangeInput}
              className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] "
              placeholder="一郎"
            />
          </div>
        </div>
        <p className="text-[14px] mt-[20px] font-[700] text-left mb-[8px]">
          プロフィール画像
        </p>
        <div className="mb-[25px] mt-[5px] flex justify-start bg-white items-center object-cover border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 w-full pr-[22px] pl-[10px] py-[5px]">
          <div className="bg-[#F4F4F4] flex justify-center items-center w-[40px] h-[40px] max-w-[40px] max-h-[40px]">
            <input
              type="file"
              id="image"
              name="avatar"
              accept="image/*"
              className="hidden"
              onChange={handleChangeInput}
              placeholder="選択されていません"
              ref={inputFileRef}
            />
            <img
              alt="avatar"
              src={
                studentData.avatar
                  ? URL.createObjectURL(studentData.avatar)
                  : LogoDefault
              }
              className="object-cover w-full h-full"
            />
          </div>
          {studentData.avatar ? (
            <>
              <div className="relative ml-[60px] mr-[15px]">
                <ImageInternalButton
                  removeImage={removeImage}
                  editImage={() => inputFileRef.current?.click()}
                  editButtonOtherStyle="!top-[-10px] !right-[25px]"
                  removeButtonOtherStyle="!top-[-10px] !right-[0px]"
                />
              </div>
              <p className="max-w-[200px] truncate text-[12px]">
                {studentData.avatar.name}
              </p>
            </>
          ) : (
            <>
              <button
                className="bg-[#7E869E] text-white rounded-full mx-[15px]"
                onClick={() => inputFileRef.current?.click()}
              >
                <AiOutlineUpload size={20} className="px-1 py-1" />
              </button>
              <p className="text-[10px]">選択されていません</p>
            </>
          )}
        </div>
        <div className={"flex"}>
          <Button
            style={{
              // backgroundColor: studentThemeColor.main_color_first,
              marginBottom: "30px",
            }}
            onClick={() => handleStep(VERIFY_STEP)}
            content={"戻る"}
            otherstyle={
              "bg-gray-400 text-white max-sm:text-[18px] ml-[30px] mr-[10px]"
            }
          />
          <Button
            style={{
              backgroundColor: studentThemeColor.main_color_first,
              marginBottom: "30px",
            }}
            content={"完了する"}
            otherstyle={
              "bg-primary text-white max-sm:text-[18px] mr-[30px] ml-[10px]"
            }
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};

export default CompleteRegistrationStep;
