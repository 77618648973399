import React, { useEffect, useState } from "react";
import { TermOfServiceType } from "../../../types/admin/term_of_use";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalError } from "../../../services/helpers/swal";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../../../assests/styles/policy.css";

const Policy = () => {
  const [policy, setPolicy] = useState<TermOfServiceType>({
    name: "",
    description: "",
  });
  const { pathname } = useObjectRoutes();
  const pathNameArr = pathname.split("/");
  const path = pathNameArr[pathNameArr.length - 1];

  useEffect(() => {
    const fetch = async () => {
      await request.get(
        `${API.POLICY.GET_DETAIL_POLICY}?path=${path}`,
        (data) => {
          setPolicy(data);
        },
        (error) => {
          swalError();
        }
      );
    };

    fetch();
  }, []);

  return (
    <div className="px-[20px] py-[15px]">
      <Markdown
        remarkPlugins={[remarkGfm]}
        className={`whitespace-pre-wrap text-[12px] leading-[18px] text-justify font-[500] commercial`}
      >
        {policy?.description}
      </Markdown>
    </div>
  );
};

export default Policy;
