import { ChangeEvent, useEffect, useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { FILTER } from '../../../../services/constants/admin/main';
import { useCourseListContext } from '../../../../context/CourseContext';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { paramizeObject } from '../../../../services/helpers/parseData';

type Filter = {
  name?: string;
  limit?: number;
};

const TableAction = () => {
  const { setCourses } = useCourseListContext();
  const [filter, setFilter] = useState<Filter>({});
  const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!Object.keys(filter).length) return;

    const timer = setTimeout(async () => {
      await request.get(`${API.ADMIN_COURSE.LIST}?${paramizeObject(filter)}`, setCourses);
    }, 500);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div className="flex gap-[10px] pb-[40px] border-b-[1px] border-secondary-light">
      {/* search */}
      <div className="relative">
        <input
          type="text"
          name="name"
          id=""
          className="h-full max-w-[200px] pl-[12px] text-[11px] leading-[100%] min-h-[30px] text-secondary-dark"
          placeholder="学習コースを検索"
          onChange={handleChange}
          value={filter?.name || ''}
        />
        <BiSearchAlt2 className="absolute text-secondary-light top-[6px] right-[10px]" size={18} />
      </div>

      {/* limit */}
      <div className="flex items-end gap-[10px]">
        <div className="relative">
          <select
            name="limit"
            id=""
            className="min-h-[30px] pr-[33px] pl-[7px] text-secondary-dark text-[11px] w-[95px]"
            onChange={handleChange}
            value={filter?.limit || FILTER.OPTION.RECORD_LIMIT[0].value}
          >
            {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
              <option value={item.value} className="" key={index}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default TableAction;
