import { useEffect, useState } from "react";
import { ADMIN_SIDEBAR_DATA } from "../../services/constants/admin/sidebar";
import SidebarItem from "./SidebarItem";
import Hamburger from "../../components/commons/icons/Sidebar/Hamburger";
import { useSidebarContextProvider } from "../../context/SidebarContext";
import { useStorage } from "../../hooks/useStorage";
import { SidebarItem as SidebarItemProps } from "../../types/admin/main";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const storedOpenItem = localStorage.getItem("openItem");
  const [openItem, setOpenItem] = useState<number | null>(
    storedOpenItem ? Number(storedOpenItem) : null
  );
  const { showSidebar, setShowSidebar } = useSidebarContextProvider();
  const { getLocalStorage } = useStorage();
  const paymentFeature = getLocalStorage("payment_feature") || false;
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("openItem", openItem?.toString() || "");
  }, [openItem]);

  const handleItemClick = (index: number | null, data: SidebarItemProps) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }

    if (showSidebar) {
      if (data.href.length > 0 && data.childs.length <= 0) {
        navigate(`${data.href}`);
      }
      if (data.href.length <= 0 && data.childs.length > 0) {
        navigate(`${data.childs[0]['href']}`);
      }
    }
  };

  return (
    <section
      className="w-full bg-white text-secondary-light h-full transition-all"
      style={{
        maxWidth: showSidebar ? "60px" : "250px",
      }}
    >
      <div className="h-0 min-h-[38px] flex justify-end items-center cursor-pointer">
        <Hamburger
          onClick={() => setShowSidebar(!showSidebar)}
          isOpen={!showSidebar}
        />
      </div>
      <div className="whitespace-nowrap">
        {ADMIN_SIDEBAR_DATA.map((data, index) => (
          <SidebarItem
            data={data}
            key={index}
            currentPath={location.pathname}
            isOpen={index === openItem}
            onItemClick={() => {
              handleItemClick(index, data);
              // setShowSidebar(false);
            }}
            isMinimize={!!showSidebar}
            otherStyle={paymentFeature === false && data.label === "講座販売" ? "hidden" : ""}
          />
        ))}
      </div>

      {/* Sidebar item hidden for later phases */}
      {/* <div className="bg-secondary-lesslight text-primary-light text-[11px] font-[500] leading-[100%] tracking-[0.03em] min-h-[40px] pl-[15px] border-t border-secondary-medium flex items-center cursor-pointer">
        追加機能設定
      </div> */}
    </section>
  );
};

export default Sidebar;
