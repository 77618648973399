/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, FormEvent } from "react";
import UnitTestFormItem from "./UnitTestFormItem";
import styles from "../../../../../assests/styles/material.module.css";
import { Unit, UnitForm } from "../../../../../types/admin/unit";
import {
  CREATION_UNIT_TEST_TYPE,
  UNIT_TEST_MODE_TYPE,
  UNIT_TEST_ORDER_DATA,
} from "../../../../../services/constants/admin/pages/material";
import { request } from "../../../../../services/axios/axios";
import { API } from "../../../../../services/constants/route/api";
import { useObjectRoutes } from "../../../../../hooks/useObjectRoutes";
import { PATH_ADMIN_MATERIAL } from "../../../../../services/constants/route/router";
import UnitChapterSelect from "./UnitChapterSelect";
import UnitEstimationTime from "./UnitEstimationRequiredTime";
import { ErrorData } from "../../../../../types/globals";
import ErrorBox from "../../../../commons/form/ErrorBox";
import UnitTestList from "../UnitTestList";
import { swallConfirm } from "../../../../../services/helpers/swal";

type UnitTestFormProps = {
  unitData: Unit;
  createType: Unit["type"];
};
const UnitTestForm = ({ unitData, createType }: UnitTestFormProps) => {
  const [formData, setFormData] = useState<UnitForm>({
    ...(unitData && { ...unitData }),
    is_main_mode: Number(unitData?.is_main_mode || 0),
    is_practice_mode: Number(unitData?.is_practice_mode || 0),
    is_revise_mode: Number(unitData?.is_revise_mode || 0),
  });
  const [errors, setErrors] = useState<ErrorData>({});
  const { getParamValue, navigate } = useObjectRoutes();
  const withChapterId = getParamValue("with_chapter_id");

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const currentValue =
      e.target.name === "is_practice_mode" ||
      e.target.name === "is_main_mode" ||
      e.target.name === "is_revise_mode"
        ? Number((e as ChangeEvent<HTMLInputElement>).target.checked)
        : e.target.value;

    setFormData({ ...formData, [e.target.name]: currentValue });
  };
  
  const handleSubmit = (
    e: FormEvent<HTMLButtonElement>,
    submitType?: number
  ) => {
    e.preventDefault();
    let submitAction = request.post;
    let submitUrl = API.ADMIN_UNIT.CREATE_UNIT_TEST;
    const status = submitType || CREATION_UNIT_TEST_TYPE.DRAFT;
    let submitData = {
      ...formData,
      status,
      type: createType,
      chapter_id: formData.chapter_id || Number(withChapterId) || unitData.chapter_id,
    };

    if (unitData && unitData.id) {
      submitAction = request.patch;
      submitUrl = API.ADMIN_UNIT.UPDATE_UNIT_TEST;
      submitData = { ...submitData, unit_id: unitData.id };
    }

    swallConfirm(
      async () => {
        await submitAction(
          submitUrl,
          submitData,
          (upsertedUnit) => {
            if (
              !submitType ||
              submitType === CREATION_UNIT_TEST_TYPE.DRAFT ||
              submitAction === request.patch
            )
              return navigate(`${PATH_ADMIN_MATERIAL.UNIT}?course_id=${upsertedUnit.course_id}`);

            if (upsertedUnit && upsertedUnit.id && !unitData.id)
              setErrors({});
              return navigate(
                `${PATH_ADMIN_MATERIAL.UNIT_UPSERT}?unit_id=${upsertedUnit.id}`
              );
          },
          setErrors,
          { withSuccess: true }
        );
      },
      "このテストを公開しても<br/>よろしいですか？",
      "公開後のテストは受講者が閲覧可能です",
      "公開する",
      status === CREATION_UNIT_TEST_TYPE.DRAFT
    );
  };
  
  return (
    <>
      <form className={`text-secondary-dark ${styles.form_styles}`}>
        {/* errors */}
        {errors && !!Object.keys(errors).length && (
          <div className="mb-[20px]">
            <ErrorBox errors={errors} />
          </div>
        )}
        {/* Unit test form */}
        <UnitTestFormItem label="単元名">
          <input
            type="text"
            name="name"
            id=""
            placeholder="単元名を入力してください。"
            className="placeholder-secondary-light"
            value={formData.name}
            onChange={handleChange}
          />
        </UnitTestFormItem>
        <UnitTestFormItem label="登録カテゴリ（コース\講義\章）設定">
          <UnitChapterSelect
            value={formData.chapter_id || Number(withChapterId || 0)}
            onChange={handleChange}
            unitId={unitData.id}
          />
        </UnitTestFormItem>
        <UnitTestFormItem label="概要説明">
          <textarea
            name="description"
            placeholder="この単元の簡単な説明を入力してください"
            className="placeholder-secondary-light"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
        </UnitTestFormItem>
        <UnitTestFormItem label="テスト（本番モード）の制限時間">
          <UnitEstimationTime formData={formData} setFormData={setFormData} />
        </UnitTestFormItem>
        <UnitTestFormItem label="出題数">
          <input
            type="number"
            name="question_quantity"
            id=""
            className="w-full max-w-[90px] text-right"
            placeholder="0"
            value={formData.question_quantity}
            onChange={handleChange}
          />
        </UnitTestFormItem>
        <UnitTestFormItem label="合格点">
          <div className="flex items-center gap-[10px]">
            <input
              type="number"
              name="passing_score"
              id=""
              placeholder="0"
              className="w-full max-w-[90px] text-right"
              value={formData.passing_score}
              onChange={handleChange}
            />
            点以上で合格
          </div>
        </UnitTestFormItem>
        <UnitTestFormItem label="出題モードを選択する">
          <div className="w-full max-w-[380px] min-h-[50px] flex items-center gap-[30px] bg-secondary-morelighter pl-[12.5px]">
            {UNIT_TEST_MODE_TYPE.map((data) => (
              <div className="flex items-center" key={data.key}>
                <input
                  type="checkbox"
                  name={data.key}
                  id={data.key}
                  value={formData[data.key] || 0}
                  onChange={handleChange}
                  checked={!!formData[data.key]}
                />
                <label htmlFor={data.key}>{data.label}</label>
              </div>
            ))}
          </div>
        </UnitTestFormItem>
        <UnitTestFormItem label="出題順をシャッフルする">
          <div className="flex items-center gap-[30px] mt-[21px] bg-secondary-morelighter w-full max-w-[340px] min-h-[50px] pl-[12.5px]">
            {UNIT_TEST_ORDER_DATA.map((data, index) => (
              <div className="flex items-center gap-[8.5px]" key={index}>
                <input
                  type="radio"
                  name="is_shuffle_questions"
                  id={`is_shuffle_questions_${index}`}
                  className="form-radio text-primary-light border border-success-extralight"
                  checked={data.value === Number(formData.is_shuffle_questions)}
                  value={data.value}
                  onChange={handleChange}
                />
                <label htmlFor={`is_shuffle_questions_${index}`}>
                  {data.label}
                </label>
              </div>
            ))}
          </div>
        </UnitTestFormItem>

        <div className="h-[1px] w-full bg-secondary-light"></div>
      </form>

      {/* Unit test list */}
      <div className={`mt-[30px] ${styles.form_styles}`}>
        <UnitTestList
          questionData={unitData.questions || []}
          unitId={unitData.id}
          handleSubmit={handleSubmit}
          question_quantity={formData.question_quantity}
        />
      </div>
    </>
  );
};

export default UnitTestForm;
