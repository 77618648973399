import React from 'react';
import { LEARNING_TOOL_TYPE } from '../../../services/constants/student/learning';
import { LearningTool } from '../../../types/student/learning';
import BookOpen from '../../commons/icons/BookOpen';
import Calendar from '../../commons/icons/Calendar';
import CircleCheck from '../../commons/icons/CircleCheck';
import Pen from '../../commons/icons/Pen';
import { useStudentThemeContext } from '../../../context/StudentThemeContext';

const LearningToolType = ({ type }: { type: LearningTool['type'] }) => {
  const {studentTheme} = useStudentThemeContext();

  switch (type) {
    case LEARNING_TOOL_TYPE.READ:
      return <BookOpen color={studentTheme.sub_color_first} />;

    case LEARNING_TOOL_TYPE.SCHEDULE:
      return <Calendar color={studentTheme.sub_color_first} />;

    case LEARNING_TOOL_TYPE.WRITE:
      return <Pen color={studentTheme.sub_color_first} />;

    default:
      return <CircleCheck color={studentTheme.sub_color_first} />;
  }
};

export default LearningToolType;
