import dayjs from "dayjs";
import { useState } from "react";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { PATH_STUDY_TOOL } from "../../../../services/constants/route/router";
import { MdOutlineCreate, MdOutlineSettings } from "react-icons/md";
import { getCommonDate } from "../../../../services/helpers/date";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { STATE_EVENT_FORM } from "../../../../services/constants/student/event";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

const CalendarHeader = ({ calendarRef }: { calendarRef: any }) => {
  const { studentTheme } = useStudentThemeContext();
  const [date, setDate] = useState<string>(dayjs().format());
  // Previous month handler
  const prevHandle = () => {
    const prevMonth = dayjs(date).subtract(1, "M").format();
    setDate(prevMonth);
    const calendarApi = calendarRef.current?.getApi();
    calendarApi.prev();
  };

  const nextHandle = () => {
    const nextMonth = dayjs(date).add(1, "M").format();
    setDate(nextMonth);
    const calendarApi = calendarRef.current?.getApi();
    calendarApi.next();
  };

  const paramsUrl = {
    date: getCommonDate(date),
    state: STATE_EVENT_FORM.CREATE,
  };

  return (
    <div className="flex justify-between items-center h-0 min-h-[57px]">
      {/* Date navigation */}
      <div className="w-full max-w-[150px]">
        <div className="w-full flex justify-between items-center text-secondary">
          <div id="btn-prev" className="cursor-pointer" onClick={prevHandle}>
            <IoIosArrowBack />
          </div>
          <div
            style={{ color: studentTheme.text_color }}
            id="btn-next"
            className="text-[13.6px] font-[700] tracking-[0.3px]"
          >
            {formatDateTime(date, TYPE_DATE_FORMAT.YEAR_MONTH)}
          </div>
          <div className="cursor-pointer" onClick={nextHandle}>
            <IoIosArrowForward />
          </div>
        </div>
      </div>

      {/* Insert, setting action */}
      <div className="flex items-center gap-[20px]">
        <Link
          to={`${PATH_STUDY_TOOL.SCHEDULE.EVENT.DEFAULT}?${paramizeObject(
            paramsUrl
          )}`}
          className="rounded-[19px]"
        >
          <div
            style={{ backgroundColor: studentTheme.main_color_first }}
            className="flex items-center justify-center gap-[6.8px] w-[140px] h-0 min-h-[37px] bg-primary text-white rounded-[19px] hover:bg-[#00000026]"
          >
            <div className="">
              <MdOutlineCreate />
            </div>
            <div className="text-[12px] font-[700] tracking-[0.2px]">
              予定を追加する
            </div>
          </div>
        </Link>
        {/* <div className="text-secondary-light text-[24px] cursor-pointer">
          <MdOutlineSettings />
        </div> */}
      </div>
    </div>
  );
};

export default CalendarHeader;
