import SectionTable from './Table';
import SectionTitle from '../Title';
import TableCommonAction from '../../commons/TableCommonAction';
import { scrollToTop } from '../../../../services/helpers/effect';
import { useState } from 'react';

const SectionList = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  scrollToTop();

  return (
    <>
      <TableCommonAction onCreate={() => setIsOpenModal(true)}/>
      <SectionTitle content="すべてのセクション" isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} withButton />
      <SectionTable className="mt-[15px] text-secondary-dark" />
    </>
  );
};

export default SectionList;
