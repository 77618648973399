import {
  PATH_COURSE,
  PATH_POLICY,
  PATH_PRODUCT,
  PATH_STUDENT_CART,
  PATH_STUDY_TOOL,
  ROUTE,
} from "../services/constants/route/router";
import { RouteObject } from "react-router";
import MyPage from "../pages/student/my-page";
import Profile from "../pages/student/profile";
import MailChanging from "../pages/student/profile/mail-changing";
import PasswordChanging from "../pages/student/profile/password-changing";
import Courses from "../pages/student/my-page/courses";
import Lectures from "../pages/student/my-page/lectures";
import UnitPage from "../pages/student/course/unit";
import CourseLists from "../pages/student/my-page/course-list";
import StudyHistory from "../pages/student/my-page/study-history";
import ExecuteTestPage from "../pages/student/course/execute-test";
import ExplanationPage from "../pages/student/course/explanation";
import MemoList from "../pages/student/study-tool/memo/MemoList";
import MemoView from "../pages/student/study-tool/memo/MemoView";
import MemoRegistration from "../pages/student/study-tool/memo/MemoRegistration";
import ReportPage from "../pages/student/study-tool/report/ReportPage";
import CalendarPage from "../pages/student/study-tool/schedule/calendar";
import EventPage from "../pages/student/study-tool/schedule/event";
import RevisionReviewPage from "../pages/student/study-tool/revision-review/revision-review";
import ListProductPage from "../pages/student/product/ListProduct";
import ProductDetailPage from "../pages/student/product/ProductDetail";
import StudentCartPage from "../pages/student/cart/StudentCart";
import StudentPaymentPage from "../pages/student/payment";
import TermOfUsePage from "../pages/student/policy/TermOfUse";
import PrivacyPolicyPage from "../pages/student/policy/PrivacyPolicy";
import CommercialPage from "../pages/student/policy/Commercial";
import OrderSuccessPage from "../pages/student/payment/OrderSuccess";
import PurchaseHistory from "../pages/student/profile/order/purchase-history";

type CustomRouterObject = {};

const studentRouter: Array<RouteObject & CustomRouterObject> = [
  {
    path: ROUTE.MY_PAGE.DEFAULT,
    children: [
      { index: true, element: <MyPage /> },
      {
        path: ROUTE.MY_PAGE.COURSE_LIST,
        element: <CourseLists />,
      },
      {
        path: ROUTE.MY_PAGE.STUDY_HISTORY,
        element: <StudyHistory />,
      },

      // Study-tools
      {
        path: ROUTE.STUDY_TOOL.DEFAULT,
        children: [
          {
            // Schedule
            path: ROUTE.STUDY_TOOL.SCHEDULE.DEFAULT,
            children: [
              { index: true, element: <CalendarPage /> },
              {
                path: ROUTE.STUDY_TOOL.SCHEDULE.CALENDAR.DEFAULT,
                element: <CalendarPage />,
              },
              {
                path: ROUTE.STUDY_TOOL.SCHEDULE.EVENT.DEFAULT,
                element: <EventPage />,
              },
            ],
          },
          {
            path: PATH_STUDY_TOOL.MEMO,
            element: <MemoList />,
          },
          {
            path: PATH_STUDY_TOOL.CREATE_MEMO,
            element: <MemoRegistration />,
          },
          {
            path: `${PATH_STUDY_TOOL.DETAIL_MEMO}/:id`,
            element: <MemoView />,
          },
          {
            path: `${PATH_STUDY_TOOL.EDIT_MEMO}/:id`,
            element: <MemoRegistration />,
          },

          // Report.
          {
            path: PATH_STUDY_TOOL.REPORT,
            element: <ReportPage />,
          },

          // Revision review
          {
            path: PATH_STUDY_TOOL.REVISION_REVIEW,
            element: <RevisionReviewPage />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTE.COURSES.DEFAULT,
    children: [
      { index: true, element: <Courses /> },
      {
        path: ROUTE.COURSES.LECTURES,
        children: [
          { index: true, element: <Lectures /> },
          {
            path: ROUTE.COURSES.UNITS,
            element: <UnitPage />,
          },
          {
            path: ROUTE.COURSES.DETAIL,
            element: <UnitPage />,
          },
        ],
      },
    ],
  },
  {
    path: ROUTE.PROFILE.DEFAULT,
    children: [
      { index: true, element: <Profile /> },
      {
        path: ROUTE.PROFILE.MAIL_CHANGING,
        element: <MailChanging />,
      },
      {
        path: ROUTE.PROFILE.PASSWORD_CHANGING,
        element: <PasswordChanging />,
      },
      {
        path: ROUTE.PROFILE.PURCHASE_HISTORY,
        element: <PurchaseHistory />
      }
    ],
  },

  {
    path: PATH_COURSE.DEFAULT,
    children: [
      { index: true, element: <Courses /> },
      {
        path: ROUTE.COURSES.LECTURES,
        children: [
          { index: true, element: <Lectures /> },
          {
            path: ROUTE.COURSES.UNITS,
            element: <UnitPage />,
          },
          {
            path: ROUTE.COURSES.DETAIL,
            element: <UnitPage />,
          },
        ],
      },
      {
        path: PATH_COURSE.EXECUTE_TEST,
        element: <ExecuteTestPage />,
      },
      {
        path: PATH_COURSE.CONTINUE_TEST,
        element: <ExecuteTestPage />,
      },
      {
        path: PATH_COURSE.EXPLANATION,
        element: <ExplanationPage />,
      },
    ],
  },

  {
    path: ROUTE.MY_PAGE.COURSE_LIST,
    element: <CourseLists />,
  },
  {
    path: ROUTE.MY_PAGE.STUDY_HISTORY,
    element: <StudyHistory />,
  },
  {
    path: PATH_PRODUCT.DEFAULT,
    children: [
      { index: true, element: <ListProductPage /> },
      { path: `${PATH_PRODUCT.DETAIL}`, element: <ProductDetailPage /> },
    ],
  },
  {
    path: PATH_STUDENT_CART.DEFAULT,
    element: <StudentCartPage />,
  },
  {
    path: PATH_STUDENT_CART.PAYMENT,
    element: <StudentPaymentPage />,
  },
  {
    path: PATH_STUDENT_CART.ORDER_SUCCESS,
    element: <OrderSuccessPage />,
  },
  {
    path: PATH_POLICY.DEFAULT,
    children: [
      {
        path: PATH_POLICY.TERM_OF_USE,
        element: <TermOfUsePage />,
      },
      {
        path: PATH_POLICY.PRIVACY_POLICY,
        element: <PrivacyPolicyPage />,
      },
      {
        path: PATH_POLICY.COMMERCIAL_TRANSACTION_LAW,
        element: <CommercialPage />,
      },
    ],
  },
];

export default studentRouter;
