import Button from "../../Login/Button";
import ErrorBox from "../../commons/form/ErrorBox";
import { useState } from "react";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { ErrorData } from "../../../types/globals";
import { swalError, swalSuccess } from "../../../services/helpers/swal";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { VERIFY_STEP } from "../../../services/constants/student/completeStudentRegistration";
import { redirect, useNavigate } from "react-router-dom";
import { ROUTE } from "../../../services/constants/route/router";

interface ResendMailCompleteStudentRegistrationStepProp {
  handleStep: Function;
  studentThemeColor: any;
}

const ResendMailCompleteStudentRegistrationStep = (
  props: ResendMailCompleteStudentRegistrationStepProp
) => {
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
  let navigate = useNavigate();
  const { getParamValue } = useObjectRoutes();
  let token = getParamValue("token");
  let { handleStep, studentThemeColor } = props;

  let [email, setEmail] = useState("");
  const [errors, setErrors] = useState<ErrorData>({});

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const resendEmailVerifyCode = async (e: any) => {
    e.preventDefault();
    setIsDisableButton(true);
    const result = await makeRequest({
      method: "post",
      url: API.AUTH.RESEND_VERIFY_EMAIL,
      data: {
        email: email,
      },
    });
    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError();
    }

    swalSuccess();
    handleStep(VERIFY_STEP);
    navigate(
      `${ROUTE.COMPLETE_STUDENT_REGISTRATION}?token=${result.data.token}`,
      { replace: true }
    );
  };

  return (
    <form
      style={{ color: studentThemeColor.text_color }}
      className="max-sm:w-full mb-[42px] mt-[30px] mx-auto max-sm:mt-[25px] max-sm:px-[10px] max-sm:mb-[32px] animate-[show_0.5s_ease-in-out]"
      onSubmit={resendEmailVerifyCode}
    >
      <div className="sm:w-[750px] max-sm:w-full mb-[30px] max-sm:mb-[25px] bg-[#F8F7F6] px-[25px] border border-warning-light">
        <p className="mt-[30px] max-sm:mt-[25px] mb-[20px] text-[17px] font-bold">
          コード再信
        </p>
        <div className="flex flex-col text-center">
          <p className="my-[5px] max-sm:my-[5px] text-[15px]">
            ご登録メールアドレスに確認コードをお届けします。
          </p>
          <p className="my-[5px] max-sm:my-[5px] text-[15px]">
            確認コードを受け取るメールアドレスを入力してください。
          </p>
        </div>

        {errors && !!Object.keys(errors).length && (
          <div className="mb-[20px] text-left">
            <ErrorBox errors={errors} />
          </div>
        )}
        <div className="mt-[20px] mb-[30px]">
          <input
            type="text"
            name="email"
            className="max-w-[600px] mx-auto min-h-[50px] border border-warning-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F] placeholder:text-sm"
            placeholder="メールアドレスを入力"
            onChange={handleChangeInput}
          />
        </div>
        <div className={"flex flex-col w-[250px] mx-auto"}>
          <Button
            style={{
              backgroundColor: studentThemeColor.main_color_first,
              marginBottom: "20px",
            }}
            content={"コードを再信"}
            otherstyle={"bg-primary text-white max-sm:text-[18px] "}
            type="submit"
          />

          <Button
            style={{
              marginBottom: "30px",
            }}
            content={"キャセル"}
            otherstyle={"bg-gray-400 text-white max-sm:text-[18px] "}
            onClick={() => handleStep(VERIFY_STEP)}
          />
        </div>

        <p className="mt-[5px] max-sm:my-[5px] text-red-500 mb-[15px] text-[15px]">
          管理者が登録したメールアドレスを入力してください
        </p>
      </div>
    </form>
  );
};

export default ResendMailCompleteStudentRegistrationStep;
