import CouponBase from ".";
import CouponList from "../../../components/admin/Coupon/List/List";

const CouponListPage = () => {
  return (
    <CouponBase title={` 講座販売 ｜ クーポン管理`}>
      <CouponList />
    </CouponBase>
  );
};

export default CouponListPage;