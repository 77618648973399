import type { ChartOptions } from 'chart.js';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { ChartProps, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

export type LineChartProps = Omit<ChartProps<'line'>, 'type'>;

const LineChart = ({ data, options, ...props }: LineChartProps) => {
  const defaultChartOptions: ChartOptions<'line'> = {
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 10
        }
      },
      y: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          callback: function (val, index) {
            // Hide every 2nd tick label
            if (typeof val === 'number') {
              return index % 2 === 0 ? this.getLabelForValue(val) : '';
            }
          },
          padding: 30,
        },

        border: {
          display: false,
        },
      },
    },
    responsive: true,
  };

  return <Line data={data} options={options || defaultChartOptions} {...props} />;
};

export default LineChart;
