/* eslint-disable react-hooks/exhaustive-deps */
import TableHeadItem from '../../commons/TableHeadItem';
import TableItem from './CourseTableItem';
import { Fragment, useState, useEffect } from 'react';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { Course } from '../../../../types/admin/course';
import { filterUniqueArray, sortArrayByKey } from '../../../../services/helpers/parseData';
export type MemoriedExpandStatus = {
  [key: string]: boolean;
};

const CourseTable = () => {
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    const fetchCourses = async () => await request.get(API.ADMIN_COURSE.LIST, (fetchedCourses) => {
      const uniqueCourses = filterUniqueArray(fetchedCourses);
      setCourses(sortArrayByKey(uniqueCourses, 'order'));
    });
    fetchCourses();
  }, []);

  return (
    <section>
      <div className="flex bg-secondary-extralight items-center justify-between w-full mt-[15px] mb-[8px]">
        <TableHeadItem className="w-[60%] !border-l-0 !justify-start !pl-[10px]">
          学習コース
        </TableHeadItem>
        <TableHeadItem className="w-[10%]">講義数</TableHeadItem>
        <TableHeadItem className="w-[10%]">ステータス</TableHeadItem>
        <TableHeadItem className="w-[30%] !border-r-0 text-secondary-extralight"></TableHeadItem>
      </div>

      <div className="">
        {courses &&
          courses.map((course) => (
            <Fragment key={course.id}>
              <TableItem data={course} />
            </Fragment>
          ))}
      </div>
    </section>
  );
};

export default CourseTable;
