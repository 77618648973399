import React from 'react';
import CourseBase from '.';
import Form from '../../../components/admin/Course/Setting/Form';
import TableCommonAction from '../../../components/admin/commons/TableCommonAction';
import { useObjectRoutes } from '../../../hooks/useObjectRoutes';
import { PATH_ADMIN_MATERIAL } from '../../../services/constants/route/router';

const ChapterRegistrationPage = () => {
  const { navigate } = useObjectRoutes();

  return (
    <CourseBase title="学習コース管理 ｜ 章の詳細">
      <TableCommonAction
        className="mb-[60px]"
        createBtnLabel="教材の新規登録"
        onCreate={() => navigate(PATH_ADMIN_MATERIAL.UNIT_UPSERT)}
      />
      <Form />
    </CourseBase>
  );
};

export default ChapterRegistrationPage;
