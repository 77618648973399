import ProductBase from ".";
import ProductList from "../../../components/admin/Product/List/List";
import ProductListProvider from "../../../context/ProductContext";

const ProductListPage = () => {
  return (
    <ProductBase title="講座販売 ｜ 商品一覧">
      <ProductListProvider>
        <ProductList />
      </ProductListProvider>
    </ProductBase>
  );
};

export default ProductListPage;
