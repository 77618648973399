import Button from "../../commons/buttons/Button";
import { FiShoppingCart } from "react-icons/fi";
import { formatNumberWithCommas } from "../../../services/helpers/parseData";
import LayoutWaitingApi from "../common/LayoutWaitingApi";
import { PAYMENT_METHOD_TYPE } from "../../../services/constants/student/payment";
import { PaymentDataType } from "../../../types/student/payment";

type PaymentFrameworkProps = {
  total: number;
  amountDiscount: number;
  waitingApi?: boolean;
  services: string[];
  handleOnChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  handleCheckout(): void;
  paymentData: PaymentDataType;
};
const PaymentFramework = ({
  total,
  amountDiscount,
  waitingApi,
  services,
  handleOnChange,
  handleCheckout,
  paymentData,
}: PaymentFrameworkProps) => {
  
  return (
    <div className="min-w-[190px] mt-[60px]">
      <div className="w-full p-[15px] border border-danger-light rounded-[5px] flex flex-col gap-[10px]">
        <div className="flex items-center justify-between py-[10px] border-b border-b-secondary-light text-[12px] overflow-ellipsis">
          <LayoutWaitingApi className="w-full h-[20px]" waitingApi={waitingApi}>
            <>
              <span className="px-[5px]">小計</span>
              <span className="px-[5px] animate-[show_0.5s_ease-in-out]">
                {formatNumberWithCommas(total)}円
              </span>
            </>
          </LayoutWaitingApi>
        </div>
        <div className="flex items-center justify-between py-[10px] border-b border-b-secondary-light text-[12px] overflow-ellipsis">
          <span className="px-[5px]">クーポン利用</span>
          <span className="px-[5px] max-w-[80px] overflow-hidden whitespace-nowrap">
            -{formatNumberWithCommas(amountDiscount)}円
          </span>
        </div>
        <div className="flex items-center justify-between py-[6.5px] px-[13px]">
          <LayoutWaitingApi className="w-full h-[20px]" waitingApi={waitingApi}>
            <>
              <span className="text-[14px]">合計</span>
              <span
                className={`text-danger font-[700] animate-[show_0.5s_ease-in-out] ${
                  total.toString().length >= 8 ? "text-[16px]" : "text-[18px]"
                }`}
              >
                {formatNumberWithCommas(total - amountDiscount)}円
              </span>
            </>
          </LayoutWaitingApi>
        </div>
        <div className="flex items-center justify-between">
          <select
            name="payment_method"
            id=""
            className={
              "w-full max-w-[320px] h-[30px] pl-[13px] pr-[20px] text-[12px] leading-[100%] truncate"
            }
            onChange={handleOnChange}
          >
            <option value="0" disabled selected>
              決済方法
            </option>
            {services &&
              !!services.filter(
                (service: string) => service === PAYMENT_METHOD_TYPE.CARD.VALUE
              ).length && (
                <option
                  value={PAYMENT_METHOD_TYPE.CARD.VALUE}
                  key={PAYMENT_METHOD_TYPE.CARD.VALUE}
                >
                  {PAYMENT_METHOD_TYPE.CARD.LABEL}
                </option>
              )}
            {services &&
              !!services.filter(
                (service: string) =>
                  service === PAYMENT_METHOD_TYPE.KONBINI.VALUE
              ).length && (
                <option
                  value={PAYMENT_METHOD_TYPE.KONBINI.VALUE}
                  key={PAYMENT_METHOD_TYPE.KONBINI.VALUE}
                >
                  {PAYMENT_METHOD_TYPE.KONBINI.LABEL}
                </option>
              )}
            {services &&
              !!services.filter(
                (service: string) =>
                  service === PAYMENT_METHOD_TYPE.INVOICE.VALUE
              ).length && (
                <option
                  value={PAYMENT_METHOD_TYPE.INVOICE.VALUE}
                  key={PAYMENT_METHOD_TYPE.INVOICE.VALUE}
                >
                  {PAYMENT_METHOD_TYPE.INVOICE.LABEL}
                </option>
              )}
          </select>
        </div>
        <div>
          <Button
            className={`w-full h-[40px] px-[15px] py-[10px] bg-danger text-white text-[14px] font-[500] rounded-[5px] tracking-[0.28px] active:scale-90 ${
              !paymentData.payment_method_type
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={() => {
              handleCheckout();
            }}
            disabled={!paymentData.payment_method_type}
          >
            <FiShoppingCart size={18} className="text-white" />
            決済画面へ進む
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFramework;
