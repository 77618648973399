import StudentLayout from "../../../layouts/student";
import MyPageTop from "../../../components/student/MyPage/MyPageTop";

const MyPage = () => {
  return (
    <StudentLayout gap>
      <MyPageTop/>
    </StudentLayout>
  );
};

export default MyPage;
