/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { useObjectRoutes } from "../hooks/useObjectRoutes";
import { Unit } from "../types/admin/unit";
import { ChildrenWithProps, SetStateAction } from "../types/globals";
import { TestHistory } from "../types/student/unit";

export type ShowSettings<T = boolean> = {
  isLatestHistory?: T;
  isReviewTestTable?: T;
  isTestGroup?: T;
};

type StudentUnitContextType = {
  showSettings: ShowSettings;
  setShowSettings: SetStateAction<ShowSettings>;
  unitData: Unit;
  setUnitData: SetStateAction<Unit>;
  currentHistory: TestHistory;
  setCurrentHistory: SetStateAction<TestHistory>;
  triggerRefetch: number;
  setTriggerRefetch: SetStateAction<number>;
  currentHistoryId: number;
  setCurrentHistoryId: SetStateAction<number>;
};

const StudentUnitContext = React.createContext<StudentUnitContextType>({
  showSettings: {},
  setShowSettings: () => {},
  unitData: {},
  setUnitData: () => {},
  currentHistory: {},
  setCurrentHistory: () => {},
  triggerRefetch: 0,
  setTriggerRefetch: () => {},
  currentHistoryId: 0,
  setCurrentHistoryId: () => {},
});

const StudentUnitProvider = ({ children }: ChildrenWithProps) => {
  const {searchParamsObject} = useObjectRoutes()

  const [showSettings, setShowSettings] = useState<ShowSettings>({
    isLatestHistory: true,
    isReviewTestTable: !!Number(searchParamsObject?.with_result) || false,
  });

  const [unitData, setUnitData] = useState<Unit>({});
  const [currentHistory, setCurrentHistory] = useState<TestHistory>({});
  const [triggerRefetch, setTriggerRefetch] = useState<number>(0);
  const [currentHistoryId, setCurrentHistoryId] = useState<number>(0);

  return (
    <StudentUnitContext.Provider
      value={{
        showSettings,
        setShowSettings,
        unitData,
        setUnitData,
        currentHistory,
        setCurrentHistory,
        triggerRefetch,
        setTriggerRefetch,
        currentHistoryId,
        setCurrentHistoryId,
      }}
    >
      {children}
    </StudentUnitContext.Provider>
  );
};

export default StudentUnitProvider;

export const useStudentUnitContext = () => useContext(StudentUnitContext);
