import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/student/Profile/Sidebar';
import StudentLayout from '../../../layouts/student';
import EditButton from '../../../components/student/Profile/EditButton';
import UserInformation from '../../../components/student/Profile/UserInformation';
import ProfileSetting from '../../../components/student/Profile/ProfileSetting';
import { User } from '../../../types/user';
import { makeRequest } from '../../../services/axios/axios';
import { API } from '../../../services/constants/route/api';
import Swal from 'sweetalert2';
import { swalLoading, swalMessage } from '../../../services/helpers/swal';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../../services/constants/route/router';

const Profile = () => {
  const [isEditUserInformation, setIsEditUserInformation] = useState<boolean>(false);
  const [isEditProfileSetting, setIsEditProfileSetting] = useState<boolean>(false);
  const [userData, setUserData] = useState<User>({} as any);

  useEffect(() => {
    const asyncFn = async () => {
      // swalLoading();
      const userResult = await makeRequest({ method: 'get', url: API.USER.INFO });

      if (!userResult.data) {
        return swalMessage('error', 'error', 'error');
      }

      setUserData(userResult.data);
      Swal.close();
    };

    asyncFn();
  }, []);

  return (
    <StudentLayout sidebar={<Sidebar data={userData}/>} cover>
      <div className="w-full">
        {/* title */}
        <div className="border-b border-danger-light pb-[14px] w-full mb-[60px] text-[14px] font-[500] leading-[100%]">
          お客様情報
        </div>
        {/* gmail */}
        <div className="w-full mb-[50px]">
          <div className="font-[700] text-[16px] leading-[100%] mb-[18px]">登録メールアドレス</div>
          <div className="flex items-center h-[40px]">
            <div className="w-full max-w-[220px] pl-[14px] py-[12px] bg-warning-lighter font-[500] text-[14px] leading-[100%]">
              メールアドレス
            </div>
            <div className="w-full max-w-[485px] flex justify-between pl-[13px]">
              <div className="mb-[-4px]">{userData.email}</div>
              <Link to={`${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.MAIL_CHANGING}`}>
                <EditButton 
                className='min-w-[240px]'
                label="メールアドレスの変更はこちら" 
                />
              </Link>
            </div>
          </div>
        </div>

        {/* user information */}
        <div className="">
          <div className="font-[700] text-[16px] leading-[100%] mb-[18px]">お客様情報</div>
          <div className="flex gap-[10px] items-center justify-between pr-[120px]">
            <div className="text-[12px] leading-[190%]">
              変更箇所を入力し、「保存する」ボタンをクリックしてください。
            </div>
            {!isEditUserInformation && (
              <EditButton
                className='min-w-[200px]'
                label="お客様情報を編集する"
                onClick={() => setIsEditUserInformation(true)}
              />
            )}
          </div>
          <UserInformation
            data={userData}
            isEdit={isEditUserInformation}
            setIsEdit={setIsEditUserInformation}
          />
        </div>

        {/* profile settings */}
        <div className="">
          <div className="font-[700] text-[16px] leading-[100%] mb-[18px]">プロフィール設定</div>
          <div className="flex gap-[10px] items-center justify-between pr-[120px]">
            <div className="text-[12px] leading-[190%]">
              変更箇所を入力し、「保存する」ボタンをクリックしてください。
            </div>
            {!isEditProfileSetting && (
              <EditButton
                className='min-w-[210px]'
                label="プロフィールを編集する"
                onClick={() => setIsEditProfileSetting(true)}
              />
            )}
          </div>
          <ProfileSetting
            data={userData}
            isEdit={isEditProfileSetting}
            setIsEdit={setIsEditProfileSetting}
          />
        </div>
      </div>
    </StudentLayout>
  );
};

export default Profile;
