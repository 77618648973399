import { useNavigate } from "react-router-dom";
import CartButton from "../../../../components/commons/buttons/CartButton";
import StudentLayout from "../../../../layouts/student";
import { WithChildren } from "../../../../types/globals";
import { PATH_STUDY_TOOL } from "../../../../services/constants/route/router";

const StudyToolBase = ({ children }: WithChildren) => {
  const navigate = useNavigate();

  return (
    <StudentLayout gap>
      <section className="border-b pb-[14px] flex items-center justify-between mb-[10px] ">
        <div className="flex justify-between items-center w-full h-[34px] px-[5px]">
          <div
            className="text-[14px] font-[500] cursor-pointer"
            onClick={() => navigate(PATH_STUDY_TOOL.MEMO)}
          >
            学習レポート
          </div>
          <div>
            <CartButton />
          </div>
        </div>
      </section>
      {children}
    </StudentLayout>
  );
};

export default StudyToolBase;
