import React, { ChangeEvent } from 'react';
import { Section } from '../../../../types/admin/section';
import { FiTrash2 } from 'react-icons/fi';
import { SectionGroupType } from './Form';

type FormSectionGroupType = {
  groupIndex: number;
  sections: Section[];
  subSections: Section[];
  deletable: boolean;
  data: SectionGroupType;
  sectionGroups: SectionGroupType[];
  setSectionGroups: React.Dispatch<React.SetStateAction<SectionGroupType[]>>;
  onRemoveSectionGroup: (groupIndex: number) => void;
};

const FormSectionGroup = ({
  groupIndex,
  sections,
  subSections,
  deletable,
  data,
  sectionGroups,
  setSectionGroups,
  onRemoveSectionGroup,
}: FormSectionGroupType) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newSectionGroups = [...sectionGroups];
    newSectionGroups[groupIndex][e.target.name as keyof SectionGroupType] = Number(e.target.value);
    if (e.target.name === 'parent_section_id') newSectionGroups[groupIndex].sub_section_id = 0;
    setSectionGroups(newSectionGroups);
  };

  return (
    <div className="flex gap-[10px] mb-[15px]">
      <select
        name="parent_section_id"
        id=""
        className="w-full max-w-[320px] h-[30px] pl-[13px] text-[12px]"
        value={data.parent_section_id}
        onChange={handleChange}
      >
        <option value="0" disabled>
          セクションを選択
        </option>
        {sections.map((section, index) => (
          <option value={section.id} key={index}>
            {section.name}
          </option>
        ))}
      </select>

      <select
        name="sub_section_id"
        id=""
        className="w-full max-w-[320px] h-[30px] pl-[13px] text-[12px]"
        value={data.sub_section_id}
        onChange={handleChange}
      >
        <option value="0" disabled>
          サブセクションを選択
        </option>
        {subSections.map((subSection, index) => (
          <option value={subSection.id} key={index}>
            {subSection.name}
          </option>
        ))}
      </select>
      <div className="">
        <div className="text-secondary flex items-center w-[25px] h-[30px] cursor-pointer">
          {deletable && <FiTrash2 onClick={() => onRemoveSectionGroup(groupIndex)} size={21} />}
        </div>
      </div>
    </div>
  );
};

export default FormSectionGroup;
