import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Unit } from "../../../../../types/admin/unit";
import {
  getMillisecondsFromTimeObject,
  getTimeObjectFromMilliseconds,
} from "../../../../../services/helpers/formatTime";

type UnitEstimationRequiredTimeProps = {
  formData?: Unit;
  setFormData?: Dispatch<SetStateAction<Unit>>;
};

type TimeGroup = {
  hours?: number;
  minutes?: number;
  seconds?: number;
};

const UnitEstimationRequiredTime = ({
  formData,
  setFormData,
}: UnitEstimationRequiredTimeProps) => {
  const [timeGroup, setTimeGroup] = useState<TimeGroup>({});
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    //limit character by 2
    const timeGroupChanged = {
      ...timeGroup,
      [name]: value.length > 2 ? value.slice(0, -1) : value,
    };
    setTimeGroup(timeGroupChanged);
    setFormData?.({
      ...formData,
      estimate_time_required: getMillisecondsFromTimeObject(timeGroupChanged),
    });
  };

  useEffect(() => {
    if (!formData?.estimate_time_required) return;
    setTimeGroup(
      getTimeObjectFromMilliseconds(formData?.estimate_time_required)
    );
  }, [formData?.estimate_time_required]);

  return (
    <div className="flex w-full gap-[10px] items-center">
      <input
        type="number"
        name="hours"
        id=""
        placeholder="00"
        className="w-full max-w-[90px] text-right"
        onChange={handleChange}
        value={timeGroup.hours || ""}
      />
      時間
      <input
        type="number"
        name="minutes"
        id=""
        placeholder="00"
        className="w-full max-w-[90px] text-right"
        onChange={handleChange}
        value={timeGroup.minutes || ""}
      />
      分
    </div>
  );
};

export default UnitEstimationRequiredTime;
