import React, { ChangeEvent, useEffect } from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { useStudentContext } from "../../../context/StudentContext";
import { formatTime } from "../../../services/helpers/formatTime";
import Pagination from "./Pagination";
import { Student } from "../../../types/admin/student";
import { Link } from "react-router-dom";
import { STUDENT_STATUS_DATA } from "../../../services/constants/admin/pages/student";
import dayjs from "dayjs";
import { swalMessage } from "../../../services/helpers/swal";

const StudentTable = ({
  handlePageChange,
  currentPage,
  _students,
  toggleSelect,
  selectAll,
  toggleSelectAll,
  isStudentSelected,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  _students: Student[];
  toggleSelect: (id: number) => void;
  selectAll: boolean;
  toggleSelectAll: () => void;
  isStudentSelected: (id: number) => boolean;
}) => {
  const { totalPages } = useStudentContext();

  const formatLastLoginTime = (lastLoginTime?: string) => {
    if (!lastLoginTime) return "";
    return dayjs(lastLoginTime).format("YYYY/MM/DD HH:mm:ss");
  };

  const handleCheckStudent = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    student: Student
  ) => {
    if (student?.status === STUDENT_STATUS_DATA.CONFIRMING) {
      e.preventDefault();
      swalMessage(
        "メール確認待ち中です！",
        "メール確認が完了しませんため編集できません。",
        "error",
        {
          timer: 2000,
        }
      );
    }
  };

  return (
    <>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="px-[10px] py-[2px] border-x-4 border-white whitespace-nowrap "
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
              </th>
              <th
                scope="col"
                className="2xl:px-[25px] py-[5px] xl:px-[5px] lg:px-0 px-0 border-x-4 border-white whitespace-nowrap"
              ></th>
              <th
                scope="col"
                className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                氏名
              </th>
              <th
                scope="col"
                className="2xl:px-[130px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ログインID
              </th>
              <th
                scope="col"
                className="2xl:px-[43px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ステータス
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                最終ログイン
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px]border-x-4 border-white whitespace-nowrap"
              >
                合計学習時間
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              ></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(_students) &&
              _students &&
              !!_students.length &&
              _students.map((student) => (
                <tr
                  key={student.id}
                  className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
                >
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={isStudentSelected(student.id)}
                      onChange={() => toggleSelect(student.id)}
                    />
                  </td>
                  <td className="">
                    {student.avatar_url === null ? (
                      <div className="flex justify-center items-center">
                        <HiOutlineUserCircle color="#BEBEBE" size={40} />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center">
                        <img
                          className="object-cover w-[40px] h-[40px]"
                          src={student.avatar_url}
                          width={40}
                          alt={student?.first_name}
                        />
                      </div>
                    )}
                  </td>
                  <th className="px-6 py-4 text-[14px] font-[500] text-primary whitespace-nowrap">
                    <Link
                      to={`./details/${student.id}`}
                      onClick={(
                        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                      ) => {
                        handleCheckStudent(e, student);
                      }}
                    >
                      {`${student.last_name} ${student.first_name}`}
                    </Link>
                  </th>
                  <td className="px-12 py-4 whitespace-nowrap">
                    {student.username}
                  </td>
                  <td className="py-4 text-center m-auto">
                    {student.status === STUDENT_STATUS_DATA.CONFIRMING && ( // button for isActive=0
                      <div className="min-w-[110px] px-[2px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <RiErrorWarningLine
                          className="absolute top-[2px] left-[8px]"
                          size={18}
                        />
                        <p className="pl-[12px]">確認中</p>
                      </div>
                    )}
                    {student.status === STUDENT_STATUS_DATA.ACTIVE && ( // button for isActive=1
                      <div className="w-[50px] max-h-[25px] px-[6px] pb-[5px] text-danger border border-danger rounded-[5px] align-middle inline-block whitespace-nowrap">
                        有効
                      </div>
                    )}
                    {student.status === STUDENT_STATUS_DATA.DEACTIVE && ( // button for isActive=2
                      <div className="min-w-[100px] px-[2px] pt-[3px] pb-[5px] text-[#7A7A7A] bg-neutral-100 rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <IoMdRemoveCircleOutline
                          className="absolute top-[2px] left-[4px]"
                          size={18}
                        />
                        <p className="pl-[20px]">停止中</p>
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {formatLastLoginTime(student.last_login_at)}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {formatTime(student.total_activity_time)}
                  </td>
                  <td className="text-center">
                    <Link
                      to={`./details/${student.id}`}
                      onClick={(
                        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                      ) => {
                        handleCheckStudent(e, student);
                      }}
                    >
                      <button className="min-w-[50px] text-[14px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary text-white">
                        編集
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages && totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default StudentTable;
