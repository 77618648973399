import React, { useEffect, useRef, useState } from "react";
import cloud_upload from "../../../assests/images/icons/cloud_upload.svg";
import { BiSearchAlt2 } from "react-icons/bi";
import { UNIT_FILE_TYPE } from "../../../services/constants/admin/pages/material";
import { makeRequest, request } from "../../../services/axios/axios";
import { Folder, File as FolderFile } from "../../../types/admin/folder";
import { API } from "../../../services/constants/route/api";
import FileSearchItem from "../Material/Upsert/FileSearchItem";
import { S3 } from "aws-sdk";
import {
  swalClose,
  swalError,
  swalLoading,
} from "../../../services/helpers/swal";
import { getSubdomainFromUrl } from "../../../services/helpers/domain";
import { Question, QuestionAnswers } from "../../../types/admin/unit";
import { MESSAGE_COMMON } from "../../../services/constants/message";
import {
  PREFIX_PATH_S3,
  SERVICE_ID,
  TYPE_CAPACITY,
} from "../../../services/constants/globals";
import { formatCapacity } from "../../../services/helpers/formatCapacity";
import { StorageType } from "../../../types/student/storageType";
import { useAuthContext } from "../../../context/AuthContext";
import { getUploadFilePath } from "../../../services/helpers/uploadFile";
import ProgressBar from "./ProgressBar";

const bytesToMegabytes = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2);
};
type UploadQuestionImageProps = {
  fileType?: number | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAnswers?: React.Dispatch<React.SetStateAction<QuestionAnswers[]>>;
  setQuestionForm?: React.Dispatch<React.SetStateAction<Question>>;
  isAttatchment?: boolean;
  index?: number;
  setResourceLink?: React.Dispatch<React.SetStateAction<string>>;
};
type FileTypeConfig = {
  label: string;
  mimeTypes: string[];
  maxSize: number;
  maxSizeName: string;
};
const fileTypes: { [key: string]: FileTypeConfig } = {
  4: {
    label: "pdf",
    mimeTypes: ["application/pdf"],
    maxSize: 100 * 1024 * 1024,
    maxSizeName: "100MB",
  }, // 100MB
  5: {
    label: "image",
    mimeTypes: ["image/jpeg", "image/png"],
    maxSize: 10 * 1024 * 1024,
    maxSizeName: "10MB",
  }, // 10MB
  2: {
    label: "video",
    mimeTypes: ["video/mp4", "video/quicktime"],
    maxSize: 2 * 1024 * 1024 * 1024,
    maxSizeName: "2GB",
  }, // 2GB
  3: {
    label: "mp3",
    mimeTypes: ["audio/mpeg"],
    maxSize: 2 * 1024 * 1024 * 1024,
    maxSizeName: "2GB",
  }, // 2GB
};

const UploadQuestionImage = ({
  fileType,
  setIsOpen,
  setAnswers,
  setQuestionForm,
  isAttatchment,
  index,
  setResourceLink,
}: UploadQuestionImageProps) => {
  const [processPercent, setProcessPercent] = useState<number>(0);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [error, setError] = useState<string | null>("");
  const [folderList, setFolderList] = useState<Folder[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [searchData, setSearchData] = useState<FolderFile[]>([]);
  const [originFilename, setOriginFilename] = useState<string>("");
  const [folderId, setFolderId] = useState<string | number | null>("");
  const [folderCode, setFolderCode] = useState<number | null>(null);
  const [storageData, setUsedStorageData] = useState<StorageType>({
    usedStorage: 0,
    totalStorage: 0,
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const { user } = useAuthContext();
  const oz_id = user.organization_id;

  useEffect(() => {
    const fetch = async () => {
      const [result, storageResult] = await Promise.all([
        makeRequest({
          method: "get",
          url: `${API.ADMIN_FOLDER.LIST}?sortType=1&limit=1000`,
        }),

        makeRequest({
          method: "get",
          url: `${API.ADMIN_FOLDER.CAPACITY}`,
        }),
      ]);

      if (!result?.data || storageResult?.data === null) return swalError();
      setFolderList([...folderList, ...result?.data?.resource_folders]);
      setUsedStorageData({
        ...storageData,
        usedStorage: storageResult?.data?.["used-storage"],
        totalStorage: storageResult?.data?.total,
      });
      swalClose();
    };

    fetch();
  }, []);

  useEffect(() => {
    const fetchFileData = async () => {
      await request.get(
        `${
          API.ADMIN_FOLDER.FILE
        }?resource_folder_id=${folderId}&origin_filename=${originFilename}&resource_type=${
          fileType ? fileTypes[fileType].label : ""
        }`,
        (fileResults) => setSearchData(fileResults.resources)
      );
    };
    fetchFileData();
  }, [originFilename, folderId]);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (disabled) return;

    event.preventDefault();
    setError(null);
    const files = event.dataTransfer.files;
    handleFiles(files);
  };
  const handleFolderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") setFolderId("");
    const [id, code] = e.target.value.split("|");

    setFolderId(id);
    setFolderCode(Number(code));
    setDisabled(false);
  };
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    handleFiles(files);
  };

  const handleFiles = async (files: FileList | null) => {
    if (!files) return;
    if (!folderCode) {
      setError(`保管場所を選択してください`);
      return;
    }
    const file = files[0];

    const fileTypeConfig = fileType ? fileTypes[fileType] : null;
    if (!fileTypeConfig) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    const allowedMimeTypes = fileTypeConfig.mimeTypes;
    const maxSize = fileTypeConfig.maxSize;

    if (!allowedMimeTypes.includes(file.type)) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    if (file.size > maxSize) {
      setError(
        `添付ファイルのサイズが${fileTypeConfig.maxSizeName}を越えています`
      );
      return;
    }
    const totalStorageFormatKB = formatCapacity(
      storageData?.totalStorage || 0,
      TYPE_CAPACITY.GB,
      TYPE_CAPACITY.KB
    );
    const usedStorageFormatKB = formatCapacity(
      storageData?.usedStorage || 0,
      TYPE_CAPACITY.MB,
      TYPE_CAPACITY.KB
    );
    const fileSizeFormatKB = formatCapacity(
      file.size,
      TYPE_CAPACITY.BYTE,
      TYPE_CAPACITY.KB
    );
    if (
      Number(fileSizeFormatKB + usedStorageFormatKB) >
      Number(totalStorageFormatKB)
    ) {
      setError(`十分な収納スペースがない`);
      return;
    }
    setDisabled(true);

    // swalLoading();
    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    const fileName = `${Date.now()}.${fileExtension}`;
    const folderPath = getUploadFilePath(oz_id, folderCode);
    let contentType = "";
    switch (fileExtension) {
      case "pdf":
        contentType = "application/pdf";
        break;
      case "mp3":
        contentType = "audio/mpeg";
        break;
      case "mp4":
        contentType = "video/mp4";
        break;
      case "jpg":
      case "jpeg":
        contentType = "image/jpeg";
        break;
      case "png":
        contentType = "image/png";
        break;
      default:
        contentType = "application/octet-stream";
        break;
    }
    const uploadS3 = await s3.putObject({
      Bucket: process.env.REACT_APP_AWS_BUCKET || "",
      Key: folderPath + fileName,
      Body: file,
      ContentType: contentType,
      ACL: "public-read",
    });

    uploadS3.on("httpUploadProgress", (progress) => {
      const percentUploaded = (progress.loaded / progress.total) * 100;
      setProcessPercent(percentUploaded);
    });

    try {
      await uploadS3.promise();
      setDisabled(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    //------------------
    // .promise();

    let time = null;
    if (fileExtension === "mp3" || fileExtension === "mp4") {
      const mediaElement = document.createElement("audio");
      mediaElement.src = URL.createObjectURL(file);

      await new Promise<void>((resolve, reject) => {
        mediaElement.addEventListener("loadedmetadata", () => {
          time = Math.round(mediaElement.duration * 1000);
          resolve();
        });
        mediaElement.addEventListener("error", () => {
          reject();
        });
      });

      URL.revokeObjectURL(mediaElement.src);
    }
    const data = {
      resource_folder_id: folderId,
      filename: fileName,
      origin_filename: file.name,
      content_type: fileExtension,
      size: bytesToMegabytes(file.size),
      path: `${folderPath}${fileName}`,
    };

    if (time) {
      (data as any).time = time;
    }

    await request.post(
      API.ADMIN_FOLDER.UPLOAD_FILE,
      data,
      (resourceFile) => {
        if (isAttatchment && setQuestionForm) {
          setQuestionForm((prevQuestion) => ({
            ...prevQuestion,
            origin_filename: resourceFile.origin_filename,
            question_resource_id: resourceFile.id,
            resource_data: { resource_link: resourceFile.resource_link },
          }));
          return;
        }

        if (setAnswers && index !== undefined) {
          setAnswers((prevAnswers) => {
            const updatedAnswers = [...prevAnswers];
            updatedAnswers[index] = {
              ...updatedAnswers[index],
              origin_filename: resourceFile.origin_filename,
              answer_resource_link: resourceFile.resource_link,
              answer_resource_id: resourceFile.id,
            };
            return updatedAnswers;
          });
        }
        if (setResourceLink) {
          setResourceLink(resourceFile.resource_link);
        }
      },
      async () => {
        await s3
          .deleteObject({
            Bucket: process.env.REACT_APP_AWS_BUCKET || "",
            Key: folderPath + fileName,
          })
          .promise();
        return swalError();
      },
      { withSuccess: true, withLoading: false }
    );

    setError("");
    setIsOpen(false);
  };

  return (
    <section className="w-full px-[245px] relative z-[1000]">
      <div
        className={`flex justify-center gap-[10px] ${
          tabIndex === 0 ? "pt-[75px]" : "pt-[145px]"
        }`}
      >
        {["ファイルをアップロード", "ファイル管理から選択"].map(
          (tab, index) => (
            <div
              className={`w-full min-w-[250px] text-center min-h-[40px] flex items-end justify-center px-[15px] rounded-t-[10px] cursor-pointer ${
                tabIndex === index
                  ? "bg-primary text-white"
                  : "bg-success-extralight text-secondary"
              }`}
              key={index}
              onClick={() => {
                if (disabled) return;
                setTabIndex?.(index);
              }}
            >
              <div
                className={`w-full border-b-[3px] ${
                  tabIndex === index
                    ? "border-primary"
                    : "border-secondary-light"
                } border-primary text-[14px] font-[500] leading-[100%] tracking-[0.02] pb-[10px]`}
              >
                {tab}
              </div>
            </div>
          )
        )}
      </div>
      {tabIndex === 0 && (
        <div className="w-full py-[30px]">
          <div className="font-[700] text-[16px] text-primary flex justify-center mb-[20px]">
            ファイルをアップロード
          </div>
          <div className="font-[400] text-[14px] mb-[8px]">
            ファイルを格納する【ファイル管理のカテゴリ】を選択
          </div>
          <select
            className={`${
              folderId === ""
                ? "text-secondary-disabled"
                : "text-secondary-dark"
            } h-[40px] pr-[33px] pl-[15.5px] mb-[20px] appearance-none font-[400] text-[12px] w-full`}
            name="section_ids"
            onChange={handleFolderChange}
          >
            <option value="" className="hidden">
              選択してください
            </option>
            {folderList.map((folder) => (
              <option
                className="text-secondary-dark"
                key={folder.id}
                value={`${folder.id}|${folder.code}`}
              >
                {folder.name}
              </option>
            ))}
          </select>
          <div
            className={`${
              disabled ? "cursor-not-allowed" : ""
            } min-h-[170px] w-full flex flex-col items-center border border-dashed border-secondary-light py-[20px] mb-[20px]`}
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
          >
            <img
              src={cloud_upload}
              width={44}
              className="max-h-[30.78px] mb-[26px]"
            />
            <input
              ref={fileInputRef}
              id="file-input"
              type="file"
              className="hidden"
              onChange={handleFileInputChange}
              accept={
                fileType === UNIT_FILE_TYPE.TYPE_IMAGE
                  ? "image/jpeg, image/png"
                  : fileType
                  ? fileTypes[fileType].mimeTypes[0]
                  : ""
              }
            />
            <div className="text-[14px] font-[400] text-center mb-[15px]">
              ファイルを選択、またはドラッグ＆ドロップ
            </div>
            <button
              type="button"
              className={`${
                disabled
                  ? "cursor-not-allowed bg-success-lighter"
                  : "bg-primary"
              } w-full max-w-[116px] min-h-[37px] text-white  rounded-[5px] px-[16px] py-[10px] text-[12px] font-[700]`}
              onClick={() => {
                if (disabled) return;
                fileInputRef.current?.click();
              }}
            >
              ファイルを選択
            </button>
            {error !== "" && (
              <div className="text-danger mt-[10px]">{error}</div>
            )}
          </div>
          {/* Process bar */}
          {processPercent > 0 && <ProgressBar percent={processPercent} />}
          <div className="flex justify-center my-[30px]">
            <div className="text-[14px] font-[500] mb-[5px] bg-secondary-extralight px-[20px] py-[10px]">
              現在のファイル：{" "}
              <span className="text-[16px] font-[400] text-black">
              </span>
            </div>
          </div>
          <div className="text-[12px] font-[400] mb-[94px]">
            ・アップロードできる動画、音声ファイル容量の上限は2GBです。
            <br />
            ・スライドPDFのファイル容量は3MB以下を推奨しています（上限100MB）
            <br />
            ・視聴画像の推奨サイズは509x300px以下です（上限10MB）
            <br />
            ・ファイルは1つずつ選択してアップロードをしてください（複数選択不可）
            <br />
          </div>
        </div>
      )}
      {tabIndex === 1 && (
        <div className="w-full py-[30px]">
          <div className="font-[700] text-[16px] text-primary flex justify-center mb-[40px]">
            「ファイル管理」にアップロードされた教材ファイルから選択
          </div>
          <div className="font-[400] text-[14px] mb-[8px]">カテゴリを選択</div>
          <select
            className={`${
              folderId === ""
                ? "text-secondary-disabled"
                : "text-secondary-dark"
            } h-[40px] pr-[33px] pl-[15.5px] mb-[20px] appearance-none font-[400] text-[12px] w-full`}
            name="section_ids"
            onChange={handleFolderChange}
          >
            <option value="" className="hidden">
              選択してください
            </option>
            {folderList.map((folder) => (
              <option
                className="text-secondary-dark"
                key={folder.id}
                value={`${folder.id}|${folder.code}`}
              >
                {folder.name}
              </option>
            ))}
          </select>
          <div className="font-[400] text-[14px] mb-[8px]">
            ファイル名で検索
          </div>
          <div className="relative">
            <input
              type="text"
              name="originFilename"
              id=""
              className="w-full h-[40px] pl-[12px] placeholder-[#BEBEBE] text-[14px] font-[400] leading-[100%] border-[#E1E3E2] rounded-[5px]"
              placeholder="ファイル名を入力"
              onChange={(e) => setOriginFilename(e.target.value)}
              value={originFilename}
            />
            <BiSearchAlt2
              className="absolute top-[7px] right-[9.26px] text-secondary-light"
              size={22}
            />
            <div className="absolute top-12 left-0 z-[1000] max-h-[250px] overflow-y-auto w-full bg-white shadow-2xl rounded-[5px] text-left pl-[10px]">
              {originFilename &&
                searchData &&
                searchData.map((file) => (
                  <FileSearchItem
                    key={file.id}
                    file={file}
                    className="!max-w-[493px] !min-h-[50px] rounded-[5px] mb-[5px] !justify-start !cursor-pointer hover:bg-secondary-lighter"
                    onClick={() => {
                      if (isAttatchment && setQuestionForm) {
                        setQuestionForm((prevQuestion) => ({
                          ...prevQuestion,
                          origin_filename: file.origin_filename,
                          question_resource_id: file.id,
                          resource_data: { resource_link: file.resource_link },
                        }));
                      } else if (setResourceLink) {
                        setResourceLink(file.resource_link || "");
                      } else {
                        if (
                          !(setAnswers && index !== undefined && index !== null)
                        )
                          return;
                        setAnswers((prevAnswers) => {
                          const updatedAnswers = [...prevAnswers];
                          updatedAnswers[index] = {
                            ...updatedAnswers[index],
                            origin_filename: file.origin_filename,
                            answer_resource_link: file.resource_link,
                            answer_resource_id: file.id,
                          };
                          return updatedAnswers;
                        });
                      }
                      setOriginFilename("");
                      setSearchData([]);
                      setIsOpen(false);
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default UploadQuestionImage;
