import React, { useContext, useEffect, useState } from "react";
import { ChildrenWithProps, SetStateAction } from "../types/globals";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { CartItem } from "../types/student/cart";
import { swalLoading } from "../services/helpers/swal";
import { isArray } from "chart.js/dist/helpers/helpers.core";

type CartContextType = {
  totalProduct: number;
  setTotalProduct: SetStateAction<number>;
  cartItems: CartItem[];
  setCartItems: SetStateAction<CartItem[]>;
  waitingApi: boolean;
  setWaitingApi: SetStateAction<boolean>;
};

const CartContext = React.createContext<CartContextType>({
  totalProduct: 0,
  setTotalProduct: () => {},
  cartItems: [],
  setCartItems: () => {},
  waitingApi: true,
  setWaitingApi: () => {}
});

const CartContextProvider = ({ children }: ChildrenWithProps) => {
  const [totalProduct, setTotalProduct] = useState<number>(0);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  useEffect(() => {
    const fetchCart = async () => {
      const cartItemsLocal = localStorage.getItem("cart_items");

      if (cartItemsLocal) {
        const cartItems = JSON.parse(cartItemsLocal);
        if (Array.isArray(cartItems) && cartItems.length > 0) {
          setTotalProduct(cartItems.length);
          setCartItems(cartItems);
        }
      } else {
        // swalLoading();

        let cartItems: CartItem[];
        const result = await makeRequest({
          method: "get",
          url: API.CART.LIST,
        });
        cartItems = result.data;
        localStorage.setItem("cart_items", JSON.stringify(cartItems));
        setTotalProduct(cartItems.length);
        setCartItems(cartItems);
      }

      setWaitingApi(false);
    };

    fetchCart();
  }, []);

  return (
    <CartContext.Provider
      value={{
        totalProduct,
        setTotalProduct,
        cartItems,
        setCartItems,
        waitingApi,
        setWaitingApi
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;

export const useCartContext = () => useContext(CartContext);
