import React from "react";

const ButtonExecuteTest = ({
  children,
  isDisabled = false,
  className,
  ...props
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      disabled={isDisabled}
      className={`rounded-[5px] font-[700] text-[18px] leading-[100%] text-white px-[30px] py-[10px] ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default ButtonExecuteTest;
