import { useMemo } from 'react';
import { UnitForm } from '../../../../types/admin/unit';
import ResourceContentDisplay from '../../common/ResourceContentDisplay';

const UnitSubContent = ({
  widthResponsive,
  unitData,
}: {
  widthResponsive: number;
  unitData: UnitForm;
}) => {
  return useMemo(
    () => (
      <div className="mt-[40px] animate-[show_0.5s_ease-in-out] max-sm:mx-[5px] max-sm:mt-[10px]">
        <ResourceContentDisplay
          width={widthResponsive}
          url={unitData.sub_resource?.resource_link}
          resourceType={unitData.sub_resource_type}
          downloadable={unitData.sub_resource_downloadable}
          textContent={unitData.sub_resource_content}
          sub
        />
      </div>
    ),
    [widthResponsive, unitData],
  );
};

export default UnitSubContent;
