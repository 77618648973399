import React, { useEffect, useState } from "react";
import {
  swalClose,
  swalError,
  swalSuccess,
} from "../../../services/helpers/swal";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { Student } from "../../../types/admin/student";
import { TbTrash } from "react-icons/tb";
import { RiErrorWarningLine } from "react-icons/ri";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";
import StudentTableCommonAction from "./StudentTableCommonAction";
import { Link, useNavigate } from "react-router-dom";
import { PATH_ADMIN_STUDENT } from "../../../services/constants/route/router";
import Spinner from "../../commons/icons/Animations/Spinner";

type MainSection = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_section_id: null;
  status: number;
  student_count: number;
  sub_sections: SubSection[];
};

type SubSection = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_section_id: number;
  status: number;
  student_count: number;
};

type SectionGroup = {
  mainSectionId: number;
  subSectionId: number;
};
const StudentCreate = () => {
  const [studentFormData, setStudentFormData] = useState<Student>({
    status: 0,
  } as any);
  const [errors, setErrors] = useState<ErrorData>({});
  const [mainSections, setMainSections] = useState<MainSection[]>([]);
  const [sectionGroups, setSectionGroups] = useState<SectionGroup[]>([
    { mainSectionId: 0, subSectionId: 0 },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_SECTION.GET_SECTION_LIST}`,
      });

      if (!result.data) return swalError();

      const mainSections = result.data.map((mainSectionData: any) => {
        const subSections = mainSectionData.sub_section.map(
          (subSectionData: any) => ({
            id: subSectionData.id,
            name: subSectionData.name,
          })
        );
        return {
          id: mainSectionData.id,
          name: mainSectionData.name,
          sub_sections: subSections,
        };
      });
      setMainSections(mainSections);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMainSectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newSectionGroups = [...sectionGroups];
    newSectionGroups[index].mainSectionId = parseInt(event.target.value);
    newSectionGroups[index].subSectionId = 0;
    setSectionGroups(newSectionGroups);
  };
  const handleSubSectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const newSectionGroups = [...sectionGroups];
    newSectionGroups[index].subSectionId = parseInt(event.target.value);
    setSectionGroups(newSectionGroups);
  };

  const handleAddSectionGroup = () => {
    setSectionGroups([...sectionGroups, { mainSectionId: 0, subSectionId: 0 }]);
  };

  const handleDeleteSectionGroup = (index: number) => {
    const newSectionGroups = [...sectionGroups];
    newSectionGroups.splice(index, 1);
    setSectionGroups(newSectionGroups);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const file =
      (e.target as HTMLInputElement).files &&
      (e.target as HTMLInputElement).files?.[0];
    if (file) {
      setStudentFormData({ ...studentFormData, [name]: file });
      return;
    }

    setStudentFormData({ ...studentFormData, [name]: value });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const filteredSectionGroups = sectionGroups
      .flatMap(({ mainSectionId, subSectionId }) =>
        subSectionId !== 0
          ? [subSectionId]
          : mainSectionId !== 0
          ? [mainSectionId]
          : []
      )
      .filter((num) => num !== 0)
      .filter((num, index, self) => self.indexOf(num) === index);
    const result = await makeRequest({
      method: "post",
      url: API.ADMIN_STUDENT.CREATE_STUDENT,
      data: {
        ...studentFormData,
        section_ids: filteredSectionGroups,
      },
      hasFileRequest: true,
    });
    if (!result.status) {
      setIsSubmitting(false);
      setErrors(result.error as ErrorData);
      return swalError(result.message, 2000);
    }

    setIsSubmitting(false);
    swalSuccess();
    navigate(PATH_ADMIN_STUDENT.DEFAULT);
  };
  return (
    <>
      <StudentTableCommonAction />
      <div className="mt-[40px]">基本情報</div>
      <div className="mb-[25px] mt-[10px]">
        {errors && Object.keys(errors).length > 0 && (
          <ErrorBox errors={errors} />
        )}
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex items-center pl-[14px]">
            <div className="w-full flex justify-between">
              <div className="">メールアドレス</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="text-secondary-dark ml-[13px] w-[85%] flex items-center mx-[5px] mr-[30px]">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-[40%]"
              name="email"
              value={studentFormData.email || ""}
              onChange={handleChange}
            />
            <div className="min-w-[90px] ml-[5px] pl-[12px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <RiErrorWarningLine
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認中</p>
            </div>
          </div>
        </div>
        <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] ">
          <div className="font-[500] text-[14px] leading-[100%] w-full max-w-[450px] bg-[#EFF1F0] flex pl-[14px] pt-[10px]">
            <div className="w-full flex justify-between">
              <div className="">セクション</div>
              <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
                必須
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            {sectionGroups.map((sectionGroup, index) => (
              <div
                key={index}
                className="flex justify-between w-full max-h-[40px] mx-[5px]"
              >
                <div className="text-secondary-dark w-full flex items-center mx-[5px] relative">
                  <select
                    className="h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark text-[13px] w-full"
                    name="section_ids"
                    onChange={(event) => handleMainSectionChange(event, index)}
                  >
                    <option value=""></option>
                    {mainSections.map((mainSection) => (
                      <option key={mainSection.id} value={mainSection.id}>
                        {mainSection.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="text-secondary-dark w-full flex items-center mx-[5px] relative">
                  <select
                    className="h-[30px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark text-[13px] w-full"
                    name="section_ids"
                    onChange={(event) => handleSubSectionChange(event, index)}
                  >
                    <option value="0"></option>
                    {mainSections
                      .find((ms) => ms.id === sectionGroup.mainSectionId)
                      ?.sub_sections.map((subSection) => (
                        <option key={subSection.id} value={subSection.id}>
                          {subSection.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="text-secondary-dark flex items-center w-[25px]">
                  {index > 0 ? (
                    <TbTrash
                      className="mb-[4px] text-[#7A7A7A]"
                      onClick={() => handleDeleteSectionGroup(index)}
                      size={50}
                    />
                  ) : (
                    <TbTrash
                      className="mb-[4px] text-secondary-light invisible"
                      size={50}
                    />
                  )}
                </div>
              </div>
            ))}
            <button
              className={`bg-primary text-white text-[12px] font-[500] leading-[17px] flex items-center justify-center ml-[10px] rounded-[5px] min-h-[25px] w-full max-w-[180px] cursor-pointer bg-primary mb-[5px]`}
              onClick={handleAddSectionGroup}
            >
              <span className="text-[23px] mb-[3px] mr-[8px]">+</span>{" "}
              所属セクションを追加
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center mb-[60px] mt-[60px] gap-10">
        <Link to={PATH_ADMIN_STUDENT.DEFAULT}>
          <button className="bg-[#BEBEBE] px-[64px] pt-[10px] pb-[12px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%]">
            一覧画面へ戻る
          </button>
        </Link>
        <button
          className={`flex gap-[10px] items-center justify-center bg-primary w-full max-w-[290px] min-h-[40px] text-white rounded-[10px] font-[700] text-[18px] leading-[100%] ${
            isSubmitting ? "pointer-events-none opacity-50" : ""
          }`}
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          <div className="mt-[-2px]">入力内容で登録する</div>
          {isSubmitting && <Spinner />}
        </button>
      </div>
    </>
  );
};

export default StudentCreate;
