import { useStorage } from "../../../../hooks/useStorage";

type PaginationProps = {
  totalItems: number;
  currentPage: number;
  handlePageChange: (number: number) => void;
};

const Pagination = ({
  totalItems,
  currentPage,
  handlePageChange,
}: PaginationProps) => {
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  const pageNumbers = [];

  for (let i = 1; i <= totalItems; i++) {
    pageNumbers.push(i);
  }

  const handlePageClick = (page: number) => {
    if (page < 1 || page > pageNumbers.length) {
      return;
    }
    handlePageChange(page);
  };

  const renderPageNumbers = pageNumbers.map((number) => (
    <li
      style={{ color: currentPage !== number && studentTheme.text_color }}
      key={number}
      onClick={() => handlePageClick(number)}
      className={`mx-1 px-2 py-[0.5px] rounded-[3px] text-[12.5px] transition-colors duration-300 cursor-default ${
        currentPage === number
          ? "bg-warning-medium text-white"
          : "text-gray-700 hover:bg-warning-medium hover:text-white"
      }`}
    >
      {number}
    </li>
  ));

  return (
    <ul className="flex items-center justify-center my-4 list-none">
      <li
        onClick={() => handlePageClick(currentPage - 1)}
        className={`mx-1 px-4 py-[0.25px] rounded-[3px] text-[12.5px] text-white transition-colors duration-300 cursor-default ${
          currentPage === 1
            ? "bg-gray-200 text-gray-700 cursor-not-allowed"
            : "bg-danger-light text-white hover:bg-warning-medium hover:text-white"
        }`}
      >
        {"<"}
      </li>
      {renderPageNumbers}
      <li
        onClick={() => handlePageClick(currentPage + 1)}
        className={`mx-1 px-4 py-[0.25px] rounded-[3px] text-[12.5px] text-white transition-colors duration-300 cursor-default ${
          currentPage === pageNumbers.length
            ? "bg-gray-200 text-gray-700 cursor-not-allowed"
            : "bg-danger-light  text-white hover:bg-warning-medium hover:text-white"
        }`}
      >
        {">"}
      </li>
    </ul>
  );
};

export default Pagination;
