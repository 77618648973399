import { BiSearchAlt2 } from "react-icons/bi";

type MemoFilterType = {
  name: string,
  handleChange: React.Dispatch<React.SetStateAction<string>>
}

const MemoFilter = ({name, handleChange}: MemoFilterType) => {
  return (
    <div className="pb-[10px] mb-[5px] border-b border-secondary-light relative">
      <input
        type="text"
        name={name}
        className="h-[30px] !pl-[30px] text-[12px] placeholder-secondary font-medium border-danger-light rounded-[5px]"
        placeholder="メモを検索"
        onChange={(e) => handleChange(e.target.value)}
      />
      <BiSearchAlt2
        className="absolute top-[4px] left-[6.57px] text-danger-light"
        size={24}
      />
    </div>
  );
};

export default MemoFilter;
