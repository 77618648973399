import { BiPlus } from "react-icons/bi";

type MemoCreateButtonProps = {
  size?: number;
  name: string;
  className?: string;
};

const MemoCreateButton = ({ name, className, size }: MemoCreateButtonProps) => {
  return (
    <div
      className={`flex items-center gap-[7px] h-[18px] py-[4px] text-secondary ${className}`}
    >
      <BiPlus
        className="border border-secondary rounded-lg ml-[16px]"
        size={size || 18}
      />
      <div className="w-[140px] text-[14px] pl-[6px] whitespace-nowrap">
        {name}
      </div>
    </div>
  );
};

export default MemoCreateButton;
