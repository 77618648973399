import React from 'react';
import { ElementProps } from '../../../../types/globals';

type SpinnerProps = {
  width?: number;
  height?: number;
  color?: string;
  thickness?: number;
} & ElementProps<HTMLDivElement>;

const Spinner = ({
  width = 20,
  height = 20,
  color = 'white',
  thickness = 3,
  ...props
}: SpinnerProps) => {
  return (
    <div {...props}>
      <div
        className="inline-block animate-spin rounded-full border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
        style={{
          width,
          height,
          color,
          borderWidth: `${thickness}px`,
        }}
      ></div>
    </div>
  );
};

export default Spinner;
