interface StudentVerifiedStepProp {
  studentThemeColor: any;
}

const StudentVerifiedStep = () => {
  return (
    <>
      <div className="max-sm:w-full mb-[42px] mt-[30px] mx-auto max-sm:mt-[25px] max-sm:px-[10px] max-sm:mb-[32px] animate-[show_0.5s_ease-in-out]">
        <div className="sm:w-[750px] max-sm:w-full h-[300px] mb-[30px] max-sm:mb-[25px] px-[25px] flex justify-center">
          <p className="m-auto text-red-500">
            確認URLの有効期限が切れているか、無効になりました。
          </p>
        </div>
      </div>
    </>
  );
};

export default StudentVerifiedStep;
