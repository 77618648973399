import UnitTestForm from './Form/UnitTestForm';
import { Unit } from '../../../../types/admin/unit';

type UnitTestProps = {
  unitData: Unit;
  createType: Unit['type'];
};

const UnitTest = ({ unitData, createType }: UnitTestProps) => {
  return (
    <section>
      <div className="flex items-center gap-[15px]">
        <div className="flex items-center justify-center w-full max-w-[140px] min-h-[30px] text-[14px] font-[500] leading-[100%] text-primary border border-primary rounded-[5px]">
          基本情報登録
        </div>
        <div className="text-[12px] leading-[190%]">この単元のテストについて設定します</div>
      </div>
      <div className="mt-[22px]">
        <UnitTestForm unitData={unitData} createType={createType} />
      </div>
    </section>
  );
};

export default UnitTest;
