import React, { ChangeEvent, useState } from "react";
import Button from "../components/Login/Button";

import { Link, useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { swalLoading, swalMessage } from "../services/helpers/swal";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import AuthLayout from "../layouts/AuthLayout";
import { getSubdomainFromUrl } from "../services/helpers/domain";
import { useStorage } from "../hooks/useStorage";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);

  const [dataForgotPassword, setDataForgotPassword] = useState({
    username: "",
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;

    setDataForgotPassword({ ...dataForgotPassword, [name]: value });
  };

  const handleSubmitEmail = async () => {
    // swalLoading();
    if (isDisableButton) return;
    let result = await makeRequest({
      method: "post",
      url: API.AUTH.SEND_EMAIL_FORGOT_PASSWORD,
      data: {
        ...dataForgotPassword,
        subdomain: getSubdomainFromUrl(),
      },
    });

    if (result.status) {
      navigate(ROUTE.SUBMIT_FORGOT_PASSWORD_DONE);
      return;
    }
    setIsDisableButton(false);
    swalMessage("エラー", result.message, "error");
    return;
  };

  return (
    <AuthLayout disableTokenCheck>
      <div
        style={{ color: studentTheme.text_color }}
        className="mb-[45px] mt-[30px] mx-auto"
      >
        <div className="md:w-[550px] sm:w-[450px] w-[370px] h-[441px] mb-[30px] bg-white px-[25px] pt-[25px] pb-[20px] border border-warning-light">
          <p className="mb-[20px] text-[0.94rem] font-medium max-sm:mb-[7px]">
            パスワード再設定
          </p>

          <div className="text-[0.75rem] font-light leading-[190%] max-sm:py-[15px]">
            ご登録いただいたメールアドレスを入力してください。
            <br />
            パスワードを再発行いたします。
            <br />
            ※再設定のURLの有効期限は30分で、時間内は何度でも使用可能です。
          </div>

          <div className="mb-[30px] mt-[30px] max-sm:mt-[5px] max-sm:mb-[27px]">
            <input
              type="text"
              name="username"
              onChange={handleChangeInput}
              className="min-h-[50px] border border-warning-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F] placeholder:text-sm"
              placeholder="メールアドレス　または　ログインID"
              required
            />
          </div>

          <Button
            isSubmitting={isDisableButton}
            content={"送信する"}
            otherstyle={"bg-primary text-white"}
            type={"button"}
            onClick={() => {
              setIsDisableButton(true);
              handleSubmitEmail();
            }}
            style={{ backgroundColor: studentTheme.main_color_first }}
          />

          <Link to={ROUTE.LOGIN}>
            <Button
              content={"ログイン画面へ戻る "}
              otherstyle={
                "bg-secondary-light text-white mt-[10px] max-sm:mt-[20px]"
              }
              type={"button"}
            />
          </Link>

          <p className="text-[0.75rem] align-middle font-normal mt-[8px] py-[14px] leading-[190%]">
            ログイン名を忘れた方：
            <br /> 管理者に連絡をしてログイン名をご確認ください
          </p>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
