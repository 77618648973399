import React, { useEffect, useState } from "react";
import Title from "../MyPage/Title";
import StatisticalChart from "../MyPage/StatisticalChart";
import LearningList from "../Learning/List";
import LectureList from "./Lectures/LectureList";
import LearningTool from "../MyPage/LearningTool";
import ScheduleBoard from "../Schedule/EventBoard";
import { Course, Statistics } from "../../../types/student/course";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { Lecture } from "../../../types/admin/lecture";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { Unit } from "../../../types/student/unit";
import { useNavigate } from "react-router-dom";
import { useFeaturePurchasedContext } from "../../../context/FeaturePurchasedContext";
import { useStorage } from "../../../hooks/useStorage";

const CourseTop = () => {
  const [courseStatistics, setCourseStatistics] = useState<Statistics>({});
  const [lectureList, setLectureList] = useState<Lecture[]>([]);
  const [courseDetail, setCourseDetail] = useState<Course>({});
  const [unitLearningList, setUnitLearningList] = useState<Unit[]>([]);
  const { getParamValue } = useObjectRoutes();
  const courseId = getParamValue("course_id");
  const navigate = useNavigate();
  const { getLocalStorage } = useStorage();
  const paymentFeature = getLocalStorage("payment_feature") || false;
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const courseStatisticalStorage = getLocalStorage("courses_of_user");

  const fetchCourseStatistical = () => {
    request.get(
      `${API.COURSE.COURSE_STATISTICS}?course_id=${courseId}`,
      (courseStatisticsData) => setCourseStatistics(courseStatisticsData || {}),
      () => {},
      { withError: false, withClose: false }
    );
  };

  useEffect(() => {
    if (!courseId) return;

    const fetchCourseDetail = async () => {
      await request.get(
        `${API.COURSE.COURSE_DETAIL}?course_id=${courseId}`,
        (courseDetailData) => setCourseDetail(courseDetailData || {}),
        (error) => {
          if (error === 404) {
            navigate("/404");
          }
        }
      );
    };

    fetchCourseDetail();

    const fetchCourseData = async () => {
      const getCourseStatistics = () => {
        if (
          courseStatisticalStorage &&
          courseStatisticalStorage.courses.length > 0
        ) {
          const courses: Course[] = courseStatisticalStorage.courses;
          const course = courses.find(
            (course) => course.id === parseInt(courseId)
          );
          if (course) {
            setCourseStatistics(course?.statistical || {});
          }

          if (!course) {
            fetchCourseStatistical();
          }
        } else {
          fetchCourseStatistical();
        }
      };
      const getUnitUserHistory = request.get(
        `${API.COURSE.LIST_UNIT_DOING}?course_id=${courseId}`,
        (unitDoingList) => setUnitLearningList(unitDoingList || {}),
        () => {},
        { withError: false, withClose: false }
      );
      const getLectureList = request.get(
        `${API.COURSE.LECTURE_OF_CURRENT_COURSE}?course_id=${courseId}`,
        (lectureListData) => setLectureList(lectureListData || []),
        () => {},
        { withError: false, withClose: false }
      );

      try {
        await Promise.all([
          getCourseStatistics(),
          getLectureList,
          getUnitUserHistory,
        ]);

        setWaitingApi(false);
      } catch (error) {
        setWaitingApi(false);
      }
    };

    fetchCourseData();
  }, [courseId, paymentFeature]);

  return (
    <>
      <Title content={courseDetail?.name || ""} />
      {courseStatistics && !waitingApi ? (
        <StatisticalChart
          blue={true}
          title={`${
            courseDetail?.name ? `【${courseDetail?.name}】` : ""
          } コースの学習時間`}
          statistics={courseStatistics}
        />
      ) : (
        <div className="skeleton w-full min-h-[330px] mb-[20px]"></div>
      )}
      {/* <StatisticalChart
        blue={true}
        title={`${
          courseDetail?.name ? `【${courseDetail?.name}】` : ""
        } コースの学習時間`}
        statistics={courseStatistics}
      /> */}
      <section className="flex gap-[32px]">
        <div className="w-[478px]">
          {unitLearningList && !waitingApi ? (
            <LearningList unitLearningList={unitLearningList} />
          ) : (
            <div className="skeleton w-full min-h-[150px] mb-[20px]"></div>
          )}

          {lectureList && !waitingApi ? (
            <LectureList lectureList={lectureList} />
          ) : (
            <div className="skeleton w-full min-h-[150px] mb-[20px]"></div>
          )}

          {/* LearningTool hidden for later phases */}
          <div className="max-lg:hidden">
            <LearningTool />
          </div>
        </div>
        {/* ScheduleBoard hidden for later phases */}
        {/* <div className="w-[260px]">
          <ScheduleBoard />
        </div> */}
      </section>
    </>
  );
};

export default CourseTop;
