import EventBoardProvider from "../../../context/EventBoardContext";
import { getArrayByObject } from "../../../services/helpers/parseData";
import { EventsInMonth } from "../../../types/student/event";
import CalendarDatePicker from "./CalendarDatePicker";
import ScheduleEventBoardList from "./EventBoardList";

type EventBoardProps = {
  onRefHandle?: (ref: React.RefObject<HTMLInputElement>) => void;
  existedEvents?: EventsInMonth;
  initialDate?: string | Date;
};

const EventBoard = ({
  existedEvents,
  onRefHandle,
  initialDate,
}: EventBoardProps) => {
  const highLightDates: Date[] = [];
  const eventsInMonth = getArrayByObject(existedEvents || {}).flat();

  for (const highlight of Object.keys(existedEvents || {})) {
    highLightDates.push(new Date(highlight || new Date()));
  }

  return (
    <div className="w-full border border-danger-light rounded-[5px] mb-[15px] p-[20px]">
      <EventBoardProvider>
        <CalendarDatePicker
          onRefHandle={onRefHandle}
          highlightDates={highLightDates}
          initialDate={initialDate}
        />

        <ScheduleEventBoardList eventsInMonth={eventsInMonth} />
      </EventBoardProvider>
    </div>
  );
};

export default EventBoard;
