import React from "react";
import { Link } from "react-router-dom";

type ListItemType = {
  title: string;
  pathDetail: string;
};

const ListItem = ({ title, pathDetail }: ListItemType) => {
  return (
    <div className="w-full h-[50px] flex items-center mb-[5px] px-[10px] border border-secondary-light bg-secondary-morelighter rounded-[5px] text-secondary-dark font-[500]">
      <div className="flex items-center w-[83.3%] h-[30px] px-[10px] text-[15]">
        {title}
      </div>
      <div className="flex items-center justify-center w-[16.7%] h-[30px] px-[10px]">
        <Link
          to={pathDetail}
          className="text-white text-[12px] px-[10px] py-[5px] bg-danger rounded-[5px]"
        >
          編集する
        </Link>
      </div>
    </div>
  );
};

export default ListItem;
