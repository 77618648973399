const REPORT_PERIOD_OPTION = {
  CUSTOM_DATE: 0,
  _7_DAYS: 1,
  _30_DAYS: 2,
  _90_DAYS: 3,
};

const REPORT_PERIOD_OPTION_DATA = [
  { value: REPORT_PERIOD_OPTION.CUSTOM_DATE, label: '期間を選択' },
  {
    value: REPORT_PERIOD_OPTION._7_DAYS,
    label: '過去７日間',
  },
  {
    value: REPORT_PERIOD_OPTION._30_DAYS,
    label: '過去30日間',
  },
  {
    value: REPORT_PERIOD_OPTION._90_DAYS,
    label: '過去90日間',
  },
];

const REVISION_LIST_PERIOD_OPTION_DATA = [
  { value: REPORT_PERIOD_OPTION.CUSTOM_DATE, label: 'すべて' },
  {
    value: REPORT_PERIOD_OPTION._7_DAYS,
    label: '過去７日間',
  },
  {
    value: REPORT_PERIOD_OPTION._30_DAYS,
    label: '過去30日間',
  },
  {
    value: REPORT_PERIOD_OPTION._90_DAYS,
    label: '過去90日間',
  },
];

const REPORT_UNIT_OPTION = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
};

const REPORT_UNIT_OPTION_DATA = [
  {
    value: REPORT_UNIT_OPTION.DAY,
    label: '1日',
  },
  {
    value: REPORT_UNIT_OPTION.WEEK,
    label: '1週',
  },
  {
    value: REPORT_UNIT_OPTION.MONTH,
    label: '1月',
  },
];

export {
  REPORT_PERIOD_OPTION,
  REPORT_PERIOD_OPTION_DATA,
  REPORT_UNIT_OPTION,
  REPORT_UNIT_OPTION_DATA,
  REVISION_LIST_PERIOD_OPTION_DATA,
};
