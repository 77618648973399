import React, { useRef, useState } from "react";
import cloud_upload from "../../../assests/images/icons/cloud_upload.svg";
import AWS, { S3 } from "aws-sdk";
import {
  swalError,
  swalSuccess,
  swallConfirm,
} from "../../../services/helpers/swal";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { File } from "../../../types/admin/folder";
import { MESSAGE_COMMON } from "../../../services/constants/message";
import ProgressBar from "./ProgressBar";
import { formatCapacity } from "../../../services/helpers/formatCapacity";
import { TYPE_CAPACITY } from "../../../services/constants/globals";
import { useAuthContext } from "../../../context/AuthContext";
import { getUploadFilePath } from "../../../services/helpers/uploadFile";

interface FileTypeConfig {
  mimeTypes: string[];
  maxSize: number;
  maxSizeName: string;
}
type TabCardType = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  folderId?: string | null;
  resetTable: () => void;
  changePaginate: (id: number) => void;
  folderCode?: string;
  fileInfo?: File | null;
  usedStorage?: number;
  totalStorage?: number;
};
const fileTypes: { [key: string]: FileTypeConfig } = {
  PDF: {
    mimeTypes: ["application/pdf"],
    maxSize: 100 * 1024 * 1024,
    maxSizeName: "100MB",
  }, // 100MB
  images: {
    mimeTypes: ["image/jpeg", "image/png"],
    maxSize: 10 * 1024 * 1024,
    maxSizeName: "10MB",
  }, // 10MB
  video: {
    mimeTypes: ["video/mp4",  "video/quicktime"],
    maxSize: 2 * 1024 * 1024 * 1024,
    maxSizeName: "2GB",
  }, // 2GB
  audio: {
    mimeTypes: ["audio/mpeg"],
    maxSize: 2 * 1024 * 1024 * 1024,
    maxSizeName: "2GB",
  }, // 2GB
};

const bytesToMegabytes = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2);
};
const FileUploadFolder = ({
  setIsOpen,
  folderId,
  resetTable,
  changePaginate,
  folderCode,
  fileInfo,
  usedStorage,
  totalStorage,
}: TabCardType) => {
  const [error, setError] = useState<string | null>("");
  const [fileType, setFileType] = useState<string>(() => {
    switch (fileInfo?.content_type) {
      case "pdf":
        return "PDF";
      case "mp3":
        return "audio";
      case "mp4":
      case "video/quicktime":
        return "video";
      case "jpg":
      case "jpeg":
      case "png":
        return "images";
      default:
        return "audio";
    }
  });
  const [processPercent, setProcessPercent] = useState<number>(0);
  const [disabled, setDisabled] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentUploadFile, setCurrentUploadFile] = useState<globalThis.File | null>(null)
  const { user } = useAuthContext()
  const oz_id = user.organization_id;

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (disabled) return;

    event.preventDefault();
    setError(null);
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;

    if (fileInfo) {
      swallConfirm(
        () => handleFiles(files),
        "新しいファイルで上書きしますか？",
        "このファイルを使用している場合、<br>ページ内の画像が表示されない、または<br>リンク切れが発生するため<br>確認の上、ファイルを上書きしてください。<br>（古いデータに戻すことはできません）"
      );
      return;
    }
    handleFiles(files);
    setDisabled(true);
  };

  const handleFiles = async (files: FileList | null) => {
    if (!files) return;
    setError(null);
    const file = files[0];
    setCurrentUploadFile(file);
    const fileTypeConfig = fileTypes[fileType];
    if (!fileTypeConfig) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    const allowedMimeTypes = fileTypeConfig.mimeTypes;
    const maxSize = fileTypeConfig.maxSize;

    if (!allowedMimeTypes.includes(file.type)) {
      setError(MESSAGE_COMMON.INVALID_FILE_TYPE);
      return;
    }
    if (file.size > maxSize) {
      setError(
        `添付ファイルのサイズが${fileTypeConfig.maxSizeName}を越えています`
      );
      return;
    }

    if (totalStorage && usedStorage) {
      const totalStorageFormatKB = formatCapacity(totalStorage, TYPE_CAPACITY.GB, TYPE_CAPACITY.KB);
      const usedStorageFormatKB = formatCapacity(usedStorage, TYPE_CAPACITY.GB, TYPE_CAPACITY.KB);
      const fileSizeFormatKB = formatCapacity(file.size, TYPE_CAPACITY.BYTE, TYPE_CAPACITY.KB);
      let currentFIleSizeFormatKB = 0;
      if(fileInfo && fileInfo.size) currentFIleSizeFormatKB = formatCapacity(fileInfo.size, TYPE_CAPACITY.MB, TYPE_CAPACITY.KB);
      if (Number(fileSizeFormatKB + usedStorageFormatKB - currentFIleSizeFormatKB) > Number(totalStorageFormatKB)) {
        setError(
          `十分な収納スペースがない`
        );
        return;
      }
    }
    setDisabled(true);

    const s3 = new S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });
    const fileExtension = fileInfo?.content_type
      ? fileInfo.content_type
      : file.name.split(".").pop()?.toLowerCase();
    const fileName = fileInfo ? fileInfo.filename : `${Date.now()}.${fileExtension}`;
    const folderPath = getUploadFilePath(oz_id, folderCode);
    let contentType = "";
    switch (fileExtension) {
      case "pdf":
        contentType = "application/pdf";
        break;
      case "mp3":
        contentType = "audio/mpeg";
        break;
      case "mp4":
        contentType = "video/mp4";
        break;
      case "jpg":
      case "jpeg":
        contentType = "image/jpeg";
        break;
      case "png":
        contentType = "image/png";
        break;
      default:
        contentType = "application/octet-stream";
        break;
    }

    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

    AWS.config.update({
      accessKeyId,
      secretAccessKey,
    });

    const upload = new S3.ManagedUpload({
      params: {
        Bucket: process.env.REACT_APP_AWS_BUCKET || "",
        Key: folderPath + fileName,
        Body: file,
        ContentType: contentType,
        ACL: "public-read",
      },
    });

    upload.on("httpUploadProgress", (progress) => {
      const percentUploaded = (progress.loaded / progress.total) * 100;
      setProcessPercent(percentUploaded);
    });

    try {
      await upload.promise();
      setDisabled(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    // swalLoading();

    let time = null;
    if (fileExtension === "mp3" || fileExtension === "mp4") {
      const mediaElement = document.createElement("audio");
      mediaElement.src = URL.createObjectURL(file);

      await new Promise<void>((resolve, reject) => {
        mediaElement.addEventListener("loadedmetadata", () => {
          time = Math.round(mediaElement.duration * 1000);
          resolve();
        });
        mediaElement.addEventListener("error", () => {
          reject();
        });
      });

      URL.revokeObjectURL(mediaElement.src);
    }

    if (fileInfo) {
      const data = {
        resource_id: fileInfo.id,
        content_type: fileExtension,
        size: bytesToMegabytes(file.size),
        origin_filename: file.name,
      };

      if (time) {
        (data as any).time = time;
      }

      await request.patch(
        API.ADMIN_FOLDER.UPDATE_FILE,
        data,
        () => { },
        async () => {
          await s3
            .deleteObject({
              Bucket: process.env.REACT_APP_AWS_BUCKET || "",
              Key: folderPath + fileName,
            })
            .promise();
          return swalError();
        },
        { withSuccess: true, withLoading: false }
      );
    } else {
      const data = {
        resource_folder_id: folderId,
        filename: fileName,
        origin_filename: file.name,
        content_type: fileExtension,
        size: bytesToMegabytes(file.size),
        path: `${folderPath}${fileName}`,
      };

      if (time) {
        (data as any).time = time;
      }

      await request.post(
        API.ADMIN_FOLDER.UPLOAD_FILE,
        data,
        () => { },
        async () => {
          await s3
            .deleteObject({
              Bucket: process.env.REACT_APP_AWS_BUCKET || "",
              Key: folderPath + fileName,
            })
            .promise();
          return swalError();
        },
        { withSuccess: true, withLoading: false }
      );
    }
    resetTable();
    swalSuccess();
    setError("");
    setIsOpen(false);
    changePaginate(1);
    setCurrentUploadFile(null);
  };

  return (
    <section className="w-full h-full px-[245px] relative z-[1001]">
      <div className="w-full py-[30px]">
        <div className="font-[700] text-[16px] text-primary flex justify-center mb-[20px]">
          {fileInfo ? "ファイルを差し替え" : "ファイルをアップロード"}
        </div>
        <div
          className={`${disabled ? "cursor-not-allowed" : ""
            } min-h-[170px] w-full flex flex-col items-center border border-dashed border-secondary-light py-[20px] mb-[20px]`}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <img
            src={cloud_upload}
            width={44}
            className="max-h-[30.78px] mb-[26px]"
            alt=""
          />
          <input
            ref={fileInputRef}
            id="file-input"
            type="file"
            className="hidden"
            onChange={handleFileInputChange}
            // accept={
            //   fileType === "images"
            //     ? "image/jpeg, image/png"
            //     : fileTypes[fileType].mimeTypes[0]
            // }
            accept={fileTypes[fileType].mimeTypes.join(",")}
          />
          <div className="text-[14px] font-[400] text-center mb-[15px]">
            ファイルを選択、またはドラッグ＆ドロップ
          </div>
          <button
            type="button"
            className={`${disabled ? "cursor-not-allowed bg-success-lighter" : "bg-primary"
              } w-full max-w-[116px] min-h-[37px] text-white  rounded-[5px] px-[16px] py-[10px] text-[12px] font-[700]`}
            onClick={() => {
              if (disabled) return;
              fileInputRef.current?.click();
            }}
          >
            ファイルを選択
          </button>
          {error !== "" && <div className="text-danger mt-[10px]">{error}</div>}
        </div>
        {/* Process bar */}
        {processPercent > 0 && <ProgressBar percent={processPercent} />}
        {fileInfo && (
          <div className="flex justify-center my-[30px]">
            <div className="text-[14px] font-[500] mb-[5px] bg-secondary-extralight px-[20px] py-[10px]">
              現在のファイル：{" "}
              <span className="text-[16px] font-[400] text-black">
                {fileInfo?.origin_filename}
              </span>
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <div className="text-[12px] font-[400] mb-[5px]">
            ・アップロードできる動画、音声ファイル容量の上限は2GBです。
            <br />
            ・スライドPDFのファイル容量は3MB以下を推奨しています（上限100MB）
            <br />
            ・視聴画像の推奨サイズは509x300px以下です（上限10MB）
            <br />
            ・ファイルは1つずつ選択してアップロードをしてください（複数選択不可）
            <br />
          </div>
        </div>
      </div>
      <div className="flex min-h-[40px] mb-[2px] border-b-[1.5px] mx-[60px]">
        <div className="font-[500] text-[14px] leading-[100%] w-[60%] bg-[#EFF1F0] flex pt-[10px] pl-[14px]">
          <div className="w-full flex justify-between">
            <div className="">ファイル形態を選択</div>
            <div className="pl-[6.5px] pr-[5.5px] py-[3px] rounded-[5px] bg-danger font-[500px] text-[9px] leading-[100%] text-white h-[15px] mr-[8px]">
              必須
            </div>
          </div>
        </div>
        <div className="text-secondary-dark ml-[13px] w-[40%] flex flex-col mx-[5px] mr-[30px] pt-[10px]">
          <label className="flex items-center mb-[15px]">
            <input
              type="radio"
              className="form-radio text-primary-light border border-success-extralight"
              name="fileType"
              value={"video"}
              onChange={(e) => {
                setFileType(e.target.value);
                setError("");
              }}
              checked={fileType === "video" || fileInfo?.content_type === "mp4"}
              disabled={
                (Boolean(fileInfo?.content_type) &&
                fileInfo?.content_type !== "mp4") || 
                (currentUploadFile !== null)
              }
            />
            <span className="ml-[8px] text-[12px] leading-[100%]">動画</span>
          </label>
          <label className="flex items-center mb-[15px]">
            <input
              type="radio"
              className="form-radio text-primary-light border border-success-extralight"
              name="fileType"
              value={"audio"}
              onChange={(e) => {
                setFileType(e.target.value);
                setError("");
              }}
              checked={fileType === "audio" || fileInfo?.content_type === "mp3"}
              disabled={
                (Boolean(fileInfo?.content_type) &&
                fileInfo?.content_type !== "mp3") ||
                (currentUploadFile !== null)
              }
            />
            <span className="ml-[8px] text-[12px] leading-[100%]">
              音声（mp3）
            </span>
          </label>
          <label className="flex items-center mb-[15px]">
            <input
              type="radio"
              className="form-radio text-primary-light border border-success-extralight"
              name="fileType"
              value={"PDF"}
              onChange={(e) => {
                setFileType(e.target.value);
                setError("");
              }}
              checked={fileType === "PDF" || fileInfo?.content_type === "pdf"}
              disabled={
                (Boolean(fileInfo?.content_type) &&
                fileInfo?.content_type !== "pdf") ||
                (currentUploadFile !== null)
              }
            />
            <span className="ml-[8px] text-[12px] leading-[100%]">
              スライド（pdf）
            </span>
          </label>
          <label className="flex items-center mb-[15px]">
            <input
              type="radio"
              className="form-radio text-primary-light border border-success-extralight"
              name="fileType"
              value={"images"}
              onChange={(e) => {
                setFileType(e.target.value);
                setError("");
              }}
              checked={
                fileType === "images" ||
                fileInfo?.content_type === "jpg" ||
                fileInfo?.content_type === "png" ||
                fileInfo?.content_type === "jpeg"
              }
              disabled={
                (Boolean(fileInfo?.content_type) &&
                fileInfo?.content_type !== "jpg" &&
                fileInfo?.content_type !== "png" &&
                fileInfo?.content_type !== "jpeg") ||
                (currentUploadFile !== null)
              }
            />
            <span className="ml-[8px] text-[12px] leading-[100%]">
              画像（jpg、png）
            </span>
          </label>
        </div>
      </div>
    </section>
  );
};

export default FileUploadFolder;
