/* eslint-disable react-hooks/exhaustive-deps */
import TableHeadItem from "../../commons/TableHeadItem";
import UnitTableItem from "./UnitTableItem";
import { COURSE_TABLE_ITEM_TYPE } from "../../../../services/constants/admin/pages/course";
import { Fragment, useState, useEffect } from "react";
import { getDataByParent } from "../../../../services/utils/admin/course";
import { swallConfirm } from "../../../../services/helpers/swal";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { Course, CourseItemType } from "../../../../types/admin/course";
import {
  DELETE_CONFIRM_CONTENT,
  DELETE_CONFIRM_TITLE,
} from "../../../../services/constants/message";
import type { Direction, Object } from "../../../../types/globals";
import { DIRECTION } from "../../../../services/constants/globals";
import { Chapter } from "../../../../types/admin/chapter";
import { Lecture } from "../../../../types/admin/lecture";
import {
  filterUniqueArray,
  sortArrayByKey,
} from "../../../../services/helpers/parseData";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { PATH_ADMIN_MATERIAL } from "../../../../services/constants/route/router";
import { Unit } from "../../../../types/admin/unit";
import TableTitle from "../../commons/TableTitle";

export type MemoriedExpandStatus = {
  [key: string]: boolean;
};

type TableItemType = Course | Lecture | Chapter;
type CurrentArray<T = TableItemType> = Array<T>;

type DeleteData = {
  course_id?: number;
  lecture_id?: number;
  chapter_id?: number;
  unit_id?: number;
};

type UpdatedObject = {
  id?: number;
  itemType?: CourseItemType;
  parentId?: number | null;
};

const Table = () => {
  const { getParamValue, navigate } = useObjectRoutes();
  const courseId = getParamValue("course_id");
  const [lectures, _setLectures] = useState<Lecture[]>([]);
  const [chapters, _setChapters] = useState<Chapter[]>([]);
  const [units, _setUnits] = useState<Unit[]>([]);
  const [memoriedOpenStatus, setMemoriedOpenStatus] =
    useState<MemoriedExpandStatus>({});
  const [updatedObjectInfo, setUpdatedObjectInfo] = useState<UpdatedObject>({});
  const [courseInfo, setCourseInfo] = useState<Course>({});
  useEffect(() => {
    if (!courseId) navigate(PATH_ADMIN_MATERIAL.DEFAULT);

    const fetch = async () =>
      await request.get(
        `${API.ADMIN_LECTURE.LIST}?course_id=${courseId}`,
        setLectures
      );
    const fetchCourseInfo = async () => {
      await request.get(
        `${API.ADMIN_COURSE.DETAIL}?course_id=${courseId}`,
        setCourseInfo
      );
    };
    fetch();
    fetchCourseInfo();
  }, []);

  const setLectures = (lectures: Lecture[]) => {
    const uniqueLectures = filterUniqueArray(lectures);
    _setLectures(sortArrayByKey(uniqueLectures, "order"));
  };

  const setChapters = (chapters: Chapter[]) => {
    _setChapters(sortArrayByKey(filterUniqueArray(chapters), "order"));
  };

  const setUnits = (chapters: Chapter[]) => {
    _setUnits(sortArrayByKey(filterUniqueArray(chapters), "order"));
  };

  const handleExpand = async (
    id?: number,
    itemType?: CourseItemType,
    isExpand?: boolean
  ) => {
    if (!id || !itemType) return;
    if (!isExpand)
      return setMemoriedOpenStatus({
        ...memoriedOpenStatus,
        [`${itemType}_${id}`]: false,
      });

    let urlByType = `${API.ADMIN_CHAPTER.LIST}?${itemType}_id=${id}`;
    if (itemType === COURSE_TABLE_ITEM_TYPE.CHAPTER)
      urlByType = `${API.ADMIN_UNIT.LIST}?${itemType}_id=${id}`;

    const resAction: <T extends Object>(res: Array<T>) => void = (res) => {
      switch (itemType) {
        case COURSE_TABLE_ITEM_TYPE.LECTURE:
          setChapters([...chapters, ...res]);
          break;

        case COURSE_TABLE_ITEM_TYPE.CHAPTER:
          setUnits([...units, ...res]);
          break;

        default:
          break;
      }
    };

    await request.get(urlByType, resAction);
    setMemoriedOpenStatus({
      ...memoriedOpenStatus,
      [`${itemType}_${id}`]: isExpand || false,
    });
  };

  const handleDelete = (
    id: number,
    itemType: CourseItemType,
    parentId: number | null
  ) => {
    if (!id || !itemType) return;

    let deleteData: DeleteData = { course_id: id };
    let url = API.ADMIN_COURSE.DELETE;
    let confirmContent = DELETE_CONFIRM_CONTENT.COURSE;
    let confirmTitle = DELETE_CONFIRM_TITLE.COURSE;

    switch (itemType) {
      case COURSE_TABLE_ITEM_TYPE.LECTURE:
        deleteData = { lecture_id: id };
        url = API.ADMIN_LECTURE.DELETE;
        confirmTitle = DELETE_CONFIRM_TITLE.LECTURE;
        confirmContent = DELETE_CONFIRM_CONTENT.LECTURE;

        break;
      case COURSE_TABLE_ITEM_TYPE.CHAPTER:
        deleteData = { chapter_id: id };
        url = API.ADMIN_CHAPTER.DELETE;
        confirmTitle = DELETE_CONFIRM_TITLE.CHAPTER;
        confirmContent = DELETE_CONFIRM_CONTENT.CHAPTER;
        break;

      case COURSE_TABLE_ITEM_TYPE.UNIT:
        deleteData = { unit_id: id };
        url = API.ADMIN_UNIT.DELETE;
        confirmTitle = DELETE_CONFIRM_TITLE.UNIT;
        confirmContent = "";
        break;

      default:
        break;
    }

    swallConfirm(
      async () => {
        await request.delete(url, deleteData, () =>
          setUpdatedObjectInfo({ id: id, itemType: itemType, parentId })
        );
      },
      confirmTitle,
      confirmContent
    );
  };

  const handleOrder = async (
    id: number,
    direction: Direction,
    itemType: CourseItemType,
    parentId: number | null
  ) => {
    let currentArray: CurrentArray = lectures.filter(
        (lecture) => lecture.course_id === Number(courseId)
      ),
      updateEndpoint: string = API.ADMIN_LECTURE.UPDATE_ORDER;

    switch (itemType) {
      case COURSE_TABLE_ITEM_TYPE.CHAPTER:
        currentArray = chapters.filter(
          (chapter) => chapter.lecture_id === Number(parentId)
        );
        updateEndpoint = API.ADMIN_CHAPTER.UPDATE_ORDER;
        break;

      case COURSE_TABLE_ITEM_TYPE.UNIT:
        currentArray = units.filter(
          (unit) => unit.chapter_id === Number(parentId)
        );
        updateEndpoint = API.ADMIN_UNIT.UPDATE_ORDER;
        break;

      default:
        break;
    }

    const currentRecord: TableItemType =
      currentArray.find((item) => item.id === id) || {};
    const currentIndex = currentArray.findIndex((item) => item.id === id);
    const siblingIndex =
      direction === DIRECTION.UP ? currentIndex - 1 : currentIndex + 1;
    const siblingRecord = currentArray[siblingIndex];

    if (!currentRecord || !siblingRecord) return;

    const currentSubmitData = {
      [`${itemType}_id`]: currentRecord.id,
      order:
        direction === DIRECTION.UP
          ? (currentRecord.order || 0) - 1
          : (currentRecord.order || 0) + 1,
    };
    const siblingSubmitData = {
      [`${itemType}_id`]: siblingRecord.id,
      order:
        direction === DIRECTION.UP
          ? (siblingRecord.order || 0) + 1
          : (siblingRecord.order || 0) - 1,
    };

    const currentCoursePromise = request.patch(
      updateEndpoint,
      currentSubmitData
    );
    const siblingCoursePromise = request.patch(
      updateEndpoint,
      siblingSubmitData
    );

    await Promise.all([currentCoursePromise, siblingCoursePromise]);
    setUpdatedObjectInfo({ id: id, itemType: itemType, parentId });
  };

  useEffect(() => {
    const fetch = async () => {
      if (!updatedObjectInfo || !Object.keys(updatedObjectInfo).length) return;

      let getEndpoint: string = `${API.ADMIN_LECTURE.LIST}?${COURSE_TABLE_ITEM_TYPE.COURSE}_id=${courseId}`;
      let actionCallback: <T extends Object>(res: T[]) => void = setLectures;
      let responseTarget = [
        ...lectures.filter((lecture) => lecture.course_id !== Number(courseId)),
      ];

      switch (updatedObjectInfo.itemType) {
        case COURSE_TABLE_ITEM_TYPE.CHAPTER:
          getEndpoint = `${API.ADMIN_CHAPTER.LIST}?${COURSE_TABLE_ITEM_TYPE.LECTURE}_id=${updatedObjectInfo.parentId}`;
          responseTarget = [
            ...chapters.filter(
              (chapter) =>
                chapter.lecture_id !== Number(updatedObjectInfo.parentId)
            ),
          ];
          actionCallback = setChapters;
          break;

        case COURSE_TABLE_ITEM_TYPE.UNIT:
          getEndpoint = `${API.ADMIN_UNIT.LIST}?${COURSE_TABLE_ITEM_TYPE.CHAPTER}_id=${updatedObjectInfo.parentId}`;
          responseTarget = [
            ...units.filter(
              (unit) => unit.chapter_id !== Number(updatedObjectInfo.parentId)
            ),
          ];
          actionCallback = setUnits;
          break;

        default:
          break;
      }

      await request.get(getEndpoint, (res) =>
        actionCallback([...responseTarget, ...res])
      );
    };

    fetch();
  }, [updatedObjectInfo]);

  return (
    <section>
      <TableTitle subContent="選択中のコース" content={courseInfo.name} />
      <div className="flex bg-secondary-extralight items-center justify-between w-full mt-[15px] mb-[8px]">
        <TableHeadItem className="w-[50%] !border-l-0 !justify-start !pl-[10px]">
          学習コース
        </TableHeadItem>
        <TableHeadItem className="w-[5%]">数</TableHeadItem>
        <TableHeadItem className="w-[12%]">並び順</TableHeadItem>
        <TableHeadItem className="w-[10%]">ステータス</TableHeadItem>
        <TableHeadItem className="w-[5%]">削除</TableHeadItem>
        <TableHeadItem className="w_outer_last_table_item !border-r-0 text-secondary-extralight"></TableHeadItem>
      </div>

      <div className="">
        {lectures &&
          lectures.map((lecture, lectureIndex) => (
            <Fragment key={`${COURSE_TABLE_ITEM_TYPE.LECTURE}_${lecture.id}`}>
              <UnitTableItem
                data={lecture}
                itemType={COURSE_TABLE_ITEM_TYPE.LECTURE}
                expandData={memoriedOpenStatus}
                onExpandFetch={handleExpand}
                onDelete={handleDelete}
                onOrder={handleOrder}
                isUpDisabled={lectureIndex === 0}
                isDownDisabled={lectureIndex === lectures.length - 1}
              />
              <div className="">
                {chapters &&
                  memoriedOpenStatus[
                    `${COURSE_TABLE_ITEM_TYPE.LECTURE}_${lecture.id}`
                  ] &&
                  lecture.id &&
                  getDataByParent(
                    chapters,
                    lecture.id,
                    COURSE_TABLE_ITEM_TYPE.LECTURE
                  ).map((chapter, chapterIndex) => (
                    <Fragment
                      key={`${COURSE_TABLE_ITEM_TYPE.CHAPTER}_${chapter.id}`}
                    >
                      <UnitTableItem
                        data={chapter}
                        itemType={COURSE_TABLE_ITEM_TYPE.CHAPTER}
                        expandData={memoriedOpenStatus}
                        onExpandFetch={handleExpand}
                        onDelete={handleDelete}
                        onOrder={handleOrder}
                        isUpDisabled={chapterIndex === 0}
                        isDownDisabled={
                          chapterIndex ===
                          getDataByParent(
                            chapters,
                            lecture.id,
                            COURSE_TABLE_ITEM_TYPE.LECTURE
                          ).length -
                            1
                        }
                        parentId={lecture.id}
                      />
                      <div
                        className={`${
                          units &&
                          memoriedOpenStatus[
                            `${COURSE_TABLE_ITEM_TYPE.CHAPTER}_${chapter.id}`
                          ] &&
                          chapter.id &&
                          "bg-secondary-extralight ml-[40px] mt-[-3px] py-[10px] pl-[-10px]"
                        }`}
                      >
                        {units &&
                          memoriedOpenStatus[
                            `${COURSE_TABLE_ITEM_TYPE.CHAPTER}_${chapter.id}`
                          ] &&
                          chapter.id &&
                          getDataByParent(
                            units,
                            chapter.id,
                            COURSE_TABLE_ITEM_TYPE.CHAPTER
                          ).map((unit, unitIndex) => (
                            <UnitTableItem
                              data={unit}
                              key={`${COURSE_TABLE_ITEM_TYPE.UNIT}_${unit.id}`}
                              itemType={COURSE_TABLE_ITEM_TYPE.UNIT}
                              onDelete={handleDelete}
                              onOrder={handleOrder}
                              isUpDisabled={unitIndex === 0}
                              isDownDisabled={
                                unitIndex ===
                                getDataByParent(
                                  units,
                                  chapter.id,
                                  COURSE_TABLE_ITEM_TYPE.CHAPTER
                                ).length -
                                  1
                              }
                              parentId={chapter.id}
                            />
                          ))}
                      </div>
                    </Fragment>
                  ))}
              </div>
            </Fragment>
          ))}
      </div>
    </section>
  );
};

export default Table;
