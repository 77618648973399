import React, { useState, useContext } from 'react';
import { ChildrenWithProps } from '../../types/globals';
import { Section } from '../../types/admin/section';
import { sortArrayByKey } from '../../services/helpers/parseData';

interface SectionListContextType {
  sections: Section[];
  setSections: (sections: Section[]) => void;
  mainSections: Section[];
  subSections: (parentSectionId?: number) => Section[];
  selectedSection: Section;
  setSelectedSection: (sectionId: Section['id']) => void;
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SectionListContext = React.createContext<SectionListContextType>({
  sections: [],
  setSections: () => {},
  mainSections: [],
  subSections: () => [],
  selectedSection: {},
  setSelectedSection: () => {},
  isOpenModal: false,
  setIsOpenModal: () => {},
});

const SectionListProvider = ({ children }: ChildrenWithProps) => {
  const [sections, _setSections] = useState<Section[]>([]);
  const [selectedSection, _setSelectedSection] = useState<Section>({});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const setSections = (sections: Section[]) => {
    const uniqueSections = sections.filter(
      (section, index, a) => a.findIndex((_section) => _section.id === section.id) === index,
    );
    _setSections(sortArrayByKey(uniqueSections, 'order'));
  };

  const setSelectedSection = (sectionId: Section['id']) =>
    _setSelectedSection(sections.find((section) => section.id === sectionId) || {});

  const mainSections = sections.filter((section) => section.parent_section_id === null);
  const subSections = (parentSectionId?: number) => {
    if (parentSectionId)
      return sections.filter((section) => section.parent_section_id === parentSectionId);
    return sections.filter((section) => section.parent_section_id !== null);
  };

  return (
    <SectionListContext.Provider
      value={{
        sections,
        setSections,
        mainSections,
        subSections,
        selectedSection,
        setSelectedSection,
        isOpenModal,
        setIsOpenModal,
      }}
    >
      {children}
    </SectionListContext.Provider>
  );
};

export default SectionListProvider;

export const useSectionListContext = () => useContext(SectionListContext);
