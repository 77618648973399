import React, { useState } from "react";
import { placeYieldQuestionContent } from "../../../../services/utils/student/course";
import { Question } from "../../../../types/student/unit";
import AudioPlayer from "../../common/AudioPlayer";
import { QUESTION_TYPE } from "../../../../services/constants/admin/pages/material";
import { isEmptyObj } from "../../../../services/helpers/etc";
import { SubmitData } from "./ExecuteTest";
import { useStorage } from "../../../../hooks/useStorage";
import { HiOutlineXMark } from "react-icons/hi2";

type QuestionContentProps = {
  question: Question;
  currentStep: number;
  options?: QuestionContentOptions;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type QuestionContentOptions = {
  autoHeightContent?: boolean;
  disabled?: boolean;
  answeredData?: SubmitData["questions"];
};

const QuestionContent = ({
  question,
  currentStep,
  options,
  setIsOpen,
}: QuestionContentProps) => {
  const [isPlayedAudio, setIsPlayedAudio] = useState(false);
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  if (isEmptyObj(question)) return <></>;
  const { id, title, type, resource_data } = question;

  return (
    <div
      style={{ color: studentTheme.text_color }}
      className="relative ml-[43px] mt-[33px] text-secondary-dark animate-[show_0.5s_ease-in-out]"
      key={id}
    >
      {setIsOpen && (
        <button
          type="button"
          className="text-white bg-secondary rounded-[100%] h-[25px] w-[25px] flex justify-center items-center absolute top-[-15px] right-[15px]"
          onClick={() => setIsOpen(false)}
        >
          <HiOutlineXMark size={20} />
        </button>
      )}
      <div className="mb-[33px] font-[700] text-[20px] leading-[100%] tracking-[0.02em]">
        問題 {currentStep + 1}
      </div>
      {type === QUESTION_TYPE.LISTENING && (
        <>
          <div
            className=""
            dangerouslySetInnerHTML={{ __html: title || "" }}
          ></div>
          <div className="flex items-start mt-[25px] mb-[60px] max-sm:w-[280px]">
            <AudioPlayer
              src={
                resource_data?.resource_link || question?.resource_link || ""
              }
              className="!justify-start"
              readOnly
              setIsPlayed={setIsPlayedAudio}
            />
          </div>
        </>
      )}
      <div
        className={`${
          type === QUESTION_TYPE.LISTENING && !isPlayedAudio
            ? "pointer-events-none"
            : ""
        }`}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{
            __html: placeYieldQuestionContent(question, options) || "",
          }}
        ></div>
      </div>
    </div>
  );
};

export default QuestionContent;
