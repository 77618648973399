/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { ChildrenWithProps } from '../types/globals';
import { Course } from '../types/admin/course';
import { Lecture } from '../types/admin/lecture';
import { Chapter } from '../types/admin/chapter';
import { request } from '../services/axios/axios';
import { API } from '../services/constants/route/api';

type UpsertUnitContextType = {
  courses: Course[];
  setCourses: (courses: Course[]) => void;
  lectures: Lecture[];
  setLectures: (lectures: Lecture[]) => void;
  chapters: Chapter[];
  setChapters: (chapters: Chapter[]) => void;
};

const UpsertUnitContext = React.createContext<UpsertUnitContextType>({
  courses: [],
  setCourses: () => {},
  lectures: [],
  setLectures: () => {},
  chapters: [],
  setChapters: () => {},
});

const UpsertUnitProvider = ({ children }: ChildrenWithProps) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [lectures, setLectures] = useState<Lecture[]>([]);
  const [chapters, setChapters] = useState<Chapter[]>([]);

  //fetch courses, lectures, chapters
  useEffect(() => {
    if (!!courses.length && !!lectures.length) return;

    const coursePromise = request.get(API.ADMIN_COURSE.LIST);
    const lecturePromise = request.get(API.ADMIN_LECTURE.LIST);
    const chapterPromise = request.get(API.ADMIN_CHAPTER.LIST);

    const fetch = async () => {
      const [courseResult, lectureResult, chapterResult] = await Promise.all([
        coursePromise,
        lecturePromise,
        chapterPromise,
      ]);

      setCourses(courseResult || []);
      setLectures(lectureResult || []);
      setChapters(chapterResult || []);
    };

    fetch();
  }, []);

  return (
    <UpsertUnitContext.Provider
      value={{
        courses,
        setCourses,
        lectures,
        setLectures,
        chapters,
        setChapters,
      }}
    >
      {children}
    </UpsertUnitContext.Provider>
  );
};

export default UpsertUnitProvider;

export const useUpsertUnitContext = () => useContext(UpsertUnitContext);
