import { StudentActivityLog } from "../../../types/student/studentActivityLog";
import { PATH_COURSE, PATH_STUDY_TOOL } from "../../constants/route/router";
import { STUDENT_ACTIVITY_LOG_TYPE } from "../../constants/student/studytool";
import { swalError } from "../../helpers/swal";

const showLabel = (logType: number) => {
    switch (logType) {
        case STUDENT_ACTIVITY_LOG_TYPE.UNIT_TEST:
            return "テストを受けました";
        case STUDENT_ACTIVITY_LOG_TYPE.UNIT_LESSON:
            return "講義を受けました";
        case STUDENT_ACTIVITY_LOG_TYPE.CREATE_MEMO:
            return "メモを作成しました";
        default:
            return "";
    }
};

const showButton = (logType: number) => {
    switch (logType) {
        case STUDENT_ACTIVITY_LOG_TYPE.UNIT_TEST:
            return "テストの結果をみる";
        case STUDENT_ACTIVITY_LOG_TYPE.UNIT_LESSON:
            return "講義の復習をする";
        case STUDENT_ACTIVITY_LOG_TYPE.CREATE_MEMO:
            return "メモを確認する";
        default:
            return "";
    }
};

const handleRedirectDetail = (logType: number, data: StudentActivityLog) => {
    if (data.is_deleted) {
        if (logType === STUDENT_ACTIVITY_LOG_TYPE.UNIT_TEST) {
            swalError('テストを削除されました。')
            return "";
        }

        if (logType === STUDENT_ACTIVITY_LOG_TYPE.UNIT_LESSON) {
            swalError('単位を削除されました。')
            return "";
        }

        if (logType === STUDENT_ACTIVITY_LOG_TYPE.CREATE_MEMO) {
            swalError('メモを削除されました。');
            return "";
        }
    }

    switch (logType) {
        case STUDENT_ACTIVITY_LOG_TYPE.UNIT_TEST:
        case STUDENT_ACTIVITY_LOG_TYPE.UNIT_LESSON:
            return `${PATH_COURSE.UNIT_DETAIL}?unit_id=${data?.id}&course_id=${data?.course_id
                }`
                // ${
                //   data.log_type === STUDENT_ACTIVITY_LOG_TYPE.UNIT_TEST
                //     ? `&with_result=${DECISION.YES}`
                //     : ""
                // }
                ;
        case STUDENT_ACTIVITY_LOG_TYPE.CREATE_MEMO:
            return `${PATH_STUDY_TOOL.DETAIL_MEMO}/${data.id}`;
        default:
            return "";
    }
};

export { showLabel, showButton, handleRedirectDetail }