const HEADER_TITLE_DEFAULT = "パレットLMS";

const BREADCRUMB_SEPARATOR = "　〉";

const WEEKDAYS = ["月", "火", "水", "木", "金", "土", "日"];

const BASE_LOCAL = "ja";

const PICTURE_TYPE = ["image/jpeg", "image/png"];

const DIRECTION = {
  UP: "up",
  DOWN: "down",
  RIGHT: "right",
  LEFT: "left",
  CENTER: "center",
} as const;

const STATUS = {
  ACTIVE: "有効",
  INACTIVE: "無効",
};

const PUBLIC_STATUS = {
  PUBLIC: "公開",
  PRIVATE: "非公開",
};

const STATUS_DATA = [
  { value: 1, label: STATUS.ACTIVE },
  { value: 0, label: STATUS.INACTIVE },
];

const PUBLIC_STATUS_DATA = [
  { value: 1, label: PUBLIC_STATUS.PUBLIC },
  { value: 0, label: PUBLIC_STATUS.PRIVATE },
];

const STEP = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

const DECISION = {
  YES: 1,
  NO: 0,
};

const BASIC_PASSPHRASE = "passphrase";

const TYPE_DATE_FORMAT = {
  REGULAR: 1,
  YEAR_MONTH: 2,
  REGULAR_GLOBAL: 3,
  TIME_HOUR_MINUTE: 4,
  TIME_HOUR_MINUTE_24H: 5,
  MONTH_DAY: 6,
  SHORT_DEFAULT: 7,
  FULL_TO_MINUTE_DEFAULT: 8,
};
const ROLE_MANABITE = {
  ROLE_TEACHER: 2,
  ROLE_STUDENT: 1,
};

const TYPE_CAPACITY = {
  BYTE: "BYTE",
  KB: "KB",
  MB: "MB",
  GB: "GB",
};

const SERVICE_ID = 1;

const PREFIX_PATH_S3 = {
  FEATURE: "feature_",
  OZ: "organization_",
};

export {
  HEADER_TITLE_DEFAULT,
  BREADCRUMB_SEPARATOR,
  WEEKDAYS,
  BASE_LOCAL,
  DIRECTION,
  STATUS_DATA,
  STEP,
  PUBLIC_STATUS_DATA,
  DECISION,
  BASIC_PASSPHRASE,
  TYPE_DATE_FORMAT,
  ROLE_MANABITE,
  TYPE_CAPACITY,
  SERVICE_ID,
  PREFIX_PATH_S3,
  PICTURE_TYPE,
};
