import { Course } from '../../../../types/admin/course';
import { Link } from 'react-router-dom';
import { PATH_ADMIN_MATERIAL } from '../../../../services/constants/route/router';
import StatusButtonDisplay from './StatusButtonDisplay';

type CourseTableItemProps = {
  className?: string;
  data: Course;
};

const TableItem = ({ data, className }: CourseTableItemProps) => {
  return (
    <div
      className={`min-h-[50px] border border-secondary-light rounded-[5px] flex items-center w-full text-[13px] font-[500] leading-[100%] mb-[4px] bg-secondary-morelighter
       ${className}`}
    >
      <div className="flex items-center text-secondary w-[60%] pl-[20px]">{data.name}</div>

      <div className="w-[10%] flex justify-center items-center text-[13px] font-[500] leading-[100%]">{`${data.lectures_count}`}</div>

      <div className="w-[10%] flex justify-center items-center">
        <StatusButtonDisplay status={data.status || 5} />
      </div>

      <div className="w-[30%] flex justify-center items-center">
        <Link to={`${PATH_ADMIN_MATERIAL.UNIT}?course_id=${data.id}`} className="w-full max-w-[180px]">
          <button className="bg-danger rounded-[5px] text-white px-[7.5px] pt-[3px] pb-[5px] w-full">
            この学習コースを選択
          </button>
        </Link>
      </div>
    </div>
  );
};

export default TableItem;
