import React from 'react';
import CourseBase from '.';
import Form from '../../../components/admin/Course/Setting/Form';
import TableCommonAction from '../../../components/admin/commons/TableCommonAction';
import { useObjectRoutes } from '../../../hooks/useObjectRoutes';
import { PATH_ADMIN_COURSE } from '../../../services/constants/route/router';

const CourseRegistrationPage = () => {
  const { navigate } = useObjectRoutes();

  return (
    <CourseBase title="学習コース管理 ｜ コースの新規登録">
      <TableCommonAction
        className="mb-[60px]"
        createBtnLabel="講義の新規登録"
        onCreate={() => navigate(PATH_ADMIN_COURSE.LECTURE_REGISTRATION)}
      />
      <Form />
    </CourseBase>
  );
};

export default CourseRegistrationPage;
