import React, { useEffect, useState } from "react";
import { ChildrenWithProps } from "../../types/globals";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "../../components/student/MyPage/Sidebar";
import RequiredAuth from "../../middleware/auth";
import CheckRoleStudent from "../../middleware/checkRoleStudent";
import { useStudentThemeContext } from "../../context/StudentThemeContext";
import { StudentThemeType } from "../../types/admin/student_theme";
import { useStorage } from "../../hooks/useStorage";
import { makeRequest } from "../../services/axios/axios";
import { API } from "../../services/constants/route/api";
import { STUDENT_THEME_COLOR_DEFAULT } from "../../services/constants/admin/pages/student_theme";
import MenuScroll from "../../components/student/MyPage/Mobile/MenuScroll";

const StudentLayout = ({
  sidebar,
  children,
  gap = false,
  cover = false,
  ...props
}: {
  sidebar?: JSX.Element;
  gap?: boolean;
  cover?: boolean;
} & ChildrenWithProps) => {
  const { getLocalStorage, setLocalStorage } = useStorage();
  const [style, setStyle] = useState<object>({});
  const studentTheme = getLocalStorage("theme");
  const subDomain = window.location.hostname.split(".")[0];
  const [isSmartPhone, setIsSmartPhone] = useState<boolean>();

  const checkScreenWidth = () => {
    if (window.innerWidth > 375) {
      setIsSmartPhone(false);
    } else {
      setIsSmartPhone(true);
    }
  };
  window.addEventListener("resize", checkScreenWidth);
  document.addEventListener("DOMContentLoaded", checkScreenWidth);
  window.addEventListener("load", checkScreenWidth);

  const handleStyle = (studentTheme: StudentThemeType) => {
    const styles = `
    .react-datepicker__day:hover {
        background-color: ${
          studentTheme.main_color_second || STUDENT_THEME_COLOR_DEFAULT.MAIN_2
        } !important;
        color: white;
      }
      .react-datepicker__day--today {
        background-color: ${
          studentTheme.main_color_second || STUDENT_THEME_COLOR_DEFAULT.MAIN_2
        } !important;
        border-radius: 0;
      }
      .react-datepicker__day--highlighted {
        background-color: ${
          studentTheme.sub_color_second || STUDENT_THEME_COLOR_DEFAULT.SUB_2
        } !important;
        border-radius: 0;
      }
      .react-datepicker__day--selected {
        background-color: ${
          studentTheme.main_color_second || STUDENT_THEME_COLOR_DEFAULT.MAIN_2
        } !important;
        color: white;
      }
      .react-datepicker__current-month {
        color: ${studentTheme.text_color} !important;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${studentTheme.sub_color_first};
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: ${studentTheme.sub_color_first};
      }
      input[type='radio']:checked {
        background-color: ${studentTheme.sub_color_first} !important
      }
    `;

    var styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  };

  useEffect(() => {
    if (!!studentTheme && studentTheme !== null) {
      handleStyle(studentTheme);
      setStyle({
        backgroundColor: studentTheme.background_color,
        color: studentTheme.text_color,
      });
    }

    if (!studentTheme) {
      const fetchStudentThemeAdmin = async () => {
        const result = await makeRequest({
          method: "get",
          url: `${API.STUDENT_THEME_SETTING.GET_DETAIL}?sub_domain=${subDomain}`,
        });
        const studentTheme = result.data;
        handleStyle(studentTheme);
        setLocalStorage("theme", studentTheme);
        setStyle({
          backgroundColor: studentTheme.background_color,
          color: studentTheme.text_color,
        });
      };

      fetchStudentThemeAdmin();
    }
  }, []);

  return (
    <RequiredAuth>
      <CheckRoleStudent>
        <section {...props}>
          <Header />
          <div
            style={style}
            className={`w-full min-h-screen flex justify-center`}
          >
            <div
              className={`w-full ${
                gap ? "max-w-[1084px]" : "max-w-[1095px]"
              } flex justify-between`}
            >
              <div
                className={`flex ${
                  gap ? "gap-[11px]" : "gap-0"
                } pt-[20px] w-full items-start mb-[20px]`}
              >
                {sidebar ? sidebar : <Sidebar />}
                <div
                  className={`w-full bg-white pt-[30px] px-[26px] max-xl:px-[10px] pb-[30px] mb-[20px] ${
                    cover ? "h-full" : ""
                  }
                     max-sm:bg-white max-sm:pt-0 max-sm:px-0`}
                >
                  {children}
                </div>
              </div>
            </div>
            <MenuScroll />
          </div>
          <Footer />
        </section>
      </CheckRoleStudent>
    </RequiredAuth>
  );
};

export default StudentLayout;
