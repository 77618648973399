import { FC } from "react";
import CheckSubDomain from "../middleware/checkSubDomain";
import Logo from "../components/Login/Logo";
import YellowBlock from "../components/Login/YellowBlock";
import BeigeBlock from "../components/Login/BeigeBlock";
import OrangeBlock from "../components/Login/OrangeBlock";
import BlueBlock from "../components/Login/BlueBlock";
import GreenBlock from "../components/Login/GreenBlock";
import PurpleBlock from "../components/Login/PurpleBlock";

interface LayoutProps {
  children?: JSX.Element;
}

const LayoutLandingPage: FC<LayoutProps> = (props) => {
  return (
    <CheckSubDomain>
      <div className="w-full min-h-screen text-center flex flex-col h-full bg-white justify-center items-center">
        <Logo
          imgStyle="object-contain"
          otherStyle={
            "mt-[70px] mx-auto max-sm:w-[200px] max-sm:h-[150px] max-sm:mt-[35px] w-[180px] h-[180px] relative z-[20]"
          }
        />
        {props.children}
        <div className="fixed top-0 left-0 z-10">
          <YellowBlock />
        </div>
        <div className="fixed top-[91px] left-[146px]">
          <BeigeBlock />
        </div>
        <div className="fixed bottom-0 left-0">
          <OrangeBlock />
        </div>
        <div className="fixed bottom-[-13px] right-[133px]">
          <BlueBlock />
        </div>
        <div className="fixed bottom-[38%] right-0">
          <GreenBlock />
        </div>
        <div className="fixed bottom-[27%] right-0">
          <PurpleBlock />
        </div>
      </div>
    </CheckSubDomain>
  );
};

export default LayoutLandingPage;
