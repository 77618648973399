import React, { useEffect, useRef, useState } from "react";
import { HiOutlineUserCircle } from "react-icons/hi";
import { SlCamera } from "react-icons/sl";
import { useAuthContext } from "../../../context/AuthContext";
import { API } from "../../../services/constants/route/api";
import { request } from "../../../services/axios/axios";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
import { deleteFile, uploadFileToS3 } from "../../../services/helpers/s3Helper";
import { useStorage } from "../../../hooks/useStorage";
import { swalError, swalMessage } from "../../../services/helpers/swal";
import { MESSAGE_COMMON } from "../../../services/constants/message";
import {
  PREFIX_PATH_S3,
  SERVICE_ID,
} from "../../../services/constants/globals";
import ImageInternalButton from "../../commons/buttons/ImageInternalButton";
import Button from "../../Login/Button";
import default_avatar from "../../../assests/images/default_avatar.webp";

const SidebarAvatar = () => {
  const folderName = "avatar";
  const inputFileRef = useRef<HTMLInputElement>(null);
  let { setUser, user } = useAuthContext();
  const [avatar, setAvatar] = useState<File | null>(null);
  const [avatarURL, setAvatarURL] = useState<string | null | undefined>(
    user?.avatar_url
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { studentTheme } = useStudentThemeContext();
  const { setLocalStorage } = useStorage();
  const oz_id = user.organization_id;

  useEffect(() => {
    setAvatarURL(user?.avatar_url);
  }, [user]);

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (!file) return;
    const fileType = file.type;
    const fileSize = file.size / 1024 / 1024; // Size in MB

    // Check file type
    if (!fileType.startsWith("image/")) {
      swalMessage("", MESSAGE_COMMON.INVALID_FILE_TYPE, "error", {
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }
    // Check file size
    if (fileSize > 2) {
      swalMessage("", `添付ファイルのサイズが2MBを越えています`, "error", {
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    setAvatar(file);
    setAvatarURL(URL.createObjectURL(file));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let pathFile = "";

    try {
      if (!avatar) {
        deleteFile(user.avatar || "");
      } else {
        const fileExtension = avatar.name.split(".").pop()?.toLowerCase();
        const fileName = `${Date.now()}.${fileExtension}`;
        pathFile =
          PREFIX_PATH_S3.FEATURE +
          SERVICE_ID +
          "/" +
          folderName +
          "/" +
          PREFIX_PATH_S3.OZ +
          oz_id +
          "/" +
          fileName;
        await uploadFileToS3(avatar, pathFile);
      }

      await request.post(
        API.PROFILE.UPDATE_AVATAR,
        { path_file: pathFile },
        (userData) => {
          deleteFile(user.avatar || "");
          setUser(userData || {});
          setLocalStorage("user", userData);
        },
        () => {
          deleteFile(pathFile);
        },
        { withSuccess: true, withLoading: true, hasFileRequest: true }
      );
    } catch (error) {
      console.log("Error: ", error);
      swalError();
    }
    setIsSubmitting(false);
  };

  return (
    <div className="w-full pb-[25px] border-b border-danger-light mb-[15px]">
      {avatar || user?.avatar_url ? (
        <div className="flex items-center justify-center mb-[10px] relative">
          <div className="absolute right-[60px] top-[-5px]">
            <ImageInternalButton
              removeImage={() => {
                if (inputFileRef.current) {
                  inputFileRef.current.value = "";
                }
                setAvatar(null);
                setAvatarURL(null);
              }}
              editImage={() => inputFileRef.current?.click()}
            />
          </div>
          <img
            className="w-[80px] h-[80px] object-cover border-gray-200 cursor-pointer"
            src={avatarURL ? avatarURL : default_avatar}
            alt="Profile Avatar"
            onClick={() => inputFileRef.current?.click()}
          />
        </div>
      ) : (
        <>
          <div
            className="w-full flex justify-center cursor-pointer"
            onClick={() => inputFileRef.current?.click()}
          >
            <div className="relative w-full max-w-[80px] h-full min-h-[80px] mb-[10px]">
              <div className="bg-secondary-morelighter w-full h-full flex justify-center items-center absolute top-0 left-0">
                <HiOutlineUserCircle color="#EBE4D8" size={55} />
              </div>

              <div className="w-[19px] h-[15px] bg-secondary-light flex justify-center items-center absolute bottom-0 right-0">
                <SlCamera color="#FAF7F0" size={11.46} />
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="w-full flex justify-center cursor-pointer"
        onClick={() => handleSubmit()}
      >
        <Button
          otherstyle={
            "font-[500] text-[11px] leading-[11px] text-center text-white w-full max-w-[145px] rounded-[5px] bg-primary pt-[5px] py-[7px]"
          }
          content="プロフィール写真を変更"
          style={{
            backgroundColor: studentTheme.main_color_first,
            height: "30px",
          }}
          isSubmitting={isSubmitting}
        />
      </div>
      <input
        type="file"
        id="image"
        name="image"
        accept="image/*"
        className="hidden"
        ref={inputFileRef}
        onChange={handleAvatarChange}
        placeholder="ファイルを選択"
      />
    </div>
  );
};

export default SidebarAvatar;
