import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ErrorData } from "../../../../types/globals";
import ErrorBox from "../../../commons/form/ErrorBox";
import { Product } from "../../../../types/admin/product";
import { Course } from "../../../../types/admin/course";
import { Lecture } from "../../../../types/admin/lecture";
import RichTextEditorProduct from "../../../commons/form/TextEditorProduct";
import { makeRequest, request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import {
  EXPIRY_DATE,
  EXPIRY_DATE_DEFAULT,
  RESOURCE_TYPE,
} from "../../../../services/constants/admin/pages/product";
import BaseModal from "../../commons/BaseModal";
import UploadFile from "../../commons/UploadFile";
import dayjs from "dayjs";

import { ShowPickerStatus } from "../../../student/StudyTool/Schedule/EventForm";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { MainResource } from "../../../../types/admin/unit";
import { useNavigate, useParams } from "react-router-dom";
import ProductFormItem from "./ProductFormItem";
import { PATH_ADMIN_PRODUCT } from "../../../../services/constants/route/router";
import { LuTrash2 } from "react-icons/lu";
import {
  swalError,
  swalLoading,
  swallConfirm,
} from "../../../../services/helpers/swal";
import ProductFormDate from "./ProductFormDate";
import { handleFileUpload } from "../../../../services/utils/admin/product";
import ProductFormUploadFile from "./ProductFormUploadFile";
import FormInput from "./FormInput";
import { transformZenkakuToNormalNumber } from "../../../../services/helpers/parseData";

type ExpireDateType = {
  label?: string;
  display?: string;
  value?: number;
};

const ProductUpsert = () => {
  const [productFormData, setProductFormData] = useState<Product>({
    expiry_quantity: null,
  });
  const [expiryDateType, setExpiryDateType] =
    useState<ExpireDateType>(EXPIRY_DATE_DEFAULT);
  const [courses, setCourses] = useState<Course[]>([]);
  const [filterLectures, setFilterLectures] = useState<Lecture[]>([]);
  const [errors, setErrors] = useState<ErrorData>({});
  const [typeResource, setTypeResource] = useState<number>(RESOURCE_TYPE.IMAGE);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [inventory, setInventory] = useState<number>(0);
  const [showStatuses, setShowStatuses] = useState<ShowPickerStatus[]>([]);
  const [showDatetimeSell, setShowDatetimeSell] = useState<boolean>(false);
  const [uploadDate, setUploadDate] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [fileFolderId, setFileFolderId] = useState<string>("");
  const [fileFolderCode, setFileFolderCode] = useState<number | null>(null);
  const [resource, setResource] = useState<MainResource | null>(null);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [processPercent, setProcessPercent] = useState<number>(0);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setDisabledButton(false);

    const fetchCourse = async () => {
      const result = await makeRequest({
        method: "get",
        url: API.ADMIN_COURSE.LIST,
      });

      setCourses(result.data);
    };

    const fetchLecture = async (courseId: number, productId: number) => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_LECTURE.LIST_FOR_PRODUCT}?course_id=${courseId}&product_id=${productId}`,
      });

      setFilterLectures(result.data);
    };

    if (params.id) {
      const productId = Number(params.id);
      const getProduct = async () => {
        await request.get(
          `${API.ADMIN_PRODUCT.DETAIL}?product_id=${productId}`,
          (product) => {
            setProductFormData(product);
            fetchCourse();
            fetchLecture(product.course_id, productId);

            if (product?.inventory !== null) setInventory(1);
            if (product?.start_date_sell && product?.end_date_sell)
              setShowDatetimeSell(true);
            if (product?.expiry_type !== null)
              setExpiryDateType(EXPIRY_DATE[product.expiry_type]);
            if (product?.resource) setResource(product?.resource);
            if (product?.resource_url) setTypeResource(RESOURCE_TYPE.URL);
          },
          (error) => {
            navigate("/404");
          }
        );
      };

      getProduct();
      return;
    } else {
      handleClearForm();
    }

    fetchCourse();
  }, [params]);

  useEffect(() => {
    let newProductFormData = productFormData;
    if (params.id) {
      setProductFormData({ ...newProductFormData, resource_id: resource?.id });
    }
  }, [resource]);

  const handleChangeForm = (
    e:
      | FormEvent<HTMLInputElement>
      | ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setDisabledButton(false);

    let currentInput = e.currentTarget;
    let newProductFormData = productFormData;

    switch (currentInput.type) {
      case "number":
        newProductFormData = {
          ...newProductFormData,
          [currentInput.name]: Number(parseInt(currentInput.value)),
        };
        break;
      case "checkbox":
        newProductFormData = {
          ...newProductFormData,
          [currentInput.name]: (currentInput as HTMLInputElement).checked,
        };
        break;
      case "time":
        // validate input is 'start_time' & 'end_time' for sure
        if (
          currentInput.name !== "start_date_sell" &&
          currentInput.name !== "end_date_sell"
        )
          return;

        let currentDate;
        if (currentInput.name === "start_date_sell") {
          currentDate = formatDateTime(
            productFormData["start_date_sell"] || new Date().toDateString(),
            TYPE_DATE_FORMAT.REGULAR_GLOBAL
          );
        }

        if (currentInput.name === "end_date_sell") {
          currentDate = formatDateTime(
            productFormData["end_date_sell"] || new Date().toDateString(),
            TYPE_DATE_FORMAT.REGULAR_GLOBAL
          );
        }

        // combine date with time
        newProductFormData = {
          ...newProductFormData,
          [currentInput.name]: dayjs(
            new Date(`${currentDate} ${currentInput.value}`)
          ).format(),
        };
        break;
      default:
        newProductFormData = {
          ...newProductFormData,
          [currentInput.name]: currentInput.value,
        };
        break;
    }

    switch (currentInput.name) {
      case "inventory_type":
        const inventoryType = Number(currentInput.value);
        setInventory(inventoryType);

        if (!!inventoryType) {
          newProductFormData = { ...newProductFormData, inventory: 1 };
        } else {
          newProductFormData = { ...newProductFormData, inventory: null };
        }

        break;
      case "expiry_type":
        const expiryType = Number(currentInput.value);
        newProductFormData = {
          ...newProductFormData,
          [currentInput.name]: expiryType,
        };

        if (!expiryType) {
          newProductFormData = {
            ...newProductFormData,
            expiry_quantity: null,
          };
        }

        setExpiryDateType(EXPIRY_DATE[expiryType]);

        break;
      case "course_id":
        const fetch = async () => {
          const courseId = Number(currentInput.value);
          if (!courseId) {
            setFilterLectures([]);
            return;
          }

          let url = `${API.ADMIN_LECTURE.LIST_FOR_PRODUCT}?course_id=${courseId}`;
          if (params.id) {
            url += `&product_id=${params.id}`;
          }

          await request.get(url, (lectures) => {
            setFilterLectures(lectures);
          });
        };

        fetch();
        break;
      case "sale_price":
        const value: number = Number((e.target as HTMLInputElement).value);
        let salePrice = "";
        
        if (value !== 0) {
          salePrice = transformZenkakuToNormalNumber(currentInput.value);
        }

        newProductFormData = {
          ...newProductFormData,
          sale_price: salePrice,
        };

        break;
      case "price":
      case "inventory":
      case "expiry_quantity":
        let newValue = transformZenkakuToNormalNumber(currentInput.value);
        newProductFormData = {
          ...newProductFormData,
          [currentInput.name]: newValue,
        };

        break;
      default:
        break;
    }
    setProductFormData(newProductFormData);
  };

  const handleChangeDateSell = () => {
    if (showDatetimeSell) {
      setShowDatetimeSell(false);
      setShowStatuses([]);
      const newProductFormData = {
        ...productFormData,
        start_date_sell: null,
        end_date_sell: null,
      };
      setProductFormData(newProductFormData);
    } else {
      setShowDatetimeSell(true);
    }
  };

  const handleClearForm = () => {
    setProductFormData({
      overview: "",
      description: "",
      expiry_type: 0,
      expiry_quantity: undefined,
    });
    setInventory(0);
    setShowDatetimeSell(false);
    setExpiryDateType(EXPIRY_DATE_DEFAULT);
    setResource(null);
    setTypeResource(RESOURCE_TYPE.IMAGE);
  };

  const handleDeleteProduct = async (productId: number) => {
    swallConfirm(async () => {
      const result = await makeRequest({
        method: "delete",
        url: API.ADMIN_PRODUCT.DELETE,
        data: { product_ids: [params.id] },
      });

      if (!result.status) return swalError();
      navigate(PATH_ADMIN_PRODUCT.DEFAULT);
    }, "この商品を削除します。<br>よろしいですか？");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setDisabledButton(true);

    let resourceId = null;
    if (file && fileFolderCode && fileFolderId) {
      resourceId = await handleFileUpload(
        file,
        fileFolderCode,
        fileFolderId,
        setProcessPercent,
        setErrors
      );
      if (resourceId === null) return;
    }

    if (resource) {
      resourceId = resource.id;
    }

    let newProductFormData = { ...productFormData, resource_id: resourceId };

    if (newProductFormData.start_date_sell) {
      newProductFormData = {
        ...newProductFormData,
        start_date_sell: dayjs(productFormData.start_date_sell).format(),
      };
    }
    if (newProductFormData.end_date_sell) {
      newProductFormData = {
        ...newProductFormData,
        end_date_sell: dayjs(productFormData.end_date_sell).format(),
      };
    }

    if (params.id) {
      await request.patch(
        API.ADMIN_PRODUCT.UPDATE,
        newProductFormData,
        () => {
          navigate(PATH_ADMIN_PRODUCT.DEFAULT);
        },
        (errors) => {
          setErrors(errors);
          setDisabledButton(false);
        },
        { withSuccess: true, withLoading: false }
      );
    } else {
      await request.post(
        API.ADMIN_PRODUCT.CREATE,
        newProductFormData,
        () => {
          navigate(PATH_ADMIN_PRODUCT.DEFAULT);
        },
        (errors) => {
          setErrors(errors);
          setDisabledButton(false);
        },
        { withSuccess: true, withLoading: false }
      );
    }
  };

  return (
    <>
      <div className="mt-[40px]">基本情報</div>
      <form>
        <div className="mb-[25px] mt-[10px]">
          {errors && Object.keys(errors).length > 0 && (
            <ErrorBox errors={errors} />
          )}
          <ProductFormItem name="販売コース選択" isRequired={true}>
            <div className="flex flex-col ml-[10px] text-secondary text-[12px] font-[400] py-[10px]">
              <div className="mb-[10px] w-[350px]">
                <select
                  name="course_id"
                  className="w-full pr-[30px] truncate"
                  onChange={handleChangeForm}
                  value={productFormData?.course_id || 0}
                >
                  <option value="0">販売対象の学習コースを選択</option>
                  {courses?.map((course, index) => (
                    <option value={course.id} key={index}>
                      {course.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-[350px]">
                <select
                  name="lecture_id"
                  className="w-full pr-[30px] truncate"
                  onChange={handleChangeForm}
                  value={productFormData?.lecture_id || 0}
                >
                  <option value="0">販売対象の講義を選択</option>
                  {filterLectures?.map((lecture, index) => (
                    <option value={lecture.id} key={index}>
                      {lecture.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </ProductFormItem>
          <ProductFormItem name="商品名" isRequired={true}>
            <div className="text-secondary-dark ml-[13px] w-full flex items-center flex-col">
              <div className="flex text-[10px] font-[400] leading-[100%] items-center w-full">
                <FormInput
                  className="text-[40px] leading-[100%] flex items-center border-[#EFF1F0] border-[1px] pl-[8px] h-[30px] pb-[18px] tracking-tighter my-[5px] w-full"
                  type="text"
                  name="name"
                  value={productFormData?.name || ""}
                  handleChange={handleChangeForm}
                />
              </div>
            </div>
          </ProductFormItem>
          <ProductFormItem name="価格（税抜）" isRequired={true}>
            <div className="text-secondary-dark ml-[13px] w-full flex items-center flex-col mx-[5px] mr-[30px]">
              <div className="flex text-[10px] font-[400] leading-[100%] items-center w-full">
                <FormInput
                  className="max-w-[180px] text-[14px] leading-[100%] flex items-center text-center border-[#EFF1F0] border-[1px] pl-[8px] h-[30px] tracking-tighter my-[5px] w-[20%]"
                  name="price"
                  value={productFormData?.price || ""}
                  handleChange={handleChangeForm}
                  min={1}
                  labelName="円"
                  pattern="\d*"
                />
              </div>
            </div>
          </ProductFormItem>
          <ProductFormItem name="特別価格（税抜）">
            <div className="text-secondary-dark ml-[13px] w-full flex items-center flex-col">
              <div className="flex text-[10px] font-[400] leading-[100%] items-center w-full">
                <FormInput
                  name="sale_price"
                  value={productFormData?.sale_price || ""}
                  min={1}
                  className="max-w-[180px] text-[14px] leading-[100%] flex items-center text-center border-[#EFF1F0] border-[1px] pl-[8px] h-[30px] tracking-tighter my-[5px] w-[20%]"
                  handleChange={handleChangeForm}
                  pattern="\d*"
                />
                <span className="ml-[5px] text-[14px] text-secondary-dark font-[500]">
                  円
                </span>
                <FormInput
                  id="is_sale"
                  type="checkbox"
                  name="is_sale"
                  className="ml-[50px]"
                  handleChange={handleChangeForm}
                  checked={productFormData?.is_sale}
                  labelName="特別価格で販売する"
                />
              </div>
            </div>
          </ProductFormItem>
          <ProductFormItem name="商品概要説明" isRequired={true}>
            <div className="text-secondary-dark ml-[13px] w-full flex items-center">
              <textarea
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[140px] w-full pt-[10px] my-[5px] placeholder:text-[12px] placeholder:text-secondary-light"
                name="overview"
                placeholder="商品概要説明を入力してください"
                value={productFormData?.overview}
                onChange={handleChangeForm}
              ></textarea>
            </div>
          </ProductFormItem>
          <ProductFormItem name="詳細説明" isRequired={true}>
            <div className="w-full ml-[10px]">
              <RichTextEditorProduct
                value={productFormData?.description}
                changeHandler={(html: string) => {
                  setProductFormData((prev) => ({
                    ...prev,
                    description: html,
                  }));
                }}
                toolbarId="toolbar"
              />
            </div>
          </ProductFormItem>

          <ProductFormItem name="在庫数" isRequired={true}>
            <div className="text-secondary-dark ml-[13px] w-full flex items-center gap-[5px] mx-[5px] mr-[30px]">
              <div className="flex text-[10px] font-[400] leading-[100%] items-center">
                <select
                  name="inventory_type"
                  id=""
                  className="w-[130px]"
                  onChange={handleChangeForm}
                  value={Number(inventory)}
                >
                  <option value={0}>無制限</option>
                  <option value={1}>制限</option>
                </select>
              </div>
              {!!Number(inventory) && (
                <div>
                  <FormInput
                    name="inventory"
                    className="w-[50px] h-[30px] text-[14px] text-center"
                    value={
                      productFormData?.inventory !== null
                        ? productFormData.inventory
                        : ""
                    }
                    min={1}
                    handleChange={handleChangeForm}
                    pattern="\d*"
                  />
                </div>
              )}
            </div>
          </ProductFormItem>

          <ProductFormItem name="販売期限" isRequired={true}>
            <div className="text-secondary-dark ml-[13px] w-full flex flex-col justify-center mx-[5px] mr-[30px] pt-[10px]">
              <div className="flex text-[10px] font-[400] leading-[100%] items-center w-full">
                <div className="flex items-center">
                  <FormInput
                    type="radio"
                    name="sell_date"
                    id="no_deadline"
                    className="cursor-pointer text-primary-light"
                    handleChange={handleChangeDateSell}
                    checked={!showDatetimeSell}
                    labelName="期限なし"
                  />
                </div>
                <div className="ml-[22px] flex items-center">
                  <FormInput
                    type="radio"
                    name="sell_date"
                    id="deadline"
                    className="cursor-pointer text-primary-light"
                    handleChange={handleChangeDateSell}
                    checked={showDatetimeSell}
                    labelName="期限設定あり"
                  />
                </div>
              </div>
              {/* start time and end time sell */}
              <ProductFormDate
                productFormData={productFormData}
                showDatetimeSell={showDatetimeSell}
                showStatuses={showStatuses}
                setShowStatuses={setShowStatuses}
                handleChangeForm={handleChangeForm}
                setProductFormData={setProductFormData}
              />
            </div>
          </ProductFormItem>

          <ProductFormItem name="有効期間" isRequired={true}>
            <div className="text-secondary-dark ml-[13px] w-full flex items-center mx-[5px] mr-[30px]">
              <div className="flex text-[10px] font-[400] leading-[100%] items-center w-full">
                <div>
                  <select
                    name="expiry_type"
                    className="w-[130px] truncate"
                    onChange={handleChangeForm}
                    value={productFormData?.expiry_type}
                  >
                    {EXPIRY_DATE.map((item, index) => (
                      <option value={index} key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="ml-[11px]">
                  <FormInput
                    name="expiry_quantity"
                    className="w-[50px] h-[30px] text-[14px] text-center"
                    handleChange={handleChangeForm}
                    value={productFormData?.expiry_quantity || ""}
                    labelName={expiryDateType.display}
                    pattern="\d*"
                  />
                </div>
              </div>
            </div>
          </ProductFormItem>

          <ProductFormItem name="商品動画">
            <ProductFormUploadFile
              file={file}
              resource={resource}
              uploadDate={uploadDate}
              processPercent={processPercent}
              typeResource={typeResource}
              productFormData={productFormData}
              setIsOpenModal={setIsOpenModal}
              setFile={setFile}
              setResource={setResource}
              setTypeResource={setTypeResource}
              handleChangeForm={handleChangeForm}
              setFileFolderId={setFileFolderId}
            />
          </ProductFormItem>
        </div>

        <div className="flex gap-[10px] items-center justify-center">
          {params.id && (
            <div
              className="flex items-center gap-[10px] px-[30px] py-[10px] border border-secondary rounded-[10px] cursor-pointer"
              onClick={() => handleDeleteProduct(Number(params.id))}
            >
              <LuTrash2 size={24} className="text-secondary" />
              <button
                type="button"
                className="text-[16px] text-secondary font-[500]"
              >
                この商品を削除
              </button>
            </div>
          )}
          {/* <div className="">
            <button
              type="reset"
              className="py-[10px] px-[50px] bg-secondary-light rounded-[10px] text-[16px] text-white font-[700]"
              onClick={handleClearForm}
            >
              リセット
            </button>
          </div> */}
          <div className="">
            <button
              type="reset"
              className="py-[10px] px-[50px] bg-secondary-light rounded-[10px] text-[16px] text-white font-[700]"
              onClick={() => {
                navigate(PATH_ADMIN_PRODUCT.DEFAULT);
              }}
            >
              キャンセル
            </button>
          </div>
          <div className="">
            <button
              className={`py-[10px] px-[50px] bg-primary rounded-[10px] text-[16px] text-white font-[700] ${
                disabledButton ? "opacity-25" : ""
              }`}
              onClick={handleSubmit}
              disabled={disabledButton}
            >
              この内容で商品登録する
            </button>
          </div>
        </div>
      </form>
      {isOpenModal && (
        <BaseModal
          setIsOpen={setIsOpenModal}
          width={1010}
          height={718}
          justifyDirection="end"
        >
          <UploadFile
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            setFile={setFile}
            fileType={typeResource}
            setIsOpen={setIsOpenModal}
            setUploadDate={setUploadDate}
            setFolderId={setFileFolderId}
            folderId={fileFolderId}
            setFolderCode={setFileFolderCode}
            setResource={setResource}
            isDisabled={!fileFolderId}
          />
        </BaseModal>
      )}
    </>
  );
};

export default ProductUpsert;
