import { ROUTE } from '../route/router';

const PROFILE_SIDEBAR_DATA = [
  {
    label: '基本情報設定',
    child: [
      { href: `${ROUTE.PROFILE.DEFAULT}`, label: 'お客様情報' },
      {
        href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.MAIL_CHANGING}`,
        label: 'メールアドレス変更',
      },
      {
        href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.PASSWORD_CHANGING}`,
        label: 'パスワード変更',
      },
      // uncooment for purchase-history
      {
        href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.PURCHASE_HISTORY}`,
        label: '購入履歴・領収書',
      },
      // SidebarItem hidden for future phases
      // { href: '/#', label: '外部アカウント連携・解除' },
      // { href: '/#', label: 'コミュニティ一覧' },
      // { href: '/#', label: '目標設定' },
      // { href: '/#', label: '保有資格登録' },
      // { href: '/#', label: '受講修了証' },
      // { href: '/#', label: '購入履歴・領収書' },
      // { href: '/#', label: '通知設定' },
      // { href: '/#', label: '操作履歴' },
    ],
  },
  // SidebarItem hidden for future phases
  {
    label: '機能設定',
    child: [
      // { href: '/#', label: 'コミュニティ設定' },
      // { href: '/#', label: 'zoom設定' },
      // { href: '/#', label: '出席管理設定' },
      // { href: '/#', label: 'シラバス設定' },
      // { href: '/#', label: '講座購入設定' },
      // { href: '/#', label: '課題提出設定' },
      // { href: '/#', label: '課題評価設定' },
      // { href: '/#', label: '受信ボックス設定' },
    ],
  },
];

const PROFILE_SIDEBAR_DATA_MENU = [
  { href: `${ROUTE.PROFILE.DEFAULT}`, label: 'お客様情報' },
  {
    href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.MAIL_CHANGING}`,
    label: 'メールアドレス変更',
  },
  {
    href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.PASSWORD_CHANGING}`,
    label: 'パスワード変更',
  },
  {
    href: `${ROUTE.PROFILE.DEFAULT}/${ROUTE.PROFILE.PURCHASE_HISTORY}`,
    label: '購入履歴・領収書',
  },
  // SidebarItem hidden for future phases
  { href: '/#', label: '外部アカウント連携・解除' },
  { href: '/#', label: 'コミュニティ一覧' },
  { href: '/#', label: '目標設定' },
  { href: '/#', label: '保有資格登録' },
  { href: '/#', label: '受講修了証' },
  // { href: '/#', label: '購入履歴・領収書' },
  { href: '/#', label: '通知設定' },
  { href: '/#', label: '操作履歴' }
];

export { PROFILE_SIDEBAR_DATA, PROFILE_SIDEBAR_DATA_MENU };
