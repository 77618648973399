import React from 'react';
import RequiredBox from './RequiredBox';
import OptionalBox from './OptionalBox';

type FormItemProps = {
  children: React.ReactNode;
  label: string;
  withRequired?: boolean;
  withOptional?: boolean;
  zIndex?: string;
};

const FormItem = ({ label, withRequired = false, withOptional = false, children, zIndex }: FormItemProps) => {
  return (
    <div className="border-b border-secondary-lighter flex mb-[3px]">
      <div className="flex justify-between items-center text-[14px] text-secondary-dark font-[600] leading-[100%] bg-success-lighter pl-[14px] pr-[10px] min-h-[40px] w-full max-w-[350px]">
        {label}
        {withRequired && <RequiredBox />}
        {withOptional && <OptionalBox />}
      </div>
      <div className={`w-full py-[5px] pl-[9px] ${zIndex}`}>{children}</div>
    </div>
  );
};

export default FormItem;
