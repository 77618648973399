import { Link, useNavigate } from "react-router-dom";
import { DIRECTION } from "../../services/constants/globals";
import { SidebarItem as SidebarItemProps } from "../../types/admin/main";
import SidebarDirection from "./SidebarDirection";
import { useEffect, useState } from "react";

type SidebarProps = {
  data: SidebarItemProps;
  currentPath: string;
  isOpen: boolean;
  onItemClick: () => void;
  isMinimize?: boolean;
  otherStyle?: string;
};

const SidebarItem = ({
  data,
  currentPath,
  isOpen,
  onItemClick,
  isMinimize,
  otherStyle
}: SidebarProps) => {
  const { label, href, childs, icon: IconComponent } = data;
  const [isChooseParent, setIsChooseParent] = useState<boolean>(false);
  const parentPath = currentPath.split("/")[2];
  const navigate = useNavigate();

  const isChooseChild = (href: string) => {
    if (href === currentPath) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!childs.length) {
      if (currentPath === href) setIsChooseParent(true);
    } else {
      childs.forEach((child) => {
        const featurePath = child.href.split("/")[2];
        if (featurePath === parentPath) {
          setIsChooseParent(child.href.includes(parentPath));
        }
      });
    }
  }, [currentPath]);

  const handleOnClick = () => {
    onItemClick();
    if (href.length && !isMinimize) {
      navigate(href);
    }
  };

  return (
    <>
      <div
        className={`bg-success-lighter flex justify-between items-center cursor-pointer min-h-[40px] max-h-[48px] px-[10px] py-[15px] ${
          (isMinimize || !isOpen || !childs.length) && isChooseParent
            ? "!bg-primary"
            : ""
        } ${otherStyle}`}
        onClick={() => {
          handleOnClick();
        }}
      >
        <div
          className={`flex items-center text-[#5A5A5A] text-[13px] font-[500] leading-[100%] ${
            isMinimize ? "w-full justify-center" : "gap-[8px]"
          } ${
            (!isOpen || !childs.length) && isChooseParent ? "!text-white" : ""
          }`}
        >
          <IconComponent
            color={
              (isMinimize || !isOpen || !childs.length) && isChooseParent
                ? "#FFFFFF"
                : "#5A5A5A"
            }
          />
          {!isMinimize && label}
        </div>
        {!isMinimize && (
          <SidebarDirection
            onDirection={isOpen ? DIRECTION.UP : DIRECTION.DOWN}
            color={!isOpen && isChooseParent ? "#FFFFFF" : ""}
          />
        )}
      </div>
      {!isMinimize && isOpen && childs && childs.length > 0 && (
        <>
          {childs.map(({ href, label }, index) => (
            <Link to={href} className="w-full cursor-pointer" key={index}>
              <div
                className={`min-h-[36px] flex items-center pl-[42px] text-[12px] font-[500] leading-[100%] bg-white border-b border-success-extralight ${
                  isChooseChild(href)
                    ? "text-white !bg-primary"
                    : "text-[#5A5A5A]"
                } hover:bg-primary hover:text-white`}
              >
                {label}
              </div>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

export default SidebarItem;
