import React, { useEffect } from "react";
import Payment from "../../../components/student/Payment/Payment";
import CartContextProvider from "../../../context/CartContext";
import { useNavigate } from "react-router-dom";

const StudentPaymentPage = () => {
  const navigate = useNavigate();
  const paymentFeature = localStorage.getItem("payment_feature");

  useEffect(() => {
    if (paymentFeature) {
      const parseData = JSON.parse(paymentFeature);
      if (!parseData) navigate("/404");
    }
  }, [paymentFeature]);

  return (
    <CartContextProvider>
      <Payment />
    </CartContextProvider>
  );
};

export default StudentPaymentPage;
