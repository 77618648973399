import { useEffect, useState } from "react";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { StudentActivityLog } from "../../../../types/student/studentActivityLog";
import StudyHistoryListItem from "../../StudyHistory/ListItem";
import { AiOutlineArrowDown } from "react-icons/ai";
import { FilterType } from "../../../../types/student/report";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

const ReportActivityList = () => {
  const { studentTheme } = useStudentThemeContext();
  const [userReportActivityList, setUserReportActivityList] = useState<
    StudentActivityLog[]
  >([]);
  const [filter, setFilter] = useState<FilterType>({
    limit: 3,
    page: 1,
  });
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchUserHistory = async () => {
      await request.get(
        `${API.STUDENT_ACTIVITY_LOG.GET_STUDENT_ACTIVITY_LOG}?${paramizeObject(
          filter
        )}`,
        (studentActivityLog) => {
          setUserReportActivityList([
            ...userReportActivityList,
            ...(studentActivityLog?.student_activity_logs || []),
          ]);
          setTotalPage(studentActivityLog?.total_page || 1);
        }
      );
    };

    fetchUserHistory();
  }, [filter]);

  const changePaginate = () => {
    const targetPage = currentPage + 1;
    if (targetPage > totalPage) return;

    setCurrentPage(targetPage);
    setFilter({ ...filter, page: targetPage });
  };

  return (
    <section
      style={{ color: studentTheme.text_color }}
      className="max-sm:border max-sm:border-danger-light max-sm:rounded-[14px] max-sm:px-[5px] max-sm:mt-[15px] max-sm:pb-[15px]"
    >
      <div className="text-[14px] font-[700] tracking-[0.287px] pt-[33px] mb-[22px] max-sm:pt-[10px]">
        学習履歴
      </div>

      <div className={`w-full mb-[40px]`}>
        {Array.isArray(userReportActivityList) &&
          userReportActivityList &&
          userReportActivityList.map((unit, index) => (
            <StudyHistoryListItem key={index} data={unit} />
          ))}
      </div>

      <div className="">
        <div className="w-full flex justify-between gap-[10.77px] items-center">
          <div className="w-full h-[1px] bg-danger-light"></div>
          <div
            className={`w-full max-w-[131px] h-0 min-h-[32px] justify-center flex items-center gap-[5px] bg-warning-lighter rounded-[16px] text-[10.8px] tracking-[0.215px]
            ${
              currentPage === totalPage
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }
            `}
            onClick={changePaginate}
          >
            <div className="">履歴をもっとみる</div>
            <AiOutlineArrowDown size={15} />
          </div>
          <div className="w-full h-[1px] bg-danger-light"></div>
        </div>
      </div>
    </section>
  );
};

export default ReportActivityList;
