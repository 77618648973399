import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import "../../../../assests/styles/scheduleCalendar.css";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import dayjs from "dayjs";
import ja from "date-fns/locale/ja";
import { ElementProps } from "../../../../types/globals";
import { ShowPickerStatus } from "./EventForm";
import { useStorage } from "../../../../hooks/useStorage";
registerLocale("ja", ja);

type HighlightDates = {
  [className: string]: Date[];
};

type CalendarDatePickerProps = {
  className?: string;
  initialDate?: Date | string;
  inputName?: string;
  highlightDates?: Array<HighlightDates | Date>;
  focusable?: boolean;
  onRefHandle?: (ref: React.RefObject<HTMLInputElement>) => void;
  setShowStatuses?: React.Dispatch<React.SetStateAction<ShowPickerStatus[]>>;
} & ElementProps<HTMLDivElement>;

const CalendarDatePickerEvent = ({
  className,
  initialDate,
  inputName,
  onRefHandle,
  focusable,
  highlightDates,
  setShowStatuses,
  ...props
}: CalendarDatePickerProps) => {
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  const [date, setDate] = useState<Date | null>(
    new Date(initialDate || new Date())
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    return onRefHandle?.(inputRef);
  }, [date]);

  useEffect(() => {
    divRef.current?.focus();
  }, []);

  if (!dayjs(date).isValid()) return <></>;

  return (
    <div
      id="date_picker"
      className={className}
      {...props}
      ref={divRef}
      tabIndex={0}
      onBlur={() => setTimeout(() => setShowStatuses?.([]), 300)}
      onClick={() => divRef.current?.focus()}
    >
      <div className="flex gap-[21.06px] text-secondary items-center mb-[15px]">
        <TbChevronLeft
          onClick={() => setDate(dayjs(date).subtract(1, "M").toDate())}
          className="cursor-pointer"
        />
        <div
          style={{ color: studentTheme.text_color }}
          className="font-[600] text-[14px] leading-[100%] text-secondary-dark"
        >
          {dayjs(date).format("YYYY年M月")}
        </div>
        <TbChevronRight
          onClick={() => setDate(dayjs(date).add(1, "M").toDate())}
          className="cursor-pointer"
        />
      </div>
      <div className="mt-[-5px] mb-[15px]">
        <DatePicker
          renderCustomHeader={() => <></>}
          calendarClassName="!border-none !w-[210px] !h-[200px] !bg-white"
          selected={date}
          onChange={setDate}
          highlightDates={highlightDates}
          inline
          calendarStartDay={1}
          locale={"ja"}
          fixedHeight
          disabled
        />
      </div>
      <div className=""></div>
      <input
        type="hidden"
        name={inputName}
        id=""
        value={dayjs(date).format()}
        ref={inputRef}
      />
    </div>
  );
};

export default CalendarDatePickerEvent;
