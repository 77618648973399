import Calendar from '../../../../components/student/StudyTool/Schedule/Calendar';
import SchedulePageBase from '.';

const CalendarPage = () => {
  return (
    <SchedulePageBase>
      <Calendar />
    </SchedulePageBase>
  );
};

export default CalendarPage;
