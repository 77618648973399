import React from "react";
import { LEARNING_TYPE } from "../../../services/constants/student/learning";
import { Learning } from "../../../types/student/learning";
import LearningRead from "../../commons/icons/Learning/Read";
import LearningWrite from "../../commons/icons/Learning/Write";
import { BsCheck2 } from "react-icons/bs";
import { UNIT_STATUS } from "../../../services/constants/student/unit";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";

const LearningType = ({
  type,
  status,
}: {
  type?: Learning["type"];
  status?: number;
}) => {
  const { studentTheme } = useStudentThemeContext();
  const getTypeBasedOnStatus = (type?: Learning["type"], status?: number) => {
    if (status === UNIT_STATUS.UNIT_COMPLETED_STATUS) {
      if (type === LEARNING_TYPE.WRITE) {
        return LEARNING_TYPE.WRITE_DONE;
      } else if (type === LEARNING_TYPE.READ) {
        return LEARNING_TYPE.READ_DONE;
      }
    }
    return type;
  };

  switch (getTypeBasedOnStatus(type, status)) {
    case LEARNING_TYPE.WRITE_DONE:
      return (
        <div
          style={{
            color: studentTheme?.main_color_second,
            borderColor: studentTheme?.main_color_second,
          }}
          className="w-[24px] h-[24px] rounded-full border-[2px] border-[#FAC46E] flex items-center justify-center text-[#FAC46E]"
        >
          <BsCheck2 size={20} />
        </div>
      );
    case LEARNING_TYPE.READ_DONE:
      return (
        <div
          style={{
            color: studentTheme?.sub_color_first,
            borderColor: studentTheme?.sub_color_first,
          }}
          className="w-[24px] h-[24px] rounded-full border-[2px] border-primary-light flex items-center justify-center text-primary-light"
        >
          <BsCheck2 size={20} />
        </div>
      );
    case LEARNING_TYPE.WRITE:
      return (
        <div
          style={{ backgroundColor: studentTheme.main_color_second }}
          className="w-[24px] h-[24px] rounded-full bg-warning flex items-center justify-center"
        >
          <LearningWrite />
        </div>
      );

    default:
      return (
        <div
          style={{ backgroundColor: studentTheme.main_color_first }}
          className="w-[24px] h-[24px] rounded-full bg-primary flex items-center justify-center"
        >
          <LearningRead />
        </div>
      );
  }
};

export default LearningType;
