import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalError, swalSuccess } from "../services/helpers/swal";
import { ROUTE } from "../services/constants/route/router";
import { useStorage } from "../hooks/useStorage";
import { ROLE_MANABITE } from "../services/constants/globals";
import { STUDENT_THEME_DEFAULT } from "../services/constants/admin/pages/student_theme";

const Logout = () => {
  const navigate = useNavigate();
  const { clearLocalStorage, setLocalStorage } = useStorage();

  useEffect(() => {
    const handleLogout = async () => {
      let url = `http://${process.env.REACT_APP_MAIN_DOMAIN}`;
      const userLocal = localStorage.getItem('user');
      const subdomain = window.location.hostname.split(".")[0];
      // setLocalStorage for login setting
      const loginSettingResult = await makeRequest({
        method: "get",
        url: `${API.LOGIN_SETTING.GET_DETAIL}?sub_domain=${subdomain}`,
      });
      const loginSettingData = loginSettingResult.data;
      if (loginSettingData) {
        setLocalStorage("login_setting", loginSettingData);
      }
      // setLocalStorage for studentTheme
      const themeResult = await makeRequest({
        method: "get",
        url: `${API.STUDENT_THEME_SETTING.GET_DETAIL}?sub_domain=${subdomain}`,
      });
      const studentTheme = !themeResult.data ? STUDENT_THEME_DEFAULT : themeResult.data;
      setLocalStorage("theme", studentTheme);

      let loginSetting = localStorage.getItem('login_setting');
      let theme = localStorage.getItem('theme');

      if (userLocal === null) {
        clearLocalStorage();
        if(loginSetting) localStorage.setItem('login_setting', loginSetting)
        if(theme) localStorage.setItem('theme', theme)
        return navigate(ROUTE.LOGIN);
      }

      const user = JSON.parse(userLocal || '');
      if (user.role_id && Number(user?.role_id) !== ROLE_MANABITE.ROLE_STUDENT) {
        url = ROUTE.LOGIN;
      }
      if (user.user_type && Number(user?.user_type) !== ROLE_MANABITE.ROLE_STUDENT) {
        url = ROUTE.LOGIN;
      }

      let result = await makeRequest({
        method: "post",
        url: API.AUTH.LOGOUT,
      });
      if (!result.status) {
        navigate(ROUTE.MY_PAGE.DEFAULT);
        return swalError();
      }

      clearLocalStorage();
      if(loginSetting) localStorage.setItem('login_setting', loginSetting)
      if(theme) localStorage.setItem('theme', theme)
      swalSuccess();

      window.location.href = url;
    };

    handleLogout();
  }, [navigate]);

  return <div></div>;
};

export default Logout;
