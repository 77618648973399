import { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import {
  swalClose,
  swalError,
  swalLoading,
} from "../../../../services/helpers/swal";
import { EventContentArg } from "@fullcalendar/core";
import jaLocale from "@fullcalendar/core/locales/ja";
import ScheduleEventType from "../../Schedule/EventType";
import { ROUTE } from "../../../../services/constants/route/router";
import { ScheduleEventCalendar } from "../../../../types/student/event";
import { getCommonDate } from "../../../../services/helpers/date";
import CalendarHeader from "./CalendarHeader";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";
import { STUDENT_THEME_COLOR_DEFAULT } from "../../../../services/constants/admin/pages/student_theme";
import { isEmptyObj } from "../../../../services/helpers/etc";

type DateTimeType = {
  start_date: string;
  end_date: string;
};

const ScheduleCalendar = () => {
  const calendarRef = useRef(null);
  const { studentTheme } = useStudentThemeContext();
  const [schedules, setSchedules] = useState<object[]>([]);
  const [dateTime, setDateTime] = useState<DateTimeType>({
    start_date: "",
    end_date: "",
  });
  const scheduleEventsRest = (totalEventsRest: number, date: string) => {
    return {
      title: `他${totalEventsRest}件`,
      backgroundColor: "transparent",
      textColor: "#7A7A7A",
      start: date,
      end: date + " 23:59:00",
    };
  };

  const filterScheduleEvents = (scheduleEvents: any) => {
    const uniqueDates: string[] = [];
    const filteredScheduleEvents: object[] = [];

    for (const event of scheduleEvents) {
      const date = event.start;
      const scheduleEventsOnDate = scheduleEvents.filter(
        (event: any) => event.start == date
      );

      if (!uniqueDates.includes(date)) {
        uniqueDates.push(date);
        filteredScheduleEvents.push(event);

        if (scheduleEventsOnDate.length > 2) {
          const totalEventsRest = scheduleEventsOnDate.length - 1;
          filteredScheduleEvents.push(
            scheduleEventsRest(totalEventsRest, date)
          );
        } else if (scheduleEventsOnDate[1] !== undefined) {
          filteredScheduleEvents.push(scheduleEventsOnDate[1]);
        }
      }
    }

    return filteredScheduleEvents;
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    const isEventOther = eventContent.backgroundColor === "#FAF7F0";
    return (
      <>
        <div
          className={`flex items-center gap-[5px] h-[18px] cursor-pointer rounded-[5px] ${
            isEventOther && "border border-danger-light"
          }`}
        >
          {eventContent.event.extendedProps.icon && (
            <span className="w-[14px] pl-[5px]">
              {eventContent.event.extendedProps.icon}
            </span>
          )}
          <span
            className={`max-w-[80px] pl-[5px] pb-[2px] truncate font-[500] ${
              isEventOther && "text-secondary-dark"
            }`}
          >
            {eventContent.event.title}
          </span>
        </div>
      </>
    );
  };

  const renderDateContent = (arg: any) => {
    const today = getCommonDate(new Date());

    return (
      <div
        className={`text-[12px] font-[600] z-10 ${
          today === getCommonDate(arg.date) ? "text-white" : "text-secondary"
        }`}
      >
        {arg.date.getDate()}
      </div>
    );
  };

  useEffect(() => {
    if (!isEmptyObj(studentTheme)) {
      const fetch = async () => {
        const paramsUrl = {
          start_date: dateTime?.start_date,
          end_date: dateTime?.end_date,
        };

        const result = await makeRequest({
          method: "get",
          url: `${API.SCHEDULE.LIST_SCHEDULE_CALENDAR}?${paramizeObject(
            paramsUrl
          )}`,
        });

        if (!result.data) swalError();

        const events = filterScheduleEvents(result?.data);
        setSchedules(
          events.map((event: ScheduleEventCalendar) => {
            const eventBase = {
              icon: event.icon && (
                <ScheduleEventType name={event.icon} size={14} />
              ),
              url: `${ROUTE.STUDY_TOOL.SCHEDULE.DEFAULT}/${ROUTE.STUDY_TOOL.SCHEDULE.EVENT.DEFAULT}?date=${event.start}&state=edit&event_id=${event.id}`,
              borderColor: "!#EBE4D8",
              backgroundColor:
                event.backgroundColor === "#227C9D"
                  ? studentTheme.main_color_first
                  : event.backgroundColor,
            };

            return {
              ...event,
              ...eventBase,
            };
          })
        );
      };

      fetch();
    }
  }, [dateTime, studentTheme]);

  useEffect(() => {
    const styles = `
    .fc-day-today.fc-daygrid-day .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
      border: none;
      background-color: ${
        studentTheme.main_color_second || STUDENT_THEME_COLOR_DEFAULT.MAIN_2
      } !important;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    }
    `;

    var styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }, [studentTheme]);

  const calendarOptions = {
    ref: calendarRef,
    plugins: [dayGridPlugin],
    initialView: "dayGridMonth",
    locale: jaLocale,
    firstDay: 1,
    events: schedules,
    eventContent: renderEventContent,
    dayCellContent: renderDateContent,
    datesSet: (args: any) => {
      setDateTime({
        start_date: getCommonDate(args.startStr),
        end_date: getCommonDate(args.endStr),
      });
    },
  };

  return (
    <div className="px-[20px]">
      <CalendarHeader calendarRef={calendarRef} />
      <div
        id="calendar"
        className="w-full min-h-[700px] pt-[24px] text-[12px] text-center "
      >
        <FullCalendar {...calendarOptions} height={"602px"} />
      </div>
    </div>
  );
};

export default ScheduleCalendar;
