import SectionList from '../../../components/admin/Section/List/List';
import SectionListProvider from '../../../context/Section/List';
import SectionBase from '.';

const SectionListPage = () => {
  return (
    <SectionBase title='セクション管理 ｜ セクション一覧'>
      <SectionListProvider>
        <SectionList />
      </SectionListProvider>
    </SectionBase>
  );
};

export default SectionListPage;
