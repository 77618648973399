import { FC, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { checkIsSubdomain } from "../services/helpers/domain";
import { LOGIN_SETTING } from "../services/constants/loginSetting";
import { useLoginSettingContext } from "../context/LoginSettingContext";
import classNames from "classnames";
import logo_login from "../assests/images/logo2_LMS.webp";
import YellowBlock from "../components/Login/YellowBlock";
import BeigeBlock from "../components/Login/BeigeBlock";
import OrangeBlock from "../components/Login/OrangeBlock";
import BlueBlock from "../components/Login/BlueBlock";
import GreenBlock from "../components/Login/GreenBlock";
import PurpleBlock from "../components/Login/PurpleBlock";
import LayoutWaitingApi from "../components/student/common/LayoutWaitingApi";
import { BACKGROUND_LOGIN_TYPE } from "../services/constants/admin/pages/loginSetting";

interface LayoutProps {
  children?: JSX.Element;
  disableTokenCheck?: boolean;
}

const AuthLayout: FC<LayoutProps> = (props) => {
  const navigate = useNavigate();
  let accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const [token, setToken] = useState(accessToken);
  const [style, setStyle] = useState<Object>({
    backgroundColor: "#FFFFFF",
  });
  const isSubdomain = checkIsSubdomain();
  const { loginSetting, fetching } = useLoginSettingContext();

  const updateLoginSetting = () => {
    if (localStorage.getItem("login_setting")) {
      localStorage.removeItem("login_setting");
    }
  };
  updateLoginSetting();

  useEffect(() => {
    if (
      loginSetting.background_login_type ===
      LOGIN_SETTING.BACKGROUND_LOGIN_TYPE.COLOR
    ) {
      setStyle({ backgroundColor: loginSetting.background_login_color });
    }

    if (
      loginSetting.background_image_login_path_url &&
      loginSetting.background_login_type ===
        LOGIN_SETTING.BACKGROUND_LOGIN_TYPE.IMAGE
    ) {
      setStyle({
        backgroundImage: `url(${loginSetting.background_image_login_path_url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      });
    }
  }, [loginSetting]);

  if (!isSubdomain) {
    return <Navigate to={ROUTE.HOME} replace={true} />;
  }

  return (
    <>
      {token && !props.disableTokenCheck ? (
        <Navigate to={ROUTE.MY_PAGE.DEFAULT} replace={true} />
      ) : (
        <div
          style={style}
          className={`w-full min-h-screen text-center relative flex flex-col`}
        >
          <div
            className={`mt-[70px] mx-auto max-sm:mt-[35px] max-sm:!w-[200px] max-sm:!h-[150px] w-auto max-h-[170px] overflow-hidden flex justify-center`}
          >
            <LayoutWaitingApi
              waitingApi={fetching}
              className="w-[170px] h-[170px] ml-[10px]"
            />
            <div onClick={() => navigate(`${ROUTE.LOGIN}`)}>
              <img
                className={classNames(
                  "min-w-[170px] h-full max-h-[170px] animate-[show_0.5s_ease-in-out] hidden overflow-hidden object-contain relative z-[20]",
                  {
                    "!block": !fetching,
                  }
                )}
                src={loginSetting.logo_login_path_url || logo_login}
                alt="Logo login"
              />
            </div>
          </div>
          <div className="z-[1000] flex flex-col">{props.children}</div>
          {(loginSetting.background_image_login_path_url &&
            loginSetting.background_login_type ==
              BACKGROUND_LOGIN_TYPE.PICTURE) ||
          (loginSetting.background_login_color &&
            loginSetting.background_login_type ===
              BACKGROUND_LOGIN_TYPE.COLOR) ? (
            <></>
          ) : (
            <>
              <div className="fixed top-0 left-0 z-10">
                <YellowBlock />
              </div>
              <div className="fixed top-[91px] left-[146px]">
                <BeigeBlock />
              </div>
              <div className="fixed bottom-0 left-0">
                <OrangeBlock />
              </div>
              <div className="fixed bottom-[-13px] right-[133px]">
                <BlueBlock />
              </div>
              <div className="fixed bottom-[38%] right-0">
                <GreenBlock />
              </div>
              <div className="fixed bottom-[27%] right-0">
                <PurpleBlock />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AuthLayout;
