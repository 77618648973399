import TableTitle from '../../commons/TableTitle';
import Table from './Table';
import TableAction from './TableAction';

const CourseList = () => {
  return (
    <>
      <div className="">
        <TableAction />
      </div>

      <TableTitle subContent="選択中のコース" content="すべての学習コース" />
      <Table />
    </>
  );
};

export default CourseList;
