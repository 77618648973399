import React, { useState } from "react";
import CourseType from "./Type";
import { RiArrowRightSLine } from "react-icons/ri";
import { Course } from "../../../types/student/course";
import { useNavigate } from "react-router-dom";
import { PATH_COURSE } from "../../../services/constants/route/router";
import dayjs from "dayjs";
import { getPercent } from "../../../services/helpers/parseData";
import { useStudentThemeContext } from "../../../context/StudentThemeContext";
const CourseDetailItems = ({ course }: { course: Course }) => {
  const navigate = useNavigate();
  const handleRedirectToCourseTop = () =>
    navigate(`${PATH_COURSE.DEFAULT}?course_id=${course.id}`);
  const formatDateTime = (inputDate?: string) => {
    if (!inputDate) return "";
    return dayjs(inputDate).format("YYYY年MM月DD日");
  };
  const { studentTheme } = useStudentThemeContext();

  return (
    <div
      className={`flex items-center justify-between pl-[8.5px] min-h-[60px] bg-white border-b border-danger-light relative mb-[14px] max-lg:p-[10px]`}
    >
      <div className="flex items-center ">
        <div className="min-w-[20px]">
          <CourseType status={course.status} />
        </div>
        <div className="flex items-center justify-start max-sm:flex-col max-sm:items-start max-sm:gap-y-[3px]">
          <div className="min-w-[190px] ml-[8px]">
            <div
              style={{
                color: studentTheme.main_color_first,
              }}
              className="text-[16px] font-[500] text-primary max-w-[200px] truncate cursor-pointer"
              onClick={handleRedirectToCourseTop}
            >
              {course.name}
            </div>
            <div className="text-secondary-light text-[10px] font-[500] flex gap-[15px]">
              <p>
                受講開始 {formatDateTime(course.start_date)}
                {course.start_date ? "~" : ""}
                {formatDateTime(course.completed_date)}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-start w-full max-w-[170px] sm:hidden ml-[8px]">
            <div className="text-secondary font-[500] text-[10px] whitespace-nowrap mr-[3.5px]">
              修了講座
            </div>
            <div
              style={{
                color: studentTheme.main_color_first,
              }}
              className="text-[16.8px] font-[700] with_roboto leading-[100%] text-primary mr-[10px]"
            >
              {course.total_lecture_completed || 0}
              <span className="text-[10px] font-[700] leading-[100%] with_roboto text-secondary">
                /{course.total_lecture || 0}
              </span>
            </div>
            <div className="w-full max-w-[90px]">
              <div className="w-full rounded-full h-[8px] bg-success-extralight">
                <div
                  className="bg-warning h-[8px] rounded-full"
                  style={{
                    width: `${getPercent(
                      course.total_lecture_completed,
                      course.total_lecture
                    )}%`,
                    backgroundColor: studentTheme.main_color_second,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center w-full max-w-[170px] max-sm:hidden">
        <div className="text-secondary font-[500] text-[10px] whitespace-nowrap mr-[3.5px]">
          修了講座
        </div>
        <div
          style={{
            color: studentTheme.main_color_first,
          }}
          className="text-[16.8px] font-[700] with_roboto leading-[100%] text-primary mr-[10px]"
        >
          {course.total_lecture_completed || 0}
          <span className="text-[10px] font-[700] leading-[100%] with_roboto text-secondary">
            /{course.total_lecture || 0}
          </span>
        </div>
        <div className="w-full max-w-[90px]">
          <div className="w-full rounded-full h-[8px] bg-success-extralight">
            <div
              className="bg-warning h-[8px] rounded-full"
              style={{
                width: `${getPercent(
                  course.total_lecture_completed,
                  course.total_lecture
                )}%`,
                backgroundColor: studentTheme.main_color_second,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="flex max-w-[214px] justify-end max-lg:flex-col max-lg:justify-start max-lg:gap-y-[5px]">
        {course.unit_id && (
          <div
            className="text-[10px] font-[500] bg-white leading-[100%] text-danger border border-danger flex items-center justify-center w-[50px] min-w-[96px] h-[20px] mr-[9px] cursor-pointer hover:bg-[#00000026]"
            onClick={() =>
              course.unit_id &&
              navigate(
                `${PATH_COURSE.UNIT_DETAIL}?unit_id=${course.unit_id}&course_id=${course.id}`
              )
            }
          >
            学習中の単元へ <RiArrowRightSLine className="mr-[-2px]" size={15} />
          </div>
        )}
        <div
          className="text-[10px] font-[500] bg-white leading-[100%] text-secondary border border-secondary flex items-center justify-center w-[50px] min-w-[96px] h-[20px] mr-[16px] cursor-pointer hover:bg-[#00000026]"
          onClick={handleRedirectToCourseTop}
        >
          コーストップ <RiArrowRightSLine className="mr-[-2px]" size={15} />
        </div>
      </div>
    </div>
  );
};

export default CourseDetailItems;
