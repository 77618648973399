import React from "react";
import { UNIT_STATUS } from "../../../../services/constants/admin/pages/material";

type StatusButtonDisplayProps = {
  status?: number;
};

const StatusButtonDisplay = ({ status }: StatusButtonDisplayProps) => {
  switch (status) {
    case UNIT_STATUS.PUBLIC:
      return (
        <div className="px-[6px] pt-[3px] pb-[5px] text-danger border border-danger rounded-[5px] cursor-default">
          公開
        </div>
      );
    case UNIT_STATUS.PRIVATE:
      return (
        <div className="px-[6px] pt-[3px] pb-[5px] text-white bg-secondary-light rounded-[5px] cursor-default">
          非公開
        </div>
      );
    case UNIT_STATUS.DRAFT:
      return (
        <div className="px-[6px] pt-[3px] pb-[5px] text-primary border border-primary rounded-[5px] cursor-default">
          編集中
        </div>
      );
    default:
      return (
        <div></div>
      )
  }
};

export default StatusButtonDisplay;
