/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { request } from '../../../../services/axios/axios';
import { FilterType } from '../../../../types/student/report';
import { paramizeObject } from '../../../../services/helpers/parseData';
import { API } from '../../../../services/constants/route/api';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { ReviseHistory } from '../../../../types/student/studyToolRevise';
import ReviseHistoryItem from './ReviseHistoryItem';
import { isUsableArr } from '../../../../services/helpers/etc';
import { MESSAGE_SPECIFIED } from '../../../../services/constants/message';

const ReviseHistoryList = () => {
  const [studentReviseHistoryList, setStudentReviseHistoryList] = useState<ReviseHistory[]>([]);
  const [filter, setFilter] = useState<FilterType>({
    limit: 3,
    page: 1,
  });
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchUserHistory = async () => {
      await request.get(
        `${API.REVISION_REVIEW.GET_REVISE_HISTORY_LIST}?${paramizeObject(filter)}`,
        (studentReviseHistory) => {
          setStudentReviseHistoryList([
            ...studentReviseHistoryList,
            ...(studentReviseHistory?.student_revise_histories || []),
          ]);
          setTotalPage(studentReviseHistory?.total_page || 1);
        },
      );
    };

    fetchUserHistory();
  }, [filter]);

  const changePaginate = () => {
    const targetPage = currentPage + 1;
    if (targetPage > totalPage) return;

    setCurrentPage(targetPage);
    setFilter({ ...filter, page: targetPage });
  };

  return (
    <section>
      {isUsableArr(studentReviseHistoryList) ? (
        <div>
          <div className="text-[14px] font-[700] tracking-[0.287px] pt-[33px] mb-[22px]">
            学習履歴
          </div>

          <div className={`w-full mb-[40px]`}>
            {Array.isArray(studentReviseHistoryList) &&
              studentReviseHistoryList &&
              studentReviseHistoryList.map((unit, index) => (
                <ReviseHistoryItem key={index} data={unit} />
              ))}
          </div>

          <div className="" title={`${currentPage === totalPage ? 'No more record' : 'Load more'}`}>
            <div className="w-full flex justify-between gap-[10.77px] items-center">
              <div className="w-full h-[1px] bg-danger-light"></div>
              <div
                className={`w-full max-w-[131px] h-0 min-h-[32px] justify-center flex items-center gap-[5px] bg-warning-lighter rounded-[16px] text-[10.8px] tracking-[0.215px]
            ${currentPage === totalPage ? 'cursor-not-allowed' : 'cursor-pointer'}
            `}
                onClick={changePaginate}
              >
                <div className="">履歴をもっとみる</div>
                <AiOutlineArrowDown size={15} />
              </div>
              <div className="w-full h-[1px] bg-danger-light"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-secondary mt-[10px]">{MESSAGE_SPECIFIED.HISTORY_REVISE_TEST.NO_DATA_FOUND}</div>
      )}
    </section>
  );
};

export default ReviseHistoryList;
