import React, { useEffect, useRef, useState } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import { BsFillPauseFill } from "react-icons/bs";
import { SetStateAction } from "../../../types/globals";
import { useStorage } from "../../../hooks/useStorage";
import StudentThemeItem from "../../admin/TermOfUse/StudentTheme/StudentThemeComponent.tsx/StudentThemeItem";

const AudioPlayer = ({
  src,
  onLoad,
  setIsPlayed,
  readOnly = false,
  className,
  setResourceProgressTime,
  ...props
}: {
  src?: string;
  onLoad?: () => void;
  setIsPlayed?: SetStateAction<boolean>;
  readOnly?: boolean;
  setResourceProgressTime?: React.Dispatch<React.SetStateAction<number>>;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const sliderRef = useRef<HTMLInputElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current) {
      const time = parseFloat(e.target.value);
      audioRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };
  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (sliderRef.current) {
      const progressBarRect = sliderRef.current.getBoundingClientRect();
      const progressBarWidth = progressBarRect.width;
      const clickX = e.clientX - progressBarRect.left;
      const time = (clickX / progressBarWidth) * duration;
      if (audioRef.current) audioRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };
  useEffect(() => {
    // Update the current time every second
    const interval = setInterval(() => {
      setCurrentTime(audioRef.current?.currentTime || 0);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setResourceProgressTime?.(currentTime * 1000);
  }, [currentTime]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes < 10 ? "0" + minutes : minutes} : ${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  return (
    <div
      className={`flex items-center w-full justify-center ${className || ""}`}
      onContextMenu={handleContextMenu}
      {...props}
    >
      <div className="mr-4 bg-white w-11 h-11 rounded-full flex justify-center items-center shadow-lg">
        <div
          className="bg-secondary-light w-10 h-10 rounded-full cursor-pointer flex items-center justify-center text-white"
          onClick={togglePlay}
        >
          {isPlaying ? (
            <BsFillPauseFill size={30} />
          ) : (
            <BsFillPlayFill size={30} />
          )}
        </div>
      </div>
      <div
        className="relative h-[8px] w-[350px] bg-gray-300 rounded-full"
        onClick={handleProgressBarClick}
      >
        <input
          ref={sliderRef}
          type="range"
          className="absolute w-full h-[8px] appearance-none rounded-full"
          value={currentTime}
          max={duration}
          onChange={handleSeek}
          style={{ background: "transparent", outline: "none" }}
          readOnly={readOnly}
        />
        <div
          className="absolute top-0 left-0 h-[8px] bg-primary-light rounded-full"
          style={{
            width: `${(currentTime / duration) * 100}%`,
            backgroundColor: studentTheme.sub_color_first,
          }}
        />
        <div
          className="absolute top-1 transform -translate-x-1/2 -translate-y-1/2 bg-white w-5 h-5 rounded-full z-20 pointer-events-none shadow-lg"
          style={{
            left: `${(currentTime / duration) * 100}%`,
          }}
        />
        <div
          className="absolute top-1 transform -translate-x-1/2 -translate-y-1/2 bg-primary-light w-4 h-4 rounded-full z-20 pointer-events-none"
          style={{
            left: `${(currentTime / duration) * 100}%`,
            backgroundColor: studentTheme.sub_color_first,
          }}
          onMouseDown={() => {
            if (sliderRef.current) {
              sliderRef.current.blur();
            }
          }}
          onMouseUp={() => {
            if (sliderRef.current) {
              sliderRef.current.focus();
            }
          }}
        />
        <div className="flex justify-end mt-[12px] pr-[1px]">
          <p className="font-[500] text-[12px] tracking-[2%] text-secondary">
            {formatTime(currentTime)}
          </p>
        </div>
      </div>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)}
        onLoadedMetadata={() => {
          setDuration(audioRef.current?.duration || 0);
        }}
        onCanPlayThrough={onLoad}
        onPlay={() => setIsPlayed?.(true)}
      />
    </div>
  );
};

export default AudioPlayer;
