/* eslint-disable react-hooks/exhaustive-deps */
import { useStudentUnitContext } from "../../../../context/StudentUnitContext";
import { TestStatistic } from "../../../../types/student/unit";
import CircularProgress from "../../../commons/charts/CircularProgress";
import SmallButton from "../../common/SmallButton";
import dayjs from "dayjs";
import { MODE_EXECUTE_TEST_DATA } from "../../../../services/constants/student/unit";
import { formatTotalTestTime } from "../../../../services/helpers/formatTime";
import {
  getPercent,
  parsePercent,
} from "../../../../services/helpers/parseData";
import awardIcon from "../../../../assests/images/icons/award.svg";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type TestStatisticProps = {
  data?: TestStatistic;
  noHistoryData?: boolean;
};

const TestStatistics = ({ data, noHistoryData }: TestStatisticProps) => {
  const { showSettings, setShowSettings } = useStudentUnitContext();
  const { studentTheme } = useStudentThemeContext();

  return (
    <div className="w-full border border-danger-light rounded-[5px] mb-[15px] py-2 px-2 animate-[show_0.5s_ease-in-out]">
      <div className="w-full bg-warning-lighter pt-3 pb-1 px-3 rounded-[5px]">
        <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
          テスト結果
        </div>
        <div className="flex gap-[30px] items-center mb-[22px]">
          <div className="w-full max-w-[200px] h-[200px] relative">
            <div className="absolute top-0 left-0 w-full h-[200px] rounded-full bg-white flex items-center justify-center">
              <div className="text-center">
                <div className="text-[14px] font-[700] leading-[20px] tracking-[0.02em] text-secondary mb-[6.86px]">
                  最高得点
                </div>
                <div
                  style={{
                    color: studentTheme.main_color_first,
                  }}
                  className={`text-[12px] font-[500] leading-[100%] text-primary mb-[6.5px]`}
                >
                  <span
                    style={{
                      color: studentTheme.main_color_first,
                    }}
                    className="text-[43px] font-[700] leading-[100%] with_roboto text-primary"
                  >
                    {data?.score || "-"}
                  </span>
                  <span className="text-[11px] font-[400] text-secondary-light leading-[100%] with_roboto">
                    点/{data?.total_score || "-"}
                  </span>
                </div>
                <div className="text-[12px] leading-[100%] tracking-[0.02em] text-secondary">
                  {data?.correct_question_quantity || 0}／
                  {data?.question_quantity || 0}問正解
                </div>
              </div>
            </div>
            <CircularProgress
              blue={true}
              sqSize={175}
              strokeWidth={17}
              percentage={getPercent(data?.score, data?.total_score)}
              className="absolute top-[12px] left-[12.5px]"
            />
          </div>
          <div className="flex flex-col justify center items-center">
            <p className="mb-[8px] text-[10px] font-[400] leading-[13px]">
              合格ライン {data?.passing_score || "-"}点
              <br />（{data?.total_score || "-"}点満点）
            </p>
            <SmallButton
              style={{
                backgroundColor: !!data?.is_pass ? studentTheme.main_color_second: studentTheme.sub_color_first
              }}
              className={`${
                !!data?.is_pass ? "bg-warning" : "bg-primary-light"
              } mb-[16.5px]`}
            >
              {noHistoryData ? (
                <span className="whitespace-nowrap mx-[8px]">ー 点</span>
              ) : !!data?.is_pass ? (
                <>
                  <img src={awardIcon} alt="award" /> 合格
                </>
              ) : (
                <>あと{data?.score_needed_to_pass || 0}点</>
              )}
            </SmallButton>
            {!noHistoryData && (
              <p
                style={{
                  color: studentTheme.main_color_first,
                }}
                className="text-[13px] font-[500] text-primary leading-[13px] cursor-pointer"
                onClick={() =>
                  setShowSettings({
                    ...showSettings,
                    isReviewTestTable: !showSettings.isReviewTestTable,
                  })
                }
              >
                {!showSettings?.isReviewTestTable && "詳細結果をみる"}
              </p>
            )}
          </div>
          <div className="w-full max-w-[355px]">
            <div className="flex min-h-[22px] py-[5px]">
              <div className="font-[400] text-[11px] leading-[100%] w-full max-w-[95px] bg-danger-light flex items-center justify-end pr-[9px]">
                <div className="">実施日</div>
              </div>
              <div className="text-[12px] font-[400] bg-white w-full flex items-center  whitespace-nowrap pl-[21px]">
                {data?.created_at && data?.test_type
                  ? `${dayjs(data?.created_at).format("YYYY年M月D")}　｜　${
                      MODE_EXECUTE_TEST_DATA[data?.test_type || 0]
                    }`
                  : "-"}
              </div>
            </div>
            <div className="flex min-h-[22px] py-[5px]">
              <div className="font-[400] text-[11px] leading-[100%] w-full max-w-[95px] bg-danger-light flex items-center justify-end pr-[9px]">
                <div className="">正答</div>
              </div>
              <div className="text-[12px] font-[400] bg-white w-full flex items-center  whitespace-nowrap pl-[21px]">
                {data?.correct_question_quantity !== undefined &&
                data?.question_quantity !== undefined &&
                data?.percent_correct !== undefined ? (
                  <>
                    {data?.correct_question_quantity}／{data?.question_quantity}
                    問正解（正答率　{data?.percent_correct || 0}% ）
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="flex min-h-[22px] py-[5px]">
              <div className="font-[400] text-[11px] leading-[100%] w-full max-w-[95px] bg-danger-light flex items-center justify-end pr-[9px]">
                <div className="">解答時間</div>
              </div>
              <div className="text-[12px] font-[500] bg-white w-full flex items-center whitespace-nowrap pl-[21px]">
                {formatTotalTestTime(data?.test_time)}
              </div>
            </div>
            <div className="mt-[13px] text-[12px] font-[500]">平均との比較</div>
            <div className="bg-white mt-[2px] text-[12px] font-[400] px-[17px] py-[15px]">
              <div className="flex gap-[12px] items-center justify-center mb-[10px]">
                <p>あなたの最高得点</p>
                <div className="w-full max-w-[210px]">
                  <div className="w-full rounded-full h-[8px] bg-success-extralight">
                    <div
                      className={`bg-warning h-[8px] rounded-full`}
                      style={{
                        width: `${getPercent(data?.score, data?.total_score)}%`,
                        backgroundColor: studentTheme.main_color_second,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="flex gap-[12px] items-start justify-center">
                <p>全受講者の平均点</p>
                <div className="w-full max-w-[210px] mt-[6px]">
                  <div className="w-full rounded-full h-[8px] bg-success-extralight mb-[8px]">
                    <div
                      className={`bg-primary-light h-[8px] rounded-full`}
                      style={{
                        width: `${parsePercent(data?.avg_percent_correct)}%`,
                        backgroundColor: studentTheme.sub_color_first,
                      }}
                    ></div>
                  </div>
                  <div className="text-[11px]">
                    {data?.avg_score || 0}点 ({data?.avg_correct_question || 0}/
                    {data?.avg_question || 0}問) 正答率{" "}
                    {data?.avg_percent_correct || 0}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestStatistics;
