import React from "react";
import ListItem from "./Item";
import { useEffect, useState } from "react";
import { TermOfServiceType } from "../../../../types/admin/term_of_use";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { PATH_ADMIN_TERMS_OF_USE } from "../../../../services/constants/route/router";
import { swalLoading } from "../../../../services/helpers/swal";

const ListTermOfService = () => {
  const [termsService, setTermsService] = useState<TermOfServiceType[]>([]);

  useEffect(() => {
    // swalLoading();
    
    const fetchTermsOfService = async () => {
      const result = await makeRequest({
        method: "get",
        url: API.ADMIN_TERM_SERVICE.LIST,
      });

      setTermsService(result.data);
    };

    fetchTermsOfService();
  }, []);

  return (
    <div className="">
      <div className="flex items-center text-[18px] font-[700] text-white w-full h-[50px] px-[21px] bg-primary rounded-[5px] mb-[15px]">
        一覧
      </div>
      <div className="w-full h-[30px] flex items-center text-[13px] text-secondary-dark font-[500] bg-success-lighter mb-[5px]">
        <div className="flex items-center w-[83.3%] h-[30px] px-[10px] border-r-[3px] border-white">
          ページタイトル
        </div>
        <div className="flex items-center justify-center w-[16.7%] h-[30px] px-[10px]">
          編集
        </div>
      </div>
      {termsService.map((termService) => (
        <ListItem
          title={termService.name || ""}
          key={termService.id}
          pathDetail={`${PATH_ADMIN_TERMS_OF_USE.TERMS_OF_SERVICE_DETAIL}?id=${termService.id}`}
        />
      ))}
    </div>
  );
};

export default ListTermOfService;
