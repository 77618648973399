import * as React from 'react';

const Cart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.25 15.75c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5Zm5-1.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5Zm4.48-9.57-2 8a.75.75 0 0 1-.73.57H5c-.36 0-.67-.26-.74-.62L2.37 2.25H1c-.41 0-.75-.34-.75-.75S.59.75 1 .75h2c.36 0 .67.26.74.62l.43 2.38H17a.754.754 0 0 1 .73.93Zm-1.69.57H4.44l1.18 6.5h8.79l1.63-6.5Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
  </svg>
);

export default Cart;
