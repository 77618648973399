import React from 'react';
import StudentLayout from '../../../../layouts/student';
import { WithChildren } from '../../../../types/globals';
import Title from '../../../../components/student/MyPage/Title';

const SchedulePageBase = ({ children }: WithChildren) => {
  return (
    <StudentLayout gap>
      <Title content="学習スケジュール" />
      {children}
    </StudentLayout>
  );
};

export default SchedulePageBase;
