import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { IoMdRemove } from 'react-icons/io';
import { BsPlusLg } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Course, CourseItemType } from '../../../../types/admin/course';
import { Lecture } from '../../../../types/admin/lecture';
import { Chapter } from '../../../../types/admin/chapter';
import { DIRECTION } from '../../../../services/constants/globals';
import {
  PATH_ADMIN_COURSE,
  PATH_ADMIN_MATERIAL,
} from '../../../../services/constants/route/router';
import { getCountLabel } from '../../../../services/utils/admin/course';
import { FiTrash2 } from 'react-icons/fi';
import { COURSE_TABLE_ITEM_TYPE } from '../../../../services/constants/admin/pages/course';
import { MemoriedExpandStatus } from '../../Course/List/Table';
import ActionButtonDisplay from './ActionButtonDisplay';
import { Direction } from '../../../../types/globals';
import StatusButtonDisplay from './StatusButtonDisplay';
import UnitIcon from '../../commons/UnitIcon';
import { Unit } from '../../../../types/admin/unit';
import { UNIT_TYPE } from '../../../../services/constants/admin/pages/material';

type CourseTableItemProps = {
  isUpDisabled?: boolean;
  isDownDisabled?: boolean;
  className?: string;
  data: Course | Lecture | Chapter | Unit;
  onOrder?: (
    id: number,
    direction: Direction,
    itemType: CourseItemType,
    parentId: number | null,
  ) => void;
  itemType: CourseItemType;
  expandData?: MemoriedExpandStatus;
  onDelete?: (id: number, itemType: CourseItemType, parentId: number | null) => void;
  onExpandFetch?: (id?: number, itemType?: CourseItemType, isExpand?: boolean) => void;
  parentId?: number;
};

const TableItem = ({
  isUpDisabled = false,
  isDownDisabled = false,
  data,
  itemType,
  expandData,
  onExpandFetch,
  onOrder,
  onDelete,
  parentId,
}: CourseTableItemProps) => {
  let widthStyleByType = 'w-[50%]';
  let marginStyleByType = '';
  let creationTarget = PATH_ADMIN_COURSE.LECTURE_REGISTRATION;

  switch (itemType) {
    case COURSE_TABLE_ITEM_TYPE.CHAPTER:
      marginStyleByType = 'ml-[20px] w_lecture';
      widthStyleByType = 'w_inner_lecture';
      creationTarget = PATH_ADMIN_MATERIAL.UNIT_UPSERT;
      break;
    case COURSE_TABLE_ITEM_TYPE.UNIT:
      marginStyleByType = 'ml-[23px] w_unit !mb-[5px]';
      widthStyleByType = 'w_inner_unit';
      break;
    default:
      break;
  }

  return (
    <div
      className={`${marginStyleByType} ${
        !parentId ? 'bg-secondary-morelighter' : 'bg-white'
      } min-h-[50px] border border-secondary-light rounded-[5px] flex items-center w-full text-[13px] font-[500] leading-[100%] mb-[4px]`}
    >
      <div className={`${widthStyleByType} flex items-center text-secondary`}>
        <div className="ml-[16px] mr-[16px] cursor-pointer">
          {itemType !== COURSE_TABLE_ITEM_TYPE.UNIT && (
            <>
              {expandData && expandData[`${itemType}_${data.id}`] ? (
                <IoMdRemove size={22} onClick={() => onExpandFetch?.(data.id, itemType, false)} />
              ) : (
                <BsPlusLg size={22} onClick={() => onExpandFetch?.(data.id, itemType, true)} />
              )}
            </>
          )}

          {itemType === COURSE_TABLE_ITEM_TYPE.UNIT && <UnitIcon type={(data as Unit).type || 0} />}
        </div>
        {data.name}
      </div>

      <div className="w-[5%] flex justify-center items-center text-[13px] font-[500] leading-[100%]">
        {itemType !== COURSE_TABLE_ITEM_TYPE.UNIT && (
          <>{`${(data as Lecture | Chapter).child_count || 0}${getCountLabel(itemType)}`}</>
        )}
      </div>

      <div className="flex w-[12%] justify-between items-center px-[1%]">
        <div className="w-[30px] h-[24px] appearance-none justify-center text-right p-[5px] border border-secondary-light rounded-[2px]">
          {data.order}
        </div>
        <AiOutlineArrowUp
          className={`items-center ${
            isUpDisabled
              ? 'text-secondary-extralight cursor-not-allowed'
              : 'text-secondary cursor-pointer'
          }`}
          size={22}
          onClick={() =>
            data.id && !isUpDisabled && onOrder?.(data.id, DIRECTION.UP, itemType, parentId || null)
          }
        />
        <AiOutlineArrowDown
          className={`items-center ${
            isDownDisabled
              ? 'text-secondary-extralight cursor-not-allowed'
              : 'text-secondary cursor-pointer'
          }`}
          size={22}
          onClick={() =>
            data.id &&
            !isDownDisabled &&
            onOrder?.(data.id, DIRECTION.DOWN, itemType, parentId || null)
          }
        />
      </div>
      <div className="w-[10%] flex justify-center items-center">
        <StatusButtonDisplay status={data.status} />
      </div>
      <div className="w-[5%] flex justify-center items-center text-secondary cursor-pointer">
        <FiTrash2
          size={20}
          onClick={() => data.id && onDelete?.(data.id, itemType, parentId || null)}
          className='hover:text-secondary-lesslight'
        />
      </div>

      <div className="w-[10%] flex justify-center items-center">
        <div className="w-full flex justify-end">
          {itemType === COURSE_TABLE_ITEM_TYPE.UNIT && (
            <Link
              to={`${PATH_ADMIN_MATERIAL.UNIT_UPSERT}?${itemType}_id=${data.id}`}
              className="w-full max-w-[45px]"
            >
              <button className="w-full px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-primary text-white text-[12px] font-[500] leading-[100%]">
                編集
              </button>
            </Link>
          )}
        </div>
      </div>

      <div
        className={`${
          itemType === COURSE_TABLE_ITEM_TYPE.UNIT ? 'w_inner_last_unit' : 'w-[20%]'
        } flex justify-start items-center ml-[30px]`}
      >
        <Link
          to={`${creationTarget}?with_${itemType}_id=${data.id}`}
          className={`flex justify-start items-center ${
            itemType === COURSE_TABLE_ITEM_TYPE.UNIT && 'pointer-events-none'
          }`}
        >
          <ActionButtonDisplay
            itemType={itemType}
            isTest={(data as Unit).type === UNIT_TYPE.TEST}
            time={(data as Unit).estimate_time_required}
          />
        </Link>
      </div>
    </div>
  );
};

export default TableItem;
