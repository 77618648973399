const COURSE_TYPE = {
  LEARNING: 0,
  DONE: 1,
};

const LECTURE_STATUS = {
  LECTURE_STATUS_UNLEARNED: 0,
  LECTURE_STATUS_COMPLETED: 1,
  LECTURE_STATUS_LEARNING: 2,
  UNPURCHASED_STATUS_LECTURE: 3,
  REPURCHASE_STATUS_LECTURE: 4,
  UNPUBLISHED_STATUS_LECTURE: 5
}
export { COURSE_TYPE, LECTURE_STATUS };