const PAYMENT_METHOD_TYPE = {
  CARD: {
    VALUE: "card",
    LABEL: "クレジットカード"
  },
  KONBINI: {
    VALUE: "konbini",
    LABEL: "コンビニ"
  },
  INVOICE: {
    VALUE: "invoice",
    LABEL: "請求書"
  }
}

export { PAYMENT_METHOD_TYPE }
