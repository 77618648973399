import React from 'react';
import RevisionReviewDashboard from '../../../../components/student/StudyTool/RevisionReview/RevisionReviewDashboard';
import RevisionReviewPageBase from '.';

const RevisionReviewPage = () => {
  return (
    <RevisionReviewPageBase>
      <RevisionReviewDashboard />
    </RevisionReviewPageBase>
  );
};

export default RevisionReviewPage;
