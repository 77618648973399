import React from "react";
import { Course } from "../../../types/student/course";
import CourseListItem from "./ListItem";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { PATH_PRODUCT, ROUTE } from "../../../services/constants/route/router";
import { useFeaturePurchasedContext } from "../../../context/FeaturePurchasedContext";

const CourseList = ({ data }: { data: Course[] }) => {
  const { featurePurchased } = useFeaturePurchasedContext();
  const navigate = useNavigate();
  return (
    <div
      className="w-full max-h-[252px] border border-danger-light rounded-[5px] mb-[15px] pt-[20px] px-[21px] pb-[27px]
                max-sm:border-none max-sm:bg-white max-sm:p-[16px] max-sm:mb-0 animate-[show_0.5s_ease-in-out]"
    >
      <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
        受講コース一覧
      </div>
      <div className={`max-h-[150px] ${data.length > 3 ? 'overflow-y-scroll pr-[10px]' : ''}`}>
        {Array.isArray(data) &&
          data &&
          data.map((learning) => (
              <CourseListItem key={learning.id} data={learning} />
          ))}
      </div>
      <div className="w-full flex justify-end mb-[12px] mt-[14px]">
        <div
          className="h-[21px] w-[126px] text-white bg-secondary-light text-[11px] font-[500] leading-[100%] rounded-[5px] flex justify-center items-center cursor-pointer"
          onClick={() => navigate(ROUTE.MY_PAGE.COURSE_LIST)}
        >
          受講コース一覧をみる
        </div>
      </div>
      {/* Adding Courses hidden for later phases */}
      <div className="text-[12px] leading-[100%] font-[500] text-secondary flex gap-[7px] items-center w-full">
        {featurePurchased && (
            <Link
              to={PATH_PRODUCT.DEFAULT}
              className="text-[12px] font-[500] flex text-secondary items-center mb-[17px] pl-[18px] cursor-pointer hover:bg-none"
            >
              <IoIosAddCircleOutline size={18} className="mr-[7px]" />
              ほかのコースを検索・追加する
            </Link>
          )}
      </div>
    </div>
  );
};

export default CourseList;
