import React from 'react';
import TabCard from '../../../components/admin/commons/TabCard';
import AdminLayout from '../../../layouts/admin';
import { SECTION_TAB_DATA } from '../../../services/constants/admin/pages/section';
import { PATH_ADMIN_SECTION } from '../../../services/constants/route/router';
import { useTabFinder } from '../../../hooks/useTabFinder';
import { WithChildren } from '../../../types/globals';

type SectionMainProp = {
  title?: string;
};

const SectionBase = ({ title, children }: WithChildren<SectionMainProp>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_SECTION));

  return (
    <AdminLayout>
      <TabCard tabData={SECTION_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </AdminLayout>
  );
};

export default SectionBase;
