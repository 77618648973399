import { MdDone, MdOutlineBook, MdSchedule } from "react-icons/md";
import { TYPE_CHART } from "../../../../services/constants/student/studytool";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { useNavigate } from "react-router-dom";
import { PATH_STUDY_TOOL } from "../../../../services/constants/route/router";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";
import { compareValueBackground } from "../../../../services/helpers/compare";

const ReportHeader = () => {
  const navigate = useNavigate();
  const { getParamValue } = useObjectRoutes();
  const { studentTheme } = useStudentThemeContext();
  const typeChart = getParamValue("type") || TYPE_CHART.STUDY_TIME;

  return (
    <div className="max-w-[734px] flex justify-between items-center h-[47px] my-[10px] bg-warning-lighter rounded-[23px] text-secondary">
      <div className="flex flex-1 justify-center items-center text-[12.5px]  font-[700]">
        <div
          style={{
            backgroundColor: compareValueBackground(
              typeChart,
              TYPE_CHART.STUDY_TIME,
              studentTheme.main_color_second || "bg-warning",
              ""
            ),
          }}
          className={`flex justify-center items-center gap-[10px] w-[235px] h-[32px] ml-[5px] cursor-pointer  max-sm:w-[115px] ${
            typeChart === TYPE_CHART.STUDY_TIME
              ? "bg-warning rounded-[16px] text-white"
              : ""
          } `}
          onClick={() => {
            navigate(`${PATH_STUDY_TOOL.REPORT}?type=${TYPE_CHART.STUDY_TIME}`);
          }}
        >
          <MdSchedule size={18} />
          学習時間
        </div>
      </div>
      <div className="flex flex-1 justify-center items-center text-[12.5px]  font-[700]">
        <div
          style={{
            backgroundColor: compareValueBackground(
              typeChart,
              TYPE_CHART.STUDY_PROCESS,
              studentTheme.main_color_second || "bg-warning",
              ""
            ),
          }}
          className={`flex justify-center items-center gap-[10px] w-[235px] h-[32px] cursor-pointer max-sm:w-[115px] ${
            typeChart === TYPE_CHART.STUDY_PROCESS
              ? "bg-warning rounded-[16px] text-white"
              : ""
          }`}
          onClick={() => {
            navigate(
              `${PATH_STUDY_TOOL.REPORT}?type=${TYPE_CHART.STUDY_PROCESS}`
            );
          }}
        >
          <MdDone size={18} />
          学習進捗率
        </div>
      </div>
      <div className="flex flex-1 justify-center items-center text-[12.5px]  font-[700]">
        <div
          style={{
            backgroundColor: compareValueBackground(
              typeChart,
              TYPE_CHART.STUDY_COURSE,
              studentTheme.main_color_second || "bg-warning",
              ""
            ),
          }}
          className={`flex justify-center items-center gap-[10px] w-[235px] h-[32px] mr-[5px] cursor-pointer max-sm:w-[120px] ${
            typeChart === TYPE_CHART.STUDY_COURSE
              ? "bg-warning rounded-[16px] text-white"
              : ""
          }`}
          onClick={() => {
            navigate(
              `${PATH_STUDY_TOOL.REPORT}?type=${TYPE_CHART.STUDY_COURSE}`
            );
          }}
        >
          <MdOutlineBook size={18} />
          学習コース割合
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
