import { Object } from '../types/globals';

const useStorage = () => {
  const getLocalStorage = (name: string) => JSON.parse(localStorage.getItem(name) || '""');

  const setLocalStorage = (name: string, data: Object | string) =>
    localStorage.setItem(name, JSON.stringify(data));

  const deleteLocalStorage = (name: string | Array<string>) => {
    if (typeof (name) === 'string') {
      localStorage.removeItem(name)
      return;
    }

    name.map((key: string) => localStorage.removeItem(key));
  };

  const clearLocalStorage = () => localStorage.clear();

  return { getLocalStorage, setLocalStorage, deleteLocalStorage, clearLocalStorage };
};

export { useStorage };
