import ChartStudyTime, { ChartStudyTimeProps } from './ChartStudyTime';
import ChartStudyProcess, { ChartStudyProcessProps } from './ChartStudyProcess';
import ChartStudyRatio, { ChartStudyRatioProps } from './ChartStudyRatio';
import { TYPE_CHART } from '../../../../services/constants/student/studytool';

type ChartDisplayerProps = {
  typeChart: string;
} & (ChartStudyTimeProps | ChartStudyProcessProps | ChartStudyRatioProps);

const ChartDisplayer = ({ typeChart, ...props }: ChartDisplayerProps): JSX.Element => {
  switch (typeChart) {
    case TYPE_CHART.STUDY_PROCESS:
      return <ChartStudyProcess {...(props as ChartStudyProcessProps)} />;
    case TYPE_CHART.STUDY_COURSE:
      return <ChartStudyRatio {...(props as ChartStudyRatioProps)} />;
    default:
      return <ChartStudyTime {...(props as ChartStudyTimeProps)} />;
  }
};

export default ChartDisplayer;
