const SECTION_TYPE_EVENT_FORM_DATEPICKER = {
  START: "start",
  END: "end",
};

const UNIT_EVENT_FORM_DATEPICKER = {
  DATE: "date",
  TIME: "time",
};

const STATE_EVENT_FORM = {
  CREATE: "create",
  EDIT: "edit",
};

export { SECTION_TYPE_EVENT_FORM_DATEPICKER, UNIT_EVENT_FORM_DATEPICKER, STATE_EVENT_FORM };
