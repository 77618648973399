import { useEffect, useState } from "react";
import StudyHistoryListItem from "./ListItem";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import Pagination from "./Pagination";
import { paramizeObject } from "../../../services/helpers/parseData";
import { StudentActivityLog } from "../../../types/student/studentActivityLog";

type FilterType = {
  limit: number;
  page: number;
};

const StudyHistoryList = () => {
  const [userStudyHistoryList, setUserStudyHistoryList] = useState<
    StudentActivityLog[]
  >([]);
  const [filter, setFilter] = useState<FilterType>({
    limit: 15,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  useEffect(() => {
    const fetchUserHistory = async () => {
      await request.get(
        `${API.STUDENT_ACTIVITY_LOG.GET_STUDENT_ACTIVITY_LOG}?${paramizeObject(
          filter
        )}`,
        (studentActivityLog) => {
          setUserStudyHistoryList(
            studentActivityLog?.student_activity_logs || []
          );
          setTotalPages(studentActivityLog?.total_page || 1);
          setWaitingApi(false);
        },
        (error) => {
          setWaitingApi(false);
        }
      );
    };

    fetchUserHistory();
  }, [filter]);

  const changePaginate = (number: number) => {
    setCurrentPage(number);
    setFilter({ ...filter, page: number });
  };

  return (
    <section>
      {!waitingApi ? (
        <>
          <div className="w-full mb-[40px] pt-[42px] px-[34px] animate-[show_0.5s_ease-in-out]">
            {Array.isArray(userStudyHistoryList) &&
              userStudyHistoryList &&
              userStudyHistoryList.map((unit, index) => (
                <StudyHistoryListItem key={index} data={unit} />
              ))}
          </div>
          <Pagination
            totalItems={totalPages}
            currentPage={currentPage}
            handlePageChange={changePaginate}
          />
        </>
      ) : (
        <div className="skeleton w-full min-h-[100px] mb-[20px]"></div>
      )}
    </section>
  );
};

export default StudyHistoryList;
