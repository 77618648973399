import React from 'react';
import TabCard from '../../../components/admin/commons/TabCard';
import AdminLayout from '../../../layouts/admin';
import { STUDENT_TAB_DATA } from '../../../services/constants/admin/pages/student';
import { PATH_ADMIN_STUDENT } from '../../../services/constants/route/router';
import { useTabFinder } from '../../../hooks/useTabFinder';

type StudentMainProp = {
  children: React.ReactNode;
  title?: string;
};

const StudentBase = ({ title, children }: StudentMainProp) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_ADMIN_STUDENT));

  return (
    <AdminLayout>
      <TabCard tabData={STUDENT_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </AdminLayout>
  );
};

export default StudentBase;
