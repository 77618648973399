import React from "react";
import TermOfUseBase from ".";
import LoginPageSettingEdit from "../../../components/admin/TermOfUse/LoginPageSetting/LoginPageSettingEdit";

const LoginPageSetting = () => {
  return (
    <TermOfUseBase title="各種設定  ｜ ログインページ設定">
      <LoginPageSettingEdit/>
    </TermOfUseBase>
  );
};

export default LoginPageSetting;
