import { ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { PATH_PRODUCT } from "../../../services/constants/route/router";
import StudentCartTable from "./StudentCartTable";
import { CartItem } from "../../../types/student/cart";
import { CART_COMMON } from "../../../services/constants/student/cart";
import LayoutWaitingApi from "../common/LayoutWaitingApi";

type StudentCartMain = {
  className?: string;
  cartItems: CartItem[];
  selectedIds: number[];
  waitingApi?: boolean;
  handleDelete: (artItemId?: number, status?: number) => void;
  handleMove: (status: number) => void;
  toggleSelect: (id: number) => void;
  isSelected: (id: number) => boolean;
  handleChangeQuantity: (
    e: ChangeEvent<HTMLInputElement>,
    cartItemId: number
  ) => void;
};

const StudentCartMain = ({
  className,
  cartItems,
  selectedIds,
  waitingApi,
  toggleSelect,
  isSelected,
  handleDelete,
  handleMove,
  handleChangeQuantity,
}: StudentCartMain) => {
  return (
    <>
      <div className="w-full flex items-center justify-between text-white text-[14px] font-[500] py-[15px]">
        <div>
          <Link
            to={PATH_PRODUCT.DEFAULT}
            className="tracking-[0.28px] bg-secondary-light px-[20px] py-[5px] rounded-lg hover:bg-[#00000026] active:scale-90"
          >
            商品一覧へ戻る
          </Link>
        </div>
        <div className="flex gap-[5px] items-center text-[12px]">
          <span className="">チェックした商品を</span>
          <button
            className={`px-[10px] py-[2px] bg-danger rounded-[5px] ${
              selectedIds.length > 0 ? "active:scale-90" : ""
            }`}
            disabled={!selectedIds.length}
            onClick={() => handleDelete(undefined, CART_COMMON.CART_IN_SHOP)}
          >
            削除
          </button>
          <button
            className={`px-[10px] py-[2px] bg-secondary-light rounded-[5px] ${
              selectedIds.length > 0 ? "active:scale-90" : ""
            }`}
            disabled={!selectedIds.length}
            onClick={() => handleMove(CART_COMMON.CART_BUY_LATER)}
          >
            あとで買う
          </button>
        </div>
      </div>
      <LayoutWaitingApi waitingApi={waitingApi} className="w-full h-[150px]">
        <StudentCartTable
          className="px-[10px] animate-[show_0.5s_ease-in-out]"
          cartItems={cartItems?.filter(
            (cartItem) => cartItem.status === CART_COMMON.CART_IN_SHOP
          )}
          isSelected={isSelected}
          toggleSelect={toggleSelect}
          handleDelete={handleDelete}
          handleChangeQuantity={handleChangeQuantity}
        />
      </LayoutWaitingApi>
    </>
  );
};

export default StudentCartMain;
