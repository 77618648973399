import {
  MODE_EXECUTE_TEST,
  MODE_EXECUTE_TEST_DATA,
  TEST_HISTORY_TABLE_HEAD,
} from "../../../../services/constants/student/unit";
import { isEmptyArr } from "../../../../services/helpers/etc";
import { TestHistory } from "../../../../types/student/unit";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { getPercent } from "../../../../services/helpers/parseData";
import { useStudentUnitContext } from "../../../../context/StudentUnitContext";
import { scrollToTop } from "../../../../services/helpers/effect";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";

type TestHistoryTableProps = {
  data?: TestHistory[];
};

const displayRecord = (record: any) => {
  return record ? record : "-";
};

const TestHistoryTable = ({ data }: TestHistoryTableProps) => {
  const { studentTheme } = useStudentThemeContext();
  const { showSettings, currentHistory, setShowSettings, setCurrentHistoryId } =
    useStudentUnitContext();

  const handleHistorySelect = (history: TestHistory) => {
    setCurrentHistoryId(Number(history.id));
    scrollToTop();

    if (!showSettings?.isReviewTestTable) {
      setShowSettings((prevSettings) => ({
        ...prevSettings,
        isReviewTestTable: true,
      }));
    }
  };

  return (
    <div
      style={{
        scrollbarColor: studentTheme.sub_color_first + " !important",
      }}
      className="h-[276px] overflow-y-scroll"
    >
      <table className="w-full text-sm text-left text-gray-500 table-auto overflow-scroll mb-[20px]">
        <thead className="text-base bg-success-lighter text-center">
          <tr
            style={{ color: studentTheme.text_color }}
            className="min-h-[30px] text-[13px] font-[500]"
          >
            {TEST_HISTORY_TABLE_HEAD.map((label, index) => (
              <th
                scope="col"
                className="px-0 border-x-4 border-white whitespace-nowrap"
                key={`${label}_${index}`}
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!isEmptyArr(data) &&
            data?.map((record, index) => (
              <tr
                style={{ color: studentTheme.text_color }}
                key={`${record.id || "_"}${index}`}
                className={`border-b w-full text-[12px] font-[400] w-[15%] h-[50px] ${
                  currentHistory?.id === record.id
                    ? "bg-warning-extralight"
                    : "bg-white"
                }`}
              >
                <td className="text-center">
                  {displayRecord(formatDateTime(record.created_at))}
                </td>
                <td className="text-center">
                  {MODE_EXECUTE_TEST_DATA[record.test_type || 0]}
                </td>
                <td className="text-center">{record.question_quantity}</td>
                <td className="text-center">
                  {record.correct_question_quantity}
                </td>
                <td className="text-center w-[30%]">
                  <div className="flex justify-center items-center gap-[10px]">
                    <div className="w-full max-w-[110px]">
                      <div className="w-full rounded-full h-[8px] bg-success-extralight">
                        <div
                          className={`bg-warning h-[8px] rounded-full`}
                          style={{
                            width: `${getPercent(
                              record.score,
                              record.total_score
                            )}%`,
                            backgroundColor: studentTheme.sub_color_first,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="w-[60px]">
                      {record.score || "-"}/{record.total_score || "-"}点
                    </div>
                  </div>
                </td>
                <td className="text-center w-[15%]">
                  {record.test_type === MODE_EXECUTE_TEST.REVISE
                    ? "-"
                    : record.is_pass
                    ? "合格"
                    : `あと${record.score_needed_to_pass || "0"}点`}
                </td>
                <td
                  className="text-center cursor-pointer"
                  onClick={() => handleHistorySelect(record)}
                >
                  結果詳細
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TestHistoryTable;
