import React, { useEffect, useState } from "react";
import { Course } from "../../../types/student/course";
import { request } from "../../../services/axios/axios";
import CourseDetailItems from "./DetailItems";
import { API } from "../../../services/constants/route/api";
import { BsPlusCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { PATH_PRODUCT } from "../../../services/constants/route/router";
import { useFeaturePurchasedContext } from "../../../context/FeaturePurchasedContext";

const CourseDetailList = () => {
  const [courseList, setCourseList] = useState<Course[]>([]);
  const [waitingApi, setWaitingApi] = useState<boolean>(true);
  const { featurePurchased } = useFeaturePurchasedContext();

  useEffect(() => {
    const fetchCourses = async () =>
      await request.get(
        API.MYPAGE.COURSE_LIST,
        (data) => {
          setCourseList(data);
          setWaitingApi(false);
        },
        (error) => setWaitingApi(false)
      );
    fetchCourses();
  }, [featurePurchased]);

  return (
    <div className="w-full border border-danger-light rounded-[5px] mb-[15px] py-2 px-2">
      {!waitingApi ? (
        <div className="w-full bg-secondary-morelighter pt-3 pb-1 px-3 rounded-[5px] animate-[show_0.5s_ease-in-out]">
          <div className="font-[500] text-[14px] leading-[100%] mb-[14px]">
            受講コース一覧
          </div>
          {courseList.map((course) => (
            <CourseDetailItems key={course.id} course={course} />
          ))}
          {/* Adding Courses hidden for later phases */}
          {featurePurchased && (
            <Link
              to={PATH_PRODUCT.DEFAULT}
              className="text-[12px] font-[500] flex text-secondary items-center mb-[17px] pl-[18px] cursor-pointer hover:bg-none"
            >
              <BsPlusCircle size={18} className="mr-[7px]" />
              ほかのコースを検索・追加する
            </Link>
          )}
        </div>
      ) : (
        <div className="skeleton w-full min-h-[150px] mb-[20px]"></div>
      )}
    </div>
  );
};

export default CourseDetailList;
