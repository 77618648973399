import React, { useContext, useEffect, useState } from "react";
import { ChildrenWithProps, SetStateAction } from "../types/globals";
import { request } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { useStorage } from "../hooks/useStorage";
import axios from "axios";

type FeaturePurchasedContextType = {
  featurePurchased: boolean;
  setFeaturePurchased: SetStateAction<boolean>;
};

const FeaturePurchasedContext =
  React.createContext<FeaturePurchasedContextType>({
    featurePurchased: false,
    setFeaturePurchased: () => {},
  });

const FeaturePurchasedContextProvider = ({ children }: ChildrenWithProps) => {
  const [featurePurchased, setFeaturePurchased] = useState<boolean>(false);
  const { setLocalStorage, getLocalStorage } = useStorage();
  const user = getLocalStorage("user");

  useEffect(() => {
    const handleFetchPaymentMethod = async () => {
      try {
        const url =
          process.env.REACT_APP_API_PALETTE +
          `/api/payment-setting/is-set-payment-setting?organization_id=${user.organization_id}`;
        const response = await axios.get(url);
        const result = response.data.result;
        setLocalStorage("payment_feature", result.data);
        setFeaturePurchased(result.data);
      } catch (error) {
        return;
      }
    };

    handleFetchPaymentMethod();
  }, []);

  return (
    <FeaturePurchasedContext.Provider
      value={{
        featurePurchased,
        setFeaturePurchased,
      }}
    >
      {children}
    </FeaturePurchasedContext.Provider>
  );
};

export default FeaturePurchasedContextProvider;

export const useFeaturePurchasedContext = () =>
  useContext(FeaturePurchasedContext);
