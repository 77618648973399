import React from "react";

import { FiPenTool } from "react-icons/fi";
import { IconBaseProps } from "react-icons/lib";
import {
  MdOutlineInventory,
  MdOutlineSchool,
  MdOndemandVideo,
} from "react-icons/md";

type ScheduleEventTypeProps = {
  name: string;
} & IconBaseProps;

const ScheduleEventType = ({ name, ...props }: ScheduleEventTypeProps) => {
  switch (name) {
    case "FiPenTool":
      return <FiPenTool {...props}/>;

    case "MdOutlineInventory":
      return <MdOutlineInventory {...props}/>;

    case "MdOutlineSchool":
      return <MdOutlineSchool {...props}/>;

    case "MdOndemandVideo":
      return <MdOndemandVideo {...props}/>;
    default:
      return <></>;
  }
};

export default ScheduleEventType;
