import React from 'react'
import { ANSWER_TYPE } from '../../../../services/constants/student/unit';
import Correct from '../../../commons/icons/Learning/Correct';
import Incorrect from '../../../commons/icons/Learning/Incorrect';
import Unknown from '../../../commons/icons/Learning/Unknown';

const AnswerType = ({type} : {type?: number | null}) => {
    switch (type) {
        case ANSWER_TYPE.CORRECT:
          return (
            <div className="flex justify-center items-center">
              <Correct/>
            </div>
          );
        case ANSWER_TYPE.INCORRECT:
          return (
            <div className="flex justify-center items-center">
              <Incorrect/>
            </div>
          );
    
        default:
          return (
            <div className="flex justify-center items-center">
              <Unknown/>
            </div>
          );
      }
    };


export default AnswerType