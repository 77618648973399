import SectionTitle from '../Title';
import SidebarManagement from './Sidebar';
import TableManagement from './Table';
import TableActionManagement from './TableAction';
import TableCommonAction from '../../commons/TableCommonAction';
import { useSectionManagementContext } from '../../../../context/Section/Management';
import { useState } from 'react';

const SectionManagement = () => {
  const { mainSections, title } = useSectionManagementContext();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <section>
      <TableCommonAction onCreate={() => setIsOpenModal(true)}/>
      <SectionTitle isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} content={title || mainSections[0]?.name || ''} withSubtitle withButton/>

      <div className="flex w-full mt-[15px] gap-[30px]">
        <div className="w-full max-w-[300px]">
          <SidebarManagement />
        </div>
        <div className="w-full">
          <TableActionManagement />
          <TableManagement />
        </div>
      </div>
    </section>
  );
};

export default SectionManagement;
