import { BiSearchAlt2 } from "react-icons/bi";
import { FILTER } from "../../../services/constants/admin/main";
import { useState, ChangeEvent, useEffect, useRef } from "react";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalClose, swalError } from "../../../services/helpers/swal";
type MainSection = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_section_id: null;
  status: number;
  student_count: number;
};

type SubSection = {
  code: string;
  id: number;
  name: string;
  order: number;
  parent_section_id: number;
  status: number;
  student_count: number;
};

const StudentFilter = ({
  changeHandle,
  deleteSelected,
  selectedMainSection,
}: {
  changeHandle: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  deleteSelected: () => void;
  selectedMainSection: string;
}) => {
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const [mainSections, setMainSections] = useState<MainSection[]>([]);
  const [subSections, setSubSections] = useState<SubSection[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_SECTION.GET_SECTION_LIST}`,
      });

      if (!result.data) {
        swalError();
      }

      setMainSections(result.data);
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (parseInt(selectedMainSection) > 0) {
      const fetch = async () => {
        const result = await makeRequest({
          method: "get",
          url: `${API.ADMIN_SECTION.GET_SUBSECTION_LIST}?main_section_id=${selectedMainSection}`,
        });

        if (!result.data) {
          swalError();
        }

        setSubSections(result.data);
        swalClose();
      };
      fetch();
    } else {
      // Clear the subsections array when no main section is selected
      setSubSections([]);
    }
  }, [selectedMainSection]);
  return (
    <section className="mt-[40px] pb-[40px] border-secondary-light">
      <div className="flex h-[30px] gap-[8px] relative">
        <div className="relative">
          <input
            type="text"
            name="query"
            id=""
            className="h-full max-w-[130px] pl-[12px] placeholder-[#BEBEBE] text-[11px] leading-[100%] border-[#E1E3E2] rounded-[5px]"
            placeholder="受講者の検索"
            onChange={changeHandle}
          />
          <BiSearchAlt2
            className="absolute top-[5px] right-[9.26px] text-secondary-light"
            size={22}
          />
        </div>
        <div className="relative">
          <select
            name="mainSection"
            className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] min-w-[170px]"
            value={selectedMainSection}
            onChange={changeHandle}
            ref={selectRef}
          >
            <option value="">セクション名を選択</option>
            {mainSections?.map((mainSection) => (
              <option key={mainSection.id} value={mainSection.id}>
                {mainSection.name}
              </option>
            ))}
          </select>
        </div>
        <div className="relative">
          <select
            name="subSection"
            className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] min-w-[170px]"
            onChange={changeHandle}
          >
            <option value="">サブセクション名を選択</option>
            {subSections?.map((subSection) => (
              <option key={subSection.id} value={subSection.id}>
                {subSection.name}
              </option>
            ))}
          </select>
        </div>
        <div className="relative">
          <select
            name="limit"
            id=""
            className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] w-[95px]"
            onChange={changeHandle}
            ref={selectRef}
          >
            {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
              <option value={item.value} className="" key={index}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="relative flex items-center">
          <label className="ml-[3px] inline-flex items-center ">
            <input
              id="check-user-deactive"
              type="checkbox"
              onChange={changeHandle}
              className="form-checkbox text-primary border-[1px] border-[#EBE4D8] rounded-[3px] cursor-pointer"
            />
            <label
              htmlFor="check-user-deactive"
              className="ml-2 mr-[5px] font-400 text-[12px] whitespace-nowrap cursor-pointer"
            >
              無効アカウントを表示
            </label>
          </label>
        </div>
        <div className="relative whitespace-nowrap text-[13px] font-[400] 2xl:absolute 2xl:right-1">
          チェックした受講生を
          <button
            className="ml-[8px] min-w-[60px] min-h-[30px] text-[14px] px-[6px] pt-[3px] pb-[5px] rounded-[5px] bg-[#FE6D73] text-white"
            onClick={deleteSelected}
          >
            削除
          </button>
        </div>
      </div>
    </section>
  );
};

export default StudentFilter;
