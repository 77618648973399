import { getTabObj } from '../../../helpers/parseData';
import { PATH_ADMIN_SECTION } from '../../route/router';

const SECTION_TAB_ITEM = {
  LIST: 'セクション一覧',
  MANAGEMENT: 'セクション管理',
  SETTING: 'セクション設定',
};

const SECTION_TAB_DATA = [
  getTabObj(SECTION_TAB_ITEM.LIST, PATH_ADMIN_SECTION.DEFAULT),
  getTabObj(SECTION_TAB_ITEM.MANAGEMENT, PATH_ADMIN_SECTION.MANAGEMENT),
];

const TRANS_STUDENT_ACTION = {
  ADD: 1,
  MOVE: 2,
};

export {
  SECTION_TAB_DATA,
  TRANS_STUDENT_ACTION,
  SECTION_TAB_ITEM,
};
