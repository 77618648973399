import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useStorage } from "../../hooks/useStorage";

const FinishSubmitForgotPwd = () => {
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");

  return (
    <AuthLayout disableTokenCheck>
      <div className="mb-[45px] mt-[50px] mx-auto max-sm:mt-[25px]">
        <div className="w-[550px] max-sm:w-[100%] h-[252px] max-sm:h-[190px] bg-white px-[30px] border border-warning-light max-sm:pt-[30px] max-sm:pb-[]">
          <p
            style={{ color: studentTheme.main_color_first }}
            className="mt-[50px] mb-[30px] text-[18px] font-medium leading-[100%] text-primary max-sm:mt-0 max-sm:mb-0 max-sm:text-[15px]"
          >
            パスワード再設定が完了しました
          </p>

          <div className="text-[0.75rem] font-light leading-[190%] max-sm:px-[15px] max-sm:pt-[15px] max-sm:pb-[20px]">
            ご登録いただいたメールアドレス宛に <br />
            仮パスワードをご案内いたしました。
            <br />
            メールに記載されたURLから30分以内にログインし <br />
            パスワードの再設定を行なってください。
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default FinishSubmitForgotPwd;
