import React, { useState, useEffect } from "react";
import StudentThemeItem from "./StudentThemeComponent.tsx/StudentThemeItem";
import Button from "../../../commons/buttons/Button";
import {
  STUDENT_THEME_COLOR_DEFAULT,
  STUDENT_THEME_DEFAULT,
} from "../../../../services/constants/admin/pages/student_theme";
import { makeRequest, request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import ErrorBox from "../../../commons/form/ErrorBox";
import { ErrorData } from "../../../../types/globals";
import { useStudentThemeContext } from "../../../../context/StudentThemeContext";
import { StudentThemeType } from "../../../../types/admin/student_theme";
import { useStorage } from "../../../../hooks/useStorage";

const StudentThemeSettingEdit = () => {
  const subDomain = window.location.hostname.split(".")[0];
  const [errors, setErrors] = useState<ErrorData>({});
  const [studentTheme, setStudentTheme] = useState<StudentThemeType>({
    sub_domain: subDomain,
  });
  const { setLocalStorage } = useStorage();
  const handleSubmit = async () => {
    await request.patch(
      API.ADMIN_STUDENT_THEME_SETTING.UPDATE,
      studentTheme,
      (data) => {
        setStudentTheme(data);
      },
      (errors) => {
        setErrors(errors);
      },
      { withSuccess: true }
    );
  };

  useEffect(() => {
    const fetchStudentThemeAdmin = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.ADMIN_STUDENT_THEME_SETTING.GET_DETAIL}?sub_domain=${subDomain}`,
      });
      if (!result.data) {
        const data = STUDENT_THEME_DEFAULT;
        setStudentTheme({ ...studentTheme, ...data });
        return;
      }

      setStudentTheme({ ...studentTheme, ...result.data });
    };

    fetchStudentThemeAdmin();
  }, []);

  return (
    <section>
      {errors && Object.keys(errors).length > 0 && <ErrorBox errors={errors} />}
      <div className="py-[20px] text-secondary-dark font-[400] test-[12px] leading-[14px]">
        <div className="text-[14px] font-[500]">受講者画面　背景デザイン</div>
        <div className="text-[14px] py-[20px] leading-[190%] text-justify">
          システムのカラーを変更できます
        </div>
        <div>
          <StudentThemeItem
            label="背景色"
            color={
              studentTheme.background_color ||
              STUDENT_THEME_COLOR_DEFAULT.BACKGROUND
            }
            colorDefault={STUDENT_THEME_COLOR_DEFAULT.BACKGROUND}
            inputName="background_color"
            setStudentThemeData={setStudentTheme}
            studentThemeData={studentTheme}
          />
          <StudentThemeItem
            label="メインカラー１"
            color={
              studentTheme.main_color_first ||
              STUDENT_THEME_COLOR_DEFAULT.MAIN_1
            }
            colorDefault={STUDENT_THEME_COLOR_DEFAULT.MAIN_1}
            inputName="main_color_first"
            setStudentThemeData={setStudentTheme}
            studentThemeData={studentTheme}
          />
          <StudentThemeItem
            label="メインカラー2"
            color={
              studentTheme.main_color_second ||
              STUDENT_THEME_COLOR_DEFAULT.MAIN_2
            }
            colorDefault={STUDENT_THEME_COLOR_DEFAULT.MAIN_2}
            inputName="main_color_second"
            setStudentThemeData={setStudentTheme}
            studentThemeData={studentTheme}
          />
          <StudentThemeItem
            label="アクセントカラー１"
            color={
              studentTheme.sub_color_first || STUDENT_THEME_COLOR_DEFAULT.SUB_1
            }
            colorDefault={STUDENT_THEME_COLOR_DEFAULT.SUB_1}
            inputName="sub_color_first"
            setStudentThemeData={setStudentTheme}
            studentThemeData={studentTheme}
          />
          <StudentThemeItem
            label="アクセントカラー２"
            color={
              studentTheme.sub_color_second || STUDENT_THEME_COLOR_DEFAULT.SUB_2
            }
            colorDefault={STUDENT_THEME_COLOR_DEFAULT.SUB_2}
            inputName="sub_color_second"
            setStudentThemeData={setStudentTheme}
            studentThemeData={studentTheme}
          />
          <StudentThemeItem
            label="トップダッシュボード"
            color={
              studentTheme.page_top_color || STUDENT_THEME_COLOR_DEFAULT.PAGE_TOP
            }
            colorDefault={STUDENT_THEME_COLOR_DEFAULT.PAGE_TOP}
            inputName="page_top_color"
            setStudentThemeData={setStudentTheme}
            studentThemeData={studentTheme}
          />
          <StudentThemeItem
            label="講座トップダッシュボード"
            color={
              studentTheme.lecture_top_color || STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP
            }
            colorDefault={STUDENT_THEME_COLOR_DEFAULT.LECTURE_TOP}
            inputName="lecture_top_color"
            setStudentThemeData={setStudentTheme}
            studentThemeData={studentTheme}
          />
          <StudentThemeItem
            label="文字"
            color={studentTheme.text_color || STUDENT_THEME_COLOR_DEFAULT.TEXT}
            colorDefault={STUDENT_THEME_COLOR_DEFAULT.TEXT}
            inputName="text_color"
            setStudentThemeData={setStudentTheme}
            studentThemeData={studentTheme}
          />
        </div>
      </div>
      <div className="flex items-center justify-center mx-[26px]">
        <Button
          className="px-[50px] py-[10px] text-[16px] text-white font-[700] leading-[100%] text-center bg-primary rounded-[10px]"
          onClick={handleSubmit}
        >
          設定を保存
        </Button>
      </div>
    </section>
  );
};

export default StudentThemeSettingEdit;
