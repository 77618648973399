import TableTitle from '../../commons/TableTitle';
import CourseTable from './CourseTable';

const MaterialCourseList = () => {
  return (
    <>
      <TableTitle subContent="選択中のコース" content="すべての学習コース" />
      <CourseTable />
    </>
  );
};

export default MaterialCourseList;
