import * as React from 'react';

const History = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.05 3.08a8.39 8.39 0 0 0-11.84 0L2 4.29V1.38a.75.75 0 0 0-1.5 0v4.74a.76.76 0 0 0 .75.76H6a.75.75 0 1 0 0-1.5H3l1.27-1.24a6.88 6.88 0 0 1 9.72 0c6.19 6.69-3 15.91-9.72 9.72a.75.75 0 0 0-1.06 0 .74.74 0 0 0 0 1.06A8.372 8.372 0 1 0 15.05 3.08Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
    <path
      d="M9 4.75a.76.76 0 0 0-.75.75V9c0 .199.08.39.22.53L11 12a.74.74 0 0 0 .53.22A.75.75 0 0 0 12 11L9.72 8.72V5.5A.76.76 0 0 0 9 4.75Z"
      fill={`${props.color || '#7A7A7A'}`}
    />
  </svg>
);

export default History;
