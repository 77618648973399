import React from "react";
import TableTitle from "../../commons/TableTitle";
import UnitTable from "./UnitTable";

const MaterialUnitList = () => {
  return (
    <>
      <UnitTable />
    </>
  );
};

export default MaterialUnitList;
