import React, { useState } from "react";
import Button from "../components/Login/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalError, swalSuccess } from "../services/helpers/swal";
import AuthLayout from "../layouts/AuthLayout";
import { ErrorData } from "../types/globals";
import ErrorBox from "../components/commons/form/ErrorBox";
import { useStorage } from "../hooks/useStorage";
import { useObjectRoutes } from "../hooks/useObjectRoutes";

const ResendVerification = () => {
  let navigate = useNavigate();
  const { getLocalStorage } = useStorage();
  const studentTheme = getLocalStorage("theme");
  const [errors, setErrors] = useState<ErrorData>({});
  const [email, setEmail] = useState("");
  const { getParamValue } = useObjectRoutes();
  const [waitingAPI, setWaitingAPI] = useState<boolean>(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const handleResendCode = async () => {
    let urlApi: string = API.AUTH.RESEND_VERIFY_EMAIL;
    let type: string = "";
    if (getParamValue("type") && getParamValue("type") === "change_email") {
      urlApi = API.AUTH.RESEND_VERIFY_CHANGE_EMAIL;
      type = "&type=change_email";
    }

    setWaitingAPI(true);
    const result = await makeRequest({
      method: "post",
      url: urlApi,
      data: { ["email"]: email },
    });
    setWaitingAPI(false);

    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError();
    }

    swalSuccess();
    navigate(`${ROUTE.VERIFY_EMAIL}?token=${result.data.token}${type}`);
  };

  return (
    <>
      <AuthLayout>
        <>
          <form
            style={{ color: studentTheme.text_color }}
            className="mb-[45px] mt-[30px] mx-auto max-sm:mx-[10px]"
          >
            <div className="w-[550px] max-sm:w-full mb-[30px] bg-white px-[25px] border border-warning-light">
              <p className="mt-[30px] mb-[20px] text-[15px] max-sm:mt-[25px]">
                コード再信
              </p>
              <p className="text-[12px] px-[60px] leading-[22.8px] mb-[20px] max-sm:font-[400]">
                ご登録メールアドレスに確認コードをお届けします。<br></br>
                確認コードを受け取るメールアドレスを入力してください。
              </p>
              <div className="mb-[40px] max-sm:mb-[20px]">
                <input
                  type="text"
                  placeholder="メールアドレスを入力"
                  onChange={handleEmailChange}
                  value={email}
                  name="email"
                  className="min-h-[50px] border border-warning-light max-sm:border-danger-light text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full py-[14px] placeholder:text-[#9E9E9F] placeholder:text-sm"
                  required
                />
              </div>
              <div className="mb-[15px] max-sm:flex max-sm:flex-col max-sm:items-center">
                <Button
                  style={{ backgroundColor: studentTheme.main_color_first }}
                  content={"コードを再信"}
                  otherstyle={
                    "bg-primary text-white mb-[10px] max-sm:mb-[20px] max-sm:text-[18px]"
                  }
                  type={"button"}
                  onClick={handleResendCode}
                  isSubmitting={waitingAPI}
                />
                <Button
                  content={"キャセル"}
                  otherstyle={
                    "bg-secondary-light text-white mb-[10px] max-sm:text-[18px]"
                  }
                  type={"button"}
                  onClick={handleGoBack}
                />
                {errors && Object.keys(errors).length > 0 && (
                  <ErrorBox errors={errors} />
                )}
                <p className="text-[12px] align-middle font-[400] mt-[7px] py-[14px] text-danger">
                  管理者が登録したメールアドレスを入力してください
                </p>
              </div>
            </div>
          </form>
        </>
      </AuthLayout>
    </>
  );
};

export default ResendVerification;
