import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { IUser, User } from '../types/user';
import { useLocalStorage } from './useLocalStorage';

export const useUser = () => {
  const { user, setUser } = useContext(AuthContext);
  const { setItem } = useLocalStorage();
  
  const addUser = (user: User) => {
    setUser(user);
    setItem('user', JSON.stringify(user));
  };

  const removeUser = () => {
    setUser({});
    setItem('user', '');
  };

  return { user, setUser, addUser, removeUser };
};
